import React from "react";
import styled from "styled-components";
import { space } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";
import { Container } from "../../../common/website/HOC.jsx";

import HeadingOne from "../../../common/website/HeadingOne.jsx";
import Paragraph from "../../../common/website/Paragraph.jsx";
import HeadingTwo from "../../../common/website/HeadingTwo.jsx";

import LandingBannerImg from "../../../images/website/landing_banner_dashboard.png";
import BannerLeftCircle from "../../../images/website/banner-circle.svg";
import BannerLeftPattern from "../../../images/website/banner-left.svg";

import BannerDottedLine from "../../../images/website/banner-line.svg";
import BannerRightPattern from "../../../images/website/banner-right.svg";

const LandingBannerRow = styled.section`
  /* padding:${space.space60} 0 ${space.space40}; */
  padding: ${space.space60} 0 0;
  .banner_image {
    position: relative;
    padding: ${space.space40} 0 0;
    img {
      width: 100%;
      border-radius: 16px;
      box-shadow: 0px 0px 18px rgb(48 223 242 / 5%);
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      display: none;
    }
    &::before {
      width: 298px;
      height: 247px;
      background: url(${BannerDottedLine}) no-repeat;
      z-index: -1;
    }
    &::after {
      width: 355px;
      height: 417px;
      background: url(${BannerRightPattern}) no-repeat;
      z-index: -1;
    }
  }
  .banner_content {
    position: relative;
    h2 {
      font-weight: 600;
    }
    &::before,
    &::after {
      position: absolute;
      content: "";
      z-index: -1;
    }
    &::before {
      top: -36px;
      left: -8px;
      width: 236px;
      height: 247px;
      background: url(${BannerLeftCircle}) no-repeat;
      background-size: contain;
      z-index: -1;
    }
    &::after {
      top: -76px;
      left: -38px;
      width: 216px;
      height: 318px;
      background: url(${BannerLeftPattern}) no-repeat;
      background-size: contain;
      z-index: -1;
    }
  }
  overflow: hidden;

  @media ${device.mobileL} {
    .banner_image {
      padding: ${space.space60} 0 0;
    }
    .banner_content {
      &::before {
        width: 301px;
        height: 301px;
        top: -60px;
        left: 34px;
      }
      &::after {
        width: 238px;
        height: 399px;
        top: -112px;
        left: 0;
      }
    }
  }
  @media ${device.tablet} {
    /* padding:${space.space60} 0 ${space.space150}; */
    padding: ${space.space60} 0 0;
    .banner_content {
      &::before {
        top: -40px;
        left: 40px;
      }
      &::after {
        top: -92px;
        left: -18px;
      }
    }
    .banner_image {
      padding: 0;
      &::after {
        top: 20px;
        right: -125px;
        display: block;
      }
      &::before {
        display: none;
      }
    }
  }

  @media ${device.laptop} {
    .banner_content {
      &::before {
        left: -110px;
      }
      &::after {
        left: -136px;
      }
    }
  }

  @media ${device.laptopL} {
    .banner_image {
      &::before {
        top: -24px;
        left: -298px;
        background-size: cover;
        display: block;
      }
    }
  }
`;

function LandingBanner() {
  return (
    <>
      <LandingBannerRow>
        <Container>
          <div className='grid gap-4 md:grid-cols-2 items-center'>
            <div className='banner_content'>
              <HeadingOne cname='gradient' h1Data={"Yearbook 3"} />
              <Paragraph
                cname='noMargin'
                pData={"Where a 100-Year-Old Plus Tradition Meets"}
              />
              {/* <HeadingTwo cname='noMargin' h2Data={"Web 3"} />
              <HeadingTwo
                cname='white noMargin'
                h2Data={"Blockchain Technology"}
              /> */}
              <HeadingTwo
                cname='noMargin'
                h2Data={"Web 3 Blockchain Technology"}
              />
            </div>
            <div className='banner_image'>
              <img src={LandingBannerImg} alt='About_img' />
            </div>
          </div>
        </Container>
      </LandingBannerRow>
    </>
  );
}

export default LandingBanner;
