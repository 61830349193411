import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Formik, Field } from "formik";
import { SiteForm } from "./HOC.jsx";
import Label from "./Label.jsx";
import { device } from "./Devices";
import CustomRadioField from "../CustomRadioField.jsx";

import ImageCheckIcon from "../../images/website/icon_radio_check.png";
import DigitalFiltersImage from "../../images/website/digital_filters_image.png";
import DigitalFiltersImage2 from "../../images/website/digital_filters_image2.png";
import DigitalFiltersImage3 from "../../images/website/digital_filters_image3.png";
import DigitalFiltersImage4 from "../../images/website/digital_filters_image4.png";
import DigitalFiltersImage5 from "../../images/website/digital_filters_image5.png";
import { space } from "./Variables";
import { FilterContext } from "../../Pages/website/Dashboard/DigitalArtCreatorDetails.jsx";
import Button from "./Button.jsx";

const DigitalFiltersRow = styled.div`
  .digital_filters_list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -8px;
    margin-bottom: -16px;
    .item {
      width: 50%;
      padding: 0 8px 16px;
    }
  }
  .digital_filters_image {
    border-radius: ${space.space8};
    overflow: hidden;
    position: relative;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }
    .input_radio {
      display: none;
    }
    .check_icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      cursor: pointer;
      &::before {
        content: "";
        position: absolute;
        background-image: url(${ImageCheckIcon});
        background-color: rgb(0 0 0 / 60%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 38px 38px;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: none;
        cursor: pointer;
      }
    }
    .input_radio:checked ~ .check_icon::before {
      display: block;
    }
  }
  @media ${device.mobileM} {
    .digital_filters_list {
      .item {
        width: 25%;
      }
    }
  }
  @media ${device.mobileL} {
    .digital_filters_list {
      .item {
        width: 20%;
      }
    }
  }
  @media ${device.tablet} {
    .digital_filters_list {
      .item {
        width: 50%;
      }
    }
  }
`;

function DigitalFilters(props) {
  const [defaultChecked, setDefaultChecked] = useState({
    image0: false,
    image1: false,
    image2: false,
    image3: false,
    image4: false,
    image5: false,
  });

  const highContrast = [
    {
      name: "Brightness",
      property: "brightness",
      value: 100,
      range: {
        min: 0,
        max: 200,
      },
      unit: "%",
      step: 1,
    },
    {
      name: "Contrast",
      property: "contrast",
      value: 174,
      range: {
        min: 0,
        max: 200,
      },
      unit: "%",
      step: 1,
    },
    {
      name: "Saturation",
      property: "saturate",
      value: 184,
      range: {
        min: 0,
        max: 200,
      },
      unit: "%",
      step: 1,
    },
    {
      name: "Sepia",
      property: "sepia",
      value: 0,
      range: {
        min: 0,
        max: 200,
      },
      unit: "%",
      step: 1,
    },
  ];

  const lowContrast = [
    {
      name: "Brightness",
      property: "brightness",
      value: 100,
      range: {
        min: 0,
        max: 200,
      },
      unit: "%",
      step: 1,
    },
    {
      name: "Contrast",
      property: "contrast",
      value: 102,
      range: {
        min: 0,
        max: 200,
      },
      unit: "%",
      step: 1,
    },
    {
      name: "Saturation",
      property: "saturate",
      value: 71,
      range: {
        min: 0,
        max: 200,
      },
      unit: "%",
      step: 1,
    },
    {
      name: "Sepia",
      property: "sepia",
      value: 0,
      range: {
        min: 0,
        max: 200,
      },
      unit: "%",
      step: 1,
    },
  ];

  const blackAndWhite = [
    {
      name: "Brightness",
      property: "brightness",
      value: 100,
      range: {
        min: 0,
        max: 200,
      },
      unit: "%",
      step: 1,
    },
    {
      name: "Contrast",
      property: "contrast",
      value: 100,
      range: {
        min: 0,
        max: 200,
      },
      unit: "%",
      step: 1,
    },
    {
      name: "Saturation",
      property: "saturate",
      value: 0,
      range: {
        min: 0,
        max: 200,
      },
      unit: "%",
      step: 1,
    },
    {
      name: "Sepia",
      property: "sepia",
      value: 0,
      range: {
        min: 0,
        max: 200,
      },
      unit: "%",
      step: 1,
    },
  ];

  const orangeAndYellow = [
    {
      name: "Brightness",
      property: "brightness",
      value: 100,
      range: {
        min: 0,
        max: 200,
      },
      unit: "%",
      step: 1,
    },
    {
      name: "Contrast",
      property: "contrast",
      value: 100,
      range: {
        min: 0,
        max: 200,
      },
      unit: "%",
      step: 1,
    },
    {
      name: "Saturation",
      property: "saturate",
      value: 101,
      range: {
        min: 0,
        max: 200,
      },
      unit: "%",
      step: 1,
    },
    {
      name: "Sepia",
      property: "sepia",
      value: 0,
      range: {
        min: 0,
        max: 200,
      },
      unit: "%",
      step: 1,
    },
  ];

  const blackAndWhiteHightContrast = [
    {
      name: "Brightness",
      property: "brightness",
      value: 102,
      range: {
        min: 0,
        max: 200,
      },
      unit: "%",
      step: 1,
    },
    {
      name: "Contrast",
      property: "contrast",
      value: 127,
      range: {
        min: 0,
        max: 200,
      },
      unit: "%",
      step: 1,
    },
    {
      name: "Saturation",
      property: "saturate",
      value: 0,
      range: {
        min: 0,
        max: 200,
      },
      unit: "%",
      step: 1,
    },
    {
      name: "Sepia",
      property: "sepia",
      value: 0,
      range: {
        min: 0,
        max: 200,
      },
      unit: "%",
      step: 1,
    },
  ];

  const hightContrastLast = [
    {
      name: "Brightness",
      property: "brightness",
      value: 100,
      range: {
        min: 0,
        max: 200,
      },
      unit: "%",
      step: 1,
    },
    {
      name: "Contrast",
      property: "contrast",
      value: 111,
      range: {
        min: 0,
        max: 200,
      },
      unit: "%",
      step: 1,
    },
    {
      name: "Saturation",
      property: "saturate",
      value: 127,
      range: {
        min: 0,
        max: 200,
      },
      unit: "%",
      step: 1,
    },
    {
      name: "Sepia",
      property: "sepia",
      value: 0,
      range: {
        min: 0,
        max: 200,
      },
      unit: "%",
      step: 1,
    },
  ];

  const { setOptions, digitalImgLink, initialFilterValues, loadId, setLoadId } =
    useContext(FilterContext);

  const condition = [
    {
      name: "highContrast",
      value: highContrast,
    },
    {
      name: "lowContrast",
      value: lowContrast,
    },
    {
      name: "blackAndWhite",
      value: blackAndWhite,
    },

    {
      name: "orangeAndYellow",
      value: orangeAndYellow,
    },
    {
      name: "blackAndWhiteHightContrast",
      value: blackAndWhiteHightContrast,
    },
    {
      name: "hightContrastLast",
      value: hightContrastLast,
    },
  ];

  useEffect(() => {
    if (loadId !== "") {
      setDefaultChecked((preVal) => {
        for (const key in preVal) {
          if (key == loadId) {
            return { ...preVal, [key]: true };
          }
        }
      });
    }
  }, []);

  const handelChange = (name) => {
    for (let i = 0; i < condition.length; i++) {
      if (name === condition[i]?.name) {
        setOptions(condition[i]?.value);
        setDefaultChecked((preValue) => {
          for (const key in preValue) {
            preValue[key] = false;
          }
          return { ...preValue, [`image${i}`]: true };
        });
        setLoadId(`image${i}`);
      }
    }
  };

  function getImageStyle(filterArray) {
    const filters = filterArray.map((option) => {
      return `${option.property}(${option.value}${option.unit})`;
    });
    return { filter: filters.join(" ") };
  }

  const handelReset = () => {
    for (const key in defaultChecked) {
      setDefaultChecked((preVal) => {
        return { ...preVal, [key]: false };
      });
    }
    setOptions(initialFilterValues);
    setLoadId("");
  };

  return (
    <>
      <DigitalFiltersRow className={props.cname}>
        <ul className='tab_list'>
          <li
            className='tab_item'
            onClick={() => props.handleClick("digital_adjust")}>
            Adjust
          </li>
          <li className='tab_item active'>Filters</li>
        </ul>
        <div className='digital_body'>
          <SiteForm as='div'>
            <ul className='digital_filters_list'>
              <li className='item'>
                <div className='digital_filters_image' htmlFor='image'>
                  <img
                    src={digitalImgLink ? digitalImgLink : " "}
                    alt='digital_filters_image'
                    style={getImageStyle(highContrast)}
                  />
                  <CustomRadioField
                    type='radio'
                    name='radio'
                    id='image'
                    cname='input_radio'
                    checked={defaultChecked.image0}
                    onChange={() => {
                      handelChange("highContrast");
                    }}
                  />
                  {/* <Field checked /> */}
                  <Label for='image' cname='check_icon' />
                </div>
              </li>
              <li className='item'>
                <div className='digital_filters_image' htmlFor='image2'>
                  <img
                    src={digitalImgLink ? digitalImgLink : " "}
                    alt='digital_filters_image'
                    style={getImageStyle(lowContrast)}
                    loading='lazy'
                  />
                  <CustomRadioField
                    name='radio'
                    id='image2'
                    cname='input_radio'
                    checked={defaultChecked.image1}
                    onChange={() => {
                      handelChange("lowContrast");
                    }}
                  />
                  <Label for='image2' cname='check_icon' />
                </div>
              </li>
              <li className='item'>
                <div className='digital_filters_image' htmlFor='image3'>
                  <img
                    src={digitalImgLink ? digitalImgLink : " "}
                    alt='digital_filters_image'
                    style={getImageStyle(blackAndWhite)}
                    loading='lazy'
                  />
                  <CustomRadioField
                    name='radio'
                    id='image3'
                    cname='input_radio'
                    checked={defaultChecked.image2}
                    onChange={() => {
                      handelChange("blackAndWhite");
                    }}
                  />
                  <Label for='image3' cname='check_icon' />
                </div>
              </li>
              <li className='item'>
                <div className='digital_filters_image' htmlFor='image4'>
                  <img
                    src={digitalImgLink ? digitalImgLink : " "}
                    alt='digital_filters_image'
                    style={getImageStyle(orangeAndYellow)}
                    loading='lazy'
                  />
                  <CustomRadioField
                    name='radio'
                    id='image4'
                    cname='input_radio'
                    checked={defaultChecked.image3}
                    onChange={() => {
                      handelChange("orangeAndYellow");
                    }}
                  />
                  <Label for='image4' cname='check_icon' />
                </div>
              </li>
              <li className='item'>
                <div className='digital_filters_image' htmlFor='image5'>
                  <img
                    src={digitalImgLink ? digitalImgLink : " "}
                    alt='digital_filters_image'
                    style={getImageStyle(blackAndWhiteHightContrast)}
                    loading='lazy'
                  />
                  <CustomRadioField
                    name='radio'
                    id='image5'
                    cname='input_radio'
                    checked={defaultChecked.image4}
                    onChange={() => {
                      handelChange("blackAndWhiteHightContrast");
                    }}
                  />
                  <Label for='image5' cname='check_icon' />
                </div>
              </li>
              <li className='item'>
                <div className='digital_filters_image' htmlFor='image6'>
                  <img
                    src={digitalImgLink ? digitalImgLink : " "}
                    alt='digital_filters_image'
                    style={getImageStyle(hightContrastLast)}
                    loading='lazy'
                  />
                  <CustomRadioField
                    name='radio'
                    id='image6'
                    cname='input_radio'
                    checked={defaultChecked.image5}
                    onChange={() => {
                      handelChange("hightContrastLast");
                    }}
                  />
                  <Label for='image6' cname='check_icon' />
                </div>
              </li>
            </ul>
            <Button
              tag='button'
              type='button'
              cname='outline mt-5 w-full'
              buttonData={"Reset"}
              onClick={handelReset}
            />
          </SiteForm>
        </div>
      </DigitalFiltersRow>
    </>
  );
}

export default DigitalFilters;
