import React from "react";
import styled from "styled-components";
import { colors, space, siteGradient } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";
import { Container } from "../../../common/website/HOC.jsx";

import Paragraph from "../../../common/website/Paragraph.jsx";
import HeadingFour from "../../../common/website/HeadingFour.jsx";

import IconStar from "../../../images/website/Icon_Star.svg";
// import IconBlock from '../../images/Icon_block.svg';
// // import IconNFT from '../../images/Icon_NFT.svg';
// import AboutUsCircle from '../../images/about_us_circle.svg';

const LandingFeaturesTopRow = styled.section`
  padding: 0 0 ${space.space40};
  position: relative;
  .traditional_list {
    position: relative;
    li {
      padding-bottom: ${space.space40};
      flex-direction: column;
      &:hover {
        .image_box {
          &::after {
            top: 0;
            left: 0;
            transition: 0.4s;
          }
        }
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
  .traditional_school {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    order: 1;
    h4 {
      display: block;
      margin: 0;
    }
  }
  .image_box {
    background: ${colors.lightblue};
    box-shadow: ${siteGradient.boxshadowblue};
    backdrop-filter: blur(5px);
    border-radius: 8px;
    min-width: 70px;
    height: 70px;
    margin: 0 0 30px;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: 0.4s;
    &::after {
      position: absolute;
      content: "";
      top: 14px;
      left: 14px;
      min-width: 70px;
      height: 70px;
      background: #fff;
      opacity: 0.05;
      transition: 0.4s;
      border-radius: 8px;
    }
    img {
      width: 24px;
    }
  }
  .content {
    margin: 16px 0 0;
    order: 2;
    h4 {
      margin-bottom: ${space.space16};
    }
    p {
      margin-bottom: ${space.space12};

      &:last-child {
        margin: 0;
      }
    }
  }
  @media ${device.mobileM} {
    .traditional_school {
      align-items: center;
      flex-direction: row;
    }
    .image_box {
      margin: 0 30px 0 0;
    }
    .content {
      margin: 30px 0 0;
    }
  }
  @media ${device.tablet} {
    padding: 0 0 ${space.space150};
    .image_box {
      min-width: 107px;
      height: 107px;
      margin: 0 43px 0 0;
      &::after {
        min-width: 107px;
        height: 107px;
      }
      img {
        width: 42px;
      }
    }
    .blockchain {
      margin-left: 43px;
      margin-right: 0;
    }
    .content {
      margin: 0;
      order: unset;
      h4 {
        display: block;
      }
    }
    .traditional_school {
      order: unset;
      display: block;
      h4 {
        display: none;
      }
    }
    .traditional_list {
      li {
        padding-bottom: ${space.space80};
      }
    }
  }
  @media ${device.mobileM} {
    .traditional_list {
      li {
        flex-direction: row;
      }
    }
  }
`;

function LandingFeaturesTop() {
  return (
    <>
      <LandingFeaturesTopRow className='features_sec'>
        <Container>
          <ul className='traditional_list'>
            <li className='flex'>
              <div className='traditional_school'>
                <span className='image_box'>
                  <img src={IconStar} alt='Yearbook 3.0' />
                </span>
                {/* <HeadingFour
                  cname='white noMargin'
                  h4Data={"Traditional Yearbooks"}
                /> */}
              </div>
              <div className='content'>
                <HeadingFour
                  cname='white noMargin'
                  h4Data={"Traditional Yearbooks"}
                />
                <Paragraph
                  pData={
                    "Memorialize what happened at a school year-by-year and provide insight into the different activities that took place."
                  }
                />
                <Paragraph
                  cname='noMargin'
                  pData={
                    "They provide historical individual photos memorializing a time gone by. Often, they get lost, destroyed, damaged, and frankly forgotten."
                  }
                />
              </div>
            </li>
            {/* <li className="flex">
                  <div className="content">
                    <HeadingFour cname="white noMargin" h4Data={"Blockchain Technology"}/>
                    <Paragraph pData={"Blockchain is a system of recording information in a way that makes it difficult or impossible to change, hack, or cheat the system."}/>
                    <Paragraph cname="noMargin" pData={"Once a high school yearbook is created and recorded on the blockchain. It can never be lost, forged, stolen, duplicated, or destroyed. It is a 1-of-1 digital asset stored and maintained forever on blockchain technology."}/>
                  </div>
                  <div className="traditional_school">
                    <span className="image_box blockchain">
                      <img src={IconBlock} alt="Yearbook 3.0"/>
                    </span>
                    <HeadingFour cname="white noMargin" h4Data={"Blockchain Technology"}/>
                  </div>		
                </li> */}
            {/* <li className="flex">
                  <div className="traditional_school">
                    <span className="image_box">
                      <img src={IconNFT} alt="Yearbook 3.0"/>
                    </span>
                    <HeadingFour cname="white noMargin" h4Data={"Whar is a NFT?"}/>
                  </div>							
                  <div className="content">
                    <HeadingFour cname="white noMargin" h4Data={"Whar is a NFT?"}/>
                    <Paragraph pData={"A non-fungible token is a unique digital identifier that cannot be copied, substituted, or subdivided, that is recorded in a blockchain, and that is used to certify authenticity and ownership"}/>
                    <Paragraph pData={"The biggest benefit for fractional NFT owners is that they get to own a percentage of a larger, and more expensive, whole NFT. A YB3 F-NFT is like owning stock in a company, except its stock in a book. When a student purchases their yearbook, they gain an ownership share."}/>
                    <Paragraph cname="noMargin" pData={"The yearbook becomes a collectible. Depending on the student body, the yearbook can become an asset years later, especially if there is a celebrity or athlete in the graduation class."}/>
                  </div>
                </li> */}
          </ul>
        </Container>
      </LandingFeaturesTopRow>
    </>
  );
}

export default LandingFeaturesTop;
