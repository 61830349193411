import React, { useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Main } from "../../../common/website/HOC";
import styled from "styled-components";
import HeaderWithoutLogin from "../../../components/website/header/HeaderWithoutLogin";
import Footer from "../../../components/website/footer/Footer.jsx";
import * as ThemeStyle from "../../../common/website/Variables";
import { Container } from "@material-ui/core";
import HeadingTwo from "../../../common/website/HeadingTwo";
import Paragraph from "../../../common/website/Paragraph";
import List from "../../../common/website/List";
import { AppContext } from "../../../App";

const CMSSec = styled.section`
  h2 {
    padding: 40px 0;
  }
  .fw-bold {
    font-weight: 700;
    color: ${ThemeStyle.colors.primary};
    margin-top: 30px;
  }
`;

function Privacy() {
  const { setConstactUsHide } = useContext(AppContext);
  const ref = useRef(null);
  useEffect(() => {
    setConstactUsHide(false);
  }, []);

  const handleClick = () => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div>
      <HeaderWithoutLogin handleClick={handleClick} />
      <Main className='website_main'>
        <CMSSec>
          <Container>
            <HeadingTwo cname='' h2Data='Privacy Policy for YB3' />
            <Paragraph pData='At YB3, accessible from www.YB3.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by YB3 and how we use it.' />
            <Paragraph pData='If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.' />
            <Paragraph pData='This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in YB3. This policy is not applicable to any information collected offline or via channels other than this website. Our Privacy Policy was created with the help of the Free Privacy Policy Generator.' />
            <Paragraph cname='fw-bold' pData='Consent' />
            <Paragraph pData='By using our website, you hereby consent to our Privacy Policy and agree to its terms. ' />
            <Paragraph cname='fw-bold' pData='Information we collect ' />
            <Paragraph pData='The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information. ' />
            <Paragraph pData='If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.' />
            <Paragraph pData='When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number. ' />
            <Paragraph cname='fw-bold' pData='How we use your information ' />
            <Paragraph pData='We use the information we collect in various ways, including to:' />
            <ul>
              <List liData='Provide, operate, and maintain our website'></List>
              <List liData='Improve, personalize, and expand our website'></List>
              <List liData='Understand and analyze how you use our website'></List>
              <List liData='Develop new products, services, features, and functionality'></List>
              <List liData='Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes '></List>
              <List liData='Send you emails'></List>
              <List liData='Find and prevent fraud'></List>
            </ul>
            <Paragraph cname='fw-bold' pData='Log Files' />
            <Paragraph pData="YB3 follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information." />
            <Paragraph cname='fw-bold' pData='Cookies and Web Beacons' />
            <Paragraph pData="Like any other website, YB3 uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information." />
            <Paragraph
              cname='fw-bold'
              pData='Advertising Partners Privacy Policies'
            />
            <Paragraph pData='You may consult this list to find the Privacy Policy for each of the advertising partners of YB3' />
            <Paragraph pData="Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on YB3, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit." />
            <Paragraph pData='Note that YB3 has no access to or control over these cookies that are used by third-party advertisers.' />
            <Paragraph cname='fw-bold' pData='Third Party Privacy Policies' />
            <Paragraph pData="YB3's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options." />
            <Paragraph pData="You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites. " />
            <Paragraph
              cname='fw-bold'
              pData='CCPA Privacy Rights (Do Not Sell My Personal Information)'
            />
            <Paragraph pData='Under the CCPA, among other rights, California consumers have the right to: ' />
            <ul>
              <List liData="Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers."></List>
              <List liData='Request that a business delete any personal data about the consumer that a business has collected.'></List>
              <List liData="Request that a business that sells a consumer's personal data, not sell the consumer's personal data."></List>
              <List liData='If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.'></List>
            </ul>
            <Paragraph cname='fw-bold' pData='GDPR Data Protection Rights ' />
            <Paragraph pData='We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:' />
            <ul>
              <List liData='The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service. '></List>
              <List liData='The right to rectification – You has the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.'></List>
              <List liData='The right to erasure – You has the right to request that we erase your personal data, under certain conditions.'></List>
              <List liData='The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.'></List>
              <List liData='The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.'></List>
              <List liData='The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.'></List>
            </ul>
            <Paragraph pData='If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.' />
            <Paragraph cname='fw-bold' pData="Children's Information" />
            <Paragraph pData='Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.' />
            <Paragraph pData='YB3 does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.' />
          </Container>
        </CMSSec>
      </Main>
      <Footer handleClick={handleClick} />
    </div>
  );
}

export default Privacy;
