import React from "react";
import styled from "styled-components";

import { colors, space } from "./Variables.js";

const Litag = styled.li`
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.625rem;
  position: relative;
  padding-left: ${space.space20};

  // Spacing
  margin-bottom: ${space.space10};

  &.noMargin {
    margin: 0;
  }

  &::before {
    position: absolute;
    content: "";
    top: 10px;
    left: 0;
    background: ${colors.primary};
    height: 8px;
    width: 8px;
    border-radius: 50%;
  }
`;

function List(props) {
  return (
    <>
      <Litag className={props.cname}>{props.liData}</Litag>
    </>
  );
}
export default List;
