import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

function CreatePlan({
  open,
  setOpen,
  addInfo,
  setAddinfo,
  submitFunction,
  submitType,
  setSubmitType,
  currency,
}) {
  //checking positive value
  const checkPricePositive = (value) => {
    return `${value}`.match(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/);
  };
  //regex for alphabets and space
  const alphaSpace = /^[A-Za-z\s]*$/;
  //checking condition
  const checkAlphaSpace = (value) => {
    return value.match(alphaSpace);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        static
        className='fixed z-10 inset-0 overflow-y-auto'
        open={open}
        onClose={setOpen}>
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'>
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
            <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
              <div>
                <label className='block text-sm font-medium text-gray-700'>
                  Name<span className='text-red-600'>&#42;</span>
                </label>
                <input
                  type={"text"}
                  value={addInfo?.title}
                  onChange={(e) => {
                    setAddinfo((preVal) => {
                      if (
                        e?.target?.value?.trim()?.length > 0 ||
                        e?.target?.value === ""
                      ) {
                        if (checkAlphaSpace(e?.target?.value)) {
                          return {
                            ...preVal,
                            title: e.target.value,
                          };
                        } else {
                          return {
                            ...preVal,
                            title: addInfo?.title,
                          };
                        }
                      } else {
                        return {
                          ...preVal,
                          title: addInfo?.title,
                        };
                      }
                    });
                    setSubmitType(false);
                  }}
                  autoComplete='cc-given-name'
                  className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm'
                />

                {addInfo?.title === "" && submitType && (
                  <p className='text-sm text-red-500'>Please fill the field</p>
                )}

                <label className='block mt-2 text-sm font-medium text-gray-700'>
                  Description<span className='text-red-600'>&#42;</span>
                </label>
                <input
                  type={"text"}
                  value={addInfo?.description}
                  onChange={(e) =>
                    setAddinfo((preVal) => {
                      if (
                        e?.target?.value?.trim()?.length > 0 ||
                        e?.target?.value === ""
                      ) {
                        return {
                          ...preVal,
                          description: e.target.value,
                        };
                      } else {
                        return {
                          ...preVal,
                          description: addInfo?.description,
                        };
                      }
                    })
                  }
                  autoComplete='cc-given-name'
                  className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm'
                />
                {addInfo?.description === "" && submitType && (
                  <p className='text-sm text-red-500'>Please fill the field</p>
                )}

                <label className='block mt-2 text-sm font-medium text-gray-700'>
                  Plan type<span className='text-red-600'>&#42;</span>
                </label>
                <select
                  className=' className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 
                bg-white focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm'
                  onChange={(e) =>
                    setAddinfo((preVal) => {
                      return {
                        ...preVal,
                        plan_type: e.target.value,
                      };
                    })
                  }>
                  <option value=''>Select Plan type</option>
                  <option value='2'>Paid</option>
                  <option value='3'>Topup</option>
                </select>
                {addInfo?.plan_type === "" && submitType && (
                  <p className='text-sm text-red-500'>
                    Please select plan type
                  </p>
                )}

                {addInfo?.plan_type === "2" && (
                  <>
                    <label className='block mt-2 text-sm font-medium text-gray-700'>
                      Duration(In months)
                      <span className='text-red-600'>&#42;</span>
                    </label>
                    <input
                      type='text'
                      value={addInfo?.duration}
                      maxLength={5}
                      minLength={1}
                      onChange={(e) =>
                        setAddinfo((preVal) => {
                          if (
                            (checkPricePositive(e.target.value) &&
                              !isNaN(parseInt(e.target.value))) ||
                            e.target.value.toString() === ""
                          ) {
                            if (parseInt(e.target.value) !== 0) {
                              return { ...preVal, duration: e.target.value };
                            } else {
                              return { ...preVal, duration: addInfo?.duration };
                            }
                          } else {
                            return { ...preVal, duration: addInfo?.duration };
                          }
                        })
                      }
                      id='default-toggle'
                      className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm'
                    />
                    {addInfo?.duration === "" && submitType && (
                      <p className='text-sm text-red-500'>
                        Please fill the field
                      </p>
                    )}
                    {addInfo?.duration.length > 5 && submitType && (
                      <p className='text-sm text-red-500'>
                        give proper duration
                      </p>
                    )}
                  </>
                )}

                {addInfo?.plan_type !== "1" && (
                  <>
                    <label className='mt-2 block text-sm font-medium text-gray-700'>
                      Number of NFT<span className='text-red-600'>&#42;</span>
                    </label>
                    <input
                      type={"text"}
                      value={addInfo?.no_of_nft}
                      maxLength={5}
                      minLength={1}
                      onChange={(e) =>
                        setAddinfo((preVal) => {
                          if (
                            (checkPricePositive(e.target.value) &&
                              !isNaN(parseInt(e.target.value))) ||
                            e.target.value.toString() === ""
                          ) {
                            if (parseInt(e.target.value) !== 0) {
                              return { ...preVal, no_of_nft: e.target.value };
                            } else {
                              return {
                                ...preVal,
                                no_of_nft: addInfo?.no_of_nft,
                              };
                            }
                          } else {
                            return { ...preVal, no_of_nft: addInfo?.no_of_nft };
                          }
                        })
                      }
                      autoComplete='cc-given-name'
                      className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm'
                    />
                    {addInfo?.no_of_nft === "" && submitType && (
                      <p className='text-sm text-red-500'>
                        Please fill the field
                      </p>
                    )}
                  </>
                )}

                <label className='mt-2 block text-sm font-medium text-gray-700'>
                  {`Price (${currency})`}
                  <span className='text-red-600'>&#42;</span>
                </label>
                <input
                  type={"text"}
                  value={addInfo?.price}
                  maxLength={7}
                  minLength={1}
                  onChange={(e) =>
                    setAddinfo((preVal) => {
                      if (
                        (checkPricePositive(e.target.value) &&
                          !isNaN(parseInt(e.target.value))) ||
                        e.target.value.toString() === ""
                      ) {
                        if (parseInt(e.target.value) !== 0) {
                          return { ...preVal, price: e.target.value };
                        } else {
                          return { ...preVal, price: addInfo?.price };
                        }
                      } else {
                        return { ...preVal, price: addInfo?.price };
                      }
                    })
                  }
                  autoComplete='cc-given-name'
                  className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm'
                />
                {addInfo?.price === "" && submitType && (
                  <p className='text-sm text-red-500'>Please fill the field</p>
                )}

                <button
                  onClick={() => {
                    submitFunction();
                    setSubmitType(true);
                  }}
                  className='mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900'>
                  Save
                </button>
                <button
                  className='mt-2 block w-full bg-white border border-white rounded-md py-2 text-sm font-semibold text-gray-800 text-center shadow-md'
                  onClick={() => {
                    setOpen(false);
                    setSubmitType(false);
                    for (const key in addInfo) {
                      setAddinfo((preVal) => {
                        return { ...preVal, [key]: "" };
                      });
                    }
                  }}>
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default CreatePlan;
