import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";

import {
  DashboardBody,
  DashboardRightBar,
} from "../../../common/website/HOC.jsx";
import { device } from "../../../common/website/Devices";
import { colors, space, siteGradient } from "../../../common/website/Variables";
import Classmates from "../../../components/website/Classmates/Classmates.jsx";
import About from "../../../components/website/RecentActivity/About.jsx";
import ProfileHeader from "../../../components/website/ProfileHeader/ProfileHeader.jsx";
import CreatePost from "../../../components/website/CreatePost/CreatePost.jsx";
import Post from "../../../components/website/Post/Post.jsx";

import IconBluePhotograph from "../../../images/website/icon_blue_photograph.svg";
import ClubProfileImage from "../../../images/website/image_club.png";
import PostImage from "../../../images/website/post_image.png";
import PostImage1 from "../../../images/website/post_1.png";
import PostImage2 from "../../../images/website/post_2.png";
import PostImage3 from "../../../images/website/post_3.png";
import { loaderContext } from "../../../components/global loader/global-loader-provider.js";
import { PostContext } from "../../../components/website/header/MainHeader.jsx";
import {
  clubView,
  getSubscriptionDetail,
  memberList,
  teamView,
} from "../../../services/auth.services.js";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import {
  ADMIN_USER_DATA,
  GRADUATED,
  TEAM_POST,
} from "../../../utils/constant.js";
import { useNavigate } from "react-router";
import { student } from "../../../linksprovider.js";
import SchoolPost from "../../../components/website/Post/SchoolPost.jsx";

const ClubRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  .profile_top {
    /* .NFT_Balance {
      display: none !important;
    } */
    .NFT_Balance.other_profile_year {
      display: flex;
    }

    .other_profile_text {
      display: block;
      font-size: 1rem;
    }
  }
  .classmates_group {
    display: none;
  }
  h3,
  h4,
  p {
    position: static;
  }
  .sidebar_right {
    position: sticky;
    top: 144px;
    display: none;
  }
  .upload_card {
    border-radius: ${space.space16};
    padding: 64px ${space.space24};
    text-align: center;
    .add_head {
      margin: 18px 0 2px;
    }
  }
  .upload_photos {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background-color: ${colors.lightblue};
    box-shadow: ${siteGradient.boxshadowblue};
    background-image: url(${IconBluePhotograph});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40px 40px;
    cursor: pointer;
    margin: auto;
    input {
      display: none;
    }
  }
  .classmates_list {
    height: calc(100vh - 472px);
  }
  .sceen_bottom {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
  .sceen_main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-wrap: wrap;
  }
  .create_post {
    margin-top: 0;
  }
  @media ${device.mobileM} {
    .upload_photos {
      width: 100px;
      height: 100px;
      background-size: 54px 54px;
    }
    .upload_card {
      padding: ${space.space40} ${space.space24};
    }
  }
  @media ${device.tablet} {
    width: 75%;
    .profile_header {
      padding-left: ${space.space20};
    }
    .sidebar_right {
      display: block;
    }
    .mobile_hidden {
      display: block;
    }
    .club_group {
      flex-direction: column;
      align-items: flex-start;
      .count {
        font-size: 0.75rem;
        line-height: 19px;
      }
    }
    .classmates_list {
      height: calc(100vh - 516px);
    }
  }
  @media ${device.laptop} {
    width: 77%;
    .sidebar_right {
      top: 202px;
    }
    .classmates_list {
      height: calc(100vh - 566px);
    }
    .club_group {
      flex-direction: row;
      align-items: center;
    }
  }
  @media ${device.laptopL} {
    width: 80%;
    .club_group {
      .count {
        font-size: 1rem;
        line-height: 1.625rem;
      }
    }
    .classmates_list {
      height: calc(100vh - 586px);
    }
    .profile_header {
      padding-left: ${space.space40};
    }
  }
`;
function SportsClub() {
  const [clubMateData, setClubMateData] = useState([]);
  const [clubMemberCount, setClubMemberCount] = useState(0);

  const [classMatePage, setClassMatePage] = useState(1);

  const { loaderDispatch } = useContext(loaderContext);

  const scrollRef = useRef();
  let pages = classMatePage;
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }

  const {
    setUserInfo,
    gettingClassList,
    teamData,
    getAllPostDetails,
    postData,
    setTeamData,
    userInfo,
    setpostlocation,
    setClubData,
    setIsMyMoment,
    setPage,
    gettingNotification,
    handelLogout,
  } = useContext(PostContext);
  let navigate = useNavigate();

  useEffect(() => {
    getStudentProfile();
    setpostlocation(TEAM_POST);
    setClubData({});
    setIsMyMoment(false);
    setPage(1);
  }, []);

  //getting student details on page load
  const getStudentProfile = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });

      let studentData = await getSubscriptionDetail(userData);
      loaderDispatch({
        isLoading: false,
      });
      if (studentData?.data?.status === 410) {
        handelLogout();
      }
      if (studentData?.data?.status === StatusCodes.OK) {
        let data = studentData?.data?.data;
        if (!data?.sport_id || data?.sport_status === 2) {
          navigate(student?.post);
        }

        console.log("data?.sport_id,", data?.sport_id);
        getAllPostDetails({
          id: data?.User?.id,
          sport_id: data?.sport_id,
          studentId: data?.id,
          pages: 1,
        });
        getClubMatesList({ data, loader: true });
        setUserInfo(data);
        getClubDetails(data);
        gettingNotification({
          StudentInfo: data,
          notification: false,
          pages: 1,
          loader: true,
        });
      } else {
        toast(studentData?.data?.message, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Views a club.
  const getClubDetails = async (data) => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let params = {
        id: data?.sport_id,
        userId: userData?.id,
      };
      let result = await teamView(params);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        setTeamData(result?.data?.data);
      } else {
        toast(result?.data?.message, {
          type: "error",
          theme: "dark",
        });
      }
      console.log("clubView", result);
    } catch (error) {
      console.error(error);
    }
  };

  const getClubMatesList = async ({ data, loader = null, pages = null }) => {
    try {
      if (loader) {
        loaderDispatch({
          isLoading: true,
        });
      }

      let params = {
        sportId: data?.sport_id ? data?.sport_id : userInfo?.sport_id,
        schoolId: data?.User?.id ? data?.User?.id : userInfo?.User?.id,
        page: pages ? pages : 1,
        userId: data?.id ? data?.id : userInfo?.id ? userInfo?.id : "",
      };

      let result = await memberList(params);
      loaderDispatch({
        isLoading: false,
      });

      if (result?.data?.status === StatusCodes?.OK) {
        let count = result?.data?.data?.total;
        let data = result?.data?.data?.docs;
        if (data?.length > 0) {
          setClubMateData((preVal) => {
            return [...preVal, ...data];
          });
        }
        setClubMemberCount(count);
      } else {
        toast(result?.data?.message, { type: "error", theme: "dark" });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelPagination = () => {
    if (scrollRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        console.log("yesssss", scrollTop, clientHeight, scrollHeight);
        pages++;
        setClassMatePage(pages);
        let loader = 1;
        getClubMatesList({ pages, loader: false });
      }
    }
  };

  console.log("teamData", teamData);
  return (
    <>
      <ClubRow>
        <div className='sceen_main'>
          <ProfileHeader
            cname='profile_header'
            clubData={teamData}
            ClubProfileImage={teamData?.sport_profile}
            Name={teamData?.sportName}
          />
          <div className='sceen_bottom'>
            <DashboardBody className='dash_body'>
              {userInfo?.standard?.toLowerCase() !== GRADUATED && (
                <CreatePost cname='create_post' clubId={teamData?.id} />
              )}
              {postData?.length > 0 ? (
                postData?.map((val, i) => {
                  return (
                    <>
                      {" "}
                      {val?.User?.user_type === 2 ? (
                        <SchoolPost val={val} key={i} />
                      ) : (
                        <Post val={val} key={i} />
                      )}
                    </>
                  );

                  {
                    /* <Post val={val} key={i} />; */
                  }
                })
              ) : (
                <p className='flex justify-center'>Post not found..!!</p>
              )}
            </DashboardBody>
            <DashboardRightBar className='sidebar_right'>
              <About description={teamData?.description} />
              <Classmates
                classmatesData={clubMateData}
                message='No members found'
                pData={"Team members"}
                link={true}
                paginationRef={scrollRef}
                handelPagination={handelPagination}
                memberCount={clubMemberCount}
              />
            </DashboardRightBar>
          </div>
        </div>
      </ClubRow>
    </>
  );
}

export default SportsClub;
