import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { Link, useNavigate } from "react-router-dom";
import { colors, space } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  SiteForm,
  LoginCard,
  LoginCardInner,
} from "../../../common/website/HOC.jsx";

import HeadingTwo from "../../../common/website/HeadingTwo.jsx";
import Paragraph from "../../../common/website/Paragraph.jsx";
import Label from "../../../common/website/Label.jsx";
import Button from "../../../common/website/Button.jsx";

import IconCheck from "../../../images/website/icon_check_blue.svg";
import siteLogo from "../../../images/website/site_logo.svg";
import OpenEye from "../../../images/website/icon_open_eye.svg";
import CloseEye from "../../../images/website/icon_close_eye.svg";

import { loaderContext } from "../../../components/global loader/global-loader-provider";

import {
  getSubscriptionDetail,
  login,
  studentLogin,
} from "../../../services/auth.services";
import { admin, school, student, website } from "../../../linksprovider";
import { toast } from "react-toastify";
import { StatusCodes } from "http-status-codes";
import { AppContext } from "../../../App";
import {
  ADMIN_USER,
  ADMIN_USER_DATA,
  GRADUATED,
  REMEMBER_STATE_STUDENT,
  STUDENT_USER_DATA,
} from "../../../utils/constant";
import { useCookies } from "react-cookie";

const LoginRightRow = styled.div`
  h2 {
    margin-bottom: ${space.space14};
    text-align: center;
  }
  .form_group {
    margin-bottom: ${space.space24};
    position: relative;
  }
  .subtilte {
    margin-bottom: ${space.space30};
    text-align: center;
  }
  .submit-btn {
    width: 100%;
  }
  .account_link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ${space.space16};
    grid-gap: 6px;
    flex-wrap: wrap;
    p {
      font-size: 0.875rem;
      margin: 0;
    }
    .link {
      font-size: 0.875rem;
      line-height: 19px;
      color: ${colors.primary};
      padding: 0;
      &:hover {
        color: ${colors.secondary};
      }
    }
  }
  .check_group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    grid-gap: 10px;
    .left {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      white-space: nowrap;
      input[type="checkbox"] {
        width: 18px;
        height: 18px;
        cursor: pointer;
        border: 1px solid ${colors.white};
        border-radius: 5px;
        appearance: none;
        overflow: hidden;
        padding: 0;
        &:checked {
          background-image: url(${IconCheck});
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          border: 1px solid ${colors.secondary};
        }
        &[value="true"] {
          background-image: url(${IconCheck});
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          border: 1px solid ${colors.secondary};
        }
      }
      label {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1.063rem;
        padding-left: 8px;
        margin: 0;
        cursor: pointer;
      }
    }
    .right {
      .link {
        font-size: 0.75rem;
        color: ${colors.primary};
        padding: 0;
        &:hover {
          color: ${colors.secondary};
        }
      }
    }
  }
  .logo {
    text-align: center;
    margin-bottom: ${space.space20};
    display: block;
    a {
      display: inline-block;
    }
    img {
      max-width: 120px;
    }
  }
  .pass_group {
    position: relative;
    input {
      padding-right: 26px;
    }
    .eye_btn {
      content: "";
      position: absolute;
      background: url(${OpenEye}) no-repeat;
      background-size: contain;
      background-position: center;
      width: 18px;
      height: 18px;
      right: 0;
      bottom: 15px;
    }
    input[type="text"] ~ .eye_btn {
      background: url(${CloseEye}) no-repeat;
    }
  }
  @media ${device.mobileM} {
    .check_group {
      .left {
        label {
          font-size: 0.875rem;
          padding-left: 10px;
        }
        input[type="checkbox"] {
          width: 20px;
          height: 20px;
        }
      }
      .right {
        .link {
          font-size: 1rem;
        }
      }
    }
    .account_link {
      p,
      .link {
        font-size: 1rem;
      }
    }
  }
  @media ${device.mobileL} {
    .logo {
      img {
        max-width: 150px;
      }
    }
    .account_link {
      margin-top: ${space.space30};
    }
  }
  @media ${device.tablet} {
    .subtilte {
      margin-bottom: ${space.space50};
    }
    .logo {
      margin-bottom: ${space.space24};
      img {
        max-width: 168px;
      }
    }
    .form_group {
      margin-bottom: 34px;
    }
  }
  @media ${device.laptop} {
    .logo {
      display: none;
    }
  }
`;

function LoginForm(props) {
  // Password Show Hiden
  const [passwordShown, setPasswordShown] = useState(false);
  const { loaderDispatch } = useContext(loaderContext);
  const { setStudentRemember, studentRemember } = useContext(AppContext);
  const [cookies, setCookie] = useCookies([STUDENT_USER_DATA]);

  let userData;
  try {
    userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  } catch (error) {
    console.error(error);
  }
  if (!userData) {
    // userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  }

  let navigate = useNavigate();
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  // End Password Show Hiden

  // Sets the initial values for the email and password.

  useEffect(() => {
    localStorage.setItem(REMEMBER_STATE_STUDENT, studentRemember);
  }, [studentRemember]);

  const initialValues = {
    email: studentRemember ? cookies.email : "",
    password: studentRemember ? cookies.password : "",
  };

  console.log(cookies);

  async function navigateFunction(email) {
    let user = {
      email: email,
    };
    let subDetails = await getSubscriptionDetail(user);
    console.log(subDetails);
    let StudentInfo = subDetails?.data?.data;
    if (subDetails?.data?.status === StatusCodes.OK) {
      if (
        (!StudentInfo?.subscription_id ||
          StudentInfo?.is_plan_expired === "1") &&
        StudentInfo?.standard.toLowerCase() !== GRADUATED
      ) {
        navigate(student.subscribeOurPlan);
      } else if (
        StudentInfo?.subscription_id &&
        !StudentInfo?.profile_picture &&
        StudentInfo?.standard.toLowerCase() !== GRADUATED
      ) {
        navigate(student.dashbord);
      } else if (
        StudentInfo?.subscription_id &&
        StudentInfo?.profile_picture &&
        StudentInfo?.is_plan_exist === "1" &&
        (StudentInfo?.is_plan_expired === "0" ||
          StudentInfo?.standard.toLowerCase() === GRADUATED)
      ) {
        navigate(student.post);
      }
    } else {
      toast(StudentInfo?.data?.message, {
        type: "error",
        theme: "dark",
      });
    }
  }
  useEffect(() => {
    if (userData && userData.user_type === 3) {
      const navigateAfterLogin = async () => {
        await navigateFunction(userData?.email);
      };

      navigateAfterLogin();
    } else {
      navigate(website.studentLogin);
    }
  }, []);

  // Creates a new validation schema object
  const validationSchema = Yup.object({
    email: Yup.string()
      .trim()
      .email("Invalid mail")
      .required("Please fill the field"),
    password: Yup.string()
      // .password("Invalid password")
      .required("Please fill the field")
      .min(4, "Password must be at least 4 characters long"),
  });

  // Login with the given email and password.
  const onLogin = async (values, { setSubmitting }) => {
    console.log(values);

    try {
      let body = {
        email: values.email.trim(),
        password: values.password,
        user_type: 3,
      };
      loaderDispatch({
        isLoading: true,
      });

      const result = await studentLogin(body);

      loaderDispatch({
        isLoading: false,
      });

      if (result?.data?.status === StatusCodes.OK) {
        // localStorage.clear();
        sessionStorage.clear();
        if (studentRemember) {
          setCookie("email", values.email, {
            path: website.studentLogin,
            expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
          });
          setCookie("password", values.password, {
            path: website.studentLogin,
            expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
          });
        }

        console.log("studentRemember", studentRemember);
        sessionStorage.setItem(
          ADMIN_USER,
          JSON.stringify(result?.data?.data?.token)
        );
        sessionStorage.setItem(
          ADMIN_USER_DATA,
          JSON.stringify(result?.data?.data)
        );

        await navigateFunction(values.email);
      } else {
        console.log("errorMessage", result?.data?.message);
        toast(result?.data?.message, {
          type: "error",
          theme: "dark",
        });
      }
      setSubmitting(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <LoginRightRow className='right'>
        <LoginCard>
          <LoginCardInner>
            <div className='logo'>
              <Link to='/'>
                <img
                  src={siteLogo}
                  srcSet={siteLogo}
                  className='sitelogo_img'
                  alt='YearBook3.0'
                />
              </Link>
            </div>
            <HeadingTwo cname='white' h2Data={"Login"} />
            <Paragraph
              cname='subtilte'
              pData={"Welcome Back! Please enter your details"}
            />
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onLogin}>
              {({
                isSubmitting,
                /* and other goodies */
              }) => (
                <SiteForm as='div'>
                  <Form>
                    <div className='grid gap-0 md:grid-cols-1 items-center form_label_group'>
                      <div className='form_group'>
                        <Label labelData={"Email*"} />
                        <Field
                          type='email'
                          name='email'
                          placeholder='Enter your email'
                          autoComplete='off'
                        />
                        <ErrorMessage
                          name='email'
                          component='div'
                          className='text-red-500 text-sm'
                        />
                      </div>
                      <div className='form_group pass_group'>
                        <Label labelData={"Password*"} />
                        <Field
                          type={passwordShown ? "text" : "password"}
                          name='password'
                          placeholder='Enter your password'
                          autoComplete='off'
                        />
                        <button
                          type='button'
                          onClick={togglePassword}
                          className='eye_btn'></button>
                        <ErrorMessage
                          name='password'
                          component='div'
                          className='text-red-500 text-sm absolute'
                        />
                      </div>
                      <div className='form_group check_group'>
                        <div className='left'>
                          <input
                            type='checkbox'
                            name='remember'
                            id='Remember'
                            checked={studentRemember}
                            onChange={() => {
                              setStudentRemember((preVal) => {
                                return !preVal;
                              });
                            }}
                          />
                          <Label htmlFor='Remember' labelData={"Remember me"} />
                        </div>
                        <div className='right'>
                          <button
                            type='button'
                            className='link'
                            onClick={() => props.handleClick("forgot")}>
                            Forgot Password?
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className='btn_group'>
                      <Button
                        tag='button'
                        type='submit'
                        cname='gradient submit-btn'
                        buttonData={"Login"}
                        disabled={isSubmitting}
                      />
                    </div>
                  </Form>
                </SiteForm>
              )}
            </Formik>
          </LoginCardInner>
        </LoginCard>
      </LoginRightRow>
    </>
  );
}

export default LoginForm;
