import React from "react";
import styled from "styled-components";

import { colors, space } from "./Variables.js";
import Paragraph from "./Paragraph";
import { device } from "./Devices";

const ChatListRow = styled.li`
  display: flex;
  grid-gap: 6px;
  margin-bottom: ${space.space16};
  &:last-child {
    margin: 0;
  }
  .profile_image {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    object-fit: cover;
    flex-shrink: 0;
  }
  &.left_chat {
    align-items: flex-start;
    .massage_main {
      align-items: flex-start;
      .massage {
        border-radius: 0px ${space.space10} ${space.space10} ${space.space10};
        background-color: ${colors.lightwhitecolor};
        .time {
          color: ${colors.lightgrey};
        }
      }
    }
  }
  &.right_chat {
    align-items: flex-end;
    flex-direction: row-reverse;
    .massage_main {
      align-items: flex-end;
      .massage {
        border-radius: ${space.space10} ${space.space10} 0px ${space.space10};
        background-color: ${colors.secondary};
        .time {
          color: ${colors.inputbordercolor};
        }
      }
    }
  }
  .massage_main {
    display: flex;
    flex-direction: column;
    max-width: 80%;
    .massage {
      position: relative;
      padding: ${space.space16};
      margin-bottom: ${space.space16};
      max-width: max-content;
      &:last-child {
        margin: 0;
      }
      .smallParagaraph {
        margin: 0;
      }
      .time {
        position: absolute;
        right: ${space.space10};
        bottom: 4px;
        font-size: 0.625rem;
        line-height: 0.75rem;
        margin: 0;
      }
    }
  }
  @media ${device.tablet} {
    .massage_main {
      max-width: 60%;
    }
  }
  @media ${device.laptop} {
    .massage_main {
      max-width: 50%;
    }
  }
`;

function ChatList(props) {
  return (
    <>
      <ChatListRow className={props.cname}>
        <img
          src={props.ProfileImage}
          alt='profile_image'
          className='profile_image'
        />
        <div className='massage_main'>
          <div className='massage'>
            <Paragraph cname='smallParagaraph' pData={props.massage} />
            <Paragraph cname='time' pData={props.time} />
          </div>
          {/* <div className='massage'>
            <Paragraph cname='smallParagaraph' pData={props.massage2} />
            <Paragraph cname='time' pData={props.time2} />
          </div> */}
        </div>
      </ChatListRow>
    </>
  );
}
export default ChatList;
