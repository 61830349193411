import React, { useState } from "react";
import { FaSort } from "react-icons/fa";
import { BiEditAlt } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { AiFillEye, AiOutlineUserSwitch } from "react-icons/ai";
import { ADMIN_USER_DATA, DELETE, UPDATE, VIEW } from "../../utils/constant";
import ActiveStatus from "../../common/ActiveStatus";
import InActiveStatus from "../../common/InActiveStatus";
import Tooltip from "@material-ui/core/Tooltip";
import { useNavigate } from "react-router-dom";

function SportTeamListing({
  handleOptions,
  currentItems,
  tableHeader,
  handleSorting,
  type,
}) {
  const [order, setOrder] = useState(true);

  const handleSortTable = (item, orders, subValue) => {
    if (subValue) {
      handleSorting(subValue, orders);
    } else {
      handleSorting(item, orders);
    }
  };

  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }

  let navigate = useNavigate();

  return (
    <div className='p-1.5'>
      <div className='overflow-hidden border rounded-lg'>
        <table className='min-w-full divide-y divide-gray-200'>
          <thead className='bg-gray-50'>
            <tr>
              {tableHeader.map((item, key) => {
                return (
                  <th
                    key={key}
                    className='px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase max-w-xs truncate'>
                    {item.sorting ? (
                      <span className='flex'>
                        {item.key}
                        <FaSort
                          className='cursor-pointer text-md'
                          onClick={() => {
                            handleSortTable(
                              item?.value,
                              !order,
                              item?.subValue
                            );
                            setOrder(!order);
                          }}
                        />
                      </span>
                    ) : (
                      <span className='flex float-left'>{item.key}</span>
                    )}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-200'>
            {currentItems?.map((dataItem, dataKey) => {
              return (
                <tr key={dataKey}>
                  {tableHeader.map((item, key) => {
                    if (item.key === "options") {
                      return (
                        <td
                          key={key}
                          className='float-left px-5 py-4 font-normal text-gray-600 whitespace-nowrap flex justify-between max-w-xs truncate'>
                          <Tooltip title='view'>
                            <span className='p-1'>
                              <AiFillEye
                                className='cursor-pointer'
                                onClick={() => handleOptions(VIEW, dataItem)}
                              />
                            </span>
                          </Tooltip>
                          <Tooltip title='edit'>
                            <span className='p-1'>
                              <BiEditAlt
                                className='cursor-pointer'
                                onClick={() => handleOptions(UPDATE, dataItem)}
                              />
                            </span>
                          </Tooltip>
                          <Tooltip title='delete'>
                            <span className='p-1'>
                              <MdDelete
                                className='cursor-pointer'
                                onClick={() => handleOptions(DELETE, dataItem)}
                              />
                            </span>
                          </Tooltip>
                          {userData.user_type != 1 && (
                            <Tooltip title='add / remove students'>
                              <span className='p-1'>
                                <AiOutlineUserSwitch
                                  className='cursor-pointer'
                                  onClick={() => {
                                    navigate(
                                      `/school/teams/user/add/remove/${dataItem.id}`
                                    );
                                  }}
                                />
                              </span>
                            </Tooltip>
                          )}
                        </td>
                      );
                    } else if (item.key === "S.No.") {
                      return (
                        <td
                          className={
                            item?.value === "total_nft" || item?.clickable
                              ? "px-6 py-4 text-sm font-medium text-gray-600 whitespace-nowrap cursor-pointer max-w-xs  truncate"
                              : "px-6 py-4 text-sm font-medium text-gray-600 whitespace-nowrap max-w-xs truncate "
                          }
                          key={key}>
                          {dataKey + 1}
                        </td>
                      );
                    } else {
                      if (dataItem?.status === 1) {
                        dataItem["status"] = (
                          <ActiveStatus message={"Active"} />
                        );
                      } else if (dataItem?.status === 2) {
                        dataItem["status"] = (
                          <InActiveStatus message={"Inactive"} />
                        );
                      } else if (dataItem?.createdAt) {
                        dataItem["createdAt"] = dataItem?.createdAt;
                      }
                      return (
                        <td
                          className='px-6 py-4 text-sm font-medium text-gray-600 whitespace-nowrap max-w-xs truncate'
                          key={key}>
                          {dataItem?.[item?.value] &&
                          dataItem?.[item?.value]?.[item?.subValue]
                            ? dataItem?.[item?.value]?.[item?.subValue]
                            : dataItem?.[item?.value] &&
                              dataItem?.[item?.value]?.toString()?.length > 0
                            ? dataItem?.[item?.value]
                            : "--"}
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SportTeamListing;
