import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Dialog, Transition } from "@headlessui/react";
import { GrClose } from "react-icons/gr";
import { SiteCard, SiteModal, SiteForm } from "../../../common/website/HOC.jsx";
import { colors, space, siteGradient } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";

import Paragraph from "../../../common/website/Paragraph.jsx";
import LikeDislike from "../../../common/website/LikeDislike.jsx";
import Comments from "../../../common/website/Comments.jsx";
import Button from "../../../common/website/Button.jsx";
import dateDifference from "date-difference";

import IconDots from "../../../images//website/icon_dots.svg";

import IconDelete from "../../../images/website/icon_delete.svg";
import IconEdit from "../../../images/website/icon_edit.svg";
import { PostContext } from "../header/MainHeader.jsx";
import { GRADUATED } from "../../../utils/constant.js";
import blankProfile from "../../../images/website/dummy_user_profile.svg";
import { MentionsInput, Mention } from "react-mentions";
import { commentList } from "../../../services/auth.services.js";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";

const ViewCommentRow = styled.div`
  .modal_inner {
    max-width: 100% !important;
    height: 100vh;
    padding: 0;
  }
  .modal_boday {
    height: 100%;
    border-radius: 0px;
    padding: 0;
  }
  .model_top {
    margin: 0;
    padding: ${space.space20} ${space.space20} 26px;
    position: fixed;
    left: 0;
    right: 0;
    background: #0b0b0b;
    z-index: 9;
    .modal_close {
      right: 10px;
      top: 10px;
    }
  }
  .modal_content {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(172 172 172 / 10%);
      border-radius: 8px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Fire Fox */
    scrollbar-color: rgb(172 172 172 / 10%) transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: transparent;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(172 172 172 / 10%);
      border-radius: 8px;
    }
    /* End Fire Fox */
  }
  .comment_link {
    cursor: initial;
  }
  .left_content {
    padding: ${space.space50} ${space.space16} ${space.space16};
    border-bottom: 1px solid #ffffff1a;
    width: 100%;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(172 172 172 / 10%);
      border-radius: 8px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    /* Fire Fox */
    scrollbar-color: rgb(172 172 172 / 10%) transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: transparent;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(172 172 172 / 10%);
      border-radius: 8px;
    }
    /* End Fire Fox */
  }
  .right_content {
    padding: ${space.space16};
    width: 100%;
  }
  .card_head {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    grid-gap: ${space.space8};
    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: ${space.space10};
      img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 100%;
        flex-shrink: 0;
      }
      .profile_name {
        margin: 0 0 2px;
      }
      .time {
        font-size: 0.75rem;
        line-height: 0.938rem;
        margin: 0;
        color: ${colors.lightgrey2};
      }
    }
  }
  .description {
    position: static;
    font-size: 0.75rem;
    margin: ${space.space10} 0 ${space.space20};
  }
  .menu_dropdown {
    position: relative;
    .menu_list {
      position: absolute;
      top: 100%;
      background: ${colors.body};
      box-shadow: ${siteGradient.cardlightboxshadow};
      border-radius: ${space.space8};
      width: 110px;
      transform: translateX(-80px);
      overflow: hidden;
      padding: ${space.space14} 0;
      z-index: 1;
    }
    .link {
      display: flex;
      align-items: center;
      grid-gap: ${space.space8};
      font-size: 0.875rem;
      line-height: 1.063rem;
      padding: ${space.space8} 22px;
      width: 100%;
      &:hover {
        background: ${colors.primary};
      }
    }
  }
  .post_card {
    height: 450px;
    border-radius: ${space.space16};
    padding: ${space.space10};
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: auto;
      object-fit: cover;
      border-radius: ${space.space16};
      height: 100%;
    }
  }
  .action_group,
  .comment_group {
    display: flex;
    align-items: center;
  }
  .action_group {
    grid-gap: ${space.space20};
    margin: 0 0 ${space.space10};
  }
  .comment_group {
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    grid-gap: ${space.space10};
    border-bottom: 1px solid ${colors.primary};
    padding-bottom: ${space.space16};
    margin-bottom: ${space.space16};
    .user_count {
      font-size: 12px;
      line-height: 15px;
      color: ${colors.lightgrey2};
      margin: 0;
    }
  }
  .comment_form {
    padding-top: ${space.space16};
    margin-top: ${space.space16};
    border-top: 1px solid #ffffff1a;
    input {
      font-size: 0.875rem;
      border: none;
      padding: 0;
      padding-right: ${space.space40};
      &::placeholder {
        font-size: 0.875rem;
      }
    }
    .form_group {
      position: relative;
      z-index: 1;
      .post_btn {
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 0;
        font-size: 0.875rem;
        opacity: 0.3;
        pointer-events: none;
        &.show {
          pointer-events: auto;
          opacity: 1;
        }
        &:hover {
          color: ${colors.secondary};
        }
      }
    }
  }
  .mention {
    textarea {
      display: none !important;
    }
    strong {
      font-family: "Inter" !important;
      background-color: #0b0b0b;
      color: #00e1f0;
    }
    span {
      font-family: "Inter" !important;
      color: white;
      visibility: visible !important;
    }
  }
  .comment_list {
    height: 290px;
    overflow-y: auto;
    padding-right: ${space.space8};
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(172 172 172 / 10%);
      border-radius: 8px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    .list_item {
      display: flex;
      align-items: flex-start;
      grid-gap: ${space.space12};
      margin-bottom: ${space.space24};
      &:last-child {
        margin: 0;
      }
    }
    .profile_image {
      width: 30px;
      height: 30px;
      object-fit: cover;
      border-radius: 100%;
      flex-shrink: 0;
    }
    .detail {
      position: relative;
      width: 100%;
      .name {
        margin-bottom: 4px;
        padding-right: ${space.space40};
      }
      .massages {
        margin-bottom: ${space.space8};
        color: ${colors.lightgrey2};
      }
      .reply_link,
      .time {
        font-size: 0.75rem;
        line-height: 0.938rem;
      }
      .reply_link {
        color: ${colors.primary};
        cursor: pointer;
        &:hover {
          color: ${colors.secondary};
        }
      }
      .time {
        position: absolute;
        top: 0;
        right: 0;
        color: ${colors.lightgrey};
      }
    }
  }
  @media ${device.mobileM} {
    .left_content {
      padding: ${space.space50} ${space.space20} ${space.space20};
    }
    .right_content {
      padding: ${space.space20};
    }
    .comment_group {
      align-items: center;
      flex-direction: row;
      padding-bottom: ${space.space24};
      margin-bottom: ${space.space24};
    }
    .action_group {
      grid-gap: ${space.space30};
    }
    .card_head {
      .profile_name {
        margin: 0 0 6px;
      }
      .left {
        grid-gap: 22px;
        img {
          width: 55px;
          height: 55px;
        }
      }
    }
    .description {
      font-size: 0.875rem;
    }
    .post_card {
      padding: ${space.space24};
    }
    .comment_form {
      padding-top: ${space.space20};
      margin-top: ${space.space20};
      input {
        font-size: 1rem;
      }
      &::placeholder {
        font-size: 1rem;
      }
      .form_group {
        .post_btn {
          font-size: 1rem;
        }
      }
    }
  }
  @media ${device.tablet} {
    .model_top {
      position: static;
      background: transparent;
      padding: 0;
    }
    .post_card {
      height: 620px;
    }
    .modal_content {
      flex-direction: row;
      overflow-y: hidden;
    }
    .left_content {
      border-bottom: none;
      border-right: 1px solid #ffffff1a;
      padding: ${space.space40} ${space.space20};
      width: 55%;
      overflow-y: auto;
    }
    .right_content {
      padding: ${space.space40} ${space.space20};
      width: 45%;
    }
    .comment_list {
      height: calc(100vh - 234px);
    }
  }
  @media ${device.laptop} {
    .model_top {
      .modal_close {
        right: 17px;
        top: 17px;
      }
    }
    .left_content {
      padding: ${space.space40};
      width: 60%;
    }
    .right_content {
      padding: ${space.space40};
      width: 40%;
    }
  }
  @media ${device.laptopL} {
    .comment_list {
      height: calc(100vh - 275px);
    }
    .left_content {
      padding: ${space.space70} ${space.space40} ${space.space50}
        ${space.space70};
    }
    .right_content {
      padding: ${space.space70} ${space.space70} ${space.space50}
        ${space.space40};
    }
  }
`;

function CreateNewPostModal({
  openCommentModal,
  setOpenCommentModal,
  val,
  handelPostComment,
  setComment,
  comment,
  handelDelete,
  handelOpenEditModal,
  postTime,
  postId,
  getCommentList,
  commentData,
  page,
  setPage,
}) {
  const mentionStyle = {
    control: {
      backgroundColor: "#0b0b0b",
      fontSize: " 0.875rem",
      // fontWeight: 'normal',
      // margin: "0.625rem 0 1.25rem",
      lineHeight: "1.313rem",
      position: "static",
    },
    "&multiLine": {
      control: {
        fontFamily: "monospace",
        minHeight: 63,
      },
      highlighter: {
        padding: 9,
        border: "1px solid transparent",
      },
      input: {
        padding: 9,
        // border: "1px solid silver",
      },
    },
    "&singleLine": {
      display: "inline-block",
      width: 180,
      highlighter: {
        padding: 1,
        border: "2px inset transparent",
      },
      input: {
        padding: 1,
        border: "2px inset",
      },
    },
    suggestions: {
      list: {
        backgroundColor: "black",
        border: "1px solid rgba(0,0,0,0.15)",
        fontSize: 16,
      },
      item: {
        padding: "5px 15px",
        borderBottom: "1px solid rgba(0,0,0,0.15)",
        "&focused": {
          backgroundColor: "#00e1f0",
        },
      },
    },
  };
  const scrollRef = useRef();
  let pages = page;

  // Post Dropdown
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };

  //comment data
  // End Post Dropdown
  const { userInfo, isMyMoment } = useContext(PostContext);

  const handelReplyOnComment = async (commentInfo) => {
    setComment(`${commentInfo?.User?.student_name} `);
  };

  useEffect(() => {
    if (openCommentModal) {
      getCommentList();
      setPage(1);
    }
  }, [openCommentModal]);

  // SHOW LIKE CAPTION DATA
  const handelLikeData = (val) => {
    if (val?.Likes.length === 1) {
      if (val?.Likes[0]?.User?.id === userInfo?.id) {
        return `${val?.total_likes} Like`;
      } else {
        return `Liked by ${val?.Likes[0]?.User?.student_name}`;
      }
    } else {
      if (val?.Likes[0]?.User?.id === userInfo?.id) {
        return `${val?.total_likes} Like`;
      } else {
        return `Liked by ${val?.Likes[0]?.User?.student_name} and ${
          val?.total_likes - 1
        } others`;
      }
    }
  };

  const commentTime = (val) => {
    let date = new Date(val?.createdAt);
    let vals = dateDifference(new Date(val?.createdAt), new Date(new Date()), {
      compact: true,
    });
    let vals2 = vals.slice(1);

    if (vals2 === "1d") {
      return `yesterday ${date?.getHours()}:${date?.getMinutes()} `;
    } else if (vals2?.includes("ms")) {
      return `few seconds ago`;
    } else if (vals2?.includes("m")) {
      return `${vals2} ago`;
    } else if (vals2?.includes("s")) {
      return `few seconds ago`;
    } else if (vals2?.includes("h")) {
      return `${vals2} ago`;
    } else {
      return `${date?.getDate()}-${
        date?.getMonth() + 1
      }-${date.getFullYear()} ${date?.getHours()}:${date?.getMinutes()} `;
    }

    // console.log(val);
  };

  const handelPagination = () => {
    if (scrollRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        pages++;
        setPage(pages);
        let loader = 1;
        getCommentList(pages);
      }
    }
  };
  return (
    <Transition.Root show={openCommentModal}>
      <Dialog
        as='div'
        static
        open={openCommentModal}
        onClose={setOpenCommentModal}>
        <ViewCommentRow>
          <SiteModal>
            <Transition.Child
              className='h-screen'
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
              <div className='modal_inner'>
                <div className='modal_boday'>
                  <div className='model_top'>
                    <span
                      className='modal_close'
                      onClick={() => setOpenCommentModal(false)}>
                      {" "}
                      <GrClose />
                    </span>
                  </div>
                  <div className='modal_content'>
                    <div className='left_content'>
                      <div className='card_head'>
                        <div className='left'>
                          <img
                            src={
                              val?.User?.filtered_image
                                ? val?.User?.filtered_image
                                : blankProfile
                            }
                            alt='Profile_Image'
                          />
                          <div className='detail'>
                            <Paragraph
                              cname='profile_name'
                              pData={val?.User?.student_name}
                            />
                            <Paragraph cname='time' pData={postTime()} />
                          </div>
                        </div>
                        <div className='menu_dropdown'>
                          {userInfo?.id === val?.User?.id &&
                            userInfo?.standard?.toLowerCase() !== GRADUATED &&
                            userInfo?.is_plan_expired !== "1" && (
                              <button onClick={handleOpen}>
                                <img src={IconDots} alt='icon dots' />
                              </button>
                            )}
                          {open ? (
                            <ul className='menu_list text-white'>
                              <li className='menu-item'>
                                <button
                                  className='link'
                                  onClick={handelOpenEditModal}>
                                  <img src={IconEdit} alt='edit' /> Edit
                                </button>
                              </li>
                              <li className='menu-item'>
                                <button className='link' onClick={handelDelete}>
                                  <img src={IconDelete} alt='close' /> Delete
                                </button>
                              </li>
                            </ul>
                          ) : null}
                        </div>
                      </div>

                      {!isMyMoment ? (
                        <Paragraph
                          cname='smallParagaraph description'
                          pData={val?.description}
                        />
                      ) : (
                        <span className='mention'>
                          <MentionsInput
                            name='caption'
                            style={mentionStyle}
                            rows='6'
                            disabled
                            value={val?.description ? val.description : ""}>
                            <Mention
                              trigger='@'
                              // data={userData}

                              appendSpaceOnAdd={true}
                            />
                          </MentionsInput>
                        </span>
                      )}

                      <SiteCard className='post_card'>
                        {/* {console.log(val?.postType)} */}
                        {val?.postType == 1 ? (
                          <img
                            src={val?.postName}
                            alt='Post Image'
                            className='post_image'
                          />
                        ) : (
                          <video
                            src={val?.postName}
                            controls
                            className='post_image'></video>
                        )}
                      </SiteCard>
                    </div>
                    <div className='right_content'>
                      <div className='action_group'>
                        {userInfo?.standard?.toLowerCase() !== GRADUATED &&
                          userInfo?.is_plan_expired !== "1" && (
                            <>
                              <LikeDislike val={val} userInfo={userInfo} />
                              <Comments />
                            </>
                          )}
                      </div>
                      <div className='comment_group'>
                        {val?.Likes?.length > 0 && (
                          <Paragraph
                            cname='user_count'
                            pData={handelLikeData(val)}
                          />
                        )}
                      </div>
                      <ul
                        className='comment_list'
                        ref={scrollRef}
                        onScroll={handelPagination}>
                        {commentData?.length > 0 ? (
                          commentData?.map((commentDetails, i) => {
                            return (
                              <li className='list_item' key={i}>
                                <img
                                  src={
                                    commentDetails?.User?.filtered_image
                                      ? commentDetails?.User?.filtered_image
                                      : blankProfile
                                  }
                                  alt='profile_image'
                                  className='profile_image'
                                />
                                <div className='detail'>
                                  <Paragraph
                                    cname='name'
                                    pData={commentDetails?.User?.student_name}
                                  />
                                  <Paragraph
                                    cname='massages smallParagaraph'
                                    pData={commentDetails?.comment}
                                  />
                                  {/* <Link
                                    className='reply_link'
                                    onClick={() =>
                                      handelReplyOnComment(commentDetails)
                                    }>
                                    Reply
                                  </Link> */}
                                  <span className='time'>
                                    {commentTime(commentDetails)}
                                  </span>
                                </div>
                              </li>
                            );
                          })
                        ) : (
                          <p className='text-white'>No comments found..!!</p>
                        )}
                      </ul>
                      {userInfo?.standard?.toLowerCase() !== GRADUATED &&
                        userInfo?.is_plan_expired !== "1" && (
                          <SiteForm as='div' className='comment_form'>
                            <div className='form_group'>
                              <input
                                type='text'
                                name='text'
                                placeholder='Add a Comment'
                                value={comment}
                                onChange={(e) => {
                                  setComment(e.target.value);
                                }}
                              />
                              <Button
                                tag='button'
                                cname={
                                  comment?.length > 0
                                    ? "post_btn show"
                                    : "post_btn"
                                }
                                buttonData={"Post"}
                                onClick={handelPostComment}
                              />
                            </div>
                          </SiteForm>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </SiteModal>
        </ViewCommentRow>
      </Dialog>
    </Transition.Root>
  );
}
export default CreateNewPostModal;
