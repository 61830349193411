import React from "react";
import {
  DashboardBody,
  DashboardRightBar,
} from "../../../common/website/HOC.jsx";

import MomentsImageNFT from "../../../common/website/MomentsImageNFT.jsx";
import NFTBalance from "../../../images/website/moment_img_nft.png";
import NFTBalance2 from "../../../images/website/moment_img_nft2.png";
import NFTBalance3 from "../../../images/website/moment_img_nft3.png";
import NFTBalance4 from "../../../images/website/moment_img_nft4.png";
import NFTBalance5 from "../../../images/website/moment_img_nft5.png";
import NFTBalance6 from "../../../images/website/moment_img_nft6.png";

function NFTMoments(props) {
  console.log("sssssss", props?.nftPostData);
  return (
    <>
      <DashboardBody className='dash_body'>
        <div className='tab_body'>
          <ul className='tab_list'>
            <li
              className='tab_item'
              onClick={() => props.handleClick("my_moments")}>
              My Moment
            </li>
            {/* <li
              className='tab_item'
              onClick={() => props.handleClick("trade_moments")}>
              Trade Moments
            </li> */}
            <li
              className='tab_item'
              onClick={() => props.handleClick("digital_art")}>
              Digital Art Gallery
            </li>
            <li className='tab_item active'>NFT</li>
          </ul>
          <div
            className='moment_list_block'
            ref={props?.scrollRef}
            onScroll={props?.handelScroll}>
            <div className='moments_list'>
              {props?.nftPostData?.length > 0 && props?.nftPostData ? (
                props?.nftPostData?.map((value, i) => {
                  return (
                    <MomentsImageNFT MomentsImageNFT={value?.name} key={i} />
                  );
                })
              ) : (
                <p>NFT posts not found..!!</p>
              )}
            </div>
          </div>
        </div>
      </DashboardBody>
      <DashboardRightBar className='sidebar_right'></DashboardRightBar>
    </>
  );
}

export default NFTMoments;
