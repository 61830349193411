import React, { useState, useEffect, useContext } from "react";
import ListLayout from "../../components/admin club/SportTeamListing";
import {
  DELETE,
  UPDATE,
  VIEW,
  SCHOOL_CLUB,
  ADMIN_USER_DATA,
  SCHOOL_SPORTS_CLUB,
} from "../../utils/constant";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import {} from "../../linksprovider.js";
import { loaderContext } from "../../components/global loader/global-loader-provider.js";
import {
  clubDelete,
  clubListing,
  clubUpdate,
  clubView,
  schoolClubListing,
  schoolTeamListing,
  teamDelete,
  teamUpdate,
  teamView,
} from "../../services/auth.services.js";
import { StatusCodes } from "http-status-codes";
import AdminClubEdit from "../admin club managment/AdminClubEdit.jsx";
import { toast } from "react-toastify";
import AdminDeleteModel from "../../common/AdminDeleteModel.jsx";
import CreateTeamModal from "../school club managment/CreateTeamModal.jsx";
import { AppContext } from "../../App.js";
import { SchoolContext } from "../../common/SchoolDashboardWrapper.jsx";
import SearchFilter from "../../components/search_filter/SearchFilter.jsx";
import TeamClubEdit from "../admin club managment/TeamClubEdit.jsx";
// import { AdminContext } from "../../common/DashboardWrapper.jsx";

function SchoolSportClubManagment() {
  const [data, setData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [viewData, setViewData] = useState({});
  const [sortby, setSortby] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentItems, setCurrentItems] = useState(null);
  const [dataCount, setDataCount] = useState(1);
  const [itemOffset, setItemOffset] = useState(1);
  const [orderby, setOrderby] = useState(true);
  const [editData, setEditData] = useState({});
  const [openEditModal, setOpenEditModal] = useState(false);
  const [error, setError] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedData, setDeletedData] = useState(0);
  const [dataToUpdate, setDataToUpdate] = useState({});
  const [openCreateClubModal, setOpenCreateClubModal] = useState(false);

  let navigate = useNavigate();
  const { loaderState, loaderDispatch } = useContext(loaderContext);
  const { setAdminActiveTab } = useContext(SchoolContext);
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }
  const tableHeader = [
    { key: "S.No.", value: "key" },
    // {
    //   key: "School Name",
    //   subValue: "User",
    //   value: "school_name",
    // },
    { key: "Team Name", value: "sportName", clickable: false, sorting: true },
    { key: "Status", value: "status", clickable: false, sorting: true },

    { key: "Created On", value: "createdAt", clickable: false, sorting: true },
    // { key: "Created By", value: "created_by" },
    {
      key: "Total Member",
      value: "total_members",
      clickable: false,
      sorting: false,
    },
    {
      key: "Total Post",
      value: "total_post",
      clickable: false,
      sorting: false,
    },

    {
      key: "options",
      value: "options",
    },
  ];

  const option = [
    {
      name: "Select Status",
      value: editData?.status == 1 ? "1" : "2",
    },
    {
      name: "Active",
      value: "1",
    },
    {
      name: "Inactive",
      value: "2",
    },
  ];

  const edit = [
    {
      label: "Team Name",
      type: "text",
      value: dataToUpdate?.sportName,
      name: "sportName",
      maxLength: "60",
      minLength: "0",
    },
  ];

  const itemsOnPage = [
    { option: 10 },
    { option: 20 },
    { option: 50 },
    { option: 70 },
    { option: 100 },
  ];

  // useEffect(() => {
  //   setAdminActiveTab(CLUB);
  // }, []);

  useEffect(() => {
    const debounce = setTimeout(() => {
      handelClubListing();
    }, 300);
    setAdminActiveTab(SCHOOL_SPORTS_CLUB);
    return () => {
      clearTimeout(debounce);
    };
  }, [searchItem, sortby, itemOffset, itemsPerPage, orderby]);

  useEffect(() => {
    setCurrentItems(data);
    setPageCount(Math.ceil(dataCount / itemsPerPage));
  }, [data, dataCount, itemsPerPage]);

  //function to get selected options
  const handleOptions = (optionValue, item) => {
    console.log("test", optionValue, item);
    switch (optionValue) {
      case VIEW:
        navigate(`/school/team/view/${item.id}`);
        break;
      case UPDATE:
        setOpenEditModal(true);
        studentEdit(item.id);
        break;

      case DELETE:
        setOpenDeleteModal(true);
        setDeletedData(item.id);
        break;

      default:
        break;
    }
  };

  //function to get club list
  const handelClubListing = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let order = orderby ? "desc" : "asc";

      let body = {
        id: userData.id,
        search: searchItem.trim(),
        sort: sortby,
        page: itemOffset,
        limit: itemsPerPage,
        order: order,
      };

      let result = await schoolTeamListing(body);
      // console.log(result);

      loaderDispatch({
        isLoading: false,
      });
      setData([]);
      setDataCount(0);
      if (result?.data?.status === StatusCodes.OK) {
        // console.log(result);
        const studentData = result?.data?.data?.rows;
        const studentDataCount = result?.data?.data?.count;
        console.log(studentData);
        for (let i = 0; i < studentData.length; i++) {
          studentData[i].total_members = studentData[i]?.total_members + "";
          studentData[i].total_post = studentData[i]?.total_post + "";
        }
        setData(studentData);
        setDataCount(studentDataCount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //handel shorting
  const handleSorting = (data, order) => {
    console.log("sorting works", data);
    setSortby(data);
    setOrderby(order);
  };

  //handel pagination
  const handlePageClick = (event) => {
    let newOffset = (event.selected * itemsPerPage) % dataCount;
    console.log("pagination", newOffset);
    // dataCall(body);
    setItemOffset(newOffset / 10 + 1);
  };
  console.log("ss", dataToUpdate);
  //function to update club data
  const updateData = async () => {
    for (const key in dataToUpdate) {
      console.log("error", key);
      if (dataToUpdate[key] === "") {
        setError((preVal) => {
          return { ...preVal, [key]: true };
        });
      }
    }

    // console.log(checkError.length, data.length, checkError);
    console.log("dataToUpdate", dataToUpdate);
    let data = {
      sportName: dataToUpdate?.sportName.trim(),
      sport_profile: dataToUpdate?.sport_profile,
      description: dataToUpdate?.description.trim(),
      status: dataToUpdate?.status,
    };
    let formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }

    if (dataToUpdate?.sportName !== "" && dataToUpdate?.description !== "") {
      loaderDispatch({
        isLoading: true,
      });
      let result = await teamUpdate(editData.id, formData);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        let item = result?.data?.data;
        console.log("item updated", item);
        setEditData(item);
        setOpenEditModal(false);
        handelClubListing();
        toast(result?.data?.message, {
          type: "success",
        });
      } else {
        setOpenEditModal(false);
        handelClubListing();
        toast(result?.data?.message, {
          type: "error",
        });
      }
    }
  };

  //handel deletion of club
  const deleteData = async () => {
    try {
      let result = await teamDelete(deletedData);

      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
        });
        setOpenDeleteModal(false);
        handelClubListing();
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
      setOpenDeleteModal(false);
    }
  };

  const studentEdit = async (id) => {
    console.log("asdfafd", id);
    loaderDispatch({
      isLoading: true,
    });
    let param = {
      id: id,
    };
    let result = await teamView(param);
    console.log(result);

    if (result?.data?.status === StatusCodes.OK) {
      loaderDispatch({
        isLoading: false,
      });
      let item = result?.data?.data;
      console.log("ITEM", item);
      setEditData(item);
      for (const key in item) {
        setError((preVal) => {
          return { ...preVal, [key]: false };
        });
      }
    }
  };

  //api to get all data

  return (
    <div className=''>
      <div className='p-2 flex justify-between'>
        <SearchFilter
          setSearchItem={setSearchItem}
          searchItem={searchItem}
          setPage={setItemOffset}
          cname={
            "mt-1 block w-1/2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
          }
        />

        <button
          className=' text-sm mr-1 px-4 py-2 bg-indigo-500 mt-1 block text-white rounded shadow-md font-semibold'
          onClick={() => setOpenCreateClubModal(true)}>
          Create Team
        </button>
      </div>

      <CreateTeamModal
        openCreateClubModal={openCreateClubModal}
        setOpenCreateClubModal={setOpenCreateClubModal}
        id={userData.id}
        handelClubListing={handelClubListing}
      />

      <TeamClubEdit
        viewData={editData}
        openViewModal={openEditModal}
        setOpenViewModal={setOpenEditModal}
        view={edit}
        setEditData={setEditData}
        updateData={updateData}
        error={error}
        setError={setError}
        option={option}
        setDataToUpdate={setDataToUpdate}
        dataToUpdate={dataToUpdate}
      />
      <AdminDeleteModel
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        deleteData={deleteData}
        header={"Delete Team"}
      />
      <ListLayout
        handleOptions={handleOptions}
        tableHeader={tableHeader}
        handleSorting={handleSorting}
        currentItems={currentItems}
        type={true}
      />
      <div className='flex justify-between '>
        {data?.length > 0 && (
          <>
            <div
              className='block lg:inline-block lg:mt-0'
              style={{ marginTop: "5px" }}>
              <label htmlFor='item' className='ml-5'>
                Record Per Page:
              </label>
              <select
                className='ml-3 bg-white border p-2 rounded'
                name='item'
                id='item'
                onChange={(e) => {
                  setItemsPerPage(parseInt(e.target.value));
                }}>
                {itemsOnPage.map((val, i) => {
                  return (
                    <option value={val.option} key={i}>
                      {val.option}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className='mt-2'>
              <ReactPaginate
                breakLabel='...'
                nextLabel='Next >'
                onPageChange={handlePageClick}
                pageCount={pageCount}
                previousLabel='< Previous'
                renderOnZeroPageCount={null}
                containerClassName={"pagination"}
                pageClassName={"pageClass"}
                forcePage={itemOffset - 1}
              />
            </div>
          </>
        )}
      </div>
      {!data.length && !loaderState.isLoading ? (
        <div className='flex absolute left-1/2 top-1/2 mx-auto'>
          Data not found..!!
        </div>
      ) : null}
    </div>
  );
}

export default SchoolSportClubManagment;
