import React, { useState, useContext, Fragment } from "react";
import { FaSort } from "react-icons/fa";
import { BiEditAlt } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { AiFillEye } from "react-icons/ai";
import { DELETE, UPDATE, VIEW } from "../../utils/constant";
import Tooltip from "@material-ui/core/Tooltip";

function AdminCmsListLayout({
  handleOptions,
  currentItems,
  tableHeader,
  handleSorting,
}) {
  const [order, setOrder] = useState(true);

  const handleSortTable = (item, orders) => {
    handleSorting(item, orders);
  };
  return (
    <div className='p-1.5'>
      <div className='overflow-hidden border rounded-lg'>
        <table className='min-w-full divide-y divide-gray-200'>
          <thead className='bg-gray-50'>
            <tr>
              {tableHeader.map((item, key) => {
                return (
                  <th
                    key={key}
                    className='px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase max-w-xs truncate'>
                    {item.sorting ? (
                      <span className='flex'>
                        {item.key}
                        <FaSort
                          className='cursor-pointer text-md'
                          onClick={() => {
                            handleSortTable(item.value, !order);
                            setOrder(!order);
                          }}
                        />
                      </span>
                    ) : (
                      <span className='flex float-left'>{item.key}</span>
                    )}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-200'>
            {currentItems?.map((dataItem, dataKey) => {
              return (
                <tr key={dataKey}>
                  {tableHeader.map((item, key) => {
                    if (item.key === "options") {
                      return (
                        <td
                          key={key}
                          className='float-left px-5 py-4 font-normal text-gray-600 whitespace-nowrap flex justify-between max-w-xs truncate'>
                          <Tooltip title='view'>
                            <span className='p-2'>
                              <AiFillEye
                                className='cursor-pointer'
                                onClick={() => handleOptions(VIEW, dataItem)}
                              />
                            </span>
                          </Tooltip>
                          <Tooltip title='edit'>
                            <span className='p-2'>
                              <BiEditAlt
                                className='cursor-pointer'
                                onClick={() => handleOptions(UPDATE, dataItem)}
                              />
                            </span>
                          </Tooltip>
                          {/* <span className="p-2">
                            <MdDelete
                              className="cursor-pointer"
                              onClick={() => handleOptions(DELETE, dataItem)}
                            />
                          </span> */}
                        </td>
                      );
                    } else if (item.key === "S.No.") {
                      return (
                        <td
                          className={
                            item?.value === "total_nft" || item?.clickable
                              ? "px-6 py-4 text-sm font-medium text-gray-600 whitespace-nowrap cursor-pointer max-w-xs  truncate"
                              : "px-6 py-4 text-sm font-medium text-gray-600 whitespace-nowrap max-w-xs truncate "
                          }
                          key={key}>
                          {dataKey + 1}
                        </td>
                      );
                    } else {
                      return (
                        <td
                          className='px-6 py-4 text-sm font-medium text-gray-600 whitespace-nowrap max-w-xs truncate'
                          key={key}>
                          {dataItem[item.value]}
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AdminCmsListLayout;
