import React, { useContext, useEffect, useState } from "react";
import ListLayout from "../../shared/listLayout.jsx";
import {
  ADMIN_USER_DATA,
  NFT,
  REPORTS,
  TASKREPORTS,
} from "../../../utils/constant.js";
import DateFilter from "../../date_filter/DateFilter.jsx";
import SearchFilter from "../../search_filter/SearchFilter.jsx";
import DownloadButton from "../../download_button/DownloadButton.jsx";
import ReactPaginate from "react-paginate";
import { StatusCodes } from "http-status-codes";
import {
  adminStudentTaskListing,
  downloadCSV,
  getSchoolList,
  viewSchoolDetails,
} from "../../../services/auth.services.js";
import { loaderContext } from "../../global loader/global-loader-provider.js";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useNavigate } from "react-router-dom";
import SelectFilter from "../../school_select_filter/SelectFilter.jsx";
import { toast } from "react-toastify";
import { SchoolContext } from "../../../common/SchoolDashboardWrapper.jsx";

function SchoolTaskReport() {
  const [sortby, setSortby] = useState("createdAt");
  const [orderby, setOrderby] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [Taskfilter, setTaskfilter] = useState(null);
  const [dataCount, setDataCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  const [selectedField, setSelectedField] = useState(null);
  const [textValue, SettextValue] = useState(null);

  const [CustomFieldSetstartdate, handleCustomFieldSetstartdate] =
    useState(null);

  const [CustomFieldSetenddate, handleCustomFieldSetenddate] = useState(null);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [data, setData] = useState([]);
  const [sendingDate, setSendingDate] = useState({
    startDate: "",
    endDate: "",
  });

  const [searchItem, setSearchItem] = useState("");
  const [open, setOpen] = useState(false);

  const [fileLink, setFileLink] = useState(null);

  const tableHeader = [
    {
      key: "S.No.",
      value: "key",
      clickable: false,
      sorting: false,
    },
    {
      key: "Task Name",
      value: "title",
      clickable: false,
      sorting: true,
    },
    {
      key: "CompletedUserCount",
      value: "completedTodosCount",
      clickable: false,
      sorting: true,
    },
    {
      key: "IncompleteTodosCount",
      value: "incompleteTodosCount",
      clickable: false,
      sorting: true,
    },
    {
      key: "StartDate",
      value: "startDate",
      clickable: false,
      sorting: true,
    },
    {
      key: "DueDate",
      value: "dueDate",
      clickable: false,
      sorting: true,
    },

    { key: "status", value: "status", clickable: false, sorting: true },
  ];

  const itemsOnPage = [
    { label: 10, value: "10" },
    { label: 20, value: "20" },
    { label: 50, value: "50" },
    { label: 70, value: "70" },
    { label: 100, value: "100" },
  ];

  const filtertask = [
    { label: "All", value: null },
    { label: "Incomplate task", value: false },
    { label: "Complate task", value: true },
  ];

  const { setAdminActiveTab } = useContext(SchoolContext);
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }

  const { loaderDispatch } = useContext(loaderContext);
  let navigate = useNavigate();

  const [fields, setFields] = useState([
    // Your initial field objects go here
  ]);

  useEffect(() => {
    setAdminActiveTab(TASKREPORTS);
  }, []);

  useEffect(() => {
    const debunce = setTimeout(() => {
      handleStudentData();
      getSchoolDetails();
      // setItemOffset(1);
    }, 300);

    return () => {
      clearTimeout(debunce);
    };
  }, [
    searchItem,
    sortby,
    orderby,
    itemOffset,
    itemsPerPage,
    sendingDate,
    Taskfilter,
    CustomFieldSetstartdate,
    CustomFieldSetenddate,
    textValue,
  ]);

  const getSchoolDetails = async () => {
    loaderDispatch({
      isLoading: true,
    });

    let result = await viewSchoolDetails(userData.id);
    loaderDispatch({
      isLoading: false,
    });

    let schooluserData = result?.data?.data;
    if (schooluserData?.customFields) {
      const updatedFields = schooluserData?.customFields.map((item) => ({
        columnName: item.columnName,
        columnType: item.columnType,
        value: item.value,
        id: item.id,
      }));

      if (updatedFields?.length > 0) {
        setFields(updatedFields);
      }
    }
  };

  useEffect(() => {
    setCurrentItems(data);
    setPageCount(dataCount);
  }, [data, dataCount, itemsPerPage]);

  useEffect(() => {
    let event = {
      selected: 0,
    };
    handlePageClick(event);
  }, [sendingDate]);

  const handleStudentData = async () => {
    loaderDispatch({
      isLoading: true,
    });
    try {
      if (searchItem.toLowerCase() === "active") {
        setSearchItem("2");
      }
      if (searchItem.toLowerCase() === "inactive") {
        setSearchItem("1");
      }

      let order = orderby ? "desc" : "asc";

      if (searchItem.toLowerCase() === "active") {
        setSearchItem("1");
      } else if (searchItem.toLowerCase() === "inactive") {
        setSearchItem("2");
      }
      let customFields;
      if (
        selectedField?.id !== null &&
        selectedField?.id !== "" &&
        selectedField?.id !== undefined
      ) {
        customFields = {
          customFieldStartDate:
            CustomFieldSetstartdate !== null
              ? new Date(CustomFieldSetstartdate).toISOString()
              : null,
          customFieldEndDate:
            CustomFieldSetenddate !== null
              ? new Date(CustomFieldSetenddate).toISOString()
              : null,
          customFieldId: selectedField?.id,
          customFieldValue: textValue,
        };
      } else {
        customFields = null;
      }

      // Convert customFields object to a JSON string
      let customFieldsJsonString = JSON.stringify(customFields);

      let result = await adminStudentTaskListing(
        userData.id,
        `search=${searchItem.trim()}&sortBy=${sortby}&order=${order}&page=${itemOffset}&limit=${itemsPerPage}&isCompleted=${Taskfilter}&customField=${encodeURIComponent(
          customFieldsJsonString
        )}`
      );
      loaderDispatch({
        isLoading: false,
      });
      setData([]);
      setDataCount(0);

      if (
        result?.data?.status === StatusCodes.OK &&
        result?.data?.data?.total > 0
      ) {
        const studentData = result?.data?.data?.rows;
        const studentDataCount = result?.data?.data?.pages;
        let studentInfo = [];
        for (let i = 0; i < studentData.length; i++) {
          const startDate = new Date(studentData[i].startDate);
          // Extract year, month, and day components
          const years = startDate.getFullYear();
          const months = String(startDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
          const days = String(startDate.getDate()).padStart(2, "0");
          const formattedstartDate = `${years}-${months}-${days}`;

          const dueDate = new Date(studentData[i].dueDate);
          // Extract year, month, and day components
          const yeard = dueDate.getFullYear();
          const monthd = String(dueDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
          const dayd = String(dueDate.getDate()).padStart(2, "0");
          const formatteddueDate = `${yeard}-${monthd}-${dayd}`;
          studentInfo.push({
            id: studentData[i].id,
            title: studentData[i].title,
            incompleteTodosCount:
              studentData[i].incompleteTodosCount !== null &&
              studentData[i].incompleteTodosCount !== ""
                ? studentData[i].incompleteTodosCount
                : "Not Assigned",
            completedTodosCount:
              studentData[i].completedTodosCount !== null &&
              studentData[i].completedTodosCount !== ""
                ? studentData[i].completedTodosCount
                : "Not Assigned",
            startDate: formattedstartDate,
            dueDate: formatteddueDate,
            status: studentData[i].status,
          });
        }

        setData((preVal) => {
          return [...preVal, ...studentInfo];
        });
        setDataCount(studentDataCount);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSorting = (data, order) => {
    setSortby(data);
    setOrderby(order);
  };
  const handlecount = (value, id) => {
    if (value) {
      navigate(`/complate/students/${id}`);
    } else {
      navigate(`/incomplate/students/${id}`);
    }
  };
  const handlePageClick = (event) => {
    setItemOffset(event.selected + 1);
  };

  const handelDownloadCSV = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let order = orderby ? "desc" : "asc";

      let customFields;
      if (
        selectedField?.id !== null &&
        selectedField?.id !== "" &&
        selectedField?.id !== undefined
      ) {
        customFields = {
          customFieldStartDate:
            CustomFieldSetstartdate !== null
              ? new Date(CustomFieldSetstartdate).toISOString()
              : null,
          customFieldEndDate:
            CustomFieldSetenddate !== null
              ? new Date(CustomFieldSetenddate).toISOString()
              : null,
          customFieldId: selectedField?.id,
          customFieldValue: textValue,
        };
      } else {
        customFields = null;
      }

      // Convert customFields object to a JSON string
      let customFieldsJsonString = JSON.stringify(customFields);

      let body = {
        search: searchItem.trim(),
        sort: sortby,
        order: order,
        school_id: userData?.id,
        isCompleted: Taskfilter,
        customField: encodeURIComponent(customFieldsJsonString),
      };

      let result = await downloadCSV(body);
      loaderDispatch({
        isLoading: false,
      });
      if (result.data.status === StatusCodes.OK) {
        setFileLink(result?.data?.data);
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCustomFieldSelection = (selectedLabel) => {
    // Find the selected custom field in the array
    const selectedFieldData = fields.find(
      (item) => item.columnName === selectedLabel
    );
    setSelectedField(selectedFieldData);
    SettextValue(null);
    handleCustomFieldSetstartdate(null);
    handleCustomFieldSetenddate(null);
  };

  return (
    <>
      <div className="p-2 grid grid-cols-2 gap-2 mt-1">
        <div className="flex justify-center">
          <SearchFilter
            setSearchItem={setSearchItem}
            searchItem={searchItem}
            setPage={setItemOffset}
            cname={
              " block w-full border border-gray-300 rounded-md shadow py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
            }
          />

          <select
            className="ml-3 bg-white border p-2 rounded"
            name="taskfilter"
            id="taskfilter"
            onChange={(e) => {
              setTaskfilter(e.target.value);
            }}
          >
            <option value="null">All</option>
            <option value="true">Complated Task</option>
            <option value="false">Incomplated Task</option>
          </select>
          <select
            className="ml-3 bg-white border p-2 rounded"
            name="customfieldlabel"
            id="customfieldlabel"
            onChange={(e) => {
              handleCustomFieldSelection(e.target.value);
            }}
          >
            <option value="">Select custom field</option>
            {fields.map((val, i) => {
              return (
                <option value={val.columnName} key={i}>
                  {val.columnName}
                </option>
              );
            })}
          </select>
        </div>

        {/* <div className='shadow flex justify-center w-fit rounded-md'>
          <DateFilter
            setOpen={setOpen}
            open={open}
            setSendingDate={setSendingDate}
            setDates={setDates}
            dates={dates}
          />
        </div> */}
        <div className=" flex justify-end">
          <DownloadButton
            title={"Export CSV"}
            btnCls={"bg-green-700 px-3 rounded shadow-md text-xl text-white"}
            onClick={handelDownloadCSV}
            fileLink={fileLink}
          />
        </div>
      </div>
      <div className="p-2 grid grid-cols-2 ">
        <div className="flex justify-center">
          {selectedField?.columnType === "string" && (
            <input
              className="ml-3 bg-white border p-2 rounded"
              type="text"
              name="searchtext"
              onChange={(e) => {
                SettextValue(e.target.value);
              }}
              placeholder="Enter search value"
            ></input>
          )}

          {selectedField?.columnType === "date" && (
            <>
              <label className="mt-2">Start Date</label>
              <input
                className="ml-3 bg-white border p-2 rounded"
                type="date"
                name="startdatesearch"
                onChange={(e) => {
                  handleCustomFieldSetstartdate(e.target.value);
                }}
              ></input>
              &nbsp; &nbsp;
              <label className="mt-2">End Date</label>
              <input
                className="ml-3 bg-white border p-2 rounded"
                type="date"
                name="enddatesearch"
                onChange={(e) => {
                  handleCustomFieldSetenddate(e.target.value);
                }}
              ></input>
            </>
          )}
          {selectedField?.columnType === "checkbox" && (
            <>
              <select
                className="ml-3 bg-white border p-2 rounded"
                name="optionsearch"
                id="optionsearch"
                onChange={(e) => {
                  SettextValue(e.target.value);
                }}
              >
                <option value="">Select value</option>
                {selectedField?.value.map((val, i) => {
                  return (
                    <option value={val} key={i}>
                      {val}
                    </option>
                  );
                })}
              </select>
            </>
          )}
          {selectedField?.columnType === "dropdown" && (
            <>
              <select
                className="ml-3 bg-white border p-2 rounded"
                name="optionsearch"
                id="optionsearch"
                onChange={(e) => {
                  SettextValue(e.target.value);
                }}
              >
                <option value="">Select value</option>
                {selectedField?.value.map((val, i) => {
                  return (
                    <option value={val} key={i}>
                      {val}
                    </option>
                  );
                })}
              </select>
            </>
          )}
        </div>
      </div>

      <ListLayout
        tableHeader={tableHeader}
        handleSorting={handleSorting}
        currentItems={currentItems}
        handlecount={handlecount}
      />
      <div className="flex justify-between">
        {data?.length > 0 && (
          <>
            <div
              className="block lg:inline-block lg:mt-0"
              style={{ marginTop: "6px" }}
            >
              <label htmlFor="item" className="ml-5">
                Record Per Page:
              </label>
              <SelectFilter
                options={itemsOnPage}
                selectCls={"ml-3 bg-white border p-2 rounded"}
                name={"item"}
                id={"item"}
                onChange={(e) => {
                  setItemsPerPage(parseInt(e.target.value));
                }}
              />
            </div>
            <div className="mt-2 mb-10">
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next >"
                onPageChange={handlePageClick}
                pageCount={pageCount}
                previousLabel="< Previous"
                renderOnZeroPageCount={null}
                containerClassName={"pagination"}
                pageClassName={"pageClass"}
                forcePage={itemOffset - 1}
              />
            </div>
          </>
        )}
      </div>
      {data?.length === 0 && (
        <div className="flex flex-row absolute left-1/2 top-1/2">
          <p>Data not found..!!</p>
        </div>
      )}
    </>
  );
}

export default SchoolTaskReport;
