import React, { useEffect } from "react";
import validator from "validator";

function EmailSetting({
  settingData,
  setSettingData,
  isError,
  setisError,
  setSwitchSetting,
  onSubmit,
  isTypeError,
  setisTypeError,
}) {
  const inputField = [
    {
      name: "mail_username",
      id: "mail_username",
      label: "Mail User",
      type: "email",
      value: settingData?.mail_username,
    },
    {
      name: "mail_password",
      id: "mail_password",
      label: "Mail Password",
      type: "password",
      value: settingData?.mail_password,
    },
    {}, //DONT REMOVE DUE TO INDEXING
    {
      name: "from_email",
      id: "from_email",
      label: "From Mail",
      type: "email",
      value: settingData?.from_email,
    },

    {
      name: "support_email",
      id: "support_email",
      label: "Support Mail",
      type: "email",
      value: settingData?.support_email,
    },
    {
      name: "support_contact_number",
      id: "support_contact_number",
      label: "Support Number",
      type: "number",
      value: settingData?.support_contact_number,
    },
    {
      name: "support_name",
      id: "support_name",
      label: "Support Name",
      type: "text",
      value: settingData?.support_name,
    },
  ];

  const mailEncryptionOption = [
    {
      value: "SSl",
    },
    {
      value: "TLS",
    },
    {
      value: "STARTTLS",
    },
  ];

  const option = [
    {
      value: "SMTP",
    },
    {
      value: "POP/IMAP",
    },

    {
      value: "Mail Filtering",
    },
  ];

  const key = "value";

  const options = option.filter((item) => {
    return item.value.toLowerCase() !== settingData?.mail_driver;
  });

  const mailEncryptionOptions = mailEncryptionOption.filter((item) => {
    return item.value.toLowerCase() !== settingData?.mail_encryption;
  });

  useEffect(() => {
    for (const key in settingData) {
      if (settingData[key] === "") {
        setisError((preVal) => {
          return { ...preVal, [key]: true };
        });
      }
    }
  }, []);

  const handeleeEmailSettingChange = (e) => {
    const { name, value } = e.target;

    setisError((preVal) => {
      return { ...preVal, [name]: false };
    });
    setisTypeError((preVal) => {
      return { ...preVal, [name]: false };
    });
    setSettingData((preVal) => {
      return { ...preVal, [name]: value };
    });
  };

  return (
    <>
      <div>
        <div className='space-y-6 sm:px-6 lg:px-0 lg:col-span-9'>
          <section aria-labelledby='payment-details-heading'>
            <div className='shadow sm:rounded-md sm:overflow-hidden'>
              <div className='bg-white py-6 px-4 sm:p-6'>
                <div className='space-x-6'>
                  <label
                    onClick={() => setSwitchSetting(true)}
                    className='border-purple-600 border px-5 py-2 rounded-3xl  text-purple-600 cursor-pointer'>
                    General Setting
                  </label>
                  <label
                    className='border-purple-600 border px-5 py-2 rounded-3xl text-white bg-purple-600 cursor-pointer '
                    onClick={() => setSwitchSetting(false)}>
                    Email Setting
                  </label>
                </div>

                <div className='mt-6 grid grid-cols-5   gap-6'>
                  <div className='col-span-2'>
                    <label
                      htmlFor='mail_driver'
                      className='block text-sm font-medium text-gray-700'>
                      Mail Driver
                    </label>
                    <select
                      name='mail_driver'
                      id='mail_driver'
                      onChange={handeleeEmailSettingChange}
                      className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm bg-white'>
                      <option value={settingData?.mail_driver}>
                        {settingData?.mail_driver.toUpperCase()}
                      </option>
                      {options.map((val, i) => {
                        return (
                          <option key={i} value={val.value}>
                            {val.value}
                          </option>
                        );
                      })}
                    </select>
                    {isError.mail_driver && (
                      <p className='text-sm text-red-500'>
                        Please select field
                      </p>
                    )}
                  </div>
                  <div className='col-span-2'>
                    <div>
                      <label
                        htmlFor='mail_host'
                        className='block text-sm font-medium text-gray-700'>
                        Mail Host
                      </label>
                      <input
                        type='text'
                        name='mail_host'
                        id='mail_host'
                        autoComplete='cc-given-name'
                        onChange={handeleeEmailSettingChange}
                        defaultValue={settingData?.mail_host}
                        className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-gray-300 focus:ring-gray-900 focus:border-gray-900 sm:text-sm'
                      />
                      {isError.mail_host && (
                        <p className='text-sm text-red-500'>
                          Please fill the field
                        </p>
                      )}
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor='port'
                      className='block text-sm font-medium text-gray-700'>
                      Mail Post
                    </label>
                    <input
                      type='number'
                      name='mail_port'
                      id='port'
                      autoComplete='cc-given-name'
                      onChange={handeleeEmailSettingChange}
                      defaultValue={settingData?.mail_port}
                      className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm'
                    />
                    {isError.mail_port && (
                      <p className='text-sm text-red-500'>
                        Please fill the field
                      </p>
                    )}
                    {}
                  </div>
                  {inputField.map((val, i) => {
                    if (i === 2) {
                      return (
                        <div className={"col-span-2"} key={i}>
                          <label
                            htmlFor='mail_encryption'
                            className='block text-sm font-medium text-gray-700'>
                            Mail Encryption
                          </label>
                          <select
                            name='mail_encryption'
                            id='mail_encryption'
                            onChange={handeleeEmailSettingChange}
                            className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm bg-white'>
                            <option value={settingData?.mail_encryption}>
                              {settingData?.mail_encryption}
                            </option>
                            {mailEncryptionOptions.map((item, idx) => {
                              return (
                                <option key={idx} value={item.value}>
                                  {item.value}
                                </option>
                              );
                            })}
                          </select>
                          {isError.mail_encryption && (
                            <p className='text-sm text-red-500'>
                              Please select field
                            </p>
                          )}
                        </div>
                      );
                    } else {
                      return (
                        <>
                          <div
                            className={
                              i % 2 === 0 ? "col-span-2" : "col-span-3"
                            }
                            key={i}>
                            <label
                              htmlFor={val.id}
                              className='block text-sm font-medium text-gray-700'>
                              {val.label}
                            </label>
                            <input
                              type={val.type}
                              name={val.name}
                              id={val.id}
                              defaultValue={val.value}
                              onChange={handeleeEmailSettingChange}
                              autoComplete='cc-given-name'
                              className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm'
                            />
                            {isError[val.name] && (
                              <p className='text-sm text-red-500'>
                                Please fill the field
                              </p>
                            )}
                            {isTypeError[val.name] && !isError[val.name] && (
                              <p className='text-sm text-red-500'>
                                Please fill Correctly
                              </p>
                            )}
                            {val.name === "mail_password" &&
                              ((settingData?.mail_password?.length > 3 &&
                                settingData?.mail_password?.length < 9) ||
                                (settingData?.mail_password?.length != 0 && (
                                  <p className='text-sm text-red-500'>
                                    Password must be at least 4 characters long
                                  </p>
                                )))}
                          </div>
                        </>
                      );
                    }
                  })}
                </div>
              </div>
              <div className='px-4 py-3 bg-gray-50 text-right sm:px-6'>
                <button
                  type='submit'
                  onClick={onSubmit}
                  className='bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900'>
                  Save
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default EmailSetting;
