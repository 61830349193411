import React from "react";
import styled from "styled-components";

import { colors, space, siteGradient } from "./Variables.js";
import { device } from "./Devices.js";

const H2 = styled.h2`
  font-family: "Inter", sans-serif;
  font-weight: 800;
  font-size: 1.5rem;
  line-height: 2.2rem;
  position: relative;

  color: ${colors.primary};

  // Spacing
  margin-bottom: ${space.space8};

  @media ${device.mobileL} {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }

  @media ${device.tablet} {
    font-size: 2rem;
    line-height: 3rem;
  }

  @media ${device.laptop} {
    font-size: 2.625rem;
    line-height: 3.938rem;
  }

  // Gradient
  &.gradient {
    background: ${siteGradient.bluePink};
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-flex;
  }

  &.white {
    color: ${colors.white};
  }

  &.noMargin {
    margin: 0;
  }
`;

function HeadingTwo(props) {
  return (
    <>
      <H2 className={props.cname}>{props.h2Data}</H2>
    </>
  );
}
export default HeadingTwo;
