import React from "react";
import CommonModal from "../../../common/commonModal";
import MultiSelect from "../../../common/MultiSelect";

function CreateWebLinkModals({
  openCreateModal,
  setOpenCreateModal,
  isFormSubmit,
  inputArray,
  isEdit,
  inputData,
  handelCreateWebLinks,
  errorMessage,
  handelCreate,
  setInputData,
  channelOption,
  channelOptionValue,
  handelCreateSelect,
  multiSelectValue,
}) {
  return (
    <>
      <CommonModal
        openModal={openCreateModal}
        setOpenModal={setOpenCreateModal}
        header={isEdit ? "Edit web-link" : "Create web-link"}
        divClass={
          "inline-block align-bottom bg-white rounded-lg pl-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-5"
        }>
        <div className='max-h-96 overflow-y-auto px-2 w-80 '>
          <div className='mr-2'>
            <div className='my-2 text-sm'>
              <div className=''>
                <label htmlFor='' className='font-medium text-slate-800'>
                  Channel
                  <span className='text-sm text-red-500'>*</span>
                </label>
              </div>
                            <div>
                <select
                  name='type'
                  id=''
                  onChange={(e) => {
                    handelCreateSelect(e);
                    setInputData((preVal) => {
                      return { ...preVal, id: [] };
                    });
                  }}
                  className='w-full px-2 py-2 bg-white outline-none border rounded'>
                  {channelOption?.map((value) => {
                    return (
                      <option
                        value={value?.value}
                        key={value?.value}
                        selected={value?.selected}>
                        {value?.label}
                      </option>
                    );
                  })}
                </select>
              </div>
              {isFormSubmit?.type && (
                <p className='text-red-500'>Please select this field</p>
              )}
            </div>

            <div className='my-2 text-sm'>
              <div className=''>
                <label htmlFor='' className='font-medium text-slate-800'>
                  Channel value
                  <span className='text-sm text-red-500'>*</span>
                </label>
              </div>
              <div>
                {isEdit ? (
                  <select
                    disabled={
                      inputData?.type == 1 || inputData?.type == 4 || inputData?.type == "" 
                        ? true
                        : false
                    }
                    name='id'
                    id=''
                    onChange={handelCreateWebLinks}
                    placeholder='Select channel value'
                    className='w-full px-2 py-2 bg-white outline-none border rounded'>
                    {channelOptionValue?.map((value) => {
                      return (
                        <option
                          value={value?.value}
                          key={value?.value}
                          selected={value?.selected}>
                          {value?.label}
                        </option>
                      );
                    })}
                  </select>
                ) : (
                  <MultiSelect
                    name='id'
                    colourOptions={channelOptionValue}
                    disabled={
                      channelOptionValue?.length > 1 && inputData?.type !== ""
                        ? false
                        : true
                    }
                    onChange={handelCreateWebLinks}
                    multiSelectValue={multiSelectValue}
                    placeholder={"Select channel value"}
                  />
                )}
              </div>
              {isFormSubmit?.id && (
                <p className='text-red-500'>Please select this field</p>
              )}
            </div>
            {inputArray?.map((value) => {
              return (
                <div key={value?.label} className='my-2 text-sm'>
                  <div className=''>
                    <label htmlFor='' className='font-medium text-slate-800'>
                      {value?.label}
                      <span className='text-sm text-red-500'>*</span>
                    </label>
                  </div>
                  <div>
                    <value.tag
                      type={value?.type}
                      name={value?.name}
                      onChange={handelCreateWebLinks}
                      minLength={value?.minLength}
                      maxLength={value?.maxLength}
                      value={inputData?.[value?.name]}
                      placeholder={value?.placeholder}
                      className='border px-2 py-2 rounded focus:ring-1 ring-indigo-500 outline-none w-full'
                    />
                  </div>

                  {isFormSubmit?.[value?.name] && (
                    <p className='text-red-500'>{errorMessage[value?.name]}</p>
                  )}
                </div>
              );
            })}

            {isEdit && (
              <label className='block text-sm font-medium text-gray-700 mt-3 mb-3 w-fit'>
                Status<span className='text-red-600'>&#42;</span>
                <label
                  htmlFor='default-toggle1'
                  className='inline-flex relative items-center cursor-pointer align-middle ml-5 w-fit'>
                  <input
                    type='checkbox'
                    checked={inputData?.status == 1 ? true : false}
                    name='status1'
                    onChange={() => {
                      setInputData((preVal) => {
                        return {
                          ...preVal,
                          status: inputData?.status == 1 ? 2 : 1,
                        };
                      });
                    }}
                    id='default-toggle1'
                    className='sr-only peer'
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
              </label>
            )}
            <div className='flex justify-between space-x-2 my-4 text-md font-medium'>
              <button
                className='px-3 py-2 bg-indigo-600 hover:bg-indigo-700 text-white rounded w-full shadow-md shadow-slate-200 ring-indigo-700 ring-offset-2 focus:ring-2 outline-none'
                onClick={handelCreate}>
                Save
              </button>
              <button
                onClick={() => setOpenCreateModal(false)}
                className='px-3 py-2 w-full shadow-md rounded shadow-slate-200 hover:shadow-xl text-slate-800 outline-none'>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </CommonModal>
    </>
  );
}

export default CreateWebLinkModals;
