import React, { useLayoutEffect } from "react";
import { Link } from "react-router-dom";

function About() {
  useLayoutEffect(() => {}, []);
  return (
    <div>
      <h1>About</h1>
      <Link to='/'>Home</Link>
    </div>
  );
}

export default About;
