import React, { useContext, useEffect, useState } from "react";
import { StatusCodes } from "http-status-codes";
import { dashboard } from "../../services/auth.services";
import { AppContext } from "../../App";
import { loaderContext } from "../global loader/global-loader-provider";
import { toast } from "react-toastify";
import { admin } from "../../linksprovider";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function AdminOverview() {
  const { loaderDispatch } = useContext(loaderContext);

  const [gettingCountData, setGettingCountData] = useState({});

  const projects = [
    {
      name: "Total Number of School",
      initials: "TS",
      href: admin.school,
      bgColor: "bg-pink-600",

      value: gettingCountData.totalSchools ? gettingCountData.totalSchools : 0,
    },
    {
      name: "Total Number of Users",
      initials: "TS",
      href: admin.student,
      bgColor: "bg-purple-600",
      value: gettingCountData.totalStudent ? gettingCountData.totalStudent : 0,
    },
    {
      name: "Total Number of Subscription Plan",
      initials: "TSP",
      href: admin.plans,
      bgColor: "bg-yellow-500",
      value: gettingCountData.totalSubscription
        ? gettingCountData.totalSubscription
        : 0,
    },
    {
      name: "Total Revenue Generated ",
      initials: "TRG",
      href: admin.report,
      bgColor: "bg-green-500",

      value:
        gettingCountData.totalRevenue && gettingCountData.currency
          ? gettingCountData.currency + gettingCountData.totalRevenue
          : "$" + 0,
    },
  ];

  let count = 0;
  useEffect(() => {
    if (count === 0) {
      dashboardCountData();
    }
    count++;
  }, []);

  // getting count data on page load
  const dashboardCountData = async () => {
    loaderDispatch({
      isLoading: true,
    });
    let result = await dashboard();

    loaderDispatch({
      isLoading: false,
    });

    if (result?.data?.status === StatusCodes.OK) {
      setGettingCountData(result?.data?.data);
    }

    if (result?.data?.status !== StatusCodes.OK) {
      toast(result?.data?.message, {
        type: "error",
      });
    }
  };

  return (
    <div>
      <div>
        <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
          Overview
        </h2>
        <ul className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
          {projects.map((project) => (
            <li
              key={project.name}
              className="col-span-1 flex shadow-sm rounded-md"
            >
              <div
                className={classNames(
                  project.bgColor,
                  "flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md"
                )}
              >
                <Link to={project.href}>{project.initials}</Link>
              </div>
              <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md">
                <div className="flex-1 px-4 py-2 text-sm overflow-clip">
                  <Link
                    to={project.href}
                    className="text-gray-900 font-medium hover:text-gray-600"
                  >
                    {project.name} : {project.value}
                  </Link>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default AdminOverview;
