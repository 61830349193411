import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { SubscriptionCard } from "../../../common/website/HOC.jsx";
import HeadingThree from "../../../common/website/HeadingThree.jsx";
import HeadingFour from "../../../common/website/HeadingFour.jsx";
import Paragraph from "../../../common/website/Paragraph.jsx";
import Button from "../../../common/website/Button.jsx";
import List from "../../../common/website/List.jsx";
import { device } from "../../../common/website/Devices";
import { colors, space, siteGradient } from "../../../common/website/Variables";

import NFTBalance from "../../../images/website/icon_NFT_Balance.svg";
import { ADMIN_USER_DATA, GRADUATED } from "../../../utils/constant.js";
import {
  adminPlans,
  getSubscriptionDetail,
  renewPlan,
} from "../../../services/auth.services.js";
import { StatusCodes } from "http-status-codes";
import { useContext } from "react";
import { PostContext } from "../../../components/website/header/MainHeader.jsx";
import { toast } from "react-toastify";
import { loaderContext } from "../../../components/global loader/global-loader-provider.js";
import { object } from "yup/lib/locale.js";
import { useNavigate } from "react-router-dom";

const MySubscriptionPlanRow = styled.div`
  .gradient_border {
    background: ${siteGradient.linearGradientBorder};
    width: 100%;
    padding: 1px;
    border-radius: 20px;
  }
  .exp_gradient {
    margin-top: ${space.space14};
  }
  .description_card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    height: 100%;
    background: ${colors.body};
    box-shadow: none;
    &.exp {
      position: relative;
      padding-top: ${space.space24};
      box-shadow: ${siteGradient.cardlightboxshadow};
      .exp_description {
        position: absolute;
        top: -15px;
        right: 50%;
        transform: translate(50%, 0);
        display: inline-block;
        font-size: 0.75rem;
        line-height: 0.938rem;
        padding: 6px ${space.space10};
        background: ${colors.darkgrey};
        box-shadow: ${siteGradient.boxshadowWhite};
        border-radius: ${space.space50};
        margin: 0;
      }
    }
    .top {
      text-align: center;
      .title {
        display: flex;
        align-items: center;
        grid-gap: 6px;
        justify-content: center;
        margin-bottom: ${space.space8};
      }
    }
    h3 {
      text-align: center;
      margin-bottom: 0;
    }
    .card_description {
      text-align: center;
      margin-bottom: ${space.space24};
      position: relative;
      &::before {
        content: "";
        position: absolute;
        background: ${colors.white};
        height: 2px;
        width: ${space.space80};
        bottom: -16px;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
    h4 {
      font-weight: 900;
      font-size: 1.5rem;
      line-height: 2rem;
      color: ${colors.primary};
      margin-bottom: ${space.space16};
      text-align: center;
    }
    ul {
      text-align: start;
      li {
        font-size: 0.875rem;
        &::before {
          top: 8px;
        }
      }
    }
  }
  .bottom {
    margin-top: ${space.space24};
    .btn {
      width: 100%;
    }
  }
  .NFT_Balance {
    display: inline-flex;
    align-items: center;
    font-size: 0.75rem;
    line-height: 0.938rem;
    font-weight: 600;
    background-color: ${colors.primary};
    color: ${colors.body};
    border-radius: ${space.space8};
    padding: 6px ${space.space10};
    margin-bottom: ${space.space24};
    img {
      position: static;
      width: 14px;
      height: 14px;
      flex-shrink: 0;
      margin-right: 4px;
    }
    span {
      margin-left: 2px;
    }
  }
  .plan_list {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    grid-gap: 24px;
    width: 100%;
  }
  .plan_btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0 ${space.space24};
    .btn {
      display: flex;
      align-items: center;
      grid-gap: ${space.space10};
      font-size: 0.875rem;
      line-height: 1.063rem;
      color: ${colors.primary};
      &:hover {
        grid-gap: ${space.space14};
      }
      &.back {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
  @media ${device.mobileM} {
    .NFT_Balance {
      padding: ${space.space10} ${space.space12};
    }
    .bottom {
      margin-top: ${space.space30};
    }
  }
  @media ${device.mobileL} {
    .plan_btn {
      justify-content: flex-end;
    }
    .gradient_border {
      width: 46%;
    }
    .exp_gradient {
      margin-top: 0;
    }
    .description_card {
      h4 {
        font-size: 1.875rem;
      }
      .card_description {
        margin-bottom: ${space.space30};
      }
      &.exp {
        padding-top: ${space.space30};
        .exp_description {
          top: -16px;
          padding: ${space.space10} ${space.space14};
        }
      }
    }
    .NFT_Balance {
      margin-bottom: ${space.space40};
    }
  }
  @media ${device.tablet} {
    .gradient_border {
      width: 70%;
    }
    .description_card {
      .card_description {
        margin-bottom: ${space.space50};
        &::before {
          bottom: -24px;
        }
      }
      &.exp {
        padding-top: ${space.space40};
      }
    }
    .bottom {
      margin-top: ${space.space40};
    }
  }
  @media ${device.laptop} {
    .gradient_border {
      width: 45%;
    }
  }
  @media ${device.laptopL} {
    .gradient_border {
      width: 366px;
    }
  }
  @media ${device.desktop} {
  }
`;
function MySubscriptionPlan(props) {
  // Sets the changeText to true.
  let [changeText, setChangeText] = useState(true);
  // Sets the use state of the plan.
  const [plans, setPlans] = useState([]);

  // Sets the loaderDispatch to use the context.
  const { loaderDispatch } = useContext(loaderContext);

  // Gets the userInfo to use the PostContext
  const { userInfo } = useContext(PostContext);

  let navigate = useNavigate();

  // Show admin plans.
  useEffect(() => {
    props.getinfo();

    showAdminPlans();
  }, []);

  // Show admin plans.
  const showAdminPlans = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let param = {
        user_type: 3,
      };
      let result = await adminPlans(param);

      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        setPlans(result?.data?.data);
      } else {
        toast(result?.data?.message, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Sets the change text.
  const handleChange = () => {
    setChangeText(!changeText);
  };

  // Renews handel plan.
  const handelRenewPlan = async (plan_id) => {
    try {
      loaderDispatch({
        isLoading: true,
      });

      let body = {
        subscription_id: plan_id,
        user_id: userInfo?.id,
        existing_plan_id: userInfo?.Subscription?.plan_type,
      };

      let result = await renewPlan(body);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
          theme: "dark",
        });
        props.getinfo();
        setChangeText(true);
      } else {
        toast(result?.data?.message, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  console.log("userInfo", userInfo);

  return (
    <MySubscriptionPlanRow className='tab_body'>
      <ul className='tab_list'>
        <li className='tab_item active'>My Subscription Plan</li>

        <li
          className='tab_item'
          onClick={() => props.handleClick("edit_profile")}>
          Edit Profile
        </li>

        {props.metaMaskShow && (
          <li
            className='tab_item'
            onClick={() => props.handleClick("meta_mask_wallet")}>
            Meta Mask Wallet
          </li>
        )}
        <li className='tab_item' onClick={() => props.handleClick("id")}>
          ID
        </li>
      </ul>

      {changeText ? (
        <div className='plan_main'>
          <div className='plan_btn'>
            <button className='btn' onClick={() => handleChange()}>
              Upgrade Your Plan
              <svg
                width='20'
                height='20'
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <g clip-path='url(#clip0_2749_180300)'>
                  <path
                    d='M19.7709 9.44699C19.7706 9.44676 19.7704 9.44648 19.7702 9.44625L15.688 5.38375C15.3821 5.07941 14.8875 5.08054 14.5831 5.3864C14.2787 5.69222 14.2799 6.18687 14.5857 6.49125L17.3265 9.21875H0.78125C0.349766 9.21875 0 9.56851 0 10C0 10.4315 0.349766 10.7812 0.78125 10.7812H17.3264L14.5857 13.5087C14.2799 13.8131 14.2788 14.3078 14.5831 14.6136C14.8875 14.9195 15.3822 14.9205 15.688 14.6162L19.7702 10.5537C19.7704 10.5535 19.7706 10.5532 19.7709 10.553C20.0769 10.2476 20.0759 9.75136 19.7709 9.44699Z'
                    fill='#30DFF2'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_2749_180300'>
                    <rect width='20' height='20' fill='white' />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
          {
            <div className='plan_list'>
              {Object.keys(userInfo).length > 0 &&
              userInfo?.is_plan_expired == 0 ? (
                <div className='gradient_border exp_gradient'>
                  <SubscriptionCard className='description_card exp'>
                    <Paragraph
                      cname='exp_description'
                      pData={`EXP: ${
                        userInfo?.expiry_date ? userInfo?.expiry_date : 0
                      }`}
                    />
                    <div className='top'>
                      <div className='title'>
                        <HeadingThree h3Data={userInfo?.Subscription?.title} />
                        <svg
                          width='18'
                          height='18'
                          viewBox='0 0 18 18'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'>
                          <g clip-path='url(#clip0_2749_180188)'>
                            <path
                              d='M17.5629 7.93928L16.3352 6.71187C16.0439 6.4205 15.8051 5.84472 15.8051 5.4318V3.69593C15.8051 2.87018 15.1305 2.19561 14.305 2.19517H12.5684C12.156 2.19517 11.5796 1.95597 11.2882 1.66482L10.0607 0.437407C9.47753 -0.145802 8.52263 -0.145802 7.93942 0.437407L6.712 1.6657C6.42037 1.95707 5.84327 2.19561 5.43172 2.19561H3.69584C2.87119 2.19561 2.19574 2.87018 2.19574 3.69593V5.43185C2.19574 5.84314 1.95702 6.42072 1.66562 6.71191L0.437981 7.93933C-0.145668 8.52254 -0.145668 9.47744 0.437981 10.0616L1.66562 11.289C1.9572 11.5804 2.19574 12.1577 2.19574 12.5691V14.305C2.19574 15.1298 2.87119 15.8053 3.69584 15.8053H5.43176C5.84419 15.8053 6.42064 16.044 6.71204 16.3352L7.93946 17.5631C8.52267 18.1458 9.47758 18.1458 10.0608 17.5631L11.2882 16.3352C11.5798 16.0438 12.1561 15.8053 12.5685 15.8053H14.3051C15.1306 15.8053 15.8052 15.1298 15.8052 14.305V12.5691C15.8052 12.156 16.0441 11.5802 16.3353 11.289L17.5629 10.0616C18.1457 9.47744 18.1457 8.52249 17.5629 7.93928ZM7.80938 12.3753L4.49979 9.06523L5.56048 8.00476L7.80964 10.2539L12.4395 5.62512L13.5 6.68559L7.80938 12.3753Z'
                              fill='#30DFF2'
                            />
                          </g>
                          <defs>
                            <clipPath id='clip0_2749_180188'>
                              <rect width='18' height='18' fill='white' />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <Paragraph
                        cname='card_description'
                        pData={
                          (userInfo?.Subscription?.plan_type === 1 && "Free") ||
                          (userInfo?.Subscription?.plan_type === 2 && "Paid") ||
                          (userInfo?.Subscription?.plan_type === 3 && "Topup")
                        }
                      />
                      <HeadingFour
                        h4Data={`${userInfo?.currency || ""} ${
                          userInfo?.Subscription?.price
                        }`}
                      />
                      <div className='NFT_Balance'>
                        <img
                          src={NFTBalance}
                          alt='profile_image'
                          className='profile_image'
                        />
                        NFT Balance : <span>{userInfo?.total_nft}</span>
                      </div>
                      <ul>
                        <List liData={userInfo?.Subscription?.description} />
                        <List
                          liData={
                            userInfo?.Subscription?.duration
                              ? "Validity: " +
                                userInfo?.Subscription?.duration +
                                " (In months)"
                              : "Validity: 0 (In months)"
                          }
                        />
                      </ul>
                    </div>
                    {userInfo?.remainder && userInfo?.subscription_id != 1 && (
                      <div className='bottom'>
                        <Button
                          cname='gradient btn'
                          buttonData={"Renew Plan"}
                          onClick={() =>
                            // handelRenewPlan(userInfo?.Subscription?.plan_type)
                            navigate(
                              `/checkout/${userInfo?.Subscription?.price}/${userInfo?.Subscription?.plan_type}/${userInfo?.Subscription?.id}`
                            )
                          }
                        />
                      </div>
                    )}
                  </SubscriptionCard>
                </div>
              ) : userInfo?.is_plan_expired == 1 ? (
                <p>Your plan is expired</p>
              ) : (
                <p>Student plan details not found..!! </p>
              )}
            </div>
          }
        </div>
      ) : (
        <div className='plan_main'>
          <div className='plan_btn'>
            <button className='btn back' onClick={() => handleChange()}>
              <svg
                width='20'
                height='20'
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <g clip-path='url(#clip0_2749_180300)'>
                  <path
                    d='M19.7709 9.44699C19.7706 9.44676 19.7704 9.44648 19.7702 9.44625L15.688 5.38375C15.3821 5.07941 14.8875 5.08054 14.5831 5.3864C14.2787 5.69222 14.2799 6.18687 14.5857 6.49125L17.3265 9.21875H0.78125C0.349766 9.21875 0 9.56851 0 10C0 10.4315 0.349766 10.7812 0.78125 10.7812H17.3264L14.5857 13.5087C14.2799 13.8131 14.2788 14.3078 14.5831 14.6136C14.8875 14.9195 15.3822 14.9205 15.688 14.6162L19.7702 10.5537C19.7704 10.5535 19.7706 10.5532 19.7709 10.553C20.0769 10.2476 20.0759 9.75136 19.7709 9.44699Z'
                    fill='#30DFF2'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_2749_180300'>
                    <rect width='20' height='20' fill='white' />
                  </clipPath>
                </defs>
              </svg>
              Back to My Plan
            </button>
          </div>
          <div className='plan_list'>
            {plans?.length > 0 ? (
              plans?.map((val, i) => {
                if (
                  val?.plan_type != 1 &&
                  val?.id != userInfo?.subscription_id &&
                  userInfo?.is_plan_expired == 0
                ) {
                  if (userInfo?.standard?.toLowerCase() === GRADUATED) {
                    if (val?.plan_type != 2) {
                      return (
                        <div className='gradient_border' key={i}>
                          <SubscriptionCard className='description_card'>
                            <div className='top'>
                              <HeadingThree h3Data={val?.title} />
                              <Paragraph
                                cname='card_description'
                                pData={val?.plan_type === 3 && "Topup"}
                              />
                              <HeadingFour
                                h4Data={`${val?.currency}${val?.price}`}
                              />
                              <ul>
                                <List liData={val?.description} />
                                {val?.plan_type != 3 && (
                                  <List
                                    liData={`Valid till ${
                                      val?.duration ? val?.duration : 0
                                    } (In months)`}
                                  />
                                )}
                                <List
                                  liData={`Number of NFT ${
                                    val?.no_of_nft || ""
                                  }`}
                                />
                              </ul>
                            </div>
                            <div className='bottom'>
                              <Button
                                cname='gradient btn'
                                buttonData={"Upgrade Plan"}
                                onClick={() =>
                                  navigate(
                                    `/checkout/${val?.price}/${val?.plan_type}/${val.id}`
                                  )
                                }
                              />
                            </div>
                          </SubscriptionCard>
                        </div>
                      );
                    }
                  } else {
                    return (
                      <div className='gradient_border' key={i}>
                        <SubscriptionCard className='description_card'>
                          <div className='top'>
                            <HeadingThree h3Data={val?.title} />
                            <Paragraph
                              cname='card_description'
                              pData={
                                (val?.plan_type === 3 && "Topup") ||
                                (val?.plan_type === 2 && "Paid")
                              }
                            />
                            <HeadingFour
                              h4Data={`${val?.currency}${val?.price}`}
                            />
                            <ul>
                              <List liData={val?.description} />
                              {val?.plan_type != 3 && (
                                <List
                                  liData={`Valid till ${
                                    val?.duration ? val?.duration : 0
                                  } (In months)`}
                                />
                              )}
                              <List
                                liData={`Number of NFT ${val?.no_of_nft || ""}`}
                              />
                            </ul>
                          </div>
                          <div className='bottom'>
                            <Button
                              cname='gradient btn'
                              buttonData={"Upgrade Plan"}
                              onClick={() =>
                                navigate(
                                  `/checkout/${val?.price}/${val?.plan_type}/${val?.id}`
                                )
                              }
                            />
                          </div>
                        </SubscriptionCard>
                      </div>
                    );
                  }
                } else if (
                  val?.plan_type != 1 &&
                  userInfo?.is_plan_expired == 1
                ) {
                  if (userInfo?.standard?.toLowerCase() === GRADUATED) {
                    if (val?.plan_type != 2) {
                      return (
                        <div className='gradient_border' key={i}>
                          <SubscriptionCard className='description_card'>
                            <div className='top'>
                              <HeadingThree h3Data={val?.title} />
                              <Paragraph
                                cname='card_description'
                                pData={val?.plan_type === 3 && "Topup"}
                              />
                              <HeadingFour
                                h4Data={`${val?.currency}${val?.price}`}
                              />
                              <ul>
                                <List liData={val?.description} />
                                {val?.plan_type != 3 && (
                                  <List
                                    liData={`Valid till ${
                                      val?.duration ? val?.duration : 0
                                    } (In months)`}
                                  />
                                )}
                                <List
                                  liData={`Number of NFT ${
                                    val?.no_of_nft || ""
                                  }`}
                                />
                              </ul>
                            </div>
                            <div className='bottom'>
                              <Button
                                cname='gradient btn'
                                buttonData={"Upgrade Plan"}
                                onClick={() =>
                                  navigate(
                                    `/checkout/${val?.price}/${val?.plan_type}/${val.id}`
                                  )
                                }
                              />
                            </div>
                          </SubscriptionCard>
                        </div>
                      );
                    }
                  } else {
                    if (userInfo?.is_plan_expired == 1) {
                      if (val?.plan_type !== 3)
                        return (
                          <div className='gradient_border' key={i}>
                            <SubscriptionCard className='description_card'>
                              <div className='top'>
                                <HeadingThree h3Data={val?.title} />
                                <Paragraph
                                  cname='card_description'
                                  pData={val?.plan_type === 2 && "Paid"}
                                />
                                <HeadingFour
                                  h4Data={`${val?.currency}${val?.price}`}
                                />
                                <ul>
                                  <List liData={val?.description} />
                                  {val?.plan_type != 3 && (
                                    <List
                                      liData={`Valid till ${
                                        val?.duration ? val?.duration : 0
                                      } (In months)`}
                                    />
                                  )}
                                  <List
                                    liData={`Number of NFT ${
                                      val?.no_of_nft || ""
                                    }`}
                                  />
                                </ul>
                              </div>
                              <div className='bottom'>
                                <Button
                                  cname='gradient btn'
                                  buttonData={"Upgrade Plan"}
                                  onClick={() =>
                                    navigate(
                                      `/checkout/${val?.price}/${val?.plan_type}/${val?.id}`
                                    )
                                  }
                                />
                              </div>
                            </SubscriptionCard>
                          </div>
                        );
                    }
                  }
                }
              })
            ) : (
              <p>Plans not found..!!</p>
            )}
          </div>
        </div>
      )}
    </MySubscriptionPlanRow>
  );
}

export default MySubscriptionPlan;
