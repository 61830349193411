import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import {
  SiteCard,
  DashboardBody,
  DashboardRightBar,
} from "../../../common/website/HOC.jsx";
import { device } from "../../../common/website/Devices";
import { colors, space, siteGradient } from "../../../common/website/Variables";
import HeadingThree from "../../../common/website/HeadingThree.jsx";
import SignaturesFilter from "../../../components/website/Classmates/SignaturesFilter.jsx";

import IconSignatures from "../../../images/website/icon_signatures_star.svg";
import ProfileImage from "../../../images/website/profile_new_image.png";
import ProfileImage2 from "../../../images/website/profile_new_image2.png";
import ProfileImage3 from "../../../images/website/profile_new_image3.png";
import ProfileImage4 from "../../../images/website/profile_new_image4.png";
import ProfileImage5 from "../../../images/website/profile_new_image5.png";
import ProfileImage6 from "../../../images/website/profile_new_image6.png";
import IconDots from "../../../images/website/icon_dots.svg";

import Paragraph from "../../../common/website/Paragraph.jsx";
import {
  getSignatureClassmatesList,
  getSignatureListing,
  getSubscriptionDetail,
  updateSignature,
  updateSignatureStatus,
} from "../../../services/auth.services.js";
import { loaderContext } from "../../../components/global loader/global-loader-provider.js";
import { ADMIN_USER_DATA } from "../../../utils/constant.js";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import SignaturesData from "../../../common/website/SignaturesData.jsx";
import { PostContext } from "../../../components/website/header/MainHeader.jsx";

const NotificationRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  width: 100%;
  .club_group {
    display: none !important;
  }
  h3,
  h4,
  p {
    position: static;
  }
  .full_screen_card {
    border-radius: ${space.space16};
    padding: ${space.space16};
    margin-bottom: ${space.space12};
    .head {
      display: flex;
      align-items: center;
      grid-gap: ${space.space8};
      img {
        width: 24px;
        height: 24px;
      }
      h3 {
        margin: 0;
      }
    }
  }
  .list_wrap_main {
    .list_head {
      margin-bottom: ${space.space14};
    }
    .list_wrap {
      .list_item {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        grid-gap: ${space.space8};
        margin-bottom: ${space.space12};
        border: 1px solid ${colors.darkgrey};
        position: relative;
        padding: ${space.space16};
        border-radius: ${space.space8};
        &:last-child {
          margin: 0;
        }
        img {
          width: 30px;
          height: 30px;
          border-radius: 100%;
          object-fit: cover;
          flex-shrink: 0;
        }
        .details {
          .name,
          .description {
            font-size: 0.875rem;
            line-height: 1.063rem;
            color: ${colors.white};
            margin-bottom: 4px;
          }
          .description {
            line-height: 1.313rem;
            margin: 0;
          }
          .duration {
            font-size: 0.75rem;
            line-height: 0.938rem;
            color: ${colors.primary};
            margin: 0 0 ${space.space8};
          }
        }
        .menu_dropdown {
          position: absolute;
          right: 20px;
          top: 16px;
          height: 24px;
          .dropdown_btn {
            display: inline-block;
            img {
              width: 24px;
              height: 24px;
              object-fit: contain;
              border-radius: 0px;
            }
          }
          .menu_list {
            content: "";
            position: absolute;
            top: 100%;
            right: 0;
            background: ${colors.body};
            box-shadow: ${siteGradient.cardlightboxshadow};
            border-radius: ${space.space8};
            width: 118px;
            overflow: hidden;
            padding: 6px 0;
            z-index: 1;
          }
          .link {
            display: flex;
            align-items: center;
            grid-gap: ${space.space8};
            font-size: 0.875rem;
            line-height: 1.063rem;
            padding: ${space.space8} ${space.space20};
            width: 100%;
            img {
              width: 14px;
              height: 14px;
              object-fit: contain;
              border-radius: 0px;
            }
            &:hover {
              background: ${colors.primary};
            }
          }
        }
      }
    }
  }
  .classmates_list {
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    grid-gap: 12px 16px;
    max-height: 120px;
    li {
      margin: 0;
    }
  }
  .sidebar_right {
    position: static;
    width: 100%;
    margin-bottom: ${space.space12};
  }
  @media ${device.mobileM} {
    .list_wrap_main {
      .list_wrap {
        .list_item {
          flex-direction: row;
          grid-gap: ${space.space14};
          padding-right: ${space.space50};
          img {
            width: 44px;
            height: 44px;
          }
          .menu_dropdown {
            .menu_list {
              padding: 0.875rem 0;
            }
          }
        }
      }
    }
  }
  @media ${device.mobileL} {
    .full_screen_card {
      padding: ${space.space20};
      .head {
        grid-gap: ${space.space14};
        img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
  @media ${device.tablet} {
    width: 75%;
    flex-direction: row;
    flex-wrap: nowrap;
    .sidebar_right {
      position: sticky;
      width: 35%;
      margin-bottom: 0;
    }
    .classmates_list {
      display: block;
      grid-gap: 0;
      max-height: 100%;
      height: calc(100vh - 201px);
      li {
        margin-bottom: 18px;
      }
    }
  }
  @media ${device.laptop} {
    width: 77%;
    .sidebar_right {
      width: 29%;
    }
    .full_screen_card {
      padding: ${space.space30};
    }
    .classmates_list {
      height: calc(100vh - 230px);
    }
  }
  @media ${device.laptopL} {
    width: 80%;
    .sidebar_right {
      width: 26%;
    }
    .classmates_list {
      height: calc(100vh - 250px);
    }
  }
`;
function Signatures() {
  const [signatureData, setSignatureData] = useState([]);
  const [classMateData, setClassMateData] = useState([]);
  const [filterType, setFilterType] = useState(true);
  const [classMatePage, setClassMatePage] = useState(1);
  const [signaturePageCount, setSignaturePageCount] = useState(1);

  const { loaderDispatch } = useContext(loaderContext);
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  const { setUserInfo, userInfo, handelLogout } = useContext(PostContext);

  const scrollRef = useRef();
  let pages = classMatePage;

  const signatureRef = useRef();
  let signaturePage = signaturePageCount;

  let sub = true;
  useEffect(() => {
    if (sub) {
      getSignatureList({ pages: 1, loader: true });
      getStudentProfile();
    }
    return () => (sub = false);
  }, []);

  const getSignatureList = async ({ pages = null, loader = null }) => {
    try {
      if (loader) {
        loaderDispatch({
          isLoading: true,
        });
      }
      let body = {
        id: userData?.id,
        page: pages ? pages : 1,
      };
      let result = await getSignatureListing(body);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        let data = result?.data?.data?.signatureList?.rows;
        console.log("signature", data);
        if (pages > 1 && data?.length > 0) {
          setSignatureData((preVal) => {
            return [...preVal, ...data];
          });
        }
        if (pages === 1 || !pages) {
          setSignatureData(data);
        }
      } else {
        toast(result?.data?.message, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getStudentProfile = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });

      let studentData = await getSubscriptionDetail(userData);

      loaderDispatch({
        isLoading: false,
      });
      if (studentData?.data?.status === 410) {
        handelLogout();
      }
      if (studentData?.data?.status === StatusCodes.OK) {
        let data = studentData?.data?.data;

        setUserInfo(data);

        handelFilterClassmateList({ singed: true, data });
      } else {
        toast(studentData?.data?.massage, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handelFilterClassmateList = async ({
    singed,
    data = null,
    loader = null,
    pages = null,
  }) => {
    try {
      if (loader) {
        loaderDispatch({
          isLoading: true,
        });
      }

      let body = {
        isSinged: singed,
        schoolId: data?.User?.id ? data?.User?.id : userInfo?.User?.id,
        standard: data?.standard ? data?.standard : userInfo?.standard,
        academicYear: data?.academic_year
          ? data?.academic_year
          : userInfo?.academic_year,
        userId: data?.id ? data?.id : userInfo?.id,
        page: pages ? pages : 1,
      };
      let result = await getSignatureClassmatesList(body);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        let data = result?.data?.data?.docs;
        if (data?.length > 0 && pages > 1) {
          setClassMateData((preVal) => {
            return [...preVal, ...data];
          });
        }
        if (pages === 1 || !pages) {
          setClassMateData(data);
        }
      } else {
        toast(result?.data?.message, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelPagination = () => {
    if (scrollRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        console.log("yesssss", scrollTop, clientHeight, scrollHeight);
        pages++;
        setClassMatePage(pages);

        handelFilterClassmateList({ singed: true, loader: false, pages });
      }
    }
  };

  const handelSignatureScroll = () => {
    if (signatureRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = signatureRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        signaturePage++;
        setSignaturePageCount(signaturePage);
        getSignatureList({ pages: signaturePage });
      }
    }
  };

  console.log("signatureData", signatureData);

  return (
    <>
      <NotificationRow>
        <DashboardBody>
          <div className='body_full_screen'>
            <SiteCard className='full_screen_card'>
              <div className='head'>
                <img src={IconSignatures} alt='icon_notification' />
                <HeadingThree h3Data={"Signatures"} />
              </div>
            </SiteCard>
            <div className='list_wrap_main'>
              <ul
                className='list_wrap'
                ref={signatureRef}
                onScroll={handelSignatureScroll}>
                {signatureData?.length > 0 ? (
                  signatureData?.map((value) => {
                    return (
                      <SignaturesData
                        key={value?.id}
                        id={value?.id}
                        profileImage={value?.User?.filtered_image}
                        name={value?.User?.student_name}
                        duration={value?.createdAt}
                        description={value?.description}
                        signatureUserId={value?.User?.id}
                        academic_year={value?.academic_year}
                        filterType={filterType}
                        handelFilterClassmateList={handelFilterClassmateList}
                        setSignatureData={setSignatureData}
                        modes={value?.mode}
                        type={true}
                      />
                    );
                  })
                ) : (
                  <p className='flex justify-center'>
                    Signature's Data not found..!!..!!
                  </p>
                )}
              </ul>
            </div>
          </div>
        </DashboardBody>
        <DashboardRightBar className='sidebar_right'>
          <SignaturesFilter
            getStudentProfile={getStudentProfile}
            handelFilterClassmateList={handelFilterClassmateList}
            classMateData={classMateData}
            setFilterType={setFilterType}
            paginationRef={scrollRef}
            handelPagination={handelPagination}
            setClassMatePage={setClassMatePage}
            filterType={filterType}
          />
        </DashboardRightBar>
      </NotificationRow>
    </>
  );
}

export default Signatures;
