import React, { useEffect, useRef } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { BiDownload } from "react-icons/bi";

function DownloadButton({ title, btnCls, iconCls, onClick, fileLink }) {
  let downloadRef = useRef();
  useEffect(() => {
    if (fileLink) {
      downloadRef?.current?.click();
    }
  }, [fileLink]);

  return (
    <>
      <Tooltip title={title}>
        <button className={btnCls} onClick={onClick}>
          <BiDownload className={iconCls} />
        </button>
      </Tooltip>
      <a href={fileLink} target='_blank' ref={downloadRef} rel='noreferrer' />
    </>
  );
}

export default DownloadButton;
