import React from "react";
import styled from "styled-components";

import { colors, space, siteGradient } from "./Variables.js";

const H5 = styled.h5`
  font-family: "Inter", sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.625rem;
  position: relative;

  color: ${colors.white};

  // Spacing
  margin-bottom: ${space.space8};

  // Gradient
  &.gradient {
    background: ${siteGradient.bluePink};
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-flex;
  }
`;

function HeadingFive(props) {
  return (
    <>
      <H5 className={props.cname}>{props.h5Data}</H5>
    </>
  );
}
export default HeadingFive;
