import React, { useState } from "react";
import { FaSort } from "react-icons/fa";
import { BiEditAlt } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { AiFillEye } from "react-icons/ai";
import { DELETE, DOCS, NFT, UPDATE, USERS, VIEW } from "../../utils/constant";
import ActiveStatus from "../../common/ActiveStatus";
import InActiveStatus from "../../common/InActiveStatus";
import { Link } from "react-router-dom";
import { BsCardList } from "react-icons/bs";
import { HiDocumentText } from "react-icons/hi";
import Tooltip from "@material-ui/core/Tooltip";
import PendingStatus from "../../common/PendingStatus";
import { ImUsers } from "react-icons/im";

function ListLayout({
  handleOptions,
  tableHeader,
  handleSorting,
  currentItems,
  type,
  isHistory,
  documents,
  isUser,
  handlecount,
}) {
  const [order, setOrder] = useState(true);

  const handleSortTable = (item, orders, subItem) => {
    if (subItem) {
      handleSorting(subItem, orders);
    } else {
      handleSorting(item, orders);
    }
  };

  const handeldataShow = (dataItem, item) => {
    if (dataItem?.[item?.value] && !item.subValue && !item.subSubValue) {
      if (item?.key === "CompletedUserCount") {
        return (
          <a
            onClick={() => handlecount(true, dataItem?.id)}
            style={{ color: "blue", cursor: "pointer" }}
          >
            {dataItem && item && dataItem[item.value]}
          </a>
        );
      } else if (item?.key === "IncompleteTodosCount") {
        return (
          <a
            onClick={() => handlecount(false, dataItem?.id)}
            style={{ color: "blue", cursor: "pointer" }}
          >
            {dataItem && item && dataItem[item.value]}
          </a>
        );
      } else {
        return dataItem?.[item?.value];
      }
    } else if (
      dataItem?.[item?.value] &&
      dataItem?.[item?.value]?.[item?.subValue] &&
      !item.subSubValue
    ) {
      if (parseInt(dataItem?.[item?.value]?.[item?.subValue]) === 1) {
        return <ActiveStatus message={"Active"} />;
      } else if (parseInt(dataItem?.[item?.value]?.[item?.subValue]) === 2) {
        return <InActiveStatus message={"Inactive"} />;
      } else {
        return dataItem?.[item?.value]?.[item?.subValue];
      }
    } else if (
      item?.value &&
      item.subValue &&
      item.subSubValue &&
      Array?.isArray(dataItem?.[item?.value])
    ) {
      if (
        dataItem?.[item?.value][item?.positionInArray]?.[item?.subValue]?.[
          item?.subSubValue
        ]
      ) {
        return dataItem?.[item?.value][item?.positionInArray]?.[
          item?.subValue
        ]?.[item?.subSubValue];
      } else {
        return "--";
      }
    } else {
      return "--";
    }
  };

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="p-1.5  min-w-full inline-block align-middle">
          <div className="overflow-x-auto overflow-y-hidden border rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {tableHeader.map((item, key) => {
                    return (
                      <th
                        key={key}
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase max-w-xs truncate"
                      >
                        {item.sorting ? (
                          <span className="flex">
                            {item.key}
                            <FaSort
                              className="cursor-pointer text-md"
                              onClick={() => {
                                handleSortTable(
                                  item?.value,
                                  !order,
                                  item?.subValue
                                );
                                setOrder(!order);
                              }}
                            />
                          </span>
                        ) : (
                          <span className="flex float-left">{item.key}</span>
                        )}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {currentItems?.length > 0 &&
                  currentItems?.map((dataItem, dataKey) => {
                    if (
                      parseInt(dataItem.status) === 1 ||
                      parseInt(dataItem.status) === "1"
                    ) {
                      dataItem.status2 = dataItem.status;
                      dataItem.status = <ActiveStatus message={"Active"} />;
                    } else {
                      dataItem.status2 = dataItem.status;
                      dataItem.status = <InActiveStatus message={"Inactive"} />;
                    }
                    if (dataItem?.is_plan_expired === "1") {
                      dataItem.is_plan_expired = (
                        <ActiveStatus message={"Active"} />
                      );
                    } else if (dataItem?.is_plan_expired === "0") {
                      dataItem.is_plan_expired = "--";
                    } else if (dataItem?.is_plan_expired === "2") {
                      dataItem.is_plan_expired = (
                        <InActiveStatus message={"Inactive"} />
                      );
                    }

                    if (dataItem?.is_verified === 1) {
                      dataItem.is_verified = (
                        <ActiveStatus message={"Accepted"} />
                      );
                    } else if (dataItem?.is_verified === 2) {
                      dataItem.is_verified = (
                        <InActiveStatus message={"Rejected"} />
                      );
                    } else if (dataItem?.is_verified === 3) {
                      dataItem.is_verified = (
                        <PendingStatus message={"Pending"} />
                      );
                    }
                    return (
                      <tr key={dataKey}>
                        {tableHeader.map((item, key) => {
                          if (item.key === "options") {
                            return (
                              <td
                                key={key}
                                className="float-left px-5 py-4 font-normal text-gray-600 whitespace-nowrap flex justify-between max-w-xs overflow-hidden"
                              >
                                {isHistory ? (
                                  <>
                                    {" "}
                                    <Tooltip title="view" arrow>
                                      <span className="p-2">
                                        <AiFillEye
                                          className="cursor-pointer"
                                          onClick={() =>
                                            handleOptions(VIEW, dataItem)
                                          }
                                        />
                                      </span>
                                    </Tooltip>
                                    <Tooltip title="edit" arrow>
                                      <span className="p-2">
                                        <BiEditAlt
                                          className="cursor-pointer"
                                          onClick={() =>
                                            handleOptions(UPDATE, dataItem)
                                          }
                                        />
                                      </span>
                                    </Tooltip>
                                  </>
                                ) : (
                                  <>
                                    <Tooltip title="view" arrow>
                                      <span className="p-2">
                                        <AiFillEye
                                          className="cursor-pointer"
                                          onClick={() =>
                                            handleOptions(VIEW, dataItem)
                                          }
                                        />
                                      </span>
                                    </Tooltip>
                                    <Tooltip title="edit" arrow>
                                      <span className="p-2">
                                        <BiEditAlt
                                          className="cursor-pointer"
                                          onClick={() =>
                                            handleOptions(UPDATE, dataItem)
                                          }
                                        />
                                      </span>
                                    </Tooltip>
                                    <Tooltip title="delete" arrow>
                                      <span className="p-2">
                                        <MdDelete
                                          className="cursor-pointer"
                                          onClick={() =>
                                            handleOptions(DELETE, dataItem)
                                          }
                                        />
                                      </span>
                                    </Tooltip>
                                    {documents && (
                                      <Tooltip title="Identity docs" arrow>
                                        <span className="p-2">
                                          <HiDocumentText
                                            className="cursor-pointer"
                                            onClick={() =>
                                              handleOptions(DOCS, dataItem)
                                            }
                                          />
                                        </span>
                                      </Tooltip>
                                    )}
                                    {isUser && (
                                      <Tooltip title="Users" arrow>
                                        <span className="p-2">
                                          <ImUsers
                                            className="cursor-pointer"
                                            onClick={() =>
                                              handleOptions(USERS, dataItem)
                                            }
                                          />
                                        </span>
                                      </Tooltip>
                                    )}
                                  </>
                                )}
                                {type && (
                                  <Tooltip title="school common post" arrow>
                                    <span className="p-2">
                                      <Link
                                        to={`/admin/school/post/${dataItem.id}`}
                                      >
                                        <BsCardList className="cursor-pointer" />
                                      </Link>
                                    </span>
                                  </Tooltip>
                                )}
                              </td>
                            );
                          } else if (item.key === "S.No.") {
                            return (
                              <td
                                className={
                                  item?.value === "total_nft" || item?.clickable
                                    ? "px-6 py-4 text-sm font-medium text-gray-600 whitespace-nowrap cursor-pointer max-w-xs  truncate"
                                    : "px-6 py-4 text-sm font-medium text-gray-600 whitespace-nowrap max-w-xs truncate "
                                }
                                onClick={
                                  item?.value === "total_nft" || item?.clickable
                                    ? () => handleOptions(NFT, dataItem)
                                    : null
                                }
                                key={key}
                              >
                                {dataKey + 1}
                              </td>
                            );
                          } else {
                            return (
                              <td
                                className={
                                  item?.value === "total_nft" || item?.clickable
                                    ? "px-6 py-4 text-sm font-medium text-gray-600 whitespace-nowrap cursor-pointer max-w-xs  truncate"
                                    : "px-6 py-4 text-sm font-medium text-gray-600 whitespace-nowrap max-w-xs truncate "
                                }
                                onClick={
                                  item?.value === "total_nft" || item?.clickable
                                    ? () => handleOptions(NFT, dataItem)
                                    : null
                                }
                                key={key}
                              >
                                {/* {console.log(dataItem[`item?.value$[key]`])} */}

                                {handeldataShow(dataItem, item)}
                                {/* {dataItem?.[item?.value] &&
                              dataItem?.[item?.value]?.[item?.subValue]
                                ? dataItem?.[item?.value]?.[item?.subValue]
                                : dataItem?.[item?.value] &&
                                  dataItem?.[item?.value]?.toString()?.length >
                                    0
                                ? dataItem?.[item?.value]
                                : "--"} */}
                              </td>
                            );
                          }
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListLayout;
