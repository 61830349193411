import React, { useState, Fragment, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { GrClose } from "react-icons/gr";

function SchoolVotingEdit({
  viewData,
  openViewModal,
  setOpenViewModal,
  view,
  setEditData,
  updateData,
  error,
  setError,
  option,
  setDataToUpdate,
  dataToUpdate,
}) {
  const [standard, setStandard] = useState([
    {
      name: "Select grade level",
      value: "",
    },
    {
      name: "Freshmen",
      value: "freshmen",
      selected: false,
    },
    {
      name: "Sophomores",
      value: "sophomores",
      selected: false,
    },
    { name: "Juniors", value: "juniors", selected: false },
    { name: "Seniors", value: "seniors", selected: false },
  ]);

  useEffect(() => {
    setDataToUpdate({
      name: viewData.name ? viewData.name : "",
      status: viewData.status ? viewData.status : "",
      description: viewData?.description ? viewData?.description.trim() : "",
      // standard: viewData?.standard ? viewData?.standard.trim() : "",
    });
    setError({});
    setStandard((preVal) => {
      for (let i = 0; i < preVal?.length; i++) {
        if (preVal[i].value === viewData?.standard) {
          preVal[i].selected = true;
        } else {
          preVal[i].selected = false;
        }
      }
      return preVal;
    });
  }, [viewData, openViewModal]);

  const inputRef = useRef(null);

  // let imageUrl;
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value?.toString().trim().length > 0 || value === "") {
      setDataToUpdate((preVal) => {
        return { ...preVal, [name]: value };
      });
    }

    setError((preVal) => {
      return { ...preVal, [name]: false };
    });
  };

  const chooseFileBox = () => {
    inputRef?.current?.click();
  };

  const fileName = () => {
    if (viewData?.sport_profile) {
      if (Object.keys(viewData?.sport_profile).length > 0) {
        let file = viewData?.sport_profile?.split("/");
        return file?.[file?.length - 1];
      }
    }
    // setEditData((preVal) => {
    //   return { ...preVal, sport_profile: file?.[file?.length - 1] };
    // });
  };

  return (
    <Transition.Root show={openViewModal} as={Fragment}>
      <Dialog
        as='div'
        static
        className='fixed z-10 inset-0 overflow-y-auto'
        open={openViewModal}
        onClose={setOpenViewModal}>
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'>
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
            <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
              <div>
                <div className='mb-6 row block text-2xl font-medium text-gray-700'>
                  <span>Edit Details </span>
                  <span className='float-right cursor-pointer'>
                    {" "}
                    <GrClose onClick={() => setOpenViewModal(false)} />
                  </span>
                </div>
                {view.map((val, i) => {
                  return (
                    <>
                      <label className='block text-sm font-medium text-gray-700'>
                        {val.label}
                        <span className='text-red-600'>&#42;</span>
                      </label>

                      <input
                        type={val.type}
                        value={val.value}
                        name={val.name}
                        onChange={handleChange}
                        autoComplete='cc-given-name'
                        className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm'
                      />
                      {error[val.name] && (
                        <p className='text-sm text-red-500'>
                          Please fill the field
                        </p>
                      )}
                    </>
                  );
                })}

                <label className='block text-sm font-medium text-gray-700 mt-3 mb-3'>
                  Status<span className='text-red-600'>&#42;</span>
                  <label
                    htmlFor='default-toggle1'
                    className='inline-flex relative items-center cursor-pointer align-middle ml-5'>
                    <input
                      type='checkbox'
                      checked={dataToUpdate.status == 1 ? true : false}
                      name='status1'
                      onChange={() => {
                        setDataToUpdate((preVal) => {
                          return {
                            ...preVal,
                            status: dataToUpdate.status == 1 ? 2 : 1,
                          };
                        });
                      }}
                      id='default-toggle1'
                      className='sr-only peer'
                    />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </label>

                {/* Club Description */}

                <div className='mb-2'>
                  <label className='block text-sm font-medium text-gray-700'>
                    Description
                  </label>

                  <textarea
                    type={"text"}
                    value={dataToUpdate?.description}
                    name={"description"}
                    onChange={handleChange}
                    autoComplete='cc-given-name'
                    className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm'
                  />
                </div>
                {/* club description ends */}

                {/* Standard */}
                {/* <div>
                  <label
                    htmlFor='date'
                    className='block text-sm font-medium text-gray-700 mt-2'>
                    Grade Level<span className='text-red-600'>&#42;</span>
                  </label>
                  <div className='mt-1'>
                    <select
                      name='standard'
                      onChange={handleChange}
                      autoComplete='cc-given-date'
                      className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-500 bg-white focus:border-gray-500 sm:text-sm'>
                      {standard.map((val, i) => {
                        return (
                          <option
                            key={i}
                            value={val.value}
                            selected={val.selected}>
                            {val.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  {error["standard"] && (
                    <div className='text-sm text-red-500'>
                      Please select standard
                    </div>
                  )}
                </div> */}

                {/* Club profile pic upload */}
                {/* <div>
                  <label className='block text-sm font-medium text-gray-700'>
                    Team Profile Image
                  </label>
                  <img
                    className='w-10 h-10 bg-gray-300 rounded-full flex-shrink-0'
                    src={
                      dataToUpdate?.sport_profile &&
                      typeof dataToUpdate.sport_profile !== "string"
                        ? URL.createObjectURL(dataToUpdate?.sport_profile)
                        : viewData?.sport_profile
                        ? viewData?.sport_profile
                        : "https://t3.ftcdn.net/jpg/01/09/00/64/360_F_109006426_388PagqielgjFTAMgW59jRaDmPJvSBUL.jpg"
                    }
                    alt=''
                  />

                  <label
                    className={
                      viewData?.sport_profile
                        ? "mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                        : "mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                    }
                    onClick={chooseFileBox}>
                    {dataToUpdate?.sport_profile &&
                    Object.keys(dataToUpdate?.sport_profile).length > 0
                      ? "Change photo"
                      : " Select photo"}
                  </label>

                  <input
                    type={"file"}
                    ref={inputRef}
                    accept='image/*'
                    name='sport_profile'
                    onChange={handleChange}
                    className='hidden'
                  />
                </div> */}
                {/* club profile pic ends */}

                {/* <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="status">
                  Status
                </label>

                <select
                  name="status"
                  id="status"
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm">
                  {option.map((val, i) => {
                    return (
                      <option key={i} value={val.value}>
                        {val.name}
                      </option>
                    );
                  })}
                </select> */}

                <button
                  className='mt-2 px-4 py-2 bg-indigo-600 text-white outline-none rounded shadow hover:bg-indigo-700'
                  onClick={updateData}>
                  {" "}
                  Update
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default SchoolVotingEdit;
