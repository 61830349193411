import React, { useContext, useEffect, useState, useRef } from "react";
import { ADMIN_USER_DATA, PROFILE } from "../../utils/constant";
import {
  editAdminProfile,
  getAdminProfile,
} from "../../services/auth.services";
import { StatusCodes } from "http-status-codes";
import { loaderContext } from "../global loader/global-loader-provider";
import { toast } from "react-toastify";
import { AdminContext } from "../../common/DashboardWrapper";
import { IoEyeSharp } from "react-icons/io5";
import { BsEyeSlashFill } from "react-icons/bs";
import { FaBullseye } from "react-icons/fa";
import imageCompression from "browser-image-compression";

function AdminProfileEdit() {
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }

  const [adminData, setAdminData] = useState({});

  const [adminNewData, setNewAdminData] = useState({
    first_name: "",
    last_name: "",
    profile_picture: "",
  });
  const [passwordHideAndShow, setPasswordHideAndShow] = useState({
    new_password: false,
    confirm_password: false,
    old_password: false,
  });

  const inputRef = useRef(null);

  let count = 0;
  useEffect(() => {
    if (count === 0) {
      getAdminData();
    }
    count++;
    setAdminActiveTab(PROFILE);
  }, []);

  const [adminPassword, setAdminPassword] = useState({
    old_password: "",
    new_password: "",
    confirm_password: null,
  });

  const [profileErrorMessage, setProfileErrorMessage] = useState("");

  const [isError, setIsError] = useState({
    first_name: false,
    last_name: false,
    profile_picture: false,
  });

  const [isErrorPassword, setisErrorPassword] = useState({
    old_password: false,
    new_password: false,
    confirm_password: false,
  });

  const [isErrorFormat, setIsErrorFormat] = useState(false);

  const [passwordMatching, setPasswordMatching] = useState(false);

  const [passwordComparison, setPasswordComparison] = useState(false);
  const { loaderDispatch } = useContext(loaderContext);
  const { getData, setAdminActiveTab } = useContext(AdminContext);

  const getAdminData = async () => {
    loaderDispatch({
      isLoading: true,
    });
    let result = await getAdminProfile(userData.id);

    loaderDispatch({
      isLoading: false,
    });

    if (result?.data?.status === StatusCodes.OK) {
      setAdminData(result?.data?.data);
      setNewAdminData((preVal) => {
        let pic = result?.data?.data?.profile_picture;

        return {
          ...preVal,
          first_name: result?.data?.data?.first_name,
          last_name: result?.data?.data?.last_name,
          profile_picture: pic,
        };
      });
    }
  };

  const handleDataInserstion = (e) => {
    const { name, value } = e.target;
    setIsError((preVal) => {
      return { ...preVal, [name]: false };
    });

    if (name === "profile_picture") {
      if (e?.target?.files[0].type?.includes("image")) {
        setIsErrorFormat(false);
        if (
          e.target.files[0]?.type === "image/png" ||
          e.target.files[0]?.type === "image/jpg" ||
          e.target.files[0]?.type === "image/jpeg"
        ) {
          setNewAdminData((preVal) => {
            return { ...preVal, [name]: e?.target?.files[0] };
          });
        } else {
          setIsErrorFormat(true);
          setProfileErrorMessage(
            "File format not supported, please upload PNG, JPG, or JPEG file"
          );
        }
      } else {
        setIsErrorFormat(true);
        setProfileErrorMessage("Please upload an image file");
      }
    } else {
      if (value?.toString().trim().length > 0 || value.toString() === "") {
        setNewAdminData((preVal) => {
          if (name === "first_name" || name === "last_name") {
            if (
              (value?.toString().trim().length > 0 &&
                value?.toString().trim().length < 16 &&
                checkAlphaSpace(value)) ||
              value?.toString() === ""
            ) {
              return { ...preVal, [name]: value };
            } else {
              return { ...preVal, [name]: adminNewData[name] };
            }
          } else {
            return { ...preVal, [name]: value };
          }
        });
      } else {
        setNewAdminData((preVal) => {
          return preVal;
        });
      }
    }
  };

  async function handleImageUpload(imageFile) {
    let size = imageFile?.size / 1024 / 1024;
    if (size > 4) {
      const options = {
        maxSizeMB: 4,
        // maxWidthOrHeight: 1920,
        // alwaysKeepResolution: true,
      };
      try {
        loaderDispatch({
          isLoading: true,
        });
        const compressedFile = await imageCompression(imageFile, options);
        console.log("compressedFileSize", compressedFile.size / 1024 / 1024);

        return compressedFile;
      } catch (error) {
        console.log(error);
        loaderDispatch({
          isLoading: false,
        });
      }
    } else {
      return imageFile;
    }
  }

  const handelDataInsertionPassword = (e) => {
    const { name, value } = e.target;

    for (const key in isErrorPassword) {
      setisErrorPassword((preVal) => {
        return { ...preVal, [key]: false };
      });
    }
    setPasswordMatching(false);
    setAdminPassword((preVal) => {
      return { ...preVal, [name]: value };
    });
  };

  const handelBlur = (e) => {
    const { name } = e.target;
    if (adminNewData[name] === "") {
      setIsError((preVal) => {
        return { ...preVal, [name]: true };
      });
    } else {
      setIsError((preVal) => {
        return { ...preVal, [name]: false };
      });
    }
  };

  const handlePasswordBlur = (e) => {
    const { name, value } = e.target;
    if (adminPassword.old_password !== "") {
      if (adminPassword[name] === "") {
        setisErrorPassword((preVal) => {
          return { ...preVal, [name]: true };
        });
      } else {
        setisErrorPassword((preVal) => {
          return { ...preVal, [name]: false };
        });
      }

      if (
        adminPassword?.new_password?.length < 4 &&
        adminPassword?.new_password?.length > 0
      ) {
        setPasswordMatching(true);
      } else {
        setPasswordMatching(false);
      }
    }

    if (
      adminPassword.new_password !== adminPassword.confirm_password &&
      (name === "confirm_password" || name === "new_password") &&
      adminPassword?.confirm_password?.length > 0
    ) {
      setPasswordComparison(true);
    } else {
      setPasswordComparison(false);
    }
  };

  const handelSubmit = async () => {
    if (adminPassword.old_password !== "") {
      for (const key in adminPassword) {
        if (adminPassword[key] === "") {
          setisErrorPassword((preVal) => {
            return { ...preVal, [key]: true };
          });
        } else {
          setisErrorPassword((preVal) => {
            return { ...preVal, [key]: false };
          });
        }
      }
    }

    for (const key in adminNewData) {
      if (key !== "profile_picture") {
        if (adminNewData[key] === "") {
          setIsError((preVal) => {
            return { ...preVal, [key]: true };
          });
        } else {
          setIsError((preVal) => {
            return { ...preVal, [key]: false };
          });
        }
      }
    }

    let formData = new FormData();
    if (
      adminPassword.old_password !== "" &&
      !passwordMatching &&
      !isErrorFormat
    ) {
      if (
        adminPassword.new_password !== "" &&
        adminPassword.confirm_password !== "" &&
        adminNewData.first_name !== "" &&
        adminNewData.last_name !== "" &&
        adminNewData.profile_picture !== "" &&
        adminNewData.profile_picture &&
        !passwordComparison
      ) {
        if (typeof adminNewData.profile_picture === "object") {
          adminNewData.profile_picture = await handleImageUpload(
            adminNewData.profile_picture
          );
        }
        for (const key in adminNewData) {
          formData.append([key], adminNewData[key]);
        }

        for (const key in adminPassword) {
          if (key !== "confirm_password") {
            formData.append([key], adminPassword[key]);
          }
        }
        callAPI(formData);
      }
    }

    if (adminPassword.old_password === "" && !isErrorFormat) {
      if (
        adminNewData.first_name !== "" &&
        adminNewData.last_name !== "" &&
        adminNewData.profile_picture !== "" &&
        adminNewData.profile_picture &&
        !passwordComparison
      ) {
        if (typeof adminNewData.profile_picture === "object") {
          adminNewData.profile_picture = await handleImageUpload(
            adminNewData.profile_picture
          );
        }
        for (const key in adminNewData) {
          formData.append([key], adminNewData[key]);
        }
        callAPI(formData);
      }
    }
  };

  const callAPI = async (formData) => {
    loaderDispatch({
      isLoading: true,
    });
    let result = await editAdminProfile(formData, userData.id);
    loaderDispatch({
      isLoading: false,
    });
    if (result?.data?.status === StatusCodes.OK) {
      toast(result?.data?.message, {
        type: "success",
      });
      getAdminData();
      getData();
    } else {
      toast(result?.data?.message, {
        type: "error",
      });
      getAdminData();
    }
  };

  const handelImageName = () => {
    if (adminNewData?.profile_picture?.name) {
      return adminNewData?.profile_picture?.name;
    } else if (adminNewData?.profile_picture) {
      return "Change photo";
    } else {
      return "Select a profile picture";
    }
  };

  const chooseFileBox = () => {
    inputRef.current.click();
  };
  const alphaSpace = /^[A-Za-z\s]*$/;
  //checking condition
  const checkAlphaSpace = (value) => {
    return alphaSpace.test(value);
  };
  return (
    <div className='flex h-screen  justify-center mt-20'>
      <div
        className='space-y-6 sm:px-6 lg:px-0 lg:col-span-9'
        style={{ width: "80%", maxWidth: "80%" }}>
        <div className='shadow-lg sm:rounded-md sm:overflow-hidden'>
          <div className='bg-white py-6 px-4 space-y-6 sm:p-6'>
            <div>
              <h3 className='text-lg leading-6 font-medium text-gray-900'>
                Profile
              </h3>
            </div>

            <div className='grid grid-cols-4 gap-6'>
              <div className='col-span-4 sm:col-span-2'>
                <label
                  htmlFor='first_name'
                  className='block text-sm font-medium text-gray-700'>
                  First Name<span className='text-red-600'>&#x2a;</span>
                </label>
                <div className='mt-1 rounded-md shadow-sm flex'>
                  <input
                    type='text'
                    name='first_name'
                    id='first_name'
                    autoComplete='first_name'
                    value={adminNewData.first_name}
                    onChange={handleDataInserstion}
                    onBlur={handelBlur}
                    className='focus:ring-indigo-500  flex-grow block w-full min-w-0 rounded-md rounded-r-md sm:text-sm border-gray-300 border p-1 outline-none '
                  />
                </div>

                {isError.first_name && (
                  <div className='text-sm text-red-600'>
                    <p>Please fill the field</p>
                  </div>
                )}
              </div>

              <div className='col-span-4 sm:col-span-2'>
                <label
                  htmlFor='last_name'
                  className='block text-sm font-medium text-gray-700'>
                  Last Name<span className='text-red-600'>&#x2a;</span>
                </label>
                <div className='mt-1 rounded-md shadow-sm flex'>
                  <input
                    type='text'
                    name='last_name'
                    id='last_name'
                    autoComplete='off'
                    value={adminNewData.last_name}
                    onChange={handleDataInserstion}
                    onBlur={handelBlur}
                    className='focus:ring-indigo-500  flex-grow block w-full min-w-0 rounded-md rounded-r-md sm:text-sm border-gray-300 border p-1 outline-none  '
                  />
                </div>
                {isError.last_name && (
                  <p className='text-sm text-red-600'>Please fill the field</p>
                )}
              </div>

              <div className='col-span-4 sm:col-span-2'>
                <label
                  htmlFor='about'
                  className='block text-sm font-medium text-gray-700'>
                  Email
                </label>
                <div className='mt-1'>
                  <input
                    type='email'
                    id='about'
                    name='about'
                    defaultValue={adminData.email}
                    className='shadow-sm focus:ring-indigo-500  mt-1 block w-full sm:text-sm   rounded-md p-1 outline-none '
                    disabled
                  />
                </div>
              </div>
              <div className='col-span-4 sm:col-span-2'>
                <label
                  htmlFor='password'
                  className='block text-sm font-medium text-gray-700'>
                  Current password
                </label>
                <div className='mt-1 rounded-md shadow-sm flex border-gray-300 border  '>
                  <input
                    type={
                      passwordHideAndShow?.old_password ? "text" : "password"
                    }
                    name='old_password'
                    id='password'
                    autoComplete='off'
                    value={adminPassword.old_password}
                    onChange={handelDataInsertionPassword}
                    onBlur={handlePasswordBlur}
                    className='focus:ring-indigo-500  flex-grow block w-full min-w-0 rounded-md rounded-r-md sm:text-sm outline-none p-1'
                  />
                  {passwordHideAndShow?.old_password ? (
                    <BsEyeSlashFill
                      className='text-lg text-gray-400 cursor-pointer self-center mr-1'
                      onClick={() =>
                        setPasswordHideAndShow((preVal) => {
                          return {
                            ...preVal,
                            old_password: !preVal.old_password,
                          };
                        })
                      }
                    />
                  ) : (
                    <IoEyeSharp
                      className='text-lg text-gray-400 cursor-pointer self-center mr-1'
                      onClick={() =>
                        setPasswordHideAndShow((preVal) => {
                          return {
                            ...preVal,
                            old_password: !preVal.old_password,
                          };
                        })
                      }
                    />
                  )}
                </div>
                {isErrorPassword.old_password && (
                  <p className='text-sm text-red-600'>Please fill the field</p>
                )}
              </div>

              {adminPassword.old_password !== "" && (
                <>
                  <div className='col-span-4 sm:col-span-2'>
                    <label
                      htmlFor='new_password'
                      className='block text-sm font-medium text-gray-700'>
                      New Password<span className='text-red-600'>&#x2a;</span>
                    </label>
                    <div className='mt-1 rounded-md shadow-sm flex border-gray-300 border  '>
                      <input
                        type={
                          passwordHideAndShow?.new_password
                            ? "text"
                            : "password"
                        }
                        name='new_password'
                        id='new_password'
                        autoComplete='off'
                        value={adminPassword.new_password}
                        onChange={handelDataInsertionPassword}
                        onBlur={handlePasswordBlur}
                        className='focus:ring-indigo-500  flex-grow block w-full min-w-0 rounded-md rounded-r-md sm:text-sm outline-none p-1'
                      />

                      {passwordHideAndShow?.new_password ? (
                        <BsEyeSlashFill
                          className='text-lg text-gray-400 cursor-pointer self-center mr-1'
                          onClick={() =>
                            setPasswordHideAndShow((preVal) => {
                              return {
                                ...preVal,
                                new_password: !preVal.new_password,
                              };
                            })
                          }
                        />
                      ) : (
                        <IoEyeSharp
                          className='text-lg text-gray-400 cursor-pointer self-center mr-1'
                          onClick={() =>
                            setPasswordHideAndShow((preVal) => {
                              return {
                                ...preVal,
                                new_password: !preVal.new_password,
                              };
                            })
                          }
                        />
                      )}
                    </div>
                    {isErrorPassword.new_password && (
                      <p className='text-sm text-red-600'>
                        Please fill the field
                      </p>
                    )}
                    {passwordMatching && (
                      <p className='text-sm text-red-600'>
                        Password must be at least 4 characters long
                      </p>
                    )}
                  </div>
                  <div className='col-span-4 sm:col-span-2'>
                    <label
                      htmlFor='confirm_password'
                      className='block text-sm font-medium text-gray-700'>
                      Confirm Password
                      <span className='text-red-600'>&#x2a;</span>
                    </label>
                    <div className='mt-1 rounded-md shadow-sm flex border-gray-300 border'>
                      <input
                        type={
                          passwordHideAndShow?.confirm_password
                            ? "text"
                            : "password"
                        }
                        name='confirm_password'
                        id='confirm_password'
                        autoComplete='off'
                        value={adminPassword.confirm_password}
                        onChange={handelDataInsertionPassword}
                        onBlur={handlePasswordBlur}
                        className='focus:ring-indigo-500  flex-grow block w-full min-w-0 rounded-md rounded-r-md sm:text-sm outline-none  p-1'
                      />
                      {passwordHideAndShow?.confirm_password ? (
                        <BsEyeSlashFill
                          className='text-lg text-gray-400 cursor-pointer self-center mr-1'
                          onClick={() =>
                            setPasswordHideAndShow((preVal) => {
                              return {
                                ...preVal,
                                confirm_password: !preVal.confirm_password,
                              };
                            })
                          }
                        />
                      ) : (
                        <IoEyeSharp
                          className='text-lg text-gray-400 cursor-pointer self-center mr-1'
                          onClick={() =>
                            setPasswordHideAndShow((preVal) => {
                              return {
                                ...preVal,
                                confirm_password: !preVal.confirm_password,
                              };
                            })
                          }
                        />
                      )}
                    </div>
                    {isErrorPassword.confirm_password && (
                      <p className='text-sm text-red-600'>
                        Please fill the field
                      </p>
                    )}
                    {passwordComparison && (
                      <p className='text-sm text-red-600'>
                        New & confirm password must be same
                      </p>
                    )}
                  </div>
                </>
              )}

              <div className='col-span-3'>
                <label className='block text-sm font-medium text-gray-700'>
                  Change Photo
                </label>
                <div className='mt-1 flex items-center '>
                  <img
                    className='w-10 h-10 bg-gray-300 rounded-full flex-shrink-0'
                    src={
                      adminNewData.profile_picture &&
                      typeof adminNewData.profile_picture !== "string"
                        ? URL.createObjectURL(adminNewData.profile_picture)
                        : adminData.profile_picture
                        ? adminData.profile_picture
                        : "https://t3.ftcdn.net/jpg/01/09/00/64/360_F_109006426_388PagqielgjFTAMgW59jRaDmPJvSBUL.jpg"
                    }
                    alt=''
                  />

                  <label
                    className='ml-2  bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer'
                    onClick={chooseFileBox}>
                    {handelImageName()}
                  </label>

                  <input
                    type='file'
                    name='profile_picture'
                    accept='image/*'
                    value=''
                    ref={inputRef}
                    className='hidden'
                    // value={adminNewData.profile_picture}
                    onChange={handleDataInserstion}
                  />
                </div>
                {isErrorFormat && (
                  <p className='text-sm text-red-500'>{profileErrorMessage}</p>
                )}
              </div>
            </div>
          </div>
          <div className='px-4 py-3 bg-gray-50 text-right sm:px-6'>
            <button
              type='submit'
              onClick={handelSubmit}
              className='bg-purple-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-purple-700'>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminProfileEdit;
