import { StatusCodes } from "http-status-codes";
import React, { useContext, useState, useEffect } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getCountryList } from "../../../common_api/commonApi";
import { school } from "../../../linksprovider";
import {
  getCountryLists,
  // getCountryLists,
  getStudentDetails,
  updateStudent,
} from "../../../services/auth.services";
import { loaderContext } from "../../global loader/global-loader-provider";

function EditLayout() {
  const [studentInfo, setStudentInfo] = useState({
    id: "",
    student_name: "",
    email: "",
    address: "",
    state: "",
    status: false,
    country: "",
    city: "",
    pincode: "",
  });

  const [errorState, setErrorState] = useState({
    id: false,
    student_name: false,
    address: false,
    state: false,
    country: false,
    pincode: false,
    city: false,
    status: false,
  });
  const [countryList, setCountryList] = useState([]);

  const { id } = useParams();
  const navigate = useNavigate();
  const { loaderState, loaderDispatch } = useContext(loaderContext);

  let subscribe = true;
  useEffect(() => {
    if (subscribe) {
      getStudentDetail();
      getAllCountries();
    }
    return () => (subscribe = false);
  }, []);

  // getting detail of a student
  const getStudentDetail = async () => {
    loaderDispatch({
      isLoading: true,
    });
    try {
      let result = await getStudentDetails(id);
      if (result) {
        loaderDispatch({
          isLoading: false,
        });
      }
      if (result?.data?.status === StatusCodes.OK) {
        let state = result?.data?.data?.student_details;

        setStudentInfo({
          id: state.id,
          student_name: state.student_name ? state.student_name : "",
          email: state.email ? state.email : "",
          address: state.address ? state.address : "",
          state: state.state ? state.state : "",
          status: state.status == 1 || state?.status == 3 ? true : false,
          country: state.country ? state.country : "",
          pincode: state.pincode ? state.pincode : "",
          Subscription: state.Subscription ? state.Subscription : null,
          city: state.city ? state.city : "",
          currency: state.currency ? `(${state.currency})` : "",
        });
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  //handel changes in input field
  const handelChange = (e) => {
    const { name, value } = e.target;
    setErrorState((preVal) => {
      return { ...preVal, [name]: false };
    });
    if (value.toString().trim().length > 0 || value?.toString() === "") {
      setStudentInfo((preVal) => {
        if (name === "student_name") {
          if (value?.toString().length < 31 && checkAlphaSpace(value)) {
            return { ...preVal, [name]: value };
          } else {
            return { ...preVal };
          }
        } else if (name === "pincode") {
          if (!isNaN(parseInt(value)) || value === "") {
            return { ...preVal, [name]: value };
          } else {
            return { ...preVal };
          }
        } else if (name === "city" || name === "state") {
          if (checkAlphaSpace(value)) {
            return { ...preVal, [name]: value };
          } else {
            return { ...preVal };
          }
        } else {
          return { ...preVal, [name]: value };
        }
      });
    }
  };

  //checking empty data and updating data
  const saveStudentInfo = async () => {
    for (const key in studentInfo) {
      if (studentInfo[key] === "") {
        setErrorState((preVal) => {
          return {
            ...preVal,
            [key]: true,
          };
        });
      }
    }

    let values = Object.values(studentInfo);
    let valStatus = [];
    for (let i = 0; i < values.length; i++) {
      if (values[i] === "") {
        break;
      } else {
        valStatus.push(true);
      }
    }
    if (valStatus.length === Object.keys(studentInfo).length) {
      try {
        loaderDispatch({
          isLoading: true,
        });
        const data = {
          student_name: studentInfo.student_name,
          address: studentInfo.address,
          state: studentInfo.state,
          country: studentInfo.country,
          pincode: studentInfo.pincode,
          status: studentInfo.status == true ? 1 : 2,
          city: studentInfo.city,
        };
        let result = await updateStudent(id, data);

        loaderDispatch({
          isLoading: false,
        });

        if (result?.data?.status === StatusCodes.OK) {
          toast(result?.data?.message, {
            type: "success",
          });
        } else {
          toast(result?.data?.message, {
            type: "error",
          });
        }
        getStudentDetail();
        navigate(-1);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getAllCountries = async () => {
    try {
      let result = await getCountryList();
      setCountryList(result);
    } catch (error) {
      console.log(error);
    }
  };

  const alphaSpace = /^[A-Za-z\s]*$/;
  //checking condition
  const checkAlphaSpace = (value) => {
    return value.match(alphaSpace);
  };

  return (
    <div className='mt-10 sm:mt-0 h-auto w-full flex flex-row justify-center items-center bg-gray-50'>
      <div className='mt-5 md:col-span-2 md:mt-0'>
        <div>
          <div className='overflow-hidden shadow sm:rounded-md'>
            <div className='bg-white px-4 py-5 sm:p-6'>
              <h2 className='text-2xl font-bold text-gray-800'>Edit Details</h2>
              <div className='mb-6'>
                <hr />
              </div>
              <div className='grid grid-cols-6 gap-6'>
                <div className='col-span-6 sm:col-span-3'>
                  <label
                    htmlFor='first-name'
                    className='block text-sm font-medium text-gray-700'>
                    Full name<span className='text-red-600'>&#42;</span>
                  </label>
                  <input
                    type={"text"}
                    value={studentInfo.student_name}
                    name='student_name'
                    onChange={handelChange}
                    className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm px-2 py-2 outline-none border'
                  />
                  {errorState.student_name && (
                    <p className='text-sm text-red-500'>
                      Please fill the field
                    </p>
                  )}
                </div>

                <div className='col-span-6 sm:col-span-3'>
                  <label
                    htmlFor='email-address'
                    className='block text-sm font-medium text-gray-700'>
                    Email Address
                  </label>
                  <input
                    type={"text"}
                    value={studentInfo.email}
                    disabled
                    autoComplete='email'
                    className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm px-2 py-2 outline-none border'
                  />
                </div>

                <div className='col-span-6 sm:col-span-3'>
                  {console.log("studentInfo.status", studentInfo.status)}
                  <label className='block text-sm font-medium text-gray-700 mt-3'>
                    Status<span className='text-red-600'>&#42;</span>
                    <label
                      htmlFor='default-toggle'
                      className='inline-flex relative items-center cursor-pointer align-middle ml-5'>
                      <input
                        type='checkbox'
                        checked={studentInfo.status}
                        name='status'
                        onChange={() => {
                          setStudentInfo((preVal) => {
                            return { ...preVal, status: !studentInfo.status };
                          });
                        }}
                        id='default-toggle'
                        className='sr-only peer'
                      />
                      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </label>
                </div>
                {/* country dropdown start */}
                <div className='col-span-6 sm:col-span-3'>
                  <label
                    htmlFor='country'
                    className='block text-sm font-medium text-gray-700'>
                    Country<span className='text-red-600'>&#42;</span>
                  </label>
                  <select
                    type={"text"}
                    name='country'
                    onChange={handelChange}
                    autoComplete='country-name'
                    className='mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'>
                    <option value={studentInfo.country}>
                      {studentInfo.country
                        ? studentInfo.country
                        : "Select Country"}
                    </option>
                    {countryList?.map((val, i) => {
                      return (
                        <option value={val.country_name} key={i}>
                          {val.country_name}
                        </option>
                      );
                    })}
                  </select>
                  {errorState.country && (
                    <p className='text-sm text-red-500'>
                      Please fill the field
                    </p>
                  )}
                </div>
                {/* country dropdown end */}

                <div className='col-span-6'>
                  <label
                    htmlFor='street-address'
                    className='block text-sm font-medium text-gray-700'>
                    Street address<span className='text-red-600'>&#42;</span>
                  </label>
                  <input
                    type={"text"}
                    value={studentInfo.address}
                    name='address'
                    onChange={handelChange}
                    autoComplete='street-address'
                    className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm px-2 py-2 outline-none border'
                  />
                  {errorState.address && (
                    <p className='text-sm text-red-500'>
                      Please fill the field
                    </p>
                  )}
                </div>

                <div className='col-span-6 sm:col-span-6 lg:col-span-2'>
                  <label
                    htmlFor='city'
                    className='block text-sm font-medium text-gray-700'>
                    City<span className='text-red-600'>&#42;</span>
                  </label>
                  <input
                    type='text'
                    name='city'
                    value={studentInfo.city}
                    id='city'
                    maxLength={30}
                    minLength={0}
                    onChange={handelChange}
                    autoComplete='address-level2'
                    className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm px-2 py-2 outline-none border'
                  />
                  {errorState.city && (
                    <p className='text-sm text-red-500'>
                      Please fill the field
                    </p>
                  )}
                </div>

                <div className='col-span-6 sm:col-span-3 lg:col-span-2'>
                  <label
                    htmlFor='region'
                    className='block text-sm font-medium text-gray-700'>
                    State / Province<span className='text-red-600'>&#42;</span>
                  </label>
                  <input
                    type={"text"}
                    value={studentInfo.state}
                    name='state'
                    maxLength={30}
                    minLength={0}
                    onChange={handelChange}
                    autoComplete='address-level1'
                    className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm px-2 py-2 outline-none border'
                  />
                  {errorState.state && (
                    <p className='text-sm text-red-500'>
                      Please fill the field
                    </p>
                  )}
                </div>

                <div className='col-span-6 sm:col-span-3 lg:col-span-2'>
                  <label
                    htmlFor='postal-code'
                    className='block text-sm font-medium text-gray-700'>
                    ZIP code/ Postal code
                    <span className='text-red-600'>&#42;</span>
                  </label>
                  <input
                    type={"text"}
                    value={studentInfo.pincode}
                    name='pincode'
                    onChange={handelChange}
                    minLength={0}
                    maxLength={13}
                    autoComplete='postal-code'
                    className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm px-2 py-2 outline-none border'
                  />
                  {errorState.pincode && (
                    <p className='text-sm text-red-500'>
                      Please fill the field
                    </p>
                  )}
                </div>

                {studentInfo.Subscription && (
                  <>
                    <div className='col-span-6 sm:col-span-6 lg:col-span-2'>
                      <label
                        htmlFor='city'
                        className='block text-sm font-medium text-gray-700'>
                        Subsciption plan
                      </label>
                      <input
                        type='text'
                        name='title'
                        disabled
                        value={studentInfo.Subscription.title}
                        id='city'
                        autoComplete='address-level2'
                        className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm px-2 py-2 outline-none border'
                      />
                    </div>

                    <div className='col-span-6 sm:col-span-6 lg:col-span-2'>
                      <label
                        htmlFor='city'
                        className='block text-sm font-medium text-gray-700'>
                        Validity
                      </label>
                      <input
                        type='text'
                        name='city'
                        disabled
                        value={studentInfo.Subscription.description}
                        id='city'
                        autoComplete='address-level2'
                        className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm px-2 py-2 outline-none border'
                      />
                    </div>

                    <div className='col-span-6 sm:col-span-6 lg:col-span-2'>
                      <label
                        htmlFor='city'
                        className='block text-sm font-medium text-gray-700'>
                        {`Price ${studentInfo?.currency}`}
                      </label>
                      <input
                        type='number'
                        name='city'
                        disabled
                        value={studentInfo.Subscription.price}
                        id='city'
                        autoComplete='address-level2'
                        className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm px-2 py-2 outline-none border'
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className='bg-gray-50 px-4 py-3 text-right sm:px-6'>
              <button
                onClick={() => saveStudentInfo()}
                type='submit'
                className='inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>
                Save
              </button>
              <button
                type='button'
                onClick={() => navigate(-1)}
                className='inline-flex justify-center rounded-md border border-transparent bg-white py-2 px-4 text-sm font-medium text-gray-800  hover:bg-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 ml-3 shadow-md'>
                cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}

export default EditLayout;
