import { StatusCodes } from "http-status-codes";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../App";
import { SchoolContext } from "../../../common/SchoolDashboardWrapper";
import { schoolDashboard } from "../../../services/auth.services";
import { ADMIN_USER_DATA, DASHBOARD } from "../../../utils/constant";
import { loaderContext } from "../../global loader/global-loader-provider";
import { school } from "../../../linksprovider";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function SchoolDashboard() {
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }
  let subScribe = true;

  const { loaderDispatch } = useContext(loaderContext);
  const { setAdminActiveTab } = useContext(SchoolContext);

  const [gettingCountData, setGettingCountData] = useState({});

  const projects = [
    {
      name: "Total Number of Users",
      initials: "TNS",
      href: school.student,
      bgColor: "bg-purple-600",
      value: gettingCountData?.totalStudent
        ? gettingCountData?.totalStudent
        : 0,
    },
    {
      name: "Total Number of Clubs",
      initials: "TNC",
      href: school.club,
      bgColor: "bg-yellow-500",
      value: gettingCountData?.totalClubs ? gettingCountData?.totalClubs : 0,
    },
    {
      name: "Total Number of Posts  ",
      initials: "TNP",
      href: school.postDetails,
      bgColor: "bg-green-500",
      value: gettingCountData?.totalPosts ? gettingCountData?.totalPosts : 0,
    },
  ];

  useEffect(() => {
    if (subScribe) {
      gettingSchoolAdminData();
    }
    setAdminActiveTab(DASHBOARD);
    return () => (subScribe = false);
  }, []);

  const gettingSchoolAdminData = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let result = await schoolDashboard(userData.id);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        setGettingCountData(result?.data?.data);
      } else {
        toast(result?.data?.status, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex overflow-hidden bg-red">
      {/* Main column */}
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          {/* Pinned projects */}
          <div>
            <div className="flex-1 min-w-0">
              <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">
                {/* {schoolData.schoolName} */}
                {userData ? userData.school_name : null}
              </h1>
            </div>
          </div>
          <div className="p-4">
            <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
              Overview
            </h2>
            <ul className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-3">
              {projects.map((project) => (
                <li
                  key={project.name}
                  className="col-span-1 flex shadow-sm rounded-md"
                >
                  <div
                    className={classNames(
                      project.bgColor,
                      "flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md"
                    )}
                  >
                    <Link to={project.href}>{project.initials}</Link>
                  </div>
                  <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md">
                    <div className="flex-1 px-4 py-2 text-sm overflow-clip">
                      <Link
                        to={project.href}
                        className="text-gray-900 font-medium hover:text-gray-600"
                      >
                        {project.name} : {project.value}
                      </Link>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>{" "}
        </main>
      </div>
    </div>
  );
}

export default SchoolDashboard;
