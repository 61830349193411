/* eslint-disable react/prop-types */
import { createContext } from "react";
import useGlobalLoader from "./global-loader";
export const loaderContext = createContext({});

const GlobalLoaderProvider = ({ children }) => {
  return (
    <loaderContext.Provider value={useGlobalLoader()}>{children}</loaderContext.Provider>
  );
};

export default GlobalLoaderProvider;
