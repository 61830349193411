import React, { useState } from "react";
import { Main, Container } from "../../../common/website/HOC.jsx";
import styled from "styled-components";
import { device } from "../../../common/website/Devices";

import LoginBG from "../../../images/website/login_bg.svg";
import SiteLogo from "../../../common/website/SiteLogo.jsx";

import LoginForm from "./LoginForm.jsx";
import RegisterForm from "./RegisterForm.jsx";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";

const LoginInnerRow = styled.div`
  height: 100vh;
  position: relative;
  &::before {
    content: "";
    position: fixed;
    background: url(${LoginBG}) no-repeat;
    background-position: center;
    background-size: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    z-index: 1;
  }
  .login_logo {
    text-align: center;
    display: none;
    a {
      display: inline-block;
      img {
        width: 250px;
        max-width: 100%;
      }
    }
  }
  .content_col {
    height: 100%;
    position: relative;
    z-index: 2;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .full_screen_container {
    height: 100%;
  }
  @media ${device.laptop} {
    .content_col {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .login_logo {
      display: block;
      a {
        img {
          width: 310px;
        }
      }
    }
  }
`;

function Login() {
  // setup useState
  const [game, setGame] = useState("login");

  // create a handleClick function
  const handleClick = (gameState) => {
    setGame(gameState);
  };

  return (
    <>
      <Main>
        <LoginInnerRow className='content_full_screen'>
          <Container className='full_screen_container'>
            <div className='grid gap-4 md:grid-cols-2 items-center content_col text-white'>
              <SiteLogo cname='login_logo' link='#' />

              {(() => {
                switch (game) {
                  case "login":
                    return <LoginForm handleClick={handleClick} />;

                  case "forgot":
                    return <ForgotPassword handleClick={handleClick} />;
                  case "reset":
                    return <ResetPassword handleClick={handleClick} />;
                  default:
                    return null;
                }
              })()}
            </div>
          </Container>
        </LoginInnerRow>
      </Main>
    </>
  );
}

export default Login;
