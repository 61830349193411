import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import siteLogo from "../../../images/website/site_logo.svg";
import { Container } from "../../../common/website/HOC.jsx";
import { colors, space } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";
import Button from "../../../common/website/Button.jsx";

import Paragraph from "../../../common/website/Paragraph.jsx";

import IconFB from "../../../images/website/icon_fb.svg";
import IconYT from "../../../images/website/icon_yt.svg";
import IconInsta from "../../../images/website/icon_insta.svg";
import IconIN from "../../../images/website/icon_in.svg";
import IconTT from "../../../images/website/icon_twitter.svg";
import IconPin from "../../../images/website/icon_pinterest.svg";
import IconTikTok from "../../../images/website/icon_tiktok.svg";
import { website } from "../../../linksprovider";

const Footer = styled.footer`
  padding: ${space.space40} 0 0;
  .footer_link {
    color: #fff;
  }

  .logo {
    display: inline-block;
    max-width: 117px;
  }
  .smallParagaraph {
    margin: 24px auto 0;
    max-width: 890px;
    &.bold {
      margin: 24px auto;
      font-weight: 600;
    }
  }
  .menu_link {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    li {
      margin: 0 14px;
      position: relative;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
        &::before {
          display: none;
        }
      }
      &::before {
        content: "";
        position: absolute;
        background: ${colors.primary};
        width: 6px;
        height: 6px;
        border-radius: 100%;
        top: 0;
        bottom: 0;
        margin: auto;
        right: -15px;
        display: none;
      }
    }
    a {
      font-size: 0.875rem;
      line-height: 1.063rem;
      padding: 0;
      color: ${colors.white};
      transition: all 0.4s;
      &:hover {
        color: ${colors.primary};
        transition: all 0.4s;
      }
    }
  }
  .footer_bottom {
    margin-top: 24px;
  }
  .social_link_list {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &::before,
    &::after {
      content: "";
      position: absolute;
      background: #ffffff4d;
      width: 100%;
      height: 1px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
    li {
      padding: 0 8px;
      background: #0b0b0b;
      position: relative;
      z-index: 9;
      &:last-child {
        padding-right: 16px;
      }
      &:first-child {
        padding-left: 16px;
      }
    }
    a {
      width: 30px;
      height: 30px;
      background: rgba(48, 223, 242, 0.05);
      box-shadow: inset 0px 0px 10px rgba(48, 223, 242, 0.3);
      backdrop-filter: blur(5px);
      border-radius: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      transition: all 0.4s;
      &:hover {
        box-shadow: inset 0px 0px 10px rgb(48 223 242);
        transition: all 0.4s;
      }
    }
  }
  .copyright {
    padding: ${space.space20} 0 ${space.space10};
    margin: 0;
    max-width: 100%;
    text-align: center;
  }
  @media ${device.mobileM} {
    .menu_link {
      li {
        &::before {
          display: block;
        }
      }
    }
    .social_link_list {
      li {
        &:last-child {
          padding-right: 30px;
        }
        &:first-child {
          padding-left: 30px;
        }
        a {
          width: 38px;
          height: 38px;
        }
      }
    }
  }
  @media ${device.mobileL} {
    .smallParagaraph {
      margin: 30px auto 0;
      &.bold {
        margin: 24px auto 38px;
      }
    }
    .footer_bottom {
      margin-top: 24px;
    }
    .copyright {
      padding: ${space.space30} 0;
      margin: 0;
    }
    .menu_link {
      li {
        margin: 0 23px;
        &::before {
          right: -23px;
        }
      }
    }
    .social_link_list {
      li {
        padding: 0 11px;
      }
    }
  }
  @media ${device.tablet} {
    padding: ${space.space70} 0 0;
  }
`;

function FooterMian({ handleClick }) {
  return (
    <>
      <Footer>
        <div className='footer_top text-center'>
          <Container>
            <Link to='/' className='logo'>
              <img
                src={siteLogo}
                srcSet={siteLogo}
                className='sitelogo_img'
                alt='YearBook3.0'
              />
            </Link>
            <Paragraph
              cname='smallParagaraph'
              pData={
                "Someday one of these students will blossom into a celebrity, a president, an artist, or a star athlete. YB3’s yearbook binds the senior class as a whole unit, allows students to create their own real-time memories, and secures these moments forever on Blockchain."
              }
            />
            <Paragraph
              cname='smallParagaraph bold'
              pData={
                "Building communities that last a lifetime –YB3 is the forever digital journal"
              }
            />
            <ul className='menu_link'>
              <li>
                <Button
                  tag='a'
                  // to='/ContactUs'
                  cname='footer_link cursor-pointer'
                  buttonData={"Contact Us"}
                  onClick={handleClick}
                />
              </li>
              <li>
                <Button
                  tag='a'
                  //  to='#'
                  cname='footer_link'
                  buttonData={"FAQ"}
                />
              </li>
              <li>
                <Link to={website.privacyPolicy} className='footer_link'>
                  Privacy Policy
                  {/* <Button
                    tag='button'
                    // to=''
                    cname='footer_link'
                    buttonData={"Privacy Policy"}></Button> */}
                </Link>
              </li>
              <li>
                <Link to={website.termsAndCondition} className='footer_link'>
                  Terms & Conditions
                  {/* <Button
                    tag='button'
                    // to=''
                    cname='footer_link'
                    buttonData={"Terms & Conditions"}
                  /> */}
                </Link>
              </li>
            </ul>
          </Container>
        </div>
        <div className='footer_bottom'>
          <ul className='social_link_list'>
            <li>
              <a
                href='https://www.facebook.com/yearbook3.official'
                target='_blank'
                className='link'
                rel='noreferrer'>
                <img src={IconFB} className='fb' alt='fb' />
              </a>
            </li>
            <li>
              <a
                href='https://www.instagram.com/yearbook_3/'
                target='_blank'
                className='link'
                rel='noreferrer'>
                <img src={IconInsta} className='fb' alt='insta' />
              </a>
            </li>
            <li>
              <a
                href='https://twitter.com/yearbookYB3'
                target='_blank'
                className='link'
                rel='noreferrer'>
                <img src={IconTT} className='fb' alt='twitter' />
              </a>
            </li>
            <li>
              <a
                href='https://www.linkedin.com/company/yb3-0digitalyearbook/'
                target='_blank'
                className='link'
                rel='noreferrer'>
                <img src={IconIN} className='fb' alt='linkedin' />
              </a>
            </li>
            <li>
              <a
                href='https://www.pinterest.com/YB3Yearbook/'
                target='_blank'
                className='link'
                rel='noreferrer'>
                <img src={IconPin} className='fb' alt='pinterest' />
              </a>
            </li>
            <li>
              <a
                href='https://www.tiktok.com/@yearbook30'
                target='_blank'
                className='link'
                rel='noreferrer'>
                <img src={IconTikTok} className='fb' alt='tiktok' />
              </a>
            </li>
            <li>
              <a
                href='https://www.youtube.com/channel/UC2RIf_Uv9gPOJRQ9ghrIZcg?sub_confirmation=1'
                target='_blank'
                className='link'
                rel='noreferrer'>
                <img src={IconYT} className='fb' alt='youTube' />
              </a>
            </li>
          </ul>
          <Paragraph
            cname='smallParagaraph copyright'
            pData={`Copyright © YB3 ${new Date().getFullYear()}. All Rights Reserved`}
          />
        </div>
      </Footer>
    </>
  );
}

export default FooterMian;
