import React, { useState, Fragment, useEffect, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { GrClose } from "react-icons/gr";
import { createTask, studentListing } from "../../services/auth.services";
import { loaderContext } from "../../components/global loader/global-loader-provider";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import { ADMIN_USER_DATA } from "../../utils/constant";
import Select from "react-select";
function CreateTaskModal({
  openCreateClubModal,
  setOpenCreateClubModal,
  id,
  handelClubListing,
}) {
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  const [searchItem, setSearchItem] = useState("");
  const [data, setData] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  console.log("selectedUserIds", selectedUserIds);
  const [itemsPerPage, setItemsPerPage] = useState(10000);
  const [sortby, setSortby] = useState("");
  const [orderby, setOrderby] = useState(true);
  const [page, setPage] = useState(1);

  const [submit, setSubmit] = useState(false);
  const [clubData, setclubData] = useState({
    title: "",
    status: "1",
    school_id: id,
    description: "",
    startDate: "",
    dueDate: "",
  });

  const { loaderDispatch } = useContext(loaderContext);

  useEffect(() => {
    setclubData({
      title: "",
      status: "1",
      school_id: id,
      description: "",
      startDate: "",
      dueDate: "",
    });
    handleSchoolData();
    setSubmit(false);
  }, [openCreateClubModal]);

  const handleSchoolData = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let order = orderby ? "desc" : "asc";

      if (searchItem.toLowerCase() === "active") {
        setSearchItem("1");
      } else if (searchItem.toLowerCase() === "inactive") {
        setSearchItem("2");
      }
      let result = await studentListing(
        userData.id,
        `&search=${searchItem.trim()}&sort_by=${sortby}&order=${order}&page=${page}&limit=${itemsPerPage}`
      );
      loaderDispatch({
        isLoading: false,
      });
      setData([]);

      const studentData = result?.data?.data?.studentListing;

      if (result?.data?.status === StatusCodes.OK) {
        setData(studentData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handelChange = (e) => {
    const { name, value } = e.target;
    setSubmit(false);

    if (value?.toString().trim().length > 0 || value === "") {
      setclubData((preVal) => {
        return { ...preVal, [name]: value };
      });
    }
  };

  const handelChangeselect = (event) => {
    if (
      event &&
      event.length > 0 &&
      event[event.length - 1].value === "selectAll"
    ) {
      // If "Select All" is selected, set selectedUserIds to all options except "Select All"
      const allUsers = options.filter((option) => option.value !== "selectAll");
      setSelectedUserIds("");
      setSelectedUserIds(allUsers);
    } else {
      setSelectedUserIds(event);
    }
  };

  const handelSubmit = async () => {
    try {
      if (
        clubData.title === "" ||
        clubData.description === "" ||
        clubData.startDate ||
        clubData.dueDate ||
        selectedUserIds.length === 0
      ) {
        setSubmit(true);
      }

      if (
        clubData.title !== "" &&
        clubData?.description !== "" &&
        clubData?.startDate !== "" &&
        clubData?.dueDate !== "" &&
        selectedUserIds.length > 0
      ) {
        loaderDispatch({
          isLoading: true,
        });

        const arrayOfStrings = selectedUserIds.map((obj) =>
          obj.value.toString()
        );

        let data = {
          title: clubData?.title.trim(),
          description: clubData?.description.trim(),
          startDate: clubData?.startDate,
          dueDate: clubData?.dueDate,
          userIds: arrayOfStrings,
        };

        let result = await createTask(data);
        loaderDispatch({
          isLoading: false,
        });

        if (result?.data?.status === StatusCodes.OK) {
          toast(result?.data?.message, {
            type: "success",
          });
          setOpenCreateClubModal(false);
          handelClubListing();
        } else {
          toast(result?.data?.message, {
            type: "error",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const options = data?.map((singleitem) => ({
    value: singleitem?.id,
    label: singleitem?.student_name,
  }));

  options.unshift({
    value: "selectAll",
    label: "Select All",
  });

  return (
    <Transition.Root show={openCreateClubModal} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={openCreateClubModal}
        onClose={setOpenCreateClubModal}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mb-6 row block text-2xl font-medium text-gray-700">
                  <span>Add Task Details </span>
                  <span className="float-right cursor-pointer">
                    {" "}
                    <GrClose onClick={() => setOpenCreateClubModal(false)} />
                  </span>
                </div>
                {/* club name */}
                <div className="mb-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Title <span className="text-red-500">*</span>
                  </label>

                  <input
                    type={"text"}
                    value={clubData.title}
                    name={"title"}
                    maxLength={60}
                    minLength={0}
                    onChange={handelChange}
                    autoComplete="cc-given-name"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                  />
                  {submit && clubData.title === "" && (
                    <p className="text-sm text-red-500">
                      Please fill the field
                    </p>
                  )}
                </div>
                {/* club name ends */}

                {/* Club Description */}

                <div className="mb-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Description <span className="text-red-500">*</span>
                  </label>

                  <textarea
                    type={"text"}
                    value={clubData.description}
                    name={"description"}
                    onChange={handelChange}
                    autoComplete="cc-given-name"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                  />
                  {submit && clubData.description === "" && (
                    <p className="text-sm text-red-500">
                      Please fill the field
                    </p>
                  )}
                </div>

                <div className="mb-2">
                  <label className="block text-sm font-medium text-gray-700">
                    StartDate <span className="text-red-500">*</span>
                  </label>

                  <input
                    type={"date"}
                    value={clubData.startDate}
                    name={"startDate"}
                    onChange={handelChange}
                    autoComplete="cc-given-name"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                  />
                  {submit && clubData.startDate === "" && (
                    <p className="text-sm text-red-500">
                      Please fill the field
                    </p>
                  )}
                </div>

                <div className="mb-2">
                  <label className="block text-sm font-medium text-gray-700">
                    DueDate <span className="text-red-500">*</span>
                  </label>

                  <input
                    type={"date"}
                    value={clubData.dueDate}
                    name={"dueDate"}
                    onChange={handelChange}
                    autoComplete="cc-given-name"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                  />
                  {submit && clubData.dueDate === "" && (
                    <p className="text-sm text-red-500">
                      Please fill the field
                    </p>
                  )}
                </div>

                <div className="mb-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Select Users <span className="text-red-500">*</span>
                  </label>
                  <Select
                    name="userIds"
                    id="userIds"
                    isMulti
                    value={selectedUserIds}
                    onChange={handelChangeselect}
                    options={options}
                    className=""
                    placeholder="Select Users..."
                    isSearchable
                    menuPlacement="top"
                  ></Select>
                  {submit && selectedUserIds.length === 0 && (
                    <p className="text-sm text-red-500">
                      Please select user field
                    </p>
                  )}
                </div>

                <button
                  className="mt-4 block w-full border border-indigo-600 rounded-md shadow-sm py-2 px-3 bg-indigo-600 text-white font-semibold focus:outline-none  sm:text-sm"
                  onClick={handelSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default CreateTaskModal;
