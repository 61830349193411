import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { colors, space } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";
import { SiteForm } from "../../../common/website/HOC.jsx";

import Label from "../../../common/website/Label.jsx";
import Button from "../../../common/website/Button.jsx";
import InfoBtn from "../../../images/website/icon_info_button.svg";
import * as Yup from "yup";
import { PostContext } from "../../../components/website/header/MainHeader";
import { loaderContext } from "../../../components/global loader/global-loader-provider";
import { updateMetaMask } from "../../../services/auth.services";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";

const MetaMaskWalletRow = styled.div`
  .meta_mask {
    text-align: right;
    margin-bottom: ${space.space24};
    .link {
      font-size: 0.875rem;
      padding: 0;
      margin-top: ${space.space14};
      &:hover {
        color: ${colors.secondary};
      }
    }
    label {
      text-align: start;
    }
  }
  .tool_tip_body {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    .tooltip {
      position: relative;
      display: inline-block;
      border-bottom: 1px dotted black;
      cursor: pointer;
      margin-bottom: 0.5rem;
      &:hover {
        .tooltiptext {
          visibility: visible;
        }
      }
    }
    .tooltiptext {
      font-size: 0.875rem;
      line-height: 1.25rem;
      visibility: hidden;
      width: 116px;
      background-color: ${colors.primary};
      color: ${colors.secondary};
      text-align: center;
      border-radius: 6px;
      padding: 5px;
      position: absolute;
      z-index: 1;
      bottom: 150%;
      left: 50%;
      margin-left: -60px;
      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: ${colors.primary} transparent transparent transparent;
      }
    }
  }
  .btn_group {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: ${space.space16};
  }
  @media ${device.mobileM} {
    .meta_mask {
      .link {
        font-size: 1rem;
      }
    }
  }
  @media ${device.mobileL} {
    .meta_mask {
      margin-bottom: ${space.space30};
    }
  }
  @media ${device.tablet} {
    .meta_mask {
      margin-bottom: ${space.space50};
    }
  }
`;

function MetaMaskWallet(props) {
  const [MetaMaskAddress, setMetaMaskAddress] = useState("");
  const [MetamaskInstallationError, setMetamaskInstallationError] =
    useState(false);
  const [metaMaskValueErorr, setmetaMaskValueErorr] = useState(false);

  const { userInfo } = useContext(PostContext);
  const { loaderDispatch } = useContext(loaderContext);

  // Sets the metamask address based on the effect.
  useEffect(() => {
    if (userInfo && userInfo?.metamask_id) {
      setMetaMaskAddress(userInfo?.metamask_id);
    }
  }, [userInfo]);

  // Connect to the Metamask.
  const connectToMetaMask = () => {
    if (window.ethereum) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((res) => {
          // Return the address of the wallet
          setmetaMaskValueErorr(false);
          setMetamaskInstallationError(false);
          setMetaMaskAddress(res);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      setMetamaskInstallationError(true);
    }
  };

  // Updates handel meta mask.
  const handelUpdateMetaMask = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let body = {
        metamask_id: MetaMaskAddress,
      };
      if (MetaMaskAddress && MetaMaskAddress !== "") {
        let result = await updateMetaMask(body, userInfo?.id);
        loaderDispatch({
          isLoading: false,
        });
        if (result?.data?.status === StatusCodes.OK) {
          toast(result?.data?.message, {
            type: "success",
            theme: "dark",
          });
          props.getinfo();
        } else {
          toast(result?.data?.message, {
            type: "error",
            theme: "dark",
          });
        }
      } else {
        loaderDispatch({
          isLoading: false,
        });
        setmetaMaskValueErorr(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Handles canceling the metamask.
  const handelCancel = () => {
    setMetaMaskAddress(userInfo?.metamask_id || "");
    setmetaMaskValueErorr(false);
    setMetamaskInstallationError(false);
  };
  return (
    <MetaMaskWalletRow className='tab_body'>
      <ul className='tab_list'>
        <li
          className='tab_item'
          onClick={() => props.handleClick("my_subscription_plan")}>
          My Subscription Plan
        </li>
        <li
          className='tab_item'
          onClick={() => props.handleClick("edit_profile")}>
          Edit Profile
        </li>
        {props.metaMaskShow && (
          <li className='tab_item active'>Meta Mask Wallet</li>
        )}
        <li className='tab_item' onClick={() => props.handleClick("id")}>
          ID
        </li>
      </ul>

      <SiteForm as='div' className='profile_form'>
        <div className='grid md:gap-7 lg:grid-cols-2 items-center form_label_group'>
          <div className='form_group meta_mask'>
            <div className='tool_tip_body'>
              <Label labelData={"Meta Mask Wallet Address"} />
              <span className='tooltip'>
                <img src={InfoBtn} />{" "}
                <span className='tooltiptext'>Address Required</span>
              </span>
            </div>
            <input
              type='text'
              name='text2'
              placeholder='Enter address'
              disabled
              value={MetaMaskAddress}
            />
            {MetamaskInstallationError && (
              <span className='text-sm text-red-500 float-left '>
                Please Install Meta Mask Extension{" "}
              </span>
            )}
            {metaMaskValueErorr && (
              <span className='text-sm text-red-500 float-left '>
                Please fill this field{" "}
              </span>
            )}
            <Button
              tag='button'
              cname='link'
              buttonData={"Connect Wallet"}
              onClick={connectToMetaMask}
            />
          </div>
        </div>
        <div className='btn_group'>
          <Button
            tag='button'
            type='submit'
            cname='gradient btn'
            buttonData={"Update"}
            onClick={handelUpdateMetaMask}
          />
          <Button
            tag='button'
            type='submit'
            cname='outline btn'
            buttonData={"Cancel"}
            onClick={handelCancel}
          />
        </div>
      </SiteForm>
    </MetaMaskWalletRow>
  );
}

export default MetaMaskWallet;
