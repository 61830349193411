export const admin = {
  login: "/admin",
  dashbord: "/admin/dashboard",
  school: "/admin/schools",
  student: "/admin/student",
  profile: "/admin/profile",
  editProfile: "/admin/profile/edit",
  forgotPassword: "/admin/forgotpassword",
  setting: "/admin/generalsetting",
  emailTemplate: "/admin/emailtemplate",
  newPassword: "/admin/newpassword/:u_id",
  plans: "/admin/plans",
  editor: "/admin/editor",
  Cms: "/admin/cms",
  CmsEditor: "/admin/cms/editor/:id",
  CmsView: "/admin/cms/view/:id",
  club: "/admin/club",
  clubView: "/admin/clubs/view/:ids",
  emailTemplateView: "/admin/emailTemplate/view/:id",
  emailTemplateEdit: "/admin/emailTemplate/Edit/:id",
  schoolPost: "/admin/school/post/:schoolId",
  studentEdit: "/admin/student/:id",
  schoolEdit: "/admin/school/edit/:schoolId",
  clubUserAddAndRemove: "/admin/clubs/user/add/remove/:id",
  NFTPost: "/admin/student/nft/:id",
  report: "/admin/report",
  reportDetails: "/admin/reports/details/:id",
  sports: "/admin/sports",
  sportView: "/admin/team/view/:ids",
};

export const school = {
  dashbord: "/school/dashboard",
  registration: "/school/school-registration/:id",
  student: "/school/students",
  complate: "/complate/students/:id",
  incomplate: "/incomplate/students/:id",
  studentEdit: "/school/students/:id",
  viewStudent: "/school/view-student/:id",
  setting: "/school/profile",
  postDetails: "/school/posts",
  postViewDetails: "/school/post-details/:id",
  club: "/school/clubs",
  task: "/school/tasks",
  clubView: "/school/clubs/view/:ids",
  taskView: "/school/task/view/:ids",
  clubUserAddAndRemove: "/school/clubs/user/add/remove/:id",
  teamUserAddAndRemove: "/school/teams/user/add/remove/:id",

  yearBook: "/school/yearbook",
  yearBookStudents: "/school/yearbook/student/:year",
  NFTPost: "/school/students/nft/:id",
  report: "/student/report",
  taskreport: "/student/taskreport",
  sports: "/school/sports",
  sportView: "/school/team/view/:ids",
  vote: "/school/voting",
  votingView: "/school/voting/view/:ids/:academicYear/:standard/:type",
  votingHistory: "/school/voting/history",
  votingCategoryHistory:
    "/school/voting/academicYear/history/:academicYear/:standard",
  votingStandardHistory: "/school/voting/category/history/:academicYear",
  labels: "/school/labels",
  broadcast: "/school/broadcast",
  webLink: "/school/webLink",
  job: "/school/jobCentre",
  jobUser: "/school/jobCentre/users",
};

export const student = {
  dashbord: "/student/dashboard",
  registration: "/student/student-registration/:u_id",
  purchasePlan: "/student/purchase-plan/:id",
  subscribeOurPlan: "/subscribeourplan",
  post: "/student/post",
  club: "/student/Club",
  notification: "/student/Notification",
  chat: "/student/Chat",
  gallery: "/student/gallery",
  aboutUs: "/student/AboutUs",
  contactUs: "/student/ContactUs",
  myPost: "/student/MyPost",
  setting: "/student/Settings",
  sports: "/student/sportsclub",
  digitalArtDetails: "/student/digitalartcreator/filters",
  digitalArt: "/student/digitalartcreator",
  myMoment: "/student/mymoment",
  otherStudentProfile: "/student/profile/:email",
  signatures: "/student/signatures",
  voting: "/VotingLeaderboard",
};

export const website = {
  landing: "/",
  about: "/aboutUs",
  studentLogin: "/login",
  resetPassword: "/resetPassword/:u_id",
  stripe: "/checkout/:amount/:plan_type/:id",
  privacyPolicy: "/PrivacyPolicy",
  termsAndCondition: "/TermsCondition",
  thankYou: "/thankYou",
  pageNotFound: "*",
};
