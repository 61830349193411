import React from "react";
import styled from "styled-components";
import { space } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";
import { Container } from "../../../common/website/HOC.jsx";

import Paragraph from "../../../common/website/Paragraph.jsx";
import HeadingTwo from "../../../common/website/HeadingTwo.jsx";
import HeadingFive from "../../../common/website/HeadingFive.jsx";

import AbotBanner from "../../../images/website/abot_banner_graphics.png";

const LandingAboutRow = styled.section`
  padding: 0 0 ${space.space40};
  .about_image {
    padding: 0 0 ${space.space40};
    iframe {
      width: 100%;
      height: 320px;
      object-fit: cover;
    }
  }
  .about_content {
    h2 {
      margin-bottom: ${space.space16};
    }
    p {
      margin-bottom: ${space.space16};
      &.noMargin {
        margin: 0;
      }
    }
  }
  .fw-bold {
    font-weight: 700;
  }

  @media ${device.tablet} {
    padding: 0 0 ${space.space150};
    .about_content {
      h2 {
        margin-bottom: ${space.space30};
      }
      h5 {
        margin-bottom: ${space.space16};
      }
    }
    .about_col {
      align-items: stretch;
    }
    .about_image {
      padding: 0;
      iframe {
        height: 100%;
      }
    }
  }
  @media ${device.laptop} {
    .about_image {
      padding: 0;
    }
  }
`;

function LandingAbout() {
  return (
    <>
      <LandingAboutRow>
        <Container>
          <div className='grid md:grid-cols-2 gap-4 items-start about_col'>
            <div className='about_image'>
              {/* <img src={AbotBanner} alt='Yearbook 3.0' /> */}
              <iframe
                width='560'
                height='315'
                src='https://www.youtube.com/embed/3hsOWLPiLFQ?rel=0'
                title='YouTube video player'
                frameborder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share full-screen'
                allowFullScreen
                rec></iframe>
            </div>
            <div className='about_content'>
              <HeadingTwo
                cname='white noMargin'
                h2Data={"YB3- Changing Digital Yearbooks"}
              />
              <Paragraph
                cname='fw-bold'
                pData={
                  "YB3 is a new disruptive technology that changes how a yearbook is created, owned, stored, bought, and sold."
                }
              />
              {/* <HeadingFive h5Data={"YB3 is a new disruptive technology that changes how a yearbook is created, owned, stored, bought, and sold."}/> */}
              <Paragraph
                pData={
                  "Yearbook3 gives students the ability to contribute to their own yearbook. Students upload videos, moments, and shared experiences in real-time creating using a simple, familiar interface. YB3 offers peer-created moments to keep private, share with the community, and own forever-all created on a mobile app."
                }
              />
              <Paragraph
                cname='noMargin'
                pData={
                  "THE YB3 PLEDGE - unlike social media platforms, YB3 will never sell or compromise your identity to a 3rd party company. YB3 will keep the user experience in the true spirit of today’s blockchain-educated consumers- Your experience, you control it."
                }
              />
            </div>
          </div>
        </Container>
      </LandingAboutRow>
    </>
  );
}

export default LandingAbout;
