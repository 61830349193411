import React, { useContext, useState } from "react";
import Paragraph from "./Paragraph";
import IconClose from "../../images/website/icon_close.svg";
import IconReport from "../../images/website/icon_report.svg";
import IconWhitePublic from "../../images/website/icon_white_public.svg";
import IconWhitePrivate from "../../images/website/icon_white_private.svg";
import IconDots from "../../images/website/icon_dots.svg";
import dateDifference from "date-difference";
import blankProfile from "../../images/website/dummy_user_profile.svg";
import { updateSignature } from "../../services/auth.services";
import { loaderContext } from "../../components/global loader/global-loader-provider";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import { ADMIN_USER_DATA } from "../../utils/constant";

function SignaturesData({
  profileImage,
  name,
  duration,
  description,
  id,
  setSignatureData,
  signatureUserId,
  academic_year,
  handelFilterClassmateList,
  filterType,
  modes,
  type,
}) {
  const [open, setOpen] = useState(false);
  // Notification Dropdown
  const handleOpen = () => {
    setOpen(!open);
  };
  // End Post Dropdown

  const { loaderDispatch } = useContext(loaderContext);
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));

  const commentTime = (val) => {
    let date = new Date(val);
    let vals = dateDifference(new Date(val), new Date(new Date()), {
      compact: true,
    });
    let vals2 = vals.slice(1);

    if (vals2 === "1d") {
      return `yesterday ${date?.getHours()}:${date?.getMinutes()} `;
    } else if (vals2?.includes("ms")) {
      return `few seconds ago`;
    } else if (vals2?.includes("m")) {
      return `${vals2} ago`;
    } else if (vals2?.includes("s")) {
      return `few seconds ago`;
    } else if (vals2?.includes("h")) {
      return `${vals2} ago`;
    } else {
      return `${date?.getDate()}-${
        date?.getMonth() + 1
      }-${date.getFullYear()} ${date?.getHours()}:${date?.getMinutes()} `;
    }
  };

  const handelUpdateSignature = async ({ mode = null, status = null }) => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let body = {
        academic_year: academic_year,
        mode: mode,
        status: status,
        action_user_id: userData?.id,
        user_id: signatureUserId,
      };
      let result = await updateSignature(id, body);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        let data = result?.data?.data;
        toast(result?.data?.message, {
          type: "success",
          theme: "dark",
        });
        setOpen(false);
        setSignatureData((preVal) => {
          for (let i = 0; i < preVal.length; i++) {
            if (preVal[i]?.id === data?.id) {
              preVal[i] = data;
            }
          }
          return [...preVal];
        });
        handelFilterClassmateList({ singed: filterType });
      } else {
        toast(result?.data?.message, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <li className='list_item'>
      <img
        src={profileImage ? profileImage : blankProfile}
        alt='profile_image'
      />
      <div className='details'>
        <Paragraph cname='name' pData={name || ""} />
        <Paragraph cname='duration' pData={commentTime(duration) || ""} />
        <Paragraph
          cname='description'
          pData={`Signature: ${description}` || ""}
        />

        {type && (
          <Paragraph
            cname='duration'
            pData={
              modes === 2
                ? "This signature is: Public"
                : "This signature is: Private"
            }
          />
        )}
      </div>
      <div className='menu_dropdown'>
        <button className='dropdown_btn' onClick={handleOpen}>
          <img src={IconDots} alt='icon dots' />
        </button>
        {open ? (
          <ul className='menu_list'>
            <li className='menu-item'>
              <button
                className='link'
                onClick={() => handelUpdateSignature({ mode: 2 })}>
                <img src={IconWhitePublic} alt='close' />
                Public
              </button>
            </li>
            <li className='menu-item'>
              <button
                className='link'
                onClick={() => handelUpdateSignature({ mode: 1 })}>
                <img src={IconWhitePrivate} alt='close' />
                Private
              </button>
            </li>
            <li className='menu-item'>
              <button
                className='link'
                onClick={() => handelUpdateSignature({ status: 0 })}>
                <img src={IconClose} alt='close' />
                Remove
              </button>
            </li>
            {/* <li className='menu-item'>
              <button className='link'>
                <img src={IconReport} alt='close' />
                Report
              </button>
            </li> */}
          </ul>
        ) : null}
      </div>
    </li>
  );
}

export default SignaturesData;
