import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";

import { SiteCard } from "../../../common/website/HOC.jsx";
import { device } from "../../../common/website/Devices";
import { colors, space, siteGradient } from "../../../common/website/Variables";
import HeadingThree from "../../../common/website/HeadingThree.jsx";

import IconNotification from "../../../images/website/icon_notification.svg";
import ProfileImage from "../../../images/website/profile_new_image.png";
import ProfileImage2 from "../../../images/website/profile_new_image2.png";
import ProfileImage3 from "../../../images/website/profile_new_image3.png";
import ProfileImage4 from "../../../images/website/profile_new_image4.png";
import ProfileImage5 from "../../../images/website/profile_new_image5.png";
import ProfileImage6 from "../../../images/website/profile_new_image6.png";
import IconDots from "../../../images/website/icon_dots.svg";
import IconClose from "../../../images/website/icon_close.svg";
import IconReport from "../../../images/website/icon_report.svg";
import blankProfile from "../../../images/website/dummy_user_profile.svg";
import dateDifference from "date-difference";
import annoumentIcon from "../../../images/website/announcement.svg";

import Paragraph from "../../../common/website/Paragraph.jsx";
import { ADMIN_USER_DATA } from "../../../utils/constant.js";
import {
  getNewNotification,
  getSubscriptionDetail,
} from "../../../services/auth.services.js";
import { StatusCodes } from "http-status-codes";
import { PostContext } from "../../../components/website/header/MainHeader.jsx";
import { useEffect } from "react";
import { loaderContext } from "../../../components/global loader/global-loader-provider.js";
import { toast } from "react-toastify";
import CommonModal from "../../../common/website/CommonModal.jsx";

const NotificationRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  .club_group {
    display: none !important;
  }
  h3,
  h4,
  p {
    position: static;
  }
  .body_full_screen {
    width: 100%;
    height: auto;
    .full_screen_card {
      border-radius: ${space.space16};
      padding: ${space.space16} 0 ${space.space16} ${space.space16};
      height: 100%;
      .head {
        display: flex;
        align-items: center;
        grid-gap: ${space.space8};
        padding-bottom: ${space.space16};
        margin-bottom: ${space.space16};
        border-bottom: 1px solid ${colors.lightwhiteborder};
        margin-right: ${space.space16};
        img {
          width: 24px;
          height: 24px;
        }
        h3 {
          margin: 0;
        }
      }
    }
  }
  .list_wrap_main {
    height: auto;
    /* overflow-y: auto; */
    overflow: hidden;
    padding-right: ${space.space16};
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(172 172 172 / 10%);
      border-radius: 8px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    /* Fire Fox */
    scrollbar-color: rgb(172 172 172 / 10%) transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: transparent;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(172 172 172 / 10%);
      border-radius: 8px;
    }
    /* End Fire Fox */
    .list_head {
      margin-bottom: ${space.space14};
    }
    .list_wrap_new {
      max-height: 50%;
      overflow-y: auto;
      padding-bottom: ${space.space16};
      margin-bottom: ${space.space16};
      border-bottom: 1px solid ${colors.lightwhiteborder};
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      &::-webkit-scrollbar-thumb {
        background: rgb(172 172 172 / 10%);
        border-radius: 8px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      /* Fire Fox */
      scrollbar-color: rgb(172 172 172 / 10%) transparent;
      scrollbar-width: thin;
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: transparent;
        border-radius: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgb(172 172 172 / 10%);
        border-radius: 8px;
      }
      /* End Fire Fox */
      &:last-child {
        padding: 0;
        margin: 0;
        border: none;
      }
      .list_item {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        grid-gap: ${space.space8};
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid ${colors.lightwhiteborder};
        position: relative;
        padding-right: ${space.space30};
        &:last-child {
          margin: 0;
          border: none;
          padding-bottom: 0;
        }
        img {
          width: 30px;
          height: 30px;
          border-radius: 100%;
          object-fit: cover;
          flex-shrink: 0;
        }
        .details {
          .name {
            font-size: 0.875rem;
            color: ${colors.white};
            margin-bottom: 4px;
            span {
              color: ${colors.lightgrey2};
            }
          }
          .duration {
            font-size: 0.75rem;
            line-height: 0.938rem;
            color: #acacac;
            margin: 0;
          }
        }
        .menu_dropdown {
          position: absolute;
          right: 0;
          top: 0;
          height: 24px;
          .dropdown_btn {
            display: inline-block;
            img {
              width: 24px;
              height: 24px;
              object-fit: contain;
              border-radius: 0px;
            }
          }
          .menu_list {
            content: "";
            position: absolute;
            top: 100%;
            right: 0;
            background: ${colors.body};
            box-shadow: ${siteGradient.cardlightboxshadow};
            border-radius: ${space.space8};
            width: 200px;
            overflow: hidden;
            padding: 6px 0;
            z-index: 1;
          }
          .link {
            display: flex;
            align-items: center;
            grid-gap: ${space.space8};
            font-size: 0.875rem;
            line-height: 1.063rem;
            padding: ${space.space8} ${space.space20};
            width: 100%;
            img {
              width: 14px;
              height: 14px;
              object-fit: contain;
              border-radius: 0px;
            }
            &:hover {
              background: ${colors.primary};
            }
          }
        }
      }
    }
    .list_wrap {
      max-height: 100%;
      overflow-y: auto;
      padding-bottom: ${space.space16};
      margin-bottom: ${space.space16};
      border-bottom: 1px solid ${colors.lightwhiteborder};
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      &::-webkit-scrollbar-thumb {
        background: rgb(172 172 172 / 10%);
        border-radius: 8px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      /* Fire Fox */
      scrollbar-color: rgb(172 172 172 / 10%) transparent;
      scrollbar-width: thin;
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: transparent;
        border-radius: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgb(172 172 172 / 10%);
        border-radius: 8px;
      }
      /* End Fire Fox */
      &:last-child {
        padding: 0;
        margin: 0;
        border: none;
      }
      .list_item {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        grid-gap: ${space.space8};
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid ${colors.lightwhiteborder};
        position: relative;
        padding-right: ${space.space30};
        &:last-child {
          margin: 0;
          border: none;
          padding-bottom: 0;
        }
        img {
          width: 30px;
          height: 30px;
          border-radius: 100%;
          object-fit: cover;
          flex-shrink: 0;
        }
        .details {
          .name {
            font-size: 0.875rem;
            color: ${colors.white};
            margin-bottom: 4px;
            span {
              color: ${colors.lightgrey2};
            }
          }
          .duration {
            font-size: 0.75rem;
            line-height: 0.938rem;
            color: #acacac;
            margin: 0;
          }
        }
        .menu_dropdown {
          position: absolute;
          right: 0;
          top: 0;
          height: 24px;
          .dropdown_btn {
            display: inline-block;
            img {
              width: 24px;
              height: 24px;
              object-fit: contain;
              border-radius: 0px;
            }
          }
          .menu_list {
            content: "";
            position: absolute;
            top: 100%;
            right: 0;
            background: ${colors.body};
            box-shadow: ${siteGradient.cardlightboxshadow};
            border-radius: ${space.space8};
            width: 200px;
            overflow: hidden;
            padding: 6px 0;
            z-index: 1;
          }
          .link {
            display: flex;
            align-items: center;
            grid-gap: ${space.space8};
            font-size: 0.875rem;
            line-height: 1.063rem;
            padding: ${space.space8} ${space.space20};
            width: 100%;
            img {
              width: 14px;
              height: 14px;
              object-fit: contain;
              border-radius: 0px;
            }
            &:hover {
              background: ${colors.primary};
            }
          }
        }
      }
    }
  }
  @media ${device.mobileM} {
    .list_wrap_main {
      .list_wrap_new {
        padding-bottom: ${space.space30};
        margin-bottom: ${space.space30};
        .list_item {
          flex-direction: row;
          align-items: center;
          margin-bottom: 28px;
          padding-bottom: 0;
          border-bottom: none;
          grid-gap: ${space.space14};
          img {
            width: 44px;
            height: 44px;
          }
          .details {
            .name {
              font-size: 1rem;
            }
          }
          .menu_dropdown {
            .menu_list {
              padding: 0.875rem 0;
            }
          }
        }
      }
      .list_wrap {
        padding-bottom: ${space.space30};
        margin-bottom: ${space.space30};
        .list_item {
          flex-direction: row;
          align-items: center;
          margin-bottom: 28px;
          padding-bottom: 0;
          border-bottom: none;
          grid-gap: ${space.space14};
          img {
            width: 44px;
            height: 44px;
          }
          .details {
            .name {
              font-size: 1rem;
            }
          }
          .menu_dropdown {
            .menu_list {
              padding: 0.875rem 0;
            }
          }
        }
      }
    }
  }
  .line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  @media ${device.mobileL} {
    .body_full_screen {
      .full_screen_card {
        .head {
          grid-gap: ${space.space14};
          img {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
    .body_full_screen {
      .full_screen_card {
        padding: ${space.space30} 0 ${space.space30} ${space.space30};
        .head {
          padding-bottom: ${space.space30};
          margin-bottom: ${space.space30};
          margin-right: ${space.space30};
        }
      }
    }
    .list_wrap_main {
      padding-right: ${space.space30};
    }
  }

  @media ${device.tablet} {
    width: 75%;
    .list_wrap_main {
      height: calc(100vh - 202px);
    }
    .body_full_screen {
      padding-left: ${space.space20};
      height: calc(100vh - 40px);
    }
  }
  @media ${device.laptop} {
    width: 77%;
  }
  @media ${device.laptopL} {
    width: 80%;
    .list_wrap_main {
      height: calc(100vh - 222px);
    }
    .body_full_screen {
      padding-left: ${space.space40};
      height: calc(100vh - 60px);
    }
  }
`;
function Notification() {
  // Notification Dropdown
  const [open, setOpen] = useState(false);
  const [newPage, setnewPage] = useState(1);
  const [oldPage, setoldPage] = useState(1);
  const [isReadMore, setIsReadMore] = useState(true);
  const [openCommonModal, setOpenCommonModal] = useState(false);
  const [message, setMessage] = useState({
    message: "",
    value: "",
  });

  const newScroll = useRef();
  const oldScroll = useRef();
  let pagesNew = newPage;
  let pagesOld = oldPage;
  const handleOpen = () => {
    setOpen(!open);
  };
  // End Post Dropdown

  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }
  const {
    setUserInfo,
    gettingRecentActivity,
    recentActivityData,
    gettingNotification,
    newNotification,
    setNewNotificationCount,
    userInfo,
    handelLogout,
  } = useContext(PostContext);

  let sub = true;
  useEffect(() => {
    if (sub) {
      getinfo();
      setNewNotificationCount(0);
    }

    return () => (sub = false);
  }, []);

  const getinfo = async () => {
    let user = {
      email: userData.email,
    };
    let subDetails = await getSubscriptionDetail(user);
    if (subDetails?.data?.status === 410) {
      handelLogout();
    }

    console.log();
    if (subDetails?.data?.status === StatusCodes.OK) {
      let StudentInfo = subDetails?.data?.data;

      setUserInfo(StudentInfo);
      let notification = 1;
      gettingRecentActivity({ StudentInfo, notification, loader: true });

      gettingNotification({
        StudentInfo,
        notification,
        loader: true,
        pages: 1,
      });
    }
  };

  const commentTime = (val) => {
    let date = new Date(val?.createdAt);
    let vals = dateDifference(new Date(val?.createdAt), new Date(new Date()), {
      compact: true,
    });
    let vals2 = vals.slice(1);

    if (vals2 === "1d") {
      return `yesterday ${date?.getHours()}:${date?.getMinutes()} `;
    } else if (vals2?.includes("ms")) {
      return `few seconds ago`;
    } else if (vals2?.includes("m")) {
      return `${vals2} ago`;
    } else if (vals2?.includes("s")) {
      return `few seconds ago`;
    } else if (vals2?.includes("h")) {
      return `${vals2} ago`;
    } else {
      return `${date?.getDate()}-${
        date?.getMonth() + 1
      }-${date.getFullYear()} ${date?.getHours()}:${date?.getMinutes()} `;
    }
  };

  const handelNewPagination = () => {
    if (newScroll?.current) {
      const { scrollTop, scrollHeight, clientHeight } = newScroll.current;
      if (scrollTop + clientHeight === scrollHeight) {
        console.log("yesssss", scrollTop, clientHeight, scrollHeight);
        pagesNew++;
        setnewPage(pagesNew);
        let loader = 1;
        let notification = 1;
        gettingNotification({
          StudentInfo: userInfo,
          notification,
          pages: pagesNew,
        });
      }
    }
  };

  const handelOldPagination = () => {
    if (oldScroll?.current) {
      const { scrollTop, scrollHeight, clientHeight } = oldScroll.current;
      if (scrollTop + clientHeight === scrollHeight) {
        console.log("yesssss", scrollTop, clientHeight, scrollHeight);
        pagesOld++;
        setoldPage(pagesOld);
        let loader = 1;
        let notification = 1;
        gettingRecentActivity({
          StudentInfo: userInfo,
          notification,
          pages: pagesOld,
        });
        // gettingClassList({ StudentInfo: userInfo, pagesOld, loader: false });
      }
    }
  };

  const toggleReadMore = (message, value) => {
    setMessage((preVal) => {
      return { ...preVal, message: message, value: value };
    });
    setOpenCommonModal(true);
  };

  return (
    <>
      <NotificationRow>
        <div className='body_full_screen'>
          <SiteCard className='full_screen_card'>
            <div className='head'>
              <img src={IconNotification} alt='icon_notification' />
              <HeadingThree h3Data={"Notification"} />
            </div>
            <div className='list_wrap_main'>
              {/* new notification starts */}
              {newNotification?.length > 0 && (
                <>
                  <Paragraph canme='list_head' pData={"New"} />
                  <ul
                    className='list_wrap_new'
                    ref={newScroll}
                    onScroll={handelNewPagination}>
                    {newNotification?.map((value, i) => {
                      return (
                        <li className='list_item' key={i}>
                          {value?.type !== 3 && (
                            <img
                              src={
                                value?.User?.filtered_image
                                  ? value?.User?.filtered_image
                                  : blankProfile
                              }
                              alt='profile_image'
                            />
                          )}
                          {value?.type === 3 && (
                            <img
                              src={annoumentIcon ? annoumentIcon : ""}
                              alt='profile_image'
                            />
                          )}
                          <div className='details'>
                            <div className='name'>
                              {" "}
                              {value?.type === 1 && (
                                <>
                                  {value?.User?.student_name}
                                  <span> Liked your post.</span>
                                </>
                              )}
                              {value?.type === 2 && (
                                <>
                                  {value?.User?.student_name}
                                  <span> Commented on your post.</span>
                                </>
                              )}
                              {value?.type === 3 && (
                                <>
                                  <div className=' name'>
                                    <p className=' text-cyan-400'>
                                      {value?.title}
                                    </p>
                                    <div className='flex'>
                                      <p className='text-white flex line-clamp  '>
                                        {value?.message}
                                      </p>
                                      <p
                                        onClick={() =>
                                          toggleReadMore(value?.message, value)
                                        }
                                        className='text-cyan-400 text-xs ml-1 mt-1 cursor-pointer whitespace-nowrap'>
                                        {value?.message?.length > 60 &&
                                          isReadMore &&
                                          " Read more"}
                                      </p>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                            <p className='duration '>{commentTime(value)}</p>
                          </div>
                          <div className='menu_dropdown'>
                            {/* <button className='dropdown_btn'>
                      <img src={IconDots} alt='icon dots' />
                    </button> */}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </>
              )}
              {/* new notification ends */}
              {/* old notification starts */}
              {newNotification?.length > 0 && (
                <Paragraph cname='list_head' pData={"Earlier"} />
              )}
              <ul
                className='list_wrap'
                ref={oldScroll}
                onScroll={handelOldPagination}>
                {recentActivityData?.length > 0 ? (
                  recentActivityData?.map((value, i) => {
                    return (
                      <li className='list_item' key={i}>
                        {value?.type !== 3 && (
                          <img
                            src={
                              value?.User?.filtered_image
                                ? value?.User?.filtered_image
                                : blankProfile
                            }
                            alt='profile_image'
                          />
                        )}
                        {value?.type === 3 && (
                          <img
                            src={annoumentIcon ? annoumentIcon : ""}
                            alt='profile_image'
                          />
                        )}
                        <div className='details'>
                          <div className='name'>
                            {" "}
                            {value?.type === 1 && (
                              <>
                                {value?.User?.student_name}
                                <span> Liked your post.</span>
                              </>
                            )}
                            {value?.type === 2 && (
                              <>
                                {value?.User?.student_name}
                                <span> Commented on your post.</span>
                              </>
                            )}
                            {value?.type === 3 && (
                              <>
                                <div className=' name'>
                                  <p className=' text-cyan-400'>
                                    {value?.title}
                                  </p>
                                  <div className='flex'>
                                    <p className='text-white flex line-clamp  '>
                                      {value?.message}
                                    </p>
                                    <p
                                      onClick={() =>
                                        toggleReadMore(value?.message, value)
                                      }
                                      className='text-cyan-400 text-xs ml-1 mt-1 cursor-pointer whitespace-nowrap'>
                                      {value?.message?.length > 60 &&
                                        isReadMore &&
                                        "Read more"}
                                    </p>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          <p className='duration '>{commentTime(value)}</p>
                        </div>
                        <div className='menu_dropdown'>
                          {/* <button className='dropdown_btn'>
                      <img src={IconDots} alt='icon dots' />
                    </button> */}
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <p>No Notification Found..!!</p>
                )}
                {/* old notification ends */}
                {/* Common modal for more message starts */}
                <CommonModal
                  header={"Message"}
                  openCommonModal={openCommonModal}
                  setOpenCommonModal={setOpenCommonModal}
                  message={message}
                  type={"notification"}
                  icon={annoumentIcon}
                />
                {/* Common modal for more message ends */}
              </ul>
            </div>
          </SiteCard>
        </div>
      </NotificationRow>
    </>
  );
}

export default Notification;
