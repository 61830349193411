import React, { useContext, useEffect, useState } from "react";
import { AdminContext } from "../../common/DashboardWrapper";
import { loaderContext } from "../../components/global loader/global-loader-provider";
import { ADMIN_USER_DATA, NFT, REPORTS } from "../../utils/constant";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  adminStudentListing,
  downloadCSV,
  planHistory,
} from "../../services/auth.services";
import { StatusCodes } from "http-status-codes";
import ListLayout from "../../components/shared/listLayout";
import SelectFilter from "../../components/school_select_filter/SelectFilter";
import ReactPaginate from "react-paginate";
import SearchFilter from "../../components/search_filter/SearchFilter";
import DateFilter from "../../components/date_filter/DateFilter";
import DownloadButton from "../../components/download_button/DownloadButton";
import { toast } from "react-toastify";
import CustomInput from "../../common/CustomInput";

function AdminPlanDetails() {
  const { state } = useLocation();
  const { dateValue, search, inputDate } = state;
  const { id } = useParams();

  const [sortby, setSortby] = useState("");
  const [orderby, setOrderby] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [dataCount, setDataCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [data, setData] = useState([]);
  const [fileLink, setFileLink] = useState(null);
  const [searchItem, setSearchItem] = useState(search);
  const [open, setOpen] = useState(false);
  const [currency, setCurrency] = useState("");
  const [schoolOptions, setSchoolOptions] = useState([
    {
      value: "",
      label: "Select School",
      selected: false,
    },
  ]);
  const [sendingDate, setSendingDate] = useState(dateValue);
  const [dates, setDates] = useState(inputDate);

  const tableHeader = [
    {
      key: "S.No.",
      value: "key",
      clickable: false,
      sorting: false,
    },
    { key: "Plan Name", value: "planName", clickable: false, sorting: true },
    {
      key: "Plan Description",
      value: "description",
      clickable: false,
      sorting: true,
    },

    {
      key: `Plan price ${currency ? currency : ""}`,
      value: "price",
      clickable: false,
      sorting: true,
    },
  ];

  const itemsOnPage = [
    { label: 10, value: "10" },
    { label: 20, value: "20" },
    { label: 50, value: "50" },
    { label: 70, value: "70" },
    { label: 100, value: "100" },
  ];

  const { setAdminActiveTab } = useContext(AdminContext);
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }

  const { loaderDispatch } = useContext(loaderContext);
  let navigate = useNavigate();

  useEffect(() => {
    setAdminActiveTab(null);
    setSearchItem("");
    setDates({
      startDate: "",
      endDate: "",
    });
  }, []);

  useEffect(() => {
    const debunce = setTimeout(() => {
      getEarningData();
    }, 300);

    return () => {
      clearTimeout(debunce);
    };
  }, [searchItem, sortby, orderby, itemOffset, itemsPerPage, sendingDate]);

  useEffect(() => {
    setCurrentItems(data);
    setPageCount(dataCount);
  }, [data, dataCount, itemsPerPage]);

  useEffect(() => {
    let event = {
      selected: 0,
    };
    handlePageClick(event);
  }, [sendingDate]);

  const getEarningData = async () => {
    loaderDispatch({
      isLoading: true,
    });
    try {
      let order = orderby ? "desc" : "asc";

      let result = await planHistory(
        id,
        `search=${searchItem.trim()}&sort_by=${sortby}&order=${order}&page=${itemOffset}&limit=${itemsPerPage}&start_date=${
          sendingDate?.startDate
        }&end_date=${sendingDate?.endDate}`
      );
      loaderDispatch({
        isLoading: false,
      });
      setData([]);
      setDataCount(0);

      if (
        result?.data?.status === StatusCodes.OK &&
        result?.data?.data?.total > 0
      ) {
        const studentData = result?.data?.data?.docs;
        const studentDataCount = result?.data?.data?.pages;
        setCurrency(`(${result?.data?.data?.currency})`);
        setData(studentData);
        setDataCount(studentDataCount);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSorting = (data, order) => {
    setSortby(data);
    setOrderby(order);
  };

  const handlePageClick = (event) => {
    setItemOffset(event.selected + 1);
  };

  const handleOptions = (optionValue, item) => {
    switch (optionValue) {
      case NFT:
        navigate(`/admin/report/details/${item.id}`);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <div className='p-2 grid grid-cols-3 gap-2 mt-1'>
        <div className=' flex justify-center'>
          <SearchFilter
            setSearchItem={setSearchItem}
            searchItem={searchItem}
            setPage={setItemOffset}
            cname={
              " block w-full border border-gray-300 rounded-md shadow py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
            }
          />
        </div>
        <div className='shadow flex justify-center'>
          <DateFilter
            setOpen={setOpen}
            open={open}
            setSendingDate={setSendingDate}
            setDates={setDates}
            dates={dates}
          />
        </div>

        <div className='flex justify-end'>
          {/* <DownloadButton
            title={"Export Excel"}
            btnCls={"bg-green-700 px-3 rounded shadow-md text-xl text-white"}
            onClick={handelDownloadCSV}
            fileLink={fileLink}
          /> */}
        </div>
      </div>
      <ListLayout
        handleOptions={handleOptions}
        tableHeader={tableHeader}
        handleSorting={handleSorting}
        currentItems={currentItems}
      />
      <div className='flex justify-between '>
        {data?.length > 0 && (
          <div
            className='block lg:inline-block lg:mt-0 '
            style={{ marginTop: "6px" }}>
            <label htmlFor='item' className='ml-5'>
              Record Per Page:
            </label>
            <SelectFilter
              options={itemsOnPage}
              selectCls={"ml-3 bg-white border p-2 rounded"}
              name={"item"}
              id={"item"}
              onChange={(e) => {
                setItemsPerPage(parseInt(e.target.value));
              }}
            />
          </div>
        )}
        <div className='mt-2 mb-10'>
          <ReactPaginate
            breakLabel='...'
            nextLabel='Next >'
            onPageChange={handlePageClick}
            pageCount={pageCount}
            previousLabel='< Previous'
            renderOnZeroPageCount={null}
            containerClassName={"pagination"}
            pageClassName={"pageClass"}
            forcePage={itemOffset - 1}
          />
        </div>

        {data?.length === 0 && (
          <div className='flex absolute left-1/2 top-1/2 mx-auto'>
            <p>Data not found..!!</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminPlanDetails;
