import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Dialog, Transition } from "@headlessui/react";
import { GrClose } from "react-icons/gr";
import { SiteModal, SiteForm, SiteCard } from "../../../common/website/HOC.jsx";
import { colors, space } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";

import HeadingFive from "../../../common/website/HeadingFive.jsx";
import Paragraph from "../../../common/website/Paragraph.jsx";
import Button from "../../../common/website/Button.jsx";

import ProcessTwo from "../../../images/website/process_2.png";
import ProcessThree from "../../../images/website/process_3.png";
import Label from "../../../common/website/Label.jsx";

const ConvertYourNFTRow = styled.div`
  .modal_inner {
    max-width: 778px;
    text-align: center;
    .modal_boday {
      padding: 1rem;
    }
    .modal_close {
      top: -8px;
    }
    .description {
      max-width: 624px;
      margin: 0 auto ${space.space16};
    }
    .convert_btn {
      padding: 0.3rem 1rem;
    }
    .card_list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: ${space.space24};
      grid-gap: ${space.space16};
      .card {
        width: 200px;
        height: 180px;
        border-radius: ${space.space16};
        padding: ${space.space14};
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: ${space.space8};
          margin: auto;
        }
      }
    }
  }
  .convert-nft-form,
  .form_group {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .convert-nft-form {
    grid-gap: ${space.space10};
    margin-bottom: ${space.space24};
    flex-wrap: wrap;
  }
  .form_group {
    input {
      display: none;
    }
    input[type="radio"]:checked ~ .dots::before {
      display: block;
    }
    .dots {
      display: inline-block;
      width: 15px;
      height: 15px;
      border-radius: 100%;
      border: 1px solid ${colors.primary};
      position: relative;
      cursor: pointer;
      &::before {
        content: "";
        position: absolute;
        background: ${colors.primary};
        width: 9px;
        height: 9px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border-radius: 100%;
        display: none;
      }
    }
    label {
      font-size: 0.875rem;
      line-height: 1.063rem;
      font-weight: 400;
      padding-left: ${space.space10};
      cursor: pointer;
      margin: 0;
    }
  }
  @media ${device.mobileM} {
    .modal_inner {
      .modal_boday {
        padding: 1.875rem;
      }
      .modal_close {
        top: -13px;
      }
      .description {
        margin: 0 auto ${space.space30};
      }
      .card_list {
        align-items: flex-start;
        flex-direction: row;
        grid-gap: ${space.space24};
        margin-bottom: ${space.space30};
      }
      .convert-nft-form {
        grid-gap: ${space.space20};
        margin-bottom: ${space.space50};
      }
    }
  }
  @media ${device.tablet} {
    .modal_inner {
      .convert_btn {
        padding: 0.5rem 1.5rem;
      }
    }
  }
  @media ${device.laptop} {
    .modal_inner {
      .convert_btn {
        padding: 0.75rem 1.5rem;
      }
    }
  }
`;

function ConvertYourNFTModal({
  openImageModal,
  setOpenImageModal,
  handelCovertPostToNFT,
  photosValue,
  setPhotosValue,
}) {
  useEffect(() => {
    setPhotosValue(false);
  }, [openImageModal]);

  const handelChange = (e) => {
    setPhotosValue(!photosValue);
  };
  return (
    <Transition.Root show={openImageModal}>
      <Dialog as='div' static open={openImageModal} onClose={setOpenImageModal}>
        <ConvertYourNFTRow>
          <SiteModal>
            <Transition.Child
              className='h-screen'
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
              <div className='modal_inner'>
                <div className='modal_boday'>
                  <div className='model_top'>
                    <HeadingFive
                      cname='title'
                      h5Data={"Students create their own NFT"}
                    />
                    <span
                      className='modal_close'
                      onClick={() => setOpenImageModal(false)}>
                      {" "}
                      <GrClose />
                    </span>
                  </div>
                  <div className='modal_content'>
                    <Paragraph
                      cname='description'
                      pData={
                        "Student upload their real-time photos and videos and make their lifetime memories, stored securely on the OpenSeas marketplace."
                      }
                    />
                    <Paragraph
                      cname='description'
                      pData={
                        "Rachel created her personal NFT. She owns, manages, and controls her identity"
                      }
                    />
                    <SiteForm as='div'>
                      {photosValue ? (
                        <div className='card_list'>
                          <SiteCard className='card'>
                            <img src={ProcessTwo} alt='img' />
                          </SiteCard>
                        </div>
                      ) : (
                        <div className='card_list'>
                          <SiteCard className='card'>
                            <img src={ProcessTwo} alt='img' />
                          </SiteCard>
                          <SiteCard className='card'>
                            <img src={ProcessThree} alt='img' />
                          </SiteCard>
                        </div>
                      )}

                      <SiteForm as='div' className='convert-nft-form'>
                        <div className='form_group'>
                          <input
                            type='radio'
                            name='radio'
                            id='keep-original'
                            checked={photosValue}
                            onChange={handelChange}
                          />
                          <label for='keep-original' className='dots'></label>
                          <Label
                            for='keep-original'
                            labelData={"Keep Original"}
                          />
                        </div>
                        <div className='form_group'>
                          <input
                            type='radio'
                            name='radio'
                            id='convert-art'
                            checked={!photosValue}
                            onChange={handelChange}
                          />
                          <label for='convert-art' className='dots'></label>
                          <Label
                            for='convert-art'
                            labelData={"Convert to Art"}
                          />
                        </div>
                      </SiteForm>

                      <Button
                        as='button'
                        cname='gradient convert_btn'
                        buttonData={"Convert Now"}
                        onClick={handelCovertPostToNFT}
                      />
                    </SiteForm>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </SiteModal>
        </ConvertYourNFTRow>
      </Dialog>
    </Transition.Root>
  );
}
export default ConvertYourNFTModal;
