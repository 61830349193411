import axios from "axios";
import axiosConfig from "./sevices";

const LOGIN_URL = `${process.env.REACT_APP_APIURL}/login`;
const FORGOT_PASSWORD = `${process.env.REACT_APP_APIURL}/forgot-password`;
const DASHBOARD_URL = `${process.env.REACT_APP_APIURL}/admin/dashboard`;
const GET_ADMIN_PROFILE = `${process.env.REACT_APP_APIURL}/admin/view-profile`;
const EDIT_ADMINPROFILE = `${process.env.REACT_APP_APIURL}/admin/edit-profile`;
const ADMIN_GENERAL_SITTING = `${process.env.REACT_APP_APIURL}/admin/get-general-settings`;
const SEND_INVITE = `${process.env.REACT_APP_APIURL}/admin/invite-link`;
const SCHOOL_REGISTRATION = `${process.env.REACT_APP_APIURL}/school/school-registration`;
const STUDENT_REGISTRATION = `${process.env.REACT_APP_APIURL}/student/student-registration`;
const ADMIN_PLANS = `${process.env.REACT_APP_APIURL}/admin/subscription-list`;
const EDIT_ADMIN_PLANS = `${process.env.REACT_APP_APIURL}/admin/edit-subscription`;
const SCHOOL_DETAILS = `${process.env.REACT_APP_APIURL}/admin/get-school-details`;
const VIEW_SCHOOL_DETAILS = `${process.env.REACT_APP_APIURL}/admin/view-school`;
const EMAIL_TEMPLATE = `${process.env.REACT_APP_APIURL}/admin/email-template`;
const EDIT_SETTING = `${process.env.REACT_APP_APIURL}/admin/general-settings`;
const STUDENT_LISTING = `${process.env.REACT_APP_APIURL}/admin/new/student-listing`;
const STUDENT_LISTING_IN = `${process.env.REACT_APP_APIURL}/school-admin/todos/assigned-user`;
const TASK_LISTING_REPORT = `${process.env.REACT_APP_APIURL}/school-admin/todos/is-completed-count`;
const GET_SCHOOL_LISTING = `${process.env.REACT_APP_APIURL}/admin/school-listing`;
const UPDATE_SCHOOL_PROFILE = `${process.env.REACT_APP_APIURL}/school/update-school-profile`;
const DELETE_CUSTOME_FIELD = `${process.env.REACT_APP_APIURL}/school-admin/dynamic-column`;
const ADMIN_SCHOOL_UPDATE = `${process.env.REACT_APP_APIURL}/admin/update-school`;
const ADMIN_SCHOOL_DELETE = `${process.env.REACT_APP_APIURL}/admin/delete-school`;
const UPDATE_STUDENT = `${process.env.REACT_APP_APIURL}/school/update-student`;
const STUDENT_DETAILS = `${process.env.REACT_APP_APIURL}/admin/get-student-details`;
const DELETE_STUDENT = `${process.env.REACT_APP_APIURL}/admin/delete-student`;
const POST_LISTING = `${process.env.REACT_APP_APIURL}/school/post-list`;
const POST_LISTING_SM_TM = `${process.env.REACT_APP_APIURL}/student/gallery-my-moment`;

const CMS_LISTING = `${process.env.REACT_APP_APIURL}/admin/cms-list`;
const POST_DETAILS = `${process.env.REACT_APP_APIURL}/school/view-post`;
const DELETE_POST = `${process.env.REACT_APP_APIURL}/school/delete-post`;
const UPDATE_POST_STATUS = `${process.env.REACT_APP_APIURL}/school/change-status`;
const CMS_VIEW = `${process.env.REACT_APP_APIURL}/admin/cms-details`;
const CMS_UPDATE = `${process.env.REACT_APP_APIURL}/admin/cms-update`;
const EMAIL_LISTING = `${process.env.REACT_APP_APIURL}/admin/email-template-list`;
const EMAIL_UPDATE = `${process.env.REACT_APP_APIURL}/admin/update-email-template`;
const CLUB_LISTING = `${process.env.REACT_APP_APIURL}/admin/get-club-listing`;
const TASK_LISTING = `${process.env.REACT_APP_APIURL}/school-admin/todos`;
const CLUB_VIEW = `${process.env.REACT_APP_APIURL}/admin/get-club-details`;
const TASK_VIEW = `${process.env.REACT_APP_APIURL}/school-admin/todos`;
const CLUB_DELETE = `${process.env.REACT_APP_APIURL}/admin/delete-club`;
const TASK_DELETE = `${process.env.REACT_APP_APIURL}/school-admin/todos`;
const CLUB_UPDATE = `${process.env.REACT_APP_APIURL}/admin/update-club`;
const TASK_UPDATE = `${process.env.REACT_APP_APIURL}/school-admin/todos`;
const ADMIN_POST_STATUS = `${process.env.REACT_APP_APIURL}/school/change-post-status`;
const SCHOOL_BULK_INVITE = `${process.env.REACT_APP_APIURL}/school/bulk-invite`;
const CREATE_CLUB = `${process.env.REACT_APP_APIURL}/school/create-club`;
const CREATE_TASK = `${process.env.REACT_APP_APIURL}/school-admin/todos`;
const CREATE_PLAN = `${process.env.REACT_APP_APIURL}/admin/add-subscription`;
const DELETE_PLAN = `${process.env.REACT_APP_APIURL}/admin/delete-subscription`;
const RESET_PASSWORD = `${process.env.REACT_APP_APIURL}/reset-password`;
const STUDENT_LIST_TO_CLUB = `${process.env.REACT_APP_APIURL}/admin/student-list-to-club`;
const ADD_STUDENT_TO_CLUB = `${process.env.REACT_APP_APIURL}/admin/add-students-to-club`;
const SCHOOL_DASHBOARD = `${process.env.REACT_APP_APIURL}/school/school-dashboard`;
const COUNTRY_API = `${process.env.REACT_APP_APIURL}/admin/country-list`;
const SUBSCRIBER = `${process.env.REACT_APP_APIURL}/subscriber`;
const CONTACT = `${process.env.REACT_APP_APIURL}/create-contact`;
const CHECK_SUBSCRIPTION_DETAIL = `${process.env.REACT_APP_APIURL}/student/has-subscription`;
const CHOOSE_PLAN = `${process.env.REACT_APP_APIURL}/student/add-subscription`;
const UPLOAD_PROFILE_IMAGE = `${process.env.REACT_APP_APIURL}/student/upload-profile`;
const UPLOAD_POST = `${process.env.REACT_APP_APIURL}/student/create-post`;
const SCHOOL_POST_LIKE = `${process.env.REACT_APP_APIURL}/student/add-like`;
const SCHOOL_POST_COMMENT = `${process.env.REACT_APP_APIURL}/student/add-comment`;
const POST_UPDATE = `${process.env.REACT_APP_APIURL}/student/update-post`;
const RECENT_ACTIVITY = `${process.env.REACT_APP_APIURL}/student/notification-list`;
const CLASSMATES_LIST = `${process.env.REACT_APP_APIURL}/student/classmate-list`;
const CONVERT_POST_TO_PROFILE = `${process.env.REACT_APP_APIURL}/student/set-as-profile`;
const CONVERT_POST_TO_NFT = `${process.env.REACT_APP_APIURL}/student/convert-to-nft`;
const NFT_POSTS = `${process.env.REACT_APP_APIURL}/student/nft-list`;
const STUDENT_LOGIN = `${process.env.REACT_APP_APIURL}/student-login`;
const RENEW_PLAN = `${process.env.REACT_APP_APIURL}/student/renew-plan`;
const UPDATE_STUDENT_PROFILE = `${process.env.REACT_APP_APIURL}/student/edit-student-profile`;
const UPDATE_METAMASK_ADDRESS = `${process.env.REACT_APP_APIURL}/student/update-metamask`;

const YEARBOOK_CREATE_COLLAGE = `${process.env.REACT_APP_APIURL}/create-collage`;
const YEARBOOK_HISTORY = `${process.env.REACT_APP_APIURL}/history`;

const YEARBOOK_STUDENTS = `${process.env.REACT_APP_APIURL}/year-book-students`;
const CHATTING_MESSAGE = `${process.env.REACT_APP_APIURL}/student/send-message`;

const CHAT_USER_LIST = `${process.env.REACT_APP_APIURL}/student/chat-user-list`;
const CHAT_LIST = `${process.env.REACT_APP_APIURL}/student/chat-list`;
const NEW_NOTIFICATION = `${process.env.REACT_APP_APIURL}/student/unread-notifications`;
const INITIAL_PAYMENT = `${process.env.REACT_APP_APIURL}//student/initiate-payment`;
const PAYMENT_DONE = `${process.env.REACT_APP_APIURL}/student/payment`;
const CSV_DOWNLOAD = `${process.env.REACT_APP_APIURL}/student/export-as-csv`;
const CSV_TASK_DOWNLOAD = `${process.env.REACT_APP_APIURL}/school-admin/todos/export-csv`;
const EARNING_REPORT = `${process.env.REACT_APP_APIURL}/admin/plan-history`;
const TEAM_LISTING = `${process.env.REACT_APP_APIURL}/school/get-sports-list`;
const CREATE_TEAM = `${process.env.REACT_APP_APIURL}/school/create-sport`;
const TEAM_VIEW = `${process.env.REACT_APP_APIURL}/school/get-sports-details`;
const TEAM_UPDATE = `${process.env.REACT_APP_APIURL}/admin/update-sport`;
const TEAM_DELETE = `${process.env.REACT_APP_APIURL}/school/delete-sport`;
const ADD_STUDENT_TO_TEAM = `${process.env.REACT_APP_APIURL}/school/add-students-to-sport`;
const STUDENT_LIST_TO_TEAM = `${process.env.REACT_APP_APIURL}/school/student-list-to-sport`;
const FILTERED_IMG = `${process.env.REACT_APP_APIURL}/student/digital-art`;
const COVERT_BASE_IMG = `${process.env.REACT_APP_APIURL}/student/covert-to-base`;
const DIGITAL_ARTS = `${process.env.REACT_APP_APIURL}/student/get-digital-arts`;
const LIKE_LIST = `${process.env.REACT_APP_APIURL}/school/like-list`;
const COMMENT_LIST = `${process.env.REACT_APP_APIURL}/school/comment-list`;
const MEMBER_LIST = `${process.env.REACT_APP_APIURL}/admin/get-club-members-listing`;
const MY_MOMENT_POST_LIST = `${process.env.REACT_APP_APIURL}/student/get-moment-list`;
const SIGNATURE = `${process.env.REACT_APP_APIURL}/student/add-signature`;
const SIGNATURE_LISTING = `${process.env.REACT_APP_APIURL}/student/signature-list`;
const SIGNATURE_UPDATE = `${process.env.REACT_APP_APIURL}/student/update-signature`;
const SIGNATURE_POST_LISTING = `${process.env.REACT_APP_APIURL}/student/user-signature-list`;

const SIGNATURE_CLASSMATES_LIST = `${process.env.REACT_APP_APIURL}/student/signature-classmate-list`;
const CREATE_VOTING_CATEGORY = `${process.env.REACT_APP_APIURL}/school/create-category`;
const LIST_VOTING_CATEGORY = `${process.env.REACT_APP_APIURL}/school/category-list`;
const DELETE_VOTE_CATEGORY = `${process.env.REACT_APP_APIURL}/school/delete-category`;
const VOTING_STATUS = `${process.env.REACT_APP_APIURL}/school/get-voting-status`;
const UPDATE_VOTING_STATUS = `${process.env.REACT_APP_APIURL}/school/update-voting-status`;
const CATEGORY_DETAILS = `${process.env.REACT_APP_APIURL}/school/get-by-id-category`;
const VIEW_CATEGORY_DETAILS = `${process.env.REACT_APP_APIURL}/school/get-category-details`;
const UPDATE_CATEGORY_DATA = `${process.env.REACT_APP_APIURL}/school/update-category`;
const VOTING_ACADEMIC_YEAR = `${process.env.REACT_APP_APIURL}/student/voting-history`;
const VOTING_WINNER = `${process.env.REACT_APP_APIURL}/school/voting-winners`;
const WINNERS_DETAILS = `${process.env.REACT_APP_APIURL}/school/winner-details`;
const SUBMIT_VOTES = `${process.env.REACT_APP_APIURL}/student/submit-vote`;
const ALREADY_VOTED = `${process.env.REACT_APP_APIURL}/student/already-voted`;
const DIGITAL_ARTS_WITH_VOTING = `${process.env.REACT_APP_APIURL}/student/digital-art-listing`;
const STUDENT_VOTING_WINNER = `${process.env.REACT_APP_APIURL}/student/voting-winners`;
const VOTING_STANDARD_HISTORY = `${process.env.REACT_APP_APIURL}/student/standard-list`;
const LABEL_LIST = `${process.env.REACT_APP_APIURL}/school/label-list`;
const LABEL_DETAIL = `${process.env.REACT_APP_APIURL}/school/get-label-details`;
const UPDATE_LABEL = `${process.env.REACT_APP_APIURL}/school/update-label`;
const UPDATE_LABEL_MOBILE = `${process.env.REACT_APP_APIURL}/school/update-label-mobile`;
const SCHOOL_LABEL_LIST = `${process.env.REACT_APP_APIURL}/school/school-label-list`;
const UPDATE_UPDATED_LABEL = `${process.env.REACT_APP_APIURL}/school/update`;
const DELETE_LABEL = `${process.env.REACT_APP_APIURL}/school/delete-label`;
const GET_DOCUMENTATION = `${process.env.REACT_APP_APIURL}/admin/get-documents`;
const VERIFY_DOCS = `${process.env.REACT_APP_APIURL}/admin/verify-document`;
const UPDATE_VERIFY_DOCS = `${process.env.REACT_APP_APIURL}/student/update-document`;
const BROADCAST_CREATE_MESSAGE = `${process.env.REACT_APP_APIURL}/school/create-broadcast-message`;
const BROADCAST_MESSAGE_LIST = `${process.env.REACT_APP_APIURL}/school/broadcast-message-list`;
const SINGLE_MESSAGE = `${process.env.REACT_APP_APIURL}/school/get-broadcast-message`;
const UPDATE_BROADCAST_MESSAGE = `${process.env.REACT_APP_APIURL}/school/update-broadcast-message`;
const DELETE_BROADCAST_MESSAGE = `${process.env.REACT_APP_APIURL}/school/delete-message`;
const SCHOOL_LOGO_UPDATE = `${process.env.REACT_APP_APIURL}/school/update-school-logo`;
const CREATE_WEB_LINK = `${process.env.REACT_APP_APIURL}/school/create-web-link`;
const WEB_LINK_LIST = `${process.env.REACT_APP_APIURL}/school/web-link-list`;
const DELETE_WEB_LINK = `${process.env.REACT_APP_APIURL}/school/delete-web-link`;
const LIST_TYPE_WEB_LINK = `${process.env.REACT_APP_APIURL}/school/club-sport-details`;
const EDIT_WEB_LINK = `${process.env.REACT_APP_APIURL}/school/update-web-link`;
const CREATE_JOB = `${process.env.REACT_APP_APIURL}/school/create-job`;
const JOB_LIST = `${process.env.REACT_APP_APIURL}/school/job-list`;
const EDIT_JOB = `${process.env.REACT_APP_APIURL}/school/update-job`;
const DELETE_JOB = `${process.env.REACT_APP_APIURL}/school/delete-job`;
const JOB_USER_LIST = `${process.env.REACT_APP_APIURL}/school/applied-users-list`;

export const login = async (data) => {
  try {
    return await axiosConfig.post(LOGIN_URL, data);
  } catch (error) {
    return error.response;
  }
};

export const forgetPassword = async (email) => {
  try {
    return await axiosConfig.post(FORGOT_PASSWORD, email);
  } catch (error) {
    return error.response;
  }
};

export const dashboard = async () => {
  try {
    return await axiosConfig.get(DASHBOARD_URL);
  } catch (error) {
    return error.response;
  }
};

export const getAdminProfile = async (id) => {
  try {
    let ADMIN_PROFILE = `${GET_ADMIN_PROFILE}/${id}`;
    return await axiosConfig.get(ADMIN_PROFILE);
  } catch (error) {
    return error.response;
  }
};

export const getPostDetails = async (id) => {
  try {
    let POST_DETAILS_API = `${POST_DETAILS}/${id}`;
    return await axiosConfig.get(POST_DETAILS_API);
  } catch (error) {
    return error.response;
  }
};

export const editAdminProfile = async (data, id) => {
  try {
    let NEW_EDITPROFILE = `${EDIT_ADMINPROFILE}/${id}`;
    return await axiosConfig.put(NEW_EDITPROFILE, data);
  } catch (error) {
    return error.response;
  }
};

export const adminGeneralSetting = async () => {
  try {
    return await axiosConfig.get(ADMIN_GENERAL_SITTING);
  } catch (error) {
    return error.response;
  }
};

export const sendInvite = async (data) => {
  try {
    return await axiosConfig.post(SEND_INVITE, data);
  } catch (error) {
    return error.response;
  }
};

export const schoolRegistration = async (id, data) => {
  try {
    return await axios.post(`${SCHOOL_REGISTRATION}?id=${id}`, data);
  } catch (error) {
    return error.response;
  }
};

export const studentRegistration = async (id, data) => {
  try {
    return await axiosConfig.post(
      `${STUDENT_REGISTRATION}?id=${id ? id : ""}`,
      data
    );
  } catch (error) {
    return error.response;
  }
};

export const adminPlans = async (data) => {
  try {
    return await axiosConfig.get(
      `${ADMIN_PLANS}?user_type=${data?.user_type ? data?.user_type : ""}`
    );
  } catch (error) {
    return error.response;
  }
};

export const getEmailTemplates = async (id) => {
  try {
    let EMAILTEMP = `${EMAIL_TEMPLATE}/${id}`;
    return await axiosConfig.get(EMAILTEMP);
  } catch (error) {
    return error.response;
  }
};

export const editAdminPlans = async (data, id) => {
  try {
    return await axiosConfig.put(`${EDIT_ADMIN_PLANS}/${id}`, data);
  } catch (error) {
    return error.response;
  }
};

export const getSchoolDetails = async (id) => {
  try {
    return await axiosConfig.get(`${SCHOOL_DETAILS}/${id}`);
  } catch (error) {
    return error.response;
  }
};

export const getStudentDetails = async (id = null, u_id = null) => {
  try {
    return await axiosConfig.get(
      `${STUDENT_DETAILS}?id=${id ? id : ""}&u_id=${u_id ? u_id : ""}`
    );
  } catch (error) {
    return error.response;
  }
};

export const viewSchoolDetails = async (id) => {
  try {
    return await axiosConfig.get(`${VIEW_SCHOOL_DETAILS}/${id}`);
  } catch (error) {
    return error.response;
  }
};

export const editSetting = async (data, id) => {
  try {
    let editSetting = `${EDIT_SETTING}/${id}`;
    console.log("settingData", data);
    return await axiosConfig.put(editSetting, data);
  } catch (error) {
    return error.response;
  }
};

export const updatePostStatus = async (data, id) => {
  try {
    let editSetting = `${UPDATE_POST_STATUS}/${id}`;
    console.log(editSetting);
    return await axiosConfig.put(editSetting, data);
  } catch (error) {
    return error.response;
  }
};

export const getSchoolList = async (data) => {
  try {
    if (data.keyWord.toLowerCase() === "active") {
      data.keyWord = "2";
    }
    if (data.keyWord.toLowerCase() === "inactive") {
      data.keyWord = "1";
    }
    console.log("data", data);

    let DATAURL = `${GET_SCHOOL_LISTING}?search=${
      !data?.keyWord ? "" : data?.keyWord
    }&sort_by=${!data?.sortName ? "" : data?.sortName}&page=${
      !data?.itemOffset ? "" : data?.itemOffset
    }&limit=${!data?.itemPerPage ? "" : data?.itemPerPage}&order=${
      !data?.sortTypeName ? "" : data?.sortTypeName
    }`;
    return await axiosConfig.get(DATAURL);
  } catch (error) {
    return error.response;
  }
};

export const studentListing = async (id, params) => {
  try {
    let apiUrl = `${STUDENT_LISTING}?school_id=${id}${params}`;

    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const postListing = async (params) => {
  try {
    let apiUrl = `${POST_LISTING}?school_id=${
      params?.id ? params?.id : ""
    }&search=${params?.search ? params?.search : ""}&user_id=${
      params?.userId ? params?.userId : ""
    }&student_id=${params?.studentId ? params?.studentId : ""}&club_id=${
      params?.club_id ? params?.club_id : ""
    }&academic_year=${
      params?.academicYear ? params?.academicYear : ""
    }&standard=${params?.standard ? params?.standard : ""}&page=${
      params?.page ? params?.page : ""
    }&sport_id=${params?.sport_id ? params?.sport_id : ""}&is_moment=${
      params?.is_moment ? params?.is_moment : ""
    }&get_user_id=${params?.get_user_id ? params?.get_user_id : ""}&schoolId=${
      params?.schoolId ? params?.schoolId : ""
    }&user_type=${params?.user_type ? params?.user_type : ""}`;

    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const updateSchoolProfile = async (id, data) => {
  try {
    return await axiosConfig.put(`${UPDATE_SCHOOL_PROFILE}/${id}`, data);
  } catch (error) {
    return error.response;
  }
};
export const deletecustomfield = async (id) => {
  try {
    return await axiosConfig.delete(`${DELETE_CUSTOME_FIELD}/${id}`);
  } catch (error) {
    return error.response;
  }
};
export const adminSchoolUpdate = async (id, data) => {
  try {
    let editSchoolApi = `${ADMIN_SCHOOL_UPDATE}/${id}`;
    return await axiosConfig.put(editSchoolApi, data);
  } catch (error) {
    return error.response;
  }
};

export const adminSchoolDelete = async (id) => {
  try {
    return await axiosConfig.delete(`${ADMIN_SCHOOL_DELETE}/${id}`);
  } catch (error) {
    return error.response;
  }
};

export const studentDelete = async (id) => {
  try {
    return await axiosConfig.delete(`${DELETE_STUDENT}/${id}`);
  } catch (error) {
    return error.response;
  }
};

export const deletePost = async (id) => {
  try {
    return await axiosConfig.delete(`${DELETE_POST}/${id}`);
  } catch (error) {
    return error.response;
  }
};

export const updateStudent = async (id, body) => {
  try {
    return await axiosConfig.put(`${UPDATE_STUDENT}/${id}`, body);
  } catch (error) {
    return error.response;
  }
};

export const adminStudentListing = async (id, param) => {
  try {
    let apiUrl;

    apiUrl = `${STUDENT_LISTING}?${param}`;

    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const adminStudentListingincom = async (id, param) => {
  try {
    let apiUrl;

    apiUrl = `${STUDENT_LISTING_IN}?${param}`;

    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const adminStudentTaskListing = async (id, param) => {
  try {
    let apiUrl;

    apiUrl = `${TASK_LISTING_REPORT}?${param}`;

    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const cmsListing = async (params) => {
  try {
    let apiUrl = `${CMS_LISTING}?search=${params.search}&sort_by=${params.sort}&order=${params.order}&page=${params.page}&limit=${params.limit}`;
    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const cmsView = async (id) => {
  try {
    let apiUrl = `${CMS_VIEW}/${id}`;
    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const cmsUpdate = async (id, data) => {
  try {
    let apiUrl = `${CMS_UPDATE}/${id}`;
    return await axiosConfig.put(apiUrl, data);
  } catch (error) {
    return error.response;
  }
};

export const emailListing = async (params) => {
  try {
    let apiUrl = `${EMAIL_LISTING}?search=${params.search}&sort_by=${params.sort}&order=${params.order}&page=${params.page}&limit=${params.limit}`;
    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const emailEdit = async (id, data) => {
  try {
    let apiUrl = `${EMAIL_UPDATE}/${id}`;
    return await axiosConfig.put(apiUrl, data);
  } catch (error) {
    return error.response;
  }
};

export const clubListing = async (params) => {
  try {
    let apiUrl = `${CLUB_LISTING}?search=${params.search}&sort_by=${params.sort}&order=${params.order}&page=${params.page}&limit=${params.limit}`;
    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const clubView = async (params) => {
  try {
    let apiUrl = `${CLUB_VIEW}/${params?.id ? params?.id : ""}?search=${
      params?.search ? params?.search : ""
    }&user_id=${params?.userId ? params?.userId : ""}&page=${
      params?.page ? params?.page : ""
    }`;
    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const taskView = async (params) => {
  try {
    let apiUrl = `${TASK_VIEW}/${params?.id ? params?.id : ""}?id=${
      params?.id ? params?.id : ""
    }`;
    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const clubDelete = async (id) => {
  try {
    return await axiosConfig.delete(`${CLUB_DELETE}/${id}`);
  } catch (error) {
    return error.response;
  }
};

export const TaskDelete = async (id) => {
  try {
    return await axiosConfig.delete(`${TASK_DELETE}/${id}`);
  } catch (error) {
    return error.response;
  }
};

export const clubUpdate = async (id, data) => {
  try {
    let apiUrl = `${CLUB_UPDATE}/${id}`;
    return await axiosConfig.put(apiUrl, data);
  } catch (error) {
    return error.response;
  }
};

export const taskUpdate = async (id, data) => {
  try {
    let apiUrl = `${TASK_UPDATE}/${id}`;
    return await axiosConfig.patch(apiUrl, data);
  } catch (error) {
    return error.response;
  }
};

export const adminPostStatus = async (id, data) => {
  try {
    let apiUrl = `${ADMIN_POST_STATUS}/${id}`;
    return await axiosConfig.put(apiUrl, data);
  } catch (error) {
    return error.response;
  }
};

export const schoolClubListing = async (params) => {
  try {
    let apiUrl = `${CLUB_LISTING}?school_id=${params.id}&search=${params.search}&sort_by=${params.sort}&order=${params.order}&page=${params.page}&limit=${params.limit}`;
    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const schoolTaskListing = async (params) => {
  try {
    let apiUrl = `${TASK_LISTING}?search=${params.search}&order=${params.order}&page=${params.page}&limit=${params.limit}`;
    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const bulkInvite = async (data) => {
  try {
    return await axiosConfig.post(SCHOOL_BULK_INVITE, data);
  } catch (error) {
    return error.response;
  }
};

export const createClub = async (data) => {
  try {
    return await axiosConfig.post(CREATE_CLUB, data);
  } catch (error) {
    return error.response;
  }
};

export const createTask = async (data) => {
  try {
    return await axiosConfig.post(CREATE_TASK, data);
  } catch (error) {
    return error.response;
  }
};

export const adminCreatePlan = async (data) => {
  try {
    return await axiosConfig.post(CREATE_PLAN, data);
  } catch (error) {
    return error.response;
  }
};
export const planDelete = async (id) => {
  try {
    return await axiosConfig.delete(`${DELETE_PLAN}/${id}`);
  } catch (error) {
    return error.response;
  }
};

export const adminForgetPassword = async (data, u_id) => {
  try {
    return await axiosConfig.post(`${RESET_PASSWORD}/${u_id}`, data);
  } catch (error) {
    return error.response;
  }
};

export const studentListToClub = async (data) => {
  try {
    return await axiosConfig.get(
      `${STUDENT_LIST_TO_CLUB}?club_id=${data.id}&schoolId=${data.schoolId}`
    );
  } catch (error) {
    return error.response;
  }
};

export const addStudentToClub = async (data) => {
  try {
    return await axiosConfig.put(ADD_STUDENT_TO_CLUB, data);
  } catch (error) {
    return error.response;
  }
};

export const schoolDashboard = async (id) => {
  try {
    let apiUrl = `${SCHOOL_DASHBOARD}?school_id=${id}`;
    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const getCountryLists = async () => {
  try {
    return await axiosConfig.get(COUNTRY_API);
  } catch (error) {
    return error.response;
  }
};

export const getSubscribed = async (data) => {
  try {
    return await axiosConfig.post(SUBSCRIBER, data);
  } catch (error) {
    return error.response;
  }
};

export const toContact = async (data) => {
  try {
    return await axiosConfig.post(CONTACT, data);
  } catch (error) {
    return error.response;
  }
};

export const getSubscriptionDetail = async (data) => {
  try {
    return await axiosConfig.get(
      `${CHECK_SUBSCRIPTION_DETAIL}?email=${data.email ? data.email : ""}`,
      data
    );
  } catch (error) {
    return error.response;
  }
};

export const choosePlan = async (data, id) => {
  try {
    let NEW_CHOOSE_PLAN = `${CHOOSE_PLAN}/${id}`;
    return await axiosConfig.put(NEW_CHOOSE_PLAN, data);
  } catch (error) {
    return error.response;
  }
};

export const upLoadProfileImage = async (data, id) => {
  try {
    let NEW_PROFILE_IMAGE_URL = `${UPLOAD_PROFILE_IMAGE}/${id}`;
    return await axiosConfig.post(NEW_PROFILE_IMAGE_URL, data);
  } catch (error) {
    return error.response;
  }
};

// Uploads the post data to the axios server.
export const uploadPost = async (data) => {
  try {
    return await axiosConfig.post(UPLOAD_POST, data);
  } catch (error) {
    return error.response;
  }
};

export const schoolPostLike = async (data) => {
  try {
    return await axiosConfig.post(SCHOOL_POST_LIKE, data);
  } catch (error) {
    return error.response;
  }
};

export const schoolPostComment = async (data) => {
  try {
    return await axiosConfig.post(SCHOOL_POST_COMMENT, data);
  } catch (error) {
    return error.response;
  }
};

export const updatePost = async (id, body) => {
  try {
    return await axiosConfig.put(`${POST_UPDATE}/${id}`, body);
  } catch (error) {
    return error.response;
  }
};

export const getRecentActivity = async (body) => {
  try {
    return await axiosConfig.get(
      `${RECENT_ACTIVITY}?school_id=${
        body?.schoolId ? body?.schoolId : ""
      }&user_id=${body?.userId ? body?.userId : ""}&notifications=${
        body.notification
      }&page=${body?.page ? body?.page : ""}`
    );
  } catch (error) {
    return error.response;
  }
};

export const getClassmatesList = async (body) => {
  try {
    return await axiosConfig.get(
      `${CLASSMATES_LIST}?school_id=${
        body?.schoolId ? body?.schoolId : ""
      }&standard=${body?.standard ? body?.standard : ""}&user_id=${
        body?.userId ? body?.userId : ""
      }&academic_year=${body?.academicYear ? body?.academicYear : ""}&search=${
        body?.search ? body?.search : ""
      }&page=${body?.page ? body?.page : ""}`
    );
  } catch (error) {
    return error.response;
  }
};

// Upload new profile to axios config
export const uploadNewProfile = async (data) => {
  try {
    let NEW_CONVERT_URL = `${CONVERT_POST_TO_PROFILE}?user_id=${
      data?.userId ? data?.userId : ""
    }&post_id=${data?.postId ? data?.postId : ""}`;
    return await axiosConfig.put(NEW_CONVERT_URL);
  } catch (error) {
    return error.response;
  }
};

export const convertPostToNft = async (data) => {
  try {
    return await axiosConfig.post(CONVERT_POST_TO_NFT, data);
  } catch (error) {
    return error.response;
  }
};

export const getNFTPosts = async (data) => {
  try {
    return await axiosConfig.get(
      `${NFT_POSTS}?user_id=${data?.userId ? data?.userId : ""}&page=${
        data?.page ? data?.page : ""
      }`
    );
  } catch (error) {
    return error.response;
  }
};

export const studentLogin = async (data) => {
  try {
    return await axiosConfig.post(STUDENT_LOGIN, data);
  } catch (error) {
    return error.response;
  }
};

export const renewPlan = async (data) => {
  try {
    return await axiosConfig.put(
      `${RENEW_PLAN}?subscription_id=${
        data?.subscription_id ? data?.subscription_id : ""
      }&user_id=${data?.user_id ? data?.user_id : ""}&existing_plan_id=${
        data?.existing_plan_id ? data?.existing_plan_id : ""
      }`
    );
  } catch (error) {
    return error.response;
  }
};

export const updateStudentDetail = async (data, id) => {
  try {
    return await axiosConfig.put(`${UPDATE_STUDENT_PROFILE}/${id}`, data);
  } catch (error) {
    return error.response;
  }
};

export const updateMetaMask = async (data, id) => {
  try {
    return await axiosConfig.put(`${UPDATE_METAMASK_ADDRESS}/${id}`, data);
  } catch (error) {
    return error.response;
  }
};

export const createCollage = async (data) => {
  try {
    return await axiosConfig.get(
      `${YEARBOOK_CREATE_COLLAGE}?academic_year=${
        data?.academicYear ? data?.academicYear : ""
      }&school_id=${data?.schoolId ? data?.schoolId : ""}`
    );
  } catch (error) {
    return error.response;
  }
};

export const getYearBookHistory = async (data) => {
  try {
    return await axiosConfig.get(
      `${YEARBOOK_HISTORY}?school_id=${data.schoolId ? data?.schoolId : ""}`
    );
  } catch (error) {
    return error.response;
  }
};

export const getYearBookStudents = async (data) => {
  try {
    return await axiosConfig.get(
      `${YEARBOOK_STUDENTS}?academic_year=${
        data?.academicYear ? data?.academicYear : ""
      }&search=${data.search ? data.search : ""}&sort_by=${
        data.sorts ? data.sorts : ""
      }&order=${data.order ? data.order : ""}&page=${
        data.page ? data.page : ""
      }&limit=${data.limit ? data.limit : ""}&school_id=${
        data?.school_id ? data?.school_id : ""
      }`
    );
  } catch (error) {
    return error.response;
  }
};

export const sendMessage = async (data) => {
  try {
    return await axiosConfig.post(CHATTING_MESSAGE, data);
  } catch (error) {
    return error.response;
  }
};
export const listChatUsers = async (data) => {
  try {
    return await axiosConfig.get(
      `${CHAT_USER_LIST}?id=${data?.id ? data?.id : ""}&search=${
        data?.search ? data?.search : ""
      }&page=${data?.page ? data?.page : ""}`
    );
  } catch (error) {
    return error.response;
  }
};

export const chatList = async (data) => {
  try {
    return await axiosConfig.get(
      `${CHAT_LIST}?user_id=${data?.user_id ? data?.user_id : ""}&reciever_id=${
        data?.reciever_id ? data?.reciever_id : ""
      }&page=${data?.page ? data?.page : ""}`
    );
  } catch (error) {
    return error.response;
  }
};

export const getNewNotification = async (body) => {
  try {
    return await axiosConfig.get(
      `${NEW_NOTIFICATION}?school_id=${
        body?.schoolId ? body?.schoolId : ""
      }&user_id=${body?.userId ? body?.userId : ""}&page=${
        body?.page ? body?.page : ""
      }`
    );
  } catch (error) {
    return error.response;
  }
};

export const initialPayment = async (body) => {
  try {
    return await axiosConfig.get(
      `${INITIAL_PAYMENT}?amount=${body?.amount ? body?.amount : ""}`
    );
  } catch (error) {
    return error.response;
  }
};

export const paymentDone = async (data) => {
  try {
    return await axiosConfig.put(PAYMENT_DONE, data);
  } catch (error) {
    return error.response;
  }
};

export const downloadCSV = async (data) => {
  try {
    return await axiosConfig.get(
      `${CSV_TASK_DOWNLOAD}?search=${data?.search ? data?.search : ""}&sortBy=${
        data?.sort ? data?.sort : ""
      }&order=${data?.order ? data?.order : ""}&isCompleted=${
        data?.isCompleted ? data?.isCompleted : null
      }&customField=${data?.customField ? data?.customField : null}`
    );
  } catch (error) {
    return error.response;
  }
};

export const planHistory = async (id, param) => {
  try {
    return await axiosConfig.get(`${EARNING_REPORT}/${id}?${param}`);
  } catch (error) {
    return error.response;
  }
};

export const schoolTeamListing = async (params) => {
  try {
    let apiUrl = `${TEAM_LISTING}?school_id=${params.id}&search=${params.search}&sort_by=${params.sort}&order=${params.order}&page=${params.page}&limit=${params.limit}`;
    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const createTeam = async (data) => {
  try {
    return await axiosConfig.post(CREATE_TEAM, data);
  } catch (error) {
    return error.response;
  }
};

export const teamView = async (params) => {
  try {
    let apiUrl = `${TEAM_VIEW}/${params?.id ? params?.id : ""}?search=${
      params?.search ? params?.search : ""
    }&user_id=${params?.userId ? params?.userId : ""}&page=${
      params?.page ? params?.page : ""
    }`;
    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const teamUpdate = async (id, data) => {
  try {
    let apiUrl = `${TEAM_UPDATE}/${id}`;
    return await axiosConfig.put(apiUrl, data);
  } catch (error) {
    return error.response;
  }
};

export const teamDelete = async (id) => {
  try {
    return await axiosConfig.delete(`${TEAM_DELETE}/${id}`);
  } catch (error) {
    return error.response;
  }
};

export const addStudentToTeam = async (data) => {
  try {
    return await axiosConfig.put(ADD_STUDENT_TO_TEAM, data);
  } catch (error) {
    return error.response;
  }
};

export const studentListToTeam = async (data) => {
  try {
    return await axiosConfig.get(
      `${STUDENT_LIST_TO_TEAM}?sport_id=${data.id}&schoolId=${data.schoolId}`
    );
  } catch (error) {
    return error.response;
  }
};

export const saveFilterImage = async (data) => {
  try {
    return await axiosConfig.post(FILTERED_IMG, data);
  } catch (error) {
    return error.response;
  }
};

export const convertImage = async (data) => {
  try {
    return await axiosConfig.post(COVERT_BASE_IMG, data);
  } catch (error) {
    return error.response;
  }
};

export const getDigitalArt = async (body) => {
  try {
    return await axiosConfig.get(
      `${DIGITAL_ARTS}?user_id=${body.userId ? body.userId : ""}&school_id=${
        body?.id ? body?.id : ""
      }&academic_year=${
        body?.academicYear ? body?.academicYear : ""
      }&standard=${body?.standard ? body?.standard : ""}&page=${
        body?.page ? body?.page : ""
      }`
    );
  } catch (error) {
    return error.response;
  }
};

export const likeList = async (data) => {
  try {
    return await axiosConfig.get(
      `${LIKE_LIST}?post_id=${data.id ? data?.id : ""}&page=${
        data.page ? data.page : ""
      }`
    );
  } catch (error) {
    return error.response;
  }
};

export const commentList = async (data) => {
  try {
    return await axiosConfig.get(
      `${COMMENT_LIST}?post_id=${data.id ? data?.id : ""}&page=${
        data.page ? data.page : ""
      }&sort_by=${data.sort_by ? data?.sort_by : ""}&order=${
        data?.order ? data?.order : ""
      }`
    );
  } catch (error) {
    return error.response;
  }
};

export const memberList = async (data) => {
  try {
    return await axiosConfig.get(
      `${MEMBER_LIST}?sport_id=${data.sportId ? data?.sportId : ""}&page=${
        data.page ? data.page : ""
      }&school_id=${data.schoolId ? data.schoolId : ""}&club_id=${
        data.clubId ? data.clubId : ""
      }&user_id=${data?.userId ? data?.userId : ""}`
    );
  } catch (error) {
    return error.response;
  }
};

export const getMyMomentPostListing = async (data) => {
  try {
    let apiUrl = `${MY_MOMENT_POST_LIST}?is_moment=${
      data?.is_moment ? data?.is_moment : ""
    }&user_id=${data?.user_id ? data?.user_id : ""}&school_id=${
      data?.school_id ? data?.school_id : ""
    }&page=${data?.page ? data?.page : ""}&is_trade_moment=${
      data?.is_trade_moment ? data?.is_trade_moment : ""
    }`;

    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const postSignature = async (data) => {
  try {
    return await axiosConfig.post(SIGNATURE, data);
  } catch (error) {
    return error.response;
  }
};

export const getSignatureListing = async (data) => {
  try {
    let apiUrl = `${SIGNATURE_LISTING}/${data.id}?page=${
      data?.page ? data?.page : ""
    }`;

    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const updateSignature = async (id, data) => {
  try {
    return await axiosConfig.put(`${SIGNATURE_UPDATE}/${id}`, data);
  } catch (error) {
    return error.response;
  }
};

export const signatureAndPostListing = async (data) => {
  try {
    let apiUrl = `${SIGNATURE_POST_LISTING}?schoolId=${
      data?.schoolId ? data?.schoolId : ""
    }&get_user_id=${data?.getUserId ? data?.getUserId : ""}&page=${
      data?.page ? data?.page : ""
    }`;

    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const getSignatureClassmatesList = async (body) => {
  console.log("getSignatureClassmatesList", body);
  try {
    return await axiosConfig.get(
      `${SIGNATURE_CLASSMATES_LIST}?school_id=${
        body?.schoolId ? body?.schoolId : ""
      }&standard=${body?.standard ? body?.standard : ""}&is_signed=${
        body?.isSinged ? body?.isSinged : ""
      }&academic_year=${body?.academicYear ? body?.academicYear : ""}&user_id=${
        body?.userId ? body?.userId : ""
      }&page=${body?.page ? body?.page : ""}`
    );
  } catch (error) {
    return error.response;
  }
};

export const createVotingCategory = async (data, id) => {
  try {
    return await axiosConfig.post(CREATE_VOTING_CATEGORY, data);
  } catch (error) {
    return error.response;
  }
};

export const schoolVoteCategoryListing = async (params) => {
  try {
    let apiUrl = `${LIST_VOTING_CATEGORY}?school_id=${
      params.id ? params.id : ""
    }&search=${params.search ? params.search : ""}&sort_by=${
      params.sort ? params.sort : ""
    }&order=${params.order ? params.order : ""}&page=${
      params.page ? params.page : ""
    }&limit=${params.limit ? params.limit : ""}&user_type=${
      params?.userType ? params?.userType : ""
    }`;
    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const voteCategoryDelete = async (id) => {
  try {
    return await axiosConfig.delete(`${DELETE_VOTE_CATEGORY}/${id}`);
  } catch (error) {
    return error.response;
  }
};

export const getVotingStatus = async (data) => {
  try {
    let apiUrl = `${VOTING_STATUS}?school_id=${
      data?.schoolId ? data?.schoolId : ""
    }`;

    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const updateVotingStatus = async (data) => {
  try {
    return await axiosConfig.put(`${UPDATE_VOTING_STATUS}`, data);
  } catch (error) {
    return error.response;
  }
};

export const getCategoryDetails = async (data) => {
  try {
    let apiUrl = `${CATEGORY_DETAILS}/${data.id ? data.id : ""}`;

    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const getViewCategoryDetails = async (data) => {
  try {
    let apiUrl = `${VIEW_CATEGORY_DETAILS}/${data.id ? data.id : ""}`;

    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const updateCategoryData = async (id, data) => {
  try {
    return await axiosConfig.put(
      `${UPDATE_CATEGORY_DATA}/${id ? id : ""}`,
      data
    );
  } catch (error) {
    return error.response;
  }
};

export const getAcademicYearHistoryVoting = async (data) => {
  try {
    let apiUrl = `${VOTING_ACADEMIC_YEAR}?school_id=${
      data?.schoolId ? data?.schoolId : ""
    }&sort_by=${data?.sort ? data?.sort : ""}&order=${
      data?.order ? data?.order : ""
    }`;

    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const getVotingWinnerHistory = async (data) => {
  try {
    let apiUrl = `${VOTING_WINNER}?school_id=${
      data?.schoolId ? data?.schoolId : ""
    }&academic_year=${data?.academicYear ? data?.academicYear : ""}&sort_by=${
      data?.sortBy ? data?.sortBy : ""
    }&order=${data?.order ? data?.order : ""}&standard=${
      data?.standard ? data?.standard : ""
    }`;

    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const getVotingWinnerDetails = async (data) => {
  try {
    let apiUrl = `${WINNERS_DETAILS}?school_id=${
      data?.schoolId ? data?.schoolId : ""
    }&category_id=${data?.categoryId ? data?.categoryId : ""}&academic_year=${
      data?.academicYear ? data?.academicYear : ""
    }&sort_by=${data?.sortby ? data?.sortby : ""}&order=${
      data?.order ? data?.order : ""
    }&standard=${data?.standard ? data?.standard : ""}`;

    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const submitVotes = async (data) => {
  try {
    return await axiosConfig.post(SUBMIT_VOTES, data);
  } catch (error) {
    return error.response;
  }
};

export const getAlreadyVotedDetails = async (data) => {
  try {
    let apiUrl = `${ALREADY_VOTED}?action_user_id=${
      data.action_user_id ? data.action_user_id : ""
    }&type=${data?.type ? data?.type : ""}&academic_year=${
      data?.academic_year ? data?.academic_year : ""
    }`;

    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const getDigitalArtWithCount = async (body) => {
  try {
    return await axiosConfig.get(
      `${DIGITAL_ARTS_WITH_VOTING}?user_id=${
        body.userId ? body.userId : ""
      }&school_id=${body?.id ? body?.id : ""}&academic_year=${
        body?.academicYear ? body?.academicYear : ""
      }&standard=${body?.standard ? body?.standard : ""}&limit=${
        body?.limit ? body?.limit : ""
      }&page=${body?.page ? body?.page : ""}`
    );
  } catch (error) {
    return error.response;
  }
};

export const getVotingStudentWinnerHistory = async (data) => {
  try {
    let apiUrl = `${STUDENT_VOTING_WINNER}?standard=${
      data?.standard ? data?.standard : ""
    }&academic_year=${data?.academicYear ? data?.academicYear : ""}&type=${
      data?.type ? data?.type : ""
    }`;

    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const getVotingStandardHistory = async (data) => {
  try {
    let apiUrl = `${VOTING_STANDARD_HISTORY}?academic_year=${
      data?.academicYear ? data?.academicYear : ""
    }`;

    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const myMomentAndTradePostListing = async (params) => {
  try {
    let apiUrl = `${POST_LISTING_SM_TM}?school_id=${
      params?.id ? params?.id : ""
    }&search=${params?.search ? params?.search : ""}&user_id=${
      params?.userId ? params?.userId : ""
    }&student_id=${params?.studentId ? params?.studentId : ""}&club_id=${
      params?.club_id ? params?.club_id : ""
    }&academic_year=${
      params?.academicYear ? params?.academicYear : ""
    }&standard=${params?.standard ? params?.standard : ""}&page=${
      params?.page ? params?.page : ""
    }&sport_id=${params?.sport_id ? params?.sport_id : ""}&is_trade_moment=${
      params?.is_trade_moment ? params?.is_trade_moment : ""
    }&get_user_id=${params?.get_user_id ? params?.get_user_id : ""}&schoolId=${
      params?.schoolId ? params?.schoolId : ""
    }&user_type=${params?.user_type ? params?.user_type : ""}`;

    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const labelListing = async (params) => {
  try {
    let apiUrl = `${LABEL_LIST}?school_id=${
      params?.id ? params?.id : ""
    }&search=${params?.search ? params?.search : ""}&page=${
      params?.page ? params?.page : ""
    }&sort_by=${params?.sort_by ? params?.sort_by : ""}&limit=${
      params?.limit ? params?.limit : ""
    }&order=${params?.orderBy ? params?.orderBy : ""}`;

    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const getLabelDetails = async (params) => {
  try {
    let apiUrl = `${LABEL_DETAIL}/${params?.id ? params?.id : ""}`;

    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const updateLabelData = async (id, data) => {
  try {
    return await axiosConfig.put(`${UPDATE_LABEL}/${id ? id : ""}`, data);
  } catch (error) {
    return error.response;
  }
};
export const updateMobileLabelData = async (id, data) => {
  try {
    return await axiosConfig.put(
      `${UPDATE_LABEL_MOBILE}/${id ? id : ""}`,
      data
    );
  } catch (error) {
    return error.response;
  }
};

export const schoolLabelListing = async (params) => {
  try {
    let apiUrl = `${SCHOOL_LABEL_LIST}?school_id=${
      params?.id ? params?.id : ""
    }&search=${params?.search ? params?.search : ""}&page=${
      params?.page ? params?.page : ""
    }&sort_by=${params?.sort_by ? params?.sort_by : ""}&limit=${
      params?.limit ? params?.limit : ""
    }&order=${params?.orderBy ? params?.orderBy : ""}&user_type=${
      params?.user_type ? params?.user_type : ""
    }`;

    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const updateUpdatedLabel = async (id, data) => {
  try {
    return await axiosConfig.put(
      `${UPDATE_UPDATED_LABEL}/${id ? id : ""}`,
      data
    );
  } catch (error) {
    return error.response;
  }
};

export const labelDelete = async (id) => {
  try {
    return await axiosConfig.delete(`${DELETE_LABEL}/${id}`);
  } catch (error) {
    return error.response;
  }
};

export const getVerificationDocs = async (param) => {
  try {
    return await axiosConfig.get(
      `${GET_DOCUMENTATION}/${param?.id ? param?.id : ""}?user_type=${
        param?.user_type ? param?.user_type : ""
      }`
    );
  } catch (error) {
    return error.response;
  }
};

export const docsAcceptOrReject = async (id, body) => {
  try {
    return await axiosConfig.put(
      `${VERIFY_DOCS}?user_id=${id ? id : ""}`,
      body
    );
  } catch (error) {
    return error.response;
  }
};

export const docsStudentUpload = async (params, body) => {
  try {
    return await axiosConfig.put(
      `${UPDATE_VERIFY_DOCS}?user_id=${
        params?.id ? params?.id : ""
      }&school_id=${params?.school_id ? params?.school_id : ""}`,
      body
    );
  } catch (error) {
    return error.response;
  }
};

export const createBroadcastMessage = async (data) => {
  try {
    return await axiosConfig.post(BROADCAST_CREATE_MESSAGE, data);
  } catch (error) {
    return error.response;
  }
};

export const broadcastMessageListing = async (query) => {
  try {
    let apiUrl = `${BROADCAST_MESSAGE_LIST}?school_id=${
      query?.id ? query?.id : ""
    }&search=${query?.search ? query?.search : ""}&page=${
      query?.page ? query?.page : ""
    }&sort_by=${query?.sort_by ? query?.sort_by : ""}&limit=${
      query?.limit ? query?.limit : ""
    }&order=${query?.orderBy ? query?.orderBy : ""}`;

    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const getSingleMessage = async (id) => {
  try {
    let apiUrl = `${SINGLE_MESSAGE}/${id ? id : ""}`;

    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const updateMessage = async (id, body) => {
  try {
    return await axiosConfig.put(
      `${UPDATE_BROADCAST_MESSAGE}/${id ? id : ""}`,
      body
    );
  } catch (error) {
    return error.response;
  }
};

export const deleteMessage = async (id, body) => {
  try {
    return await axiosConfig.delete(
      `${DELETE_BROADCAST_MESSAGE}/${id ? id : ""}?school_id=${
        body?.school_id ? body?.school_id : ""
      }`,
      body
    );
  } catch (error) {
    return error.response;
  }
};

export const updateLogo = async (id, body) => {
  try {
    return await axiosConfig.put(`${SCHOOL_LOGO_UPDATE}/${id ? id : ""}`, body);
  } catch (error) {
    return error.response;
  }
};

export const createWebLink = async (type, data) => {
  try {
    return await axiosConfig.post(
      `${CREATE_WEB_LINK}?type=${type ? type : ""}`,
      data
    );
  } catch (error) {
    return error.response;
  }
};

export const webLinkListing = async (query) => {
  try {
    let apiUrl = `${WEB_LINK_LIST}?school_id=${
      query?.id ? query?.id : ""
    }&search=${query?.search ? query?.search : ""}&page=${
      query?.page ? query?.page : ""
    }&sort_by=${query?.sort_by ? query?.sort_by : ""}&limit=${
      query?.limit ? query?.limit : ""
    }&order=${query?.orderBy ? query?.orderBy : ""}`;

    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const webLinkDelete = async (id) => {
  try {
    return await axiosConfig.delete(`${DELETE_WEB_LINK}/${id}`);
  } catch (error) {
    return error.response;
  }
};

export const webLinkTypeListing = async (data, body) => {
  try {
    console.log("body", body);
    return await axiosConfig.get(
      `${LIST_TYPE_WEB_LINK}?type=${data ? data : ""}&school_id=${
        body?.school_id ? body.school_id : ""
      }`
    );
  } catch (error) {
    return error.response;
  }
};

export const editWebLink = async (id, type, body) => {
  try {
    return await axiosConfig.put(
      `${EDIT_WEB_LINK}/${id ? id : ""}?type=${type ? type : ""}`,
      body
    );
  } catch (error) {
    return error.response;
  }
};

export const createJob = async (data) => {
  try {
    return await axiosConfig.post(`${CREATE_JOB}`, data);
  } catch (error) {
    return error.response;
  }
};

export const JobListing = async (query) => {
  try {
    let apiUrl = `${JOB_LIST}?school_id=${query?.id ? query?.id : ""}&search=${
      query?.search ? query?.search : ""
    }&page=${query?.page ? query?.page : ""}&sort_by=${
      query?.sort_by ? query?.sort_by : ""
    }&limit=${query?.limit ? query?.limit : ""}&order=${
      query?.orderBy ? query?.orderBy : ""
    }`;

    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};

export const editJob = async (id, body) => {
  try {
    return await axiosConfig.put(`${EDIT_JOB}/${id ? id : ""}`, body);
  } catch (error) {
    return error.response;
  }
};

export const deleteWebJob = async (id) => {
  try {
    return await axiosConfig.delete(`${DELETE_JOB}/${id}`);
  } catch (error) {
    return error.response;
  }
};

export const JobUserListing = async (id, query) => {
  try {
    let apiUrl = `${JOB_USER_LIST}/${id ? id : ""}?school_id=${
      query?.id ? query?.id : ""
    }&search=${query?.search ? query?.search : ""}&page=${
      query?.page ? query?.page : ""
    }&sort_by=${query?.sort_by ? query?.sort_by : ""}&limit=${
      query?.limit ? query?.limit : ""
    }&order=${query?.orderBy ? query?.orderBy : ""}`;

    return await axiosConfig.get(apiUrl);
  } catch (error) {
    return error.response;
  }
};
