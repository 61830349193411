import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { SiteCard } from "../../../common/website/HOC.jsx";
import { device } from "../../../common/website/Devices";
import { colors, space } from "../../../common/website/Variables";
import Button from "../../../common/website/Button.jsx";

import HeadingThree from "../../../common/website/HeadingThree.jsx";
import DigitalArtFilterList from "../../../common/website/DigitalArtFilterList.jsx";
import IconDigitalArtCreator from "../../../images/website/icon_digital_art.svg";
import ImageDigitalArtCreator from "../../../images/website/image_digital_art_creator.png";
import { useLocation, useNavigate } from "react-router-dom";
import domtoimage from "dom-to-image";
import { loaderContext } from "../../../components/global loader/global-loader-provider";
import {
  convertImage,
  getSubscriptionDetail,
  saveFilterImage,
} from "../../../services/auth.services.js";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import { ADMIN_USER_DATA } from "../../../utils/constant.js";
import { student, website } from "../../../linksprovider.js";
import { PostContext } from "../../../components/website/header/MainHeader.jsx";
import { AppContext } from "../../../App.js";

const DigitalArtCreatorDetailsRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  .club_group {
    display: none !important;
  }
  h3,
  h4,
  p {
    position: static;
  }
  .body_full_screen {
    width: 100%;
    height: auto;
    .full_screen_card {
      border-radius: ${space.space16};
      padding: ${space.space16} 0;
      height: 100%;
      .head {
        display: flex;
        align-items: flex-start;
        grid-gap: ${space.space8};
        padding-bottom: ${space.space16};
        border-bottom: 1px solid ${colors.lightwhiteborder};
        margin: 0 ${space.space16} ${space.space16};
        img {
          width: 24px;
          height: 24px;
        }
        h3 {
          margin: 0;
        }
      }
    }
  }
  .list_wrap_main {
    height: auto;
    padding: 0 ${space.space16};
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    grid-gap: ${space.space24};
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(172 172 172 / 10%);
      border-radius: 8px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    /* Fire Fox */
    scrollbar-color: rgb(172 172 172 / 10%) transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: transparent;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(172 172 172 / 10%);
      border-radius: 8px;
    }
    /* End Fire Fox */
  }
  .image_detail {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: auto;
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: ${space.space10};
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(172 172 172 / 10%);
      border-radius: 8px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    /* Fire Fox */
    scrollbar-color: rgb(172 172 172 / 10%) transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: transparent;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(172 172 172 / 10%);
      border-radius: 8px;
    }
    /* End Fire Fox */
    .btn_group {
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: ${space.space16};
      margin: ${space.space24} 0 0;
    }
    .image {
      width: 100%;
      max-height: 614px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      img {
        height: auto;
        object-fit: contain;
      }
    }
  }
  @media ${device.mobileM} {
    .body_full_screen {
      .full_screen_card {
        .head {
          align-items: center;
        }
      }
    }
  }
  @media ${device.mobileL} {
    .body_full_screen {
      .full_screen_card {
        .head {
          grid-gap: ${space.space14};
          img {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
    .body_full_screen {
      .full_screen_card {
        padding: ${space.space30} 0;
        .head {
          padding-bottom: ${space.space30};
          margin: 0 ${space.space30} ${space.space30};
        }
      }
    }
    .list_wrap_main {
      padding: 0 ${space.space30};
    }
  }
  @media ${device.tablet} {
    width: 75%;
    .list_wrap_main {
      grid-gap: ${space.space16};
      flex-direction: row;
      overflow-y: auto;
      height: calc(100vh - 202px);
      padding: 0 0 0 ${space.space30};
    }
    .body_full_screen {
      padding-left: ${space.space20};
      height: calc(100vh - 40px);
    }
    .image_detail {
      padding-top: ${space.space10};
      padding-bottom: 0;
      padding-right: ${space.space30};
      max-height: calc(100vh - 202px);
      flex-direction: column;
      .btn_group {
        justify-content: flex-end;
        margin: 0 0 ${space.space24};
      }
    }
  }
  @media ${device.laptop} {
    width: 77%;
    .list_wrap_main {
      grid-gap: ${space.space30};
    }
  }
  @media ${device.laptopL} {
    width: 80%;
    .list_wrap_main {
      height: calc(100vh - 222px);
    }
    .body_full_screen {
      padding-left: ${space.space40};
      height: calc(100vh - 60px);
    }
    .image_detail {
      max-height: calc(100vh - 222px);
      .btn_group {
        margin: 0 0 ${space.space40};
      }
    }
  }
`;
export const FilterContext = createContext(null);
function DigitalArtCreatorDetails() {
  //initial value of filters
  const initialFilterValues = [
    {
      name: "Brightness",
      property: "brightness",
      value: 100,
      range: {
        min: 0,
        max: 200,
      },
      unit: "%",
      step: 1,
    },
    {
      name: "Contrast",
      property: "contrast",
      value: 100,
      range: {
        min: 0,
        max: 200,
      },
      unit: "%",
      step: 1,
    },
    {
      name: "Saturation",
      property: "saturate",
      value: 100,
      range: {
        min: 0,
        max: 200,
      },
      unit: "%",
      step: 1,
    },
    {
      name: "Sepia",
      property: "sepia",
      value: 0,
      range: {
        min: 0,
        max: 200,
      },
      unit: "%",
      step: 1,
    },
  ];
  //main useState of filter
  const [options, setOptions] = useState(initialFilterValues);
  //save string value of base64
  const [saveBaseStrg, setSaveBaseStrg] = useState("");
  //useState use for preChecked of filters
  const [loadId, setLoadId] = useState("");
  //getting image link from state

  //refer to image reference
  let imageRef = useRef();
  const { digitalImgLink } = useContext(AppContext);
  const { loaderDispatch } = useContext(loaderContext);
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  let navigate = useNavigate();
  const { setUserInfo, userInfo, handelLogout } = useContext(PostContext);

  useEffect(() => {
    if (digitalImgLink) {
      handelBaseConvert();
    }
  }, [digitalImgLink]);

  //getting student details on page load
  const getStudentProfile = async () => {
    try {
      let studentData = await getSubscriptionDetail(userData);
      if (studentData?.data?.status === 410) {
        handelLogout();
      }
      if (studentData?.data?.status === StatusCodes.OK) {
        let data = studentData?.data?.data;
        console.log("data?.sport_id,", data?.sport_id);
        setUserInfo(data);
      } else {
        toast(studentData?.data?.massage, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  function getImageStyle() {
    const filters = options.map((option) => {
      return `${option.property}(${option.value}${option.unit})`;
    });
    return { filter: filters.join(" ") };
  }

  const handelSaveFilterImage = () => {
    if (imageRef?.current) {
      domtoimage
        .toPng(imageRef?.current)
        .then(function (dataUrl) {
          console.log("dataUrl", dataUrl);
          handelSaveFilteredImage(dataUrl);
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
    }
  };

  const handelSaveFilteredImage = async (dataUrl) => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let body = {
        postName: dataUrl,
        status: 1,
        user_id: userInfo?.id,
        digital_id: 1,
        postType: 1,
        school_id: userInfo?.User?.id,
      };
      let result = await saveFilterImage(body);
      loaderDispatch({
        isLoading: false,
      });

      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
          theme: "dark",
        });
      } else {
        toast(result?.data?.message, { type: "error", theme: "dark" });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelBaseConvert = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let body = {
        file: digitalImgLink,
      };
      let result = await convertImage(body);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        setSaveBaseStrg(result?.data?.data);
      } else {
        toast(result?.data?.message, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const valueObj = useMemo(
    () => ({
      options,
      setOptions,
      digitalImgLink,
      initialFilterValues,
      loadId,
      setLoadId,
    }),
    [digitalImgLink, options, loadId]
  );

  return (
    <>
      <FilterContext.Provider value={valueObj}>
        <DigitalArtCreatorDetailsRow>
          <div className='body_full_screen'>
            <SiteCard className='full_screen_card'>
              <div className='head'>
                <img src={IconDigitalArtCreator} alt='icon_digital_art' />
                <HeadingThree h3Data={"Digital Art Creator by Dalle.E 2"} />
              </div>
              <div className='list_wrap_main'>
                <DigitalArtFilterList />
                <div className='image_detail'>
                  <div className='btn_group'>
                    <Button
                      tag='button'
                      type='submit'
                      cname='gradient'
                      buttonData={"Save"}
                      onClick={handelSaveFilterImage}
                    />
                    <Button
                      tag='button'
                      type='button'
                      cname='outline'
                      buttonData={"Cancel"}
                      onClick={() => navigate(student.digitalArt)}
                    />
                  </div>
                  <div className='image'>
                    <img
                      src={saveBaseStrg}
                      alt=''
                      style={getImageStyle()}
                      ref={imageRef}
                    />
                  </div>
                </div>
              </div>
            </SiteCard>
          </div>
        </DigitalArtCreatorDetailsRow>
      </FilterContext.Provider>
    </>
  );
}

export default DigitalArtCreatorDetails;
