import React, { useContext } from "react";
import { PostContext } from "../../../components/website/header/MainHeader";

function SearchInput(props) {
  // Sets the search data.
  const { setSearchData } = useContext(PostContext);
  return (
    <input
      type='search'
      name='search'
      placeholder={props?.placeholder}
      disabled={props?.disabled}
      onChange={(e) => {
        setSearchData(e.target.value.trim());
      }}
    />
  );
}

export default SearchInput;
