import React, { useEffect, useState } from "react";

function AdminSetting({
  settingData,
  setSettingData,
  isError,
  setisError,
  setSwitchSetting,
  onSubmit,
  isTypeError,
  setisTypeError,
}) {
  const inputField = [
    {
      name: "instagram_link",
      id: "instagram_link",
      label: "Instagram Link",
      type: "text",
      value: settingData.instagram_link,
    },
    {
      name: "facebook_link",
      id: "facebook_link",
      label: "Facebook Link",
      type: "text",
      value: settingData.facebook_link,
    },
    {
      name: "twitter_link",
      id: "twitter_link",
      label: "Twitter Link",
      type: "text",
      value: settingData.twitter_link,
    },
    {
      name: "linkedin_link",
      id: "linkedin_link",
      label: "LinkedIn Link",
      type: "text",
      value: settingData.linkedin_link,
    },
    {
      name: "country_code",
      id: "country_code",
      label: "Country Code",
      type: "text",
      value: settingData.country_code,
    },
    {
      name: "currency",
      id: "currency",
      label: "Currency",
      type: "text",
      value: settingData.currency,
    },
  ];

  const option = [
    {
      name: "DD/MM/YYYY",
      value: "DD/MM/YYYY",
    },
    {
      name: "MMMM d, YYYY",
      value: "MMMM d, YYYY",
    },
    {
      name: "L",
      value: "L",
    },
    {
      name: "LL",
      value: "LL",
    },
    {
      name: "h:mma",
      value: "h:mma",
    },
  ];

  let date = [
    {
      label: "Select Date",
      value: "",
    },
    {
      label: 1,
      value: 1,
    },
    {
      label: 2,
      value: 2,
    },
    {
      label: 3,
      value: 3,
    },
    {
      label: 4,
      value: 4,
    },
    {
      label: 5,
      value: 5,
    },
    {
      label: 6,
      value: 6,
    },
    {
      label: 7,
      value: 7,
    },
    {
      label: 8,
      value: 8,
    },
    {
      label: 9,
      value: 9,
    },
    {
      label: 10,
      value: 10,
    },
    {
      label: 11,
      value: 11,
    },
    {
      label: 12,
      value: 12,
    },
    {
      label: 13,
      value: 13,
    },
    {
      label: 14,
      value: 14,
    },
    {
      label: 15,
      value: 15,
    },
    {
      label: 16,
      value: 16,
    },
    {
      label: 17,
      value: 17,
    },
    {
      label: 18,
      value: 18,
    },
    {
      label: 19,
      value: 19,
    },
    {
      label: 20,
      value: 20,
    },
    {
      label: 21,
      value: 22,
    },
    {
      label: 23,
      value: 23,
    },
    {
      label: 24,
      value: 24,
    },
    {
      label: 25,
      value: 25,
    },
    {
      label: 26,
      value: 26,
    },
    {
      label: 27,
      value: 27,
    },
    {
      label: 28,
      value: 28,
    },
    {
      label: 29,
      value: 29,
    },
    {
      label: 30,
      value: 30,
    },
    {
      label: 31,
      value: 31,
    },
  ];

  const monthsList = [
    { value: "", label: "Select Month", key: "" },
    { value: "1", label: "JANUARY", key: "01" },
    { value: "2", label: "FEBRUARY", key: "FEBRUARY" },
    { value: "3", label: "MARCH", key: "03" },
    { value: "4", label: "APRIL", key: "04" },
    { value: "5", label: "MAY", key: "05" },
    { value: "6", label: "JUNE", key: "06" },
    { value: "7", label: "JULY", key: "JULY" },
    { value: "8", label: "AUGUST", key: "AUGUST" },
    { value: "9", label: "SEPTEMBER", key: "09" },
    { value: "10", label: "OCTOBER", key: "10" },
    { value: "11", label: "NOVEMBER", key: "11" },
    { value: "12", label: "DECEMBER", key: "12" },
  ];

  const [rangeDates, setRangeDates] = useState([]);

  useEffect(() => {
    setRangeDates([...date]);
  }, []);

  const handelSettingChange = (e) => {
    const { name, value } = e.target;

    setisError((preVal) => {
      return { ...preVal, [name]: false };
    });
    setisTypeError((preVal) => {
      return { ...preVal, [name]: false };
    });
    if (name == "country_code") {
      let testValue = value.slice(1);
      if (
        value == "+" ||
        value == "" ||
        (/^\d+$/.test(testValue) && value.length > 1 && value.length < 5)
      ) {
        setSettingData((preVal) => {
          return { ...preVal, [name]: value };
        });
      }
    } else if (name == "currency") {
      if (
        value == "€" ||
        value == "₡" ||
        value == "£" ||
        value == "₪" ||
        value == "₹" ||
        value == "¥" ||
        value == "₩" ||
        value == "₦" ||
        value == "₱" ||
        value == "zł" ||
        value == "₲" ||
        value == "฿" ||
        value == "₴" ||
        value == "₫" ||
        value == "₭" ||
        value == "$" ||
        value == ""
      ) {
        setSettingData((preVal) => {
          return { ...preVal, [name]: value };
        });
      }
    } else {
      setSettingData((preVal) => {
        return { ...preVal, [name]: value };
      });
    }
  };

  const options = option.filter((val) => val.value !== settingData.date_format);

  const handelDateRange = (e) => {
    let index = e.target.selectedIndex;
    let optionElement = e.target.childNodes[index];
    if (optionElement.getAttribute("test-data") === "FEBRUARY") {
      setRangeDates(date.slice(0, -2));
    } else if (
      optionElement.getAttribute("test-data") === "JULY" ||
      optionElement.getAttribute("test-data") === "AUGUST"
    ) {
      setRangeDates(date);
    } else if (!optionElement.getAttribute("test-data")) {
      setRangeDates([]);
    } else if (parseInt(optionElement.getAttribute("test-data")) % 2 !== 0) {
      setRangeDates(date);
    } else if (parseInt(optionElement.getAttribute("test-data")) % 2 === 0) {
      setRangeDates(date.slice(0, -1));
    } else {
      console.log(date);
    }
  };

  return (
    Object.keys(settingData).length > 0 && (
      <>
        <div>
          <div className='space-y-6 sm:px-6 lg:px-0 lg:col-span-9'>
            <section aria-labelledby='payment-details-heading'>
              <div className='shadow sm:rounded-md sm:overflow-hidden'>
                <div className='bg-white py-6 px-4 sm:p-6'>
                  <div className='space-x-6'>
                    <label
                      className='border-purple-600 border px-5 py-2 rounded-3xl text-white bg-purple-600 cursor-pointer '
                      onClick={() => setSwitchSetting(true)}>
                      General Setting
                    </label>
                    <label
                      onClick={() => setSwitchSetting(false)}
                      className='border-purple-600 border px-5 py-2 rounded-3xl  text-purple-600 cursor-pointer'>
                      Email Setting
                    </label>
                  </div>

                  <div className='mt-6 grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-6'>
                    <div className=''>
                      <label
                        htmlFor='date_format'
                        className='block text-sm font-medium text-gray-700'>
                        Date Format
                      </label>
                      <select
                        name='date_format'
                        id='date_format'
                        onChange={handelSettingChange}
                        className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm bg-white'>
                        <option value={settingData?.date_format}>
                          {settingData?.date_format}
                        </option>
                        {options.map((val, i) => {
                          return (
                            <option key={i} value={val.value}>
                              {val.value}
                            </option>
                          );
                        })}
                      </select>
                      {isError.date_format && (
                        <p className='text-sm text-red-500'>
                          please fill the field
                        </p>
                      )}
                    </div>

                    <div>
                      <label
                        htmlFor='month'
                        className='block text-sm font-medium text-gray-700'>
                        Academic End Month
                      </label>
                      <select
                        name='session_end_month'
                        id='month'
                        onChange={(e) => {
                          handelSettingChange(e);
                          handelDateRange(e);
                        }}
                        className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm bg-white '>
                        {/* <option value=''>Select month</option> */}
                        {monthsList.map((val, i) => {
                          if (settingData?.session_end_month == val.value) {
                            return (
                              <option
                                key={i}
                                value={val.value}
                                test-data={val.key}
                                selected>
                                {val.label}
                              </option>
                            );
                          } else {
                            return (
                              <option
                                key={i}
                                value={val.value}
                                test-data={val.key}>
                                {val.label}
                              </option>
                            );
                          }
                        })}
                      </select>
                      {isError.session_end_month && (
                        <p className='text-sm text-red-500'>
                          please fill the field
                        </p>
                      )}
                    </div>

                    <div>
                      <label
                        htmlFor='month'
                        className='block text-sm font-medium text-gray-700'>
                        Academic End Date
                      </label>
                      <select
                        name='session_end_date'
                        id='session_end_date'
                        disabled={settingData.session_end_month ? false : true}
                        onChange={(e) => {
                          handelSettingChange(e);
                        }}
                        className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm bg-white'>
                        {!settingData?.session_end_month ? (
                          <option value=''>Select Date</option>
                        ) : (
                          rangeDates.map((val, i) => {
                            if (settingData?.session_end_date == val.value) {
                              return (
                                <option key={i} value={val.value} selected>
                                  {val.label}
                                </option>
                              );
                            } else {
                              return (
                                <option key={i} value={val.value}>
                                  {val.label}
                                </option>
                              );
                            }
                          })
                        )}
                      </select>
                      {isError.session_end_date && (
                        <p className='text-sm text-red-500'>
                          please fill the field
                        </p>
                      )}
                    </div>
                    {inputField?.map((val, i) => {
                      return (
                        <div key={i}>
                          <div>
                            <label
                              htmlFor={val.id}
                              className='block text-sm font-medium text-gray-700'>
                              {val.label}
                            </label>
                            <input
                              type={val.type}
                              name={val.name}
                              id={val.id}
                              value={val.value}
                              onChange={handelSettingChange}
                              autoComplete='off'
                              className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm'
                            />
                            {isError[val.name] && (
                              <p className='text-red-500 text-sm'>
                                Please fill the field
                              </p>
                            )}
                            {isTypeError[val.name] && !isError[val.name] && (
                              <p className='text-red-500 text-sm'>
                                Please fill correctly
                              </p>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className='px-4 py-3 bg-gray-50 text-right sm:px-6'>
                  <button
                    type='submit'
                    onClick={onSubmit}
                    className='bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900'>
                    Save
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
      </>
    )
  );
}

export default AdminSetting;
