import React from "react";
import styled from "styled-components";
import { space } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";
import { Container, SiteCard } from "../../../common/website/HOC.jsx";

import Paragraph from "../../../common/website/Paragraph.jsx";
import HeadingTwo from "../../../common/website/HeadingTwo.jsx";

import ProcessOne from "../../../images/website/process_1.png";
import ProcessTwo from "../../../images/website/process_2.png";
import ProcessThree from "../../../images/website/process_3.png";
import LinemoreRadius from "../../../images/website/how_it_works_line_1.svg";
import LinemoreRadius2 from "../../../images/website/how_it_works_line_2.svg";

const HowItWorksRow = styled.section`
  padding: 0 0 ${space.space40};
  h2 {
    margin-bottom: ${space.space24};
  }
  .how_work_col {
    width: 100%;
    height: 100%;
    img {
      overflow: hidden;
      border-radius: ${space.space8};
      width: 100%;
      max-width: 100%;
      object-fit: cover;
    }
    p {
      margin-top: ${space.space16};
      margin-bottom: 0;
      &.noMargin {
        margin: 0;
      }
    }
  }
  .moreRadius {
    height: 100%;
  }
  .moreRadius_center {
    position: relative;
    &::before,
    &::after {
      position: absolute;
      content: "";
    }
    &::before {
      top: 2.625rem;
      left: -9.625rem;
      background: url(${LinemoreRadius}) no-repeat;
      background-size: contain;
    }
    &::after {
      top: 4.5rem;
      right: -5.375rem;
      background: url(${LinemoreRadius2}) no-repeat;
      background-size: contain;
    }
  }
  @media ${device.mobileM} {
    .howitworks_col {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  @media ${device.tablet} {
    padding: 0 0 ${space.space150};
    h2 {
      margin-bottom: ${space.space40};
    }
    .howitworks_col {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .how_work_col {
      p {
        margin-top: ${space.space20};
      }
    }
    .moreRadius_center {
      &::before {
        top: 0;
        bottom: 0;
        margin: auto;
        left: -74px;
        width: 94px;
        height: 65px;
      }
      &::after {
        top: 0;
        bottom: 0;
        margin: auto;
        right: -60px;
        width: 94px;
        height: 42px;
      }
    }
  }
  @media ${device.laptop} {
    .moreRadius_center {
      &::before {
        left: -72px;
      }
      &::after {
        right: -74px;
      }
    }
  }
  @media ${device.laptopL} {
    .moreRadius_center {
      &::before {
        top: 42px;
        bottom: auto;
        left: -156px;
        width: 170px;
        height: 129px;
      }
      &::after {
        top: 92px;
        bottom: auto;
        right: -94px;
        width: 183px;
        height: 72px;
      }
    }
  }
`;

function HowItWorks() {
  return (
    <>
      <HowItWorksRow>
        <Container>
          <HeadingTwo
            cname='white'
            h2Data={
              "YB3 is built on the Blockchain ensuring the yearbook, and its memories are held safe, secure, and FOREVER."
            }
          />
          <div className='grid gap-4 md:grid-cols-3 lg:gap-14 md:gap-8 items-center howitworks_col'>
            <div className='how_work_col'>
              <SiteCard className='moreRadius'>
                <img src={ProcessOne} alt='Process 1' />
                <Paragraph
                  pData={
                    "Students upload their real-time photos and videos and make their lifetime memories, stored securely on the OpenSea marketplace."
                  }></Paragraph>
              </SiteCard>
            </div>
            <div className='how_work_col'>
              <SiteCard className='moreRadius moreRadius_center'>
                <img src={ProcessTwo} alt='Process 2' />
                <Paragraph pData={"Meet Rachel"}></Paragraph>
                <Paragraph
                  cname='noMargin'
                  pData={"Happy Graduating Senior"}></Paragraph>
              </SiteCard>
            </div>
            <div className='how_work_col'>
              <SiteCard className='moreRadius'>
                <img src={ProcessThree} alt='Process 1' />
                <Paragraph
                  pData={
                    "Rachel created her personal NFT. She owns, manages, and controls her identity."
                  }></Paragraph>
              </SiteCard>
            </div>
          </div>
        </Container>
      </HowItWorksRow>
    </>
  );
}

export default HowItWorks;
