import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { space, colors, siteGradient } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";
import { Container, SiteForm } from "../../../common/website/HOC.jsx";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import HeadingTwo from "../../../common/website/HeadingTwo.jsx";
import HeadingFive from "../../../common/website/HeadingFive.jsx";
import Label from "../../../common/website/Label.jsx";
import Button from "../../../common/website/Button.jsx";

import IconMail from "../../../images/website/icon_email_blue.svg";
import IconCall from "../../../images/website/icon_call_blue.svg";
import ContactUsCircle from "../../../images/website/contactus_circle.svg";

import { getCountryList } from "../../../common_api/commonApi";
import { toContact } from "../../../services/auth.services";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import { website } from "../../../linksprovider";
import { loaderContext } from "../../../components/global loader/global-loader-provider";

const LandingContactUsRow = styled.section`
  padding: ${space.space80} 0 ${space.space40};
  position: relative;
  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 10px;
    width: 96px;
    height: 90px;
    background: url(${ContactUsCircle}) no-repeat;
    background-size: contain;
    z-index: 1;
  }
  h2 {
    margin-bottom: ${space.space20};
    text-align: center;
  }
  .contact_list_link {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    grid-gap: 16px;
    margin-bottom: ${space.space30};

    .link {
      display: flex;
      align-items: center;
      justify-content: center;

      h5 {
        font-size: 0.875rem;
        margin: 0;
        transition: 0.4s;
      }

      &:hover {
        .image_box {
          &::before {
            top: 0;
            left: 0;
            transition: 0.4s;
          }
        }
        h5 {
          color: ${colors.primary};
          transition: 0.4s;
        }
      }
    }
  }
  .image_box {
    background: ${colors.lightblue};
    box-shadow: ${siteGradient.boxshadowblue};
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border-radius: 8px;
    min-width: 30px;
    height: 30px;
    margin-right: 14px;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: 0.4s;

    &::before {
      position: absolute;
      content: "";
      top: 6px;
      left: 6px;
      min-width: 30px;
      height: 30px;
      background: #fff;
      opacity: 0.05;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 8px;
    }
    img {
      width: 16px;
    }
  }
  .form_group {
    margin-bottom: 24px;
  }
  .phone-group {
    display: flex;
    align-items: center;

    select {
      width: 66px;
      flex-shrink: 0;
      height: 2.2rem;
    }
    input {
      padding-left: 12px;
      height: 2.2rem;
    }
    span {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 1px;
        height: 23px;
        background: #ffffff80;
        top: -15px;
        display: block;
        left: -1px;
      }
    }
  }
  .btn_group {
    text-align: center;
    padding-top: 0;
  }
  .form_label_group {
    gap: 0;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  @media ${device.mobileM} {
    .form_label_group {
      gap: 1rem;
    }
    .image_box {
      min-width: 40px;
      height: 40px;
      &::before {
        min-width: 40px;
        height: 40px;
      }
      img {
        width: 21px;
      }
    }
  }
  @media ${device.mobileL} {
    .image_box {
      min-width: 52px;
      height: 52px;
      &::before {
        min-width: 52px;
        height: 52px;
      }
    }
    .contact_list_link {
      flex-wrap: nowrap;
      grid-gap: 28px;
      margin-bottom: ${space.space40};
      .link {
        h5 {
          font-size: 1.125rem;
        }
      }
    }
    .btn_group {
      padding-top: 1rem;
    }
  }
  @media ${device.tablet} {
    padding: ${space.space150} 0;
    .form_group {
      margin-bottom: 34px;
    }
    .contact_list_link {
      margin-bottom: ${space.space60};
    }
    h2 {
      margin-bottom: ${space.space30};
    }
    &::before {
      width: 122px;
      height: 124px;
    }
    .form_label_group {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .phone-group {
      input {
        padding-left: 0;
      }
      span {
        position: relative;
        &::before {
          height: 20px;
          left: -6px;
        }
      }
    }
  }
  @media ${device.laptop} {
    h2 {
      margin-bottom: ${space.space40};
    }
    &::before {
      top: 30px;
      width: 158px;
      height: 155px;
    }
    .phone-group {
      span {
        position: relative;
        &::before {
          height: 23px;
          left: -1px;
        }
      }
    }
    .form_label_group {
      gap: 1.5rem;
    }
    .phone-group {
      input {
        padding-left: 12px;
      }
    }
  }
`;

function LandingContactUs() {
  const [countryCode, setCountryCode] = useState([]);
  const [mobileError, setMobileError] = useState(false);
  const [initialValues, setinitialValues] = useState({
    name: "",
    email: "",
    country_code: countryCode[0]?.country_code
      ? countryCode[0]?.country_code
      : +1,
    contact_number: "",
    message: "",
    status: 1,
  });

  const { loaderDispatch } = useContext(loaderContext);
  let navigate = useNavigate();
  let subscribe = true;
  useEffect(() => {
    // window.lintrk("track", { conversion_id: 11700508 });
    if (subscribe) {
      getCountryCodeList();
    }
    return () => {
      subscribe = false;
    };
  }, []);

  const phoneRegExp = /^[0-9]*$/;

  const alphaSpace = /^[A-Za-z\s]*$/;

  const validationSchema = Yup.object({
    name: Yup.string()
      .trim()
      .matches(alphaSpace, "Please enter valid name")
      .required("Please fill the field")
      .max(30, "Maximum 30 characters are allowed"),
    email: Yup.string()
      .trim()
      .email("Invalid email address")
      .required("Please fill the field"),

    country_code: Yup.number().required("Please fill the field"),
    contact_number: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Please fill the field")
      .min(4, "Phone number is not valid")
      .max(12, "Phone number is not valid"),

    message: Yup.string().trim().required("Please fill the field"),
  });

  const onSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let body = {
        name: values?.name?.trim(),
        email: values?.email?.trim(),
        country_code: countryCode[0]?.country_code
          ? countryCode[0]?.country_code
          : +1,
        contact_number: values?.contact_number?.trim(),
        message: values?.message?.trim(),
        status: values?.status,
      };

      let result = await toContact(body);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        // toast("Your request is raised successfully..!!", {
        //   type: "success",
        //   theme: "dark",
        // });
        navigate(website.thankYou);
        resetForm();
      } else {
        toast(result?.data?.message, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.log(error);
    }

    setSubmitting(false);
  };

  const getCountryCodeList = async () => {
    let list = await getCountryList();

    if (list?.length > 0) {
      setCountryCode(list);
    }
  };
  console.log(countryCode);

  const checkAlphaAndSpace = (value) => {
    return /^[A-Za-z\s]*$/.test(value);
  };
  return (
    <>
      <LandingContactUsRow className='contact_us' id='contact'>
        <Container>
          <div className='grid gap-0 md:grid-cols-1 items-center'>
            <HeadingTwo cname='white' h2Data={"Register Now"} />
            <div className='contact_list_link'>
              <a href='mailto:info@yearbook3.com' className='link'>
                <span className='image_box'>
                  <img src={IconMail} alt='IconMail' />
                </span>
                <HeadingFive cname='white' h5Data={"info@yearbook3.com"} />
              </a>
              <a href='tel:847-848-5168' className='link'>
                <span className='image_box'>
                  <img src={IconCall} alt='IconCall' />
                </span>
                <HeadingFive cname='white' h5Data={"847-848-5168"} />
              </a>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              // enableReinitialize
            >
              {({
                isSubmitting,
                /* and other goodies */
              }) => (
                <SiteForm as='div'>
                  <Form>
                    <div className='grid gap-6 md:grid-cols-3 items-center form_label_group'>
                      <div className='form_group'>
                        <Label labelData={"Name*"} />
                        <Field
                          type='text'
                          name='name'
                          // value={initialValues?.name}
                          placeholder='Enter Name'
                          // onChange={handelChange}
                        />
                        <ErrorMessage
                          name='name'
                          component='div'
                          className='text-red-500 text-sm absolute'
                        />
                      </div>

                      <div className='form_group'>
                        <Label labelData={"Email*"} />
                        <Field
                          type='text'
                          name='email'
                          placeholder='Enter Email'
                        />
                        <ErrorMessage
                          name='email'
                          component='div'
                          className='text-red-500 text-sm absolute'
                        />
                      </div>

                      <div className='form_group'>
                        <Label labelData={"Phone*"} />
                        <div className='phone-group'>
                          <Field name='country_code' as='select'>
                            {countryCode?.map((val, i) => {
                              return (
                                <option value={val?.country_code} key={i}>
                                  +{val?.country_code}
                                </option>
                              );
                            })}
                          </Field>
                          <ErrorMessage
                            name='country_code'
                            component='div'
                            className='text-red-500 text-sm absolute'
                          />
                          <span></span>
                          <Field
                            type='text'
                            name='contact_number'
                            placeholder='Enter Phone Number'
                          />
                        </div>
                        <ErrorMessage
                          name='contact_number'
                          component='div'
                          className='text-red-500 text-sm absolute'
                        />
                        {mobileError && (
                          <div className='text-red-500 text-sm'>
                            mobile number must be valid
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='form_group'>
                      <Label labelData={"Message*"} />
                      <Field
                        as='textarea'
                        label='First Name'
                        name='message'
                        rows='3'
                        placeholder='Type message here'
                      />
                      <ErrorMessage
                        name='message'
                        component='div'
                        className='text-red-500 text-sm absolute'
                      />
                    </div>

                    <div className='btn_group'>
                      <Button
                        tag='button'
                        type='submit'
                        cname='gradient'
                        buttonData={"Register Now"}
                        disabled={isSubmitting}
                      />
                    </div>
                  </Form>
                </SiteForm>
              )}
            </Formik>
          </div>
        </Container>
      </LandingContactUsRow>
    </>
  );
}

export default LandingContactUs;
