import React, { Fragment } from "react";
import styled from "styled-components";
import { Transition, Dialog } from "@headlessui/react";
import HeadingFive from "./HeadingFive.jsx";
import { GrClose } from "react-icons/gr";
import { SiteModal } from "./HOC.jsx";
import { device } from "./Devices";
import dateDifference from "date-difference";

import HeadingFour from "./HeadingFour.jsx";
import { space } from "./Variables";

const PostSubmittedRow = styled.div`
  .modal_content {
    text-align: center;
    padding: ${space.space60} ${space.space16};
    img {
      width: 50px;
      height: 50px;
      margin: 0 auto ${space.space20};
    }
    h4 {
      margin: 0;
    }
    .modal_inner {
      max-width: 590px;
    }
  }
  @media ${device.mobileL} {
    .modal_content {
      padding: 112px 130px;
      img {
        width: 82px;
        height: 82px;
      }
    }
  }
  .modal_content_Notification {
    padding: 19px;
    max-height: 500px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 4px;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(172 172 172 / 10%);
      border-radius: 8px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    /* Fire Fox */
    scrollbar-color: rgb(172 172 172 / 10%) transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 4px;
      height: 6px;
      background-color: transparent;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(172 172 172 / 10%);
      border-radius: 8px;
    }
    /* End Fire Fox */
    h4 {
      font-size: 18px;
      font-weight: 400;
    }
  }
`;

function CommonModal({
  header,
  openCommonModal,
  setOpenCommonModal,
  message,
  icon,
  type,
}) {
  const commentTime = (val) => {
    let date = new Date(val?.createdAt);
    let vals = dateDifference(new Date(val?.createdAt), new Date(new Date()), {
      compact: true,
    });
    let vals2 = vals.slice(1);

    if (vals2 === "1d") {
      return `yesterday ${date?.getHours()}:${date?.getMinutes()} `;
    } else if (vals2?.includes("ms")) {
      return `few seconds ago`;
    } else if (vals2?.includes("m")) {
      return `${vals2} ago`;
    } else if (vals2?.includes("s")) {
      return `few seconds ago`;
    } else if (vals2?.includes("h")) {
      return `${vals2} ago`;
    } else {
      return `${date?.getDate()}-${
        date?.getMonth() + 1
      }-${date.getFullYear()} ${date?.getHours()}:${date?.getMinutes()} `;
    }
  };
  return (
    <Transition.Root show={openCommonModal} as={Fragment}>
      <Dialog
        as='div'
        static
        open={openCommonModal}
        onClose={setOpenCommonModal}>
        <PostSubmittedRow className='SetProfilePhoto'>
          <SiteModal>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
              <div className='modal_inner'>
                <div className='modal_boday'>
                  <div className='model_top'>
                    <HeadingFive cname='title' h5Data={header} />
                    <span
                      className='modal_close'
                      onClick={() => {
                        setOpenCommonModal(false);
                      }}>
                      {" "}
                      <GrClose />
                    </span>
                  </div>
                  <div
                    className={
                      type !== "notification"
                        ? "modal_content"
                        : "modal_content_Notification"
                    }>
                    {icon && type !== "notification" && (
                      <>
                        <img src={icon} alt='right icon' />
                        <HeadingFour h4Data={message} />
                      </>
                    )}

                    {icon && type === "notification" && (
                      <div>
                        <div className='flex'>
                          <div className='w-14'>
                            <img src={icon} alt='right icon' />
                          </div>
                          <div className='name '>
                            <p className='text-cyan-400'>
                              {message?.value?.title}
                            </p>
                            <p className='text-[0.75rem] text-[#ACACAC] '>
                              {commentTime(message?.value)}
                            </p>
                          </div>
                        </div>
                        <p className='mt-2'>{message?.message}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </SiteModal>
        </PostSubmittedRow>
      </Dialog>
    </Transition.Root>
  );
}

export default CommonModal;
