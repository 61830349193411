import React, { useState } from "react";

function ReadMore({ children }) {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className='description'>
      {isReadMore ? text?.slice(0, 210) : text}
      <span onClick={toggleReadMore} className='read-or-hide'>
        {text?.length > 210 && (isReadMore ? " See More" : " See Less")}
      </span>
    </p>
  );
}

export default ReadMore;
