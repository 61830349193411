import { useContext, useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  adminPostStatus,
  clubView,
  deletePost,
} from "../../services/auth.services";
import { loaderContext } from "../../components/global loader/global-loader-provider";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import moment from "moment/moment";
import IconMenuDropDown from "../../common/IconMenuDropDown";
import { AiFillLike, AiOutlineLike } from "react-icons/ai";
import { FaComment } from "react-icons/fa";
import AdminDeleteModel from "../../common/AdminDeleteModel";
import AdminPostDetailModal from "./AdminPostDetailModal";
import { COMMENT } from "../../utils/constant";
import AdminClubPostDetails from "./AdminClubPostDetails";
import ActiveInactiveModal from "../../common/ActiveInactiveModal";
import { BiArrowBack } from "react-icons/bi";
import styled from "styled-components";
import MembersList from "../../common/MembersList";

const Main = styled.div`
  background-color: #f3f4f6;
  height: 100vh;
  overflow-y: scroll;
  -ms-overflow-style: transparent; /* IE and Edge */
  &::-webkit-scrollbar {
    background: transparent;
  }
`;

function AdminClubView() {
  const [viewData, setViewData] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openPostModal, setOpenPostModal] = useState(false);
  const [postDetail, setPostDetail] = useState({});
  const [type, setType] = useState("");
  const { loaderState, loaderDispatch } = useContext(loaderContext);
  const [id, setId] = useState("");
  const [openActiveModal, setOpenActiveModal] = useState(false);
  const [isActivePost, setActive] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  const { ids } = useParams();
  let navigate = useNavigate();
  let postref = useRef();

  useEffect(() => {
    const deBounce = setTimeout(() => {
      getClubDetails(1);
    }, 300);
    return () => {
      clearTimeout(deBounce);
    };
  }, [search]);

  const getClubDetails = async (pages, loader = null) => {
    try {
      if (!loader) {
        loaderDispatch({
          isLoading: true,
        });
      }
      let params = {
        id: ids,
        search: search,
        page: pages,
      };
      let result = await clubView(params);
      loaderDispatch({
        isLoading: false,
      });
      // if (search !== "") {
      //   setViewData((preVal) => {
      //     for (const key in preVal) {
      //       if (key == "Posts") {
      //         preVal["Posts"] = [];
      //       }
      //     }
      //     return preVal;
      //   });
      // }
      if (result?.data?.status === StatusCodes.OK) {
        if (result?.data?.data?.Posts?.length > 0 && pages > 1) {
          setViewData((preVal) => {
            return { ...preVal, ...result?.data?.data?.Posts };
          });
        }
        if (pages === 1) {
          setViewData(result?.data?.data);
        }
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
        setViewData({});
      }
    } catch (error) {
      console.error(error);
      setViewData({});
    }
  };

  const adminDeletePost = async () => {
    loaderDispatch({
      isLoading: true,
    });
    try {
      let result = await deletePost(id);

      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
        });
        setOpenDeleteModal(false);
        setViewData((preVal) => {
          for (let i = 0; i < preVal?.Posts?.length; i++) {
            if (preVal?.Posts[i]?.id === id) {
              delete preVal?.Posts[i];
            }
          }
          return preVal;
        });
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
        setOpenDeleteModal(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleStatus = async () => {
    loaderDispatch({
      isLoading: true,
    });

    try {
      let jsonData = {
        status: isActivePost ? "2" : "1",
      };

      let result = await adminPostStatus(id, jsonData);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
        });
        setViewData((preVal) => {
          for (let i = 0; i < preVal?.Posts?.length; i++) {
            if (preVal?.Posts[i]?.id === id) {
              preVal.Posts[i].status = isActivePost ? 2 : 1;
            }
          }
          return preVal;
        });
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
      setOpenActiveModal(false);
      setActive(!isActivePost);
    } catch (error) {
      console.error(error);
    }
  };

  let pages = page;
  const handelScroll = () => {
    if (postref?.current) {
      const { scrollTop, scrollHeight, clientHeight } = postref.current;
      if (scrollTop + clientHeight === scrollHeight) {
        pages++;
        setPage(pages);
        let loader = 1;
        getClubDetails(pages, loader);
      }
    }
  };
  console.log(viewData);
  return (
    <>
      <Main ref={postref} onScroll={handelScroll}>
        <main className="py-4">
          <button
            className=" cursor-pointer w-fit bg-white px-2 py-2 flex rounded-md shadow text-gray-500 hover:text-gray-600 mb-4 ml-6 "
            onClick={() => navigate(-1)}
          >
            <BiArrowBack className="text-xl outline-none " />
            <p>Go Back</p>
          </button>
          {/* Page header */}
          <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
            <div className="flex items-center space-x-5">
              <div className="flex-shrink-0">
                <div className="relative ">
                  {Object.keys(viewData).length > 0 && (
                    <img
                      src={
                        viewData?.club_profile
                          ? viewData?.club_profile
                          : "https://t3.ftcdn.net/jpg/01/09/00/64/360_F_109006426_388PagqielgjFTAMgW59jRaDmPJvSBUL.jpg"
                      }
                      alt=""
                      style={{
                        display: "inline-block",
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                    />
                  )}
                  <span
                    className="absolute inset-0 shadow-inner rounded-full"
                    aria-hidden="true"
                  />
                </div>
              </div>
              <div>
                <h1 className="text-2xl font-bold text-gray-600">
                  {viewData?.clubName}
                </h1>
              </div>
            </div>
          </div>

          {Object.keys(viewData).length > 0 ? (
            <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
              <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                {/* Description list*/}
                <section aria-labelledby="applicant-information-title">
                  <div className="bg-white shadow sm:rounded-lg">
                    {/* club details */}
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Total Members
                          </dt>
                          <dd className="mt-1 text-sm text-gray-600">
                            {viewData?.total_members?.count}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Total Post
                          </dt>
                          <dd className="mt-1 text-sm text-gray-600">
                            {viewData?.total_posts}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Created On
                          </dt>
                          <dd className="mt-1 text-sm text-gray-600">
                            {viewData?.createdAt}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Created By
                          </dt>
                          <dd className="mt-1 text-sm text-gray-600">
                            {viewData?.User?.school_name}
                          </dd>
                        </div>
                      </dl>
                      <div className="sm:col-span-1 mt-8">
                        <dt className="text-sm font-medium text-gray-500">
                          Description
                        </dt>
                        <dd className="mt-1 text-sm text-gray-600">
                          {viewData?.description}
                        </dd>
                      </div>
                      <div className="sm:col-span-1 mt-8">
                        <dt className="text-sm font-medium text-gray-500">
                          Clubtype
                        </dt>
                        <dd className="mt-1 text-sm text-gray-600">
                          {viewData?.clubType}
                        </dd>
                      </div>
                    </div>
                  </div>
                </section>

                {/* post List*/}

                <AdminClubPostDetails
                  viewData={viewData}
                  setOpenDeleteModal={setOpenDeleteModal}
                  setOpenPostModal={setOpenPostModal}
                  setPostDetail={setPostDetail}
                  setType={setType}
                  changePostStatus={setOpenActiveModal}
                  setId={setId}
                  setActive={setActive}
                  setSearch={setSearch}
                />
              </div>

              <MembersList id={ids} heading="List of Members" />
            </div>
          ) : (
            !loaderState.isLoading && (
              <div className="absolute left-1/2">
                <p>Data not found..!!</p>
              </div>
            )
          )}
        </main>
      </Main>
      <AdminDeleteModel
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        deleteData={adminDeletePost}
        header={"Delete Post"}
      />
      <AdminPostDetailModal
        openDeleteModal={openPostModal}
        setOpenDeleteModal={setOpenPostModal}
        postData={postDetail}
        type={type}
      />
      <ActiveInactiveModal
        openActiveModal={openActiveModal}
        setOpenActiveModal={setOpenActiveModal}
        isActivePost={isActivePost}
        handleStatus={handleStatus}
      />
    </>
  );
}

export default AdminClubView;
