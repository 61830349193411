import styled from "styled-components";
import { space, colors, siteGradient } from "./Variables";
import { device } from "./Devices";

import DownArrow from "../../images/website/icon_down_arrow.svg";
import IconCalendar from "../../images/website/icon_calendar.svg";
import IconSearch from "../../images/website/icon_nav_search.svg";

const CustomLink = styled.div`
  a {
    font-family: "Inter", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    border-radius: ${space.space8};
    display: inline-block;
    text-align: center;
    position: relative;
    outline: 0;
    transition: all 0.3s ease-in-out;
    color: ${colors.primary};
    z-index: 1;

    // Gradient
    &.gradient {
      padding: ${space.space8} 1rem;
    }
    &.outline {
      padding: 0.438rem 1rem;
    }
    &.gradient,
    &.outline {
      /* background:${siteGradient.bluePink}; */
      color: ${colors.white};

      &::after,
      &::before {
        background: ${siteGradient.bluePink};
        position: absolute;
        content: "";
        border-radius: ${space.space8};
        z-index: -1;
      }

      &::before {
        background-size: 400%;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        top: -2px;
        left: -2px;
        opacity: 0;
        filter: blur(5px);
        transition: all 0.3s ease-in-out;
        animation: glowing 8s linear infinite;
        /* animation: glowing 20s linear infinite; */
      }

      &::after {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }

      &:hover,
      &:active {
        color: ${colors.primary};
        transition: all 0.3s ease-in-out;

        &::before {
          opacity: 1;
          color: ${colors.primary};
        }

        &::after {
          background: ${colors.body};
        }
      }
    }

    // Outline
    &.outline {
      color: ${colors.primary};
      border: 1px solid ${colors.primary};
      &:hover {
        border-color: transparent;
      }
      &::after {
        background: ${colors.body};
      }
    }

    // Only Text
    &.textOnly {
      padding: 0 ${space.space8};

      &:hover {
        color: ${colors.white};
        text-decoration: underline;
      }
    }
  }

  @media ${device.tablet} {
    a {
      font-size: 1rem;
      line-height: 1.5rem;
      &.gradient {
        padding: ${space.space12} ${space.space24};
      }
      &.outline {
        padding: 11px ${space.space24};
      }
    }
  }

  @keyframes glowing {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
`;

const Container = styled.div`
  max-width: 100%;
  margin: 0 auto;
  padding: 0 ${space.space20};

  @media ${device.laptopL} {
    max-width: 1140px;
    padding: 0;
  }
`;

const Sidebar = styled.div`
  width: 20%;
  height: 100%;
  background-color: #f9f9f9;
`;

const Main = styled.main`
  &.website_main {
    margin-top: ${space.space60};
    @media ${device.mobileM} {
      margin-top: ${space.space80};
    }
    @media ${device.laptop} {
      margin-top: ${space.spaceMain};
    }
  }
`;

const SiteCard = styled.div`
  background: ${colors.darkgrey};
  padding: ${space.space20};
  border-radius: ${space.space8};

  &.moreRadius {
    border-radius: ${space.space16};
  }
`;

const LoginCard = styled.div`
  min-width: 100%;
  margin: 0 auto;
  padding: ${space.space20} 0;
  background: ${colors.cardlightbg};
  box-shadow: ${siteGradient.cardlightboxshadow};
  backdrop-filter: blur(15px);
  border-radius: 20px;
  @media ${device.tablet} {
    min-width: 550px;
  }
  @media ${device.laptop} {
    min-width: 100%;
  }
`;

const LoginCardInner = styled.div`
  max-height: calc(100vh - 80px);
  padding: ${space.space20};
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgb(172 172 172 / 10%);
    border-radius: 8px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  /* Fire Fox */
  scrollbar-color: rgb(172 172 172 / 10%) transparent;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;
    border-radius: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(172 172 172 / 10%);
    border-radius: 8px;
  }
  /* End Fire Fox */

  @media ${device.mobileL} {
    padding: ${space.space40};
  }
  @media ${device.tablet} {
    padding: ${space.space50};
    max-height: calc(100vh - 136px);
  }
  @media ${device.laptopL} {
    padding: 50px 55px;
  }
`;

const SiteForm =
  styled.form &&
  styled.div`
    input,
    select,
    textarea {
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.188rem;
      border-bottom: 1px solid ${colors.inputbordercolor};
      color: ${colors.white};
      background: transparent;
      padding-bottom: 0.75rem;
      width: 100%;
      &::placeholder {
        color: ${colors.placeholdercolor};
      }
      &:focus-visible {
        outline: none;
      }
      &:placeholder-shown {
        text-overflow: ellipsis;
      }
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        text-overflow: ellipsis;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        text-overflow: ellipsis;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        text-overflow: ellipsis;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        text-overflow: ellipsis;
      }
    }
    input[type="search"] {
      border: 1px solid #ffffff33;
      border-radius: ${space.space8};
      padding: ${space.space8} ${space.space14};
      padding-right: ${space.space34};
      background: url(${IconSearch}) no-repeat right 14px center !important;
      background-size: 16px 16px !important;
      &::placeholder {
        font-size: 14px;
        line-height: 17px;
      }
      &::-webkit-search-cancel-button {
        appearance: none;
      }
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
    }
    input:-webkit-autofill {
      -webkit-text-fill-color: ${colors.white} !important;
    }
    select {
      height: 2rem;
      background: url(${DownArrow}) no-repeat 75% 6px !important;
      appearance: none;
      background-size: 10px 10px !important;
      cursor: pointer;
      color: ${colors.darkgrey2};
    }
    @media ${device.mobileM} {
      input[type="search"] {
        padding: ${space.space10} ${space.space16};
        padding-right: ${space.space40};
      }
    }
    @media ${device.laptop} {
      input[type="search"] {
        padding: ${space.space16} ${space.space20};
        background: url(${IconSearch}) no-repeat right 20px center !important;
        background-size: 21px 21px !important;
        padding-right: ${space.space50};
      }
    }
  `;

const CustomDatePicker = styled.div`
  .react-date-picker {
    width: 100%;
  }
  .react-date-picker__wrapper {
    padding-bottom: 0.75rem;
    border: none;
    border-bottom: 1px solid ${colors.inputbordercolor};
    cursor: pointer;
    input {
      padding: 0;
      border: none;
    }
    .react-date-picker__clear-button {
      display: none;
    }
    .react-date-picker__calendar-button {
      background: url(${IconCalendar}) no-repeat center;
      background-size: contain;
      width: 18px;
      height: 18px;
      padding: 0;
      position: absolute;
      right: 0;
      bottom: 18px;
      svg {
        display: none;
      }
    }
  }
  .react-calendar {
    font-family: "Inter", sans-serif;
    background: ${colors.body};
    border-radius: ${space.space8};
    border: none;
    box-shadow: 0px 6px 20px rgb(48 223 242 / 30%);
    padding: ${space.space10};
  }
  .react-calendar__month-view__weekdays__weekday {
    text-transform: capitalize;
    abbr {
      text-decoration: none;
      letter-spacing: 0.03em;
      font-size: 0.75rem;
    }
  }
  .react-calendar .react-calendar__tile {
    border-radius: ${space.space8};
    font-size: 0.75rem;
    &:hover,
    &:focus {
      background-color: ${colors.primary};
      background: ${colors.primary};
      color: ${colors.white};
    }
  }
  .react-calendar__tile--active,
  .react-calendar__tile--active.react-calendar__tile--now {
    background-color: ${colors.primary};
    background: ${colors.primary};
    color: ${colors.white};
  }
  .react-calendar__tile--now {
    background: transparent;
  }
  .react-calendar,
  .react-date-picker__calendar {
    width: 100%;
    z-index: 2;
  }
  .react-date-picker__calendar {
    padding-bottom: 20px;
  }
  .react-calendar__month-view__days__day--neighboringMonth.react-calendar__month-view__days__day--weekend {
    color: ${colors.red};
  }
  .react-calendar__navigation .react-calendar__navigation__arrow {
    border-radius: 8px;
    &:hover,
    &:focus {
      background-color: ${colors.primary};
      background: ${colors.primary};
      color: ${colors.white};
    }
  }
  .react-calendar__navigation .react-calendar__navigation__label {
    border-radius: 8px;
    font-size: 0.75rem;
    &:hover,
    &:focus {
      background-color: ${colors.primary};
      background: ${colors.primary};
      color: ${colors.white};
    }
  }
  @media ${device.mobileM} {
    .react-calendar,
    .react-date-picker__calendar {
      width: 320px;
    }
    .kbEXin .react-calendar__month-view__weekdays__weekday abbr {
      font-size: 0.875rem;
    }
    .react-calendar__navigation .react-calendar__navigation__label {
      font-size: 1rem;
    }
  }
`;

const SubscriptionCard = styled.div`
  background: ${colors.cardlightbg};
  box-shadow: ${siteGradient.cardlightboxshadow};
  backdrop-filter: blur(15px);
  border-radius: ${space.space20};
  padding: ${space.space20};

  @media ${device.mobileL} {
    padding: ${space.space30};
  }
  @media ${device.tablet} {
    padding: ${space.space40} ${space.space34};
  }
`;

const DashboardMain = styled.main`
  display: flex;
  align-items: flex-start;
  max-width: 1920px;
  margin: auto;
  height: 100vh;
  overflow-y: auto;
  padding: 100px ${space.space20} ${space.space30};
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    background: rgb(172 172 172 / 10%);
    border-radius: 8px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  /* Fire Fox */
  scrollbar-color: rgb(172 172 172 / 10%) transparent;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;
    border-radius: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(172 172 172 / 10%);
    border-radius: 8px;
  }
  /* End Fire Fox */

  @media ${device.mobileM} {
    padding: 110px ${space.space20} ${space.space30};
  }
  @media ${device.tablet} {
    padding: ${space.space20};
  }
  @media ${device.laptopL} {
    padding: ${space.space30} ${space.space70};
  }
`;

const DashboardBody = styled.div`
  height: 100%;
  width: 100%;
  max-width: 100%;
  @media ${device.tablet} {
    padding: 0 ${space.space20};
    width: 65%;
  }
  @media ${device.laptop} {
    width: 71%;
  }
  @media ${device.laptopL} {
    width: 76%;
    padding: 0 ${space.space40};
  }
  /* @media ${device.desktop} {
    width: 680px;
    padding: 0;
    margin-left: 2.5rem;
  } */
`;

const DashboardRightBar = styled.div`
  position: sticky;
  top: 0;
  width: 35%;

  @media ${device.laptop} {
    width: 29%;
  }
  @media ${device.laptopL} {
    width: 26%;
  }
`;

const SiteModal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  background: rgb(23 23 23 / 95%);
  z-index: 99;
  .modal_inner {
    margin: auto;
    max-width: 590px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    padding: 0 ${space.space20};
  }
  .model_top {
    position: relative;
    text-align: center;
    margin-bottom: ${space.space16};
    padding: 0 ${space.space16};
    .title {
      position: static;
      display: inline-block;
      margin: 0;
    }
  }
  .modal_boday {
    background: ${colors.body};
    box-shadow: ${siteGradient.modalBoxshadow};
    border-radius: ${space.space16};
    padding: 16px ${space.space16} ${space.space16};
    margin: auto;
    width: 100%;
  }
  .modal_close {
    position: absolute;
    top: -1px;
    right: -8px;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    background: ${colors.lightgrey2};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.4s;
    &:hover {
      background: ${colors.primary};
      transition: all 0.4s;
    }
    svg {
      width: 14px;
      path {
        stroke-width: 4px;
      }
    }
  }
  .modal_btn_group {
    margin-top: ${space.space16};
    .modal_btn {
      width: 100%;
      padding: 0.5rem 1rem;
    }
  }
  @media ${device.mobileM} {
    .modal_inner {
      padding: 0;
    }
    .modal_btn_group {
      margin-top: ${space.space24};
    }
    .modal_boday {
      padding: 18px ${space.space30} ${space.space30};
    }
    .modal_close {
      right: -13px;
    }
  }
  @media ${device.mobileL} {
    .modal_close {
      width: 28px;
      height: 28px;
      svg {
        width: 16px;
      }
    }
  }
  @media ${device.tablet} {
    .modal_btn_group {
      .modal_btn {
        padding: ${space.space12} ${space.space24};
        z-index: 1;
      }
    }
  }
`;

export {
  CustomLink,
  Container,
  Sidebar,
  Main,
  SiteCard,
  SiteForm,
  LoginCard,
  LoginCardInner,
  CustomDatePicker,
  SubscriptionCard,
  DashboardMain,
  DashboardBody,
  DashboardRightBar,
  SiteModal,
};
