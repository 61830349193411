import React, { useState, useEffect, useContext } from "react";
import { ADMIN_USER_DATA, SETTING } from "../../utils/constant";
import { adminGeneralSetting, editSetting } from "../../services/auth.services";
import AdminSetting from "../../components/admin setting/AdminSetting";
import EmailSetting from "../../components/Email Setting/EmailSetting";
import { StatusCodes } from "http-status-codes";
import { AppContext } from "../../App";
import { loaderContext } from "../../components/global loader/global-loader-provider";
import { toast } from "react-toastify";
import { AdminContext } from "../../common/DashboardWrapper";
import validator from "validator";

function Setting() {
  // let { userData } = useContext(AppContext);
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }

  const [switchSetting, setSwitchSetting] = useState(true);
  const [settingData, setSettingData] = useState({});
  const [isError, setisError] = useState({});
  const [isTypeError, setisTypeError] = useState({});
  const { loaderDispatch } = useContext(loaderContext);
  const [id, setId] = useState("");

  const { setAdminActiveTab } = useContext(AdminContext);

  let count = 0;
  useEffect(() => {
    count++;

    if (count > 0) {
      gettingGeneralSettingData();
    }
  }, []);

  useEffect(() => {
    setAdminActiveTab(SETTING);
  }, []);

  const gettingGeneralSettingData = async () => {
    loaderDispatch({
      isLoading: true,
    });
    let result = await adminGeneralSetting();

    loaderDispatch({
      isLoading: false,
    });
    console.log(result);

    for (const key in result?.data?.data) {
      setId(result?.data?.data?.id);
      if (key !== "id") {
        setSettingData((preVal) => {
          return { ...preVal, [key]: result?.data?.data[key] };
        });
      }
    }

    for (const key in result?.data?.data) {
      if (key !== "id") {
        setisTypeError((preVal) => {
          return { ...preVal, [key]: false };
        });
      }
    }
  };

  const onSubmit = async (e) => {
    console.log(settingData);
    for (const key in settingData) {
      if (settingData[key] === "") {
        setisError((preVal) => {
          return { ...preVal, [key]: true };
        });
      }
    }

    let checkEmptyField = Object.values(settingData);
    let test = [];
    for (let i = 0; i < checkEmptyField.length; i++) {
      if (checkEmptyField[i] === "") {
        break;
      } else {
        test.push(true);
      }
    }

    for (const key in settingData) {
      if (key.includes("email")) {
        if (validator.isEmail(settingData[key])) {
          continue;
        } else {
          setisTypeError((preVal) => {
            return { ...preVal, [key]: true };
          });
          test.push("");
        }
      }

      if (key.includes("link")) {
        if (settingData[key].includes("://")) {
          continue;
        } else {
          setisTypeError((preVal) => {
            return { ...preVal, [key]: true };
          });
          test.push("");
        }
      }

      if (key.includes("mail_password")) {
        if (settingData[key].length > 3 && settingData[key].length < 9) {
          continue;
        } else {
          test.push("");
        }
      }
    }
    console.log(checkEmptyField);
    if (test.length === checkEmptyField.length) {
      loaderDispatch({
        isLoading: true,
      });
      console.log(settingData, "settingData");
      let result = await editSetting(settingData, id);
      console.log(result);

      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",

          onOpen: () => gettingGeneralSettingData(),
        });
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    }
  };

  return (
    <div>
      {switchSetting ? (
        <AdminSetting
          settingData={settingData}
          setSettingData={setSettingData}
          isError={isError}
          setisError={setisError}
          setSwitchSetting={setSwitchSetting}
          onSubmit={onSubmit}
          isTypeError={isTypeError}
          setisTypeError={setisTypeError}
        />
      ) : (
        <EmailSetting
          settingData={settingData}
          setSettingData={setSettingData}
          isError={isError}
          setisError={setisError}
          setSwitchSetting={setSwitchSetting}
          onSubmit={onSubmit}
          isTypeError={isTypeError}
          setisTypeError={setisTypeError}
        />
      )}
    </div>
  );
}

export default Setting;
