import React, { useContext, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { GrClose } from "react-icons/gr";
import { useEffect } from "react";
import {
  adminPostStatus,
  deletePost,
  getPostDetails,
  updatePostStatus,
} from "../../../services/auth.services";
import { StatusCodes } from "http-status-codes";
import moment from "moment";
import { toast } from "react-toastify";
import { school } from "../../../linksprovider";
import { loaderContext } from "../../global loader/global-loader-provider";
import { SchoolContext } from "../../../common/SchoolDashboardWrapper";
import { POSTS } from "../../../utils/constant";

export default function PostViewDetails() {
  const [isActivePost, setActive] = useState(false);
  const [openActiveModal, setOpenActiveModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { id } = useParams();
  const [postDetails, setPostDetails] = useState();
  const navigate = useNavigate();
  const [comments, setComments] = useState([]);

  const { loaderDispatch } = useContext(loaderContext);

  useEffect(() => {
    viewPostDetails();
  }, [isActivePost]);

  const viewPostDetails = async () => {
    loaderDispatch({
      isLoading: true,
    });

    let result = await getPostDetails(id);

    loaderDispatch({
      isLoading: false,
    });
    if (result?.data) {
      setActive(result?.data?.data.status === 1 ? true : false);
      setPostDetails(result?.data?.data);
      setComments(result?.data?.data?.Comments);
    }
  };

  const handleDelete = async () => {
    loaderDispatch({
      isLoading: true,
    });
    let result = await deletePost(id);
    loaderDispatch({
      isLoading: false,
    });
    if (result?.status === StatusCodes.OK) {
      toast(result?.message, {
        type: "success",
      });
      navigate(school.postDetails);
    } else {
      toast(result?.message, {
        type: "error",
      });
    }
  };

  const handleStatus = async () => {
    loaderDispatch({
      isLoading: true,
    });

    try {
      let jsonData = {
        status: isActivePost ? "2" : "1",
      };

      let result = await adminPostStatus(id, jsonData);

      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
        });
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }

      setActive(!isActivePost);

      loaderDispatch({
        isLoading: false,
      });

      setOpenActiveModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='p-6'>
      <div className='px-2 py-2'>
        {/* {postDetails ? } */}

        <div className='flex'>
          <img
            src={
              postDetails?.profile_picture
                ? postDetails?.profile_picture
                : "https://t3.ftcdn.net/jpg/01/09/00/64/360_F_109006426_388PagqielgjFTAMgW59jRaDmPJvSBUL.jpg"
            }
            alt=''
            style={{
              display: "inline-block",
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />

          <div>
            <Link to={`/school/view-student/${postDetails?.userId}`}>
              <div className='font-bold ml-2'>
                {postDetails?.student_name?.charAt(0).toUpperCase() +
                  postDetails?.student_name?.slice(1)}
              </div>
            </Link>
            <div className='text-sm mb-2 ml-2'>
              {" "}
              {moment(new Date(postDetails?.createdAt)).format(
                "d MMMM YYYY, hh:mm A"
              )}
            </div>
          </div>

          <div className='ml-auto mr-0'>
            {postDetails?.status === 1 ? (
              <button
                onClick={() => setOpenActiveModal(true)}
                className='mr-1 h-8 bg-neutral-300 text-black px-4 text-sm transition-colors duration-150 rounded cursor-pointer focus:shadow-outline'>
                Inactive
              </button>
            ) : (
              <button
                onClick={() => setOpenActiveModal(true)}
                className='mr-1 h-8 bg-green-500 text-white px-4 text-sm  transition-colors duration-150 rounded cursor-pointer focus:shadow-outline'>
                Active
              </button>
            )}

            <button
              onClick={() => setOpenDeleteModal(true)}
              className='h-8 text-white px-4 text-sm transition-colors duration-150 rounded cursor-pointer focus:shadow-outline bg-red-600	'>
              Delete Post
            </button>
          </div>
        </div>
        <div className='flex mt-3'>
          <div
            style={{
              height: "400px",
              display: "block",
              width: "50%",
            }}>
            {postDetails?.postType === 1 ? (
              <img
                className='w-full'
                src={postDetails?.postName}
                alt='Sunset in the mountains'
              />
            ) : (
              <video
                controls
                width='100%'
                height='400'
                title={postDetails?.postName}
                src={postDetails?.postName}
                type='video/mp4'></video>
            )}
          </div>
          <div
            style={{
              height: "400px",
              display: "block",
              width: "50%",
              overflowY: "scroll",
            }}
            className='text-center ml-2'>
            <div className='text-left mb-2 font-bold '>
              {comments.length} Comments
            </div>
            {comments.map((item, key) => {
              return (
                <div key={key} className='flex mb-3'>
                  <Link to={`/school/view-student/${item?.user_id}`}>
                    <img
                      src={
                        item.user?.profile_picture
                          ? item.user?.profile_picture
                          : "https://t3.ftcdn.net/jpg/01/09/00/64/360_F_109006426_388PagqielgjFTAMgW59jRaDmPJvSBUL.jpg"
                      }
                      alt=''
                      style={{
                        display: "inline-block",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                    />
                  </Link>
                  <div className='ml-2 text-left'>
                    <Link to={`/school/view-student/${item?.user_id}`}>
                      <div className='font-bold'>{item.user?.student_name}</div>
                    </Link>

                    <div className='text-sm mb-2 ml-2'>
                      {moment(new Date(item.user.commented_on)).format(
                        "d MMMM YYYY, hh:mm A"
                      )}
                    </div>
                    <div className='text-left mb-2 ml-2 '>{item.comment}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <Transition.Root show={openDeleteModal} as={Fragment}>
        <Dialog
          as='div'
          static
          className='fixed z-10 inset-0 overflow-y-auto'
          open={openDeleteModal}
          onClose={setOpenDeleteModal}>
          <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'>
              <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='hidden sm:inline-block sm:align-middle sm:h-screen'
              aria-hidden='true'>
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
              <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
                <div>
                  <div className='mb-6 row block text-lg font-medium text-gray-700'>
                    <span>Delete post</span>
                    <span className='float-right cursor-pointer'>
                      {" "}
                      <GrClose onClick={() => setOpenDeleteModal(false)} />
                    </span>
                  </div>

                  <div className='mb-6 row block text-sm font-medium text-gray-700'>
                    Are you sure want to delete this post ?
                  </div>
                  <div className='mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense'>
                    <button
                      type='button'
                      className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:col-start-2 sm:text-sm'
                      onClick={() => handleDelete(false)}>
                      Delete
                    </button>
                    <button
                      type='button'
                      className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:col-start-1 sm:text-sm'
                      onClick={() => setOpenDeleteModal(false)}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={openActiveModal} as={Fragment}>
        <Dialog
          as='div'
          static
          className='fixed z-10 inset-0 overflow-y-auto'
          open={openActiveModal}
          onClose={setOpenActiveModal}>
          <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'>
              <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='hidden sm:inline-block sm:align-middle sm:h-screen'
              aria-hidden='true'>
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
              <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
                <div>
                  <div className='mb-6 row block text-lg font-medium text-gray-700'>
                    <span>Inactive post</span>
                    <span className='float-right cursor-pointer'>
                      {" "}
                      <GrClose onClick={() => setOpenActiveModal(false)} />
                    </span>
                  </div>

                  {isActivePost ? (
                    <div className='mb-6 row block text-sm font-medium text-gray-700'>
                      Are you sure want to Inactive this post ?
                    </div>
                  ) : (
                    <div className='mb-6 row block text-sm font-medium text-gray-700'>
                      Are you sure want to active this post ?
                    </div>
                  )}

                  <div className='mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense'>
                    {isActivePost ? (
                      <button
                        type='button'
                        className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:col-start-2 sm:text-sm'
                        onClick={() => handleStatus(false)}>
                        Inactive
                      </button>
                    ) : (
                      <button
                        type='button'
                        className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white hover:bg-green-700 focus:outline-none sm:col-start-2 sm:text-sm'
                        onClick={() => handleStatus(false)}>
                        Active
                      </button>
                    )}

                    <button
                      type='button'
                      className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:col-start-1 sm:text-sm'
                      onClick={() => setOpenActiveModal(false)}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
