import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { SchoolContext } from "../../common/SchoolDashboardWrapper";
import {
  ADMIN_USER_DATA,
  CLUB,
  DELETE,
  EDIT,
  SCHOOL_MOMENTS,
  SCHOOL_SPORTS_CLUB,
  UPDATE,
  VIEW,
  WEBSITE_SCHOOL_MOMENTS,
  WEBSITE_Profile_OPTION,
  WEB_LINKS,
} from "../../utils/constant";
import SearchFilter from "../../components/search_filter/SearchFilter";
import ListLayout from "../../components/shared/listLayout.jsx";
import SelectFilter from "../../components/school_select_filter/SelectFilter";
import ReactPaginate from "react-paginate";
import { loaderContext } from "../../components/global loader/global-loader-provider";

import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";

import AdminDeleteModel from "../../common/AdminDeleteModel";
import CreateWebLinkModals from "../../components/schools/create_weblink/CreateWebLinkModals";
import validator from "validator";

import {
  clubListing,
  createWebLink,
  editWebLink,
  webLinkDelete,
  webLinkListing,
  webLinkTypeListing,
} from "../../services/auth.services";
import WebLinkView from "../../components/schools/create_weblink/WebLinkView";

function SchoolWebLinksManagement() {
  const [searchItem, setSearchItem] = useState("");
  const [itemOffset, setItemOffset] = useState(1);
  const [sortby, setSortby] = useState("");
  const [orderby, setOrderby] = useState(true);
  const [currentItems, setCurrentItems] = useState(null);
  const [data, setData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [dataCount, setDataCount] = useState(0);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [items, setItems] = useState("");
  console.log("items",items);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [submit, setSubmit] = useState({});
  const [multiSelectValue, setMultiSelectValue] = useState();
  const [inputData, setInputData] = useState({
    title: "",
    link: "",
    type: "",
    id: [],
  });
  const [errorMessage, setErrorMessage] = useState({
    title: "",
    link: "",
  });

  const [channelOption, setChannelOption] = useState([
    {
      label: "Select Channel",
      value: "",
      selected: false,
    },
    {
      label: WEBSITE_SCHOOL_MOMENTS,
      value: 1,
      selected: false,
    },
    {
      label: CLUB,
      value: 2,
      selected: false,
    },
    {
      label: SCHOOL_SPORTS_CLUB,
      value: 3,
      selected: false,
    },
    {
      label: WEBSITE_Profile_OPTION,
      value: 4,
      selected: false,
    }
  ]);

  const [channelOptionValue, setChannelValue] = useState([
    {
      label: "all",
      value: "all",
      selected: false,
    },
  ]);

  const { setAdminActiveTab } = useContext(SchoolContext);
  const { loaderDispatch } = useContext(loaderContext);
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));

  const tableHeader = [
    {
      key: "S.No.",
      value: "key",
      clickable: false,
      sorting: false,
    },
    {
      key: "description",
      value: "description",

      clickable: false,
      sorting: true,
    },
    { key: "link", value: "postName", clickable: false, sorting: true },

    { key: "status", value: "status", clickable: false, sorting: true },

    {
      key: "options",
      value: "options",
    },
  ];

  const itemsOnPage = [
    { label: 10, value: "10" },
    { label: 20, value: "20" },
    { label: 50, value: "50" },
    { label: 70, value: "70" },
    { label: 100, value: "100" },
  ];

  const inputArray = [
    {
      label: "Description",
      type: "text",

      placeholder: "Enter description",
      name: "title",
      tag: "textarea",
    },
    {
      label: `Link`,
      type: "text",
      placeholder: `Enter link`,
      name: "link",
      tag: "input",
    },
  ];

  useEffect(() => {
    if (isEdit) {
      handelSelectedChannelOption(handelType(items));
      handelPreSelectChannelValue(handelType(items));
    }
  }, [isEdit]);

  useLayoutEffect(() => {
    setAdminActiveTab(WEB_LINKS);
  }, []);
  useEffect(() => {
    const debounce = setTimeout(() => {
      handelWebLinkList();
    }, 300);
    return () => clearTimeout(debounce);
  }, [searchItem, sortby, orderby, itemOffset, itemsPerPage]);

  useEffect(() => {
    setCurrentItems(data);
    setPageCount(dataCount);
  }, [data, dataCount, itemsPerPage]);

  useEffect(() => {
    if (!openCreateModal) {
      setSubmit({});
      setInputData({
        title: "",
        link: "",
        type: "",
        id: [],
      });
      setErrorMessage({
        title: "",
        link: "",
      });
      setIsEdit(false);
      setMultiSelectValue(null);
      setChannelOption([
        {
          label: "Select Channel",
          value: "",
          selected: false,
        },
        {
          label: WEBSITE_SCHOOL_MOMENTS,
          value: 1,
          selected: false,
        },
        {
          label: CLUB,
          value: 2,
          selected: false,
        },
        {
          label: SCHOOL_SPORTS_CLUB,
          value: 3,
          selected: false,
        },
        {
          label: WEBSITE_Profile_OPTION,
          value: 4,
          selected: false,
        }
      ]);
    }
  }, [openCreateModal]);

  const handleOptions = (optionValue, item) => {
    console.log("fsdfsd",item)
    switch (optionValue) {
      case VIEW:
        setOpenViewModal(true);
        setItems(item);
        break;
      case UPDATE:
        setIsEdit(true);
        setOpenCreateModal(true);
        setItems(item);
        setInputData({
          title: item?.description,
          link: item?.postName,
          status: item?.status2,
          type: handelType(item),
          id: handelIdValue(item),
        });

        break;

      case DELETE:
        setItems(item?.id);
        setOpenDeleteModal(true);
        break;

      default:
        break;
    }
  };

  const handleSorting = (data, order) => {
    setSortby(data);
    setOrderby(order);
  };

  const handlePageClick = (event) => {
    setItemOffset(event.selected + 1);
  };

  const handelWebLinkList = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let order = orderby ? "desc" : "asc";
      let params = {
        id: userData?.id,
        search: searchItem,
        page: itemOffset,
        sort_by: sortby,
        orderBy: order,
        limit: itemsPerPage,
      };

      let result = await webLinkListing(params);
      loaderDispatch({
        isLoading: false,
      });
      
      if (result?.data?.status === StatusCodes.OK) {
        const data = result?.data?.data?.webLinkListing?.rows;
        const count = result?.data?.data?.totalPage;

        setData(data);
        setDataCount(count);
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  //to get and set name of create web link
  const handelCreateWebLinks = (e) => {
    setSubmit(false);

    if (e?.target) {
      const { name, value } = e?.target;
      if (value?.toString().trim().length > 0 || value.toString() === "") {
        console.log("single");
        setErrorMessage((preVal) => {
          return { ...preVal, [name]: "" };
        });
        setSubmit((preVal) => {
          return { ...preVal, [name]: false };
        });
        setInputData((preVal) => {
          if (name === "id") {
            setMultiSelectValue(null);
            return { ...preVal, [name]: [parseInt(value)] };
          } else {
            return { ...preVal, [name]: value };
          }
        });
      }
    } else if (Array?.isArray(e)) {
      setMultiSelectValue(e);
      if (handelAllCheck(e)) {
        setInputData((preVal) => {
          preVal.id = [];
          for (let index = 0; index < channelOptionValue?.length; index++) {
            console.log(
              "channelOptionValue[index]?.id",
              channelOptionValue[index]
            );
            if (!isNaN(parseInt(channelOptionValue[index]?.value))) {
              preVal.id = [...preVal?.id, channelOptionValue[index]?.value];
            }
          }
          return {
            ...preVal,
          };
        });
      } else {
        setInputData((preVal) => {
          let arr = [];
          for (let index = 0; index < e?.length; index++) {
            arr.push(parseInt(e[index]?.value));
          }
          return { ...preVal, id: arr };
        });
      }
    }
  };

  const handelCreateSelect = async (e) => {
    
    const { name, value } = e?.target || {};
    console.log("name", name, value);
    setSubmit((preVal) => {
      return { ...preVal, [name]: false, id: false };
    });

    setInputData((preVal) => {
      return { ...preVal, [name]: value };
    });

    setChannelValue((preVal) => {
      for (let index = 0; index < preVal?.length; index++) {
        if (index === 0) {
          preVal[0].selected = true;
        } else {
          preVal[index].selected = false;
        }
      }
    });

    try {
      loaderDispatch({
        isLoading: true,
      });
      let body = {
        school_id: userData?.id,
      };

      let result = await webLinkTypeListing(value, body);
      loaderDispatch({
        isLoading: false,
      });
      if (isEdit) {
        setChannelValue([
          {
            label: "Select channel value",
            value: "",
            selected: false,
          },
        ]);
        if (value == 1) {
          setInputData((preVal) => {
            return { ...preVal, id: [] };
          });
        }
      } else {
        setChannelValue([
          {
            label: "all",
            value: "all",
            selected: false,
          },
        ]);
      }
      setMultiSelectValue(null);
      if (result?.data?.status === StatusCodes.OK) {
        if (isEdit) {
          if (value == 2) {
            let data = result?.data?.data;
            for (let i = 0; i < data?.length; i++) {
              setChannelValue((preVal) => {
                if (data[i]?.id === items?.Club?.id) {
                  setInputData((preValInput) => {
                    return { ...preValInput, id: [items?.Club?.id] };
                  });

                  return [
                    ...preVal,
                    {
                      label: data[i]?.clubName,
                      value: data[i]?.id,
                      selected: true,
                    },
                  ];
                } else {
                  return [
                    ...preVal,
                    {
                      label: data[i]?.clubName,
                      value: data[i]?.id,
                      selected: false,
                    },
                  ];
                }
              });
            }
          } else if (value == 3) {
            let data = result?.data?.data;

            for (let i = 0; i < data?.length; i++) {
              setChannelValue((preVal) => {
                if (data[i]?.id === items?.Sport?.id) {
                  setInputData((preValInput) => {
                    return { ...preValInput, id: [items?.Sport?.id] };
                  });
                  return [
                    ...preVal,
                    {
                      label: data[i]?.sportName,
                      value: data[i]?.id,
                      selected: true,
                    },
                  ];
                } else {
                  return [
                    ...preVal,
                    {
                      label: data[i]?.sportName,
                      value: data[i]?.id,
                      selected: false,
                    },
                  ];
                }
              });
            }
          }
        } else {
          if (value == "2") {
            let data = result?.data?.data;
            for (let i = 0; i < data?.length; i++) {
              setChannelValue((preVal) => {
                return [
                  ...preVal,
                  {
                    label: data[i]?.clubName,
                    value: data[i]?.id,
                    selected: false,
                  },
                ];
              });
            }
          } else if (value == "3") {
            let data = result?.data?.data;
            for (let i = 0; i < data?.length; i++) {
              setChannelValue((preVal) => {
                return [
                  ...preVal,
                  {
                    label: data[i]?.sportName,
                    value: data[i]?.id,
                    selected: false,
                  },
                ];
              });
            }
          }
        }
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelDelete = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });

      let result = await webLinkDelete(items);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
        });
        setOpenDeleteModal(false);
        handelWebLinkList();
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelCreate = async () => {
    try {
      for (const key in inputData) {
        if (
          Array.isArray(inputData[key]) &&
          parseInt(inputData?.type) !== 1 && parseInt(inputData?.type) !== 4 &&
          inputData?.type !== ""
        ) {
         
          if (inputData[key].length === 0) {
            setSubmit((preVal) => {
              return { ...preVal, [key]: true };
            });
            setErrorMessage((preVal) => {
              return { ...preVal, [key]: "Please fill this field" };
            });
          }
        } else {
          
          if (parseInt(inputData?.type) === 1 || parseInt(inputData?.type) === 4 && key === "id") {
           
            continue;
          } else if (inputData[key] === "") {
            
            setSubmit((preVal) => {
              return { ...preVal, [key]: true };
            });
            setErrorMessage((preVal) => {
              return { ...preVal, [key]: "Please fill this field" };
            });
          }
        }
      }
      if (inputData?.link !== "") {
        if (
          !inputData?.link?.match(
            "((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)",
            "Please enter valid URL"
          )
        ) {
          setSubmit((preVal) => {
            return { ...preVal, link: true };
          });
          setErrorMessage((preVal) => {
            return { ...preVal, link: "Please fill valid link" };
          });
          return;
        }
        let count = 0;
        for (let index = 0; index < inputData?.link?.length; index++) {
          if (inputData?.link[index] === ":") {
            count++;
          }
        }
        if (count > 1) {
          setSubmit((preVal) => {
            return { ...preVal, link: true };
          });
          setErrorMessage((preVal) => {
            return { ...preVal, link: "Please fill valid link" };
          });
          return;
        }
      }
      if (parseInt(inputData?.type) !== 1 && parseInt(inputData?.type) !== 4 && inputData?.id?.length === 0) {
        return;
      }
      // console.log("inputData", inputData);
      if (
        inputData?.link !== "" &&
        inputData?.title !== "" &&
        inputData?.type !== ""
      ) {
        loaderDispatch({
          isLoading: true,
        });
        let body = {
          postName: inputData?.link,
          description: inputData?.title,
          status: 1,
          postType: 3,
          // type: parseInt(inputData?.type),
          user_id: userData?.id,
          id: inputData?.id?.length > 0 ? inputData?.id : [],
        };

        let result;

        if (isEdit) {
          body.status = inputData?.status;
          result = await editWebLink(
            items?.id,
            parseInt(inputData?.type),
            body
          );
        } else {
          result = await createWebLink(parseInt(inputData?.type), body);
        }

        loaderDispatch({
          isLoading: false,
        });

        if (result?.data?.status === StatusCodes.OK) {
          toast(result?.data?.message, {
            type: "success",
          });
          handelWebLinkList();
          setOpenCreateModal(false);
        } else {
          toast(result?.data?.message, {
            type: "error",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handelType = (item) => {
    if (item?.is_school_moment) {
      return 1;
    } else if (item?.Club) {
      return 2;
    } else if (item?.Sport) {
      return 3;
    } else if (item?.is_profile_web_link) {
      return 4;
    }
  };

  const handelIdValue = (item) => {
    if (item?.is_school_moment) {
      return [];
    } else if (item?.Club) {
      return [item?.Club?.id];
    } else if (item?.Sport) {
      return [item?.Sport?.id];
    } else if (item?.is_profile_web_link) {
      return [];
    }

  };

  const handelSelectedChannelOption = (value) => {
    setChannelOption((preVal) => {
      for (let index = 0; index < preVal.length; index++) {
        console.log("gffdgfd",value,preVal[index]?.value)
        if (value === preVal[index]?.value) {
          preVal[index].selected = true;
        } else {
          preVal[index].selected = false;
        }
      }
      return [...preVal];
    });
  };

  const handelAllCheck = (valueArr) => {
    let val = false;
    for (let index = 0; index < valueArr?.length; index++) {
      if (valueArr[index]?.value === "all") {
        setMultiSelectValue([valueArr[index]]);
        val = true;
      }
    }
    return val;
  };

  const handelPreSelectChannelValue = (value) => {
    let body = {
      target: {
        name: "type",
        value: value,
      },
    };
    handelCreateSelect(body);
  };

  console.log("inputData", inputData);

  return (
    <>
      <div className='p-2 grid grid-cols-2 gap-2 mt-1'>
        <div className=' flex justify-center'>
          <SearchFilter
            setSearchItem={setSearchItem}
            searchItem={searchItem}
            setPage={setItemOffset}
            cname={
              " block w-full border border-gray-300 rounded-md shadow py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
            }
          />
        </div>
        <div className='flex justify-end '>
          <button
            className='px-4 py-2 bg-indigo-600 text-white hover:bg-indigo-700 rounded shadow-md'
            onClick={() => {
              setOpenCreateModal(true);
            }}>
            Create
          </button>
        </div>
      </div>
      <ListLayout
        handleOptions={handleOptions}
        tableHeader={tableHeader}
        handleSorting={handleSorting}
        currentItems={currentItems}
      />
      <div className='flex justify-between'>
        {data?.length > 0 && (
          <>
            <div
              className='block lg:inline-block lg:mt-0'
              style={{ marginTop: "6px" }}>
              <label htmlFor='item' className='ml-5'>
                Record Per Page:
              </label>
              <SelectFilter
                options={itemsOnPage}
                selectCls={"ml-3 bg-white border p-2 rounded"}
                name={"item"}
                id={"item"}
                onChange={(e) => {
                  setItemsPerPage(parseInt(e.target.value));
                }}
              />
            </div>

            <div className='mt-2 mb-10'>
              <ReactPaginate
                breakLabel='...'
                nextLabel='Next >'
                onPageChange={handlePageClick}
                pageCount={pageCount}
                previousLabel='< Previous'
                renderOnZeroPageCount={null}
                containerClassName={"pagination"}
                pageClassName={"pageClass"}
                forcePage={itemOffset - 1}
              />
            </div>
          </>
        )}
      </div>
      {data?.length === 0 && (
        <div className='flex flex-row absolute left-1/2 top-1/2'>
          <p>Data not found..!!</p>
        </div>
      )}
      {/* create modal starts */}

      <CreateWebLinkModals
        openCreateModal={openCreateModal}
        setOpenCreateModal={setOpenCreateModal}
        isFormSubmit={submit}
        inputArray={inputArray}
        isEdit={isEdit}
        inputData={inputData}
        setInputData={setInputData}
        handelCreateWebLinks={handelCreateWebLinks}
        errorMessage={errorMessage}
        handelCreate={handelCreate}
        channelOption={channelOption}
        channelOptionValue={channelOptionValue}
        handelCreateSelect={handelCreateSelect}
        multiSelectValue={multiSelectValue}
      />

      {/* create modal ends */}

      <WebLinkView
        openViewModal={openViewModal}
        setOpenViewModal={setOpenViewModal}
        items={items}
      />

      <AdminDeleteModel
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        header={"Delete Label"}
        deleteData={handelDelete}
      />
    </>
  );
}

export default SchoolWebLinksManagement;
 
