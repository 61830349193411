import React from "react";

function ActiveStatus({ message }) {
  return (
    <span className='text-sm inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-green-500 text-white rounded'>
      {message}
    </span>
  );
}

export default ActiveStatus;
