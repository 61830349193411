import React, { useContext, useEffect, useState } from "react";
import { AdminContext } from "../common/DashboardWrapper";
import ListLayout from "./shared/listLayout";
import { ADMIN_USER_DATA, NFT, REPORTS } from "../utils/constant";
import DateFilter from "./date_filter/DateFilter.jsx";
import SearchFilter from "./search_filter/SearchFilter.jsx";
import DownloadButton from "./download_button/DownloadButton";
import ReactPaginate from "react-paginate";
import { StatusCodes } from "http-status-codes";
import {
  adminStudentListing,
  downloadCSV,
  getSchoolList,
} from "../services/auth.services";
import { loaderContext } from "./global loader/global-loader-provider";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useNavigate } from "react-router-dom";
import SelectFilter from "./school_select_filter/SelectFilter";
import { toast } from "react-toastify";
import CustomInput from "../common/CustomInput";

function AdminReport() {
  const [sortby, setSortby] = useState("");
  const [orderby, setOrderby] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [dataCount, setDataCount] = useState(0);

  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [data, setData] = useState([]);
  const [itemOffset, setItemOffset] = useState(1);
  const [sendingDate, setSendingDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [school_id, setSchool_id] = useState("");

  const [searchItem, setSearchItem] = useState("");
  const [open, setOpen] = useState(false);
  const [schoolOptions, setSchoolOptions] = useState([
    {
      value: "",
      label: "Select School",
      selected: false,
    },
  ]);
  const [dates, setDates] = useState({
    startDate: "",
    endDate: "",
  });

  const [fileLink, setFileLink] = useState(null);

  const tableHeader = [
    {
      key: "S.No.",
      value: "key",
      clickable: false,
      sorting: false,
    },
    {
      key: "Student Name",
      value: "student_name",
      clickable: false,
      sorting: true,
    },
    { key: "Email address", value: "email", clickable: false, sorting: true },
    {
      key: "School Name",
      value: "school_name",
      clickable: false,
      sorting: true,
    },
    {
      key: "Academic Year",
      value: "academic_year",
      clickable: false,
      sorting: true,
    },
    {
      key: "metamask address",
      value: "metamask_id",
      clickable: false,
      sorting: true,
    },
    {
      key: "Current plan",
      value: "Subscription_name",
      clickable: false,
      sorting: false,
    },

    { key: "status", value: "status", clickable: false, sorting: true },
    {
      key: "Total plan list",
      value: "total_product",
      clickable: true,
      sorting: false,
    },
    {
      key: "User Type",
      value: "user_type",
      clickable: false,
      sorting: false,
    },
    {
      key: "total revenue",
      value: "total_revenue",
      clickable: false,
      sorting: false,
    },
  ];

  const itemsOnPage = [
    { label: 10, value: "10" },
    { label: 20, value: "20" },
    { label: 50, value: "50" },
    { label: 70, value: "70" },
    { label: 100, value: "100" },
  ];

  const { setAdminActiveTab } = useContext(AdminContext);
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }

  const { loaderDispatch } = useContext(loaderContext);
  let navigate = useNavigate();

  useEffect(() => {
    setAdminActiveTab(REPORTS);
  }, []);

  useEffect(() => {
    const debunce = setTimeout(() => {
      handleSchoolData();
      handleStudentData();
    }, 300);

    return () => {
      clearTimeout(debunce);
    };
  }, [
    searchItem,
    sortby,
    orderby,
    itemOffset,
    itemsPerPage,
    sendingDate,
    school_id,
  ]);

  useEffect(() => {
    setCurrentItems(data);
    setPageCount(dataCount);
  }, [data, dataCount, itemsPerPage]);

  useEffect(() => {
    let event = {
      selected: 0,
    };
    handlePageClick(event);
  }, [sendingDate, school_id]);

  const handleStudentData = async () => {
    loaderDispatch({
      isLoading: true,
    });
    try {
      if (searchItem.toLowerCase() === "active") {
        setSearchItem("2");
      }
      if (searchItem.toLowerCase() === "inactive") {
        setSearchItem("1");
      }
      let order = orderby ? "desc" : "asc";

      if (searchItem.toLowerCase() === "active") {
        setSearchItem("1");
      } else if (searchItem.toLowerCase() === "inactive") {
        setSearchItem("2");
      }
      let result = await adminStudentListing(
        userData.id,
        `search=${searchItem.trim()}&sort_by=${sortby ? sortby : ""}&order=${
          order ? order : ""
        }&page=${itemOffset ? itemOffset : ""}&limit=${
          itemsPerPage ? itemsPerPage : ""
        }&start_date=${
          sendingDate?.startDate ? sendingDate?.startDate : ""
        }&end_date=${
          sendingDate?.endDate ? sendingDate?.endDate : ""
        }&school_id=${school_id ? school_id : ""}`
      );
      loaderDispatch({
        isLoading: false,
      });
      setData([]);
      setDataCount(0);

      if (
        result?.data?.status === StatusCodes.OK &&
        result?.data?.data?.total > 0
      ) {
        const studentData = result?.data?.data?.studentListing;
        const studentDataCount = result?.data?.data?.totalPage;
        let studentInfo = [];
        for (let i = 0; i < studentData.length; i++) {
          studentInfo.push({
            id: studentData[i]?.id,
            student_name: studentData[i]?.student_name,
            school_name: studentData[i]?.school_name,
            email: studentData[i]?.email,
            academic_year: studentData[i]?.academic_year,
            metamask_id: studentData[i]?.metamask_id,
            total_product: studentData[i]?.total_product,
            user_type: handelUserType(studentData[i]?.user_type),
            total_revenue: studentData[i]?.total_revenue,
            status: studentData[i]?.status,
            Subscription_name: studentData[i]?.Subscription_title,
          });
        }

        setData((preVal) => {
          return [...preVal, ...studentInfo];
        });
        setDataCount(studentDataCount);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const userTypeDetails = [
    {
      type: 1,
      value: "Admin",
    },
    {
      type: 2,
      value: "School",
    },
    {
      type: 3,
      value: "Student",
    },
  ];

  const handelUserType = (type) => {
    for (let i = 0; i < userTypeDetails.length; i++) {
      if (userTypeDetails[i].type === type) {
        return userTypeDetails[i].value;
      }
    }
  };

  const handleSchoolData = async () => {
    loaderDispatch({
      isLoading: true,
    });
    try {
      let order = orderby ? "desc" : "asc";
      let body = {
        itemOffset: "",
        itemPerPage: "",
        keyWord: "",
        sortName: "",
        sortTypeName: "",
      };

      let result = await getSchoolList(body);

      loaderDispatch({
        isLoading: false,
      });

      if (
        result?.data?.status === StatusCodes.OK &&
        result?.data?.data?.schoolListing?.count > 0
      ) {
        setSchoolOptions([
          {
            value: "",
            label: "Select School",
            selected: false,
          },
        ]);
        let data = result?.data?.data?.schoolListing?.rows;
        let schoolList = [];
        for (let i = 0; i < data.length; i++) {
          schoolList.push({
            value: data[i].id,
            label: data[i].school_name,
            selected: false,
          });
        }
        setSchoolOptions((preVal) => {
          return [...preVal, ...schoolList];
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSorting = (data, order) => {
    setSortby(data);
    setOrderby(order);
  };

  const handlePageClick = (event) => {
    setItemOffset(event.selected + 1);
  };

  const handleOptions = (optionValue, item) => {
    switch (optionValue) {
      case NFT:
        navigate(`/admin/reports/details/${item.id}`, {
          state: {
            dateValue: sendingDate,
            search: searchItem,
            inputDate: dates,
          },
        });
        break;
      default:
        break;
    }
  };

  const handelDownloadCSV = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });

      if (searchItem.toLowerCase() === "active") {
        setSearchItem("2");
      }
      if (searchItem.toLowerCase() === "inactive") {
        setSearchItem("1");
      }

      if (searchItem.toLowerCase() === "active") {
        setSearchItem("1");
      } else if (searchItem.toLowerCase() === "inactive") {
        setSearchItem("2");
      }
      let order = orderby ? "desc" : "asc";

      let body = {
        search: searchItem.trim(),
        sort: sortby,
        order: order,
        startDate: sendingDate?.startDate,
        endDate: sendingDate?.endDate,
        school_id: school_id,
        parent_id: userData?.id,
      };

      let result = await downloadCSV(body);
      loaderDispatch({
        isLoading: false,
      });
      if (result.data.status === StatusCodes.OK) {
        setFileLink(result?.data?.data);
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelChange = (e) => {
    setSchool_id(e.target.value);
  };

  return (
    <>
      <div className='p-2 grid grid-cols-4 gap-2 mt-1'>
        <div className=' flex justify-center'>
          <SearchFilter
            setSearchItem={setSearchItem}
            searchItem={searchItem}
            setPage={setItemOffset}
            cname={
              " block w-full border border-gray-300 rounded-md shadow py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
            }
          />
        </div>
        <div className='flex justify-center'>
          <SelectFilter
            options={schoolOptions}
            selectCls={
              " block w-full border bg-white border-gray-300 rounded-md shadow py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm text-gray-700"
            }
            onChange={handelChange}
          />
        </div>
        <div className='shadow flex justify-center w-fit rounded-md'>
          <DateFilter
            setOpen={setOpen}
            open={open}
            setSendingDate={setSendingDate}
            setDates={setDates}
            dates={dates}
          />
        </div>

        <div className='flex justify-end '>
          {/* <div className='pr-2'>
            <CustomInput
              inputData={""}
              inputCls='block w-full border bg-white border-gray-300 rounded-md shadow py-2 px-3 focus:outline-none 
              focus:ring-gray-900 focus:border-gray-900 sm:text-sm
              h-full'
              placeholder={"Total Revenue"}
              disabled={true}
              readOnly={false}
            />
          </div> */}
          <DownloadButton
            title={"Export CSV"}
            btnCls={"bg-green-700 px-3 rounded-md shadow-md text-xl text-white"}
            onClick={handelDownloadCSV}
            fileLink={fileLink}
          />
        </div>
      </div>
      <ListLayout
        handleOptions={handleOptions}
        tableHeader={tableHeader}
        handleSorting={handleSorting}
        currentItems={currentItems}
      />
      <div className='flex justify-between '>
        {data?.length > 0 && (
          <div
            className='block lg:inline-block lg:mt-0 '
            style={{ marginTop: "6px" }}>
            <label htmlFor='item' className='ml-5'>
              Record Per Page:
            </label>
            <SelectFilter
              options={itemsOnPage}
              selectCls={"ml-3 bg-white border p-2 rounded"}
              name={"item"}
              id={"item"}
              onChange={(e) => {
                setItemsPerPage(parseInt(e.target.value));
              }}
            />
          </div>
        )}
        <div className='mt-2 mb-10'>
          <ReactPaginate
            breakLabel='...'
            nextLabel='Next >'
            onPageChange={handlePageClick}
            pageCount={pageCount}
            previousLabel='< Previous'
            renderOnZeroPageCount={null}
            containerClassName={"pagination"}
            pageClassName={"pageClass"}
            forcePage={itemOffset - 1}
          />
        </div>

        {data?.length === 0 && (
          <div className='flex absolute left-1/2 top-1/2 mx-auto'>
            <p>Data not found..!!</p>
          </div>
        )}
      </div>
    </>
  );
}

export default AdminReport;
