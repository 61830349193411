import { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { AppContext } from "../App";
import { admin, website } from "../linksprovider";
import { ADMIN_USER_DATA } from "../utils/constant";

function StudentSecuredRoute() {
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }

  let studentUser = false;

  if (userData && userData.user_type === 3) {
    studentUser = true;
  }

  return studentUser ? (
    <div className='text-white contents'>
      <Outlet />
    </div>
  ) : (
    <Navigate to={website.landing} />
  );
}

export default StudentSecuredRoute;
