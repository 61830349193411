import React, { useState } from "react";
import styled from "styled-components";

import { Link, useNavigate, useParams } from "react-router-dom";
import { colors, space } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  LoginCard,
  LoginCardInner,
  Main,
  SiteForm,
  Container,
} from "../../../common/website/HOC.jsx";

import HeadingTwo from "../../../common/website/HeadingTwo.jsx";
import Paragraph from "../../../common/website/Paragraph.jsx";
import Label from "../../../common/website/Label.jsx";
import Button from "../../../common/website/Button.jsx";
import SiteLogo from "../../../common/website/SiteLogo.jsx";

import IconCheck from "../../../images/website/icon_check_blue.svg";
import siteLogo from "../../../images/website/site_logo.svg";
import OpenEye from "../../../images/website/icon_open_eye.svg";
import CloseEye from "../../../images/website/icon_close_eye.svg";
import LoginBG from "../../../images/website/login_bg.svg";
import { adminForgetPassword } from "../../../services/auth.services";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import { website } from "../../../linksprovider";

const ResetPasswordRow = styled.div`
  height: 100%;

  .meta_mask {
    text-align: right;
    margin-bottom: ${space.space24};
    a {
      padding: 0;
      margin-top: ${space.space14};
      &:hover {
        color: ${colors.secondary};
      }
    }
    label {
      text-align: start;
    }
  }
  h2 {
    margin-bottom: ${space.space14};
    text-align: center;
  }
  .form_group {
    margin-bottom: ${space.space24};
    position: relative;
  }
  .subtilte {
    margin-bottom: ${space.space30};
    text-align: center;
  }
  .submit-btn {
    width: 100%;
  }
  .account_link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ${space.space16};
    grid-gap: 6px;
    flex-wrap: wrap;
    p {
      margin: 0;
    }
    a {
      font-size: 16px;
      line-height: 19px;
      color: ${colors.primary};
      padding: 0;
      &:hover {
        color: ${colors.secondary};
      }
    }
  }
  .check_group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    grid-gap: 10px;
    .left {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      white-space: nowrap;
      input[type="checkbox"] {
        width: 20px;
        height: 20px;
        cursor: pointer;
        border: 1px solid ${colors.white};
        border-radius: 5px;
        appearance: none;
        overflow: hidden;
        padding: 0;
        &:checked {
          background-image: url(${IconCheck});
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          border: 1px solid ${colors.primary};
        }
        &[value="true"] {
          background-image: url(${IconCheck});
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          border: 1px solid ${colors.primary};
        }
      }
      label {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        padding-left: 10px;
        margin: 0;
      }
    }
    .right {
      a {
        padding: 0;
        &:hover {
          color: ${colors.secondary};
        }
      }
    }
  }
  .logo {
    text-align: center;
    margin-bottom: ${space.space20};
    display: block;
    a {
      display: inline-block;
    }
    img {
      max-width: 120px;
    }
  }
  .pass_group {
    position: relative;
    input {
      padding-right: 26px;
    }
    .eye_btn {
      content: "";
      position: absolute;
      background: url(${OpenEye}) no-repeat;
      background-size: contain;
      background-position: center;
      width: 18px;
      height: 18px;
      right: 0;
      bottom: 15px;
    }
    input[type="text"] ~ .eye_btn {
      background: url(${CloseEye}) no-repeat;
    }
  }
  .tool_tip_body {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    .tooltip {
      position: relative;
      display: inline-block;
      border-bottom: 1px dotted black;
      cursor: pointer;
      margin-bottom: 0.5rem;
      &:hover {
        .tooltiptext {
          visibility: visible;
        }
      }
    }
    .tooltiptext {
      font-size: 0.875rem;
      line-height: 1.25rem;
      visibility: hidden;
      width: 120px;
      background-color: ${colors.primary};
      color: ${colors.secondary};
      text-align: center;
      border-radius: 6px;
      padding: 5px;
      position: absolute;
      z-index: 1;
      bottom: 150%;
      left: 50%;
      margin-left: -60px;
      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: ${colors.primary} transparent transparent transparent;
      }
    }
  }
  .mb_50 {
    margin-bottom: ${space.space24};
  }
  @media ${device.mobileL} {
    .logo {
      img {
        max-width: 150px;
      }
    }
    .account_link {
      margin-top: ${space.space30};
    }
    .meta_mask {
      margin-bottom: ${space.space30};
    }
    .mb_50 {
      margin-bottom: ${space.space30};
    }
  }
  @media ${device.tablet} {
    .subtilte {
      margin-bottom: ${space.space50};
    }
    .logo {
      margin-bottom: ${space.space24};
      img {
        max-width: 168px;
      }
    }
    .form_group {
      margin-bottom: 34px;
      position: relative;
    }
    .mb_50 {
      margin-bottom: ${space.space50};
    }
    .meta_mask {
      margin-bottom: ${space.space50};
    }
  }
  @media ${device.laptop} {
    .logo {
      display: none;
    }
  }
`;

const LoginInnerRow = styled.div`
  height: 100vh;
  position: relative;
  &::before {
    content: "";
    position: fixed;
    background: url(${LoginBG}) no-repeat;
    background-position: center;
    background-size: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    z-index: 1;
  }
  .login_logo {
    text-align: center;
    display: none;
    a {
      display: inline-block;
      img {
        width: 250px;
        max-width: 100%;
      }
    }
  }
  .content_col {
    height: 100%;
    position: relative;
    z-index: 2;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .full_screen_container {
    height: 100%;
  }
  @media ${device.laptop} {
    .content_col {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .login_logo {
      display: block;
      a {
        img {
          width: 310px;
        }
      }
    }
  }
`;

function ResetPassword(props) {
  // Password Show Hiden
  const [passwordShown, setPasswordShown] = useState({
    passwordShown1: false,
    passwordShown2: false,
  });

  // Navigate to the page.
  let navigate = useNavigate();

  // Sets the initial values for the password and confirm password.
  const initialValues = {
    password: "",
    cnfpassword: "",
  };

  let { u_id } = useParams();

  // Creates a validation schema for a confirm password
  const validationSchema = Yup.object({
    password: Yup.string()
      .required("Please fill the field")
      .min(4, "Password must be at least 4 characters long"),

    cnfpassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "New & confirm password must be same")
      .required("Please fill the field"),
  });

  // Updates the password
  const onSubmit = async (values, { setSubmitting }) => {
    console.log(values);

    delete values.cnfpassword;
    let result = await adminForgetPassword(values, u_id);
    console.log(result?.data?.data?.length);
    if (result?.data?.status === StatusCodes.OK) {
      console.log("result.data.status", result?.data?.status);
      toast(result?.data?.message, {
        type: "success",
      });
      navigate(website.studentLogin);
    } else {
      toast(result?.data?.message, {
        type: "error",
      });
    }

    setSubmitting(false);
  };

  // Toggle the password type.
  const togglePassword = (e) => {
    const { name } = e.target;
    setPasswordShown((preVal) => {
      return { ...preVal, [name]: !preVal?.[name] };
    });
  };

  return (
    <>
      <Main>
        <LoginInnerRow className='content_full_screen'>
          <ResetPasswordRow className='right'>
            <Container className='full_screen_container'>
              <div className='grid gap-4 md:grid-cols-2 items-center content_col text-white'>
                <SiteLogo cname='login_logo' link='#' />
                <LoginCard>
                  <LoginCardInner>
                    <div className='logo'>
                      <Link to='/'>
                        <img
                          src={siteLogo}
                          srcSet={siteLogo}
                          className='sitelogo_img'
                          alt='YearBook3.0'
                        />
                      </Link>
                    </div>
                    <HeadingTwo cname='white' h2Data={"Reset Your Password"} />
                    <Paragraph
                      cname='subtilte'
                      pData={"Please Enter Your New Password"}
                    />
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}>
                      {({
                        isSubmitting,
                        /* and other goodies */
                      }) => (
                        <SiteForm as='div'>
                          <Form>
                            <div className='grid gap-1 md:grid-cols-1 items-center form_label_group'>
                              <div className='form_group pass_group'>
                                <Label labelData={"New Password*"} />
                                <Field
                                  type={
                                    passwordShown.passwordShown1
                                      ? "text"
                                      : "password"
                                  }
                                  name='password'
                                  placeholder='Enter your password'
                                />
                                <button
                                  type='button'
                                  name='passwordShown1'
                                  onClick={togglePassword}
                                  className='eye_btn absolute'></button>
                                <ErrorMessage
                                  name='password'
                                  component='div'
                                  className='text-red-500 text-sm absolute'
                                />
                              </div>
                              <div className='form_group pass_group mb_50'>
                                <Label labelData={"Confirm Password*"} />
                                <Field
                                  type={
                                    passwordShown.passwordShown2
                                      ? "text"
                                      : "password"
                                  }
                                  name='cnfpassword'
                                  placeholder='Confirm password'
                                />
                                <button
                                  type='button'
                                  name='passwordShown2'
                                  onClick={togglePassword}
                                  className='eye_btn absolute'></button>
                                <ErrorMessage
                                  name='cnfpassword'
                                  component='div'
                                  className='text-red-500 text-sm absolute'
                                />
                              </div>
                            </div>
                            <div className='btn_group'>
                              <Button
                                tag='button'
                                type='submit'
                                cname='gradient submit-btn'
                                buttonData={"Save New Password"}
                                disabled={isSubmitting}
                              />
                            </div>
                          </Form>
                        </SiteForm>
                      )}
                    </Formik>
                  </LoginCardInner>
                </LoginCard>
              </div>
            </Container>
          </ResetPasswordRow>
        </LoginInnerRow>
      </Main>
    </>
  );
}

export default ResetPassword;
