import { Fragment, useState, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { AdminContext } from "../../common/DashboardWrapper";
import { useNavigate, useLocation } from "react-router-dom";
import { admin } from "../../linksprovider";
import logo from "../../images/logo.svg";
import { sendInvite } from "../../services/auth.services";
import { AppContext } from "../../App";
import { StatusCodes } from "http-status-codes";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { loaderContext } from "../global loader/global-loader-provider";
import { toast } from "react-toastify";

function AdminInviteModal({ dataCall, body }) {
  const { open, setOpen, currentAddress } = useContext(AdminContext);
  let navigate = useNavigate();

  const [schoolData, setschoolData] = useState({
    school_name: "",
    email: "",
    status: 2,
  });
  const { loaderDispatch } = useContext(loaderContext);
  const alphaSpace = /^[A-Za-z\s]*$/;
  const validate = Yup.object({
    school_name: Yup.string()
      .trim()

      .required("Please fill the field")
      .max(60, "Maximum 60 characters are allowed"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Please fill the field"),

    status: Yup.number().required(),
  });

  const handelSubmit = async (values, { setSubmitting }) => {
    loaderDispatch({
      isLoading: true,
    });

    let result = await sendInvite(values);
    console.log(result);

    loaderDispatch({
      isLoading: false,
    });
    if (result?.data?.status === StatusCodes.OK) {
      toast(result?.data?.message, {
        type: "success",
      });
      setOpen(false);
      dataCall(body);
    } else {
      toast(result?.data?.message, {
        type: "error",
      });
    }

    setSubmitting(false);
  };

  const handelChange = (e) => {
    const { value, name } = e.target;
    if (value.trim().length !== 0 || value === "") {
      setschoolData((preVal) => {
        return { ...preVal, [name]: value };
      });
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        static
        className='fixed z-10 inset-0 overflow-y-auto'
        open={open}
        onClose={setOpen}>
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'>
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
            <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6'>
              <div>
                <div className='sm:mx-auto sm:w-full sm:max-w-md'>
                  <img
                    className='mx-auto h-12 w-auto'
                    src={logo}
                    alt='Workflow'
                  />
                  <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
                    Send Invite
                  </h2>

                  <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
                    <div className='bg-white py-8 px-4  sm:rounded-lg sm:px-10'>
                      <Formik
                        onSubmit={handelSubmit}
                        initialValues={schoolData}
                        validationSchema={validate}>
                        {({ isSubmitting }) => (
                          <div className='space-y-6'>
                            <Form>
                              <div>
                                <label
                                  htmlFor='name'
                                  className='block text-sm font-medium text-gray-700'>
                                  School name{" "}
                                  <span className='text-red-500'>&#42;</span>
                                </label>
                                <div className='mt-1'>
                                  <Field
                                    id='name'
                                    name='school_name'
                                    type='text'
                                    // value={schoolData.school_name}
                                    autoComplete='off'
                                    // onChange={handelChange}
                                    // onBlur={handelBlur}
                                    // onKeyUp={() => console.log()}
                                    // required
                                    className='appearance-none block  w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-gray-200 sm:text-sm'
                                  />
                                </div>

                                <div className='text-sm text-red-500'>
                                  <ErrorMessage name='school_name' as='div' />
                                </div>
                              </div>

                              <div className='mt-3'>
                                <label
                                  htmlFor='email'
                                  className='block text-sm font-medium text-gray-700'>
                                  Email address{" "}
                                  <span className='text-red-500'>&#42;</span>
                                </label>
                                <div className='mt-1'>
                                  <Field
                                    id='email'
                                    name='email'
                                    type='text'
                                    autoComplete='off'
                                    // value={schoolData.email}
                                    // onChange={handelChange}
                                    // onBlur={handelBlur}
                                    // required
                                    className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm'
                                  />
                                </div>

                                <div className='text-sm text-red-500'>
                                  <ErrorMessage name='email' as='div' />
                                </div>
                              </div>

                              <div className='mt-5 sm:mt-6'>
                                <button
                                  type='submit'
                                  disabled={isSubmitting}
                                  className='inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm'>
                                  Send Invite
                                </button>

                                <button
                                  type='button'
                                  onClick={() => setOpen(false)}
                                  className='inline-flex justify-center w-full rounded-md border border-transparent shadow-lg px-4 py-2 bg-white text-base font-medium text-gray-500 mt-2'>
                                  Cancel
                                </button>
                              </div>
                            </Form>
                          </div>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default AdminInviteModal;
