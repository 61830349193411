import React, { useState } from "react";
import CommonModal from "../../../common/commonModal";
import { ErrorMessage, Field, Formik, Form } from "formik";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import styled from "styled-components";

const DateDiv = styled.div`
  .react-date-picker__wrapper {
    border: none !important;
  }
  .react-calendar__tile:disabled {
    color: gray;
  }
  .react-calendar__navigation button:disabled {
    color: gray;
  }
`;

function ViewJob({ openViewModal, setOpenViewModal, itemsId }) {
  return (
    <>
      <CommonModal
        openModal={openViewModal}
        setOpenModal={setOpenViewModal}
        header={"View job"}
        divClass={
          "inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6"
        }>
        <div className='max-h-96 overflow-y-auto px-2 w-80 '>
          <div className='text-sm space-y-3'>
            <div>
              <label htmlFor='' className='font-medium text-sm text-slate-800'>
                Title
              </label>
              <p className='border px-2 py-2 rounded focus:ring-1 ring-indigo-500 outline-none w-full'>
                {itemsId?.title}
              </p>
            </div>
            <div>
              <label htmlFor='' className='font-medium text-sm text-slate-800'>
                Description
              </label>
              <p className='border px-2 py-2 rounded focus:ring-1 ring-indigo-500 outline-none w-full max-h-32 overflow-y-auto'>
                {itemsId?.description}
              </p>
            </div>

            <div>
              <label htmlFor='' className='font-medium text-sm text-slate-800'>
                URL
              </label>
              <p className='border px-2 py-2 rounded focus:ring-1 ring-indigo-500 outline-none w-full max-h-32 overflow-y-auto'>
                {itemsId?.url}
              </p>
            </div>

            <div>
              <label htmlFor='' className='font-medium text-sm text-slate-800'>
                End date
              </label>
              <p className='border px-2 py-2 rounded focus:ring-1 ring-indigo-500 outline-none w-full max-h-32 overflow-y-auto'>
                {itemsId?.application_end_date}
              </p>
            </div>
          </div>
        </div>
      </CommonModal>
    </>
  );
}

export default ViewJob;
