import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { device } from "../../../common/website/Devices";
import { colors, space } from "../../../common/website/Variables";
import ProfileHeader from "../../../components/website/ProfileHeader/ProfileHeader.jsx";
import MyMomentsImg from "./MyMomentsImg.jsx";
import NFTMoments from "./NFTMoments.jsx";

import { loaderContext } from "../../../components/global loader/global-loader-provider.js";
import { ADMIN_USER_DATA } from "../../../utils/constant.js";
import {
  getSubscriptionDetail,
  getClassmatesList,
  getNFTPosts,
  getDigitalArt,
  myMomentAndTradePostListing,
} from "../../../services/auth.services";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import { PostContext } from "../../../components/website/header/MainHeader.jsx";
import blankProfile from "../../../images/website/dummy_user_profile.svg";
import DigitalArt from "./DigitalArt.jsx";
import TradeMomentsTab from "./TradeMomentsTab.jsx";

const GalleryRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  .profile_top {
    padding: ${space.space20};
    .other_profile_text {
      display: block;
      font-size: 1rem;
    }
    .NFT_Balance.other_profile_year {
      display: flex;
    }

    &::before {
      top: -80px;
    }

    .member_count {
      display: none !important;
    }
    .profile_image {
      bottom: -6px;
    }
  }
  .club_group {
    display: none;
  }
  h3,
  h4,
  p {
    position: static;
  }
  .sidebar_right {
    position: static;
    top: 130px;
    width: 100%;
  }
  .classmates_list {
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    grid-gap: 12px 16px;
    max-height: 120px;
    li {
      margin: 0;
    }
  }
  .sceen_bottom {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    flex-direction: column-reverse;
    grid-gap: ${space.space14};
  }
  .sceen_main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-wrap: wrap;
  }
  .create_post {
    margin-top: 0;
  }
  .tab_list {
    display: flex;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    margin-bottom: ${space.space24};
    margin-top: ${space.space10};
    position: relative;
    &::before {
      content: "";
      position: absolute;
      background: ${colors.lightwhiteborder};
      width: 100%;
      height: 1px;
      bottom: 1px;
    }
    .tab_item {
      font-size: 0.875rem;
      line-height: 1.5rem;
      font-weight: 600;
      padding-bottom: ${space.space8};
      margin-right: ${space.space20};
      color: ${colors.lightgrey2};
      cursor: pointer;
      position: relative;
      transition: all 0.4s;
      &:last-child {
        margin-right: 0;
      }
      &:first-child {
        margin-left: 10px;
      }
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        background: ${colors.primary};
        width: 0%;
        height: 3px;
        border-radius: ${space.space20};
        transition: all 0.4s;
      }
      &:hover,
      &.active {
        color: ${colors.white};
        transition: all 0.4s;
        &::before {
          width: 100%;
          transition: all 0.4s;
        }
      }
    }
  }

  .moments_list {
    list-style-type: none;
    column-count: 3;
    grid-gap: 8px;
    /* height: calc(100vh - 293px);*/
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  .moment_list_block {
    height: calc(100vh - 304px);
    overflow-x: hidden;
  }
  @media ${device.mobileM} {
    .moments_list {
      column-count: 4;
      grid-gap: 16px;
    }
    .profile_top {
      padding: ${space.space30};
      .profile_image {
        bottom: -54px;
      }
    }
    .moment_list_block {
      height: calc(100vh - 330px);
      overflow-x: hidden;
    }
  }
  @media ${device.tablet} {
    width: 75%;
    .sceen_bottom {
      flex-direction: row;
      grid-gap: 0;
    }
    .sidebar_right {
      width: 35%;
    }
    .moment_list_block {
      height: calc(100vh - 235px);
    }
    .moments_list {
      column-count: 3;
      /* height: calc(100vh - 215px); */
    }
    .profile_header {
      padding-left: ${space.space20};
    }
    .sidebar_right {
      position: sticky;
    }
    .mobile_hidden {
      display: block;
    }
    .club_group {
      flex-direction: column;
      align-items: flex-start;
      .count {
        font-size: 0.75rem;
        line-height: 19px;
      }
    }
    .classmates_list {
      display: block;
      grid-gap: 0;
      max-height: 100%;
      height: calc(100vh - 375px);
      li {
        margin-bottom: 18px;
      }
    }
  }
  @media ${device.laptop} {
    width: 77%;
    .sidebar_right {
      width: 29%;
    }
    .tab_list {
      margin-top: 0;
      .tab_item {
        font-size: 1rem;
        margin-right: ${space.space30};
      }
    }
    .tab_list {
      margin-bottom: ${space.space40};
    }
    .moment_list_block {
      height: calc(100vh - 323px);
    }
    .moments_list {
      column-count: 4;
      /* height: calc(100vh - 323px); */
    }
    .sidebar_right {
      top: 188px;
    }
    .classmates_list {
      height: calc(100vh - 450px);
    }
    .club_group {
      flex-direction: row;
      align-items: center;
    }
    .profile_top {
      padding: ${space.space60} ${space.space40} ${space.space40};
      .profile_image {
        bottom: -70px;
      }
    }
  }
  @media ${device.laptopL} {
    width: 80%;
    .sidebar_right {
      width: 26%;
    }
    .club_group {
      .count {
        font-size: 1rem;
        line-height: 1.625rem;
      }
    }
    .profile_header {
      padding-left: ${space.space40};
    }
    .classmates_list {
      height: calc(100vh - 470px);
    }
    .tab_list {
      .tab_item {
        padding-bottom: ${space.space12};
        font-size: 1.125rem;
        margin-right: ${space.space50};
      }
    }
  }
`;

function Gallery() {
  const [game, setGame] = useState("my_moments");
  const [studentDetails, setStudentDetails] = useState([]);
  const [classmatesData, setClassmatesData] = useState([]);
  const [galleryData, setGalleryData] = useState([]);
  const [nftPostData, setNftPostData] = useState([]);
  const [digitalArtData, setDigitalArtData] = useState([]);

  const [page, setPage] = useState(1);
  const { loaderDispatch } = useContext(loaderContext);
  const {
    pageReload,
    setClubData,
    setUserInfo,
    setTeamData,
    getMomentPostListing,
    myMomentPostData,
    userInfo,
    gettingNotification,
    handelLogout,
  } = useContext(PostContext);

  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  let scrollRef = useRef();

  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }

  const handleClick = (gameState) => {
    setGame(gameState);
  };

  //function run on page load
  let sub = true;
  useEffect(() => {
    if (sub) {
      getStudentProfile();
      nftPostList({ loader: true });
      setClubData({});
      setTeamData({});
    }
    setPage(1);
    return () => (sub = false);
  }, [pageReload, game]);

  //getting student details on page load
  const getStudentProfile = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });

      let studentData = await getSubscriptionDetail(userData);
      loaderDispatch({
        isLoading: false,
      });
      if (studentData?.data?.status === 410) {
        handelLogout();
      }
      if (studentData?.data?.status === StatusCodes.OK) {
        let data = studentData?.data?.data;

        gettingClassList(data);
        setStudentDetails(data);
        getGalleryData({ data, loader: true });
        gettingDigitalArt({ data, loader: true });
        setUserInfo(data);
        // getMomentPostListing({
        //   userInfo: data,
        //   loader: true,
        //   is_trade_moment: true,
        // });
        gettingNotification({
          StudentInfo: data,
          notification: false,
          pages: 1,
          loader: true,
        });
      } else {
        toast(studentData?.data?.massage, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const gettingClassList = async (StudentInfo) => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let body = {
        schoolId: StudentInfo?.User?.id,
        standard: StudentInfo?.standard,
        userId: StudentInfo?.id,
        academicYear: StudentInfo?.academic_year,
      };

      let result = await getClassmatesList(body);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        setClassmatesData(result?.data?.data?.docs);
      } else {
        toast(result?.data?.massage, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getGalleryData = async ({
    data = null,
    pages = null,
    loader = null,
  }) => {
    try {
      if (loader) {
        loaderDispatch({
          isLoading: true,
        });
      }
      let params = {
        id: data?.User?.id,
        search: "",
        userId: userData?.id,
        page: pages ? pages : 1,
        // is_moment: true,
        // user_type: 3,
        is_trade_moment: game === "trade_moments" ? true : null,

        // studentId: userData?.id,
      };

      let result = await myMomentAndTradePostListing(params);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        let data = result?.data?.data?.postsListing;
        console.log(data);
        if (data?.length > 0 && pages > 1) {
          setGalleryData((preVal) => {
            return [...preVal, ...data];
          });
        }
        if (pages === 1 || !pages) {
          setGalleryData(data);
        }
      } else {
        toast(result?.data?.massage, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const gettingDigitalArt = async ({
    data = null,
    pages = null,
    loader = null,
  }) => {
    try {
      if (loader) {
        loaderDispatch({
          isLoading: true,
        });
      }
      let params = {
        // id: data?.User?.id,
        search: "",
        userId: userData?.id,
        // studentId: userData?.id,
        page: pages ? pages : 1,
      };

      let result = await getDigitalArt(params);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        let data = result?.data?.data?.docs;
        // for (let i = 0; i < data.length; i++) {
        //   data[i].postType = 1;
        //   data[i].postName = data[i]?.fileName;
        // }
        console.log("digital", data);
        if (data?.length > 0 && pages > 1) {
          setDigitalArtData((preVal) => {
            return [...preVal, ...data];
          });
        }
        if (pages === 1 || !pages) {
          setDigitalArtData(data);
        }
      } else {
        toast(result?.data?.massage, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const nftPostList = async ({ loader = null, pages = null }) => {
    try {
      if (loader) {
        loaderDispatch({
          isLoading: true,
        });
      }
      let params = {
        userId: userData?.id,
        page: pages ? pages : 1,
      };

      let result = await getNFTPosts(params);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        let data = result?.data?.data?.docs;
        console.log("postsListing", data);
        if (data?.length > 0 && pages > 1) {
          setNftPostData((preVal) => {
            return [...preVal, ...data];
          });
        }
        if (pages === 1 || !pages) {
          setNftPostData(data);
        }
      } else {
        toast(result?.data?.massage, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  let pages = page;
  const handelScroll = () => {
    if (scrollRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      console.log("sssssss", scrollTop, clientHeight, scrollHeight);
      if (
        scrollTop + clientHeight > scrollHeight - 2 &&
        scrollTop + clientHeight <= scrollHeight
      ) {
        pages++;
        setPage(pages);
        let loader = 1;
        switch (game) {
          case "my_moments":
            getGalleryData({ data: userInfo, pages, loader: false });
            break;
          case "trade_moments":
            getGalleryData({ data: userInfo, pages, loader: false });
            break;
          case "nft_moments":
            nftPostList({ pages });
            break;
          case "digital_art":
            gettingDigitalArt({ data: userInfo, pages, loader: false });
            break;
          default:
            return null;
        }
      }
    }
  };

  return (
    <>
      <GalleryRow>
        <div className='sceen_main'>
          <ProfileHeader
            cname='profile_header'
            ClubProfileImage={
              studentDetails?.filtered_image
                ? studentDetails?.filtered_image
                : blankProfile
            }
            Name={studentDetails?.student_name}
          />
          <div className='sceen_bottom'>
            {(() => {
              switch (game) {
                case "my_moments":
                  return (
                    <MyMomentsImg
                      handleClick={handleClick}
                      galleryData={galleryData}
                      scrollRef={scrollRef}
                      handelScroll={handelScroll}
                    />
                  );
                case "trade_moments":
                  return (
                    <TradeMomentsTab
                      handleClick={handleClick}
                      galleryData={galleryData}
                      scrollRef={scrollRef}
                      handelScroll={handelScroll}
                    />
                  );
                case "nft_moments":
                  return (
                    <NFTMoments
                      handleClick={handleClick}
                      nftPostData={nftPostData}
                      scrollRef={scrollRef}
                      handelScroll={handelScroll}
                    />
                  );
                case "digital_art":
                  return (
                    <DigitalArt
                      handleClick={handleClick}
                      galleryData={digitalArtData}
                      scrollRef={scrollRef}
                      handelScroll={handelScroll}
                    />
                  );
                default:
                  return null;
              }
            })()}
          </div>
        </div>
      </GalleryRow>
    </>
  );
}

export default Gallery;
