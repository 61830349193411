import React from "react";

function CustomInput({
  inputData,
  inputCls,
  placeholder,
  type,
  id,
  disabled,
  readOnly,
  onChange,
  name,
  minLength,
  maxLength,
}) {
  return (
    <input
      type={type}
      value={inputData}
      id={id}
      name={name}
      className={inputCls}
      placeholder={placeholder}
      disabled={disabled}
      readOnly={readOnly}
      onChange={onChange}
      minLength={minLength}
      maxLength={maxLength}
    />
  );
}

export default CustomInput;
