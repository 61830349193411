import React, { useRef, Fragment } from "react";
import styled from "styled-components";
import { Transition } from "@headlessui/react";
import HeadingFive from "../../../common/website/HeadingFive.jsx";
import { GrClose } from "react-icons/gr";
import { SiteModal, SiteForm } from "../../../common/website/HOC.jsx";
import { device } from "../../../common/website/Devices";

import { space } from "../../../common/website/Variables";
import Paragraph from "../../../common/website/Paragraph.jsx";
import Button from "../../../common/website/Button.jsx";
import { IMG } from "../../../utils/constant.js";
import { useEffect } from "react";

const DisCardRow = styled.div`
  .modal_content {
    text-align: center;
    padding: ${space.space60} 0;
    .modal_inner {
      max-width: 590px;
    }
    p {
      margin-bottom: ${space.space20};
    }
  }
  @media ${device.mobileL} {
    .modal_content {
      padding: 82px ${space.space20};
      p {
        margin-bottom: ${space.space30};
      }
    }
  }
`;

function DisCard({
  setOpenImageModal,
  handleClick,
  setPostDetails,
  openImageModal,
}) {
  useEffect(() => {
    if (!openImageModal) {
      // setOpenImageModal(false);
      setPostDetails({
        postImage: {},
        caption: "",
      });
      handleClick(IMG);
    }
  }, [openImageModal]);

  return (
    <DisCardRow className='SetProfilePhoto'>
      <SiteModal>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          enterTo='opacity-100 translate-y-0 sm:scale-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100 translate-y-0 sm:scale-100'
          leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
          <div className='modal_inner'>
            <div className='modal_boday'>
              <div className='model_top'>
                <HeadingFive cname='title' h5Data={"Discard Post?"} />
                <span
                  className='modal_close'
                  onClick={() => {
                    setOpenImageModal(false);
                    setPostDetails({
                      postImage: {},
                      caption: "",
                    });
                    handleClick(IMG);
                  }}>
                  {" "}
                  <GrClose />
                </span>
              </div>
              <div className='modal_content'>
                <Paragraph
                  pData={"Are you sure, you want to discard this post?"}
                />
                <SiteForm as='div'>
                  <Button
                    as='button'
                    cname='gradient discard_btn'
                    buttonData={"Discard"}
                    onClick={() => {
                      setOpenImageModal(false);
                      setPostDetails({
                        postImage: {},
                        caption: "",
                      });
                      handleClick(IMG);
                    }}
                  />
                </SiteForm>
              </div>
            </div>
          </div>
        </Transition.Child>
      </SiteModal>
    </DisCardRow>
  );
}

export default DisCard;
