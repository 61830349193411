import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../../images/logo.svg";
import {
  getSchoolDetails,
  schoolRegistration,
} from "../../services/auth.services";
import { StatusCodes } from "http-status-codes";
import { admin } from "../../linksprovider";
import { loaderContext } from "../../components/global loader/global-loader-provider";
import { getCountryList } from "../../common_api/commonApi";
import { toast } from "react-toastify";
import { IoEyeSharp } from "react-icons/io5";
import { BsEyeSlashFill } from "react-icons/bs";

function SchoolRegistration() {
  // Sets the id to use params.
  const { id } = useParams();
  // assigning usenavigate to navigate.
  let navigate = useNavigate();
  // get toast from use context.
  // initial state of schoolinfo.
  const [schoolInfo, setSchoolInfo] = useState({
    password: "",
    cnfPassword: "",
    address: "",
    state: "",
    city: "",
    country: "",
    pincode: "",
  });

  // Sets the use state for the form submit.
  const [formSubmit, setFormSubmit] = useState(false);
  // Sets the cnfErr constant.
  const [cnfErr, setCnfErr] = useState(false);

  // Sets the preSchoolDetails  to use state.
  const [preSchoolDetails, setPreSchoolDetails] = useState({
    email: "",
    school_name: "",
  });

  // Sets the use state for the given country and country.
  const [countryList, setCountryList] = useState([]);

  const [passwordHideAndShow, setPasswordHideAndShow] = useState({
    newPassword: false,
    confirmPassword: false,
  });

  // Loads the state of the loader.
  const { loaderDispatch } = useContext(loaderContext);
  const getPreRegistrationDetails = async () => {
    loaderDispatch({
      isLoading: true,
    });
    let result = await getSchoolDetails(id);

    loaderDispatch({
      isLoading: false,
    });

    if (result?.data?.status === StatusCodes.OK) {
      if (result?.data?.data) {
        setPreSchoolDetails({
          email: result?.data?.data?.email,
          school_name: result?.data?.data?.school_name,
        });
      }
    }
  };

  useEffect(() => {
    getPreRegistrationDetails();
    getAllCountries();
  }, []);

  const handelRegistration = async () => {
    setFormSubmit(true);
    setCnfErr(false);
    if (
      schoolInfo.password !== schoolInfo.cnfPassword ||
      schoolInfo?.password.length < 4
    ) {
      setCnfErr(true);
      return;
    }

    if (
      schoolInfo.password !== "" &&
      schoolInfo.cnfPassword !== "" &&
      schoolInfo.address !== "" &&
      schoolInfo.state !== "" &&
      schoolInfo.country !== "" &&
      schoolInfo.pincode !== "" &&
      schoolInfo.city !== ""
    ) {
      let jsonData = {
        address: schoolInfo.address,
        state: schoolInfo.state,
        country: schoolInfo.country,
        password: schoolInfo.password,
        pincode: schoolInfo.pincode,
        city: schoolInfo.city,
        email: preSchoolDetails?.email,
        // school_id: id,
      };
      loaderDispatch({
        isLoading: true,
      });

      let result = await schoolRegistration(id, jsonData);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
        });
        navigate(admin.login);
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    }
  };

  // Get all countries.
  const getAllCountries = async (params) => {
    try {
      let result = await getCountryList();
      setCountryList(result);
    } catch (error) {
      console.log(error);
    }
  };

  const handelChange = (e) => {
    const { name, value } = e.target;
    if (name.toLowerCase().includes("address")) {
      if (value.trim().length > 0 || value === "") {
        setSchoolInfo((preVal) => {
          return { ...preVal, [name]: value };
        });
      }
    } else if (
      name.toLowerCase().includes("state") ||
      name.toLowerCase().includes("city")
    ) {
      if (
        ((value.trim().length > 0 && value.trim().length < 31) ||
          value === "") &&
        checkAlpaSpace(value)
      ) {
        setSchoolInfo((preVal) => {
          return { ...preVal, [name]: value };
        });
      }
    } else if (name.toLowerCase().includes("pincode")) {
      if (
        (value.toString().trim().length > 0 &&
          value.toString().trim().length < 13) ||
        value.toString() === ""
      ) {
        if (
          (checkPricePositive(value) && !isNaN(parseInt(value))) ||
          value.toString() === ""
        ) {
          setSchoolInfo((preVal) => {
            return { ...preVal, [name]: value };
          });
        }
      }
    } else {
      setSchoolInfo((preVal) => {
        return { ...preVal, [name]: value };
      });
    }
  };

  const checkOnlyNumber = (value) => {
    return /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/.test(
      value
    );
  };

  const checkSpecialCharacter = (value) => {
    return /^[^a-zA-Z0-9]+$/.test(value);
  };

  const checkAlpaSpace = (value) => {
    return /^[A-Za-z\s]*$/.test(value);
  };

  //checking positive value
  const checkPricePositive = (value) => {
    return `${value}`.match(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/);
  };

  console.log("0", preSchoolDetails.school_name);

  return (
    <div className='mt-10 sm:mt-0 h-screen w-full flex  justify-center items-center bg-gray-50 '>
      <div className='mt-5 md:col-span-2 md:mt-0 w-[45%]'>
        <div>
          <div className='overflow-hidden shadow sm:rounded-md'>
            <div className='bg-white px-4 py-5 sm:p-6'>
              <img className=' h-10 w-auto' src={logo} alt='' />
              <h2 className='text-xl font-bold  mt-5 relative text-center text-cyan-600'>
                School Registration
              </h2>

              <div className='mb-6 '>
                <hr />
              </div>
              <div className='grid grid-cols-6 gap-y-2 gap-x-6'>
                <div className='col-span-6 sm:col-span-3'>
                  <label
                    htmlFor='first-name'
                    className='block text-sm font-medium text-gray-700'>
                    School name<span className='text-red-600'>&#42;</span>
                  </label>
                  <input
                    type={"text"}
                    value={preSchoolDetails?.school_name}
                    name='student_name'
                    disabled
                    className='mt-1 block w-full rounded-md border-gray-300 shadow-sm  sm:text-sm px-2 py-2 outline-none border'
                  />
                </div>

                <div className='col-span-6 sm:col-span-3'>
                  <label
                    htmlFor='email-address'
                    className='block text-sm font-medium text-gray-700'>
                    Email address
                  </label>
                  <input
                    type={"text"}
                    value={preSchoolDetails?.email}
                    disabled
                    autoComplete='email'
                    className='mt-1 block w-full rounded-md border-gray-300 shadow-sm  sm:text-sm px-2 py-2 outline-none border'
                  />
                </div>

                <div className='col-span-6 sm:col-span-3'>
                  <label
                    htmlFor='country'
                    className='block text-sm font-medium text-gray-700'>
                    New Password<span className='text-red-600'>&#42;</span>
                  </label>
                  <span className='flex border-gray-300 border pr-2 justify-between items-center mt-1  w-full shadow-sm  sm:text-sm outline-none rounded-md'>
                    <input
                      type={
                        passwordHideAndShow.newPassword ? "text" : "password"
                      }
                      value={schoolInfo?.password}
                      name='password'
                      placeholder='Password'
                      onChange={handelChange}
                      autoComplete='email'
                      className='outline-none px-2 py-2 rounded-md w-full'
                    />
                    {passwordHideAndShow.newPassword ? (
                      <BsEyeSlashFill
                        className='text-lg text-gray-400 cursor-pointer'
                        onClick={() =>
                          setPasswordHideAndShow((preVal) => {
                            return {
                              ...preVal,
                              newPassword: !preVal.newPassword,
                            };
                          })
                        }
                      />
                    ) : (
                      <IoEyeSharp
                        className='text-lg text-gray-400 cursor-pointer'
                        onClick={() =>
                          setPasswordHideAndShow((preVal) => {
                            return {
                              ...preVal,
                              newPassword: !preVal.newPassword,
                            };
                          })
                        }
                      />
                    )}
                  </span>
                  {schoolInfo?.password === "" && formSubmit && (
                    <p className='text-sm text-red-500'>
                      Please fill the field
                    </p>
                  )}
                  {schoolInfo?.password.length < 4 &&
                    schoolInfo?.password.length !== 0 && (
                      <p className='text-sm text-red-500 '>
                        Password must be at least 4 characters long
                      </p>
                    )}
                </div>
                <div className='col-span-6 sm:col-span-3'>
                  <label
                    htmlFor='country'
                    className='block text-sm font-medium text-gray-700'>
                    Confirm Password<span className='text-red-600'>&#42;</span>
                  </label>
                  <span className='flex border-gray-300 border items-center mt-1 pr-2 justify-between  w-full rounded-md shadow-sm  sm:text-sm outline-none '>
                    <input
                      type={
                        passwordHideAndShow.confirmPassword
                          ? "text"
                          : "password"
                      }
                      value={schoolInfo?.cnfPassword}
                      name='cnfPassword'
                      onChange={handelChange}
                      placeholder='Confirm password'
                      autoComplete='email'
                      className='outline-none px-2 py-2 rounded-md w-full'
                    />
                    {passwordHideAndShow.confirmPassword ? (
                      <BsEyeSlashFill
                        className='text-lg text-gray-400 cursor-pointer'
                        onClick={() =>
                          setPasswordHideAndShow((preVal) => {
                            return {
                              ...preVal,
                              confirmPassword: !preVal.confirmPassword,
                            };
                          })
                        }
                      />
                    ) : (
                      <IoEyeSharp
                        className='text-lg text-gray-400 cursor-pointer'
                        onClick={() =>
                          setPasswordHideAndShow((preVal) => {
                            return {
                              ...preVal,
                              confirmPassword: !preVal.confirmPassword,
                            };
                          })
                        }
                      />
                    )}
                  </span>

                  {formSubmit && schoolInfo.cnfPassword === "" ? (
                    <p className='text-red-500 text-sm'>
                      Please fill the field
                    </p>
                  ) : formSubmit &&
                    schoolInfo.cnfPassword !== schoolInfo?.password ? (
                    <p className='text-red-500 text-sm break-words'>
                      New & confirm password must be same
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div className='col-span-6'>
                  <label
                    htmlFor='street-address'
                    className='block text-sm font-medium text-gray-700'>
                    Street address<span className='text-red-600'>&#42;</span>
                  </label>
                  <textarea
                    type={"text"}
                    value={schoolInfo?.address}
                    name='address'
                    onChange={handelChange}
                    placeholder='Full address...'
                    autoComplete='street-address'
                    className='mt-1 block w-full rounded-md border-gray-300 shadow-sm  sm:text-sm px-2 py-2 outline-none border'
                  />
                  {schoolInfo?.address === "" && formSubmit && (
                    <p className='text-sm text-red-500'>
                      Please fill the field
                    </p>
                  )}
                </div>
                {/* country dropdown start */}
                <div className='col-span-6 sm:col-span-3'>
                  <label
                    htmlFor='country'
                    className='block text-sm font-medium text-gray-700'>
                    Country<span className='text-red-600'>&#42;</span>
                  </label>
                  <select
                    type={"text"}
                    name='country'
                    onChange={handelChange}
                    autoComplete='country-name'
                    className='mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm outline-none sm:text-sm'>
                    <option value={""}>Select Country</option>
                    {countryList?.map((val, i) => {
                      return (
                        <option value={val.country_name} key={i}>
                          {val.country_name}
                        </option>
                      );
                    })}
                  </select>
                  {schoolInfo?.country === "" && formSubmit && (
                    <p className='text-sm text-red-500'>
                      Please fill the field
                    </p>
                  )}
                </div>

                {/* country dropdown end */}

                <div className='col-span-6 sm:col-span-3'>
                  <label
                    htmlFor='region'
                    className='block text-sm font-medium text-gray-700'>
                    State / Province<span className='text-red-600'>&#42;</span>
                  </label>
                  <input
                    type={"text"}
                    value={schoolInfo?.state}
                    name='state'
                    maxLength={30}
                    minLength={0}
                    onChange={handelChange}
                    placeholder='Enter your state'
                    autoComplete='address-level1'
                    className='mt-1 block w-full rounded-md border-gray-300 shadow-sm  sm:text-sm px-2 py-2 outline-none border'
                  />
                  {schoolInfo?.state === "" && formSubmit && (
                    <p className='text-sm text-red-500'>
                      Please fill the field
                    </p>
                  )}
                </div>

                <div className='col-span-6 sm:col-span-3'>
                  <label
                    htmlFor='city'
                    className='block text-sm font-medium text-gray-700'>
                    City<span className='text-red-600'>&#42;</span>
                  </label>
                  <input
                    type='text'
                    name='city'
                    maxLength={30}
                    minLength={0}
                    value={schoolInfo?.city}
                    id='city'
                    onChange={handelChange}
                    placeholder='Enter your city'
                    autoComplete='address-level2'
                    className='mt-1 block w-full rounded-md border-gray-300 shadow-sm  sm:text-sm px-2 py-2 outline-none border'
                  />
                  {schoolInfo?.city === "" && formSubmit && (
                    <p className='text-sm text-red-500'>
                      Please fill the field
                    </p>
                  )}
                </div>

                <div className='col-span-6 sm:col-span-3'>
                  <label
                    htmlFor='postal-code'
                    className='block text-sm font-medium text-gray-700'>
                    ZIP code/ Postal code
                    <span className='text-red-600'>&#42;</span>
                  </label>
                  <input
                    type={"text"}
                    value={schoolInfo?.pincode}
                    name='pincode'
                    onChange={handelChange}
                    placeholder='Enter ZIP code/ Postal code'
                    autoComplete='postal-code'
                    className='mt-1 block w-full rounded-md border-gray-300 shadow-sm  sm:text-sm px-2 py-2 outline-none border'
                  />
                  {schoolInfo?.pincode === "" && formSubmit && (
                    <p className='text-sm text-red-500'>
                      Please fill the field
                    </p>
                  )}
                </div>
              </div>
              <div className=' pt-5 text-right '>
                <button
                  onClick={() => handelRegistration()}
                  type='submit'
                  className='inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 w-full mb-2'>
                  Register
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SchoolRegistration;
