import { Dialog, Transition } from "@headlessui/react";
import { StatusCodes } from "http-status-codes";
import { moment } from "moment/moment";
import React, { useEffect, useState, Fragment, useContext } from "react";
import { toast } from "react-toastify";
import { object } from "yup";
import ActiveStatus from "../../common/ActiveStatus";
import AdminDeleteModel from "../../common/AdminDeleteModel";
import { AdminContext } from "../../common/DashboardWrapper";
import InActiveStatus from "../../common/InActiveStatus";
// import { Link } from "react-router-dom";
// import { admin } from "../../linksprovider";
import {
  adminCreatePlan,
  adminPlans,
  editAdminPlans,
  getAdminProfile,
  planDelete,
} from "../../services/auth.services";
import { ADMIN_USER_DATA, PLANS } from "../../utils/constant";
import { loaderContext } from "../global loader/global-loader-provider";
import Createplan from "./CreatePlan.jsx";

// import { ExclamationIcon, XIcon } from '@heroicons/react/outline'

function AdminPlan() {
  const [plans, setPlans] = useState([]);
  const [open, setOpen] = useState(false);
  const [editPlanInfo, setEditPlanInfo] = useState({
    title: "",
    description: "",
    duration: "",
    no_of_nft: "",
    price: "",
    plan_type: "",
    active: false,
  });

  const [createModalData, setCreateModalData] = useState({
    title: "",
    description: "",
    price: "",
    duration: "",
    no_of_nft: "",
    plan_type: "",
  });
  const [submitType, setSubmitType] = useState(false);
  const [editSubmitType, setEditSubmitType] = useState(false);
  const [currency, setCurrency] = useState("");

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);

  //importing value from useContext
  const { loaderDispatch } = useContext(loaderContext);
  const { setAdminActiveTab, setCreateModalOpen, createModalOpen } =
    useContext(AdminContext);

  //setting header and calling API on page load
  useEffect(() => {
    setAdminActiveTab(PLANS);
    showAdminPlans();
  }, []);

  // Show admin plans.
  const showAdminPlans = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let result = await adminPlans();

      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        let data = result?.data?.data;
        setPlans(data);
        setCurrency(data[0].currency);
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Updates the plan.
  const editSubscriptionDetails = async () => {
    setEditSubmitType(true);
    loaderDispatch({
      isLoading: true,
    });
    let jsonData = {};
    switch (parseInt(editPlanInfo?.plan_type)) {
      case 1:
        jsonData = {
          status: editPlanInfo.active ? 1 : 2,
          duration: editPlanInfo.duration,
          no_of_nft: editPlanInfo.no_of_nft,
        };

        break;
      case 2:
        jsonData = {
          title: editPlanInfo.title,
          description: editPlanInfo.description,
          price: editPlanInfo.price,
          status: editPlanInfo.active ? 1 : 2,
          plan_type: editPlanInfo.plan_type,
          duration: editPlanInfo.duration,
          no_of_nft: editPlanInfo.no_of_nft,
        };
        break;
      case 3:
        jsonData = {
          title: editPlanInfo.title,
          description: editPlanInfo.description,
          price: editPlanInfo.price,
          status: editPlanInfo.active ? 1 : 2,
          plan_type: editPlanInfo.plan_type,
          no_of_nft: editPlanInfo.no_of_nft,
        };
        break;
      default:
        break;
    }
    loaderDispatch({
      isLoading: false,
    });
    let checkValidValue = [];
    for (const key in jsonData) {
      if (jsonData[key] === "") {
        break;
      } else {
        checkValidValue.push(true);
      }
    }

    if (jsonData?.duration?.length > 4) {
      checkValidValue.push(true);
    }

    if (checkValidValue.length === Object.keys(jsonData).length) {
      let result = await editAdminPlans(jsonData, editPlanInfo.id);

      if (result?.data?.status === StatusCodes.OK) {
        showAdminPlans();
        toast(result?.data?.message, {
          type: "success",
        });
        setOpen(false);
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    }
  };
  //setting initial values for edit input fields
  const openEditModal = async (plansDetails) => {
    setOpen(true);
    setEditPlanInfo({
      title: plansDetails?.title ? plansDetails?.title : "",
      description: plansDetails?.description ? plansDetails?.description : "",
      price: plansDetails?.price ? plansDetails?.price : "",
      active: plansDetails?.status === 1 ? true : false,
      id: plansDetails?.id ? plansDetails?.id : "",
      plan_type: plansDetails?.plan_type ? plansDetails?.plan_type : "",
      duration: plansDetails?.duration ? plansDetails?.duration : "",
      no_of_nft: plansDetails?.no_of_nft ? plansDetails?.no_of_nft : "",
    });
  };

  // Adds a new plan.
  const addPlan = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let body = {};
      if (createModalData.plan_type === "2") {
        body = {
          title: createModalData?.title,
          description: createModalData?.description,
          price: createModalData?.price,
          duration: createModalData?.duration,
          plan_type: createModalData?.plan_type,
          no_of_nft: createModalData?.no_of_nft,
          status: 1,
        };
      } else if (createModalData.plan_type === "3") {
        body = {
          title: createModalData?.title,
          description: createModalData?.description,
          price: createModalData?.price,
          no_of_nft: createModalData?.no_of_nft,
          plan_type: createModalData?.plan_type,
          status: 1,
        };
      }

      let checkLength = [];
      let checkEmptyValue = Object.values(body);

      for (let index = 0; index < checkEmptyValue.length; index++) {
        if (checkEmptyValue[index] === "") {
          break;
        } else {
          checkLength.push(true);
        }
      }
      loaderDispatch({
        isLoading: false,
      });

      if (body?.duration?.toString()?.length > 4) {
        checkLength.push(true);
      }

      if (
        checkLength.length === checkEmptyValue.length &&
        Object.keys(body).length > 0
      ) {
        let result = await adminCreatePlan(body);

        if (result?.data?.status === StatusCodes.OK) {
          toast(result?.data?.message, {
            type: "success",
          });
          setCreateModalOpen(false);
          showAdminPlans();
          setSubmitType(false);
          for (const key in createModalData) {
            setCreateModalData((preVal) => {
              return { ...preVal, [key]: "" };
            });
          }
        } else {
          toast(result?.data?.message, {
            type: "error",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Deletes a plan
  const deletePlan = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });

      let result = await planDelete(deleteId);
      loaderDispatch({
        isLoading: false,
      });

      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
        });
        setOpenDeleteModal(false);
        showAdminPlans();
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  //regex for alphabets and space
  const alphaSpace = /^[A-Za-z\s]*$/;
  //checking condition
  const checkAlphaSpace = (value) => {
    return value.match(alphaSpace);
  };
  //checking positive value
  const checkPricePositive = (value) => {
    return `${value}`.match(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/);
  };
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:flex-col sm:align-center">
          <h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">
            Pricing Plans
          </h1>
        </div>
        <div className="mt-12 space-y-1 sm:mt-8 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4">
          {plans?.map((planItem, idx) => (
            <div
              key={idx}
              className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200"
            >
              <div className="p-6">
                <div className="flex justify-end">
                  {planItem?.status === 1 ? (
                    <ActiveStatus message={"Active"} />
                  ) : (
                    <InActiveStatus message={"Inactive"} />
                  )}
                </div>
                <p className="text-sm text-gray-500">
                  {planItem?.plan_type === 1 && `Free`}
                  {planItem?.plan_type === 2 && `Paid`}
                  {planItem?.plan_type === 3 && `Topup`}
                </p>

                <h2 className="text-lg leading-6 font-medium text-gray-900 break-words">
                  {planItem?.title?.toUpperCase()}
                </h2>
                <p className="mt-8">
                  <span className="text-4xl font-extrabold text-gray-900">
                    {planItem?.currency}
                    {planItem?.price}
                  </span>{" "}
                </p>
                <p className="text-sm text-gray-500">
                  {planItem?.plan_type != 3
                    ? `For ${planItem?.duration} months`
                    : `For ${planItem?.no_of_nft} NFT`}
                </p>
              </div>
              <div className="pt-6 pb-8 px-6 divide-y">
                <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
                  What's included
                </h3>
                <ul className="mt-6 space-y-1 text-sm text text-gray-500">
                  <li>{planItem?.description}</li>
                  <li>
                    Created on: {planItem?.createdAt ? planItem?.createdAt : ""}
                  </li>
                  {planItem.plan_type != 3 && (
                    <li>Number of NFT {planItem?.no_of_nft}</li>
                  )}
                </ul>

                <button
                  onClick={() => openEditModal(planItem)}
                  className="mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                >
                  Edit
                </button>

                {planItem.plan_type != 1 && (
                  <button
                    onClick={() => {
                      setOpenDeleteModal(true);
                      setDeleteId(planItem?.id);
                    }}
                    className="mt-2 block w-full bg-red-500 border border-red-500 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-red-600"
                  >
                    Delete
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Edit modal */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={open}
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  {editPlanInfo.plan_type != 1 && (
                    <>
                      <label className="block text-sm font-medium text-gray-700">
                        Title<span className="text-red-600">&#42;</span>
                      </label>
                      <input
                        type={"text"}
                        value={editPlanInfo.title}
                        onChange={(e) =>
                          setEditPlanInfo((preVal) => {
                            if (
                              e?.target?.value?.trim()?.length > 0 ||
                              e?.target?.value === ""
                            ) {
                              if (checkAlphaSpace(e?.target?.value)) {
                                return {
                                  ...preVal,
                                  title: e.target.value,
                                };
                              } else {
                                return {
                                  ...preVal,
                                  title: editPlanInfo.title,
                                };
                              }
                            } else {
                              return {
                                ...preVal,
                                title: "",
                              };
                            }
                          })
                        }
                        autoComplete="cc-given-name"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                      />
                      {editPlanInfo.title === "" && editSubmitType && (
                        <p className="text-sm text-red-500">
                          Please fill the field
                        </p>
                      )}
                    </>
                  )}
                  {editPlanInfo.plan_type != 1 && (
                    <>
                      <label className="block mt-2 text-sm font-medium text-gray-700">
                        Description<span className="text-red-600">&#42;</span>
                      </label>
                      <input
                        type={"text"}
                        value={editPlanInfo.description}
                        onChange={(e) =>
                          setEditPlanInfo((preVal) => {
                            if (
                              e?.target?.value?.trim()?.length > 0 ||
                              e?.target?.value === ""
                            ) {
                              return {
                                ...preVal,
                                description: e.target.value,
                              };
                            } else {
                              return {
                                ...preVal,
                                description: editPlanInfo.description,
                              };
                            }
                          })
                        }
                        autoComplete="cc-given-name"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                      />
                      {editPlanInfo.description === "" && editSubmitType && (
                        <p className="text-sm text-red-500">
                          Please fill the field
                        </p>
                      )}
                    </>
                  )}
                  {editPlanInfo.plan_type != 1 && (
                    <>
                      <label className="block mt-2 text-sm font-medium text-gray-700">
                        Plan type<span className="text-red-600">&#42;</span>
                      </label>
                      <select
                        className=' className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 
                bg-white focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm'
                        onChange={(e) =>
                          setEditPlanInfo((preVal) => {
                            return {
                              ...preVal,
                              plan_type: e.target.value,
                            };
                          })
                        }
                      >
                        <option value="">Select Plan type</option>
                        <option
                          value="2"
                          selected={editPlanInfo?.plan_type == 2 ? true : false}
                        >
                          Paid
                        </option>
                        <option
                          value="3"
                          selected={editPlanInfo?.plan_type == 3 ? true : false}
                        >
                          Topup
                        </option>
                      </select>
                      {editPlanInfo.plan_type === "" && editSubmitType && (
                        <p className="text-sm text-red-500">
                          Please select plan type
                        </p>
                      )}
                    </>
                  )}
                  {editPlanInfo.plan_type != 1 && (
                    <>
                      <label className="mt-2 block text-sm font-medium text-gray-700">
                        {`Price (${currency})`}
                        <span className="text-red-600">&#42;</span>
                      </label>
                      <input
                        type={"text"}
                        value={editPlanInfo.price}
                        maxLength={7}
                        minLength={1}
                        onChange={(e) =>
                          setEditPlanInfo((preVal) => {
                            if (
                              (checkPricePositive(e.target.value) &&
                                !isNaN(parseInt(e.target.value))) ||
                              e.target.value.toString() === ""
                            ) {
                              if (parseInt(e.target.value) !== 0) {
                                return { ...preVal, price: e.target.value };
                              } else {
                                return { ...preVal, price: editPlanInfo.price };
                              }
                            } else {
                              return { ...preVal, price: editPlanInfo.price };
                            }
                          })
                        }
                        autoComplete="cc-given-name"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                      />
                      {editPlanInfo.price === "" && editSubmitType && (
                        <p className="text-sm text-red-500">
                          Please select plan type
                        </p>
                      )}
                    </>
                  )}
                  {editPlanInfo.plan_type != 3 && (
                    <>
                      <label className="mt-2 block text-sm font-medium text-gray-700">
                        Duration(In months)
                        <span className="text-red-600">&#42;</span>
                      </label>
                      <input
                        type={"text"}
                        maxLength={5}
                        minLength={1}
                        value={editPlanInfo.duration}
                        onChange={(e) =>
                          setEditPlanInfo((preVal) => {
                            if (
                              (checkPricePositive(e.target.value) &&
                                !isNaN(parseInt(e.target.value))) ||
                              e.target.value.toString() === ""
                            ) {
                              if (parseInt(e.target.value) !== 0) {
                                return { ...preVal, duration: e.target.value };
                              } else {
                                return {
                                  ...preVal,
                                  duration: editPlanInfo.duration,
                                };
                              }
                            } else {
                              return {
                                ...preVal,
                                duration: editPlanInfo.duration,
                              };
                            }
                          })
                        }
                        autoComplete="cc-given-name"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                      />
                      {editPlanInfo.duration === "" && editSubmitType && (
                        <p className="text-sm text-red-500">
                          Please fill the field
                        </p>
                      )}
                      {editPlanInfo.duration.toString().length > 4 &&
                        editSubmitType && (
                          <p className="text-sm text-red-500">
                            Please Enter valid months
                          </p>
                        )}
                    </>
                  )}
                  {
                    <>
                      <label className="mt-2 block text-sm font-medium text-gray-700">
                        Number of NFT<span className="text-red-600">&#42;</span>
                      </label>
                      <input
                        type={"text"}
                        value={editPlanInfo.no_of_nft}
                        maxLength={5}
                        minLength={1}
                        onChange={(e) =>
                          setEditPlanInfo((preVal) => {
                            if (
                              (checkPricePositive(e.target.value) &&
                                !isNaN(parseInt(e.target.value))) ||
                              e.target.value.toString() === ""
                            ) {
                              if (parseInt(e.target.value) !== 0) {
                                return { ...preVal, no_of_nft: e.target.value };
                              } else {
                                return {
                                  ...preVal,
                                  no_of_nft: editPlanInfo.no_of_nft,
                                };
                              }
                            } else {
                              return {
                                ...preVal,
                                no_of_nft: editPlanInfo.no_of_nft,
                              };
                            }
                          })
                        }
                        autoComplete="cc-given-name"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                      />
                      {editPlanInfo.no_of_nft === "" && editSubmitType && (
                        <p className="text-sm text-red-500">
                          Please fill the field
                        </p>
                      )}
                    </>
                  }
                  <label className="block text-sm font-medium text-gray-700 mt-3">
                    Active<span className="text-red-600">&#42;</span>
                    <label
                      htmlFor="default-toggle"
                      className="inline-flex relative items-center cursor-pointer align-middle ml-5"
                    >
                      <input
                        type="checkbox"
                        defaultChecked={editPlanInfo.active}
                        onChange={() =>
                          setEditPlanInfo((preVal) => {
                            return { ...preVal, active: !editPlanInfo.active };
                          })
                        }
                        id="default-toggle"
                        className="sr-only peer"
                      />
                      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </label>
                  <button
                    onClick={() => editSubscriptionDetails()}
                    className="mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                  >
                    Save
                  </button>
                  <button
                    onClick={() => {
                      setOpen(false);
                      setEditSubmitType(false);
                      for (const key in editPlanInfo) {
                        if (key === "active") {
                          setEditPlanInfo((preVal) => {
                            return { ...preVal, [key]: false };
                          });
                        } else {
                          setEditPlanInfo((preVal) => {
                            return { ...preVal, [key]: "" };
                          });
                        }
                      }
                    }}
                    className="mt-2 block w-full bg-white border border-white rounded-md py-2 text-sm font-semibold shadow-md text-gray-700 text-center "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Createplan
        open={createModalOpen}
        setOpen={setCreateModalOpen}
        addInfo={createModalData}
        setAddinfo={setCreateModalData}
        submitFunction={addPlan}
        submitType={submitType}
        setSubmitType={setSubmitType}
        currency={currency}
      />

      <AdminDeleteModel
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        deleteData={deletePlan}
        header={"Delete Plan"}
      />
    </div>
  );
}

export default AdminPlan;
