import React from "react";
import styled from "styled-components";
import { Dialog, Transition } from "@headlessui/react";
import { GrClose } from "react-icons/gr";
import { SiteModal, SiteForm } from "../../../common/website/HOC.jsx";
import { space } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";

import HeadingFive from "../../../common/website/HeadingFive.jsx";
import Paragraph from "../../../common/website/Paragraph.jsx";
import Button from "../../../common/website/Button.jsx";
import { useNavigate } from "react-router-dom";
import { website } from "../../../linksprovider.js";

const LogoutModalRow = styled.div`
  .modal_inner {
    max-width: 400px;
    text-align: center;
    .modal_boday {
      padding: 1rem;
    }
    .modal_close {
      top: -8px;
    }
    .description {
      max-width: 624px;
      margin: 0 auto ${space.space16};
    }
    .btn_group {
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: ${space.space8};
    }
  }
  @media ${device.mobileM} {
    .modal_inner {
      .modal_boday {
        padding: 1.875rem;
      }
      .modal_close {
        top: -13px;
      }
      .description {
        margin: 0 auto ${space.space30};
      }
    }
  }
`;

function LogoutModalModal({ openDeleteModal, setDeleteModal }) {
  let navigate = useNavigate();

  const handelLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    // setUserData({});

    navigate(website.studentLogin);
  };
  return (
    <Transition.Root show={openDeleteModal}>
      <Dialog as='div' static open={openDeleteModal} onClose={setDeleteModal}>
        <LogoutModalRow>
          <SiteModal>
            <Transition.Child
              className='h-screen'
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
              <div className='modal_inner'>
                <div className='modal_boday'>
                  <div className='model_top'>
                    <HeadingFive cname='title' h5Data={"Logout"} />
                    <span
                      className='modal_close'
                      onClick={() => setDeleteModal(false)}>
                      {" "}
                      <GrClose />
                    </span>
                  </div>
                  <div className='modal_content'>
                    <Paragraph
                      cname='description'
                      pData={"Are you sure want to Logout?"}
                    />
                    <SiteForm as='div' className='btn_group'>
                      <Button
                        as='button'
                        cname='gradient convert_btn'
                        buttonData={"Logout"}
                        onClick={handelLogout}
                      />
                      <Button
                        as='button'
                        cname='outline convert_btn'
                        buttonData={"Cancel"}
                        onClick={() => setDeleteModal(false)}
                      />
                    </SiteForm>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </SiteModal>
        </LogoutModalRow>
      </Dialog>
    </Transition.Root>
  );
}
export default LogoutModalModal;
