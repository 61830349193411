import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { DashboardBody } from "../../../common/website/HOC.jsx";
import { device } from "../../../common/website/Devices";
import { colors, space } from "../../../common/website/Variables";
import ProfileHeader from "../../../components/website/ProfileHeader/ProfileHeader.jsx";
import MySubscriptionPlan from "./MySubscriptionPlan.jsx";
import EditProfile from "./EditProfile.jsx";
import MetaMaskWallet from "./MetaMaskWallet.jsx";

import UserProfileImage from "../../../images/website/NFT_image.png";
import { useContext } from "react";
import { PostContext } from "../../../components/website/header/MainHeader.jsx";
import { getSubscriptionDetail } from "../../../services/auth.services.js";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import { ADMIN_USER_DATA } from "../../../utils/constant.js";
import { loaderContext } from "../../../components/global loader/global-loader-provider.js";
import blankProfile from "../../../images/website/dummy_user_profile.svg";
import VerificationId from "./VerificationId.jsx";

const SettingsRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  .profile_top {
    padding: ${space.space20};
    .other_profile_text {
      display: block;
      font-size: 1rem;
    }
    .NFT_Balance.other_profile_year {
      display: flex;
    }
    &::before {
      top: -80px;
      left: -36px;
      height: 155%;
    }
    .NFT_Balance {
      /* display: block; */
    }
    .member_count {
      display: none !important;
    }
    .profile_image {
      bottom: -6px;
    }
  }
  .club_group {
    display: none;
  }
  h3,
  h4,
  p {
    position: static;
  }
  .sidebar_right {
    position: sticky;
    top: 130px;
    display: none;
  }
  .sceen_bottom {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
  .sceen_main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-wrap: wrap;
  }
  .create_post {
    margin-top: 0;
  }
  .tab_list {
    display: flex;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    margin-bottom: ${space.space24};
    position: relative;
    &::before {
      content: "";
      position: absolute;
      background: ${colors.lightwhiteborder};
      width: 100%;
      height: 1px;
      bottom: 1px;
    }
    .tab_item {
      font-size: 0.875rem;
      line-height: 1.5rem;
      font-weight: 600;
      padding-bottom: ${space.space8};
      margin-right: ${space.space20};
      color: ${colors.lightgrey2};
      cursor: pointer;
      position: relative;
      transition: all 0.4s;
      &:last-child {
        margin-right: 0;
      }
      &:first-child {
        margin-left: 10px;
      }
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        background: ${colors.primary};
        width: 0%;
        height: 3px;
        border-radius: ${space.space20};
        transition: all 0.4s;
      }
      &:hover,
      &.active {
        color: ${colors.white};
        transition: all 0.4s;
        &::before {
          width: 100%;
          transition: all 0.4s;
        }
      }
    }
  }
  .dash_body {
    width: 100%;
  }

  @media ${device.mobileM} {
    .tab_list {
      margin-top: ${space.space10};
    }
    .profile_top {
      padding: ${space.space30};
      .profile_image {
        bottom: -54px;
      }
    }
  }
  @media ${device.tablet} {
    width: 75%;
    .profile_header {
      padding-left: ${space.space20};
    }
    .sidebar_right {
      display: block;
    }
    .mobile_hidden {
      display: block;
    }
    .club_group {
      flex-direction: column;
      align-items: flex-start;
      .count {
        font-size: 0.75rem;
        line-height: 19px;
      }
    }
    .dash_body {
      padding-left: ${space.space20};
    }
  }
  @media ${device.laptop} {
    width: 77%;
    .tab_list {
      margin-top: 0;
      .tab_item {
        font-size: 1rem;
        margin-right: ${space.space30};
      }
    }
    .tab_list {
      margin-bottom: ${space.space40};
    }
    .sidebar_right {
      top: 188px;
    }
    .club_group {
      flex-direction: row;
      align-items: center;
    }
    .profile_top {
      padding: ${space.space60} ${space.space40} ${space.space40};
      .profile_image {
        bottom: -70px;
      }
    }
  }
  @media ${device.laptopL} {
    width: 80%;
    .dash_body {
      padding-left: ${space.space40};
    }
    .club_group {
      .count {
        font-size: 1rem;
        line-height: 1.625rem;
      }
    }
    .profile_header {
      padding-left: ${space.space40};
    }
    .tab_list {
      .tab_item {
        padding-bottom: ${space.space12};
        font-size: 1.125rem;
        margin-right: ${space.space50};
      }
    }
  }
`;
function Settings() {
  // Initial value of state to get first switch case my_subscription_plan.
  const [game, setGame] = useState("my_subscription_plan");

  // initialize use state to false.
  const [metaMaskShow, setMetaMaskShow] = useState(false);

  // Get the ADMIN_USER_DATA from the session storage or localStorage.
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }

  // Sets the userInfo in the post context.
  const { userInfo, setUserInfo, handelLogout } = useContext(PostContext);

  // Sets the loaderDispatch to use the loader.
  const { loaderDispatch } = useContext(loaderContext);

  // Handle effects for the game.

  // Sets the game to the given state when the user clicks.
  const handleClick = (gameState) => {
    setGame(gameState);
  };

  // Loads the user s subscription details.
  const getinfo = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let user = {
        email: userData.email,
      };
      let subDetails = await getSubscriptionDetail(user);
      loaderDispatch({
        isLoading: false,
      });
      if (subDetails?.data?.status === 410) {
        handelLogout();
      }

      if (subDetails?.data?.status === StatusCodes.OK) {
        let StudentInfo = subDetails?.data?.data;

        setUserInfo(StudentInfo);
        handelAgeVerification(StudentInfo);
      } else {
        toast(subDetails?.data?.message, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Handle handel age verification.
  const handelAgeVerification = (StudentInfo) => {
    let date = new Date();
    let date2 = new Date(StudentInfo?.dob);
    let birthYear = date2.getFullYear();
    let birthMonth = date2.getMonth();
    let birthDay = date2.getDate();
    let currentYear = date.getFullYear();
    let currentMonth = date.getMonth();
    let currentDate = date.getDate();
    const date1 = new Date(`${birthMonth + 1}/${birthDay}/${birthYear}`);
    const date3 = new Date(`${currentMonth + 1}/${currentDate}/${currentYear}`);
    const diffTime = Math.abs(date3 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    console.log(`${birthMonth + 1}/${birthDay}/${birthYear}`);
    if (diffDays - 4 >= 6570) {
      setMetaMaskShow(true);
    } else {
      setMetaMaskShow(false);
    }
  };

  return (
    <>
      <SettingsRow>
        <div className="sceen_main">
          <ProfileHeader
            cname="profile_header"
            ClubProfileImage={userInfo?.filtered_image || blankProfile}
            Name={userInfo?.student_name}
          />
          <div className="sceen_bottom">
            <DashboardBody className="dash_body">
              {(() => {
                switch (game) {
                  case "my_subscription_plan":
                    return (
                      <MySubscriptionPlan
                        handleClick={handleClick}
                        getinfo={getinfo}
                        metaMaskShow={metaMaskShow}
                      />
                    );
                  case "edit_profile":
                    return (
                      <EditProfile
                        handleClick={handleClick}
                        getinfo={getinfo}
                        metaMaskShow={metaMaskShow}
                      />
                    );
                  case "meta_mask_wallet":
                    return (
                      <MetaMaskWallet
                        handleClick={handleClick}
                        getinfo={getinfo}
                        metaMaskShow={metaMaskShow}
                      />
                    );
                  case "id":
                    return (
                      <VerificationId
                        handleClick={handleClick}
                        getinfo={getinfo}
                        metaMaskShow={metaMaskShow}
                      />
                    );
                  default:
                    return null;
                }
              })()}
            </DashboardBody>
          </div>
        </div>
      </SettingsRow>
    </>
  );
}

export default Settings;
