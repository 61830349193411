import React, { useContext, useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import { admin } from "../../linksprovider";
import { getAdminProfile } from "../../services/auth.services";
import { ADMIN_USER_DATA, PROFILE } from "../../utils/constant";
import { StatusCodes } from "http-status-codes";
import { loaderContext } from "../global loader/global-loader-provider";
import { AppContext } from "../../App";
import { AdminContext } from "../../common/DashboardWrapper";

function AdminProfile() {
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }

  const { getData, adminData, setAdminActiveTab } = useContext(AdminContext);

  let count = 0;
  useEffect(() => {
    if (count === 0) {
      getData();
    }
    count++;
    setAdminActiveTab(PROFILE);
  }, []);

  // const getData = async () => {
  //   loaderDispatch({
  //     isLoading: true,
  //   });
  //   let result = await getAdminProfile(userData.id);

  //   loaderDispatch({
  //     isLoading: false,
  //   });

  //   if (result?.data?.status === StatusCodes.OK) {
  //     setAdminData(result?.data?.data);
  //   }
  // };

  return (
    <div>
      <div className='min-h-screen bg-gray-100 '>
        <div className='max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8'>
          <div className='flex items-center space-x-5 mt-2'>
            <div className='flex-shrink-0'>
              <div className='relative'>
                <div className='h-16 w-16 rounded-full'>
                  <img
                    className='h-16 w-16 rounded-full'
                    src={
                      adminData?.profile_picture
                        ? adminData?.profile_picture
                        : "https://t3.ftcdn.net/jpg/01/09/00/64/360_F_109006426_388PagqielgjFTAMgW59jRaDmPJvSBUL.jpg"
                    }
                    alt=''
                  />
                </div>
                <span
                  className='absolute inset-0 shadow-inner rounded-full'
                  aria-hidden='true'
                />
              </div>
            </div>
            <div>
              <h1 className='text-2xl font-bold text-gray-900'>
                {adminData.first_name}
              </h1>
            </div>
          </div>
        </div>
        <div className='mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3'>
          <div className='space-y-6 lg:col-start-1 lg:col-span-2'>
            <section aria-labelledby='applicant-information-title'>
              <div className='bg-white shadow sm:rounded-lg'>
                <div className='px-4 py-5 sm:px-6'>
                  {/* <h2
                    id='applicant-information-title'
                    className='text-lg leading-6 font-medium text-gray-900'>
                    Profile
                  </h2> */}
                  <h2 className='mt-1 max-w-2xl text-gray-500'>
                    Admin details
                  </h2>
                </div>
                <div className='border-t border-gray-200 px-4 py-5 sm:px-6'>
                  <dl className='grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2'>
                    <div className='sm:col-span-1'>
                      <dt className='text-sm font-medium text-gray-500'>
                        Full Name
                      </dt>
                      <dd className='mt-1 text-sm text-gray-900'>
                        {adminData.first_name} {adminData.last_name}
                      </dd>
                    </div>
                    <div className='sm:col-span-1'>
                      <dt className='text-sm font-medium text-gray-500'>
                        Email address
                      </dt>
                      <dd className='mt-1 text-sm text-gray-900'>
                        {adminData.email}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminProfile;
