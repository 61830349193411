import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import { getCountryLists } from "../services/auth.services";

export const getCountryList = async () => {
  try {
    let result = await getCountryLists();

    if (result?.data?.status === StatusCodes.OK) {
      return result?.data?.data;
    } else {
      // toast("error in getting country details", {
      //   type: "error",
      // });
    }
  } catch (error) {
    return error;
  }
};
