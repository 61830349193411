import React, { useState } from "react";
import { FaSort } from "react-icons/fa";
import Tooltip from "@material-ui/core/Tooltip";
// import calculateAge from "calculate-age";

function YearBookListLayout({
  tableHeader,
  handleSorting,
  currentItems,
  // type,
}) {
  const [order, setOrder] = useState(true);

  const handleSortTable = (item, orders) => {
    handleSorting(item, orders);
  };

  const calculateAge = () => {};
  return (
    <div className='flex flex-col'>
      <div className='overflow-x-auto'>
        <div className='p-1.5  min-w-full inline-block align-middle'>
          <div className='overflow-hidden border rounded-lg'>
            <table className='min-w-full divide-y divide-gray-200'>
              <thead className='bg-gray-50'>
                <tr>
                  {tableHeader?.map((item, key) => {
                    return (
                      <th
                        key={key}
                        className='px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase'>
                        {item.sorting ? (
                          <span className='flex'>
                            {item.key}
                            <FaSort
                              className='cursor-pointer text-md'
                              onClick={() => {
                                handleSortTable(item.value, !order);
                                setOrder(!order);
                              }}
                            />
                          </span>
                        ) : (
                          <span className='flex float-left'>{item.key}</span>
                        )}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200'>
                {currentItems?.map((dataItem, dataKey) => {
                  return (
                    <tr key={dataKey}>
                      {tableHeader?.map((item, key) => {
                        if (item.key === "options") {
                          return (
                            <td
                              key={key}
                              className='float-left px-5 py-4 font-normal text-gray-600 whitespace-nowrap flex justify-between w-fit'>
                              <Tooltip title='Fraction'>
                                <span className='p-2'>
                                  <label className='block text-sm font-medium text-gray-700 mt-3 mb-3'>
                                    <label
                                      htmlFor={dataItem["id"]}
                                      className='inline-flex relative items-center cursor-pointer align-middle ml-5'>
                                      <input
                                        type='checkbox'
                                        id={dataItem["id"]}
                                        className='sr-only peer'
                                        defaultChecked={
                                          dataItem?.[item?.subValue]
                                        }
                                      />
                                      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                    </label>
                                  </label>
                                </span>
                              </Tooltip>
                            </td>
                          );
                        } else {
                          return (
                            <td
                              className='px-6 py-4 text-sm font-medium text-gray-600 whitespace-nowrap'
                              key={key}>
                              {dataItem?.[item?.value] &&
                              dataItem?.[item?.value]?.[item?.subValue]
                                ? dataItem?.[item?.value]?.[item?.subValue]
                                : dataItem?.[item?.value] &&
                                  dataItem?.[item?.value]?.toString()?.length >
                                    0
                                ? dataItem?.[item?.value]
                                : "--"}
                            </td>
                          );
                        }
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default YearBookListLayout;
