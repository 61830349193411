import React, { useState } from "react";
import { FaSort } from "react-icons/fa";
import { AiFillEye } from "react-icons/ai";
import { DELETE, EDIT, NFT, UPDATE, VIEW } from "../utils/constant";
import { BiEditAlt } from "react-icons/bi";
import { MdDelete } from "react-icons/md";

import Tooltip from "@material-ui/core/Tooltip";
import ActiveStatus from "./ActiveStatus";
import InActiveStatus from "./InActiveStatus";

function ListLayoutEditIcon({
  handleOptions,
  tableHeader,
  handleSorting,
  currentItems,
  type,
  isHistory,
  categoryType,
}) {
  const [order, setOrder] = useState(true);

  const handleSortTable = (item, subItem, orders) => {
    console.log(item, subItem);
    if (subItem) {
      handleSorting(subItem, orders);
    } else {
      handleSorting(item, orders);
    }
  };

  const handeldataShow = (dataItem, item) => {
    if (dataItem?.[item?.value] && !item.subValue && !item.subSubValue) {
      return dataItem?.[item?.value];
    } else if (
      dataItem?.[item?.value] &&
      dataItem?.[item?.value]?.[item?.subValue] &&
      !item.subSubValue
    ) {
      return dataItem?.[item?.value]?.[item?.subValue];
    } else if (
      item?.value &&
      item.subValue &&
      item.subSubValue &&
      Array?.isArray(dataItem?.[item?.value])
    ) {
      if (
        dataItem?.[item?.value][item?.positionInArray]?.[item?.subValue]?.[
          item?.subSubValue
        ]
      ) {
        return dataItem?.[item?.value][item?.positionInArray]?.[
          item?.subValue
        ]?.[item?.subSubValue];
      } else {
        return "--";
      }
    } else {
      return "--";
    }
  };

  return (
    <div className='flex flex-col'>
      <div className='overflow-x-auto'>
        <div className='p-1.5  min-w-full inline-block align-middle'>
          <div className='overflow-hidden border rounded-lg'>
            <table className='min-w-full divide-y divide-gray-200'>
              <thead className='bg-gray-50'>
                <tr>
                  {tableHeader.map((item, key) => {
                    return (
                      <th
                        key={key}
                        className='px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase'>
                        {item.sorting ? (
                          <span className='flex'>
                            {item.key}
                            <FaSort
                              className='cursor-pointer text-md'
                              onClick={() => {
                                handleSortTable(
                                  item.value,
                                  item.subValue,
                                  !order
                                );
                                setOrder(!order);
                              }}
                            />
                          </span>
                        ) : (
                          <>
                            <span className='flex float-left'>{item.key}</span>
                          </>
                        )}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200'>
                {currentItems?.map((dataItem, dataKey) => {
                  if (dataItem.status === 1) {
                    dataItem.status = <ActiveStatus message={"Active"} />;
                  } else if (dataItem.status === 2) {
                    dataItem.status = <InActiveStatus message={"Inactive"} />;
                  }

                  return (
                    <tr key={dataKey}>
                      {tableHeader.map((item, key) => {
                        if (item.key === "options") {
                          return (
                            <td
                              key={key}
                              className='float-left px-5 py-4 font-normal text-gray-600 whitespace-nowrap flex justify-between w-fit'>
                              <>
                                {" "}
                                <Tooltip title='edit' arrow>
                                  <span className='p-2'>
                                    <BiEditAlt
                                      className='cursor-pointer'
                                      onClick={() =>
                                        handleOptions(EDIT, dataItem)
                                      }
                                    />
                                  </span>
                                </Tooltip>
                                <Tooltip title='delete' arrow>
                                  <span className='p-2'>
                                    <MdDelete
                                      className='cursor-pointer'
                                      onClick={() =>
                                        handleOptions(DELETE, dataItem)
                                      }
                                    />
                                  </span>
                                </Tooltip>
                              </>

                              {/* {type && (
                                <Tooltip title='school common post' arrow>
                                  <span className='p-2'>
                                    <Link
                                      to={`/admin/school/post/${dataItem.id}`}>
                                      <BsCardList className='cursor-pointer' />
                                    </Link>
                                  </span>
                                </Tooltip>
                              )} */}
                            </td>
                          );
                        } else if (item.key === "S.No.") {
                          return (
                            <td
                              className={
                                item?.value === "total_nft" || item?.clickable
                                  ? "px-6 py-4 text-sm font-medium text-gray-600 whitespace-nowrap cursor-pointer max-w-xs  truncate"
                                  : "px-6 py-4 text-sm font-medium text-gray-600 whitespace-nowrap max-w-xs truncate "
                              }
                              onClick={
                                item?.value === "total_nft" || item?.clickable
                                  ? () => handleOptions(NFT, dataItem)
                                  : null
                              }
                              key={key}>
                              {dataKey + 1}
                            </td>
                          );
                        } else {
                          return (
                            <td
                              className={
                                item?.value === "total_nft" || item?.clickable
                                  ? "px-6 py-4 text-sm font-medium text-gray-600 whitespace-nowrap cursor-pointer "
                                  : "px-6 py-4 text-sm font-medium text-gray-600 whitespace-nowrap "
                              }
                              onClick={
                                item?.value === "total_nft" || item?.clickable
                                  ? () => handleOptions(NFT, dataItem)
                                  : null
                              }
                              key={key}>
                              {/* {console.log(
                                dataItem[item?.value][item?.subValue]
                              )} */}
                              {handeldataShow(dataItem, item)}
                              {/* {dataItem?.[item?.value] &&
                              dataItem?.[item?.value]?.[item?.subValue]
                                ? dataItem?.[item?.value]?.[item?.subValue]
                                : dataItem?.[item?.value] &&
                                  dataItem?.[item?.value]?.toString()?.length >
                                    0
                                ? dataItem?.[item?.value]
                                : "--"} */}
                            </td>
                          );
                        }
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListLayoutEditIcon;
