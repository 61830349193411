import React from "react";
import styled from "styled-components";
import { colors, siteGradient, space } from "./Variables.js";

import { device } from "./Devices";
import Paragraph from "./Paragraph.jsx";
import blankImage from "../../images/website/dummy_user_profile.svg";

import WinnerImage1 from "../../images/website/image_winner1.png";
import WinnerImage2 from "../../images/website/image_winner2.png";
import WinnerImage3 from "../../images/website/image_winner3.png";

const VotingSelectorRow = styled.div`
  background: ${siteGradient.linearGradientBorder};
  border-radius: ${space.space16};
  box-shadow: ${siteGradient.cardlightboxshadow};
  backdrop-filter: blur(15px);
  padding: 1px;
  position: relative;
  height: 100%;
  .label {
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: ${colors.primary};
    text-align: center;
    background: ${colors.darkgrey};
    box-shadow: ${siteGradient.boxshadowWhite};
    border-radius: ${space.space50};
    padding: 3px ${space.space10};
    margin: 0 !important;
  }
  .select_inner {
    background: ${colors.body};
    padding: 36px ${space.space24} ${space.space24};
    border-radius: ${space.space16};
    margin: 0;
    height: 100%;
    .item {
      display: flex;
      align-items: center;
      grid-gap: ${space.space14};
      margin-bottom: ${space.space16};
      &:last-child {
        margin: 0;
      }
      img {
        width: 42px;
        height: 42px;
        object-fit: cover;
        border-radius: 100%;
        flex-shrink: 0;
      }
      p {
        margin: 0;
        font-weight: 600;
        &.top {
          font-size: 0.875rem;
          line-height: 1.063rem;
          margin-bottom: 4px;
          color: ${colors.white};
        }
        &.name {
          color: ${colors.primary};
        }
      }
    }
  }
  @media ${device.mobileL} {
    .select_inner {
      padding: 48px ${space.space24} ${space.space24};
      .item {
        img {
          width: 60px;
          height: 60px;
        }
      }
    }
    .label {
      top: -22px;
      font-size: 1rem;
      line-height: 1.625rem;
      padding: ${space.space10} ${space.space12};
    }
  }
  @media ${device.desktop} {
    .label {
      padding: ${space.space10} ${space.space20};
    }
  }
`;

function VotingSelector(props) {
  return (
    <>
      <VotingSelectorRow>
        <Paragraph cname='label' pData={props.headingData} />
        <ul className='select_inner'>
          {props?.winners?.length > 0 ? (
            props?.winners?.map((value, i) => {
              return (
                <li className='item' key={value?.id}>
                  <img
                    src={value?.User?.filtered_image || blankImage}
                    alt='winner1'
                  />
                  <div className='right'>
                    <p className='top'>
                      {`Winner ${i + 1}`} <span>|</span> {value?.votes} Votes
                    </p>
                    <Paragraph cname='name' pData={value?.User?.student_name} />
                  </div>
                </li>
              );
            })
          ) : (
            <li className='item'>
              <p>no winner found</p>
            </li>
          )}
        </ul>
      </VotingSelectorRow>
    </>
  );
}
export default VotingSelector;
