import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { colors, space } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";
import { SiteForm } from "../../../common/website/HOC.jsx";

import Label from "../../../common/website/Label.jsx";
import Button from "../../../common/website/Button.jsx";
import InfoBtn from "../../../images/website/icon_info_button.svg";
import plus from "../../../images/website/plus.svg";
import iconverify from "../../../images/website/icon_verify.svg";
import iconcross from "../../../images/website/icon_cross.svg";
import * as Yup from "yup";
import { PostContext } from "../../../components/website/header/MainHeader";
import { loaderContext } from "../../../components/global loader/global-loader-provider";
import {
  docsStudentUpload,
  getVerificationDocs,
  updateMetaMask,
} from "../../../services/auth.services";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import pdfIcons from "../../../images/website/pdfIcon.png";
import CommonModal from "../../../common/website/CommonPdfModal.jsx";
import imageCompression from "browser-image-compression";

const MetaMaskWalletRow = styled.div`
  .meta_mask {
    /* text-align: right; */
    margin-bottom: ${space.space24};
    .link {
      font-size: 0.875rem;
      padding: 0;
      margin-top: ${space.space14};
      &:hover {
        color: ${colors.secondary};
      }
    }
    label {
      text-align: start;
    }
  }

  .flexBox {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .img_box {
    width: 80px;
    height: 80px;
    display: flex;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    text-align: center;
    img {
      width: 24px;
    }
  }

  .infoBox {
    border: 1px solid rgb(112, 48, 140);
    padding: 15px 16px 15px 16px;
    width: 272px;
    border-radius: 16px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    box-shadow: 0px 0px 20px rgba(48, 223, 242, 0.3);
    img {
      position: relative;
      top: 5px;
    }
    span {
      text-align: left;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      margin-left: 10px;
    }
  }

  .errorBox {
    max-width: 20rem;
    border: 1px solid #b50001;
    border-radius: 8px;
    padding: 10px;
    color: #b50001;
    text-align: center;
    /* margin-top: 20px; */
    box-shadow: 0px 0px 20px rgba(48, 223, 242, 0.3);
  }

  .pendingBox {
    max-width: 20rem;
    border: 1px solid #ffe100;
    border-radius: 8px;
    padding: 10px;
    color: #ffe100;
    text-align: center;
    /* margin-top: 20px; */
    box-shadow: 0px 0px 20px rgba(48, 223, 242, 0.3);
  }

  .close-icon {
    position: absolute;
    z-index: 9;
    right: 0px;
    top: 5px;
    background: #30dff2;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .close-icon-img {
    position: absolute;
    z-index: 9;
    right: -10px;
    top: -6px;
    background: #30dff2;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .label_text {
    ul {
      display: flex;
      align-items: center;
      li {
        margin-right: 18px;
      }
    }
  }

  .close_icons {
    position: relative;
  }

  .label_text label {
    margin-bottom: 18px;
  }

  .tool_tip_body {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    .tooltip {
      position: relative;
      display: inline-block;
      border-bottom: 1px dotted black;
      cursor: pointer;
      margin-bottom: 0.5rem;
      &:hover {
        .tooltiptext {
          visibility: visible;
        }
      }
    }
    .tooltiptext {
      font-size: 0.875rem;
      line-height: 1.25rem;
      visibility: hidden;
      width: 116px;
      background-color: ${colors.primary};
      color: ${colors.secondary};
      text-align: center;
      border-radius: 6px;
      padding: 5px;
      position: absolute;
      z-index: 1;
      bottom: 150%;
      left: 50%;
      margin-left: -60px;
      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: ${colors.primary} transparent transparent transparent;
      }
    }
  }
  .btn_group {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: ${space.space16};
  }
  @media ${device.mobileM} {
    .meta_mask {
      .link {
        font-size: 1rem;
      }
    }
  }
  @media ${device.mobileL} {
    .meta_mask {
      margin-bottom: ${space.space30};
    }
  }
  @media ${device.tablet} {
    .meta_mask {
      margin-bottom: ${space.space50};
    }
  }
`;

function MetaMaskWallet(props) {
  const [fileTypeError, setFileTypeError] = useState(false);
  const [documentStatus, setDocumentStatus] = useState("");
  const [verificationDocument, setVerificationDocument] = useState({});
  const [openCommonModal, setOpenCommonModal] = useState(false);
  const [backupFileState, setbackupFileState] = useState({});
  const [submit, setSubmit] = useState(false);
  const [sizeLimit, setSizeLimit] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { userInfo } = useContext(PostContext);
  const { loaderDispatch } = useContext(loaderContext);
  let fileUploadRef = useRef(null);

  let sub = true;
  const imageRef = useRef();

  useEffect(() => {
    if (sub) {
      getDocs();
    }
    return () => (sub = false);
  }, [userInfo]);

  const handelFileUpload = (e) => {
    const file = e?.target?.files[0];
    setVerificationDocument({});

    if (file?.type?.includes("image")) {
      setFileTypeError(false);
      setSizeLimit(false);
      console.log("file?.type", file?.type);
      if (file?.type === "image/tiff") {
        setFileTypeError(true);
        setErrorMessage("Please upload standard format image file");
      } else {
        setFileTypeError(false);
        setVerificationDocument(file);
      }
    } else if (file?.type === "application/pdf") {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = function () {
        if (reader?.result?.includes("%PDF")) {
          setFileTypeError(false);
          setSubmit(false);

          setVerificationDocument(file);
        } else {
          setFileTypeError(true);
          setSizeLimit(false);
          setErrorMessage("Please valid PDF file");
        }
      };

      reader.onerror = function () {
        toast(reader?.error, {
          type: "error",
          theme: "dark",
        });
      };
    } else {
      setErrorMessage("Please upload PDF or image only");
      setFileTypeError(true);
      setSizeLimit(false);
    }
  };

  const handelSubmit = async () => {
    if (verificationDocument?.name) {
      setSizeLimit(false);
      if (verificationDocument?.type === "application/pdf") {
        let size = verificationDocument?.size / 1024 / 1024;
        if (size > 5) {
          console.log("sizepdf", size);
          setSizeLimit(true);
          setSubmit(true);
          return;
        }
      }

      try {
        loaderDispatch({
          isLoading: true,
        });

        let params = {
          school_id: userInfo?.User?.id,
          id: userInfo?.id,
        };

        let body = {
          doc: verificationDocument?.url
            ? verificationDocument?.url
            : verificationDocument,
        };

        if (verificationDocument?.type?.includes("image")) {
          body.doc = await handleImageUpload(body?.doc);
        }

        let formData = new FormData();

        formData.append("doc", body?.doc);
        let result = await docsStudentUpload(params, formData);
        loaderDispatch({
          isLoading: false,
        });

        if (result?.data?.status === StatusCodes.OK) {
          toast(result?.data?.message, {
            type: "success",
            theme: "dark",
          });
          // setVerificationDocument({});
          getDocs();
        } else {
          toast(result?.data?.message, {
            type: "error",
            theme: "dark",
          });
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      setSubmit(true);
    }
  };

  const handelFileChoose = () => {
    console.log("current", fileUploadRef);
    fileUploadRef?.current?.click();
  };

  // Handles canceling the metamask.
  const handelCancel = () => {
    setVerificationDocument({});
    setSubmit(false);
  };

  const getDocs = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let params = {
        id: userInfo?.id,
        user_type: 3,
      };

      let result = await getVerificationDocs(params);
      loaderDispatch({
        isLoading: false,
      });

      if (result?.data?.status === StatusCodes.OK) {
        let data = result?.data?.data;
        let name = data?.docName?.split("/");
        if (data?.docName) {
          setVerificationDocument({
            name: name[name?.length - 1],
            url: data?.docName,
          });
        }

        console.log("source", data);
        setDocumentStatus(data?.is_verified);
        setbackupFileState(data);
      } else {
        toast(result?.data?.message, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  async function handleImageUpload(imageFile) {
    let size = imageFile?.size / 1024 / 1024;
    if (size > 4) {
      const options = {
        maxSizeMB: 3,
        // maxWidthOrHeight: 1920,
        // alwaysKeepResolution: true,
      };
      try {
        const compressedFile = await imageCompression(imageFile, options);
        console.log("compressedFileSize", compressedFile.size / 1024 / 1024);
        return compressedFile;
      } catch (error) {
        console.log(error);
        loaderDispatch({
          isLoading: false,
        });
      }
    } else {
      return imageFile;
    }
  }

  console.log("verificationDocument", verificationDocument);

  const handelSource = () => {
    const reader = new FileReader();
    if (verificationDocument?.name.includes("pdf")) {
      return pdfIcons;
    } else if (verificationDocument?.url) {
      return verificationDocument?.url;
    } else if (!verificationDocument?.name?.includes("pdf")) {
      return URL.createObjectURL(verificationDocument);
    } else {
      return "";
    }
  };
  return (
    <>
      <MetaMaskWalletRow className='tab_body'>
        <ul className='tab_list'>
          <li
            className='tab_item'
            onClick={() => props.handleClick("my_subscription_plan")}>
            My Subscription Plan
          </li>
          <li
            className='tab_item'
            onClick={() => props.handleClick("edit_profile")}>
            Edit Profile
          </li>
          {props.metaMaskShow && (
            <li
              className='tab_item'
              onClick={() => props.handleClick("meta_mask_wallet")}>
              Meta Mask Wallet
            </li>
          )}
          <li className='tab_item active'>ID</li>
        </ul>

        <SiteForm as='div' className='profile_form'>
          <div className='md:gap-7 lg:grid-cols-2 items-center form_label_group'>
            <div className='form_group flexBox meta_mask'>
              <div className='label_text'>
                <Label labelData={"Upload ID Proof"} />
                <ul>
                  {verificationDocument?.name && (
                    <li className='close_icons'>
                      <>
                        {documentStatus !== 1 && (
                          <span
                            className={
                              verificationDocument?.name?.includes("pdf")
                                ? "close-icon"
                                : "close-icon-img"
                            }
                            onClick={handelCancel}>
                            <img
                              src={iconcross}
                              alt='profile_image'
                              className='profile_image cursor-pointer'
                            />
                          </span>
                        )}

                        <img
                          ref={imageRef}
                          src={handelSource()}
                          className='cursor-pointer w-16 object-fill'
                          alt=''
                          srcset=''
                          onClick={() => setOpenCommonModal(true)}
                        />
                      </>
                    </li>
                  )}
                  {documentStatus !== 1 && !verificationDocument?.name && (
                    <li>
                      <span
                        className='img_box cursor-pointer'
                        onClick={handelFileChoose}>
                        <img
                          src={plus}
                          alt='profile_image'
                          className='profile_image '
                        />
                      </span>
                    </li>
                  )}
                </ul>
                {verificationDocument?.name && (
                  <div className='max-w-md break-words'>
                    <p className='text-xs text-cyan-400 mt-1 '>
                      {verificationDocument?.name}
                    </p>
                  </div>
                )}
                {fileTypeError && (
                  <div className='text-xs mt-1  text-red-500'>
                    <p>{errorMessage}</p>
                  </div>
                )}

                {submit && sizeLimit && verificationDocument?.name && (
                  <div className='text-xs mt-1  text-red-500'>
                    <p>Upload PDF upto 5mb</p>
                  </div>
                )}

                <input
                  type='file'
                  name='file'
                  value=''
                  ref={fileUploadRef}
                  placeholder='Enter your first name'
                  accept='.pdf, image/*'
                  autoComplete='off'
                  className='hidden'
                  onChange={handelFileUpload}
                />
              </div>
              <div className='yourIdentification'>
                {documentStatus === 1 && (
                  <div className='infoBox'>
                    <img
                      src={iconverify}
                      alt='profile_image'
                      className='profile_image'
                    />
                    <span>
                      Your proof of identification has been authenticated.
                    </span>
                  </div>
                )}
                {documentStatus === 2 && (
                  <div className='errorBox'>
                    <span>
                      Your identification is rejected, you can upload new
                      identity to verify!
                    </span>
                  </div>
                )}
                {documentStatus === 3 && (
                  <div className='pendingBox'>
                    <span>Your identification is yet to verify.</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          {documentStatus !== 1 && (
            <div className='btn_group'>
              <Button
                tag='button'
                type='submit'
                cname='gradient btn'
                buttonData={"Update"}
                onClick={handelSubmit}
              />
            </div>
          )}
        </SiteForm>
      </MetaMaskWalletRow>
      <CommonModal
        header={"Verification ID"}
        openCommonModal={openCommonModal}
        setOpenCommonModal={setOpenCommonModal}
        pdf={verificationDocument}
      />
    </>
  );
}

export default MetaMaskWallet;
