import React, { useEffect, useState, useContext } from "react";
import { StatusCodes } from "http-status-codes";
import { Link, useParams } from "react-router-dom";
import { admin } from "../../linksprovider";
import { cmsView } from "../../services/auth.services";
import { loaderContext } from "../global loader/global-loader-provider";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import { AdminContext } from "../../common/DashboardWrapper";
import { CMS } from "../../utils/constant";

function AdminCmsView() {
  const { id } = useParams();
  const { loaderDispatch } = useContext(loaderContext);
  const { setAdminActiveTab } = useContext(AdminContext);

  const [data, setData] = useState([]);

  useEffect(() => {
    const debunce = setTimeout(() => {
      getData();
    }, 300);

    return () => {
      clearTimeout(debunce);
    };
  }, []);
  useEffect(() => {
    setAdminActiveTab(false);
  }, []);

  //to get all data of email template by id
  const getData = async () => {
    loaderDispatch({
      isLoading: true,
    });
    try {
      let result = await cmsView(id);

      if (result?.data?.status === StatusCodes.OK) {
        loaderDispatch({
          isLoading: false,
        });
        setData(result?.data?.data);
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='py-16 xl:py-36 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden fade-in'>
      <div className='max-w-max lg:max-w-7xl mx-auto'>
        {/* <div className='mt-8 inline-flex rounded-md shadow'>
          <Link
            to={admin.Cms}
            className='flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700'>
            Go Back
          </Link>
        </div> */}
        <div className='relative z-10 mb-8 md:mb-2 md:px-6'>
          <div className='text-base max-w-prose lg:max-w-none'>
            <h2 className='leading-6 text-indigo-600 font-semibold tracking-wide uppercase'>
              {data.title}
            </h2>
          </div>
        </div>
        <div className='relative'>
          <div className='relative md:bg-white md:p-6'>
            <div className='prose prose-indigo prose-lg text-gray-500 lg:max-w-none'>
              {data.description?.length > 0 && parse(data.description)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminCmsView;
