import React, { Fragment, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Transition } from "@headlessui/react";
import HeadingFive from "../../../common/website/HeadingFive.jsx";
import { GrClose } from "react-icons/gr";
import { SiteModal, SiteForm } from "../../../common/website/HOC.jsx";
import { device } from "../../../common/website/Devices";
import { colors, siteGradient, space } from "../../../common/website/Variables";

import IconNextArrow from "../../../images/website/icon_next_arrow.svg";

import Paragraph from "../../../common/website/Paragraph.jsx";
import { DISCARD, IMG } from "../../../utils/constant.js";

import { PostContext } from "../header/MainHeader.jsx";
import blankProfile from "../../../images/website/dummy_user_profile.svg";
import { MentionsInput, Mention } from "react-mentions";

const ImgDesRow = styled.div`
  .discard_btn {
    position: absolute;
    top: -1px;
    left: -8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .model_top {
    margin-bottom: ${space.space16};
  }
  .modal_content {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    grid-gap: ${space.space20};
  }
  .modal_inner {
    max-width: 600px;
  }
  .modal_boday {
    width: 100%;
  }
  .profile {
    display: flex;
    align-items: center;
    grid-gap: ${space.space8};
    margin-bottom: ${space.space16};
    img {
      width: 34px;
      height: 34px;
      border-radius: 100%;
      object-fit: cover;
      flex-shrink: 0;
    }
  }
  .comment_form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    textarea {
      padding: 0;
      border: none;
      resize: none;
    }
  }
  .form_group {
    margin-bottom: ${space.space16};
    .mention {
      textarea {
        color: black;
        font-size: 0px !important;
        &::placeholder {
          font-size: 14px !important;
          color: ${colors.placeholdercolor};
        }
      }
      strong {
        font-family: "Inter" !important;
        background-color: #0b0b0b;
        color: #00e1f0;
      }
      span {
        font-family: "Inter" !important;
        color: white;
        visibility: visible !important;
      }
    }
  }
  .left {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-height: 100%;
      object-fit: contain;
      border-radius: ${space.space16};
    }
  }
  .right {
    width: 100%;
  }
  .post_btn {
    width: 100%;
    font-family: "Inter", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    border-radius: ${space.space8};
    display: inline-block;
    text-align: center;
    position: relative;
    outline: 0;
    padding: 0.5rem 1rem;
    transition: all 0.3s ease-in-out;
    color: ${colors.primary};
    z-index: 1;

    // Gradient
    &.gradient,
    &.outline {
      /* background:${siteGradient.bluePink}; */
      color: ${colors.white};

      &::after,
      &::before {
        background: ${siteGradient.bluePink};
        position: absolute;
        content: "";
        border-radius: ${space.space8};
        z-index: -1;
      }

      &::before {
        background-size: 400%;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        top: -2px;
        left: -2px;
        opacity: 0;
        filter: blur(5px);
        transition: all 0.3s ease-in-out;
        animation: glowing 8s linear infinite;
        /* animation: glowing 20s linear infinite; */
      }

      &::after {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }

      &:hover,
      &:active {
        color: ${colors.primary};
        transition: all 0.3s ease-in-out;

        &::before {
          opacity: 1;
          color: ${colors.primary};
        }

        &::after {
          background: ${colors.body};
        }
      }
    }

    // Outline
    &.outline {
      color: ${colors.primary};
      border: 1px solid ${colors.primary};
      &:hover {
        border-color: transparent;
      }
      &::after {
        background: ${colors.body};
      }
    }

    // Only Text
    &.textOnly {
      padding: 0 ${space.space8};

      &:hover {
        color: ${colors.white};
        text-decoration: underline;
      }
    }
  }
  @keyframes glowing {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
  @media ${device.mobileM} {
    .discard_btn {
      left: -13px;
    }
    .model_top {
      margin-bottom: ${space.space24};
    }
  }
  @media ${device.tablet} {
    .post_btn {
      font-size: 1rem;
      line-height: 1.5rem;
      padding: ${space.space12} ${space.space24};
    }
    .modal_inner {
      max-width: 1054px;
    }
    .left {
      height: 600px;
      width: 70%;
    }
    .right {
      width: 30%;
    }
    .modal_content {
      flex-direction: row;
      grid-gap: ${space.space34};
    }
    .comment_form {
      textarea {
        height: calc(100vh - 402px);
      }
    }
  }
`;

function ImgDes({
  setOpenImageModal,
  handleClick,
  postDetails,
  setPostDetails,
  handelUploadPost,
  value,
  val,
  upDatePost,
  setTagId,
  tagId,
  openImageModal,
}) {
  const mentionStyle = {
    control: {
      backgroundColor: "#0b0b0b",
    },
    "&multiLine": {
      control: {
        fontFamily: "monospace",
        minHeight: 63,
      },
      highlighter: {
        padding: 9,
        border: "1px solid transparent",
      },
      input: {
        padding: 9,
        // border: "1px solid silver",
      },
    },
    "&singleLine": {
      display: "inline-block",
      width: 180,
      highlighter: {
        padding: 1,
        border: "2px inset transparent",
      },
      input: {
        padding: 1,
        border: "2px inset",
      },
    },
    suggestions: {
      list: {
        backgroundColor: "black",
        border: "1px solid rgba(0,0,0,0.15)",
        // fontSize: 16,
      },
      item: {
        padding: "5px 15px",
        borderBottom: "1px solid rgba(0,0,0,0.15)",
        "&focused": {
          backgroundColor: "#00e1f0",
        },
      },
    },
  };

  const [userData, setUserData] = useState([]);

  let InnitialVal = value ? value : IMG;
  let imageUrl = "";
  if (postDetails?.postImage) {
    imageUrl = URL.createObjectURL(postDetails?.postImage);
  }
  const { userInfo, classmatesData, isMyMoment } = useContext(PostContext);

  let valImageUrl = val?.postName ? val?.postName : imageUrl;

  const [source, setSource] = useState(valImageUrl);

  useEffect(() => {
    if (!openImageModal) {
      // setOpenImageModal(false);
      setPostDetails({
        postImage: null,
        caption: "",
      });
      handleClick(InnitialVal);
    }
  }, [openImageModal]);

  useEffect(() => {
    setUserData([]);
    for (let i = 0; i < classmatesData.length; i++) {
      setUserData((preVal) => {
        return [
          ...preVal,
          {
            id: classmatesData[i]?.id,
            display: classmatesData[i]?.student_name,
          },
        ];
      });
    }
    for (let i = 0; i < tagId?.length; i++) {
      setUserData((preVal) => {
        for (let j = 0; j < preVal?.length; j++) {
          if (tagId[i] == preVal[j]?.id) {
            delete preVal[j];
            return preVal.filter((val) => {
              return val !== undefined;
            });
          }
        }
      });
    }
  }, [classmatesData, tagId?.length]);

  const handelChange = (e) => {
    const { name, value } = e.target;
    setPostDetails((preVal) => {
      return { ...preVal, [name]: value };
    });
  };

  const handelAddAndRemoveTag = (value) => {
    if (value.match(/[\\<>@#&!]/g)) {
      let idArrays = value.match(/\d+/g);

      setTagId([...handelRemovalNotRelevantIds(idArrays, classmatesData)]);
    } else {
      setTagId([]);
    }
  };

  const handelRemovalNotRelevantIds = (idArrays, classmatesData) => {
    let ids = [];
    if (idArrays?.length > 0 && classmatesData?.length > 0) {
      for (let i = 0; i < idArrays?.length; i++) {
        for (let j = 0; j < classmatesData?.length; j++) {
          if (classmatesData[j]?.id == idArrays[i]) {
            ids.push(idArrays[i]);
          }
        }
      }
      console.log(ids, "tagId");
    }
    return ids;
  };

  // console.log(tagId, "tagId");

  return (
    <ImgDesRow>
      <SiteModal>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          enterTo='opacity-100 translate-y-0 sm:scale-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100 translate-y-0 sm:scale-100'
          leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
          <div className='modal_inner'>
            <div className='modal_boday'>
              <div className='model_top'>
                <HeadingFive
                  cname='title'
                  h5Data={value ? "Edit Post" : "Create New Post"}
                />
                <span
                  className='modal_close'
                  onClick={() => {
                    setOpenImageModal(false);
                    setPostDetails({
                      postImage: null,
                      caption: "",
                    });
                    handleClick(InnitialVal);
                  }}>
                  {" "}
                  <GrClose />
                </span>
                {!value && (
                  <button
                    onClick={() => handleClick(DISCARD)}
                    className='discard_btn'>
                    <img src={IconNextArrow} alt='back icon' />
                  </button>
                )}
              </div>
              <div className='modal_content'>
                <div className='left'>
                  {!value &&
                    (postDetails?.postImage?.type?.includes("image") ? (
                      <img src={source} alt='Post Image' />
                    ) : postDetails?.postImage?.type?.includes("video") ? (
                      <video src={source} controls height={"100%"} />
                    ) : null)}

                  {value &&
                    (val?.postType == 1 ? (
                      <img src={source} alt='Post Image' />
                    ) : (
                      <video src={source} controls />
                    ))}
                </div>
                <div className='right'>
                  <SiteForm as='div' className='comment_form'>
                    <div className='top'>
                      <div className='profile'>
                        <img
                          src={
                            userInfo?.filtered_image
                              ? userInfo?.filtered_image
                              : val?.User?.filtered_image
                              ? val?.User?.filtered_image
                              : blankProfile
                          }
                          alt='profile_image'
                        />
                        <Paragraph
                          cname='noMargin'
                          pData={
                            userInfo?.student_name
                              ? userInfo?.student_name
                              : val?.User?.student_name
                          }
                        />
                      </div>
                      <div className='form_group'>
                        {!isMyMoment ? (
                          <textarea
                            label='comment'
                            name='caption'
                            rows='6'
                            defaultValue={
                              val?.description
                                ? val.description
                                : postDetails?.caption
                            }
                            placeholder='Add caption here...'
                            onChange={handelChange}
                          />
                        ) : (
                          <span className='mention'>
                            <MentionsInput
                              placeholder='Add caption and tag here...'
                              name='caption'
                              style={mentionStyle}
                              rows='6'
                              value={postDetails?.caption}
                              onChange={(e) => {
                                setPostDetails((preVal) => {
                                  return { ...preVal, caption: e.target.value };
                                });
                                handelAddAndRemoveTag(e.target.value);
                              }}>
                              <Mention
                                trigger='@'
                                data={userData}
                                appendSpaceOnAdd={true}
                              />
                            </MentionsInput>
                          </span>
                        )}
                      </div>
                    </div>
                    <button
                      className='gradient post_btn'
                      onClick={() => {
                        value
                          ? upDatePost(val?.id, isMyMoment)
                          : handelUploadPost();
                      }}>
                      Post
                    </button>
                  </SiteForm>
                </div>
              </div>
            </div>
          </div>
        </Transition.Child>
      </SiteModal>
    </ImgDesRow>
  );
}

export default ImgDes;
