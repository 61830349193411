import React, { useState, Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { GrClose } from "react-icons/gr";

function AdminView({ viewData, openViewModal, setOpenViewModal, view }) {
  const [subscriptionDetail, setSubscriptionDetail] = useState([]);
  const tableHeaderStyle = {
    backgroundColor: "#4caf50",
    color: "white",
    border: "1px solid #dddddd",
    textAlign: "left",
    padding: "8px",
  };

  const tableCellStyle = {
    border: "1px solid #dddddd",
    textAlign: "left",
    padding: "8px",
  };
  useEffect(() => {
    setSubscriptionDetail([
      {
        label: "Name of Plan",
        type: "text",
        name: "title",
        value: viewData?.Subscription?.title,
      },
      {
        label: `Price (${viewData?.currency})`,
        type: "text",
        name: "price",
        value: viewData?.Subscription?.price,
      },
      {
        label: "Description",
        type: "text",
        name: "description",
        value: viewData?.Subscription?.description,
      },
      {
        label: "CustomField",
        type: "text",
        name: "DynamicColumns",
        value: viewData?.DynamicColumns,
      },
    ]);
  }, [viewData]);

  return (
    <Transition.Root show={openViewModal} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={openViewModal}
        onClose={setOpenViewModal}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mb-6 row block text-2xl font-medium text-gray-700">
                  <span>View Details </span>
                  <span className="float-right cursor-pointer">
                    {" "}
                    <GrClose onClick={() => setOpenViewModal(false)} />
                  </span>
                </div>
                <div className="border-t border-gray-200">
                  {view?.map((val, i) => {
                    return (
                      <div
                        className={
                          i % 2 === 0
                            ? " px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                            : " bg-slate-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                        }
                        key={i}
                      >
                        <dt className="text-sm font-medium text-gray-500">
                          {val?.label}:
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 break-words">
                          {val?.value}
                        </dd>
                      </div>
                    );
                  })}
                  {viewData?.Subscription &&
                    subscriptionDetail.map((val, i) => {
                      return (
                        <div
                          className={
                            i % 2 !== 0
                              ? " px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                              : " bg-slate-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                          }
                          key={i}
                        >
                          {val?.name === "DynamicColumns" ? (
                            <table
                              style={{
                                width: "100%",
                                borderCollapse: "collapse",
                              }}
                            >
                              <tbody>
                                <tr>
                                  <th style={tableHeaderStyle}>Label</th>
                                  <th style={tableHeaderStyle}>Value</th>
                                </tr>
                                {val?.value?.map((item) => (
                                  <React.Fragment key={item.id}>
                                    <tr>
                                      <td style={tableCellStyle}>
                                        {item?.columnName}
                                      </td>
                                      <td style={tableCellStyle}>
                                        {item?.DynamicColumnResponse?.response
                                          ? item?.DynamicColumnResponse
                                              ?.response
                                          : "--"}
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 break-words">
                              {val?.value}
                            </dd>
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default AdminView;
