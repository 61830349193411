import React, { useState, Fragment, useEffect, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { GrClose } from "react-icons/gr";
import { createClub } from "../../services/auth.services";
import { loaderContext } from "../../components/global loader/global-loader-provider";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import { useRef } from "react";

function CreateClubModal({
  openCreateClubModal,
  setOpenCreateClubModal,
  id,
  handelClubListing,
}) {
  const [submit, setSubmit] = useState(false);
  const [clubData, setclubData] = useState({
    clubName: "",
    status: "1",
    school_id: id,
    description: "",
    club_profile: "",
    clubType: "PRIVATE",
  });

  const { loaderDispatch } = useContext(loaderContext);
  const inputRef = useRef(null);

  useEffect(() => {
    setclubData({
      clubName: "",
      status: "1",
      school_id: id,
      description: "",
      club_profile: "",
      clubType: "PRIVATE",
    });
    setSubmit(false);
  }, [openCreateClubModal]);

  const handelChange = (e) => {
    const { name, value } = e.target;
    setSubmit(false);
    if (name === "club_profile") {
      setclubData((preVal) => {
        return { ...preVal, [name]: e.target.files[0] };
      });
    } else {
      if (value?.toString().trim().length > 0 || value === "") {
        setclubData((preVal) => {
          return { ...preVal, [name]: value };
        });
      }
    }
  };

  const handelSubmit = async () => {
    try {
      if (clubData.clubName === "" || clubData.description === "") {
        setSubmit(true);
      }

      if (clubData.clubName !== "" && clubData?.description !== "") {
        loaderDispatch({
          isLoading: true,
        });
        console.log(clubData?.description);
        let data = {
          clubName: clubData?.clubName.trim(),
          status: clubData?.status?.trim(),
          school_id: clubData?.school_id,
          description: clubData?.description.trim(),
          club_profile: clubData?.club_profile,
          clubType: clubData?.clubType,
        };
        let formData = new FormData();
        for (const key in data) {
          formData.append(key, data[key]);
        }
        let result = await createClub(formData);
        loaderDispatch({
          isLoading: false,
        });

        if (result?.data?.status === StatusCodes.OK) {
          toast(result?.data?.message, {
            type: "success",
          });
          setOpenCreateClubModal(false);
          handelClubListing();
        } else {
          toast(result?.data?.message, {
            type: "error",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const chooseFileBox = () => {
    inputRef.current.click();
  };

  console.log("clubData.club_profile", clubData.club_profile);

  return (
    <Transition.Root show={openCreateClubModal} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={openCreateClubModal}
        onClose={setOpenCreateClubModal}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mb-6 row block text-2xl font-medium text-gray-700">
                  <span>Add Club Details </span>
                  <span className="float-right cursor-pointer">
                    {" "}
                    <GrClose onClick={() => setOpenCreateClubModal(false)} />
                  </span>
                </div>
                {/* club name */}
                <div className="mb-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Club Name <span className="text-red-500">*</span>
                  </label>

                  <input
                    type={"text"}
                    value={clubData.clubName}
                    name={"clubName"}
                    maxLength={60}
                    minLength={0}
                    onChange={handelChange}
                    autoComplete="cc-given-name"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                  />
                  {submit && clubData.clubName === "" && (
                    <p className="text-sm text-red-500">
                      Please fill the field
                    </p>
                  )}
                </div>
                {/* club name ends */}

                {/* Club Description */}

                <div className="mb-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Description <span className="text-red-500">*</span>
                  </label>

                  <textarea
                    type={"text"}
                    value={clubData.description}
                    name={"description"}
                    onChange={handelChange}
                    autoComplete="cc-given-name"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                  />
                  {submit && clubData.description === "" && (
                    <p className="text-sm text-red-500">
                      Please fill the field
                    </p>
                  )}
                </div>
                {/* club description ends */}

                {/* Club profile pic upload */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Club Profile Image
                  </label>

                  <label
                    className={
                      clubData.club_profile.name
                        ? "mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm cursor-pointer"
                        : "mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm text-gray-400 cursor-pointer"
                    }
                    onClick={chooseFileBox}
                  >
                    {clubData.club_profile.name
                      ? clubData.club_profile.name
                      : "Select image"}
                  </label>
                  <input
                    type={"file"}
                    ref={inputRef}
                    accept="image/*"
                    name="club_profile"
                    // value={clubData.club_profile}

                    onChange={handelChange}
                    // autoComplete='cc-given-name'
                    className="hidden"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Select club type
                  </label>
                  <select
                    name="clubType"
                    id="clubType"
                    onChange={handelChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                  >
                    <option value="PRIVATE">Private</option>
                    <option value="PUBLIC">Public</option>
                  </select>
                </div>
                {/* club profile pic ends */}
                <button
                  className="mt-4 block w-full border border-indigo-600 rounded-md shadow-sm py-2 px-3 bg-indigo-600 text-white font-semibold focus:outline-none  sm:text-sm"
                  onClick={handelSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default CreateClubModal;
