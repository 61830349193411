import React, { Fragment, useState } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { GrClose } from "react-icons/gr";

import { DateRange } from "react-date-range";
import { addDays, subDays } from "date-fns";

function DateFilter({ setOpen, open, setSendingDate, setDates, dates }) {
  const [state, setState] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: addDays(new Date(), 1),
      key: "selection",
    },
  ]);

  const [date, setDate] = useState("");

  const selectMonth = (index) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[index];
  };
  const formatDate = (date) => {
    if (date.toString().length === 1) {
      return "0" + date.toString();
    } else {
      return date.toString();
    }
  };

  const onChange = (ranges) => {
    let startDate = new Date(ranges.startDate);
    let endDate = new Date(ranges.endDate);
    setDates({
      startDate: `${selectMonth(
        startDate.getMonth()
      )} ${startDate.getDate()}, ${startDate.getFullYear()}`,
      endDate: `${selectMonth(
        endDate.getMonth()
      )} ${endDate.getDate()}, ${endDate.getFullYear()}`,
    });
    setSendingDate({
      startDate: `${startDate.getFullYear()}/${
        startDate.getMonth() + 1
      }/${formatDate(startDate.getDate())}`,
      endDate: `${endDate.getFullYear()}/${endDate.getMonth() + 1}/${formatDate(
        endDate.getDate()
      )}`,
    });
  };

  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    setDate(selection);
    setState([selection]);
  };

  const handelClick = () => {
    setOpen(true);
  };
  return (
    <div className='relative z-10 rounded-lg w-full'>
      <div className='relative max-w-sm flex bg-gray-50 border border-gray-300 focus:border-black  dark:border-gray-600dark:focus:border-black items-center rounded-md'>
        <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none '>
          <svg
            aria-hidden='true'
            className='w-5 h-5 text-gray-500 dark:text-gray-400'
            fill='currentColor'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              fillRule='evenodd'
              d='M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z'
              clipRule='evenodd'></path>
          </svg>
        </div>
        <input
          datepicker
          type='text'
          onClick={handelClick}
          value={
            dates?.startDate?.length > 0
              ? `${dates?.startDate} - ${dates?.endDate}`
              : ""
          }
          className=' text-gray-900 text-sm rounded focus:ring-black w-full pl-10 p-2.5 dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white dark:focus:ring-black  outline-none  bg-gray-50'
          placeholder='Select date'
          readOnly={true}
        />
        <span
          onClick={() => {
            setSendingDate({
              startDate: "",
              endDate: "",
            });
            setDates({
              startDate: "",
              endDate: "",
            });
            setState([
              {
                startDate: subDays(new Date(), 7),
                endDate: addDays(new Date(), 1),
                key: "selection",
              },
            ]);
          }}>
          <AiOutlineCloseCircle className='text-gray-500 dark:text-gray-400 h-5 w-5 mr-1 cursor-pointer' />
        </span>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as='div'
          static
          className='fixed z-10 inset-0 overflow-y-auto'
          open={open}
          onClose={setOpen}>
          <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'>
              <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='hidden sm:inline-block sm:align-middle sm:h-screen'
              aria-hidden='true'>
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
              <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:p-6'>
                <div className='mb-10 row block text-lg font-medium text-gray-700 '>
                  <p className='float-left'>Subscription Date</p>
                  <span className='float-right cursor-pointer'>
                    {" "}
                    <GrClose onClick={() => setOpen(false)} />
                  </span>
                </div>
                <DateRange
                  editableDateInputs={false}
                  onChange={handleOnChange}
                  moveRangeOnFirstSelection={false}
                  ranges={state}
                  direction={"horizontal"}
                />
                <div className='w-full flex justify-end'>
                  <button
                    className='px-4 py-2 bg-purple-600 rounded shadow-md text-white'
                    onClick={() => {
                      onChange(date);
                      setOpen(false);
                    }}>
                    Apply
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default DateFilter;
