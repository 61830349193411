import React, { useContext, useEffect, useState, useRef } from "react";
// import { Link } from "react-router-dom";
// import { admin } from "../../linksprovider";
import {
  deletecustomfield,
  getAdminProfile,
  updateLogo,
  updateSchoolProfile,
  viewSchoolDetails,
} from "../../../services/auth.services";
import { ADMIN_USER_DATA, PROFILE } from "../../../utils/constant";
import { StatusCodes } from "http-status-codes";
import { loaderContext } from "../../global loader/global-loader-provider";
import { HiPencil } from "react-icons/hi";
import { toast } from "react-toastify";
import validator from "validator";
import { AppContext } from "../../../App";
import { SchoolContext } from "../../../common/SchoolDashboardWrapper";
import { getCountryList } from "../../../common_api/commonApi";
import imageCompression from "browser-image-compression";
import { async } from "q";

function SchoolProfile() {
  const [schoolData, setSchoolData] = useState({
    profile_picture: "",
    school_name: "",
    email: "",
    address: "",
    country: "",
    pincode: "",
    state: "",
    city: "",
    password: "",
    new_password: "",
    confirm_password: "",
    domain: "",
  });
  const [isChecked, setChecked] = useState(false);

  const [fields, setFields] = useState([
    // Your initial field objects go here
    {
      columnName: "",
      columnType: "string",
      id: null,
      value: [""],
    },
    {
      columnName: "",
      columnType: "string",
      id: null,
      value: [""],
    },
    {
      columnName: "",
      columnType: "string",
      id: null,
      value: [""],
    },
    {
      columnName: "",
      columnType: "string",
      id: null,
      value: [""],
    },
  ]);
  const handleAddRow = () => {
    if (fields.length < 4) {
      setFields((prevFields) => [
        ...prevFields,
        {
          columnName: "",
          columnType: "",
          id: null,
          value: [""],
        },
      ]);
    }
  };

  const handleRemoveRow = async (index, id) => {
    if (id) {
      loaderDispatch({
        isLoading: true,
      });
      let result = await deletecustomfield(id);

      if (result?.data?.status === StatusCodes.OK) {
        setFields((prevFields) => prevFields.filter((_, i) => i !== index));
        getSchoolDetails();
        loaderDispatch({
          isLoading: false,
        });
      }
    } else {
      setFields((prevFields) => prevFields.filter((_, i) => i !== index));
    }
  };

  const handleChange = (index, field, value) => {
    let updatedError = [...customError];
    updatedError[index] = { ...updatedError[index], labelerror: false };
    setcustomError(updatedError);

    const updatedFields = [...fields];
    updatedFields[index][field] = value;
    if (field === "columnType") {
      updatedFields[index]["value"] = [""];
      let updatedError = [...customError];
      updatedError[index] = { ...updatedError[index], valuerror: false };
      setcustomError(updatedError);
    }
    setFields(updatedFields);
  };

  const handleAddOption = (index) => {
    const newFields = [...fields];
    newFields[index]["value"].push("");
    setFields(newFields);
  };

  const handleRemoveOption = (index, optionIndex) => {
    const newFields = [...fields];
    newFields[index]["value"].splice(optionIndex, 1);
    setFields(newFields);
  };
  const handleOptionChange = (index, optionIndex, value) => {
    let updatedError = [...customError];
    updatedError[index] = { ...updatedError[index], optionerror: false };
    setcustomError(updatedError);
    const newFields = [...fields];
    newFields[index].value[optionIndex] = value;

    setFields(newFields);
  };

  // const handleRemoveField = (index) => {
  //   if (fields.length > 1) {
  //     const updatedFields = [...fields];
  //     updatedFields.splice(index, 1);
  //     setFields(updatedFields);
  //   }
  // };
  const handleToggle = () => {
    setChecked(!isChecked);
  };
  const [errorMsg, setErrorMsg] = useState({
    profile_picture: "",
    school_name: "",
    email: "",
    address: "",
    country: "",
    pincode: "",
    state: "",
    city: "",
    password: "",
    new_password: "",
    confirm_password: "",
  });
  const [customError, setcustomError] = useState([
    { labelerror: false, optionerror: false },
    { labelerror: false, optionerror: false },
    { labelerror: false, optionerror: false },
    { labelerror: false, optionerror: false },
  ]);

  const [error, setError] = useState({});

  const [editTable, setEditTable] = useState(true);
  const [editTableWebsite, setEditTableWebsite] = useState(true);

  const [profilePicture, setProfilePicture] = useState();
  const [logo, setLogo] = useState();
  const [backeupLogo, setBackeupLogo] = useState();
  const [profileFormatError, setProfileFormatError] = useState(false);
  const [logoFormatError, setLogoFormatError] = useState(false);

  const [countryList, setCountryList] = useState([]);

  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }

  const { loaderDispatch } = useContext(loaderContext);
  const { setAdminActiveTab, getSchoolProfile } = useContext(SchoolContext);

  const inputFile = useRef(null);
  const inputFileLogo = useRef(null);

  let subscribe = true;
  useEffect(() => {
    if (subscribe) {
      getSchoolDetails();
    }
    setAdminActiveTab(PROFILE);
    return () => (subscribe = false);
  }, []);

  const getSchoolDetails = async () => {
    loaderDispatch({
      isLoading: true,
    });
    setSchoolData({});
    setError({});
    let result = await viewSchoolDetails(userData.id);
    loaderDispatch({
      isLoading: false,
    });
    localStorage.setItem(ADMIN_USER_DATA, JSON.stringify(result?.data?.data));
    let schooluserData = result?.data?.data;
    if (schooluserData?.customFields) {
      const updatedFields = schooluserData?.customFields.map((item) => ({
        columnName: item.columnName,
        columnType: item.columnType,
        value: item.value,
        id: item.id,
      }));

      if (updatedFields?.length > 0) {
        setFields(updatedFields);
      }
    }

    getAllCountries(schooluserData?.country);

    if (schooluserData) {
      if (schooluserData?.yearToggle === true) {
        setChecked(true);
      } else {
        setChecked(false);
      }
      setSchoolData({
        profile_picture: schooluserData?.profile_picture,
        school_name: schooluserData?.school_name,
        email: schooluserData?.email,
        address: schooluserData?.address,
        country: schooluserData?.country,
        pincode: schooluserData?.pincode,
        state: schooluserData?.state,
        city: schooluserData?.city,
        password: "",
        new_password: "",
        confirm_password: "",
        domain: schooluserData?.school_domain || "",
      });
      setBackeupLogo(schooluserData?.school_logo);
      setError({
        school_name: false,
        email: false,
        address: false,
        country: false,
        pincode: false,
        state: false,
        city: false,
        password: false,
        new_password: false,
        confirm_password: false,
      });
    }
  };

  //update school profile
  const updateSchoolDetails = async () => {
    const newErrors = [];
    try {
      let jsonData = {};
      let testArray = [];

      if (schoolData.password === "") {
        jsonData = {
          profile_picture: profilePicture
            ? profilePicture
            : schoolData?.profile_picture,
          school_name: schoolData?.school_name,
          email: schoolData?.email,
          address: schoolData?.address,
          country: schoolData?.country,
          pincode: schoolData?.pincode,
          state: schoolData?.state,
          city: schoolData?.city,
          yearToggle: isChecked,
        };
        for (const key in jsonData) {
          if (key !== "school_domain") {
            if (jsonData[key] === "") {
              setError((preVal) => {
                return {
                  ...preVal,
                  [key]: true,
                };
              });
              setErrorMsg((preVal) => {
                return { ...preVal, [key]: "Please fill the Field" };
              });
            }
          }
        }

        if (jsonData?.email) {
          let checkEmail = validator.isEmail(jsonData?.email);
          if (!checkEmail) {
            setErrorMsg((preVal) => {
              return { ...preVal, email: "Please enter a valid email address" };
            });
            testArray.push(true);
            setError((preVal) => {
              return { ...preVal, email: true };
            });
          }
        }

        let check = Object.values(jsonData);
        for (let i = 0; i < check.length; i++) {
          if (check[i] === "") {
            break;
          } else {
            testArray.push(true);
          }
        }
      } else {
        jsonData = {
          profile_picture: profilePicture
            ? profilePicture
            : schoolData?.profile_picture,
          school_name: schoolData?.school_name,
          email: schoolData?.email,
          address: schoolData?.address,
          country: schoolData?.country,
          pincode: schoolData?.pincode,
          state: schoolData?.state,
          old_password: schoolData?.password,
          new_password: schoolData?.new_password,
          confirm_password: schoolData?.confirm_password,
          city: schoolData?.city,
          yearToggle: isChecked,
        };

        for (const key in jsonData) {
          if (key !== "school_domain") {
            if (jsonData[key] === "") {
              setError((preVal) => {
                return {
                  ...preVal,
                  [key]: true,
                };
              });
              setErrorMsg((preVal) => {
                return { ...preVal, [key]: "Please fill the Field" };
              });
            }
          }
        }

        if (
          jsonData.confirm_password.length > 0 &&
          jsonData.new_password !== jsonData.confirm_password
        ) {
          testArray.push(true);
          setError((preVal) => {
            return {
              ...preVal,
              confirm_password: true,
            };
          });
          setErrorMsg((preVal) => {
            return {
              ...preVal,
              confirm_password: "New & confirm password must be same",
            };
          });
        }
        if (jsonData?.email) {
          let checkEmail = validator.isEmail(jsonData?.email);
          if (!checkEmail) {
            setErrorMsg((preVal) => {
              return { ...preVal, email: "Please enter a valid email address" };
            });
            testArray.push(true);
            setError((preVal) => {
              return { ...preVal, email: true };
            });
          }
        }
        let check = Object.values(jsonData);
        for (let i = 0; i < check.length; i++) {
          if (check[i] === "") {
            break;
          } else {
            testArray.push(true);
          }
        }
      }

      if (
        jsonData?.new_password?.length > 0 &&
        jsonData?.new_password?.length < 4
      ) {
        setError((preVal) => {
          return { ...preVal, new_password: true };
        });
        setErrorMsg((preVal) => {
          return {
            ...preVal,
            new_password: "New password must be at least 4 characters long",
          };
        });
        return;
      }

      if (profileFormatError) {
        return;
      }

      // if (jsonData.school_domain !== "") {
      //   let tset = jsonData?.school_domain?.split(".");
      //   console.log("jasondaat", tset);
      // }

      if (Object.keys(jsonData).length === testArray.length) {
        jsonData.school_domain = schoolData?.domain ? schoolData?.domain : "";
        if (jsonData.school_domain !== "") {
          if (!jsonData.school_domain.includes(".")) {
            setError((preVal) => {
              return { ...preVal, school_domain: true };
            });
            return;
          }
          let testLink = jsonData.school_domain?.split(".");
          for (let i = 0; i < testLink?.length; i++) {
            if (testLink?.[i] === "" || testLink?.[i].length < 2) {
              setError((preVal) => {
                return { ...preVal, school_domain: true };
              });
              return;
            }
          }
        }
        loaderDispatch({
          isLoading: true,
        });

        if (typeof profilePicture === "object") {
          jsonData.profile_picture = await handleImageUpload(profilePicture);
        }

        if (jsonData?.confirm_password?.length > 0) {
          delete jsonData.confirm_password;
        }

        let formData = new FormData();
        for (const key in jsonData) {
          formData.append([key], jsonData[key]);
        }
        let submiterror = true;
        let updatedError = [...customError];
        fields.forEach((field, index) => {
          if (field?.columnName === "") {
            updatedError[index] = { ...updatedError[index], labelerror: true };
            submiterror = false;
          }

          if (
            field?.columnType === "dropdown" ||
            field?.columnType === "checkbox"
          ) {
            field.value.forEach((option, optionIndex) => {
              if (!option.trim()) {
                updatedError[index] = {
                  ...updatedError[index],
                  optionerror: true,
                };
                submiterror = false;
              }
            });
          }
        });

        setcustomError(updatedError);
        formData.append("dynamicColumns", JSON.stringify(fields));
        let result;
        if (submiterror) {
          result = await updateSchoolProfile(userData.id, formData);
        }

        loaderDispatch({
          isLoading: false,
        });

        if (result?.data?.status === StatusCodes.OK) {
          toast(result?.data?.message, {
            type: "success",
          });
          getSchoolDetails();
          setEditTable(true);
        } else {
          toast(result?.data?.message, {
            type: "error",
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  async function handleImageUpload(imageFile) {
    let size = imageFile?.size / 1024 / 1024;
    if (size > 4) {
      const options = {
        maxSizeMB: 4,
        // maxWidthOrHeight: 1920,
        // alwaysKeepResolution: true,
      };
      try {
        const compressedFile = await imageCompression(imageFile, options);
        console.log("compressedFileSize", compressedFile.size / 1024 / 1024);

        return compressedFile;
      } catch (error) {
        console.log(error);
      }
    } else {
      return imageFile;
    }
  }

  const chooseFileBox = (variable) => {
    variable.current.click();
  };

  const handelChooseFile = (e, errorState) => {
    const name = e?.target?.name;
    const fileUploaded = e.target.files[0];
    if (fileUploaded?.type.includes("image")) {
      errorState(false);
      if (
        fileUploaded?.type === "image/png" ||
        fileUploaded?.type === "image/jpg" ||
        fileUploaded?.type === "image/jpeg"
      ) {
        return fileUploaded;
      } else {
        errorState(true);
        setErrorMsg((preVal) => {
          return {
            ...preVal,
            [name]:
              "File format not supported, please upload PNG, JPG, or JPEG file",
          };
        });
        return null;
      }
    } else {
      errorState(true);
      setErrorMsg((preVal) => {
        return { ...preVal, [name]: "Please upload an image file" };
      });
      return null;
    }
  };

  const handelChange = (e) => {
    const { name, value } = e.target;

    setError((preVal) => {
      return {
        ...preVal,
        [name]: false,
      };
    });

    if (value.toString().trim().length > 0 || value?.toString() === "") {
      setSchoolData((preVal) => {
        if (name === "school_name") {
          if (value?.length < 61) {
            return { ...preVal, [name]: value };
          } else {
            return { ...preVal, [name]: preVal?.school_name };
          }
        } else if (name === "pincode") {
          if (
            (checkPricePositive(e.target.value) &&
              !isNaN(parseInt(e.target.value))) ||
            e.target.value.toString() === ""
          ) {
            return { ...preVal, pincode: value };
          } else {
            return { ...preVal, pincode: schoolData?.pincode };
          }
        } else if (name === "state" || name === "city") {
          if (checkAlphaSpace(value)) {
            return { ...preVal, [name]: value };
          } else {
            return { ...preVal, [name]: schoolData?.[name] };
          }
        } else if (name === "domain") {
          if (checkAlphaSpaceAndDot(value)) {
            return { ...preVal, [name]: value };
          } else {
            return preVal;
          }
        } else {
          return { ...preVal, [name]: value };
        }
      });
    }
  };

  const alphaSpace = /^[A-Za-z\s]*$/;
  const checkAlphaSpace = (value) => {
    return alphaSpace.test(value);
  };

  const checkAlphaSpaceAndDot = (value) => {
    return /^[a-zA-Z0-9.a-zA-Z]*$/.test(value);
  };

  const checkPricePositive = (value) => {
    return `${value}`.match(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/);
  };

  const getAllCountries = async (country) => {
    setCountryList([]);
    try {
      let result = await getCountryList();

      for (let i = 0; i < result.length; i++) {
        if (result[i].country_name === country) {
          setCountryList((preVal) => {
            return [...preVal, { ...result[i], selected: true }];
          });
        } else {
          setCountryList((preVal) => {
            return [...preVal, { ...result[i], selected: false }];
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handelUpdateWebsiteInfo = async () => {
    if (!logoFormatError) {
      try {
        // loaderDispatch({
        //   isLoading: true,
        // });

        let body = {
          school_logo: logo ? logo : backeupLogo,
        };

        let formData = new FormData();
        if (body?.school_logo) {
          loaderDispatch({
            isLoading: true,
          });
          if (typeof body?.school_logo === "object") {
            body.school_logo = await handleImageUpload(body?.school_logo);
          }
          formData.append("school_logo", body?.school_logo);
        }
        if (body?.school_logo) {
          loaderDispatch({
            isLoading: true,
          });
          let result = await updateLogo(userData?.id, formData);
          loaderDispatch({
            isLoading: false,
          });
          if (result?.data?.status === StatusCodes.OK) {
            toast(result?.data?.message, {
              type: "success",
            });
            getSchoolDetails();
          } else {
            toast(result?.data?.message, {
              type: "error",
            });
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  console.log("profileFormatError", profileFormatError);

  return (
    <div>
      <div className="min-h-screen bg-gray-100 ">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
          <div className="flex items-center space-x-5 mt-2">
            <div className="flex-shrink-0">
              <div className="relative">
                <span
                  className="absolute inset-0 shadow-inner rounded-full"
                  aria-hidden="true"
                />
              </div>
            </div>
            <div className="flex">
              <div className=" h-20 w-20 ">
                <img
                  className="h-full w-full overflow-hidden relative object-cover rounded-full"
                  src={
                    profilePicture
                      ? URL.createObjectURL(profilePicture)
                      : schoolData?.profile_picture
                      ? schoolData?.profile_picture
                      : "https://t3.ftcdn.net/jpg/01/09/00/64/360_F_109006426_388PagqielgjFTAMgW59jRaDmPJvSBUL.jpg"
                  }
                  alt=""
                />
                {!editTable && (
                  <div
                    className="rounded-full bg-gray-500 h-5 w-5 z-50 relative text-white cursor-pointer flex justify-center items-center"
                    style={{ bottom: "27px", left: "68%" }}
                    onClick={() => chooseFileBox(inputFile)}
                  >
                    <HiPencil />

                    <input
                      type="file"
                      ref={inputFile}
                      className="hidden"
                      name="profile_picture"
                      value=""
                      accept="image/*"
                      onChange={(e) =>
                        setProfilePicture(
                          handelChooseFile(e, setProfileFormatError)
                        )
                      }
                      disabled={editTable}
                    />
                  </div>
                )}
              </div>
              <h1 className="text-2xl font-bold text-gray-900 mt-3 ml-3">
                {schoolData.school_name}
                {profileFormatError && (
                  <p className="text-sm text-red-500 font-normal">
                    {errorMsg?.profile_picture}
                  </p>
                )}
              </h1>
            </div>
          </div>
        </div>

        {/* Start of school section */}
        <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-start-1 lg:col-span-2">
            <section aria-labelledby="applicant-information-title">
              <div className="bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                  <div>
                    <div className="flex justify-between">
                      <h2
                        id="applicant-information-title"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Profile Settings
                        <p className=" max-w-2xl text-sm font-normal text-gray-500 w-fit">
                          School details
                        </p>
                      </h2>

                      {editTable && (
                        <button
                          type="submit"
                          className="w-fit flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={() => setEditTable(false)}
                        >
                          Edit
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
                    <div className="sm:col-span-1">
                      <label className="block mt-2 text-sm font-medium text-gray-500">
                        School name<span className="text-red-500">&#42;</span>
                      </label>
                      <input
                        type={"text"}
                        value={schoolData?.school_name}
                        name="school_name"
                        onChange={handelChange}
                        readOnly={editTable}
                        autoComplete="cc-given-name"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                      />
                      {error?.school_name && (
                        <p className="text-sm text-red-500">
                          {errorMsg?.school_name}
                        </p>
                      )}
                    </div>
                    <div className="sm:col-span-1 mt-2">
                      <dt className="text-sm font-medium text-gray-500">
                        Email address<span className="text-red-500">&#42;</span>
                      </dt>
                      <input
                        type={"text"}
                        value={schoolData?.email ? schoolData?.email : ""}
                        name="email"
                        onChange={handelChange}
                        disabled
                        autoComplete="cc-given-name"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                      />
                      {error?.email && (
                        <p className="text-sm text-red-500">
                          {errorMsg?.email}
                        </p>
                      )}
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Street address
                        <span className="text-red-500">&#42;</span>
                      </dt>
                      <input
                        type={"text"}
                        value={schoolData?.address}
                        name="address"
                        onChange={handelChange}
                        readOnly={editTable}
                        autoComplete="cc-given-name"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                      />
                      {error?.address && (
                        <p className="text-sm text-red-500">
                          {errorMsg?.address}
                        </p>
                      )}
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Country<span className="text-red-500">&#42;</span>
                      </dt>
                      <select
                        type={"text"}
                        name="country"
                        onChange={handelChange}
                        autoComplete="country-name"
                        className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      >
                        {countryList?.map((val, i) => {
                          return (
                            <option
                              value={val.country_name}
                              key={i}
                              selected={val?.selected}
                            >
                              {val.country_name}
                            </option>
                          );
                        })}
                      </select>
                      {/* <input
                        type={"text"}
                        value={schoolData?.country}
                        name={"country"}
                        onChange={handelChange}
                        readOnly={editTable}
                        autoComplete='cc-given-name'
                        className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm'
                      />
                      {error?.country && (
                        <p className='text-sm text-red-500'>
                          {errorMsg?.country}
                        </p>
                      )} */}
                    </div>
                    <div className="sm:col-span-1 ">
                      <dt className="text-sm font-medium text-gray-500">
                        State/ Province
                        <span className="text-red-500">&#42;</span>
                      </dt>
                      <input
                        type={"text"}
                        value={schoolData?.state}
                        name="state"
                        maxLength={30}
                        minLength={0}
                        onChange={handelChange}
                        readOnly={editTable}
                        autoComplete="cc-given-name"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                      />
                      {error.state && (
                        <p className="text-sm text-red-500">
                          {errorMsg?.state}
                        </p>
                      )}
                    </div>

                    <div className="sm:col-span-1 ">
                      <dt className="text-sm font-medium text-gray-500">
                        City<span className="text-red-500">&#42;</span>
                      </dt>
                      <input
                        type={"text"}
                        value={schoolData?.city}
                        name="city"
                        maxLength={30}
                        minLength={0}
                        onChange={handelChange}
                        readOnly={editTable}
                        autoComplete="cc-given-name"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                      />
                      {error.city && (
                        <p className="text-sm text-red-500">{errorMsg?.city}</p>
                      )}
                    </div>

                    <div className="sm:col-span-1 ">
                      <dt className="text-sm font-medium text-gray-500">
                        Pin code/ Zip code
                        <span className="text-red-500">&#42;</span>
                      </dt>
                      <input
                        type={"text"}
                        name="pincode"
                        value={schoolData.pincode}
                        minLength={0}
                        maxLength={12}
                        onChange={handelChange}
                        readOnly={editTable}
                        autoComplete="cc-given-name"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                      />
                      {error.pincode && (
                        <p className="text-sm text-red-500">
                          {errorMsg?.pincode}
                        </p>
                      )}
                    </div>
                    <div className="sm:col-span-1 ">
                      <dt className="text-sm font-medium text-gray-500">
                        Current Password
                      </dt>
                      <input
                        type={"text"}
                        name="password"
                        value={schoolData.password}
                        onChange={handelChange}
                        readOnly={editTable}
                        autoComplete="cc-given-name"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                      />
                      {error.password && (
                        <p className="text-sm text-red-500">
                          {errorMsg?.password}
                        </p>
                      )}
                    </div>
                    {schoolData.password && (
                      <>
                        <div className="sm:col-span-1 ">
                          <dt className="text-sm font-medium text-gray-500">
                            New Password
                            <span className="text-red-500">&#42;</span>
                          </dt>
                          <input
                            type={"text"}
                            name={"new_password"}
                            value={schoolData.new_password}
                            onChange={handelChange}
                            readOnly={editTable}
                            autoComplete="cc-given-name"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                          />
                          {error.new_password && (
                            <p className="text-sm text-red-500">
                              {errorMsg?.new_password}
                            </p>
                          )}
                        </div>
                        <div className="sm:col-span-1 ">
                          <dt className="text-sm font-medium text-gray-500">
                            Confirm Password
                            <span className="text-red-500">&#42;</span>
                          </dt>
                          <input
                            type={"text"}
                            name="confirm_password"
                            value={schoolData.confirm_password}
                            onChange={handelChange}
                            readOnly={editTable}
                            autoComplete="cc-given-name"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                          />
                          {error.confirm_password && (
                            <p className="text-sm text-red-500">
                              {errorMsg?.confirm_password}
                            </p>
                          )}
                        </div>
                      </>
                    )}
                    <div className="sm:col-span-1 ">
                      <dt className="text-sm font-medium text-gray-500">
                        Domain Name
                      </dt>
                      <input
                        type={"text"}
                        name="domain"
                        value={schoolData.domain}
                        onChange={handelChange}
                        readOnly={editTable}
                        minLength={0}
                        maxLength={30}
                        autoComplete="cc-given-name"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                      />
                      {error.school_domain && (
                        <p className="text-sm text-red-500">
                          Please enter valid domain name
                        </p>
                      )}
                    </div>
                    <div className="sm:col-span-1 ">
                      <dt className="text-sm font-medium text-gray-500">
                        Academic year ON/OFF (ON means show Academic year and of
                        means hide Academic year)
                      </dt>
                      <div
                        className={`toggle-switch ${isChecked ? "on" : "off"}`}
                        onClick={!editTable ? handleToggle : null}
                      >
                        <div className="switch">
                          <div className="slider" />
                        </div>
                        <div className="label">{isChecked ? "ON" : "OFF"}</div>
                      </div>
                    </div>
                    <div className="sm:col-span-1">
                      <h2
                        id="applicant-information-title"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Add Custom Field
                      </h2>
                    </div>
                    <div className="sm:col-span-1"></div>
                    {fields.map((field, index) => (
                      <>
                        <div key={index} className="sm:col-span-1">
                          <label className="block text-sm font-medium text-gray-500">
                            Label
                          </label>
                          <input
                            type="string"
                            value={field.columnName}
                            readOnly={editTable}
                            onChange={(e) =>
                              handleChange(index, "columnName", e.target.value)
                            }
                            className={`mt-1 block w-500 rounded-md border ${
                              customError[index]?.labelerror
                                ? "border-red-500"
                                : "border-gray-300"
                            } bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                          />
                          {customError[index]?.labelerror && (
                            <p className="text-red-500 text-sm mt-1">
                              Label is required.
                            </p>
                          )}
                        </div>
                        <div key={index} className="sm:col-span-1">
                          <label className="block text-sm font-medium text-gray-500 mt-2">
                            Field Type
                          </label>
                          <div className="flex items-center">
                            <select
                              value={field.columnType}
                              disabled={editTable}
                              onChange={(e) =>
                                handleChange(
                                  index,
                                  "columnType",
                                  e.target.value
                                )
                              }
                              className="mt-1 block w-9/12 rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            >
                              <option value="string">Text</option>
                              <option value="date">Date</option>
                              <option value="checkbox">Checkbox</option>
                              <option value="dropdown">Dropdown</option>
                              {/* Add other options as needed */}
                            </select>
                            {!editTable && index >= 1 && (
                              <button
                                className="text-sm ml-1 px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                                onClick={() =>
                                  handleRemoveRow(index, field?.id)
                                }
                              >
                                Remove Row
                              </button>
                            )}
                          </div>

                          {customError[index]?.optionerror && (
                            <p className="text-red-500 text-sm mt-1">
                              Option field is required.
                            </p>
                          )}

                          {field.columnType === "dropdown" ||
                          field.columnType === "checkbox" ? (
                            <div>
                              <label className="block text-sm font-medium text-gray-500 mt-2">
                                Options
                              </label>
                              {field.value.map((option, optionIndex) => (
                                <div
                                  key={optionIndex}
                                  className="flex items-center mt-2"
                                >
                                  <input
                                    type="text"
                                    value={option}
                                    disabled={editTable}
                                    onChange={(e) =>
                                      handleOptionChange(
                                        index,
                                        optionIndex,
                                        e.target.value
                                      )
                                    }
                                    className={`block w-400 rounded-md border bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm mr-2`}
                                  />
                                  {!editTable && field.value.length > 1 && (
                                    <button
                                      className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                                      onClick={() =>
                                        handleRemoveOption(index, optionIndex)
                                      }
                                    >
                                      Remove
                                    </button>
                                  )}
                                </div>
                              ))}
                              {!editTable && (
                                <button
                                  className="mt-2 px-4 py-2 bg-indigo-600 text-white rounded shadow hover:bg-indigo-700"
                                  onClick={() => handleAddOption(index)}
                                >
                                  Add Option
                                </button>
                              )}
                            </div>
                          ) : null}
                        </div>
                      </>
                    ))}
                    {!editTable && fields.length < 4 && (
                      <button
                        className="mt-2 px-4 py-2 bg-indigo-600 text-white rounded shadow hover:bg-indigo-700"
                        onClick={handleAddRow}
                      >
                        Add Row
                      </button>
                    )}
                  </dl>

                  {!editTable && (
                    <div className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
                      <button
                        type="submit"
                        className="mt-2 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={updateSchoolDetails}
                      >
                        Save
                      </button>
                      <button
                        type="submit"
                        className="mt-2 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-md text-sm font-medium text-gray-700 bg-white hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
                        onClick={() => setEditTable(true)}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </div>
        </div>
        {/* end of school section */}

        {/* Start of website section */}
        <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-start-1 lg:col-span-2">
            <section aria-labelledby="applicant-information-title">
              <div className="bg-white shadow sm:rounded-lg mb-8">
                <div className="px-4 py-5 sm:px-6">
                  <div>
                    <div className="flex justify-between">
                      <h2
                        id="applicant-information-title"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        {/* Profile Settings */}
                        Website details
                      </h2>

                      {editTableWebsite && (
                        <button
                          type="submit"
                          className="w-fit flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={() => setEditTableWebsite(false)}
                        >
                          Edit
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-5 sm:grid-cols-2">
                    <div className="sm:col-span-1  ">
                      <label className="block mb-2 text-sm font-medium text-gray-500 ">
                        Website logo
                      </label>
                      <div className="flex items-center space-x-4">
                        <div className=" h-20 w-20 ">
                          <img
                            className="h-full w-full overflow-hidden relative object-cover rounded-full"
                            src={
                              logo
                                ? URL.createObjectURL(logo)
                                : backeupLogo
                                ? backeupLogo
                                : "https://t3.ftcdn.net/jpg/01/09/00/64/360_F_109006426_388PagqielgjFTAMgW59jRaDmPJvSBUL.jpg"
                            }
                            alt=""
                          />
                          {!editTableWebsite && (
                            <div
                              className="rounded-full bg-gray-500 h-5 w-5 z-50 relative text-white cursor-pointer flex justify-center items-center"
                              style={{ bottom: "27px", left: "68%" }}
                              onClick={() => chooseFileBox(inputFileLogo)}
                            >
                              <HiPencil />

                              <input
                                type="file"
                                ref={inputFileLogo}
                                className="hidden"
                                name="logo"
                                value=""
                                accept="image/*"
                                onChange={(e) =>
                                  setLogo(
                                    handelChooseFile(e, setLogoFormatError)
                                  )
                                }
                                disabled={editTableWebsite}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <h1 className="text-2xl font-bold text-gray-900 mt-3 ml-3">
                        {logoFormatError && (
                          <p className="text-sm text-red-500 font-normal">
                            {errorMsg?.logo}
                          </p>
                        )}
                      </h1>
                    </div>
                  </dl>
                  {!editTableWebsite && (
                    <div className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
                      <button
                        type="submit"
                        className="mt-2 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={handelUpdateWebsiteInfo}
                      >
                        Save
                      </button>
                      <button
                        type="submit"
                        className="mt-2 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-md text-sm font-medium text-gray-700 bg-white hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
                        onClick={() => setEditTableWebsite(true)}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </div>
        </div>
        {/* end of website section */}
      </div>
    </div>
  );
}

export default SchoolProfile;
