import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import {
  SiteCard,
  SiteForm,
  CustomLink,
} from "../../../common/website/HOC.jsx";
import { device } from "../../../common/website/Devices";
import { colors, space } from "../../../common/website/Variables";
import { Formik, Field } from "formik";
import { Link, useNavigate } from "react-router-dom";

import HeadingThree from "../../../common/website/HeadingThree.jsx";
import HeadingFour from "../../../common/website/HeadingFour.jsx";
import Label from "../../../common/website/Label.jsx";
import domtoimage from "dom-to-image";

import IconDigitalArtCreator from "../../../images/website/icon_digital_art.svg";
import StudentBlockchain from "../../../images/website/image_student_blockchain.png";
import StudentBlockchain2 from "../../../images/website/image_student_blockchain2.png";
import Button from "../../../common/website/Button.jsx";
import { student } from "../../../linksprovider.js";
import axios from "axios";
import { loaderContext } from "../../../components/global loader/global-loader-provider";
import { toast } from "react-toastify";
import { type } from "@testing-library/user-event/dist/type/index.js";
import { ADMIN_USER_DATA, GRADUATED } from "../../../utils/constant.js";
import { StatusCodes } from "http-status-codes";
import {
  convertImage,
  getSubscriptionDetail,
  saveFilterImage,
} from "../../../services/auth.services.js";
import { PostContext } from "../../../components/website/header/MainHeader.jsx";
import { AppContext } from "../../../App.js";

const DigitalArtCreatorRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  .club_group {
    display: none !important;
  }
  h3,
  h4,
  p {
    position: static;
  }
  .body_full_screen {
    width: 100%;
    height: auto;
    .full_screen_card {
      border-radius: ${space.space16};
      padding: ${space.space16} 0;
      height: 100%;
      .head {
        display: flex;
        align-items: flex-start;
        grid-gap: ${space.space8};
        padding-bottom: ${space.space16};
        border-bottom: 1px solid ${colors.lightwhiteborder};
        margin: 0 ${space.space16} ${space.space16};
        img {
          width: 24px;
          height: 24px;
        }
        h3 {
          margin: 0;
        }
      }
    }
  }
  .list_wrap_main {
    height: auto;
    padding: 0 ${space.space16};
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(172 172 172 / 10%);
      border-radius: 8px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    /* Fire Fox */
    scrollbar-color: rgb(172 172 172 / 10%) transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: transparent;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(172 172 172 / 10%);
      border-radius: 8px;
    }
    /* End Fire Fox */
  }
  .digital_art_form {
    max-width: 448px;
    margin: ${space.space24} auto ${space.space30};
    .form_group {
      margin-bottom: ${space.space20};
    }
    .generate-btn {
      width: 100%;
    }
  }
  .student_blockchain {
    padding-top: ${space.space24};
    border-top: 1px solid ${colors.lightwhiteborder};
    h4 {
      text-align: center;
      margin-bottom: ${space.space14};
    }
  }
  .student_blockchain_list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    grid-gap: ${space.space16};
    .item {
      width: 115px;
      height: 115px;
      position: relative;
      border-radius: ${space.space8};
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .action_group {
        display: flex;
        align-items: center;
        justify-content: center;
        grid-gap: ${space.space8};
        position: absolute;
        left: 0;
        right: 0;
        bottom: 8px;
        z-index: 9;
        .gradient {
          font-size: 0.875rem;
          height: 26px;
          width: 46px;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  @media ${device.mobileM} {
    .body_full_screen {
      .full_screen_card {
        .head {
          align-items: center;
        }
      }
    }
    .student_blockchain {
      h4 {
        margin-bottom: ${space.space24};
      }
    }
    .student_blockchain_list {
      .item {
        width: 180px;
        height: 180px;
        .action_group {
          grid-gap: ${space.space14};
          bottom: 15px;
          .gradient {
            font-size: 1rem;
            height: 32px;
            width: 60px;
          }
        }
      }
    }
  }
  @media ${device.mobileL} {
    .body_full_screen {
      .full_screen_card {
        .head {
          grid-gap: ${space.space14};
          img {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
    .body_full_screen {
      .full_screen_card {
        padding: ${space.space30} 0;
        .head {
          padding-bottom: ${space.space30};
          margin: 0 ${space.space30} ${space.space30};
        }
      }
    }
    .list_wrap_main {
      padding: 0 ${space.space30};
    }
  }
  @media ${device.tablet} {
    width: 75%;
    .student_blockchain_list {
      justify-content: flex-start;
    }
    .digital_art_form {
      margin: 6px auto ${space.space30};
    }
    .list_wrap_main {
      overflow-y: auto;
      height: calc(100vh - 202px);
    }
    .body_full_screen {
      padding-left: ${space.space20};
      height: calc(100vh - 40px);
    }
  }
  @media ${device.laptop} {
    width: 77%;
    .digital_art_form {
      margin: ${space.space30} auto ${space.space60};
      .form_group {
        margin-bottom: ${space.space50};
      }
    }
    .student_blockchain {
      padding-top: ${space.space40};
      h4 {
        margin-bottom: ${space.space40};
      }
    }
    .student_blockchain_list {
      .item {
        width: 244px;
        height: 244px;
      }
      .action_group {
        .gradient {
          height: 40px;
          width: 100px;
        }
      }
    }
  }
  @media ${device.laptopL} {
    width: 80%;
    .list_wrap_main {
      height: calc(100vh - 222px);
    }
    .body_full_screen {
      padding-left: ${space.space40};
      height: calc(100vh - 60px);
    }
  }
`;
function DigitalArtCreator() {
  const [searchImage, setSearchImage] = useState("");
  const [generatedImages, setGeneratedImages] = useState([]);
  const [submit, setSubmit] = useState(false);
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));

  const { setDigitalImgLink } = useContext(AppContext);

  const { loaderDispatch } = useContext(loaderContext);
  let navigate = useNavigate();
  const {
    setClubData,
    setIsMyMoment,
    setUserInfo,
    userInfo,
    gettingNotification,
    handelLogout,
  } = useContext(PostContext);

  useEffect(() => {
    setClubData({});
    setIsMyMoment(false);
    getStudentProfile();
  }, []);

  //getting student details on page load
  const getStudentProfile = async () => {
    try {
      let studentData = await getSubscriptionDetail(userData);
      if (studentData?.data?.status === 410) {
        handelLogout();
      }
      if (studentData?.data?.status === StatusCodes.OK) {
        let data = studentData?.data?.data;

        console.log("data?.sport_id,", data?.sport_id);
        setUserInfo(data);
        gettingNotification({
          StudentInfo: data,
          notification: false,
          pages: 1,
          loader: true,
        });
      } else {
        toast(studentData?.data?.massage, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelClick = () => {
    setSubmit(true);
    if (searchImage !== "") {
      const data = { prompt: searchImage, n: 3, size: "512x512" };
      loaderDispatch({
        isLoading: true,
      });
      const config = {
        // mode: "no-cors",
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_OPEN_API_KEY}`,
        },
        body: JSON.stringify(data),
      };

      const imgData = fetch(`${process.env.REACT_APP_OPEN_API}`, config);
      imgData
        .then((res) => {
          loaderDispatch({
            isLoading: false,
          });
          return res.json();
        })
        .then((data) => {
          setGeneratedImages([]);
          console.log("ress", data);

          setGeneratedImages(data?.data);
          toast(data?.error?.message, {
            type: "error",
            theme: "dark",
          });
        })
        .catch((err) => {
          loaderDispatch({
            isLoading: false,
          });

          console.error("err", err);
        });
    }
  };

  const handelChange = (e) => {
    const { value } = e?.target;
    if (value?.trim()?.length > 0 || value === "") {
      if (checkAlphaSpace(value)) {
        setSearchImage(value);
      }
    }
  };

  const checkAlphaSpace = (value) => {
    return /^[A-Za-z\s]*$/.test(value);
  };

  const handelNavigate = (link) => {
    setDigitalImgLink(link);
    navigate(student.digitalArtDetails);
  };

  const handelBaseConvert = async (imageLink) => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let body = {
        file: imageLink,
      };
      let result = await convertImage(body);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        handelSaveFilteredImage(result?.data?.data);
      } else {
        toast(result?.data?.message, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelSaveFilteredImage = async (dataUrl) => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let body = {
        postName: dataUrl,
        status: 1,
        user_id: userInfo?.id,
        digital_id: 1,
        postType: 1,
        school_id: userInfo?.User?.id,
      };
      let result = await saveFilterImage(body);
      loaderDispatch({
        isLoading: false,
      });

      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
          theme: "dark",
        });
      } else {
        toast(result?.data?.message, { type: "error", theme: "dark" });
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <DigitalArtCreatorRow>
        <div className='body_full_screen'>
          <SiteCard className='full_screen_card'>
            <div className='head'>
              <img src={IconDigitalArtCreator} alt='icon_digital_art' />
              <HeadingThree h3Data={"Digital Art Creator by Dalle.E 2"} />
            </div>
            <div className='list_wrap_main'>
              <SiteForm as='div' className='digital_art_form'>
                <div className='form_group'>
                  <Label labelData={"Digital Art*"} />
                  <input
                    type='text'
                    name='email'
                    placeholder='Enter description of the digital art you want to generate'
                    onChange={handelChange}
                    value={searchImage}
                  />
                  {searchImage === "" && submit && (
                    <p className='text-sm text-red-500'>
                      Please enter description
                    </p>
                  )}
                </div>
                {userInfo?.standard?.toLowerCase() !== GRADUATED &&
                  userInfo?.is_plan_expired !== "1" && (
                    <Button
                      tag='button'
                      type='submit'
                      cname='gradient generate-btn'
                      buttonData={"Generate Image"}
                      onClick={handelClick}
                    />
                  )}
              </SiteForm>

              <div className='student_blockchain'>
                {generatedImages?.length > 0 && (
                  <HeadingFour h4Data={"Student NFT art with blockchain"} />
                )}
                <ul className='student_blockchain_list'>
                  {generatedImages?.length > 0
                    ? generatedImages?.map((images, index) => {
                        return (
                          <li className='item' key={index}>
                            <img
                              src={images?.url ? images?.url : ""}
                              alt='student_blockchain_image'
                              loading='eager'
                              id={`${index}`}
                            />
                            <div className='action_group'>
                              <Button
                                tag='button'
                                type='submit'
                                cname='gradient'
                                buttonData={"Save"}
                                onClick={() =>
                                  handelBaseConvert(
                                    images?.url ? images?.url : ""
                                  )
                                }
                              />
                              <CustomLink>
                                <Button
                                  buttonData={"Edit"}
                                  cname='gradient'
                                  onClick={() => handelNavigate(images?.url)}
                                />
                              </CustomLink>
                            </div>
                          </li>
                        );
                      })
                    : null}

                  {/* <li className='item'>
                    <img
                      src={StudentBlockchain2}
                      alt='student_blockchain_image'
                    />
                    <div className='action_group'>
                      <Button
                        tag='button'
                        type='submit'
                        cname='gradient'
                        buttonData={"Save"}
                      />
                      <CustomLink>
                        <Button
                          buttonData={"Edit"}
                          cname='gradient'
                          onClick={() =>
                            handelNavigate(
                              "https://t4.ftcdn.net/jpg/00/97/58/97/360_F_97589769_t45CqXyzjz0KXwoBZT9PRaWGHRk5hQqQ.jpg"
                            )
                          }
                        />
                      </CustomLink>
                    </div>
                  </li> */}
                </ul>
              </div>
            </div>
          </SiteCard>
        </div>
      </DigitalArtCreatorRow>
    </>
  );
}

export default DigitalArtCreator;
