import React, { useState, useEffect, useContext } from "react";
import "../../App.css";
import Editor from "../../common/Editor.jsx";
import { useParams } from "react-router-dom";
import { loaderContext } from "../global loader/global-loader-provider";
import {
  cmsView,
  cmsUpdate,
  emailEdit,
  emailListing,
  getEmailTemplates,
} from "../../services/auth.services";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { admin } from "../../linksprovider";
import parse from "html-react-parser";

// import { ADMIN_USER_DATA } from "../../utils/constant";

function EmailEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { loaderState, loaderDispatch } = useContext(loaderContext);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [data, setData] = useState();
  const [latestData, setLatestData] = useState("");

  useEffect(() => {
    setEditorLoaded(true);
    // getEmailTemplate();
    getDataById();
  }, []);

  const getDataById = async () => {
    loaderDispatch({
      isLoading: true,
    });
    try {
      let result = await getEmailTemplates(id);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        setData(result?.data?.data?.description);
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelChange = (data) => {
    setData(data);
  };

  const handleGetChangeData = (changedData) => {
    setLatestData(changedData);
  };

  const handelUpdate = async () => {
    try {
      let body = {
        description: data,
      };
      if (body.description.length > 0) {
        loaderDispatch({
          isLoading: true,
        });
        let result = await emailEdit(id, body);
        if (result?.data?.status === StatusCodes.OK) {
          toast(result?.data?.message, {
            type: "success",
          });
          getDataById();
        } else {
          toast(result?.data?.message, {
            type: "error",
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='bg-gray-100 min-h-screen px-4 py-16 sm:px-6 sm:py-10 md:grid lg:px-8 fade-in'>
      <div className='py-12 xl:py-30 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden fade-in'>
        <div className='max-w-max lg:max-w-7xl mx-auto'>
          <div className='App'>
            <Editor
              name='description'
              value={data}
              handelChange={handelChange}
              editorLoaded={editorLoaded}
              handelUpdate={handleGetChangeData}
            />
          </div>
          <button
            className='bg-indigo-600 px-4 py-2 text-white mt-2 shadow rounded focus:bg-indigo-500'
            onClick={() => handelUpdate()}>
            Update
          </button>
          <button
            className='bg-white px-4 py-2  mt-2 shadow rounded ml-2'
            onClick={() => navigate(admin.editor)}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default EmailEdit;
