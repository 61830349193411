import React, { useContext, useEffect, useRef } from "react";
import { Main } from "../../../common/website/HOC";
import styled from "styled-components";
import HeaderWithoutLogin from "../../../components/website/header/HeaderWithoutLogin";
import Footer from "../../../components/website/footer/Footer.jsx";
import * as ThemeStyle from "../../../common/website/Variables";
import { Container } from "@material-ui/core";
import HeadingTwo from "../../../common/website/HeadingTwo";
import Paragraph from "../../../common/website/Paragraph";
import List from "../../../common/website/List";
import HeadingThree from "../../../common/website/HeadingThree";
import { AppContext } from "../../../App";

const CMSSec = styled.section`
  h2 {
    padding: 40px 0 20px;
  }
  h3 {
    padding-bottom: 20px;
  }
  .fw-bold {
    font-weight: 700;
    color: ${ThemeStyle.colors.primary};
    margin-top: 30px;
  }
`;

function TermsConditon() {
  const { setConstactUsHide } = useContext(AppContext);
  const ref = useRef(null);
  useEffect(() => {
    setConstactUsHide(false);
  }, []);

  const handleClick = () => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div>
      <HeaderWithoutLogin handleClick={handleClick} />
      <Main className='website_main'>
        <CMSSec>
          <Container>
            <HeadingTwo cname='' h2Data='Terms and Conditions ' />
            <HeadingThree h3Data='Welcome to YB3!' />
            <Paragraph pData="These terms and conditions outline the rules and regulations for the use of YB3's Website, located at www.yb3.com." />
            <Paragraph pData='By accessing this website, we assume you accept these terms and conditions. Do not continue to use YB3 if you do not agree to take all of the terms and conditions stated on this page.' />
            <Paragraph pData='The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company’s terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services, in accordance with and subject to, prevailing law of Netherlands. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same. ' />
            <Paragraph cname='fw-bold' pData='Cookies' />
            <Paragraph pData="We employ the use of cookies. By accessing YB3, you agreed to use cookies in agreement with the YB3's Privacy Policy." />
            <Paragraph pData='Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.' />
            <Paragraph cname='fw-bold' pData='License' />
            <Paragraph pData='Unless otherwise stated, YB3 and/or its licensors own the intellectual property rights for all material on YB3. All intellectual property rights are reserved. You may access this from YB3 for your own personal use subjected to restrictions set in these terms and conditions.' />
            <Paragraph cname='fw-bold' pData='You must not:' />
            <ul>
              <List liData='Republish material from YB3'></List>
              <List liData='Sell, rent or sub-license material from YB3'></List>
              <List liData='Reproduce, duplicate or copy material from YB3'></List>
              <List liData='Redistribute content from YB3'></List>
            </ul>
            <Paragraph pData='This Agreement shall begin on the date hereof. Our Terms and Conditions were created with the help of the Free Terms and Conditions Generator.' />
            <Paragraph pData='Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. YB3 does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of YB3, its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, YB3 shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.' />
            <Paragraph pData='YB3 reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.' />
            <Paragraph
              cname='fw-bold'
              pData='You warrant and represent that:'
            />
            <ul>
              <List liData='You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;'></List>
              <List liData='The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;'></List>
              <List liData='The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy'></List>
              <List liData='The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.'></List>
              <List liData='You hereby grant YB3 a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.'></List>
            </ul>
            <Paragraph cname='fw-bold' pData='Hyperlinking to our Content' />
            <Paragraph pData='The following organizations may link to our website without prior written approval:' />
            <ul>
              <List liData='Government agencies;'></List>
              <List liData='Search engines;'></List>
              <List liData='News organizations;'></List>
              <List liData='Online directory distributors may link to our website in the same manner as they hyperlink to the Websites of other listed businesses;'></List>
              <List liData='System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.'></List>
            </ul>
            <Paragraph pData='These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party’s site.' />
            <Paragraph pData='We may consider and approve other link requests from the following types of organizations:' />
            <ul>
              <List liData='commonly-known consumer and/or business information sources;'></List>
              <List liData='dot.com community sites;'></List>
              <List liData='associations or other groups representing charities;'></List>
              <List liData='online directory distributors;'></List>
              <List liData='internet portals;'></List>
              <List liData='accounting, law and consulting firms;'></List>
              <List liData='educational institutions and trade associations.'></List>
            </ul>
            <Paragraph pData='We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavourably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of YB3; and (d) the link is in the context of general resource information.' />
            <Paragraph pData='These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site.' />
            <Paragraph pData='If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to YB3. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.' />
            <Paragraph pData='Approved organizations may hyperlink to our website as follows:' />
            <ul>
              <List liData='By use of our corporate name; or'></List>
              <List liData='By use of the uniform resource locator being linked to; or'></List>
              <List liData='associations or other groups representing charities;'></List>
              <List liData='By use of any other description of our website being linked to that makes sense within the context and format of content on the linking party’s site.'></List>
            </ul>
            <Paragraph pData="No use of YB3's logo or other artwork will be allowed for linking absent a trademark license agreement." />
            <Paragraph cname='fw-bold' pData='iFrames' />
            <Paragraph pData='Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our website.' />
            <Paragraph cname='fw-bold' pData='Content Liability' />
            <Paragraph pData='We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libellous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third-party rights.' />
            <Paragraph cname='fw-bold' pData='Your Privacy ' />
            <Paragraph pData='Please read Privacy Policy' />
            <Paragraph cname='fw-bold' pData='Reservation of Rights' />
            <Paragraph pData='We reserve the right to request that you remove all links or any particular link to our website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and it’s linking policy at any time. By continuously linking to our website, you agree to be bound to and follow these linking terms and conditions.' />
            <Paragraph
              cname='fw-bold'
              pData='Removal of links from our website'
            />
            <Paragraph pData='If you find any link on our website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.' />
            <Paragraph pData='We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.' />
            <Paragraph cname='fw-bold' pData='Disclaimer' />
            <Paragraph pData='To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:' />
            <ul>
              <List liData='limit or exclude our or your liability for death or personal injury;'></List>
              <List liData='limit or exclude our or your liability for fraud or fraudulent misrepresentation;'></List>
              <List liData='limit any of our or your liabilities in any way that is not permitted under applicable law; or'></List>
              <List liData='The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.'></List>
            </ul>
            <Paragraph pData='As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.' />
            <Paragraph pData='Our physical address: 159 Horizon Ridge Lane, Charleston, South Carolina, USA 29465.' />
          </Container>
        </CMSSec>
      </Main>
      <Footer handleClick={handleClick} />
    </div>
  );
}

export default TermsConditon;
