import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  adminPostStatus,
  deletePost,
  postListing,
} from "../../../services/auth.services";
import { loaderContext } from "../../global loader/global-loader-provider";
import {
  ADMIN_USER_DATA,
  COMMENT,
  COMMON_WALL,
  POSTS,
} from "../../../utils/constant";
import { toast } from "react-toastify";
import IconMenuDropDown from "../../../common/IconMenuDropDown";
import { AiFillLike } from "react-icons/ai";
import { FaComment } from "react-icons/fa";
import AdminClubPostDetails from "../../../Pages/admin club managment/AdminClubPostDetails";
import AdminDeleteModel from "../../../common/AdminDeleteModel";
import AdminPostDetailModal from "../../../Pages/admin club managment/AdminPostDetailModal";
import { StatusCodes } from "http-status-codes";
import ActiveInactiveModal from "../../../common/ActiveInactiveModal";
import { BiArrowBack } from "react-icons/bi";
import { AppContext } from "../../../App";
import { SchoolContext } from "../../../common/SchoolDashboardWrapper";
import styled from "styled-components";

const Main = styled.div`
  background-color: #f3f4f6;
  height: 100vh;
  position: relative;
  overflow-y: scroll;
  -ms-overflow-style: transparent; /* IE and Edge */
  &::-webkit-scrollbar {
    background: transparent;
  }
`;

export default function PostDetails() {
  const [postData, setPostData] = useState({
    Posts: [],
  });
  const [search, setSearch] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openPostModal, setOpenPostModal] = useState(false);
  const [postDetail, setPostDetail] = useState({});
  const [type, setType] = useState("");
  const [id, setId] = useState("");
  const [openActiveModal, setOpenActiveModal] = useState(false);
  const [isActivePost, setActive] = useState(false);
  const [page, setPage] = useState(1);

  const { loaderDispatch } = useContext(loaderContext);
  let navigate = useNavigate();
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }
  const { setAdminActiveTab } = useContext(SchoolContext);
  let postref = useRef();

  useEffect(() => {
    const debunce = setTimeout(() => {
      getAllPostDetails(1);
    }, 300);
    setAdminActiveTab(POSTS);

    return () => {
      clearTimeout(debunce);
    };
  }, []);

  const getAllPostDetails = async (pages, loader = null) => {
    if (!loader) {
      loaderDispatch({
        isLoading: true,
      });
    }
    try {
      let params = {
        id: userData?.id,
        search: search.trim(),
        page: pages,
      };

      let result = await postListing(params);
      loaderDispatch({
        isLoading: false,
      });
      // if (search !== "") {
      //   setPostData((preVal) => {
      //     for (const key in preVal) {
      //       if (key == "Posts") {
      //         preVal["Posts"] = [];
      //       }
      //     }
      //     return preVal;
      //   });
      // }
      if (result?.data?.status === StatusCodes.OK) {
        let postListingData = result?.data?.data?.postsListing;

        if (postListingData?.rows?.length > 0 && pages !== 1) {
          setPostData((preVal) => {
            if (preVal?.Posts?.length > 0) {
              return { Posts: [...preVal?.Posts, ...postListingData?.rows] };
            } else {
              return { Posts: [...postListingData?.rows] };
            }
          });
        }
        if (pages === 1) {
          setPostData({ Posts: [...postListingData?.rows] });
        }
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const searchKeyWord = () => {
    getAllPostDetails(1);
  };

  const adminDeletePost = async () => {
    loaderDispatch({
      isLoading: true,
    });
    try {
      let result = await deletePost(id);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
        });

        setOpenDeleteModal(false);
        setPostData((preVal) => {
          for (let i = 0; i < preVal?.Posts?.length; i++) {
            if (preVal?.Posts[i]?.id === id) {
              delete preVal?.Posts[i];
            }
          }
          return preVal;
        });
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
        setOpenDeleteModal(false);
      }
    } catch (error) {
      console.error(error);
      setOpenDeleteModal(false);
    }
  };

  const handleStatus = async () => {
    loaderDispatch({
      isLoading: true,
    });
    try {
      let jsonData = {
        status: isActivePost ? "2" : "1",
      };
      let result = await adminPostStatus(id, jsonData);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
        });
        setPostData((preVal) => {
          for (let i = 0; i < preVal?.Posts?.length; i++) {
            if (preVal?.Posts[i]?.id === id) {
              preVal.Posts[i].status = isActivePost ? 2 : 1;
            }
          }
          return preVal;
        });
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
      setActive(!isActivePost);
      setOpenActiveModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  let pages = page;
  const handelScroll = () => {
    if (postref?.current) {
      const { scrollTop, scrollHeight, clientHeight } = postref.current;
      if (scrollTop + clientHeight === scrollHeight) {
        pages++;
        setPage(pages);
        let loader = 1;
        getAllPostDetails(pages, loader);
      }
    }
  };

  return (
    <>
      <Main ref={postref} onScroll={handelScroll}>
        <div className=' bg-white px-2 py-2 flex rounded-md shadow text-gray-500 hover:text-gray-600 mb-4 ml-6 absolute top-7 justify-center items-center'>
          <BiArrowBack className='text-xl outline-none ' />
          <button
            className=' cursor-pointer w-fit '
            onClick={() => navigate(-1)}>
            Go Back
          </button>
        </div>

        <div className=' md:w-[55%] lg:w-[55%] mx-auto'>
          <div className='space-y-6 lg:col-start-1 lg:col-span-2'>
            <AdminClubPostDetails
              viewData={postData}
              setOpenDeleteModal={setOpenDeleteModal}
              setOpenPostModal={setOpenPostModal}
              setPostDetail={setPostDetail}
              setType={setType}
              changePostStatus={setOpenActiveModal}
              setId={setId}
              setActive={setActive}
              setSearch={setSearch}
              type={COMMON_WALL}
              searchKeyWord={searchKeyWord}
              search={search}
            />
          </div>
        </div>
      </Main>
      <AdminDeleteModel
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        deleteData={adminDeletePost}
        header={"Delete Post"}
      />
      <AdminPostDetailModal
        openDeleteModal={openPostModal}
        setOpenDeleteModal={setOpenPostModal}
        postData={postDetail}
        type={type}
      />

      <ActiveInactiveModal
        openActiveModal={openActiveModal}
        setOpenActiveModal={setOpenActiveModal}
        isActivePost={isActivePost}
        handleStatus={handleStatus}
      />
    </>
  );
}
