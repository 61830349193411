import React, {
  createContext,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { Outlet, useNavigate } from "react-router-dom";
import HeaderWithLogin from "./HeaderWithLogin";
import HeaderWithoutLogin from "./HeaderWithoutLogin";
import "./header.css";

import { DashboardMain } from "../../../common/website/HOC.jsx";
import {
  clubView,
  getClassmatesList,
  getMyMomentPostListing,
  getNewNotification,
  getRecentActivity,
  getSubscriptionDetail,
  postListing,
  signatureAndPostListing,
} from "../../../services/auth.services";
import {
  ADMIN_USER_DATA,
  CLUB_POST,
  GRADUATED,
  MY_MOMENT_POST,
  MY_POST,
  OTHER_STUDENT_POST,
  SCHOOL_MOMENTS,
  TEAM_POST,
} from "../../../utils/constant";
import { StatusCodes } from "http-status-codes";
import { loaderContext } from "../../global loader/global-loader-provider";
import { student, website } from "../../../linksprovider";
import { toast } from "react-toastify";
import { AppContext } from "../../../App";
import { useEffect } from "react";

export const PostContext = createContext(null);

function MainHeader({}) {
  const [postData, setPostData] = useState([]);
  const [recentActivityData, setRecentActivityData] = useState([]);
  const [classmatesData, setClassmatesData] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [pageReload, setpageReload] = useState(false);
  const [clubData, setClubData] = useState({});
  const [teamData, setTeamData] = useState({});

  const [newNotificationCount, setNewNotificationCount] = useState(0);
  const [newNotification, setNewNotification] = useState([]);
  const [page, setPage] = useState(1);
  const [paginantionState, setPaginantionState] = useState(false);
  const [postlocation, setpostlocation] = useState("");
  const [isMyMoment, setIsMyMoment] = useState(false);
  const [myMomentPostData, setMyMomentPostData] = useState([]);
  const [noticationSection, setNoticationSection] = useState(false);

  const { loaderDispatch } = useContext(loaderContext);
  let scrollRef = useRef();

  let navigate = useNavigate();
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }

  const getinfo = async () => {
    let user = {
      email: userData.email,
    };
    let subDetails = await getSubscriptionDetail(user);
    if (subDetails?.data?.status === 410) {
      handelLogout();
    }

    if (subDetails?.data?.status === StatusCodes.OK) {
      let StudentInfo = subDetails?.data?.data;

      getAllPostDetails({
        id: StudentInfo?.User?.id,
        studentId: StudentInfo?.id,
        academicYear: StudentInfo?.academic_year,
        standard: StudentInfo?.standard,
        pages: 1,
      });
      setUserInfo(StudentInfo);
      gettingRecentActivity({ StudentInfo, loader: true });
      gettingClassList({ StudentInfo, loader: true });
      gettingNotification({ StudentInfo, loader: true, pages: "" });
      if (StudentInfo?.subscription_id === null) {
        navigate(student.subscribeOurPlan);
      } else if (
        StudentInfo?.subscription_id &&
        StudentInfo?.profile_picture &&
        StudentInfo?.is_plan_exist === "1" &&
        StudentInfo?.is_plan_expired === "0"
      ) {
        navigate(student.post);
      }
      // else if (
      //   StudentInfo?.subscription_id &&
      //   !StudentInfo?.profile_picture &&
      //   StudentInfo?.standard?.toLowerCase() !== GRADUATED
      // ) {
      //   navigate(student.dashbord);
      // }
    } else {
      toast(subDetails?.data?.message, {
        type: "error",
        theme: "dark",
      });
    }
  };

  const getAllPostDetails = async ({
    id = null,
    likes = null,
    studentId = null,
    clubId = null,
    userDatas = null,
    academicYear = null,
    standard = null,
    sport_id = null,
    get_user_id = null,
    schoolId = null,
    pages = null,
  }) => {
    if (!likes) {
      loaderDispatch({
        isLoading: true,
      });
    }
    try {
      let params = {
        id: id,
        search: searchData,
        userId: clubId ? null : userDatas?.id,
        studentId: studentId,
        club_id: clubId,
        academicYear: academicYear,
        standard: standard,
        sport_id: sport_id,
        get_user_id: get_user_id,
        schoolId: schoolId,
        page: pages ? pages : 1,
        user_type: 3,
      };

      let result = await postListing(params);
      loaderDispatch({
        isLoading: false,
      });

      if (result?.data?.status === StatusCodes.OK) {
        let postListingData = result?.data?.data?.postsListing?.rows;
        if (postListingData?.length > 0 && pages > 1) {
          setPostData((preVal) => {
            return [...preVal, ...postListingData];
          });
        }
        if (pages === 1 || !pages) {
          setPostData(postListingData);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const gettingRecentActivity = async ({
    StudentInfo,
    notification = null,
    pages = null,
    loader = null,
  }) => {
    try {
      if (loader) {
        loaderDispatch({
          isLoading: true,
        });
      }
      let body = {
        schoolId: StudentInfo?.User?.id,
        userId: StudentInfo?.id,
        notification: notification ? true : false,
        page: pages ? pages : 1,
      };
      let result = await getRecentActivity(body);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data.status === StatusCodes.OK) {
        let data = result?.data?.data?.docs;
        if (data?.length > 0 && pages > 1) {
          setRecentActivityData((preVal) => {
            return [...preVal, ...data];
          });
        }
        if (pages === 1 || !pages) setRecentActivityData(data);
      } else {
        toast(result?.data?.message, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const gettingClassList = async ({
    StudentInfo = null,
    search = null,
    pages = null,
    loader = null,
  }) => {
    try {
      if (loader) {
        loaderDispatch({
          isLoading: true,
        });
      }
      let body = {
        schoolId: StudentInfo?.User?.id,
        standard: StudentInfo?.standard,
        userId: StudentInfo?.id,
        academicYear: StudentInfo?.academic_year,
        search: search,
        page: pages ? pages : 1,
      };

      let result = await getClassmatesList(body);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        let data = result?.data?.data?.docs;
        if (data?.length > 0) {
          setClassmatesData((preVal) => {
            return [...preVal, ...data];
          });
        }
        if (pages === 1 || !pages) {
          setClassmatesData(data);
        }
      } else {
        toast(result?.data?.massage, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const gettingNotification = async ({
    StudentInfo = null,
    notification = null,
    pages = null,
    loader = null,
  }) => {
    try {
      if (loader) {
        loaderDispatch({
          isLoading: true,
        });
      }
      let body = {
        schoolId: StudentInfo?.User?.id,
        userId: StudentInfo?.id,
        page: pages ? pages : 1,
      };
      let result = await getNewNotification(body);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        let data = result?.data?.data?.docs;
        let count = result?.data?.data?.total;
        if (data?.length > 0 && pages > 1) {
          setNewNotification((preVal) => {
            return [...preVal, ...data];
          });
        }
        if (pages === 1 || !pages) {
          setNewNotification(data);
        }
        if (!notification) {
          setNewNotificationCount(count);
        }
      } else {
        toast(result?.data?.message, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getMomentPostListing = async ({
    userInfo,
    loader = null,
    pages = null,
    is_trade_moment = null,
    is_moment = null,
  }) => {
    try {
      if (loader) {
        loaderDispatch({
          isLoading: true,
        });
      }

      let body = {
        is_moment: is_moment,
        user_id: userInfo?.id,
        school_id: userInfo?.User?.id,
        page: pages ? pages : 1,
        is_trade_moment,
      };
      let result = await getMyMomentPostListing(body);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        let postList = result?.data?.data?.docs;
        if (postList?.length > 0 && pages > 1) {
          setMyMomentPostData((preVal) => {
            return [...preVal, ...postList];
          });
        }
        if (!pages) {
          setMyMomentPostData(postList);
        }
      } else {
        toast(result?.data?.message, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelPostList = async ({
    data = null,
    loader = null,
    pages = null,
  }) => {
    try {
      if (loader) {
        loaderDispatch({
          isLoading: true,
        });
      }
      let body = {
        schoolId: data?.User?.id,
        getUserId: data?.id,
        page: pages ? pages : 1,
      };
      let result = await signatureAndPostListing(body);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        let data = result?.data?.data?.postsListing?.rows;
        if (data?.length > 0 && pages > 1) {
          setPostData((preVal) => {
            return [...preVal, ...data];
          });
        }

        if (pages === 1 || !pages) {
          setPostData(data);
        }
      } else {
        toast(result?.data?.massage, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  let pages = page;
  const handelScroll = () => {
    if (scrollRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        pages++;
        setPage(pages);
        let loader = 1;
        // setPaginantionState(!paginantionState);
        // getAllPostDetails(pages, loader);
        if (postlocation === SCHOOL_MOMENTS) {
          getAllPostDetails({
            id: userInfo?.User?.id,
            studentId: userInfo?.id,
            academicYear: userInfo?.academic_year,
            standard: userInfo?.standard,
            pages: pages,
            likes: 1,
          });
        } else if (postlocation === MY_MOMENT_POST) {
          getMomentPostListing({
            userInfo,
            loader: false,
            pages: pages,
            is_moment: true,
          });
        } else if (postlocation === CLUB_POST) {
          getAllPostDetails({
            id: userInfo?.User?.id,
            clubId: userInfo?.club_id,
            studentId: userInfo?.id,
            pages: pages,
            likes: 1,
          });
        } else if (postlocation === TEAM_POST) {
          getAllPostDetails({
            id: userInfo?.User?.id,
            sport_id: userInfo?.sport_id,
            studentId: userInfo?.id,
            pages: pages,
            likes: 1,
          });
        } else if (postlocation === OTHER_STUDENT_POST) {
          handelPostList({ data: userInfo, pages: pages, loader: false });
        } else if (postlocation === MY_POST) {
          getAllPostDetails({
            id: userInfo?.User?.id,
            studentId: userInfo?.id,
            userDatas: userInfo,
            pages: pages,
            likes: false,
          });
        }
      }
    }
  };

  const handelLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    // setUserData({});

    navigate(website.studentLogin);
  };

  const value = useMemo(
    () => ({
      getAllPostDetails,
      page,
      isMyMoment,
      setIsMyMoment,
      userInfo,
      postData,
      recentActivityData,
      classmatesData,
      setSearchData,
      getinfo,
      pageReload,
      setpageReload,
      setUserInfo,
      gettingClassList,
      searchData,
      clubData,
      setClubData,
      gettingRecentActivity,
      setNewNotificationCount,
      newNotificationCount,
      gettingNotification,
      newNotification,
      paginantionState,
      setPage,
      postlocation,
      setpostlocation,
      teamData,
      setTeamData,
      getMomentPostListing,
      myMomentPostData,
      setMyMomentPostData,
      setPostData,
      handelPostList,
      handelLogout,
    }),
    [
      classmatesData,
      clubData,
      newNotification,
      newNotificationCount,
      pageReload,
      paginantionState,
      postData,
      postlocation,
      recentActivityData,
      searchData,
      teamData,
      userInfo,
      isMyMoment,
      myMomentPostData,
      page,
    ]
  );

  return (
    <>
      <div className='body'>
        <PostContext.Provider value={value}>
          <DashboardMain onScroll={handelScroll} ref={scrollRef}>
            <HeaderWithLogin cname='sidebar_left' />
            <Outlet />
          </DashboardMain>
        </PostContext.Provider>
      </div>
    </>
  );
}

export default MainHeader;
