import React, { useState, useEffect, useContext } from "react";
import ListLayout from "../../common/ListLayoutSingleIcon.jsx";
import {
  DELETE,
  UPDATE,
  VIEW,
  SCHOOL_CLUB,
  ADMIN_USER_DATA,
  SCHOOL_SPORTS_CLUB,
  VOTING,
  VOTING_HISTORY,
} from "../../utils/constant";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { school, website } from "../../linksprovider.js";
import { loaderContext } from "../../components/global loader/global-loader-provider.js";
import {
  clubDelete,
  clubListing,
  clubUpdate,
  clubView,
  getAcademicYearHistoryVoting,
  getCategoryDetails,
  getVotingStatus,
  schoolClubListing,
  schoolTeamListing,
  schoolVoteCategoryListing,
  teamDelete,
  teamUpdate,
  teamView,
  updateCategoryData,
  updateVotingStatus,
  voteCategoryDelete,
} from "../../services/auth.services.js";
import { StatusCodes } from "http-status-codes";
import AdminClubEdit from "../admin club managment/AdminClubEdit.jsx";
import { toast } from "react-toastify";
import AdminDeleteModel from "../../common/AdminDeleteModel.jsx";
import CreateTeamModal from "../school club managment/CreateTeamModal.jsx";
import { AppContext } from "../../App.js";
import { SchoolContext } from "../../common/SchoolDashboardWrapper.jsx";
import SearchFilter from "../../components/search_filter/SearchFilter.jsx";

// import { AdminContext } from "../../common/DashboardWrapper.jsx";

function SchoolVotingHistory() {
  const [data, setData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [viewData, setViewData] = useState({});
  const [sortby, setSortby] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentItems, setCurrentItems] = useState(null);
  const [dataCount, setDataCount] = useState(1);
  const [itemOffset, setItemOffset] = useState(1);
  const [orderby, setOrderby] = useState(true);
  const [editData, setEditData] = useState({});
  const [openEditModal, setOpenEditModal] = useState(false);
  const [error, setError] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedData, setDeletedData] = useState(0);
  const [dataToUpdate, setDataToUpdate] = useState({});
  const [votingStatus, setVotingStatus] = useState({});
  const [openCreateClubModal, setOpenCreateClubModal] = useState(false);
  const [isHistory, setIsHistory] = useState(true);

  let navigate = useNavigate();
  const { loaderState, loaderDispatch } = useContext(loaderContext);
  const { setAdminActiveTab } = useContext(SchoolContext);
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }
  const tableHeader = [
    // {
    //   key: "id",
    //   value: "id",
    // },
    // {
    //   key: "School Name",
    //   subValue: "User",
    //   value: "school_name",
    // },
    {
      key: "S.No.",
      value: "key",
      clickable: false,
      sorting: false,
    },
    {
      key: "Academic year",
      value: "voting_year",
      clickable: false,
      sorting: false,
    },
    // { key: "Status", value: "status", clickable: false, sorting: true },

    // {
    //   key: "winner 1",
    //   positionInArray: "0",
    //   value: "winners",
    //   subValue: "User",
    //   subSubValue: "student_name",
    //   clickable: false,
    //   sorting: true,
    // },
    // {
    //   key: "winner 2",
    //   positionInArray: "1",
    //   value: "winners",
    //   subValue: "User",
    //   subSubValue: "student_name",
    //   clickable: false,
    //   sorting: true,
    // },
    // {
    //   key: "winner 3",
    //   positionInArray: "2",
    //   value: "winners",
    //   subValue: "User",
    //   subSubValue: "student_name",
    //   clickable: false,
    //   sorting: true,
    // },

    // { key: "Created By", value: "created_by" },
    // {
    //   key: "Academic Year",
    //   value: "total_members",
    //   clickable: false,
    //   sorting: true,
    // },
    // {
    //   key: "Total Post",
    //   value: "total_post",
    //   clickable: false,
    //   sorting: false,
    // },

    {
      key: "options",
      value: "options",
    },
  ];

  const option = [
    {
      name: "Select Status",
      value: editData?.status == 1 ? "1" : "2",
    },
    {
      name: "Active",
      value: "1",
    },
    {
      name: "Inactive",
      value: "2",
    },
  ];

  const edit = [
    {
      label: "Category Name",
      type: "text",
      value: dataToUpdate?.name,
      name: "name",
      maxLength: "60",
      minLength: "0",
    },
  ];

  const itemsOnPage = [
    { option: 10 },
    { option: 20 },
    { option: 50 },
    { option: 70 },
    { option: 100 },
  ];

  let currentYear = new Date().getFullYear();
  const years = [
    {
      name: "Select academic year",
      value: "",
    },

    {
      name: currentYear - 1,
      value: currentYear - 1,
    },
    {
      name: currentYear - 2,
      value: currentYear - 2,
    },
    {
      name: currentYear - 3,
      value: currentYear - 3,
    },
    {
      name: currentYear - 4,
      value: currentYear - 4,
    },
    {
      name: currentYear - 5,
      value: currentYear - 5,
    },
  ];

  // useEffect(() => {
  //   setAdminActiveTab(CLUB);
  // }, []);

  useEffect(() => {
    const debounce = setTimeout(() => {
      handelSchoolVoteCategoryListing();
    }, 300);
    setAdminActiveTab(VOTING_HISTORY);
    // gettingVotingStatus();
    return () => {
      clearTimeout(debounce);
    };
  }, [searchItem, sortby, itemOffset, itemsPerPage, orderby]);

  useEffect(() => {
    setCurrentItems(data);
    setPageCount(Math.ceil(dataCount / itemsPerPage));
  }, [data, dataCount, itemsPerPage]);

  //function to get selected options
  const handleOptions = (optionValue, item) => {
    console.log("test", optionValue, item);
    switch (optionValue) {
      case VIEW:
        navigate(`/school/voting/category/history/${item.voting_year}`);
        break;
      case UPDATE:
        setOpenEditModal(true);
        categoryEdit(item.id);
        break;

      case DELETE:
        setOpenDeleteModal(true);
        setDeletedData(item.id);
        break;

      default:
        break;
    }
  };

  //get voting status
  // const gettingVotingStatus = async () => {
  //   try {
  //     loaderDispatch({
  //       isLoading: true,
  //     });

  //     let params = {
  //       schoolId: userData.id,
  //     };

  //     let result = await getVotingStatus(params);

  //     if (result?.data?.status === StatusCodes.OK) {
  //       let data = result?.data?.data;
  //       setVotingStatus(data);
  //     } else {
  //       toast(result?.data?.message, {
  //         type: "error",
  //       });
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  //function to get club list
  const handelSchoolVoteCategoryListing = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let order = orderby ? "desc" : "asc";

      let params = {
        schoolId: userData.id,
        sort: sortby,
        order: order,
      };

      let result = await getAcademicYearHistoryVoting(params);
      console.log(result);

      loaderDispatch({
        isLoading: false,
      });
      setData([]);
      setDataCount(0);
      if (result?.data?.status === StatusCodes.OK) {
        const studentData = result?.data?.data;
        const studentDataCount = result?.data?.data?.totalPage;
        console.log(studentData);

        setData(studentData);
        setDataCount(studentDataCount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //handel shorting
  const handleSorting = (data, order) => {
    console.log("sorting works", data);
    setSortby(data);
    setOrderby(order);
  };

  //handel pagination
  const handlePageClick = (event) => {
    let newOffset = (event.selected * itemsPerPage) % dataCount;
    console.log("pagination", newOffset);
    // dataCall(body);
    setItemOffset(newOffset / 10 + 1);
  };
  console.log("ss", dataToUpdate);
  //function to update club data
  const updateData = async () => {
    try {
      if (dataToUpdate?.name === "") {
        setError((preVal) => {
          return { ...preVal, name: true };
        });
      }

      // console.log(checkError.length, data.length, checkError);
      console.log("dataToUpdate", dataToUpdate);
      let data = {
        name: dataToUpdate?.sportName?.trim(),
        description: dataToUpdate?.description?.trim(),
        status: dataToUpdate?.status,
        academic_year: currentYear,
        // standard: dataToUpdate?.standard?.trim(),
      };

      if (dataToUpdate?.name !== "") {
        loaderDispatch({
          isLoading: true,
        });
        let result = await updateCategoryData(editData.id, data);
        loaderDispatch({
          isLoading: false,
        });
        if (result?.data?.status === StatusCodes.OK) {
          let item = result?.data?.data;
          console.log("item updated", item);
          setEditData(item);
          setOpenEditModal(false);
          handelSchoolVoteCategoryListing();
          toast(result?.data?.message, {
            type: "success",
          });
        } else {
          setOpenEditModal(false);
          handelSchoolVoteCategoryListing();
          toast(result?.data?.message, {
            type: "error",
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  //handel deletion of category
  const deleteData = async () => {
    try {
      let result = await voteCategoryDelete(deletedData);

      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
        });
        setOpenDeleteModal(false);
        handelSchoolVoteCategoryListing();
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
      setOpenDeleteModal(false);
    }
  };

  //handel get edit category details
  const categoryEdit = async (id) => {
    try {
      console.log("asdfafd", id);
      loaderDispatch({
        isLoading: true,
      });
      let param = {
        id: id,
      };
      let result = await getCategoryDetails(param);
      console.log(result);

      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        let item = result?.data?.data;
        console.log("ITEM", item);
        setEditData(item);
        for (const key in item) {
          setError((preVal) => {
            return { ...preVal, [key]: false };
          });
        }
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  //handel voting status
  const handelUpdateVotingStatus = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });

      let body = {
        is_voting: votingStatus.is_voting == 1 ? 0 : 1,
        school_id: userData?.id,
      };

      let result = await getAcademicYearHistoryVoting(body);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        setVotingStatus((preVal) => {
          return {
            ...preVal,
            is_voting: preVal.is_voting == 1 ? 0 : 1,
          };
        });
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setSwitchSetting = (value) => {
    setIsHistory(value);
  };

  return (
    <div className=''>
      <div className={"p-2  space-x-2 flex justify-between"}>
        {/* <SearchFilter
          setSearchItem={setSearchItem}
          searchItem={searchItem}
          setPage={setItemOffset}
          cname={
            "mt-1 block w-1/2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
          }
        /> */}
        <div className='space-x-6 float-left py-3 px-2 '>
          <label
            onClick={() => navigate(-1)}
            className={
              "border-purple-600 border px-5 py-2 rounded-3xl  text-purple-600 text-sm font-bold cursor-pointer hover:bg-purple-600 hover:text-white"
            }>
            Back
          </label>
        </div>
        {/* {!isHistory ? null : (
          <div className='flex'>
            {" "}
            <label className='block text-sm font-medium text-gray-700 mt-3 mb-3'>
              Voting Status
              <label
                htmlFor='default-toggle'
                className='inline-flex relative items-center cursor-pointer align-middle ml-5'>
                <input
                  type='checkbox'
                  checked={votingStatus.is_voting == 1 ? true : false}
                  name='status'
                  onChange={() => {
                    handelUpdateVotingStatus();
                  }}
                  id='default-toggle'
                  className='sr-only peer'
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
            </label>
            <button
              className=' text-sm mr-1 px-4 py-2 bg-indigo-500 mt-1 block text-white rounded shadow-md font-semibold ml-3'
              onClick={() => setOpenCreateClubModal(true)}>
              Create Category
            </button>
          </div>
        )} */}
      </div>

      <AdminDeleteModel
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        deleteData={deleteData}
        header={"Delete Team"}
      />
      {(() => {
        switch ("test") {
          case "":
            return <></>;
        }
      })()}
      <ListLayout
        handleOptions={handleOptions}
        tableHeader={tableHeader}
        handleSorting={handleSorting}
        currentItems={currentItems}
        type={false}
        isHistory={isHistory}
        categoryType={2}
      />
      {/* <div className='grid grid-cols-2 '>
        {data?.length > 0 && (
          <div
            className='block lg:inline-block lg:mt-0'
            style={{ marginTop: "5px" }}>
            <label htmlFor='item' className='ml-5'>
              Record Per Page:
            </label>
            <select
              className='ml-3 bg-white border p-2 rounded'
              name='item'
              id='item'
              onChange={(e) => {
                setItemsPerPage(parseInt(e.target.value));
              }}>
              {itemsOnPage.map((val, i) => {
                return (
                  <option value={val.option} key={i}>
                    {val.option}
                  </option>
                );
              })}
            </select>
          </div>
        )}

        <div className='mt-2'>
          <ReactPaginate
            breakLabel='...'
            nextLabel='Next >'
            onPageChange={handlePageClick}
            pageCount={pageCount}
            previousLabel='< Previous'
            renderOnZeroPageCount={null}
            containerClassName={"pagination"}
            pageClassName={"pageClass"}
            forcePage={itemOffset - 1}
          />
        </div>
      </div>*/}
      {!data.length && !loaderState.isLoading ? (
        <div className='flex absolute left-1/2 top-1/2 mx-auto'>
          Data not found..!!
        </div>
      ) : null}
    </div>
  );
}

export default SchoolVotingHistory;
