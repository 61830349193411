import { StatusCodes } from "http-status-codes";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { SchoolContext } from "../../common/SchoolDashboardWrapper";
import { loaderContext } from "../../components/global loader/global-loader-provider";
import YearBookModal from "../../components/schools/schoolmodals/YearBookModal";
import YearBookHistory from "../../components/schools/YearBookComponents/YearBookHistory";
import YearSelection from "../../components/schools/YearBookComponents/YearSelection";
import {
  getYearBookHistory,
  createCollage,
} from "../../services/auth.services";
import { ADMIN_USER_DATA, YEARBOOK } from "../../utils/constant";

function Schoolyearbook() {
  const [academicYear, setacadmicYear] = useState();
  const [open, setOpen] = useState(false);
  const [yearBookLink, setYearBookLink] = useState("");
  const [historyData, setHistoryData] = useState([]);

  const { loaderDispatch } = useContext(loaderContext);
  const { setAdminActiveTab } = useContext(SchoolContext);

  let sub = true;
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));

  useEffect(() => {
    if (sub) {
      checkHistory();
    }
    setAdminActiveTab(YEARBOOK);
    return () => (sub = false);
  }, []);

  const publishYearBook = async (pageLoad) => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let params = {};
      if (pageLoad) {
        params = {
          academicYear: "",
          schoolId: userData?.id,
        };
      } else {
        params = {
          academicYear: academicYear,
          schoolId: userData?.id,
        };
      }
      let result = await createCollage(params);

      if (result?.data?.status === StatusCodes.OK) {
        loaderDispatch({
          isLoading: false,
        });
        toast(result?.data?.message, {
          type: "success",
        });
        if (result?.data?.data?.length > 0) {
          setHistoryData((preVal) => {
            return [...result?.data?.data, ...preVal];
          });
        }
      } else {
        loaderDispatch({
          isLoading: false,
        });
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      loaderDispatch({
        isLoading: false,
      });
      console.error(error);
    }
  };

  const checkHistory = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });

      let params = {
        schoolId: userData?.id,
      };

      let result = await getYearBookHistory(params);

      loaderDispatch({
        isLoading: false,
      });

      if (result?.data?.status === StatusCodes.OK) {
        setHistoryData(result?.data?.data);
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='py-6 px-6 h-screen w-full'>
      <YearSelection
        setacadmicYear={setacadmicYear}
        checkHistory={publishYearBook}
        academicYear={academicYear}
      />
      {historyData?.length > 0 && (
        <YearBookHistory
          setOpen={setOpen}
          historyData={historyData}
          setYearBookLink={setYearBookLink}
        />
      )}

      {historyData?.length <= 0 && (
        <div className='flex absolute left-1/2 top-1/2 mx-auto'>
          Data not found..!!
        </div>
      )}
      <YearBookModal
        open={open}
        setOpen={setOpen}
        yearBookLink={yearBookLink}
        heading='YearBook'
      />
    </div>
  );
}

export default Schoolyearbook;
