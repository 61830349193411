import { StatusCodes } from "http-status-codes";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AdminContext } from "../../common/DashboardWrapper";
import { getNFTPosts } from "../../services/auth.services";
import { NFT } from "../../utils/constant";
import { loaderContext } from "../global loader/global-loader-provider";
import YearBookModal from "../schools/schoolmodals/YearBookModal";

import NFTImageCard from "./NFTImageCard";

function AdminNFTPost() {
  const [nftPostData, setNftPostData] = useState([]);

  let { pathname } = useLocation();

  const { setAdminActiveTab } = useContext(AdminContext);
  const { loaderDispatch } = useContext(loaderContext);
  const [open, setOpen] = useState(false);
  const [yearBookLink, setYearBookLink] = useState("");

  let { id } = useParams();

  let sub = true;
  useEffect(() => {
    if (sub) {
      setAdminActiveTab(NFT);
      nftPostList();
    }
    return () => (sub = false);
  }, []);

  const nftPostList = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let params = {
        userId: id,
      };

      let result = await getNFTPosts(params);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        let data = result?.data?.data;
        setNftPostData(data);
      } else {
        toast(result?.data?.massage, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelClick = (link) => {
    setOpen(true);
    setYearBookLink(link);
  };
  return (
    <div className='container px-3 py-3'>
      <div className=' h-screen w-full '>
        <p className='mb-5 font-semibold text-gray-700 text-xl'>NFT Posts</p>
        <div className='grid lg:grid-cols-8 md:grid-cols-7 sm:grid-cols-5 grid-cols-2 gap-3 w-full '>
          {nftPostData?.length > 0 ? (
            nftPostData?.map((val, i) => {
              return (
                <NFTImageCard
                  key={i}
                  nftimage={val?.name}
                  onClick={() => handelClick(val?.name)}
                />
              );
            })
          ) : (
            <p>Data not found..!!..!!</p>
          )}
        </div>
      </div>
      <YearBookModal
        open={open}
        setOpen={setOpen}
        yearBookLink={yearBookLink}
        nft={NFT}
        heading='NFT Post'
      />
    </div>
  );
}

export default AdminNFTPost;
