import React from "react";

import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

export default function MultiSelect({
  colourOptions,
  disabled,
  onChange,
  name,
  multiSelectValue,
  placeholder,
}) {
  return (
    <Select
      closeMenuOnSelect={false}
      components={animatedComponents}
      name={name}
      value={multiSelectValue}
      isMulti={true}
      isDisabled={disabled}
      onChange={onChange}
      options={colourOptions}
      placeholder={placeholder}
    />
  );
}
