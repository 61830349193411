import React from "react";

function CustomRadioField({
  name,
  id,
  cname,
  checked,
  defaultChecked,
  onChange,
}) {
  return (
    <>
      <input
        type='radio'
        name={name}
        id={id}
        className={cname}
        checked={checked}
        defaultChecked={defaultChecked}
        onChange={onChange}
      />
    </>
  );
}

export default CustomRadioField;
