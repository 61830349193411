import React from "react";
import styled from "styled-components";
import { SiteCard, SiteForm } from "../../../common/website/HOC.jsx";
import { colors, space } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";
import { Formik, Field } from "formik";
import Label from "../../../common/website/Label.jsx";
import Paragraph from "../../../common/website/Paragraph.jsx";
import { Link } from "react-router-dom";

import ProfileImage from "../../../images/website/profile_image.png";
import ProfileImage2 from "../../../images/website/profile_image2.png";
import ProfileImage3 from "../../../images/website/profile_image3.png";
import ProfileImage4 from "../../../images/website/profile_image4.png";
import ProfileImage5 from "../../../images/website/profile_image5.png";
import ProfileImage6 from "../../../images/website/profile_image6.png";
import ProfileImage7 from "../../../images/website/profile_image7.png";
import ProfileImage8 from "../../../images/website/profile_image8.png";
import ProfileImage9 from "../../../images/website/profile_image9.png";
import ProfileImage10 from "../../../images/website/profile_image10.png";
import IconCheck from "../../../images/website/icon_check_blue.svg";

const ClassmatesActionRow = styled.div`
  .classmates_card {
    border-radius: ${space.space16};
  }
  .head {
    margin-bottom: ${space.space14};
  }
  .search_group {
    border-bottom: 1px solid ${colors.lightwhiteborder};
    margin-bottom: ${space.space24};
    padding-bottom: ${space.space24};
  }
  .classmates_list {
    margin: 0;
    height: calc(100vh - 438px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(172 172 172 / 10%);
      border-radius: 8px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    /* Fire Fox */
    scrollbar-color: rgb(172 172 172 / 10%) transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background-color: transparent;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(172 172 172 / 10%);
      border-radius: 8px;
    }
    /* End Fire Fox */
    li {
      margin-bottom: 18px;
      &:last-child {
        margin: 0;
      }
    }
    .link {
      display: inline-flex;
      align-items: center;
      img {
        width: 26px;
        height: 26px;
        object-fit: cover;
        border-radius: 100%;
        margin-right: ${space.space8};
      }
      p {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin: 0;
        transition: all 0.4s;
      }
      &:hover,
      &.active {
        p {
          transition: all 0.4s;
          color: ${colors.primary};
        }
      }
    }
  }
  .check_group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    grid-gap: 10px;
    margin-bottom: ${space.space16};
    .left {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      white-space: nowrap;
      input[type="checkbox"] {
        width: 18px;
        height: 18px;
        cursor: pointer;
        border: 1px solid ${colors.white};
        border-radius: 5px;
        appearance: none;
        overflow: hidden;
        padding: 0;
        &:checked {
          background-image: url(${IconCheck});
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          border: 1px solid ${colors.secondary};
        }
        &[value="true"] {
          background-image: url(${IconCheck});
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          border: 1px solid ${colors.secondary};
        }
      }
      label {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1.063rem;
        padding-left: 8px;
        margin: 0;
        cursor: pointer;
      }
    }
  }
  @media ${device.mobileM} {
    .check_group {
      .left {
        label {
          font-size: 0.875rem;
          padding-left: 14px;
        }
        input[type="checkbox"] {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  @media ${device.laptop} {
    .classmates_list {
      height: calc(100vh - 488px);
      .link {
        img {
          width: 34px;
          height: 34px;
          margin-right: ${space.space12};
        }
      }
    }
  }
  @media ${device.laptopL} {
    .classmates_list {
      height: calc(100vh - 508px);
    }
  }
`;

function ClassmatesAction() {
  return (
    <>
      <ClassmatesActionRow>
        <SiteCard className='classmates_card'>
          <Paragraph cname='head c' pData={"Classmates"} />
          <Formik
            initialValues={{ email: "", password: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
                setSubmitting(false);
              }, 400);
            }}>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <SiteForm as='form' className='upload_form'>
                <div className='form_group search_group'>
                  <Field type='search' name='search' placeholder='Search' />
                </div>
                <div className='form_group check_group'>
                  <div className='left'>
                    <Field type='checkbox' name='checkbox' id='All' />
                    <Label for='All' labelData={"All"} />
                  </div>
                </div>
              </SiteForm>
            )}
          </Formik>
          <ul className='classmates_list'>
            <li>
              <Link to='' className='link active'>
                <img src={ProfileImage} alt='Profile' />{" "}
                <Paragraph cname='smallParagaraph' pData={"Michelle Coleman"} />
              </Link>
            </li>
            <li>
              <Link to='' className='link'>
                <img src={ProfileImage2} alt='Profile' />{" "}
                <Paragraph cname='smallParagaraph' pData={"Brandon Chapman"} />
              </Link>
            </li>
            <li>
              <Link to='' className='link'>
                <img src={ProfileImage3} alt='Profile' />{" "}
                <Paragraph cname='smallParagaraph' pData={"Tracey Bailey"} />
              </Link>
            </li>
            <li>
              <Link to='' className='link'>
                <img src={ProfileImage4} alt='Profile' />{" "}
                <Paragraph cname='smallParagaraph' pData={"Yvonne Dyer"} />
              </Link>
            </li>
            <li>
              <Link to='' className='link'>
                <img src={ProfileImage5} alt='Profile' />{" "}
                <Paragraph cname='smallParagaraph' pData={"Anthony Gibson"} />
              </Link>
            </li>
            <li>
              <Link to='' className='link'>
                <img src={ProfileImage6} alt='Profile' />{" "}
                <Paragraph cname='smallParagaraph' pData={"Edward Hamilton"} />
              </Link>
            </li>
            <li>
              <Link to='' className='link'>
                <img src={ProfileImage7} alt='Profile' />{" "}
                <Paragraph cname='smallParagaraph' pData={"Wendy Lambert"} />
              </Link>
            </li>
            <li>
              <Link to='' className='link'>
                <img src={ProfileImage8} alt='Profile' />{" "}
                <Paragraph cname='smallParagaraph' pData={"Gavin Lyman"} />
              </Link>
            </li>
            <li>
              <Link to='' className='link'>
                <img src={ProfileImage9} alt='Profile' />{" "}
                <Paragraph cname='smallParagaraph' pData={"Rachel Mathis"} />
              </Link>
            </li>
            <li>
              <Link to='' className='link'>
                <img src={ProfileImage10} alt='Profile' />{" "}
                <Paragraph cname='smallParagaraph' pData={"Jason Paterson"} />
              </Link>
            </li>
          </ul>
        </SiteCard>
      </ClassmatesActionRow>
    </>
  );
}

export default ClassmatesAction;
