import React, { useContext, useEffect, useState } from "react";
import { StatusCodes } from "http-status-codes";
import { AppContext } from "../../App";
import { Link, useNavigate } from "react-router-dom";
import { admin, school, student, website } from "../../linksprovider";
import {
  ADMIN_USER,
  ADMIN_USER_DATA,
  REMEMBER_STATE,
} from "../../utils/constant";
import { login } from "../../services/auth.services";
import logo from "../../images/logo.svg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { loaderContext } from "../../components/global loader/global-loader-provider";
import { IoEyeSharp } from "react-icons/io5";
import { BsEyeSlashFill } from "react-icons/bs";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";

function Login() {
  // component states
  const [cookies, setCookie] = useCookies([ADMIN_USER_DATA]);

  const { setRemember, remember } = useContext(AppContext);
  const navigate = useNavigate();

  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  if (!userData) {
    // userData = JSON.parse(localStorage.getItem());
  }

  //user data to login

  const loginData = {
    email: remember ? cookies.adminEmail : "",
    password: remember ? cookies.adminPassword : "",
  };

  const [passwordHideAndShow, setPasswordHideAndShow] = useState(false);

  const { loaderState, loaderDispatch } = useContext(loaderContext);

  const navigation = useNavigate();

  useEffect(() => {
    localStorage.setItem(REMEMBER_STATE, remember);
  }, [remember]);

  const validation = Yup.object({
    email: Yup.string()
      .trim()
      .email("Invalid email address")
      .required("Please fill the field"),
    password: Yup.string()
      .required("Please fill the field")
      .min(4, "Password must be at least 4 characters long"),
  });

  useEffect(() => {
    if (userData && userData.user_type === 1) {
      navigation(admin.dashbord);
    } else if (userData && userData.user_type === 2) {
      navigation(school.dashbord);
    } else {
      navigation(admin.login);
    }
  }, []);

  //handles Login
  const handelLogin = async (values, { setSubmitting }) => {
    console.log(values);

    try {
      let body = {
        email: values.email.trim(),
        password: values.password,
        user_type: [1, 2],
      };
      loaderDispatch({
        isLoading: true,
      });

      const result = await login(body);

      loaderDispatch({
        isLoading: false,
      });

      if (result?.data?.status === StatusCodes.OK) {
        // localStorage.clear();
        sessionStorage.clear();
        if (remember) {
          setCookie("adminEmail", values.email, {
            path: admin.login,
            expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
          });
          setCookie("adminPassword", values.password, {
            path: admin.login,
            expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
          });
        }

        sessionStorage.setItem(
          ADMIN_USER,
          JSON.stringify(result?.data?.data?.token)
        );
        sessionStorage.setItem(
          ADMIN_USER_DATA,
          JSON.stringify(result?.data?.data)
        );

        if (result?.data?.data && result?.data?.data.user_type === 1) {
          navigation(admin.dashbord);
        } else if (result?.data?.data && result?.data?.data.user_type === 2) {
          navigation(school.dashbord);
        } else {
          navigation(admin.login);
        }
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
      setSubmitting(false);
    } catch (error) {}
  };

  console.log("remember");
  return (
    <div className='min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
      <div className='sm:mx-auto sm:w-full sm:max-w-md'>
        <img className='mx-auto h-28 w-auto' src={logo} alt='Workflow' />
        <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
          Sign in to your account
        </h2>
      </div>

      <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
        <Formik
          initialValues={loginData}
          validationSchema={validation}
          onSubmit={handelLogin}>
          {({ isSubmitting }) => (
            <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
              <Form className='space-y-6'>
                <div>
                  <label
                    htmlFor='email'
                    className='block text-sm font-medium text-gray-700'>
                    Email address
                  </label>
                  <div className='mt-1'>
                    <Field
                      id='email'
                      name='email'
                      type='text'
                      autoComplete='off'
                      className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                    />
                  </div>

                  <div className='text-red-600 text-sm'>
                    <ErrorMessage name='email' as='div' />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor='password'
                    className='block text-sm font-medium text-gray-700'>
                    Password
                  </label>
                  <div className='flex mt-1 appearance-none w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'>
                    <Field
                      id='password'
                      name='password'
                      type={passwordHideAndShow ? "text" : "password"}
                      autoComplete='off'
                      className=' w-full border-0  focus:outline-none sm:text-sm'
                    />
                    {passwordHideAndShow ? (
                      <BsEyeSlashFill
                        className='text-lg text-gray-400 cursor-pointer'
                        onClick={() => setPasswordHideAndShow(false)}
                      />
                    ) : (
                      <IoEyeSharp
                        className='text-lg text-gray-400 cursor-pointer'
                        onClick={() => setPasswordHideAndShow(true)}
                      />
                    )}
                  </div>

                  <div className='text-red-600 text-sm'>
                    <ErrorMessage name='password' as='div' />
                  </div>
                </div>

                <div className='flex items-center justify-between'>
                  <div className='flex items-center'>
                    <input
                      id='remember-me'
                      name='remember-me'
                      defaultChecked={remember}
                      onChange={() => {
                        setRemember((preVal) => {
                          return !preVal;
                        });
                      }}
                      type='checkbox'
                      className='h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded'
                    />
                    <label
                      htmlFor='remember-me'
                      className='ml-2 block text-sm text-gray-900'>
                      Remember me
                    </label>
                  </div>

                  <div className='text-sm'>
                    <Link
                      to={admin.forgotPassword}
                      className='font-medium text-indigo-600 hover:text-indigo-500'>
                      Forgot your password?
                    </Link>
                  </div>
                </div>

                <div>
                  <button
                    type='submit'
                    disabled={isSubmitting}
                    className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    // onClick={handelLogin}
                  >
                    Sign in
                  </button>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default Login;
