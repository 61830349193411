import React from "react";

const Card = ({ data }) => {
  return (
    <div className='shadow-lg flex w-full cursor-pointer'>
      <main className='py-7 px-5 rounded-r-md w-full bg-white'>
        <span className='flex flex-row justify-between'>
          <h4 className='uppercase text-sm'>{data?.student_name}</h4>
        </span>
        <h1 className=' text-sm'>{data?.email}</h1>
      </main>
    </div>
  );
};

export default Card;
