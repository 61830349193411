import React, { useState, Fragment, useEffect, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { GrClose } from "react-icons/gr";
import { loaderContext } from "../components/global loader/global-loader-provider";
import {
  getLabelDetails,
  updateLabelData,
  updateUpdatedLabel,
} from "../services/auth.services";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import { ADMIN_USER_DATA } from "../utils/constant";

function SchoolLabelEdit({
  openViewModal,
  setOpenViewModal,
  view,
  itemId,
  handelLabelList,
  header,
}) {
  const [dataToUpdate, setDataToUpdate] = useState({});
  const [error, setError] = useState({});
  const { loaderDispatch } = useContext(loaderContext);
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));

  useEffect(() => {
    if (openViewModal) {
      getLabelDetail();
    }
  }, [itemId, openViewModal]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value?.toString().trim().length > 0 || value === "") {
      setDataToUpdate((preVal) => {
        return { ...preVal, [name]: value };
      });
    }

    setError((preVal) => {
      return { ...preVal, [name]: false };
    });
  };

  //regex for alphabets and space
  const alphaSpace = /^[A-Za-z\s]*$/;
  //checking condition
  const checkAlphaSpace = (value) => {
    return alphaSpace.test(value);
  };

  const getLabelDetail = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });

      let params = {
        id: itemId,
      };

      let result = await getLabelDetails(params);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        let data = result?.data?.data;
        setDataToUpdate({
          label_id: data?.id,
          labelName: data?.labelName,
          name: data?.MasterLabel?.name,
          status: data?.status,
          type: data?.type,
        });

        setError({
          label_id: false,
          labelName: false,
          name: false,
          status: false,
        });
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelUpdateData = async () => {
    try {
      if (dataToUpdate?.labelName?.toString().length === 0) {
        setError((preVal) => {
          return { ...preVal, labelName: true };
        });
        return;
      }

      loaderDispatch({
        isLoading: true,
      });

      let body = {
        school_id: userData?.id,
        ...dataToUpdate,
      };
      delete body.name;
      delete body.label_id;

      let result = await updateUpdatedLabel(dataToUpdate?.label_id, body);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
        });
        setOpenViewModal(false);
        handelLabelList();
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Transition.Root show={openViewModal} as={Fragment}>
      <Dialog
        as='div'
        static
        className='fixed z-10 inset-0 overflow-y-auto'
        open={openViewModal}
        onClose={setOpenViewModal}>
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'>
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
            <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
              <div>
                <div className='mb-6 row block text-2xl font-medium text-gray-700'>
                  <span>{header} </span>
                  <span className='float-right cursor-pointer'>
                    {" "}
                    <GrClose onClick={() => setOpenViewModal(false)} />
                  </span>
                </div>
                {view.map((val, i) => {
                  return (
                    <>
                      <label className='block text-sm font-medium text-gray-700'>
                        {val.label}
                        <span className='text-red-600'>&#42;</span>
                      </label>

                      <input
                        type={val.type}
                        value={dataToUpdate?.[val?.value]}
                        name={val.name}
                        onChange={handleChange}
                        maxLength={val.maxLength}
                        minLength={val.minLength}
                        disabled={val?.disable}
                        placeholder={val?.placeholder}
                        autoComplete='cc-given-name'
                        className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm mb-2'
                      />
                      {error[val.name] && (
                        <p className='text-sm text-red-500'>
                          Please fill the field
                        </p>
                      )}
                    </>
                  );
                })}

                <label className='block text-sm font-medium text-gray-700 mt-3 mb-3 w-fit'>
                  Status<span className='text-red-600'>&#42;</span>
                  <label
                    htmlFor='default-toggle1'
                    className='inline-flex relative items-center cursor-pointer align-middle ml-5 w-fit'>
                    <input
                      type='checkbox'
                      checked={dataToUpdate.status == 1 ? true : false}
                      name='status1'
                      onChange={() => {
                        setDataToUpdate((preVal) => {
                          return {
                            ...preVal,
                            status: dataToUpdate.status == 1 ? 2 : 1,
                          };
                        });
                      }}
                      id='default-toggle1'
                      className='sr-only peer'
                    />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </label>
                <div className='flex justify-end'>
                  <button
                    className='mt-2 px-4 py-2 bg-indigo-600 text-white outline-none rounded shadow hover:bg-indigo-700'
                    onClick={handelUpdateData}>
                    {" "}
                    Update
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default SchoolLabelEdit;
