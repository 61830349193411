import React from "react";

function SelectFilter({ options, selectCls, name, id, onChange }) {
  return (
    <select name={name} id={id} className={selectCls} onChange={onChange}>
      {options?.map((option, i) => {
        return (
          <option value={option?.id} key={i} selected={option?.selected}>
            {option?.label}{" "}
          </option>
        );
      })}
    </select>
  );
}

export default SelectFilter;
