import { useContext, useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { clubView, taskView } from "../../services/auth.services";

import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import { moment } from "moment/moment";
import { BiArrowBack } from "react-icons/bi";
import styled from "styled-components";
import { loaderContext } from "../../components/global loader/global-loader-provider";
const Main = styled.div`
  background-color: #f3f4f6;
  height: 100vh;
  overflow-y: scroll;
  -ms-overflow-style: transparent; /* IE and Edge */
  &::-webkit-scrollbar {
    background: transparent;
  }
`;

function TaskView() {
  const [viewData, setViewData] = useState({});
  console.log("viewData", viewData);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const { loaderState, loaderDispatch } = useContext(loaderContext);
  const { ids } = useParams();
  let navigate = useNavigate();
  let postref = useRef();

  useEffect(() => {
    const deBounce = setTimeout(() => {
      getTaskDetails(1);
    }, 300);
    return () => {
      clearTimeout(deBounce);
    };
  }, [search]);

  const getTaskDetails = async (pages, loader = null) => {
    try {
      if (!loader) {
        loaderDispatch({
          isLoading: true,
        });
      }
      let params = {
        id: ids,
      };
      let result = await taskView(params);
      console.log("result", result);
      loaderDispatch({
        isLoading: false,
      });

      if (result?.data?.status === StatusCodes.OK) {
        setViewData((preVal) => {
          return { ...preVal, ...result?.data?.data };
        });
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
        setViewData({});
      }
    } catch (error) {
      console.error(error);
      setViewData({});
    }
  };

  const parsedDate = new Date(viewData?.createdAt);
  // Extract year, month, and day components
  const year = parsedDate.getFullYear();
  const month = String(parsedDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(parsedDate.getDate()).padStart(2, "0");
  const formattedDate = `${month}/${day}/${year}`;

  const startDate = new Date(viewData?.startDate);
  // Extract year, month, and day components
  const years = startDate.getFullYear();
  const months = String(startDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const days = String(startDate.getDate()).padStart(2, "0");
  const formattedstartDate = `${months}/${days}/${years}`;

  const dueDate = new Date(viewData?.dueDate);
  // Extract year, month, and day components
  const yeard = dueDate.getFullYear();
  const monthd = String(dueDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const dayd = String(dueDate.getDate()).padStart(2, "0");
  const formatteddueDate = `${dayd}/${monthd}/${yeard}`;
  const formattedNames = viewData?.students
    ?.map((student) => student.student_name)
    .join(", ");
  return (
    <>
      <Main>
        <main className="py-4">
          <button
            className=" cursor-pointer w-fit bg-white px-2 py-2 flex rounded-md shadow text-gray-500 hover:text-gray-600 mb-4 ml-6 "
            onClick={() => navigate(-1)}
          >
            <BiArrowBack className="text-xl outline-none " />
            <p>Go Back</p>
          </button>
          {/* Page header */}

          <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
              {/* Description list*/}
              <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                  {/* club details */}
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Title
                        </dt>
                        <dd className="mt-1 text-sm text-gray-600">
                          {viewData?.title}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Description
                        </dt>
                        <dd className="mt-1 text-sm text-gray-600">
                          {viewData?.description}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Startdate
                        </dt>
                        <dd className="mt-1 text-sm text-gray-600">
                          {formattedstartDate}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          DueDate
                        </dt>
                        <dd className="mt-1 text-sm text-gray-600">
                          {formatteddueDate}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Users list
                        </dt>
                        <dd className="mt-1 text-sm text-gray-600">
                          {formattedNames}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Created On
                        </dt>
                        <dd className="mt-1 text-sm text-gray-600">
                          {formattedDate}
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </main>
      </Main>
    </>
  );
}

export default TaskView;
