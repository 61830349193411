import axios from "axios";
import { ADMIN_USER } from "../utils/constant";
 
const axiosConfig = axios.create({});
 
const responseHandler = (response) => {
  return response;
};
 
axiosConfig.defaults.headers = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: "0",
};
 
const errorHandler = (error) => {
  if (error.response.status === 401) {
    localStorage.clear();
    sessionStorage.clear();
    // localStorage.setItem(
    //   "tokenExpired",
    //   "Session expired, Please login again."
    // );
    sessionStorage.setItem(
      "tokenExpired",
      "Session expired, Please login again."
    );
    // window.location = admin.login;
  }
  return Promise.reject(error);
};
 
axiosConfig.interceptors.request.use(function (config) {
  let token = "";
  try {
    token = JSON.parse(localStorage.getItem(ADMIN_USER));
    if (!token || token === "") {
      token = JSON.parse(sessionStorage.getItem(ADMIN_USER));
    }
    // config.headers.Authorization = `Bearer ${token}`;
    config.headers.Authorization = `${token}`;
  } catch (error) {}
  return config;
});
 
axiosConfig.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);
 
export default axiosConfig;