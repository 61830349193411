import React from "react";
import styled from "styled-components";

import { colors, space, siteGradient } from "./Variables.js";
import { device } from "./Devices.js";

const FormLabel = styled.label`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.063rem;
  color: ${colors.white};
  margin-bottom: ${space.space8};
  display: block;

  @media ${device.tablet} {
  }
`;

function Label(props) {
  return (
    <>
      <FormLabel className={props.cname} htmlFor={props.for}>
        {props.labelData}{" "}
      </FormLabel>
    </>
  );
}
export default Label;
