import React, { useContext, useState } from "react";
import styled from "styled-components";
import { colors, space, siteGradient } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";
import { SiteCard, SiteForm } from "../../../common/website/HOC.jsx";

import Paragraph from "../../../common/website/Paragraph.jsx";
import LikeDislike from "../../../common/website/LikeDislike.jsx";
import Comments from "../../../common/website/Comments.jsx";
import Button from "../../../common/website/Button.jsx";

import IconDots from "../../../images/website/icon_dots.svg";
import IconDelete from "../../../images/website/icon_delete.svg";
import IconEdit from "../../../images/website/icon_edit.svg";

import ViewComment from "../modals/ViewComment";
import {
  commentList,
  deletePost,
  schoolPostComment,
} from "../../../services/auth.services";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import dateDifference from "date-difference";
import DeleteModal from "../modals/DeleteModal";
import CreateNewPostModal from "../modals/CreateNewPost";
import {
  CLUB_POST,
  GRADUATED,
  IMG_DES,
  MY_MOMENT_POST,
  MY_POST,
  SCHOOL_MOMENTS,
  TEAM_POST,
} from "../../../utils/constant";
import { loaderContext } from "../../global loader/global-loader-provider";
import { PostContext } from "../header/MainHeader";
import blankProfile from "../../../images/website/dummy_user_profile.svg";
import { MentionsInput, Mention } from "react-mentions";

const PostRow = styled.div`
  .card_head {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    grid-gap: ${space.space8};
    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: ${space.space10};
      img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 100%;
        flex-shrink: 0;
      }
      .profile_name {
        margin: 0 0 2px;
      }
      .time {
        font-size: 0.75rem;
        line-height: 0.938rem;
        margin: 0;
        color: ${colors.lightgrey2};
      }
    }
  }
  .description {
    font-size: 0.75rem;
    margin: ${space.space10} 0 ${space.space20};
    word-break: break-all;
  }
  .post_img_box {
    height: auto;
    width: 100%;

    border-radius: ${space.space16};
    overflow: hidden;

    .post_image {
      margin: 0 auto;
      ${"" /* height: 100%; */}
      //width:100%;
      object-fit: contain;
      border-radius: ${space.space16};
    }
  }
  .post_card {
    padding: ${space.space10};
    margin-bottom: ${space.space20};
  }
  .action_group,
  .comment_group {
    display: flex;
    align-items: center;
  }
  .action_group {
    grid-gap: ${space.space20};
    margin: ${space.space20} 0 ${space.space10};
  }
  .comment_group {
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    grid-gap: ${space.space10};
    .user_count,
    .comment_count {
      font-size: 12px;
      line-height: 15px;
      color: ${colors.lightgrey2};
      margin: 0;
    }
    .comment_count {
      cursor: pointer;
      &:hover {
        color: ${colors.primary};
      }
    }
  }
  .mention {
    textarea {
      display: none !important;
    }
    strong {
      font-family: "Inter" !important;
      background-color: #171717;
      color: #00e1f0;
    }
    span {
      font-family: "Inter" !important;
      color: white;
      visibility: visible !important;
    }
  }
  .comment_form {
    padding-top: ${space.space16};
    margin-top: ${space.space16};
    border-top: 1px solid #ffffff1a;
    input {
      font-size: 0.875rem;
      border: none;
      padding: 0;
      padding-right: ${space.space40};
      &::placeholder {
        font-size: 0.875rem;
      }
    }
    .form_group {
      position: relative;
      z-index: 1;
      .post_btn {
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 0;
        font-size: 0.875rem;
        opacity: 0.3;
        pointer-events: none;
        &.show {
          pointer-events: auto;
          opacity: 1;
        }
        &:hover {
          color: ${colors.secondary};
        }
      }
    }
  }
  .menu_dropdown {
    position: relative;
    .menu_list {
      position: absolute;
      top: 100%;
      background: ${colors.body};
      box-shadow: ${siteGradient.cardlightboxshadow};
      border-radius: ${space.space8};
      width: 110px;
      transform: translateX(-80px);
      overflow: hidden;
      padding: ${space.space14} 0;
      z-index: 1;
    }
    .link {
      display: flex;
      align-items: center;
      grid-gap: ${space.space8};
      font-size: 0.875rem;
      line-height: 1.063rem;
      padding: ${space.space8} 22px;
      width: 100%;
      &:hover {
        background: ${colors.primary};
      }
    }
  }
  @media ${device.mobileM} {
    .post_img_box {
      ${"" /* height: 400px; */}
    }
    .action_group {
      grid-gap: ${space.space30};
    }
    .card_head {
      .profile_name {
        margin: 0 0 6px;
      }
    }
    .description {
      font-size: 0.875rem;
    }
    .card_head {
      .left {
        grid-gap: 22px;
        img {
          width: 55px;
          height: 55px;
        }
      }
    }
    .post_card {
      padding: ${space.space20};
    }
    .comment_group {
      align-items: center;
      flex-direction: row;
    }
    .comment_form {
      padding-top: ${space.space20};
      margin-top: ${space.space20};
      input {
        font-size: 1rem;
      }
      &::placeholder {
        font-size: 1rem;
      }
      .form_group {
        .post_btn {
          font-size: 1rem;
        }
      }
    }
  }
`;

function Post({ val }) {
  // Adds comment to the post
  const [comment, setComment] = useState("");
  // Post Dropdown
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };
  // End Post Dropdown

  // getAllPostDetails & userInfo from post context
  const {
    getAllPostDetails,
    userInfo,
    clubData,
    postlocation,
    teamData,
    isMyMoment,
    getMomentPostListing,
    handelLogout,
    setPostData,
    setMyMomentPostData,
  } = useContext(PostContext);

  // handel open comment modal
  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [postId, setPostId] = useState("");
  const [page, setPage] = useState(1);

  const handelOpenCommentModal = (e, id) => {
    setOpenCommentModal(true);
    setPostId(id);
  };

  // handel Delete modal
  const [openDeleteModal, setDeleteModal] = useState(false);
  const handelDelete = (e) => {
    setDeleteModal(true);
  };
  // End Delete Pop Up

  const [openImageModal, setOpenImageModal] = useState(false);
  const [commentData, setCommentData] = useState([]);

  const { loaderDispatch } = useContext(loaderContext);

  const mentionStyle = {
    control: {
      backgroundColor: "#171717",
      fontSize: " 0.875rem",
      // fontWeight: 'normal',
      // margin: "0.625rem 0 1.25rem",
      lineHeight: "1.313rem",
      position: "static",
    },
    "&multiLine": {
      control: {
        fontFamily: "monospace",
        minHeight: 63,
      },
      highlighter: {
        padding: 9,
        border: "1px solid transparent",
      },
      input: {
        padding: 9,
        // border: "1px solid silver",
      },
    },
    "&singleLine": {
      display: "inline-block",
      width: 180,
      highlighter: {
        padding: 1,
        border: "2px inset transparent",
      },
      input: {
        padding: 1,
        border: "2px inset",
      },
    },
    suggestions: {
      list: {
        backgroundColor: "black",
        border: "1px solid rgba(0,0,0,0.15)",
        fontSize: 16,
      },
      item: {
        padding: "5px 15px",
        borderBottom: "1px solid rgba(0,0,0,0.15)",
        "&focused": {
          backgroundColor: "#00e1f0",
        },
      },
    },
  };

  // handelPostComment asynchronously post the comment.
  const handelPostComment = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let body = {
        comment: comment,
        post_id: val?.id,
        user_id: val?.user_id,
        school_id: userInfo?.User?.id,
        action_user_id: userInfo?.id,
        action_user_name: userInfo?.student_name,
        user_name: val?.User?.student_name,
        status: 1,
        club_id: 0,
      };
      if (comment !== "") {
        let result = await schoolPostComment(body);
        loaderDispatch({
          isLoading: false,
        });
        if (result?.data?.status === 410) {
          handelLogout();
        }
        if (result?.data?.status === 401) {
          setComment("");
        }
        if (result?.data?.status === StatusCodes.OK) {
          val.total_comments = val?.total_comments + 1;
          getCommentList(1);
          setPage(1);
          setComment("");
        } else {
          toast(result?.data?.message, {
            type: "error",
            theme: "dark",
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Returns the post time.
  const postTime = () => {
    let date = new Date(val?.createdAt);
    let vals = dateDifference(new Date(val?.createdAt), new Date(new Date()), {
      compact: true,
    });
    let vals2 = vals.slice(1);

    if (vals2 === "1d") {
      return `yesterday ${date?.getHours()}:${date?.getMinutes()} `;
    } else if (vals2?.includes("ms")) {
      return `few seconds ago`;
    } else if (vals2?.includes("m")) {
      return `${vals2} ago`;
    } else if (vals2?.includes("s")) {
      return `few seconds ago`;
    } else if (vals2?.includes("h")) {
      return `${vals2} ago`;
    } else {
      return `${date?.getDate()}-${
        date?.getMonth() + 1
      }-${date.getFullYear()} ${date?.getHours()}:${date?.getMinutes()} `;
    }
  };

  // SHOW LIKE CAPTION DATA
  const handelLikeData = (val) => {
    if (val?.Likes.length === 1) {
      if (val?.Likes[0]?.User?.id === userInfo?.id) {
        return ` Liked by you`;
      } else {
        return `Liked by ${val?.Likes[0]?.User?.student_name}`;
      }
    } else {
      if (val?.Likes[0]?.User?.id === userInfo?.id) {
        return `Liked by you and ${val?.total_likes - 1} others`;
      } else {
        return `Liked by ${val?.Likes[0]?.User?.student_name} and ${
          val?.total_likes - 1
        } others`;
      }
    }
  };

  // Sets the open image modal.
  const handelOpenEditModal = () => {
    setOpenImageModal(true);
  };

  // Deletes a post.
  const handleDeletePost = async () => {
    loaderDispatch({
      isLoading: true,
    });
    let result = await deletePost(val.id);
    loaderDispatch({
      isLoading: false,
    });
    if (result?.data?.status === StatusCodes.OK) {
      toast(result?.data?.message, {
        type: "success",
        theme: "dark",
      });
      setDeleteModal(false);
      // if (postlocation === SCHOOL_MOMENTS) {
      //   getAllPostDetails({
      //     id: userInfo?.User?.id,
      //     studentId: userInfo?.id,
      //     academicYear: userInfo?.academic_year,
      //     standard: userInfo?.standard,
      //   });
      // } else if (postlocation === CLUB_POST) {
      //   getAllPostDetails({
      //     id: userInfo?.User?.id,
      //     clubId: clubData?.id,
      //     studentId: userInfo?.id,
      //   });
      // } else if (postlocation === TEAM_POST) {
      //   getAllPostDetails({
      //     id: userInfo?.User?.id,
      //     sport_id: teamData?.id,
      //     studentId: userInfo?.id,
      //   });
      // } else if (postlocation === MY_MOMENT_POST) {
      //   getMomentPostListing({ userInfo, loader: true, is_moment: true });
      // }else if (postlocation === MY_POST) {

      // }

      if (postlocation === MY_MOMENT_POST) {
        setMyMomentPostData((preVal) => {
          return preVal.filter((value) => {
            return value?.post_id !== val.id;
          });
        });
      } else {
        setPostData((preVal) => {
          return preVal.filter((value) => {
            return value?.id !== val.id;
          });
        });
      }
      setOpen(false);
      setOpenCommentModal(false);
    } else {
      toast(result?.data?.message, { type: "error", theme: "dark" });
    }
  };

  const getCommentList = async (pages) => {
    try {
      let body = {
        id: postId,
        page: pages ? pages : 1,
        sort_by: "createdAt",
        order: "desc",
      };
      let result = await commentList(body);
      if (result?.data?.status === StatusCodes.OK) {
        let data = result?.data?.data?.commentListing?.rows;
        if (data?.length > 0 && pages > 1) {
          setCommentData((preVal) => {
            return [...preVal, ...data];
          });
        }
        if (pages === 1 || !pages) {
          setCommentData(data);
        }
      } else {
        toast(result?.data?.message, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <PostRow>
        <SiteCard className='post_card'>
          <div className='card_head'>
            <div className='left'>
              <img
                src={
                  val?.User?.filtered_image
                    ? val?.User?.filtered_image
                    : blankProfile
                }
                alt='Profile_Image'
              />
              <div className='detail'>
                <Paragraph
                  cname='profile_name'
                  pData={val?.User?.student_name}
                />
                <Paragraph cname='time' pData={postTime()} />
              </div>
            </div>
            <div className='menu_dropdown'>
              {userInfo?.id === val?.User?.id &&
                userInfo?.standard?.toLowerCase() !== GRADUATED &&
                userInfo?.is_plan_expired !== "1" && (
                  <button onClick={handleOpen}>
                    <img src={IconDots} alt='icon dots' />
                  </button>
                )}
              {open ? (
                <ul className='menu_list'>
                  <li className='menu-item'>
                    <button className='link' onClick={handelOpenEditModal}>
                      <img src={IconEdit} alt='edit' /> Edit
                    </button>
                  </li>
                  <li className='menu-item'>
                    <button className='link' onClick={handelDelete}>
                      <img src={IconDelete} alt='close' /> Delete
                    </button>
                  </li>
                </ul>
              ) : null}
            </div>
          </div>
          {!isMyMoment || !val?.moment_id ? (
            <Paragraph
              cname='smallParagaraph description'
              pData={val?.description}
            />
          ) : (
            <span className='mention'>
              <MentionsInput
                name='caption'
                style={mentionStyle}
                rows='6'
                disabled
                value={val?.description ? val.description : ""}>
                <Mention
                  trigger='@'
                  // data={userData}

                  appendSpaceOnAdd={true}
                />
              </MentionsInput>
            </span>
          )}
          {/* <img src={val?.postName} alt='Post Image' className='post_image' /> */}
          <div className='post_img_box'>
            {val?.postType == 1 ? (
              <img
                src={val?.postName ? val?.postName : ""}
                alt='Post Image'
                className='post_image'
              />
            ) : (
              <video
                src={val?.postName ? val?.postName : ""}
                controls
                className='post_image'></video>
            )}
          </div>
          <div className='action_group'>
            {userInfo?.standard?.toLowerCase() !== GRADUATED &&
              userInfo?.is_plan_expired !== "1" && (
                <>
                  <LikeDislike val={val} />
                  <div onClick={(e) => handelOpenCommentModal(e, val?.id)}>
                    <Comments />
                  </div>
                </>
              )}
          </div>
          <div className='comment_group'>
            <Paragraph
              cname='user_count'
              pData={val?.Likes?.length > 0 ? handelLikeData(val) : ""}
            />

            <span
              className='comment_count'
              onClick={(e) => handelOpenCommentModal(e, val?.id)}>
              <span>
                {val?.total_comments > 0 ? val?.total_comments : 0} Comments
              </span>
            </span>
          </div>

          {userInfo?.standard?.toLowerCase() !== GRADUATED &&
            userInfo?.is_plan_expired !== "1" && (
              <SiteForm as='div' className='comment_form'>
                <div className='form_group'>
                  <input
                    type='text'
                    name='text'
                    placeholder='Add a Comment'
                    value={comment}
                    onChange={(e) => {
                      setComment(e.target.value);
                    }}
                  />
                  <Button
                    tag='button'
                    cname={comment?.length > 0 ? "post_btn show" : "post_btn"}
                    buttonData={"Post"}
                    onClick={handelPostComment}
                  />
                </div>
              </SiteForm>
            )}
        </SiteCard>
      </PostRow>
      <ViewComment
        openCommentModal={openCommentModal}
        setOpenCommentModal={setOpenCommentModal}
        val={val}
        handelPostComment={handelPostComment}
        setComment={setComment}
        comment={comment}
        handelDelete={handelDelete}
        handelOpenEditModal={handelOpenEditModal}
        postTime={postTime}
        postId={postId}
        getCommentList={getCommentList}
        commentData={commentData}
        setPage={setPage}
        page={page}
      />
      <DeleteModal
        openDeleteModal={openDeleteModal}
        setDeleteModal={setDeleteModal}
        handleDeletePost={handleDeletePost}
      />
      <CreateNewPostModal
        openImageModal={openImageModal}
        setOpenImageModal={setOpenImageModal}
        value={IMG_DES}
        val={val}
        setOpen={setOpen}
      />
    </>
  );
}

export default Post;
