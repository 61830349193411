import React, { useState, Fragment, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { GrClose } from "react-icons/gr";

function AdminClubEdit({
  viewData,
  openViewModal,
  setOpenViewModal,
  view,
  setEditData,
  updateData,
  error,
  setError,
  option,
  setDataToUpdate,
  dataToUpdate,
}) {
  useEffect(() => {
    setDataToUpdate({
      clubName: viewData.clubName ? viewData.clubName : "",
      status: viewData.status ? viewData.status : "",
      description: viewData?.description ? viewData?.description.trim() : "",
      club_profile: viewData?.club_profile ? viewData?.club_profile : "",
      clubType: viewData?.clubType ? viewData?.clubType : "",
    });
  }, [viewData]);
  // console.log("viewData", viewData);
  console.log(dataToUpdate);
  const inputRef = useRef(null);

  // let imageUrl;
  const handleChange = (e) => {
    const { name, value } = e.target;

    // if (name === "club_profile") {
    //   setEditData((preVal) => {
    //     return { ...preVal, [name]: e.target.files[0].name };
    //   });
    // }
    //   // imageUrl = URL.createObjectURL(e.target.files[0]);
    // } else {
    //   setEditData((preVal) => {
    //     return { ...preVal, [name]: value };
    //   });
    // }

    if (name === "club_profile") {
      setDataToUpdate((preVal) => {
        return { ...preVal, [name]: e.target.files[0] };
      });
    } else {
      if (value?.toString().trim().length > 0 || value === "") {
        setDataToUpdate((preVal) => {
          return { ...preVal, [name]: value };
        });
      }
    }
    setError((preVal) => {
      return { ...preVal, [name]: false };
    });
  };

  const chooseFileBox = () => {
    inputRef?.current?.click();
  };

  const fileName = () => {
    if (viewData?.club_profile) {
      if (Object.keys(viewData?.club_profile).length > 0) {
        let file = viewData?.club_profile?.split("/");
        return file?.[file?.length - 1];
      }
    }
  };

  console.log("error", error);
  return (
    <Transition.Root show={openViewModal} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={openViewModal}
        onClose={setOpenViewModal}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mb-6 row block text-2xl font-medium text-gray-700">
                  <span>Edit Details </span>
                  <span className="float-right cursor-pointer">
                    {" "}
                    <GrClose onClick={() => setOpenViewModal(false)} />
                  </span>
                </div>
                {view.map((val, i) => {
                  return (
                    <>
                      <label className="block text-sm font-medium text-gray-700">
                        {val.label}
                        <span className="text-red-600">&#42;</span>
                      </label>

                      <input
                        type={val.type}
                        value={val.value}
                        name={val.name}
                        onChange={handleChange}
                        minLength={val.minLength}
                        maxLength={val.maxLength}
                        autoComplete="cc-given-name"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                      />
                      {error[val.name] && (
                        <p className="text-sm text-red-500">
                          Please fill the field
                        </p>
                      )}
                    </>
                  );
                })}

                <label className="block text-sm font-medium text-gray-700 mt-3 mb-3">
                  Status<span className="text-red-600">&#42;</span>
                  <label
                    htmlFor="default-toggle"
                    className="inline-flex relative items-center cursor-pointer align-middle ml-5"
                  >
                    <input
                      type="checkbox"
                      checked={dataToUpdate.status == 1 ? true : false}
                      name="status"
                      onChange={() => {
                        setDataToUpdate((preVal) => {
                          return {
                            ...preVal,
                            status: dataToUpdate.status == 1 ? 2 : 1,
                          };
                        });
                      }}
                      id="default-toggle"
                      className="sr-only peer"
                    />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </label>

                {/* Club Description */}

                <div className="mb-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Description<span className="text-red-600">&#42;</span>
                  </label>
                  <textarea
                    type={"text"}
                    value={dataToUpdate?.description}
                    name={"description"}
                    onChange={handleChange}
                    autoComplete="cc-given-name"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                  />

                  {error?.description && (
                    <p className="text-sm text-red-500">
                      Please fill the field
                    </p>
                  )}
                </div>
                {/* club description ends */}

                {/* Club profile pic upload */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Club Profile Image
                  </label>
                  {/* {console.log("imageURL", imageUrl)} */}
                  <img
                    className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
                    src={
                      dataToUpdate?.club_profile &&
                      typeof dataToUpdate.club_profile !== "string"
                        ? URL.createObjectURL(dataToUpdate?.club_profile)
                        : viewData?.club_profile
                        ? viewData?.club_profile
                        : "https://t3.ftcdn.net/jpg/01/09/00/64/360_F_109006426_388PagqielgjFTAMgW59jRaDmPJvSBUL.jpg"
                    }
                    alt=""
                  />

                  <label
                    className={
                      "mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm cursor-pointer"
                    }
                    onClick={chooseFileBox}
                  >
                    {dataToUpdate?.club_profile &&
                    Object.keys(dataToUpdate?.club_profile).length > 0
                      ? "Change photo"
                      : "Select image"}
                  </label>

                  <input
                    type={"file"}
                    ref={inputRef}
                    accept="image/*"
                    name="club_profile"
                    onChange={handleChange}
                    className="hidden"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Select club type
                  </label>
                  <select
                    name="clubType"
                    id="clubType"
                    onChange={handleChange}
                    value={dataToUpdate.clubType}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                  >
                    <option value="PRIVATE">Private</option>
                    <option value="PUBLIC">Public</option>
                  </select>
                </div>
                {/* club profile pic ends */}

                {/* <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="status">
                  Status
                </label>

                <select
                  name="status"
                  id="status"
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm">
                  {option.map((val, i) => {
                    return (
                      <option key={i} value={val.value}>
                        {val.name}
                      </option>
                    );
                  })}
                </select> */}

                <button
                  className="mt-2 px-4 py-2 bg-indigo-600 text-white outline-none rounded shadow hover:bg-indigo-700"
                  onClick={updateData}
                >
                  {" "}
                  Update
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default AdminClubEdit;
