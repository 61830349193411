export const ADMIN_USER = "as4@gi4";
export const ADMIN_USER_DATA = "g2@hy&t";
export const STUDENT_USER_DATA = "gh@jfd&";

export const VIEW = "VIEW";
export const UPDATE = "UPDATE";
export const DELETE = "DELETE";
export const EDIT = "EDIT";
export const POST = "POST";
export const NFT = "NFT Posts";
export const DOCS = "Docs";
export const USERS = "Users";
export const BROADCAST_MESSAGE = "Broadcast message";

export const DASHBOARD = "Dashboard";
export const SCHOOL = "School";
export const STUDENT = "Users";
export const CLUB = "Channel 1";
export const TASK = "Tasks";
export const CMS = "CMS";
export const EMAIL_TEMPLATES = "Email Templates";
export const PLANS = "Plans";
export const POSTS = "Posts";
export const YEARBOOK = "YearBook";
export const PROFILE = "Profile";
export const SCHOOL_CLUB = "Club";
export const SETTING = "Settings";
export const REPORTS = "Reports";
export const USERLIST = "Users list";
export const TASKREPORTS = "Task Reports";
export const SCHOOL_SPORTS_CLUB = "Channel 2";
export const VOTING = "Voting";
export const VOTING_HISTORY = "Voting History";
export const LABELS = "Labels";
export const WEB_LINKS = "Web links";
export const JOB = "Job / Event Center";

export const REMEMBER_STATE = "rememberState";
export const REMEMBER_STATE_STUDENT = "rememberStateStudent";

export const COMMENT = "comment";

export const COMMON_WALL = "COMMON WALL";

export const IMG = "img";
export const IMG_DES = "imgdes";
export const POST_SUBMIT = "postSubmit";
export const DISCARD = "discard";

export const STUDENT_DETAILS = "studentDetails";
export const GRADUATED = "graduated";
export const FRESHMEN = "freshmen";

export const SCHOOL_MOMENTS = "school_moments";
export const PROFILE_OPTION = "profile";
export const CLUB_POST = "club_post";
export const TEAM_POST = "team_post";
export const MY_MOMENT_POST = "myMomentPost";
export const OTHER_STUDENT_POST = "otherStudentProfile";

export const WEBSITE_SCHOOL_MOMENTS = "School Moments";
export const WEBSITE_Profile_OPTION = "Profile";
export const WEBSITE_MY_MOMENTS = "My Moments";
export const WEBSITE_CLUB = "Club";
export const WEBSITE_SPORT_TEAM = "Sports";
export const WEBSITE_CHAT = "Chat";
export const WEBSITE_NOTIFICATION = "Notification";
export const WEBSITE_MY_ACCOUNT = "My Account";
export const WEBSITE_LOGOUT = "Logout";
export const WEBSITE_DIGITAL_ART = "Digital Art Creator";
export const VOTING_LEADER_BOARD = "Voting Leaderboard";
export const MY_POST = "My Post";
export const WEBSITE_BACK = "Back";
export const WEBSITE_GALLERY = "Gallery";
export const WEBSITE_SIGNATURE = "Signatures";
export const WEBSITE_SETTINGS = "Settings";
