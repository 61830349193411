import React, { useContext, useRef, useState } from "react";
import styled from "styled-components";

import { SiteCard } from "../../../common/website/HOC.jsx";
import { colors, space } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";

import Paragraph from "../../../common/website/Paragraph.jsx";
import { Link } from "react-router-dom";
import blankProfile from "../../../images/website/dummy_user_profile.svg";

const ClassmatesRow = styled.div`
  .classmates_card {
    border-radius: ${space.space16};
  }
  .head {
    margin-bottom: ${space.space20};
  }
  .club_group {
    display: flex;
    align-items: center;
    margin-bottom: ${space.space20};
    .head {
      margin: 0;
    }
    .count {
      color: ${colors.lightgrey2};
    }
  }
  .classmates_list {
    margin: 0;
    height: calc(100vh - 438px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(172 172 172 / 10%);
      border-radius: 8px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    /* Fire Fox */
    scrollbar-color: rgb(172 172 172 / 10%) transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background-color: transparent;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(172 172 172 / 10%);
      border-radius: 8px;
    }
    /* End Fire Fox */
    li {
      margin-bottom: 18px;
      &:last-child {
        margin: 0;
      }
    }
    .withoutLink {
      display: inline-flex;
      align-items: center;
      cursor: default;
      img {
        width: 26px;
        height: 26px;
        object-fit: cover;
        border-radius: 100%;
        margin-right: ${space.space8};
      }
      p {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin: 0;
        transition: all 0.4s;
      }
    }
    .link {
      display: inline-flex;
      align-items: center;
      img {
        width: 26px;
        height: 26px;
        object-fit: cover;
        border-radius: 100%;
        margin-right: ${space.space8};
      }
      p {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin: 0;
        transition: all 0.4s;
      }
      &:hover {
        p {
          transition: all 0.4s;
          color: ${colors.primary};
        }
      }
    }
  }
  @media ${device.laptop} {
    .classmates_list {
      height: calc(100vh - 488px);
      .link {
        img {
          width: 34px;
          height: 34px;
          margin-right: ${space.space12};
        }
      }
    }
  }
  @media ${device.laptopL} {
    .classmates_list {
      height: calc(100vh - 508px);
    }
  }
`;

function Classmates({
  classmatesData,
  message,
  pData,
  link,
  paginationRef,
  handelPagination,
  memberCount,
}) {
  return (
    <>
      <ClassmatesRow>
        <SiteCard className='classmates_card'>
          <Paragraph cname='head classmates_group' pData={pData} />
          <div className='club_group'>
            <Paragraph cname='head name' pData={pData} />

            <Paragraph
              cname='head count'
              pData={`(${memberCount ? memberCount : 0})`}
            />
          </div>

          <ul
            className='classmates_list'
            onScroll={handelPagination}
            ref={paginationRef}>
            {classmatesData && classmatesData?.length > 0 ? (
              classmatesData?.map((val, i) => {
                return (
                  <li key={i}>
                    <Link
                      className={link ? "withoutLink" : "link"}
                      to={link ? "#" : `/student/profile/${val?.email}`}>
                      <img
                        src={
                          val?.filtered_image
                            ? val?.filtered_image
                            : blankProfile
                        }
                        alt='Profile'
                      />{" "}
                      <Paragraph
                        cname='smallParagaraph'
                        pData={val?.student_name}
                      />
                    </Link>
                  </li>
                );
              })
            ) : (
              <p>{message}</p>
            )}

            {/* <li>
              <Link className='link' to='/Chat'>
                <img src={ProfileImage2} alt='Profile' />{" "}
                <Paragraph cname='smallParagaraph' pData={"Brandon Chapman"} />
              </Link>
            </li>
            <li>
              <Link className='link' to='/Chat'>
                <img src={ProfileImage3} alt='Profile' />{" "}
                <Paragraph cname='smallParagaraph' pData={"Tracey Bailey"} />
              </Link>
            </li>
            <li>
              <Link className='link' to='/Chat'>
                <img src={ProfileImage4} alt='Profile' />{" "}
                <Paragraph cname='smallParagaraph' pData={"Yvonne Dyer"} />
              </Link>
            </li>
            <li>
              <Link className='link' to='/Chat'>
                <img src={ProfileImage5} alt='Profile' />{" "}
                <Paragraph cname='smallParagaraph' pData={"Anthony Gibson"} />
              </Link>
            </li>
            <li>
              <Link className='link' to='/Chat'>
                <img src={ProfileImage6} alt='Profile' />{" "}
                <Paragraph cname='smallParagaraph' pData={"Edward Hamilton"} />
              </Link>
            </li>
            <li>
              <Link className='link' to='/Chat'>
                <img src={ProfileImage7} alt='Profile' />{" "}
                <Paragraph cname='smallParagaraph' pData={"Wendy Lambert"} />
              </Link>
            </li>
            <li>
              <Link className='link' to='/Chat'>
                <img src={ProfileImage8} alt='Profile' />{" "}
                <Paragraph cname='smallParagaraph' pData={"Gavin Lyman"} />
              </Link>
            </li>
            <li>
              <Link className='link' to='/Chat'>
                <img src={ProfileImage9} alt='Profile' />{" "}
                <Paragraph cname='smallParagaraph' pData={"Rachel Mathis"} />
              </Link>
            </li>
            <li>
              <Link className='link' to='/Chat'>
                <img src={ProfileImage10} alt='Profile' />{" "}
                <Paragraph cname='smallParagaraph' pData={"Jason Paterson"} />
              </Link>
            </li> */}
          </ul>
        </SiteCard>
      </ClassmatesRow>
    </>
  );
}

export default Classmates;
