import React from "react";

function SearchFilter({ setSearchItem, searchItem, cname, setPage }) {
  return (
    <>
      <input
        type={"text"}
        placeholder={"Search"}
        value={searchItem}
        onChange={(e) => {
          setPage(1);
          if (e.target.value.trim().length > 0 || e.target.value === "")
            setSearchItem(e.target.value.trim());
        }}
        autoComplete='cc-given-name'
        className={cname}
      />
    </>
  );
}

export default SearchFilter;
