import React from "react";

function InActiveStatus({ message }) {
  return (
    <span className='text-sm inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-red-600 text-white rounded'>
      {message}
    </span>
  );
}

export default InActiveStatus;
