import React, { useContext } from "react";
import { CKEditor } from "ckeditor4-react";

function Editor({
  onChange,
  editorLoaded,
  name,
  value,
  handelUpdate,
  handelChange,
}) {
  return (
    <div>
      {editorLoaded && value && (
        <CKEditor
          name={name}
          initData={value}
          allowedContent={true}
          onChange={(event) => {
            // const data = editor.getData();

            handelChange(event.editor.getData());
          }}
          onBlur={(event) => handelUpdate(event.editor.getData())}
          config={{
            extraAllowedContent: "style",
          }}
        />
      )}
    </div>
  );
}

export default Editor;
