import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { colors, space } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";

import HeadingThree from "../../../common/website/HeadingThree.jsx";

import CreatePostBG from "../../../images/website/create_post_bg.svg";
import PlusIcon from "../../../images/website/icon_create_plus.svg";
import CreateNewPostRow from "../modals/CreateNewPost.jsx";
import { PostContext } from "../header/MainHeader";
import { GRADUATED } from "../../../utils/constant";

const CreatePostRow = styled.div`
  display: flex;
  align-items: center;
  grid-gap: ${space.space10};
  background: ${colors.primary};
  border-radius: ${space.space8};
  padding: ${space.space10};
  margin: ${space.space24} 0;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0px;
    background: url(${CreatePostBG}) no-repeat center;
    background-size: contain;
    width: 100px;
    height: 52px;
  }
  h3 {
    text-transform: uppercase;
    font-weight: 400;
    margin: 0;
    color: ${colors.body};
    display: inline-block;
    position: relative;
  }
  .create_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: 1px dashed ${colors.createbordercolor};
    border-radius: 8px;
    color: ${colors.body};
    cursor: pointer;
    position: relative;
    img {
      width: 16px;
    }
  }
  @media ${device.mobileM} {
    padding: ${space.space20};
    grid-gap: ${space.space16};
    .create_btn {
      width: 50px;
      height: 50px;
      img {
        width: 20px;
      }
    }
  }
  @media ${device.tablet} {
    margin: ${space.space24} 0;
  }
  @media ${device.laptop} {
    padding: 22px ${space.space24};
    &::before {
      right: 50px;
      width: 136px;
      height: 82px;
    }
  }
`;

function CreateNewPostModal({ cname, clubId }) {
  // Sets the open image modal.
  const [openImageModal, setOpenImageModal] = useState(false);
  const handelChooseFile = (e) => {
    setOpenImageModal(true);
  };
  const { userInfo } = useContext(PostContext);

  return (
    <>
      {userInfo?.standard?.toLowerCase() !== GRADUATED &&
        userInfo?.is_plan_expired !== "1" && (
          <CreatePostRow className={cname}>
            <span className='create_btn' onClick={handelChooseFile}>
              <img src={PlusIcon} alt='Plus Icon' />
            </span>
            <HeadingThree h3Data='Create Post' />
            <CreateNewPostRow
              openImageModal={openImageModal}
              setOpenImageModal={setOpenImageModal}
              clubId={clubId}
            />
          </CreatePostRow>
        )}
    </>
  );
}

export default CreateNewPostModal;
