import React, { useContext, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { loaderContext } from "../../global loader/global-loader-provider";
import YearBookListLayout from "./YearBookListLayout";
import { getYearBookStudents } from "../../../services/auth.services.js";
import { StatusCodes } from "http-status-codes";
import { useParams } from "react-router-dom";
import { SchoolContext } from "../../../common/SchoolDashboardWrapper";
import { ADMIN_USER_DATA, STUDENT_DETAILS } from "../../../utils/constant";
import { toast } from "react-toastify";

function YearBookStudentDetails() {
  const [searchItem, setSearchItem] = useState("");
  const [data, setData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [itemOffset, setItemOffset] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [dataCount, setDataCount] = useState(0);
  const [sortby, setSortby] = useState("");
  const [orderby, setOrderby] = useState(true);
  const [currentItems, setCurrentItems] = useState(null);

  const { loaderDispatch } = useContext(loaderContext);
  const { setAdminActiveTab } = useContext(SchoolContext);
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));

  let { year } = useParams();

  const tableHeader = [
    {
      key: "student name",
      value: "student_name",
      sorting: true,
    },
    {
      key: "email",
      value: "email",
      sorting: true,
    },
    {
      key: "age",
      value: "age",
    },
    {
      key: "Meta Mask Wallet Address",
      value: "metamask_id",
    },
    {
      key: "options",
      value: "options",
      subValue: "isFraction",
      sorting: false,
    },
  ];

  const itemsOnPage = [
    { option: 10 },
    { option: 20 },
    { option: 50 },
    { option: 70 },
    { option: 100 },
  ];

  // Handles a click on a page.
  const handlePageClick = (event) => {
    let newOffset = (event.selected * itemsPerPage) % dataCount;
    // dataCall(body);
    setItemOffset(event.selected + 1);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getStudentDetails();
    }, 300);
    setAdminActiveTab(STUDENT_DETAILS);

    return () => clearTimeout(delayDebounceFn);
  }, [searchItem, sortby, orderby, itemOffset, itemsPerPage]);

  useEffect(() => {
    setCurrentItems(data);
    setPageCount(dataCount);
  }, [data, dataCount, itemsPerPage]);

  const handleSorting = (data, order) => {
    setSortby(data);
    setOrderby(order);
  };

  const getStudentDetails = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let order = orderby ? "asc" : "desc";
      let params = {
        academicYear: year,
        search: searchItem.trim(),
        sorts: sortby,
        order: order,
        page: itemOffset,
        limit: itemsPerPage,
        school_id: userData?.id,
      };
      setData([]);
      setDataCount(0);
      let result = await getYearBookStudents(params);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        let studentData = result?.data?.data?.studentListing?.rows;
        let studentCount = result?.data?.data?.totalPage;
        setData(studentData);
        setDataCount(studentCount);
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className='p-2'>
        <input
          type={"text"}
          placeholder={"Search"}
          onChange={(e) => {
            setSearchItem(e.target.value);
            setItemOffset(1);
          }}
          autoComplete='cc-given-name'
          className='mt-1 block w-1/2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm'
        />
      </div>

      <YearBookListLayout
        tableHeader={tableHeader}
        handleSorting={handleSorting}
        currentItems={currentItems}
      />

      <div className='flex justify-between '>
        {data?.length > 0 && (
          <div
            className='block lg:inline-block lg:mt-0 '
            style={{ marginTop: "6px" }}>
            <label htmlFor='item' className='ml-5'>
              Record Per Page:
            </label>
            <select
              className='ml-3 bg-white border p-2 rounded'
              name='item'
              id='item'
              onChange={(e) => {
                setItemsPerPage(parseInt(e.target.value));
              }}>
              {itemsOnPage.map((val, i) => {
                return (
                  <option value={val.option} key={i}>
                    {val.option}
                  </option>
                );
              })}
            </select>
          </div>
        )}
        <div className='mt-2 mb-10'>
          <ReactPaginate
            breakLabel='...'
            nextLabel='Next >'
            onPageChange={handlePageClick}
            pageCount={pageCount}
            previousLabel='< Previous'
            renderOnZeroPageCount={null}
            containerClassName={"pagination"}
            pageClassName={"pageClass"}
            forcePage={itemOffset - 1}
          />
        </div>

        {data?.length === 0 && (
          <div className='flex absolute left-1/2 top-1/2 mx-auto'>
            <p>Data not found..!!</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default YearBookStudentDetails;
