import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import {
  DashboardBody,
  DashboardRightBar,
} from "../../../common/website/HOC.jsx";
import { device } from "../../../common/website/Devices";
import { colors, space, siteGradient } from "../../../common/website/Variables";
import Classmates from "../../../components/website/Classmates/Classmates.jsx";
import About from "../../../components/website/RecentActivity/About.jsx";
import ProfileHeader from "../../../components/website/ProfileHeader/ProfileHeader.jsx";
import CreatePost from "../../../components/website/CreatePost/CreatePost.jsx";
import Post from "../../../components/website/Post/Post.jsx";

import IconBluePhotograph from "../../../images/website/icon_blue_photograph.svg";
import UserProfileImage from "../../../images/website/NFT_image.png";
import PostImage from "../../../images/website/post_image.png";
import PostImage1 from "../../../images/website/post_1.png";
import PostImage2 from "../../../images/website/post_2.png";
import PostImage3 from "../../../images/website/post_3.png";
import { loaderContext } from "../../../components/global loader/global-loader-provider.js";
import {
  getMyMomentPostListing,
  getSubscriptionDetail,
} from "../../../services/auth.services.js";
import { ADMIN_USER_DATA, MY_MOMENT_POST } from "../../../utils/constant.js";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import { PostContext } from "../../../components/website/header/MainHeader.jsx";

const MyMomentsRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  .profile_top {
    /* .NFT_Balance {
      display: none !important;
    } */
    .NFT_Balance.other_profile_year {
      display: flex;
    }

    .other_profile_text {
      display: block;
      font-size: 1rem;
    }
  }
  .club_group {
    display: none !important;
  }
  h3,
  h4,
  p {
    position: static;
  }
  .sidebar_right {
    position: sticky;
    top: 144px;
    display: none;
  }
  .upload_card {
    border-radius: ${space.space16};
    padding: 64px ${space.space24};
    text-align: center;
    .add_head {
      margin: 18px 0 2px;
    }
  }
  .upload_photos {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background-color: ${colors.lightblue};
    box-shadow: ${siteGradient.boxshadowblue};
    background-image: url(${IconBluePhotograph});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40px 40px;
    cursor: pointer;
    margin: auto;
    input {
      display: none;
    }
  }
  .classmates_list {
    height: calc(100vh - 472px);
  }
  .sceen_bottom {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
  .sceen_main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-wrap: wrap;
  }
  .create_post {
    margin-top: 0;
  }
  @media ${device.mobileM} {
    .upload_photos {
      width: 100px;
      height: 100px;
      background-size: 54px 54px;
    }
    .upload_card {
      padding: ${space.space40} ${space.space24};
    }
  }
  @media ${device.tablet} {
    width: 75%;
    .profile_header {
      padding-left: ${space.space20};
    }
    .sidebar_right {
      display: block;
    }
    .mobile_hidden {
      display: block;
    }
    .club_group {
      flex-direction: column;
      align-items: flex-start;
      .count {
        font-size: 0.75rem;
        line-height: 19px;
      }
    }
    .classmates_list {
      height: calc(100vh - 516px);
    }
  }
  @media ${device.laptop} {
    width: 77%;
    .sidebar_right {
      top: 202px;
    }
    .classmates_list {
      height: calc(100vh - 566px);
    }
    .club_group {
      flex-direction: row;
      align-items: center;
    }
  }
  @media ${device.laptopL} {
    width: 80%;
    .club_group {
      .count {
        font-size: 1rem;
        line-height: 1.625rem;
      }
    }
    .classmates_list {
      height: calc(100vh - 586px);
    }
    .profile_header {
      padding-left: ${space.space40};
    }
  }
`;
function MyMoments() {
  const {
    setUserInfo,
    gettingClassList,
    classmatesData,
    setClubData,
    userInfo,
    getMomentPostListing,
    setpostlocation,
    setIsMyMoment,
    myMomentPostData,
    setPage,
    gettingNotification,
    handelLogout,
  } = useContext(PostContext);
  const { loaderDispatch } = useContext(loaderContext);

  const [classMatePage, setClassMatePage] = useState(1);

  const scrollRef = useRef();
  let pages = classMatePage;

  let sub = true;
  useEffect(() => {
    if (sub) {
      getStudentProfile();
      setIsMyMoment(true);
      setpostlocation(MY_MOMENT_POST);
      setClubData({});
      setPage(1);
    }

    return () => (sub = false);
  }, []);

  //getting student details on page load
  const getStudentProfile = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });

      let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));

      let studentData = await getSubscriptionDetail(userData);
      loaderDispatch({
        isLoading: false,
      });
      if (studentData?.data?.status === 410) {
        handelLogout();
      }
      if (studentData?.data?.status === StatusCodes.OK) {
        let data = studentData?.data?.data;

        gettingClassList({ StudentInfo: data, loader: true });
        setUserInfo(data);
        getMomentPostListing({
          userInfo: data,
          loader: true,
          is_moment: true,
        });
        gettingNotification({
          StudentInfo: data,
          notification: false,
          pages: 1,
          loader: true,
        });

        // getAllPostDetails({
        //   id: data?.User?.id,
        //   clubId: data?.club_id,
        //   studentId: data?.id,
        // });
      } else {
        toast(studentData?.data?.massage, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelPagination = () => {
    if (scrollRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        console.log("yesssss", scrollTop, clientHeight, scrollHeight);
        pages++;
        setClassMatePage(pages);
        let loader = 1;
        gettingClassList({ StudentInfo: userInfo, pages, loader: false });
      }
    }
  };
  return (
    <>
      <MyMomentsRow>
        <div className='sceen_main'>
          <ProfileHeader
            cname='profile_header'
            ClubProfileImage={userInfo?.filtered_image}
            Name={userInfo?.student_name}
            count={classmatesData?.length}
          />
          <div className='sceen_bottom'>
            <DashboardBody className='dash_body'>
              <CreatePost cname='create_post' postTitle={"Create Moment"} />

              {myMomentPostData?.length > 0 ? (
                myMomentPostData?.map((val, i) => {
                  return <Post val={val?.Post} key={i} />;
                })
              ) : (
                <p className='flex justify-center'>No post found..!!</p>
              )}

              {/* <Post postImage={PostImage2} />
              <Post postImage={PostImage} />
              <Post postImage={PostImage1} />
              <Post postImage={PostImage3} /> */}
            </DashboardBody>
            <DashboardRightBar className='sidebar_right'>
              <About
                description={
                  "My Moments capture your moments that you choose to keep private. We recommend using my moments to capture times with your best friends, make NFT art you want to mint once you're 18, and save memories that are yours. ."
                }
              />
              <Classmates
                classmatesData={classmatesData}
                message={"No Classmates Found..!!"}
                pData={"Classmates"}
                paginationRef={scrollRef}
                handelPagination={handelPagination}
              />
            </DashboardRightBar>
          </div>
        </div>
      </MyMomentsRow>
    </>
  );
}

export default MyMoments;
