import React from "react";
import styled from "styled-components";
import { space } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";
import { Container, SiteCard } from "../../../common/website/HOC.jsx";

import Landing_Video from "../../../images/website/Landing_Video.mp4";

import LandingNFTImage from "../../../images/website/image_NFT_light.png";
import LandingNFTImage2 from "../../../images/website/image_NFT_light2.png";
import LandingNFTTeaxt from "../../../images/website/image_NFT_text.svg";
import LandingNFTTeaxt2 from "../../../images/website/image_NFT_text2.svg";

const LandingVideoRow = styled.section`
  padding: 0;
  video {
    border-radius: 0.5rem;
  }
  .landing_video {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    background: rgba(48, 223, 242, 0.05);
    box-shadow: inset 0 0 0.625rem rgb(48 223 242 / 30%);
    padding: 1rem;
    .main_image {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
    .main_video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .main_image_text {
    margin: ${space.space30} auto 0;
    max-height: ${space.space12};
  }
  @media ${device.tablet} {
    .landing_video {
      height: 440px;
    }
    .main_image_text {
      max-height: 18px;
    }
  }
`;
function LandingVideo() {
  return (
    <>
      {" "}
      <LandingVideoRow>
        {" "}
        <Container>
          {" "}
          <div className='grid gap-6 md:grid-cols-2 items-stretch'>
            {" "}
            <div className=''>
              {" "}
              <SiteCard className='landing_video'>
                {" "}
                <img
                  src={LandingNFTImage}
                  alt='nft_image'
                  className='main_image'
                />{" "}
              </SiteCard>{" "}
              <img
                src={LandingNFTTeaxt}
                alt='nft_image'
                className='main_image_text'
              />{" "}
            </div>{" "}
            <div className=''>
              {" "}
              <SiteCard className='landing_video'>
                {" "}
                <video
                  src={Landing_Video}
                  controls
                  width='100%'
                  className='main_video'></video>{" "}
                {/* <img src={LandingNFTImage2} alt="nft_image" className='main_image'/> */}
              </SiteCard>{" "}
              <img
                src={LandingNFTTeaxt2}
                alt='nft_image'
                className='main_image_text'
              />{" "}
            </div>{" "}
          </div>{" "}
        </Container>{" "}
      </LandingVideoRow>{" "}
    </>
  );
}
export default LandingVideo;
