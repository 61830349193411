import React, { useLayoutEffect } from "react";
import { Link } from "react-router-dom";

function ContactUs() {
  useLayoutEffect(() => {}, []);
  return (
    <div>
      <h1 className='text-3xl font-bold underline'>Contact Us page</h1>
      <Link to='/'>Home</Link>
    </div>
  );
}

export default ContactUs;
