import React, { Fragment, useContext } from "react";
import styled from "styled-components";
import { Transition } from "@headlessui/react";
import HeadingFive from "../../../common/website/HeadingFive.jsx";
import { GrClose } from "react-icons/gr";
import { SiteModal } from "../../../common/website/HOC.jsx";
import { device } from "../../../common/website/Devices";

import IconRight from "../../../images/website/icon_right.svg";
import HeadingFour from "../../../common/website/HeadingFour.jsx";
import { space } from "../../../common/website/Variables";
import {
  CLUB_POST,
  IMG,
  MY_MOMENT_POST,
  SCHOOL_MOMENTS,
  TEAM_POST,
} from "../../../utils/constant.js";
import { PostContext } from "../header/MainHeader.jsx";
import { useEffect } from "react";

const PostSubmittedRow = styled.div`
  .modal_content {
    text-align: center;
    padding: ${space.space60} ${space.space16};
    img {
      width: 50px;
      height: 50px;
      margin: 0 auto ${space.space20};
    }
    h4 {
      margin: 0;
    }
    .modal_inner {
      max-width: 590px;
    }
  }
  @media ${device.mobileL} {
    .modal_content {
      padding: 112px 130px;
      img {
        width: 82px;
        height: 82px;
      }
    }
  }
`;

function PostSubmitted({
  setOpenImageModal,
  handleClick,
  setPostDetails,
  openImageModal,
}) {
  const {
    getAllPostDetails,
    userInfo,
    clubData,
    postlocation,
    teamData,
    getMomentPostListing,
    setPage,
  } = useContext(PostContext);

  useEffect(() => {
    if (!openImageModal) {
      // setOpenImageModal(false);
      setPostDetails({
        postImage: null,
        caption: "",
      });
      handleClick(IMG);
      handelPostCall();
    }
  }, [openImageModal]);

  const handelPostCall = () => {
    setPage(1);
    if (postlocation === SCHOOL_MOMENTS) {
      getAllPostDetails({
        id: userInfo?.User?.id,
        studentId: userInfo?.id,
        academicYear: userInfo?.academic_year,
        standard: userInfo?.standard,
      });
    } else if (postlocation === CLUB_POST) {
      getAllPostDetails({
        id: userInfo?.User?.id,
        clubId: clubData?.id,
        studentId: userInfo?.id,
      });
    } else if (postlocation === TEAM_POST) {
      getAllPostDetails({
        id: userInfo?.User?.id,
        sport_id: teamData?.id,
        studentId: userInfo?.id,
      });
    } else if (postlocation === MY_MOMENT_POST) {
      getMomentPostListing({ userInfo, is_moment: true });
    }
  };
  return (
    <PostSubmittedRow className='SetProfilePhoto'>
      <SiteModal>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          enterTo='opacity-100 translate-y-0 sm:scale-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100 translate-y-0 sm:scale-100'
          leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
          <div className='modal_inner'>
            <div className='modal_boday'>
              <div className='model_top'>
                <HeadingFive cname='title' h5Data={"Post Shared"} />
                <span
                  className='modal_close'
                  onClick={() => {
                    setOpenImageModal(false);
                    setPostDetails({
                      postImage: null,
                      caption: "",
                    });
                    handleClick(IMG);
                    handelPostCall();
                  }}>
                  {" "}
                  <GrClose />
                </span>
              </div>
              <div className='modal_content'>
                <img src={IconRight} alt='right icon' />
                <HeadingFour h4Data={"Your post has been shared."} />
              </div>
            </div>
          </div>
        </Transition.Child>
      </SiteModal>
    </PostSubmittedRow>
  );
}

export default PostSubmitted;
