import React, { useState } from "react";
import CommonModal from "../../../common/commonModal";
import { ErrorMessage, Field, Formik, Form } from "formik";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import styled from "styled-components";

const DateDiv = styled.div`
  .react-date-picker__wrapper {
    border: none !important;
  }
  .react-calendar__tile:disabled {
    color: gray;
  }
  .react-calendar__navigation button:disabled {
    color: gray;
  }
`;

function CreateJob({
  openCreateModal,
  setOpenCreateModal,
  isEdit,
  initialValue,
  validation,
  inputArray,
  submit,
  onChange,
  itemsId,
  handelSubmit,
  checkDateField,
  date,
  setItemsId,
}) {
  return (
    <>
      <CommonModal
        openModal={openCreateModal}
        setOpenModal={setOpenCreateModal}
        header={isEdit ? "Edit Job" : "Create Job"}
        divClass={
          "inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6"
        }>
        <div className='max-h-96 overflow-y-auto px-2 w-80 '>
          <div className='mx-2'>
            <Formik
              initialValues={initialValue}
              validationSchema={validation}
              onSubmit={handelSubmit}>
              <Form>
                {inputArray?.map((value) => {
                  return (
                    <div className='my-2 text-sm' key={value?.name}>
                      <div>
                        <label
                          htmlFor='firstName'
                          className='font-medium text-sm text-slate-800'>
                          {value?.label}
                          <span className='text-red-500'>*</span>
                        </label>
                      </div>
                      <div>
                        <Field
                          name={value?.name}
                          type='text'
                          className='border px-2 py-2 rounded focus:ring-1 ring-indigo-500 outline-none w-full'
                          as={value?.as}
                        />
                      </div>
                      <ErrorMessage
                        name={value?.name}
                        className='text-red-500'
                        component={"div"}
                      />
                    </div>
                  );
                })}

                <div className='my-2 text-sm'>
                  <div>
                    <label
                      htmlFor='firstName'
                      className='font-medium text-sm text-slate-800'>
                      End date<span className='text-red-500'>*</span>
                    </label>
                  </div>
                  <DateDiv>
                    <DatePicker
                      onChange={onChange}
                      value={date}
                      minDate={new Date()}
                      autoFocus={false}
                      clearIcon={false}
                      className={
                        "border px-2 py-2 rounded focus:ring-1 ring-indigo-500 outline-none w-full"
                      }
                    />
                  </DateDiv>

                  {!date && submit && (
                    <div className='text-red-500'>
                      <p>Please select this field </p>
                    </div>
                  )}
                </div>

                {isEdit && (
                  <label className='block text-sm font-medium text-gray-700 mt-3 mb-3 w-fit'>
                    Status<span className='text-red-600'>&#42;</span>
                    <label
                      htmlFor='default-toggle1'
                      className='inline-flex relative items-center cursor-pointer align-middle ml-5 w-fit'>
                      <input
                        type='checkbox'
                        checked={itemsId?.status == 1 ? true : false}
                        name='status1'
                        onChange={() => {
                          setItemsId((preVal) => {
                            return {
                              ...preVal,
                              status: itemsId?.status == 1 ? 2 : 1,
                            };
                          });
                        }}
                        id='default-toggle1'
                        className='sr-only peer'
                      />
                      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </label>
                )}

                <div className='flex justify-between space-x-2 my-4 text-md font-medium'>
                  <button
                    type='submit'
                    onClick={checkDateField}
                    className='px-3 py-2 bg-indigo-600 hover:bg-indigo-700 text-white rounded w-full shadow-md shadow-slate-200 ring-indigo-700 ring-offset-2 focus:ring-2 outline-none'>
                    Submit
                  </button>
                  <button
                    type='button'
                    onClick={() => setOpenCreateModal(false)}
                    className='px-3 py-2 w-full shadow-md rounded shadow-slate-200 hover:shadow-xl text-slate-800 outline-none'>
                    Cancel
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </CommonModal>
    </>
  );
}

export default CreateJob;
