let bodyColor = "#0B0B0B";
let blackColor = "#000000";
let whiteColor = "#ffffff";
let primaryColor = "#30Dff2";
let secondaryColor = "#70308C";
let darkGrey = "#171717";
let darkGrey2 = "#C0C0BF";
let lightGrey = "#797979";
let lightGreyTwo = "#ACACAC";
let errorColor = "#FF9E9";
let lightblueColor = "rgba(48,223,242,0.05)";
let placeholderColor = "#acacac80";
let redColor = "#d10000";
let cardLightBG = "rgba(23, 23, 23, 0.1)";
let inputBorderColor = "#ffffff80";
let lightWhiteBorder = "#ffffff1a";
let lightWhiteColor = "rgba(255, 255, 255, 0.05)";
let createBorderColor = "#005685";

export const colors = {
  body: bodyColor,
  black: blackColor,
  white: whiteColor,
  primary: primaryColor,
  secondary: secondaryColor,
  darkgrey: darkGrey,
  lightgrey: lightGrey,
  lightgrey2: lightGreyTwo,
  error: errorColor,
  lightblue: lightblueColor,
  darkgrey2: darkGrey2,
  placeholdercolor: placeholderColor,
  red: redColor,
  cardlightbg: cardLightBG,
  inputbordercolor: inputBorderColor,
  lightwhiteborder: lightWhiteBorder,
  lightwhitecolor: lightWhiteColor,
  createbordercolor: createBorderColor,
};

export const space = {
  space8: "0.5rem",
  space10: "0.625rem",
  space12: "0.75rem",
  space14: "0.875rem",
  space16: "1rem",
  space20: "1.25rem",
  space24: "1.5rem",
  space30: "1.875rem",
  space34: "2.125rem",
  space40: "2.5rem",
  space50: "3.125rem",
  space60: "3.75rem",
  space70: "4.375rem",
  space80: "5rem",
  space150: "9.375rem",
  spaceMain: "6.563rem",
};

export const siteGradient = {
  bluePink: `linear-gradient(90deg, ${primaryColor} 0.7%, ${secondaryColor} 97.91%)`,
  pinkBlue: `linear-gradient(90deg, ${secondaryColor} 0.7%, ${primaryColor} 97.91%)`,
  boxshadowblue: `inset 0 0 0.625rem rgb(48 223 242 / 30%)`,
  boxshadowWhite: `inset 0px 0px 10px rgba(255, 255, 255, 0.3)`,
  cardlightboxshadow: `0px 0px 20px rgba(48, 223, 242, 0.3)`,
  modalBoxshadow: `0px 0px 30px rgba(48, 223, 242, 0.2)`,
  linearGradientBorder: `linear-gradient(91.46deg, #30DFF2 -0.7%, #70308C 97.91%)`,
};
