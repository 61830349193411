import React from "react";

function PendingStatus({ message }) {
  return (
    <span className='text-sm inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-yellow-400 text-white rounded'>
      {message}
    </span>
  );
}

export default PendingStatus;
