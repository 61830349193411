import { useContext, useEffect } from "react";
import { DASHBOARD } from "../../utils/constant";

import { AdminContext } from "../../common/DashboardWrapper";
import AdminOverview from "../../components/admin Overview/AdminOverview";

// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ");
// }

function AdminDashboard() {
  const { setAdminActiveTab, adminActiveTab } = useContext(AdminContext);
  useEffect(() => {
    setAdminActiveTab(DASHBOARD);
  }, []);

  return (
    <div className='flex overflow-hidden bg-white'>
      {/* Main column */}
      <div className='flex flex-col w-0 flex-1 overflow-hidden'>
        <main className='flex-1 relative z-0 overflow-y-auto focus:outline-none'>
          {/* Pinned projects */}
          {adminActiveTab === "Dashboard" && (
            <div className='px-4 mt-6 sm:px-6 lg:px-8'>
              <AdminOverview />
            </div>
          )}
        </main>
      </div>
    </div>
  );
}

export default AdminDashboard;
