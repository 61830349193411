import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { SchoolContext } from "../../common/SchoolDashboardWrapper";
import {
  ADMIN_USER_DATA,
  DELETE,
  EDIT,
  LABELS,
  UPDATE,
} from "../../utils/constant";
import SearchFilter from "../../components/search_filter/SearchFilter";
import ListLayout from "../../common/ListLayoutEditIcon.jsx";
import SelectFilter from "../../components/school_select_filter/SelectFilter";
import ReactPaginate from "react-paginate";
import { loaderContext } from "../../components/global loader/global-loader-provider";
import {
  labelDelete,
  labelListing,
  schoolLabelListing,
  updateLabelData,
  updateMobileLabelData,
} from "../../services/auth.services";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import SchoolLabelEdit from "../../common/SchoolLabelEdit";
import CommonModal from "../../common/commonModal";
import CustomInput from "../../common/CustomInput";
import AdminDeleteModel from "../../common/AdminDeleteModel";

function SchoolLabelManagement() {
  const [searchItem, setSearchItem] = useState("");
  const [itemOffset, setItemOffset] = useState(1);
  const [sortby, setSortby] = useState("");
  const [orderby, setOrderby] = useState(true);
  const [currentItems, setCurrentItems] = useState(null);
  const [data, setData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [dataCount, setDataCount] = useState(0);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [itemsId, setItemsId] = useState("");
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [labelOptions, setLabelOptions] = useState([
    {
      id: "",
      label: "Select label",
      value: "",
      selected: false,
    },
  ]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [labelName, setLabelName] = useState("Label value");
  const [updatedLabel, setUpdatedLabel] = useState({});
  const [submit, setSubmit] = useState(false);

  const { setAdminActiveTab } = useContext(SchoolContext);
  const { loaderDispatch } = useContext(loaderContext);
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));

  const tableHeader = [
    {
      key: "S.No.",
      value: "key",
      clickable: false,
      sorting: false,
    },
    {
      key: "Label Name",
      value: "MasterLabel",
      subValue: "name",
      clickable: false,
      sorting: true,
    },
    { key: "label value", value: "labelName", clickable: false, sorting: true },

    { key: "status", value: "status", clickable: false, sorting: true },
    { key: "Label type", value: "labelType", clickable: false, sorting: true },

    {
      key: "options",
      value: "options",
    },
  ];

  const itemsOnPage = [
    { label: 10, value: "10" },
    { label: 20, value: "20" },
    { label: 50, value: "50" },
    { label: 70, value: "70" },
    { label: 100, value: "100" },
  ];

  const view = [
    {
      label: "Label name",
      type: "text",
      maxLength: 30,
      minLength: 0,
      placeholder: "Label name",
      name: "name",
      disable: true,
      value: "name",
    },
    {
      label: `${labelName} value`,
      type: "text",
      maxLength: 30,
      minLength: 0,
      placeholder: `Enter ${labelName}...`,
      name: "labelName",
      disable: false,
      value: "labelName",
    },
  ];

  useLayoutEffect(() => {
    setAdminActiveTab(LABELS);
  }, []);
  useEffect(() => {
    const debounce = setTimeout(() => {
      handelUpdatedLabelList();
    }, 300);
    return () => clearTimeout(debounce);
  }, [searchItem, sortby, orderby, itemOffset, itemsPerPage]);

  useEffect(() => {
    setCurrentItems(data);
    setPageCount(dataCount);
  }, [data, dataCount, itemsPerPage]);

  useEffect(() => {
    if (!openCreateModal) {
      setUpdatedLabel({});
      setSubmit(false);
    }
  }, [openCreateModal]);

  const handleOptions = (optionValue, item) => {
    switch (optionValue) {
      case EDIT:
        setOpenViewModal(true);
        setItemsId(item?.id);
        setLabelName(item?.labelType);
        break;

      case DELETE:
        setItemsId(item?.id);
        setOpenDeleteModal(true);
        break;

      default:
        break;
    }
  };

  const handleSorting = (data, order) => {
    setSortby(data);
    setOrderby(order);
  };

  const handlePageClick = (event) => {
    setItemOffset(event.selected + 1);
  };

  const handelLabelList = async (allUpdatedLabelList) => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let params = {
        id: userData?.id,
      };

      let result = await labelListing(params);
      loaderDispatch({
        isLoading: false,
      });
      setLabelOptions([
        {
          id: "",
          label: "Select label",
          value: "",
          selected: false,
        },
      ]);
      if (result?.data?.status === StatusCodes.OK) {
        let data = result?.data?.data?.labelListing?.rows;

        //match id of allUpdatedLabelList id and data id and remove object of matched id

        // data = data.filter((val) => {
        //   return val !== undefined;
        // });

        for (let i = 0; i < data.length; i++) {
          setLabelOptions((preVal) => {
            return [
              ...preVal,
              {
                id: data[i]?.id,
                label: data[i]?.name,
                value: data[i]?.name,
                selected: false,
              },
            ];
          });
        }

        console.log("updatedList", data, allUpdatedLabelList);

        // if (allUpdatedLabelList?.length > 0) {
        //   for (let i = 0; i < allUpdatedLabelList?.length; i++) {
        //     setLabelOptions((preVal) => {
        //       return preVal.filter((val) => {
        //         if (val?.id !== allUpdatedLabelList[i]?.MasterLabel.id) {
        //           return {
        //             id: val?.id,
        //             label: val?.name,
        //             value: val?.name,
        //             selected: false,
        //           };
        //         }
        //       });
        //     });
        //   }
        // }
        let ids = [];

        if (allUpdatedLabelList?.length > 0) {
          setLabelOptions((preVal) => {
            for (let i = 0; i < preVal.length; i++) {
              let count = 0;
              for (let j = 0; j < allUpdatedLabelList.length; j++) {
                if (preVal[i]?.id === allUpdatedLabelList[j].MasterLabel?.id) {
                  ++count;
                  if (count === 2) {
                    ids.push(i);
                  }
                }
              }
            }

            for (let i = 0; i < ids.length; i++) {
              delete preVal[ids[i]];
            }
            return preVal?.filter((val) => {
              return val !== undefined || val !== null;
            });
          });
        }
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelUpdatedLabelListForRemove = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let order = orderby ? "desc" : "asc";
      let params = {
        id: userData?.id,
      };

      let result = await schoolLabelListing(params);
      loaderDispatch({
        isLoading: false,
      });

      if (result?.data?.status === StatusCodes.OK) {
        const data = result?.data?.data?.labelListing?.rows;
        const count = result?.data?.data?.totalPage;

        handelLabelList(data);
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelUpdatedLabelList = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let order = orderby ? "desc" : "asc";
      let params = {
        id: userData?.id,
        search: searchItem,
        page: itemOffset,
        sort_by: sortby,
        orderBy: order,
        limit: itemsPerPage,
      };

      let result = await schoolLabelListing(params);
      loaderDispatch({
        isLoading: false,
      });

      if (result?.data?.status === StatusCodes.OK) {
        const data = result?.data?.data?.labelListing?.rows;
        const count = result?.data?.data?.totalPage;

        setData(data);
        setDataCount(count);
        handelUpdatedLabelListForRemove();
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  //to get and set name of create label
  const handelCreateChange = (e) => {
    setSubmit(false);

    const value = e?.target?.value;
    setUpdatedLabel((preVal) => {
      return { ...preVal, label_id: value };
    });
  };

  //to get and set value of choosed label value
  const handelLabelName = (e) => {
    const { name, value } = e?.target;
    if (value?.toString()?.trim()?.length > 0 || value.toString() === "") {
      setUpdatedLabel((preVal) => {
        setSubmit(false);

        return {
          ...preVal,
          [name]: value,
        };
      });
    }
  };

  //regex for alphabets and space
  const alphaSpace = /^[A-Za-z\s]*$/;
  //checking condition
  const checkAlphaSpace = (value) => {
    return alphaSpace.test(value);
  };

  console.log("updatedLabel", updatedLabel);

  const handelUpdateData = async () => {
    try {
      let body = {
        school_id: userData?.id ? userData?.id : "",
        status: 1,
        labelName: updatedLabel?.labelName,
        type: 1,
      };

      loaderDispatch({
        isLoading: true,
      });

      let result = await updateLabelData(updatedLabel?.label_id, body);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
        });
        setOpenCreateModal(false);
        handelUpdatedLabelList();
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handelUpdateDataMobile = async () => {
    try {
      let body = {
        school_id: userData?.id ? userData?.id : "",
        status: 1,
        labelName: updatedLabel?.labelName2,
        type: 2,
      };
      loaderDispatch({
        isLoading: true,
      });

      let result = await updateMobileLabelData(updatedLabel?.label_id, body);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
        });
        setOpenCreateModal(false);
        handelUpdatedLabelList();
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelDelete = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });

      let result = await labelDelete(itemsId);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
        });
        setOpenDeleteModal(false);
        handelUpdatedLabelList();
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelSubmit = () => {
    if (
      updatedLabel?.label_id &&
      updatedLabel?.labelName &&
      updatedLabel?.labelName2
    ) {
      handelUpdateDataMobile();
      handelUpdateData();
    } else if (updatedLabel?.label_id && updatedLabel?.labelName) {
      handelUpdateData();
    } else if (updatedLabel?.label_id && updatedLabel?.labelName2) {
      handelUpdateDataMobile();
    } else {
      setSubmit(true);
    }
  };

  return (
    <>
      <div className='p-2 grid grid-cols-2 gap-2 mt-1'>
        <div className=' flex justify-center'>
          <SearchFilter
            setSearchItem={setSearchItem}
            searchItem={searchItem}
            setPage={setItemOffset}
            cname={
              " block w-full border border-gray-300 rounded-md shadow py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
            }
          />
        </div>
        <div className='flex justify-end '>
          <button
            className='px-4 py-2 bg-indigo-500 text-white hover:bg-indigo-600 rounded shadow-md'
            onClick={() => {
              setOpenCreateModal(true);
            }}>
            Rename Labels
          </button>
        </div>
      </div>
      <ListLayout
        handleOptions={handleOptions}
        tableHeader={tableHeader}
        handleSorting={handleSorting}
        currentItems={currentItems}
      />
      <div className='flex justify-between'>
        {data?.length > 0 && (
          <>
            <div
              className='block lg:inline-block lg:mt-0'
              style={{ marginTop: "6px" }}>
              <label htmlFor='item' className='ml-5'>
                Record Per Page:
              </label>
              <SelectFilter
                options={itemsOnPage}
                selectCls={"ml-3 bg-white border p-2 rounded"}
                name={"item"}
                id={"item"}
                onChange={(e) => {
                  setItemsPerPage(parseInt(e.target.value));
                }}
              />
            </div>

            <div className='mt-2 mb-10'>
              <ReactPaginate
                breakLabel='...'
                nextLabel='Next >'
                onPageChange={handlePageClick}
                pageCount={pageCount}
                previousLabel='< Previous'
                renderOnZeroPageCount={null}
                containerClassName={"pagination"}
                pageClassName={"pageClass"}
                forcePage={itemOffset - 1}
              />
            </div>
          </>
        )}
      </div>
      {data?.length === 0 && (
        <div className='flex flex-row absolute left-1/2 top-1/2'>
          <p>Data not found..!!</p>
        </div>
      )}
      <SchoolLabelEdit
        openViewModal={openViewModal}
        setOpenViewModal={setOpenViewModal}
        view={view}
        itemId={itemsId}
        header={"Edit Label"}
        handelLabelList={handelUpdatedLabelList}
      />

      {/* create label modal starts */}
      <CommonModal
        openModal={openCreateModal}
        setOpenModal={setOpenCreateModal}
        header={"Rename label"}
        divClass={
          "inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
        }>
        <div className='mb-2 flex justify-between items-center'>
          <div className='w-1/4'>
            <label className='block text-sm font-medium text-gray-700'>
              Label name<span className='text-red-500'>*</span>
            </label>
          </div>
          <div className='w-3/4'>
            <SelectFilter
              options={labelOptions}
              name={"name"}
              id={"item"}
              onChange={handelCreateChange}
              selectCls={
                "mt-1 block w-full  border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm bg-white"
              }
            />
            {submit && !updatedLabel?.label_id && (
              <p className='text-sm text-red-500'>Please fill the field</p>
            )}
          </div>
        </div>
        <div className='mb-2 flex justify-between itemsId-center'>
          <div className='w-1/4'>
            <label className='block text-sm font-medium text-gray-700'>
              Web label value
            </label>
          </div>
          <div className='w-3/4'>
            <CustomInput
              type='text'
              inputCls={
                "mt-1 block w-full  border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm bg-white"
              }
              inputData={updatedLabel?.labelName}
              placeholder='Web label custom name'
              onChange={handelLabelName}
              name='labelName'
              minLength={0}
              maxLength={30}
            />
            {submit && !updatedLabel?.labelName && (
              <p className='text-sm text-red-500'>Please fill the field</p>
            )}
          </div>
        </div>
        <div className='mb-2 flex justify-between itemsId-center'>
          <div className='w-1/4'>
            <label className='block text-sm font-medium text-gray-700'>
              Mobile label value
            </label>
          </div>
          <div className='w-3/4'>
            <CustomInput
              type='text'
              inputCls={
                "mt-1 block w-full  border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm bg-white"
              }
              inputData={updatedLabel?.labelName2}
              placeholder='Mobile label custom name'
              onChange={handelLabelName}
              name='labelName2'
              minLength={0}
              maxLength={30}
            />
            {submit && !updatedLabel?.labelName2 && (
              <p className='text-sm text-red-500'>Please fill the field</p>
            )}
          </div>
        </div>
        <div className='flex justify-end'>
          <button
            type='submit'
            className='px-4 py-2 bg-indigo-500 hover:bg-indigo-600 text-white rounded shadow-md'
            onClick={handelSubmit}>
            Save
          </button>
        </div>
      </CommonModal>
      {/* create label modal ends */}

      <AdminDeleteModel
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        header={"Delete Label"}
        deleteData={handelDelete}
      />
    </>
  );
}

export default SchoolLabelManagement;
