import moment from "moment";
import React, { useLayoutEffect, useState } from "react";
import { AiFillLike } from "react-icons/ai";
import { FaComment } from "react-icons/fa";
import ActiveStatus from "../../common/ActiveStatus";
import IconMenuDropDown from "../../common/IconMenuDropDown";
import InActiveStatus from "../../common/InActiveStatus";
import { COMMENT } from "../../utils/constant";
import dateDifference from "date-difference";
import { MentionsInput, Mention } from "react-mentions";
import styled from "styled-components";

const MentionSpan = styled.div`
  .mention {
    textarea {
      display: none !important;
    }
    strong {
      /* font-family: "Inter" !important; */
      /* background-color: #171717; */
      color: #1200f0fa !important;
    }
    span {
      /* font-family: "Inter" !important; */
      color: #475569;
      visibility: visible !important;
    }
  }
`;
function AdminClubPostDetails(props) {
  const mentionStyle = {
    control: {
      backgroundColor: "#f5f5f4",
      // fontSize: " 0.875rem",
      // fontWeight: "normal",
      // margin: "0.625rem 0 1.25rem",
      lineHeight: "1.313rem",
      position: "static",
    },
    "&multiLine": {
      control: {
        // fontFamily: "monospace",
        minHeight: 63,
      },
      highlighter: {
        padding: 9,
        border: "1px solid transparent",
      },
      input: {
        padding: 9,
        // border: "1px solid silver",
      },
    },
    "&singleLine": {
      display: "inline-block",
      width: 180,
      highlighter: {
        padding: 1,
        border: "2px inset transparent",
      },
      input: {
        padding: 1,
        border: "2px inset",
      },
    },
    suggestions: {
      list: {
        backgroundColor: "black",
        border: "1px solid rgba(0,0,0,0.15)",
        fontSize: 16,
      },
      item: {
        padding: "5px 15px",
        borderBottom: "1px solid rgba(0,0,0,0.15)",
        "&focused": {
          backgroundColor: "#00e1f0",
        },
      },
    },
  };

  const [types, setTypes] = useState(false);
  // const [page, setPage] = useState(1)
  // const [postId, setPostId] = useState(null);

  useLayoutEffect(() => {
    if (props.type) {
      setTypes(true);
    } else {
      setTypes(false);
    }
  }, []);

  const getLikeList = async (postId, page) => {
    try {
      let body = {
        id: postId,
        page: page,
      };
    } catch (error) {}
  };

  const postTime = (createdAt) => {
    let date = new Date(createdAt);
    let vals = dateDifference(new Date(createdAt), new Date(new Date()), {
      compact: true,
    });
    let vals2 = vals.slice(1);

    if (vals2 === "1d") {
      return `yesterday ${date?.getHours()}:${date?.getMinutes()} `;
    } else if (vals2?.includes("ms")) {
      return `few seconds ago`;
    } else if (vals2?.includes("m")) {
      return `${vals2} ago`;
    } else if (vals2?.includes("s")) {
      return `few seconds ago`;
    } else if (vals2?.includes("h")) {
      return `${vals2} ago`;
    } else {
      return `${date?.getDate()}-${
        date?.getMonth() + 1
      }-${date.getFullYear()} ${date?.getHours()}:${date?.getMinutes()} `;
    }
  };
  return (
    <section aria-labelledby='notes-title'>
      <div className='bg-white shadow sm:rounded-lg sm:overflow-hidden'>
        <div className=''>
          <div className='border-b border-gray-200 px-4 py-4 flex items-center justify-between sm:px-6 lg:px-4'>
            <h1 className='text-lg font-medium leading-6 text-gray-600 sm:truncate w-1/2 mt-3 '>
              {/* Posts */}
            </h1>

            <div className=' float-right flex'>
              <input
                type={"text"}
                placeholder={"Search..."}
                onChange={(e) => {
                  props.setSearch(e.target.value.trim());
                }}
                autoComplete='cc-given-name'
                className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm'
              />

              {types && (
                <button
                  className='ml-3 border px-3 py-0 h-10 mt-1 border-indigo-500 bg-indigo-500 text-white hover:border-indigo-600 hover:bg-indigo-600 rounded shadow'
                  onClick={() => props.searchKeyWord()}>
                  Search
                </button>
              )}
              <div className='grid-cols-2 grid flex-1 min-w-0 float-left align-middle'></div>
            </div>
          </div>
          {props?.viewData?.Posts?.length > 0 ? (
            <div>
              {props?.viewData?.Posts?.map((item, key) => {
                return (
                  <div key={key} className='text-gray-600 mt-5 '>
                    <div className=' m-auto rounded shadow-md shadow-slate-400 mb-12 bg-stone-100 mx-4 '>
                      <div className='px-2 py-2'>
                        <div className=' bg-red'>
                          <span className='flex w-fit'>
                            <img
                              src={
                                item?.User?.filtered_image
                                  ? item?.User?.filtered_image
                                  : "https://t3.ftcdn.net/jpg/01/09/00/64/360_F_109006426_388PagqielgjFTAMgW59jRaDmPJvSBUL.jpg"
                              }
                              alt=''
                              style={{
                                display: "inline-block",
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                objectFit: "cover",
                              }}
                            />

                            <div className='font-bold text-xl ml-2'>
                              {item?.User?.student_name}
                            </div>
                          </span>
                          <div
                            className='text-sm mb-2 ml-14'
                            style={{
                              marginTop: "-1rem",
                            }}>
                            {postTime(item?.createdAt)}
                            <span className='ml-2'>
                              {item?.status == 1 ? (
                                <ActiveStatus message={"Active"} />
                              ) : (
                                <InActiveStatus message={"Inactive"} />
                              )}
                            </span>
                          </div>

                          <div
                            className=' '
                            style={{ float: "right", marginTop: "-50px" }}
                            onClick={() => {
                              props.setId(item.id);
                            }}>
                            <IconMenuDropDown
                              options={item}
                              setOpenDeleteModal={props.setOpenDeleteModal}
                              changePostStatus={props.changePostStatus}
                              setActive={props.setActive}
                            />
                          </div>
                        </div>
                        {!item?.moment_id ? (
                          <div className=' mt-4 break-words ml-3'>
                            <p>{item?.description || ""}</p>
                          </div>
                        ) : (
                          <MentionSpan>
                            <span className='mention'>
                              <MentionsInput
                                name='caption'
                                style={mentionStyle}
                                rows='6'
                                disabled
                                value={item?.description || ""}>
                                <Mention
                                  trigger='@'
                                  // data={userData}

                                  appendSpaceOnAdd={true}
                                />
                              </MentionsInput>
                            </span>
                          </MentionSpan>
                        )}
                      </div>
                      <div className='rounded'>
                        {item?.postName &&
                          (item?.postType === 1 ? (
                            <img
                              className='w-full px-2 py-2'
                              src={item?.postName}
                              alt='Sunset in the mountains'
                              // style={{
                              //   height: "380px",
                              // }}
                            />
                          ) : (
                            <video
                              className='px-2 py-2'
                              controls
                              width='100%'
                              height='400'
                              title={key}
                              src={item?.postName}
                              type='video/mp4'></video>
                          ))}
                      </div>
                    </div>
                    <div className='flex justify-end relative bottom-8 right-4'>
                      <AiFillLike
                        className='text-md text-red-600 cursor-pointer'
                        onClick={() => {
                          props.setOpenPostModal(true);
                          props.setPostDetail(item);
                          props.setType("");
                          getLikeList(item?.id);
                        }}
                      />{" "}
                      <p className='mr-5 text-sm '>{item.total_likes}</p>
                      <FaComment
                        className='text-sm mt-1 mr-1 text-blue-600 cursor-pointer'
                        onClick={() => {
                          props.setOpenPostModal(true);
                          props.setPostDetail(item);
                          props.setType(COMMENT);
                        }}
                      />
                      <p className='text-sm '>{item.total_comments}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
      {props?.viewData?.Posts?.length <= 0 && (
        <div className='flex relative left-[38%]'>
          <p>Data not found..!!</p>
        </div>
      )}
    </section>
  );
}

export default AdminClubPostDetails;
