import React from "react";
import {
  DashboardBody,
  DashboardRightBar,
} from "../../../common/website/HOC.jsx";
import ClassmatesAction from "../../../components/website/Classmates/ClassmatesAction.jsx";

import MomentsImageRow from "../../../common/website/MomentsImage.jsx";
import MomentsImageNFT from "../../../common/website/MomentsImageNFT.jsx";
import NFTBalance3 from "../../../images/website/moment_img3.png";
import NFTBalance4 from "../../../images/website/moment_img4.png";
import NFTBalance5 from "../../../images/website/moment_img5.png";
import NFTBalance6 from "../../../images/website/moment_img6.png";
import NFTImageBalance from "../../../images/website/moment_img_nft.png";
import NFTImageBalance2 from "../../../images/website/moment_img_nft2.png";
import NFTImageBalance3 from "../../../images/website/moment_img_nft3.png";
import NFTImageBalance4 from "../../../images/website/moment_img_nft4.png";

function TradeMomentsTab(props) {
  console.log("props", props?.galleryData);
  return (
    <>
      <DashboardBody className='dash_body'>
        <div className='tab_body'>
          <ul className='tab_list'>
            <li
              className='tab_item'
              onClick={() => props.handleClick("my_moments")}>
              My Moment
            </li>
            {/* <li className='tab_item active'>Trade Moments</li> */}
            <li
              className='tab_item'
              onClick={() => props.handleClick("digital_art")}>
              Digital Art Gallery
            </li>
            <li
              className='tab_item'
              onClick={() => props.handleClick("nft_moments")}>
              NFT
            </li>
          </ul>
          <div
            className='moment_list_block'
            ref={props?.scrollRef}
            onScroll={props?.handelScroll}>
            <div className='moments_list'>
              {props?.galleryData?.length > 0 && props?.galleryData ? (
                props?.galleryData?.map((value, i) => {
                  return (
                    <MomentsImageRow
                      cname={value?.postType == 2 ? "video" : ""}
                      value={value}
                      key={i}
                    />
                  );
                })
              ) : (
                <p>Post not found..!!</p>
              )}
            </div>
          </div>
        </div>
      </DashboardBody>

      <DashboardRightBar className='sidebar_right'>
        {/* <ClassmatesAction /> */}
      </DashboardRightBar>
    </>
  );
}

export default TradeMomentsTab;
