import React, { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { AppContext } from "../App";
import { admin } from "../linksprovider";
import { ADMIN_USER_DATA } from "../utils/constant";

function SecuredRoute() {
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }

  let adminUser = false;

  if (userData && userData.user_type === 1) {
    adminUser = true;
  }

  return adminUser ? <Outlet /> : <Navigate to={admin.login} />;
}

export default SecuredRoute;
