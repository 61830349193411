import React, {
  useState,
  Fragment,
  useEffect,
  useRef,
  useContext,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { GrClose } from "react-icons/gr";
import { loaderContext } from "../../components/global loader/global-loader-provider";
import { studentListing } from "../../services/auth.services";
import { ADMIN_USER_DATA } from "../../utils/constant";
import { StatusCodes } from "http-status-codes";
import Select from "react-select";
function ClubEdit({
  viewData,
  openViewModal,
  setOpenViewModal,
  view,
  setEditData,
  updateData,
  error,
  setError,
  option,
  setDataToUpdate,
  dataToUpdate,
  setSelectedUserIds,
  selectedUserIds,
}) {
  useEffect(() => {
    setDataToUpdate({
      title: viewData?.title ? viewData?.title : "",
      status: viewData?.status ? viewData?.status : "",
      description: viewData?.description ? viewData?.description.trim() : "",
      startDate: viewData?.startDate ? viewData?.startDate : "",
      dueDate: viewData?.dueDate ? viewData?.dueDate : "",
    });
    handleSchoolData();
  }, [viewData]);
  const [searchItem, setSearchItem] = useState("");
  const [data, setData] = useState([]);

  const [itemsPerPage, setItemsPerPage] = useState(10000);
  const [sortby, setSortby] = useState("");
  const [orderby, setOrderby] = useState(true);
  const [page, setPage] = useState(1);
  const { loaderDispatch } = useContext(loaderContext);
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  const handleSchoolData = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let order = orderby ? "desc" : "asc";

      if (searchItem.toLowerCase() === "active") {
        setSearchItem("1");
      } else if (searchItem.toLowerCase() === "inactive") {
        setSearchItem("2");
      }
      let result = await studentListing(
        userData.id,
        `&search=${searchItem.trim()}&sort_by=${sortby}&order=${order}&page=${page}&limit=${itemsPerPage}`
      );
      loaderDispatch({
        isLoading: false,
      });
      setData([]);

      const studentData = result?.data?.data?.studentListing;

      if (result?.data?.status === StatusCodes.OK) {
        setData(studentData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // console.log("viewData", viewData);

  const inputRef = useRef(null);

  // let imageUrl;
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "club_profile") {
      setDataToUpdate((preVal) => {
        return { ...preVal, [name]: e.target.files[0] };
      });
    } else {
      if (value?.toString().trim().length > 0 || value === "") {
        setDataToUpdate((preVal) => {
          return { ...preVal, [name]: value };
        });
      }
    }
    setError((preVal) => {
      return { ...preVal, [name]: false };
    });
  };

  const handelChangeselect = (event) => {
    if (
      event &&
      event.length > 0 &&
      event[event.length - 1].value === "selectAll"
    ) {
      // If "Select All" is selected, set selectedUserIds to all options except "Select All"
      const allUsers = options.filter((option) => option.value !== "selectAll");
      setSelectedUserIds("");
      setSelectedUserIds(allUsers);
    } else {
      setSelectedUserIds(event);
    }
  };

  const options = data?.map((singleitem) => ({
    value: singleitem?.id,
    label: singleitem?.student_name,
  }));

  options.unshift({
    value: "selectAll",
    label: "Select All",
  });

  const handleCloseModal = () => {
    setDataToUpdate({
      title: "",
      status: "",
      description: "",
      startDate: "",
      dueDate: "",
    });
    setSelectedUserIds("");
    setOpenViewModal(false);
  };

  return (
    <Transition.Root show={openViewModal} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={openViewModal}
        onClose={setOpenViewModal}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mb-6 row block text-2xl font-medium text-gray-700">
                  <span>Edit Details </span>
                  <span className="float-right cursor-pointer">
                    {" "}
                    <GrClose onClick={handleCloseModal} />
                  </span>
                </div>
                {view.map((val, i) => {
                  return (
                    <>
                      <label className="block text-sm font-medium text-gray-700">
                        {val.label}
                        <span className="text-red-600">&#42;</span>
                      </label>

                      <input
                        type={val.type}
                        value={val.value}
                        name={val.name}
                        onChange={handleChange}
                        minLength={val.minLength}
                        maxLength={val.maxLength}
                        autoComplete="cc-given-name"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                      />
                      {error[val.name] && (
                        <p className="text-sm text-red-500">
                          Please fill the field
                        </p>
                      )}
                    </>
                  );
                })}

                <label className="block text-sm font-medium text-gray-700 mt-3 mb-3">
                  Status<span className="text-red-600">&#42;</span>
                  <label
                    htmlFor="default-toggle"
                    className="inline-flex relative items-center cursor-pointer align-middle ml-5"
                  >
                    <input
                      type="checkbox"
                      checked={
                        dataToUpdate.status == 1 || dataToUpdate.status === 1
                          ? true
                          : false
                      }
                      name="status"
                      onChange={() => {
                        setDataToUpdate((preVal) => {
                          return {
                            ...preVal,
                            status: dataToUpdate.status == 1 ? "0" : "1",
                          };
                        });
                      }}
                      id="default-toggle"
                      className="sr-only peer"
                    />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </label>

                {/* Club Description */}

                <div className="mb-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Description<span className="text-red-600">&#42;</span>
                  </label>
                  <textarea
                    type={"text"}
                    value={dataToUpdate?.description}
                    name={"description"}
                    onChange={handleChange}
                    autoComplete="cc-given-name"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                  />

                  {error?.description && (
                    <p className="text-sm text-red-500">
                      Please fill the field
                    </p>
                  )}
                </div>

                <div className="mb-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Select Users <span className="text-red-500">*</span>
                  </label>

                  <Select
                    name="userIds"
                    id="userIds"
                    isMulti
                    value={selectedUserIds}
                    onChange={handelChangeselect}
                    options={options}
                    className=""
                    placeholder="Select users..."
                    isSearchable
                    menuPlacement="top"
                  ></Select>
                  {selectedUserIds.length === 0 && (
                    <p className="text-sm text-red-500">Please select user</p>
                  )}
                </div>

                <button
                  className="mt-2 px-4 py-2 bg-indigo-600 text-white outline-none rounded shadow hover:bg-indigo-700"
                  onClick={updateData}
                >
                  {" "}
                  Update
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default ClubEdit;
