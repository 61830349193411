import React, { useContext, useState } from "react";
import styled from "styled-components";
import { PostContext } from "../../components/website/header/MainHeader";
import ConvertYourNFTModal from "../../components/website/modals/ConvertYourNFT";
import MomentsImageModal from "../../components/website/modals/MomentsImageModal";
import { GRADUATED } from "../../utils/constant";
import NFTBalance from "../../images/website/icon_NFT_blue.png";

import { space } from "./Variables";
import { device } from "./Devices";
import CommonModal from "./CommonModalWithChild.jsx";
import HeadingFour from "./HeadingFour";
import Paragraph from "./Paragraph";
import rocket from "../../images/website/rocket.png";

const MomentsImageNFTRow = styled.div`
  position: relative;
  margin-bottom: ${space.space16};
  .moment_image {
    width: 100%;
    height: auto;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: ${space.space16};
    }
  }
  .nft_image {
    position: absolute;
    bottom: 4px;
    right: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      width: 24px;
      height: 24px;
    }
  }
  @media ${device.mobileM} {
    /* margin-bottom: ${space.space16}; */
    .nft_image {
      bottom: 8px;
      right: 8px;
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
`;

function MomentsImageNFT(props) {
  const [openImageModal2, setOpenImageModal2] = useState(false);
  const [openCommonModal, setOpenCommonModal] = useState(false);

  const { userInfo } = useContext(PostContext);

  const handelChooseFile2 = (e) => {
    setOpenImageModal2(true);
  };

  let value = {
    postName: props.MomentsImageNFT,
    postType: 1,
  };

  return (
    <>
      <MomentsImageNFTRow className={props.cname}>
        <div
          className='moment_image cursor-pointer '
          onClick={handelChooseFile2}>
          <img src={props.MomentsImageNFT} alt='no post' />
        </div>
        <div className='nft_image ' onClick={() => setOpenCommonModal(true)}>
          {userInfo?.standard?.toLowerCase() !== GRADUATED &&
            userInfo?.is_plan_expired !== "1" && (
              <img src={NFTBalance} alt='icon' />
            )}
        </div>
      </MomentsImageNFTRow>

      <MomentsImageModal
        openImageModal2={openImageModal2}
        setOpenImageModal2={setOpenImageModal2}
        value={value}
        type={false}
        // handelCovertPostToNFT={handelCovertPostToNFT}
      />
      <CommonModal
        setOpenCommonModal={setOpenCommonModal}
        openCommonModal={openCommonModal}
        header={"NFT"}
        contentClass={"text-center py-8"}>
        <Paragraph
          pData={"Get ready! We're launching this feature in the near future."}
        />
        <Paragraph pData={"Keep an eye for updates."} />
        <div className='flex justify-center mt-10'>
          <img src={rocket} alt='' />
        </div>
      </CommonModal>

      {/* <ConvertYourNFTModal
        openImageModal={openImageModal}
        setOpenImageModal={setOpenImageModal}
        // handelCovertPostToNFT={handelCovertPostToNFT}
      /> */}
    </>
  );
}
export default MomentsImageNFT;
