import Card from "./Cards.jsx";
import List from "./List.jsx";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { loaderContext } from "../global loader/global-loader-provider.js";
import {
  addStudentToClub,
  addStudentToTeam,
} from "../../services/auth.services.js";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import { school } from "../../linksprovider.js";

const Incorporate = ({ itemsNormal, setSearch, search }) => {
  const [items, setItems] = useState({});
  const [saveStorage, setSaveStorage] = useState({});
  let { id } = useParams();
  let navigate = useNavigate();

  const { loaderDispatch } = useContext(loaderContext);

  useEffect(() => {
    setItems(itemsNormal);
    setSaveStorage(itemsNormal);
  }, [itemsNormal]);

  const removeFromList = (list, index) => {
    const result = Array.from(list);
    const [removed] = result?.splice(index, 1);
    return [removed, result];
  };

  const addToList = (list, index, element) => {
    const result = Array.from(list);
    result?.splice(index, 0, element);
    return result;
  };

  // Removes a droppable and adds it to the storage.
  const onDragEnd = (result) => {
    if (!result?.destination) {
      return;
    }
    const listCopy = { ...items };
    const sourceList = listCopy[result.source.droppableId];
    const [removedElement, newSourceList] = removeFromList(
      sourceList,
      result?.source?.index
    );
    listCopy[result.source.droppableId] = newSourceList;

    const destinationList = listCopy[result.destination.droppableId];
    listCopy[result.destination.droppableId] = addToList(
      destinationList,
      result?.destination?.index,
      removedElement
    );
    setItems(listCopy);
    setSaveStorage(listCopy);
  };

  // Searches for items in the target. If the target is not found a search is performed.
  const searchFunction = (e) => {
    let { name, value } = e.target;

    setSearch((preVal) => {
      return { ...preVal, [name]: value };
    });

    value = value?.toLowerCase();
    let newString = value?.trim();
    let searchedStudentName = saveStorage?.[name]?.filter((val) => {
      if (val?.student_name?.toLowerCase().includes(newString)) {
        return val;
      } else if (val?.email?.toLowerCase().includes(newString)) {
        return val;
      }
    });
    // if (value !== "") {
    setItems((preVal) => {
      return { ...preVal, [name]: searchedStudentName };
    });
    // }
  };

  // Adds a student to a club.
  const submit = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });

      let studentListingInclubs = [];
      let studentListingNotInClubs = [];

      for (let i = 0; i < items?.studentListingInsport?.length; i++) {
        studentListingInclubs.push(items?.studentListingInsport[i].id);
      }

      for (let i = 0; i < items?.studentListingNotInSport?.length; i++) {
        studentListingNotInClubs.push(items?.studentListingNotInSport[i].id);
      }

      let body = {
        studentListingInsport: studentListingInclubs,
        studentListingNotInSport: studentListingNotInClubs,
        sport_id: id,
      };

      let result = await addStudentToTeam(body);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
        });
        navigate(school.sports);
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className='bg-white rounded-md shadow-md mb-4'>
        <DragDropContext onDragEnd={onDragEnd}>
          <div className='flex p-12'>
            <List
              title=' Team members'
              onDragEnd={onDragEnd}
              // setSearch={setSearch}
              search={search}
              searchFunction={searchFunction}
              type={true}
              name='studentListingInsport'>
              {Object.keys(items).length > 0 &&
                items?.studentListingInsport?.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id + ""}
                    index={index}>
                    {(provided, snapshot) => (
                      <div>
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}>
                          <Card data={item} />
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
            </List>
            <List
              title=' Drag student to add in club'
              onDragEnd={onDragEnd}
              // setSearch={setSearch}
              searchFunction={searchFunction}
              search={search}
              type={false}
              name='studentListingNotInSport'>
              {Object.keys(items).length > 0 &&
                items?.studentListingNotInSport?.map((item, index) => (
                  <Draggable
                    draggableId={item.id + ""}
                    index={index}
                    key={item.id}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        <Card data={item} />
                      </div>
                    )}
                  </Draggable>
                ))}
            </List>
          </div>
        </DragDropContext>
      </div>
      <div className='flex mb-5  w-[81%] h-fit justify-end'>
        <div className=' mb-5 mx-3 '>
          <button
            className='bg-indigo-600 hover:bg-indigo-700 border-indigo-500 focus:bg-indigo-700 px-8 py-2 rounded-md shadow-md font-bold text-white flex justify-between'
            onClick={submit}>
            Submit
          </button>
        </div>
        <div className=' mb-5 '>
          <button
            className='bg-white hover:bg-white border-white focus:bg-white px-8 py-2 rounded-md shadow-md font-bold text-gray-600 flex justify-between'
            onClick={() => navigate(-1)}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

export default Incorporate;
