import React, { useState, useEffect, useContext } from "react";
import ListLayout from "../shared/listLayout.jsx";
import {
  ADMIN_USER_DATA,
  DELETE,
  UPDATE,
  VIEW,
  STUDENT,
  NFT,
  DOCS,
} from "../../utils/constant";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { loaderContext } from "../global loader/global-loader-provider.js";
import {
  adminStudentListing,
  getStudentDetails,
  updateStudent,
  studentDelete,
  getVerificationDocs,
  docsAcceptOrReject,
} from "../../services/auth.services";
import AdminView from "../../common/AdminView.jsx";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import AdminDeleteModel from "../../common/AdminDeleteModel.jsx";
import { AdminContext } from "../../common/DashboardWrapper.jsx";
import { AppContext } from "../../App.js";
import CommonModal from "../../common/commonModal.jsx";

function AdminStudentManagement() {
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }

  const { loaderDispatch } = useContext(loaderContext);

  const [data, setData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [viewData, setViewData] = useState({});
  const [sortby, setSortby] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentItems, setCurrentItems] = useState(null);
  const [dataCount, setDataCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedData, setDeletedData] = useState(0);
  const [documents, setDocuments] = useState({
    id: "",
    source: "",
    userId: "",
    is_verified: "",
  });

  const [orderby, setOrderby] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const { setAdminActiveTab } = useContext(AdminContext);

  let navigate = useNavigate();

  const tableHeader = [
    {
      key: "S.No.",
      value: "key",
    },
    {
      key: "Student Name",
      value: "student_name",
      sorting: true,
      clickable: false,
    },
    { key: "Email address", value: "email", sorting: true, clickable: false },
    {
      key: "School Name",
      value: "school_name",
      sorting: true,
      clickable: false,
    },
    { key: "status", value: "status", sorting: true, clickable: false },
    {
      key: "document verification status",
      value: "is_verified",
      sorting: true,
      clickable: false,
    },

    {
      key: "Total NFT posts",
      value: "total_nft",
      sorting: false,
      clickable: false,
    },
    {
      key: "plan details",
      value: "Subscription_title",
      // subValue: "title",
      sorting: false,
      clickable: false,
    },
    { key: "Join On", value: "createdAt", sorting: false, clickable: false },
    {
      key: "options",
      value: "options",
      sorting: false,
      clickable: false,
    },
  ];
  const view = [
    {
      label: "Student Name",
      type: "text",
      value: viewData.student_name ? viewData.student_name : "--",
      name: "Student_name",
    },
    {
      label: "School Name",
      type: "text",

      value: viewData?.User?.school_name ? viewData?.User?.school_name : "--",
      name: "school_name",
    },
    {
      label: "Grade Level",
      type: "text",

      value: viewData?.standard ? viewData?.standard : "--",
      name: "standard",
    },
    {
      label: "Email Address",
      type: "email",
      value: viewData?.email ? viewData?.email : "--",
      name: "email",
    },
    {
      label: "Date of Birth",
      type: "text",
      value: viewData?.dob ? viewData?.dob : "--",
      name: "dob",
    },
    {
      label: "Join On",
      type: "text",
      value: viewData.createdAt ? viewData.createdAt : "--",
      name: "createdAt",
    },
    {
      label: "Academic Year",
      type: "text",
      value: viewData?.academic_year ? viewData?.academic_year : "--",
      name: "academic_year",
    },
    {
      label: "Meta Mask Wallet Address",
      type: "text",
      value: viewData?.metamask_id ? viewData?.metamask_id : "--",
      name: "metamask_id",
    },

    {
      label: " Street address",
      type: "text",
      value: viewData.address ? viewData.address : "--",
      name: "address",
    },
    {
      label: "Country",
      type: "text",
      value: viewData.country ? viewData.country : "--",
      name: "country",
    },
    {
      label: "State/Province",
      type: "text",
      value: viewData.state ? viewData.state : "--",
      name: "state",
    },
    {
      label: "City",
      type: "text",
      value: viewData.city ? viewData.city : "--",
      name: "city",
    },

    {
      label: "ZIP code/ Postal code",
      type: "number",
      value: viewData.pincode ? viewData.pincode : "--",
      name: "pincode",
    },
  ];

  // const edit = [
  //   {
  //     label: "Student Name",
  //     type: "text",
  //     value: editData.student_name,
  //     name: "Student_name",
  //   },
  //   {
  //     label: "School Name",
  //     type: "text",
  //     value: editData?.User?.school_name,
  //     name: "school_name",
  //   },
  //   {
  //     label: "Student Email",
  //     type: "email",
  //     value: editData?.email,
  //     name: "email",
  //   },
  //   {
  //     label: "Country",
  //     type: "text",
  //     value: editData.country,
  //     name: "country",
  //   },
  //   {
  //     label: "Join On",
  //     type: "text",
  //     value: editData.createdAt,
  //     name: "createdAt",
  //   },
  //   {
  //     label: "Pin code",
  //     type: "number",
  //     value: editData.pincode,
  //     name: "pincode",
  //   },
  //   {
  //     label: "State",
  //     type: "text",
  //     value: editData.state,
  //     name: "state",
  //   },
  // ];

  const itemsOnPage = [
    { option: 10 },
    { option: 20 },
    { option: 50 },
    { option: 70 },
    { option: 100 },
  ];

  //to set header
  useEffect(() => {
    setAdminActiveTab(STUDENT);
    setItemOffset(1);
  }, []);

  useEffect(() => {
    const debunce = setTimeout(() => {
      handleStudentData();
    }, 300);

    return () => {
      clearTimeout(debunce);
    };
  }, [searchItem, sortby, orderby, itemOffset, itemsPerPage]);

  useEffect(() => {
    setCurrentItems(data);
    setPageCount(dataCount);
  }, [data, dataCount, itemsPerPage]);

  //function to call API Data
  const handleOptions = (optionValue, item) => {
    switch (optionValue) {
      case VIEW:
        loaderDispatch({
          isLoading: true,
        });
        setOpenViewModal(true);
        // navigate(`/admin/cms/view/${item.id}`);
        studentView(item.id);

        break;
      case UPDATE:
        navigate(`/admin/student/${item.id}`);
        break;
      case DELETE:
        setOpenDeleteModal(true);
        setDeletedData(item.id);
        break;
      case NFT:
        navigate(`/admin/student/nft/${item.id}`);
        break;

      case DOCS:
        getDocs(item?.id);
        break;
      default:
        break;
    }
  };

  const handleSorting = (data, order) => {
    setSortby(data);
    setOrderby(order);
  };

  // Handles a click on a page.
  const handlePageClick = (event) => {
    let newOffset = event.selected;
    // dataCall(body);
    setItemOffset(newOffset + 1);
  };

  const handleStudentData = async () => {
    loaderDispatch({
      isLoading: true,
    });
    try {
      if (searchItem.toLowerCase() === "accepted") {
        setSearchItem("1");
      }
      if (searchItem.toLowerCase() === "rejected") {
        setSearchItem("2");
      }
      if (searchItem.toLowerCase() === "pending") {
        setSearchItem("3");
      }
      let order = orderby ? "desc" : "asc";

      if (searchItem.toLowerCase() === "active") {
        setSearchItem("1");
      } else if (searchItem.toLowerCase() === "inactive") {
        setSearchItem("2");
      }
      let result = await adminStudentListing(
        userData.id,
        `search=${searchItem.trim()}&sort_by=${sortby}&order=${order}&page=${itemOffset}&limit=${itemsPerPage}`
      );
      loaderDispatch({
        isLoading: false,
      });
      setData([]);
      setDataCount(0);

      if (
        result?.data?.status === StatusCodes.OK &&
        result?.data?.data?.total > 0
      ) {
        const studentData = result?.data?.data?.studentListing;
        const studentDataCount = result?.data?.data?.totalPage;

        setData(studentData);
        setDataCount(studentDataCount);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const studentView = async (id) => {
    let result = await getStudentDetails(id);

    if (result?.data?.status === StatusCodes.OK) {
      loaderDispatch({
        isLoading: false,
      });
      let item = result?.data?.data?.student_details;
      setViewData(item);
    }
  };

  const deleteData = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let result = await studentDelete(deletedData);
      loaderDispatch({
        isLoading: false,
      });

      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
        });
        setOpenDeleteModal(false);
        handleStudentData();
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
      setOpenDeleteModal(false);
    }
  };

  const getDocs = async (id) => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let params = {
        id: id,
        user_type: 1,
      };

      let result = await getVerificationDocs(params);
      loaderDispatch({
        isLoading: false,
      });
      setDocuments({
        id: "",
        source: "",
        userId: "",
        is_verified: "",
      });

      if (result?.data?.status === StatusCodes.OK) {
        let data = result?.data?.data;

        console.log("source", data);
        setDocuments({
          id: data?.id,
          source: data?.docName,
          userId: data?.user_id,
          is_verified: data?.is_verified,
        });
        if (data?.id) {
          setOpenModal(true);
        }
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
      setOpenDeleteModal(false);
    }
  };

  const handelDocsStatus = async (status) => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let body = {
        document_id: documents?.id,
        is_verified: status,
      };

      let result = await docsAcceptOrReject(documents?.userId, body);
      loaderDispatch({
        isLoading: false,
      });

      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
        });

        setOpenModal(false);
        handleStudentData();
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="">
      <div className="p-2">
        <input
          type={"text"}
          placeholder={"Search"}
          onChange={(e) => {
            setSearchItem(e.target.value.trim());
            setItemOffset(1);
          }}
          autoComplete="cc-given-name"
          className="mt-1 block w-1/2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
        />
      </div>

      <AdminDeleteModel
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        deleteData={deleteData}
        header={"Delete Student"}
      />

      <AdminView
        viewData={viewData}
        openViewModal={openViewModal}
        setOpenViewModal={setOpenViewModal}
        view={view}
      />
      <ListLayout
        handleOptions={handleOptions}
        tableHeader={tableHeader}
        handleSorting={handleSorting}
        currentItems={currentItems}
        documents={true}
      />

      <CommonModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        header={"Verification ID"}
        divClass={
          "inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-1 sm:align-middle sm:p-6"
        }
      >
        <div className="">
          <div>
            {documents?.is_verified === 2 && (
              <div className="my-2 flex justify-center bg-red-600 px-4 py-1 rounded shadow ">
                <p className="text-white font-bold">Current status: Rejected</p>
              </div>
            )}
            {documents?.is_verified === 1 && (
              <div className="my-2 flex justify-center bg-green-600 px-4 py-1 rounded shadow ">
                <p className="text-white font-bold">Current status: Accepted</p>
              </div>
            )}
            {documents?.is_verified === 3 && (
              <div className="my-2 flex justify-center bg-yellow-400 px-4 py-1 rounded shadow ">
                <p className="text-white font-bold">Current status: Pending</p>
              </div>
            )}
          </div>
          <div>
            {documents?.source?.includes("pdf") ? (
              <iframe
                title="verification_pdf"
                src={documents?.source}
                itemType="application/pdf"
                height={"400"}
                width={"500"}
              />
            ) : (
              <img
                src={documents?.source}
                alt=""
                className="h-96 max-w-lg object-cover"
              />
            )}
          </div>

          <div
            className={
              documents?.is_verified === 3
                ? "mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
                : "mt-5 sm:mt-6"
            }
          >
            {(documents?.is_verified === 1 || documents?.is_verified === 3) && (
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:col-start-2 sm:text-sm"
                onClick={() => handelDocsStatus(2)}
              >
                Reject
              </button>
            )}
            {(documents?.is_verified === 2 || documents?.is_verified === 3) && (
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none sm:mt-0 sm:col-start-1 sm:text-sm"
                onClick={() => handelDocsStatus(1)}
              >
                Accept
              </button>
            )}
          </div>
        </div>
      </CommonModal>
      <div className="flex justify-between ">
        {data?.length > 0 && (
          <div
            className="block lg:inline-block lg:mt-0 "
            style={{ marginTop: "6px" }}
          >
            <label htmlFor="item" className="ml-5">
              Record Per Page:
            </label>
            <select
              className="ml-3 bg-white border p-2 rounded"
              name="item"
              id="item"
              onChange={(e) => {
                setItemsPerPage(parseInt(e.target.value));
              }}
            >
              {itemsOnPage.map((val, i) => {
                return (
                  <option value={val.option} key={i}>
                    {val.option}
                  </option>
                );
              })}
            </select>
          </div>
        )}
        <div className="mt-2 mb-10">
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next >"
            onPageChange={handlePageClick}
            pageCount={pageCount}
            previousLabel="< Previous"
            renderOnZeroPageCount={null}
            containerClassName={"pagination"}
            pageClassName={"pageClass"}
            forcePage={itemOffset - 1}
          />
        </div>

        {data?.length === 0 && (
          <div className="flex absolute left-1/2 top-1/2 mx-auto">
            <p>Data not found..!!</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminStudentManagement;
