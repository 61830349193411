import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { admin } from "../../linksprovider";
import logo from "../../images/logo.svg";
import { forgetPassword } from "../../services/auth.services";
import { StatusCodes } from "http-status-codes";
import { AppContext } from "../../App";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { loaderContext } from "../global loader/global-loader-provider";
import { toast } from "react-toastify";

function AdminForgetPassword() {
  const { loaderState, loaderDispatch } = useContext(loaderContext);
  let navigate = useNavigate();

  const [userEmail, setUserEmail] = useState({
    email: "",
  });

  const inputFields = [
    {
      name: "email",
      id: "email",
      label: "Email",
      type: "text",
    },
  ];

  const validationSchema = Yup.object({
    email: Yup.string()
      .trim()
      .email("Invalid email address")
      .required("Please fill the field"),
  });

  const submit = async (values, { setSubmitting }) => {
    let body = {
      email: values.email.trim(),
    };
    loaderDispatch({
      isLoading: true,
    });
    let result = await forgetPassword(body);
    loaderDispatch({
      isLoading: false,
    });

    if (result?.data?.status === StatusCodes.OK) {
      toast(result?.data?.message, {
        type: "success",
      });
      navigate(admin.login);
    } else {
      toast(result?.data?.message, {
        type: "error",
      });
    }
    setSubmitting(false);
  };
  return (
    <div className='min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
      <div className='sm:mx-auto sm:w-full sm:max-w-md'>
        <img className='mx-auto h-12 w-auto' src={logo} alt='Workflow' />
        <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
          Forgot Password
        </h2>
      </div>

      <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
        <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
          <div className='space-y-6'>
            <Formik
              initialValues={userEmail}
              validationSchema={validationSchema}
              onSubmit={submit}>
              {({ isSubmitting }) => (
                <Form>
                  {inputFields.map((val, i) => {
                    return (
                      <div key={i}>
                        <label
                          htmlFor={val.id}
                          className='block text-sm font-medium text-gray-700'>
                          {val.label}
                        </label>
                        <div className='mt-1'>
                          <Field
                            id={val.id}
                            name={val.name}
                            type={val.type}
                            autoComplete='off'
                            className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                          />
                        </div>
                        <div className='text-sm text-red-500'>
                          <ErrorMessage name={val.name} as='div' />
                        </div>
                      </div>
                    );
                  })}
                  <div className='mt-3'>
                    <button
                      type='submit'
                      disabled={isSubmitting}
                      className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                      Send Link
                    </button>
                  </div>
                  <div className='mt-3'>
                    <Link
                      to={admin.login}
                      className='w-full flex justify-center py-2 px-4 shadow border border-transparent rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white'>
                      Cancel
                    </Link>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminForgetPassword;
