import React, { useContext, useState } from "react";
import styled from "styled-components";

import { colors, space } from "./Variables";
import { schoolPostLike } from "../../services/auth.services";
import { StatusCodes } from "http-status-codes";
import { useEffect } from "react";
import { PostContext } from "../../components/website/header/MainHeader";
import {
  CLUB_POST,
  MY_MOMENT_POST,
  SCHOOL_MOMENTS,
  TEAM_POST,
} from "../../utils/constant";
import { toast } from "react-toastify";

const LikeDislikeRow = styled.div`
  display: flex;
  align-items: center;
  grid-gap: ${space.space8};
  cursor: pointer;
  color: white;
  span {
    font-size: 0.75rem;
    line-height: 0.938rem;
    vertical-align: middle;
  }
  &.like {
    path {
      fill: ${colors.primary};
    }
    rect {
      fill: ${colors.primary};
    }
  }
`;

function LikeDislike({ val }) {
  const [like, setLike] = useState(false);

  const {
    getAllPostDetails,
    userInfo,
    clubData,
    postlocation,
    teamData,
    setPostData,
    setMyMomentPostData,
    handelLogout,
  } = useContext(PostContext);

  // Sets the user likes to true if he liked.
  useEffect(() => {
    setLike(val?.isLikedByMe);
  }, [val]);

  const likeAndDislike = async () => {
    try {
      let body = {
        post_id: val?.id,
        user_id: val?.user_id,
        school_id: userInfo?.User?.id,
        action_user_id: userInfo?.id,
        action_user_name: userInfo?.student_name,
        user_name: val?.User?.student_name,
        status: like ? 2 : 1,
      };
      let result = await schoolPostLike(body);
      if (result?.data?.status === 410) {
        handelLogout();
      }

      if (result?.data?.status === StatusCodes.OK) {
        let data = result?.data?.data;
        setLike(!like);

        if (postlocation === MY_MOMENT_POST) {
          setMyMomentPostData((preVal) => {
            for (let i = 0; i < preVal.length; i++) {
              if (preVal[i]?.Post?.id === data?.id) {
                preVal[i].Post = data;
              }
            }
            return [...preVal];
          });
        } else {
          setPostData((preVal) => {
            for (let i = 0; i < preVal.length; i++) {
              if (preVal[i]?.id === data?.id) {
                preVal[i] = data;
              }
            }
            return [...preVal];
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <LikeDislikeRow
        className={like ? "like_btn like" : "like_btn"}
        onClick={likeAndDislike}>
        <svg
          width='18'
          height='18'
          viewBox='0 0 18 18'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'>
          <g clipPath='url(#clip0_1471_53377)'>
            <path
              d='M1.93502 6.1181C0.866801 6.11952 0.00141721 6.98508 0 8.05313V15.7929C0 16.8616 0.86627 17.7279 1.93502 17.7279C3.0036 17.7279 3.86987 16.8616 3.86987 15.7929V8.05313C3.86863 6.98508 3.00307 6.11952 1.93502 6.1181Z'
              fill='white'
            />
            <path
              d='M17.4384 6.98543C16.9795 6.4352 16.3 6.11739 15.5836 6.1181H12.7359C13.1733 4.32674 12.957 2.52494 12.1408 1.39223C11.6979 0.72809 10.9566 0.324717 10.1585 0.313202C9.02951 0.313202 9.02951 1.3591 9.02951 2.12245C9.02951 3.62753 8.70727 6.1181 5.20393 7.09969C4.98993 7.15585 4.83971 7.34806 4.8374 7.56932V15.3121C4.839 16.6457 5.91962 17.7263 7.25321 17.7279H14.3515C15.5189 17.7279 16.5191 16.893 16.7278 15.7445L17.9608 8.96563C18.0894 8.26074 17.8981 7.53513 17.4384 6.98543Z'
              fill='white'
            />
          </g>
          <defs>
            <clipPath id='clip0_1471_53377'>
              <rect width='18' height='18' fill='white' />
            </clipPath>
          </defs>
        </svg>
        <span>Like</span>
      </LikeDislikeRow>
    </>
  );
}
export default LikeDislike;
