import React from "react";
import styled from "styled-components";
import { device } from "./Devices";
import { colors, space } from "./Variables.js";

const Ptag = styled.p`
  font-family: "Inter", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.313rem;
  position: relative;

  color: ${colors.white};

  // Spacing
  margin-bottom: ${space.space8};

  @media ${device.laptop} {
    font-size: 1rem;
    line-height: 1.625rem;
  }

  &.noMargin {
    margin: 0;
  }

  &.smallParagaraph {
    font-size: 0.875rem;
    line-height: 1.313rem;
  }
`;

function Paragraph(props) {
  return (
    <>
      <Ptag className={props.cname}>{props.pData}</Ptag>
    </>
  );
}
export default Paragraph;
