import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import SiteLogo from "../../../common/website/SiteLogo.jsx";
import { device } from "../../../common/website/Devices";
import { colors, space } from "../../../common/website/Variables";
import {
  Main,
  Container,
  SubscriptionCard,
} from "../../../common/website/HOC.jsx";

import HeadingTwo from "../../../common/website/HeadingTwo.jsx";
import HeadingThree from "../../../common/website/HeadingThree.jsx";
import HeadingFour from "../../../common/website/HeadingFour.jsx";
import Paragraph from "../../../common/website/Paragraph.jsx";
import Button from "../../../common/website/Button.jsx";
import List from "../../../common/website/List.jsx";

import LoginBG from "../../../images/website/login_bg.svg";
import { loaderContext } from "../../../components/global loader/global-loader-provider.js";
import { ADMIN_USER_DATA } from "../../../utils/constant.js";
import {
  adminPlans,
  choosePlan,
  getSubscriptionDetail,
} from "../../../services/auth.services.js";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import { admin, school, student, website } from "../../../linksprovider.js";
import { useNavigate } from "react-router-dom";

const SubscribeRow = styled.div`
  height: 100%;
  position: relative;
  &::before {
    content: "";
    position: fixed;
    background: url(${LoginBG}) no-repeat;
    background-position: center;
    background-size: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    z-index: 1;
  }
  h2 {
    margin-bottom: 6px;
  }
  .logo {
    margin-bottom: ${space.space20};
    a {
      display: inline-block;
    }
  }
  .full_screen_container {
    position: relative;
    height: 100%;
    z-index: 2;
    padding: ${space.space24} ${space.space20};
  }
  .subscribe_description {
    margin-bottom: ${space.space24};
  }
  li {
    font-size: 0.875rem;
    line-height: 1.063rem;
    margin-bottom: ${space.space14};
    &:last-child {
      margin: 0;
    }
    &::before {
      top: 4px;
    }
  }
  .description_card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    height: 100%;
    h3 {
      text-align: center;
      margin-bottom: ${space.space8};
    }
    .card_description {
      text-align: center;
      margin-bottom: ${space.space24};
      position: relative;
      &::before {
        content: "";
        position: absolute;
        background: ${colors.white};
        height: 2px;
        width: ${space.space80};
        bottom: -16px;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
    h4 {
      font-weight: 900;
      font-size: 1.5rem;
      line-height: 2rem;
      color: ${colors.primary};
      margin-bottom: ${space.space24};
      text-align: center;
    }
  }
  .bottom {
    margin-top: ${space.space24};
    .btn {
      width: 100%;
    }
  }

  @media ${device.mobileL} {
    .full_screen_container {
      padding: ${space.space30} ${space.space20};
    }
    .subscribe_description {
      margin-bottom: ${space.space30};
    }
    .description_card {
      h4 {
        font-size: 1.875rem;
        margin-bottom: ${space.space40};
      }
      .card_description {
        margin-bottom: ${space.space30};
      }
    }
    .bottom {
      margin-top: ${space.space30};
    }
    li {
      margin-bottom: 18px;
    }
    .logo {
      margin-bottom: ${space.space30};
    }
  }
  @media ${device.tablet} {
    .full_screen_container {
      padding: ${space.space50} ${space.space20};
    }
    .subscribe_description {
      margin-bottom: ${space.space50};
    }
    .description_card {
      .card_description {
        margin-bottom: ${space.space50};
        &::before {
          bottom: -24px;
        }
      }
    }
    .bottom {
      margin-top: ${space.space40};
    }
    .logo {
      margin-bottom: ${space.space40};
    }
  }
  @media ${device.laptopL} {
    .full_screen_container {
      padding: ${space.space50} 0;
    }
  }
`;

function SubscribeOurPlan() {
  const [plans, setPlans] = useState([]);
  const [userInfo, setUserInfo] = useState();

  const { loaderDispatch } = useContext(loaderContext);

  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }
  let navigate = useNavigate();

  useEffect(() => {
    if (userData && userData.user_type === 1) {
      navigate(admin.dashbord);
    } else if (userData && userData.user_type === 2) {
      navigate(school.dashbord);
    } else if (userData && userData.user_type === 3) {
      async function getinfo() {
        let user = {
          email: userData.email,
        };
        let subDetails = await getSubscriptionDetail(user);
        console.log(subDetails);
        if (subDetails?.data?.status === 410) {
          handelLogout();
        }
        let StudentInfo = subDetails?.data?.data;
        if (subDetails?.data?.status === StatusCodes.OK) {
          setUserInfo(StudentInfo);
          if (
            !StudentInfo?.subscription_id ||
            StudentInfo?.is_plan_expired === "1"
          ) {
            navigate(student.subscribeOurPlan);
          } else if (
            StudentInfo?.subscription_id &&
            !StudentInfo?.profile_picture
          ) {
            navigate(student.dashbord);
          } else if (
            StudentInfo?.subscription_id &&
            StudentInfo?.profile_picture &&
            StudentInfo?.is_plan_exist === "1" &&
            StudentInfo?.is_plan_expired === "0"
          ) {
            navigate(student.post);
          }
        }
      }
      getinfo();
    } else {
      navigate(website.studentLogin);
    }
    showAdminPlans();
  }, []);

  const handelLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    // setUserData({});

    navigate(website.studentLogin);
  };

  // Show admin plans.
  const showAdminPlans = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let param = {
        user_type: 3,
      };
      let result = await adminPlans(param);

      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        setPlans(result?.data?.data);
      } else {
        toast(result?.data?.message, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const subscribePlan = async (plan_id) => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let body = {
        subscription_id: plan_id,
      };
      let result = await choosePlan(body, userData.id);
      loaderDispatch({
        isLoading: false,
      });
      console.log(result);
      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
          theme: "dark",
        });
        navigate(student.dashbord);
      } else {
        toast(result?.data?.message, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(" userInfo?.is_plan_exist", userInfo?.is_plan_exist);
  return (
    <>
      <Main>
        <SubscribeRow className='content_full_screen'>
          <Container className='full_screen_container'>
            <SiteLogo cname='logo' userInfo={userInfo} link={"#"} />
            <HeadingTwo cname='white' h2Data={"Subscribe Our Plan"} />
            <Paragraph
              cname='subscribe_description'
              pData={"Select the plan best suits your need"}
            />
            <div className='grid gap-6 sm:gap-6 md:gap-8 lg:gap-10 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 items-center'>
              {plans?.length > 0 ? (
                plans?.map((plansDetails, i) => {
                  if (
                    plansDetails?.plan_type != 3 &&
                    userInfo?.is_plan_exist === "0"
                  ) {
                    return (
                      <SubscriptionCard className='description_card' key={i}>
                        <div className='top'>
                          <HeadingThree
                            h3Data={
                              (plansDetails?.plan_type == 1 && "Free") ||
                              (plansDetails?.plan_type == 2 && "Paid")
                            }
                          />
                          <Paragraph
                            cname='card_description'
                            pData={plansDetails?.description}
                          />
                          <HeadingFour h4Data={plansDetails?.title} />
                          <ul>
                            {plansDetails?.plan_type != 3 && (
                              <List
                                liData={`Valid for ${plansDetails?.duration} months`}
                              />
                            )}
                            <List
                              liData={`Number of NFT ${plansDetails?.no_of_nft}`}
                            />
                            <List liData={`Price ${plansDetails?.price}`} />
                          </ul>
                        </div>
                        <div className='bottom'>
                          <Button
                            cname='gradient btn'
                            buttonData={"Choose Plan"}
                            onClick={() =>
                              plansDetails?.plan_type == 1
                                ? subscribePlan(plansDetails?.plan_type)
                                : navigate(
                                    `/checkout/${plansDetails?.price}/${plansDetails?.plan_type}/${plansDetails?.id}`
                                  )
                            }
                          />
                        </div>
                      </SubscriptionCard>
                    );
                  } else if (
                    plansDetails?.plan_type == 2 &&
                    userInfo?.is_plan_exist === "1"
                  ) {
                    return (
                      <SubscriptionCard className='description_card' key={i}>
                        <div className='top'>
                          <HeadingThree
                            h3Data={plansDetails?.plan_type == 2 && "Paid"}
                          />
                          <Paragraph
                            cname='card_description'
                            pData={plansDetails?.description}
                          />
                          <HeadingFour h4Data={plansDetails?.title} />
                          <ul>
                            {plansDetails?.plan_type != 3 && (
                              <List
                                liData={`Valid for ${plansDetails?.duration} months`}
                              />
                            )}
                            <List
                              liData={`Number of NFT ${plansDetails?.no_of_nft}`}
                            />
                            <List liData={`Price ${plansDetails?.price}`} />
                          </ul>
                        </div>
                        <div className='bottom'>
                          <Button
                            cname='gradient btn'
                            buttonData={"Choose Plan"}
                            onClick={() =>
                              // subscribePlan(plansDetails?.plan_type)
                              navigate(
                                `/checkout/${plansDetails?.price}/${plansDetails?.plan_type}/${plansDetails?.id}`
                              )
                            }
                          />
                        </div>
                      </SubscriptionCard>
                    );
                  }
                })
              ) : (
                <p>Data not found..!!..!!</p>
              )}
            </div>
          </Container>
        </SubscribeRow>
      </Main>
    </>
  );
}

export default SubscribeOurPlan;
