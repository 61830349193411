import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { SchoolContext } from "../../common/SchoolDashboardWrapper";
import {
  ADMIN_USER_DATA,
  DELETE,
  EDIT,
  JOB,
  UPDATE,
  USERS,
  VIEW,
} from "../../utils/constant";
import SearchFilter from "../../components/search_filter/SearchFilter";
import ListLayout from "../../components/shared/listLayout.jsx";
import SelectFilter from "../../components/school_select_filter/SelectFilter";
import ReactPaginate from "react-paginate";
import { loaderContext } from "../../components/global loader/global-loader-provider";

import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import AdminDeleteModel from "../../common/AdminDeleteModel";
import CreateJob from "../../components/schools/jobs/CreateJob";
import * as Yup from "yup";
import {
  JobListing,
  createJob,
  deleteWebJob,
  editJob,
} from "../../services/auth.services";
import ViewJob from "../../components/schools/jobs/ViewJob";
import { useNavigate } from "react-router-dom";
import { school } from "../../linksprovider";
import moment from "moment";

function SchoolJobManagement() {
  const [searchItem, setSearchItem] = useState("");
  const [itemOffset, setItemOffset] = useState(1);
  const [sortby, setSortby] = useState("");
  const [orderby, setOrderby] = useState(true);
  const [currentItems, setCurrentItems] = useState(null);
  const [data, setData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [dataCount, setDataCount] = useState(0);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [itemsId, setItemsId] = useState("");
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [submit, setSubmit] = useState(false);
  const [value, onChange] = useState(new Date());
  const [date, setDate] = useState(new Date());

  const { setAdminActiveTab } = useContext(SchoolContext);
  const { loaderDispatch } = useContext(loaderContext);
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  let navigate = useNavigate();

  const initialValue = {
    title: itemsId?.title ? itemsId?.title : "",
    description: itemsId?.description ? itemsId?.description : "",
    url: itemsId?.url ? itemsId?.url : "",
  };
  const tableHeader = [
    {
      key: "S.No.",
      value: "key",
      clickable: false,
      sorting: false,
    },
    {
      key: "title",
      value: "title",

      clickable: false,
      sorting: true,
    },
    {
      key: "description",
      value: "description",
      clickable: false,
      sorting: true,
    },
    {
      key: "URL",
      value: "url",
      clickable: false,
      sorting: true,
    },
    {
      key: "end date",
      value: "application_end_date",
      clickable: false,
      sorting: true,
    },

    { key: "status", value: "status", clickable: false, sorting: true },

    {
      key: "options",
      value: "options",
    },
  ];

  const itemsOnPage = [
    { label: 10, value: "10" },
    { label: 20, value: "20" },
    { label: 50, value: "50" },
    { label: 70, value: "70" },
    { label: 100, value: "100" },
  ];

  const inputArray = [
    {
      label: "Title",
      type: "text",
      placeholder: "Enter name",
      name: "title",
    },
    {
      label: `Description`,
      type: "text",
      placeholder: `Enter description`,
      name: "description",
      as: "textarea",
    },
    {
      label: `URL`,
      type: "text",
      placeholder: `Enter description`,
      name: "url",
    },
  ];

  useLayoutEffect(() => {
    setAdminActiveTab(JOB);
  }, []);
  useEffect(() => {
    const debounce = setTimeout(() => {
      handelJobList();
    }, 300);
    return () => clearTimeout(debounce);
  }, [searchItem, sortby, orderby, itemOffset, itemsPerPage]);

  useEffect(() => {
    setCurrentItems(data);
    setPageCount(dataCount);
  }, [data, dataCount, itemsPerPage]);

  useEffect(() => {
    setDate(value);
  }, [value]);

  useEffect(() => {
    if (!openCreateModal) {
      setSubmit(false);
      setIsEdit(false);
      setItemsId("");
      setDate(new Date());
    }
  }, [openCreateModal]);

  const handleOptions = (optionValue, item) => {
    console.log("items", item);
    switch (optionValue) {
      case VIEW:
        setOpenViewModal(true);
        setItemsId(item);

        break;
      case UPDATE:
        setIsEdit(true);
        setOpenCreateModal(true);
        setItemsId({
          ...item,
          status: item?.status2,
          application_end_date: new Date(item?.application_end_date),
        });
        console.log(new Date(item?.application_end_date));
        onChange(new Date(item?.application_end_date));
        // setLabelName(item?.labelType);
        // setDate(new Date(item?.application_end_date));
        break;

      case DELETE:
        setItemsId(item?.id);
        setOpenDeleteModal(true);
        break;
      case USERS:
        navigate(`${school.jobUser}/${item?.id}`);
        break;
      default:
        break;
    }
  };

  const handleSorting = (data, order) => {
    setSortby(data);
    setOrderby(order);
  };

  const handlePageClick = (event) => {
    setItemOffset(event.selected + 1);
  };

  const handelJobList = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let order = orderby ? "desc" : "asc";
      let params = {
        id: userData?.id,
        search: searchItem,
        page: itemOffset,
        sort_by: sortby,
        orderBy: order,
        limit: itemsPerPage,
      };

      let result = await JobListing(params);
      loaderDispatch({
        isLoading: false,
      });

      if (result?.data?.status === StatusCodes.OK) {
        const data = result?.data?.data?.jobListing?.rows;
        const count = result?.data?.data?.totalPage;

        for (let index = 0; index < data.length; index++) {
          data[index].application_end_date = moment(
            data[index]?.application_end_date
          ).format("YYYY/MM/DD");
        }

        setData(data);
        setDataCount(count);
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelDelete = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });

      let result = await deleteWebJob(itemsId);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
        });
        setOpenDeleteModal(false);
        handelJobList();
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const validation = Yup.object({
    title: Yup.string()
      .trim()
      .max(30, "Title must be maximum of 30 characters")
      .required("Please fill this field"),
    description: Yup.string().trim().required("Please fill this field"),
    url: Yup.string()
      .matches(
        "((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)",
        "Please enter valid URL"
      )
      .required("Please fill this field"),
  });

  const checkDateField = () => {
    if (!date) {
      setSubmit(true);
      return;
    }
  };

  const handelSubmit = async (values, { setSubmitting }) => {
    setSubmit(false);
    if (!date) {
      setSubmit(true);
      return;
    }

    try {
      loaderDispatch({
        isLoading: true,
      });

      let momentDate = moment(date).format("YYYY-MM-DD");

      let body = {
        title: values?.title,
        description: values?.description,
        status: 1,
        application_end_date: momentDate,
        url: values?.url,
        school_id: userData?.id,
      };

      if (isEdit) {
        body.status = itemsId?.status;
      }
      let result;
      if (isEdit) {
        result = await editJob(itemsId?.id, body);
      } else {
        result = await createJob(body);
      }

      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
        });
        setOpenCreateModal(false);
        handelJobList();
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
    setSubmitting(false);
  };
  return (
    <>
      <div className='p-2 grid grid-cols-2 gap-2 mt-1'>
        <div className=' flex justify-center'>
          <SearchFilter
            setSearchItem={setSearchItem}
            searchItem={searchItem}
            setPage={setItemOffset}
            cname={
              " block w-full border border-gray-300 rounded-md shadow py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
            }
          />
        </div>
        <div className='flex justify-end '>
          <button
            className='px-4 py-2 bg-indigo-500 text-white hover:bg-indigo-600 rounded shadow-md'
            onClick={() => {
              setOpenCreateModal(true);
            }}>
            Create
          </button>
        </div>
      </div>
      <ListLayout
        handleOptions={handleOptions}
        tableHeader={tableHeader}
        handleSorting={handleSorting}
        currentItems={currentItems}
        isUser={true}
      />
      <div className='flex justify-between'>
        {data?.length > 0 && (
          <>
            <div
              className='block lg:inline-block lg:mt-0'
              style={{ marginTop: "6px" }}>
              <label htmlFor='item' className='ml-5'>
                Record Per Page:
              </label>
              <SelectFilter
                options={itemsOnPage}
                selectCls={"ml-3 bg-white border p-2 rounded"}
                name={"item"}
                id={"item"}
                onChange={(e) => {
                  setItemsPerPage(parseInt(e.target.value));
                }}
              />
            </div>

            <div className='mt-2 mb-10'>
              <ReactPaginate
                breakLabel='...'
                nextLabel='Next >'
                onPageChange={handlePageClick}
                pageCount={pageCount}
                previousLabel='< Previous'
                renderOnZeroPageCount={null}
                containerClassName={"pagination"}
                pageClassName={"pageClass"}
                forcePage={itemOffset - 1}
              />
            </div>
          </>
        )}
      </div>
      {data?.length === 0 && (
        <div className='flex flex-row absolute left-1/2 top-1/2'>
          <p>Data not found..!!</p>
        </div>
      )}

      {/* create label modal starts */}
      <CreateJob
        openCreateModal={openCreateModal}
        setOpenCreateModal={setOpenCreateModal}
        initialValue={initialValue}
        validation={validation}
        inputArray={inputArray}
        submit={submit}
        onChange={onChange}
        handelSubmit={handelSubmit}
        checkDateField={checkDateField}
        isEdit={isEdit}
        date={date}
        itemsId={itemsId}
        setItemsId={setItemsId}
      />
      {/* create label modal ends */}

      <ViewJob
        openViewModal={openViewModal}
        setOpenViewModal={setOpenViewModal}
        itemsId={itemsId}
      />

      <AdminDeleteModel
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        header={"Delete Job"}
        deleteData={handelDelete}
      />
    </>
  );
}

export default SchoolJobManagement;
