import React from "react";

function NFTImageCard({ nftimage, onClick }) {
  return (
    <div>
      <img
        src={nftimage}
        alt=''
        srcset=''
        height={125}
        width={125}
        className='rounded-md shadow-md shadow-slate-400 cursor-pointer'
        onClick={onClick}
      />
    </div>
  );
}

export default NFTImageCard;
