import React, { createContext, useState } from "react";
import { admin, school, student, website } from "./linksprovider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Pages/login/Login";
import SecuredRoute from "./common/SecuredRoute";
import DashbordWrapper from "./common/DashboardWrapper.jsx";
import AdminDashboard from "./Pages/admindashboard/AdminDashboard.jsx";
import AdminStudentManagement from "./components/admin student managment/AdminStudentManagment";
import AdminProfileEdit from "./components/admin profile edit/AdminProfileEdit";
import AdminProfile from "./components/admin profile/AdminProfile";
import AdminForgetPassword from "./components/admin forget password/AdminForgetPasswordEmail";
import AdminHeader from "./components/Admin header/Adminheader.jsx";
import AdminSetting from "./Pages/setting/Setting.jsx";

import PasswordPage from "./components/admin forget password/AdminForgetPassword.jsx";
import SchoolRegistration from "./Pages/School registration/SchoolRegistration.jsx";
import AdminPlan from "./components/admin plans/adminPlan";
import Editor from "./Pages/email listing/EmailTemplateListing.jsx";
import SchoolProfile from "./components/schools/view profile/schoolViewProfile";
import SchoolDashboardWrapper from "./common/SchoolDashboardWrapper";
import SchoolSecuredRoute from "./common/SchoolSecuredRoute";
import SchoolStudent from "./components/schools/school students/schoolStudents";
import SchoolDashboard from "./components/schools/school-dashboard/schoolDashboard";
import Schoolyearbook from "./Pages/SchoolYearbook/Schoolyearbook";
import AdminCMS from "./Pages/admin CMS managment/AdminCMS.jsx";
import EditStudent from "./components/schools/edit student/EditLayout.jsx";
import StudentSecuredRoute from "./common/StudentSecuredRoute";
import SchoolHeader from "./components/schools/School header/Schoolheader.jsx";
import StudentDashboardWrapper from "./common/StudentDashboard";

import PostDetails from "./components/schools/post details/postDetails";
import AdminPostDetails from "./components/admin school post/AdminSchoolPost.jsx";
import PostViewDetails from "./components/schools/post details/postViewDetails";
import ViewStudent from "./components/schools/view student/viewStudent";
import AdminCmsEditor from "./components/adminCMS/AdminCmsEditor";
import AdminCmsView from "./components/adminCMS/AdminCmsView.jsx";
import GlobalLoaderProvider from "./components/global loader/global-loader-provider";
import ShowLoader from "./components/global loader/loader";
// import PurchaseStudentPlan from "./components/studen\ts/student plan/purchaseStudentPlan";
import AdminClubManagment from "./Pages/admin club managment/AdminClubManagment";
import EmailView from "./components/email listing/EmailView";
import EmailEdit from "./components/email listing/EmailEdit";
import AdminClubView from "./Pages/admin club managment/AdminClubView";
import TaskView from "./Pages/school_task/TaskView.jsx";
import SchoolClubManagment from "./Pages/school club managment/SchoolClubManagment.jsx";
import SchoolTaskManagment from "./Pages/school_task/SchoolTaskManagment.jsx";
import AdminSchoolManagment from "./components/admin school data/AdminSchoolManagment.jsx";
import AdminSchoolEdit from "./components/School_Edit/SchoolEdit.jsx";
import ClubUserAddAndRemove from "./components/admin club/ClubUserAddAndRemove";
import Landing from "./Pages/website/Landing/Landing.jsx";
import GlobalStyles from "./GlobalStyle";
import MainHeader from "./components/website/header/MainHeader.jsx";
import StudentLogin from "./Pages/website/student_authentication/StudentLogin.jsx";
import About from "./Pages/website/AboutUs/About.jsx";
import ResetPassword from "./Pages/website/student_authentication/ResetPassword.jsx";

import RegisterForm from "./Pages/website/student_authentication/RegisterForm";
import SubscribeOurPlan from "./Pages/website/SubscribeOurPlan/SubscribeOurPlan.jsx";
import UploadPhoto from "./Pages/website/Dashboard/UploadPhoto.jsx";
import SchoolMoments from "./Pages/website/Dashboard/SchoolMoments.jsx";
import Club from "./Pages/website/Dashboard/Club.jsx";
import Notification from "./Pages/website/Dashboard/Notification.jsx";
import Chat from "./Pages/website/Dashboard/Chat.jsx";
import MyPost from "./Pages/website/Dashboard/MyPost.jsx";
import Gallery from "./Pages/website/Dashboard/Gallery.jsx";
import Contact from "./Pages/website/ContactUs/ContactUs.jsx";
import Settings from "./Pages/website/Dashboard/Settings.jsx";
import YearBookStudentDetails from "./components/schools/YearBookComponents/YearBookStudentDetails";
import NFTPost from "./components/admin_nft_post/AdminNFTPost.jsx";
import SchoolAdminNFTPost from "./components/schools/nft_school/SchoolAdminNFT.jsx";

import StripePayment from "./Pages/website/strip_payment/StripePayment";
import AdminReport from "./components/AdminReport";
import SchoolAdminReport from "./components/schools/school_report/SchoolReport.jsx";
import Usercomplate from "./components/schools/school_report/Usercomplate.jsx";
import Userincomplate from "./components/schools/school_report/Userincomplate.jsx";
import SchoolTaskReport from "./components/schools/school_report/SchoolTaskReport.jsx";
import AdminPlanDetails from "./Pages/admin_report_details/AdminReportDetails";
import SchoolSportClubManagment from "./Pages/school_sports_club/SchoolSportClubManagment";

import { REMEMBER_STATE, REMEMBER_STATE_STUDENT } from "./utils/constant";
import SportsClub from "./Pages/website/Dashboard/SportTeam";
import AdminTeamView from "./Pages/admin club managment/AdminTeamView";
import TeamUserAddAndRemove from "./components/admin club/TeamUserAddAndRemove";
import DigitalArtCreatorDetails from "./Pages/website/Dashboard/DigitalArtCreatorDetails.jsx";
import DigitalArtCreator from "./Pages/website/Dashboard/DigitalArtCreator.jsx";
import AdminSportManagment from "./Pages/admin_sport_managment/AdminSportManagment";
import MyMoments from "./Pages/website/Dashboard/MyMoments.jsx";
import OtherStudentProfile from "./Pages/website/Dashboard/OtherStudentProfile";
import Signatures from "./Pages/website/Dashboard/Signatures";
import VotingLeaderboard from "./Pages/website/Dashboard/VotingLeaderboard";
import PrivacyPolicy from "./Pages/website/PrivacyPolicy/Privacy.jsx";
import TermsConditon from "./Pages/website/TermsCondition/TermsConditon.jsx";
import ThankYou from "./Pages/website/ThankYou/ThankYou.jsx";
import SchoolVoting from "./Pages/school_voting/SchoolVoting";
import SchoolVotingView from "./components/voting/SchoolVotingView ";
import SchoolVotingHistory from "./Pages/school_voting/SchoolVotingHistory";
import SchoolVotingCategoryHistory from "./Pages/school_voting/SchoolVotingCategoryHistory";
import PageNotFound from "./Pages/website/404/404.jsx";
import SchoolVotingStandardHistory from "./Pages/school_voting/SchoolVotingStandardHistory.jsx";
import SchoolLabelManagement from "./Pages/school_label_managment/SchoolLabelManagement";
import SchoolBroadcastManagement from "./Pages/school_braodcast_managment/SchoolBroadcastManagment";
import SchoolWebLinksManagement from "./Pages/school_weblink/SchoolWebLinkManagement";
import SchoolJobManagement from "./Pages/school_job/SchoolJobMangement";
import JobUserAppliedDetails from "./components/schools/jobs/JobUserAppliedDetails";

export const AppContext = createContext(null);

function App() {
  const [constactUsHide, setConstactUsHide] = useState(true);
  let rememberState = JSON.parse(localStorage.getItem(REMEMBER_STATE));
  let rememberStateStudent = JSON.parse(
    localStorage.getItem(REMEMBER_STATE_STUDENT)
  );

  const [remember, setRemember] = useState(
    rememberState ? rememberState : false
  );
  const [studentRemember, setStudentRemember] = useState(
    rememberStateStudent ? rememberStateStudent : false
  );
  const [studentUserInfo, setstudentUserInfo] = useState();

  const [digitalImgLink, setDigitalImgLink] = useState();

  return (
    <div className="">
      <BrowserRouter>
        {/* <Main> */}
        <AppContext.Provider
          value={{
            remember,
            setRemember,
            setstudentUserInfo,
            studentUserInfo,
            setStudentRemember,
            studentRemember,
            constactUsHide,
            setConstactUsHide,
            setDigitalImgLink,
            digitalImgLink,
          }}
        >
          <GlobalLoaderProvider>
            <ShowLoader />
            <ToastContainer theme="colored" position="top-right" />
            <Routes>
              {/* open routes starts */}
              <Route path={admin.login} element={<Login />} />
              <Route
                path={admin.forgotPassword}
                element={<AdminForgetPassword />}
              />
              <Route path={admin.newPassword} element={<PasswordPage />} />
              <Route
                path={school.registration}
                element={<SchoolRegistration />}
              />

              {/* <Route
                path={student.purchasePlan}
                element={<PurchaseStudentPlan />}
              /> */}
              {/* open routes ends */}

              {/* secured route for admin starts*/}
              <Route element={<SecuredRoute />}>
                <Route element={<DashbordWrapper />}>
                  <Route element={<AdminHeader />}>
                    <Route path={admin.dashbord} element={<AdminDashboard />} />
                    <Route
                      path={admin.school}
                      element={<AdminSchoolManagment />}
                    />
                    <Route
                      path={admin.student}
                      element={<AdminStudentManagement />}
                    />
                    <Route path={admin.Cms} element={<AdminCMS />} />
                    <Route path={admin.club} element={<AdminClubManagment />} />
                    <Route path={admin.editor} element={<Editor />} />
                    <Route
                      path={admin.sports}
                      element={<AdminSportManagment />}
                    />

                    <Route
                      path={admin.schoolEdit}
                      element={<AdminSchoolEdit />}
                    />
                    <Route path={admin.studentEdit} element={<EditStudent />} />
                    <Route path={admin.plans} element={<AdminPlan />} />
                    <Route path={admin.dashbord} element={<AdminDashboard />} />
                    <Route
                      path={admin.clubUserAddAndRemove}
                      element={<ClubUserAddAndRemove />}
                    />
                    <Route path={admin.profile} element={<AdminProfile />} />
                    <Route
                      path={admin.editProfile}
                      element={<AdminProfileEdit />}
                    />
                    <Route path={admin.setting} element={<AdminSetting />} />
                    <Route path={admin.NFTPost} element={<NFTPost />} />
                    <Route path={admin.report} element={<AdminReport />} />
                    <Route
                      path={admin.reportDetails}
                      element={<AdminPlanDetails />}
                    />
                    <Route path={admin.CmsView} element={<AdminCmsView />} />
                  </Route>
                  <Route path={admin.sportView} element={<AdminTeamView />} />
                  <Route
                    path={admin.schoolPost}
                    element={<AdminPostDetails />}
                  />

                  <Route path={admin.CmsEditor} element={<AdminCmsEditor />} />
                  <Route path={admin.clubView} element={<AdminClubView />} />

                  {/* <Route
                    path={admin.emailTemplateView}
                    element={<EmailView />}
                  /> */}

                  <Route
                    path={admin.emailTemplateEdit}
                    element={<EmailEdit />}
                  />
                </Route>
              </Route>

              {/* </Route> */}
              {/* secured route for admin ends */}

              {/* 
            Secured route for school start
            */}

              <Route element={<SchoolSecuredRoute />}>
                <Route element={<SchoolDashboardWrapper />}>
                  <Route element={<SchoolHeader />}>
                    <Route
                      path={school.NFTPost}
                      element={<SchoolAdminNFTPost />}
                    />

                    <Route path={school.setting} element={<SchoolProfile />} />
                    <Route path={school.student} element={<SchoolStudent />} />
                    <Route
                      path={school.studentEdit}
                      element={<EditStudent />}
                    />
                    <Route
                      path={school.dashbord}
                      element={<SchoolDashboard />}
                    />
                    <Route
                      path={school.yearBook}
                      element={<Schoolyearbook />}
                    />
                    <Route
                      path={school.postDetails}
                      element={<PostDetails />}
                    />
                    <Route
                      path={school.postViewDetails}
                      element={<PostViewDetails />}
                    />
                    <Route
                      path={school.viewStudent}
                      element={<ViewStudent />}
                    />
                    <Route
                      path={school.club}
                      element={<SchoolClubManagment />}
                    />

                    <Route
                      path={school.task}
                      element={<SchoolTaskManagment />}
                    />
                    <Route
                      path={school.sports}
                      element={<SchoolSportClubManagment />}
                    />
                    <Route path={school.clubView} element={<AdminClubView />} />
                    <Route path={school.taskView} element={<TaskView />} />
                    <Route
                      path={school.sportView}
                      element={<AdminTeamView />}
                    />
                    <Route
                      path={school.clubUserAddAndRemove}
                      element={<ClubUserAddAndRemove />}
                    />
                    <Route
                      path={school.teamUserAddAndRemove}
                      element={<TeamUserAddAndRemove />}
                    />
                    <Route
                      path={school.yearBookStudents}
                      element={<YearBookStudentDetails />}
                    />
                    <Route
                      path={school.report}
                      element={<SchoolAdminReport />}
                    />

                    <Route path={school.complate} element={<Usercomplate />} />
                    <Route
                      path={school.incomplate}
                      element={<Userincomplate />}
                    />

                    <Route
                      path={school.taskreport}
                      element={<SchoolTaskReport />}
                    />
                    <Route path={school.vote} element={<SchoolVoting />} />
                    <Route
                      path={school.votingView}
                      element={<SchoolVotingView />}
                    />
                    <Route
                      path={school.votingHistory}
                      element={<SchoolVotingHistory />}
                    />
                    <Route
                      path={school.votingCategoryHistory}
                      element={<SchoolVotingCategoryHistory />}
                    />
                    <Route
                      path={school.votingStandardHistory}
                      element={<SchoolVotingStandardHistory />}
                    />
                    <Route
                      path={school.labels}
                      element={<SchoolLabelManagement />}
                    />
                    <Route
                      path={school.broadcast}
                      element={<SchoolBroadcastManagement />}
                    />

                    <Route
                      path={school.webLink}
                      element={<SchoolWebLinksManagement />}
                    />
                    <Route
                      path={school.job}
                      element={<SchoolJobManagement />}
                    />
                    <Route
                      path={`${school.jobUser}/:id`}
                      element={<JobUserAppliedDetails />}
                    />
                  </Route>
                </Route>
              </Route>

              {/* 
            Secured route for school end
            */}
              {/* WEBSITE ROUTES STARTS */}
              <Route element={<GlobalStyles />}>
                <Route path={website.landing} element={<Landing />} />
                <Route path={website.thankYou} element={<ThankYou />} />
                <Route
                  path={website.privacyPolicy}
                  element={<PrivacyPolicy />}
                />
                <Route
                  path={website.termsAndCondition}
                  element={<TermsConditon />}
                />

                <Route path={website.studentLogin} element={<StudentLogin />} />
                <Route path={website.about} element={<About />} />
                <Route
                  path={website.resetPassword}
                  element={<ResetPassword />}
                />
                <Route path={student.registration} element={<RegisterForm />} />

                {/*
              Secured route for student start
            */}
                <Route element={<StudentSecuredRoute />}>
                  <Route path={website.stripe} element={<StripePayment />} />
                  <Route
                    path={student.subscribeOurPlan}
                    element={<SubscribeOurPlan />}
                  />

                  <Route path={student.dashbord} element={<UploadPhoto />} />
                  <Route element={<MainHeader />}>
                    <Route
                      path={student.digitalArtDetails}
                      element={<DigitalArtCreatorDetails />}
                    />
                    <Route
                      path={student.digitalArt}
                      element={<DigitalArtCreator />}
                    />
                    {/* <Route element={<StudentDashboardWrapper />}> */}
                    <Route path={student.club} element={<Club />} />
                    <Route path={student.sports} element={<SportsClub />} />
                    <Route
                      path={student.notification}
                      element={<Notification />}
                    />
                    <Route path={student.chat} element={<Chat />} />
                    <Route path={student.post} element={<SchoolMoments />} />

                    <Route path={student.gallery} element={<Gallery />} />
                    <Route path={student.myMoment} element={<MyMoments />} />
                    <Route
                      path={student.otherStudentProfile}
                      element={<OtherStudentProfile />}
                    />
                    <Route path={student.signatures} element={<Signatures />} />
                    <Route
                      path="/VotingLeaderboard"
                      element={<VotingLeaderboard />}
                    />

                    <Route path={student.aboutUs} element={<About />} />
                    <Route path={student.contactUs} element={<Contact />} />
                    <Route path={student.myPost} element={<MyPost />} />
                    <Route path={student.setting} element={<Settings />} />
                  </Route>
                </Route>
                {/* </Route> */}
                {/**
                 * Secured route for student end
                 */}
                <Route path={website.pageNotFound} element={<PageNotFound />} />
              </Route>
              {/* WEBSITE ROUTES END */}
            </Routes>
          </GlobalLoaderProvider>
        </AppContext.Provider>
        {/* </Main> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
