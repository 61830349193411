import React, { useEffect, useState, useContext, Fragment } from "react";
import { StatusCodes } from "http-status-codes";
import { Link, useParams } from "react-router-dom";
import { admin } from "../../linksprovider";
import { cmsView, getEmailTemplates } from "../../services/auth.services";
import { loaderContext } from "../global loader/global-loader-provider";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import { Dialog, Transition } from "@headlessui/react";
import { GrClose } from "react-icons/gr";

function EmailView({ setOpenViewModal, openViewModal, item }) {
  const { loaderDispatch } = useContext(loaderContext);

  const [data, setData] = useState([]);

  let subscribe = openViewModal;
  useEffect(() => {
    if (subscribe) {
      getEmailData();
    }
    return () => (subscribe = false);
  }, [item]);

  //to get all data of email template by id
  const getEmailData = async () => {
    loaderDispatch({
      isLoading: true,
    });
    try {
      let result = await getEmailTemplates(item.id);

      if (result?.data?.status === StatusCodes.OK) {
        loaderDispatch({
          isLoading: false,
        });
        setData(result?.data?.data);
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Transition.Root show={openViewModal} as={Fragment}>
      <Dialog
        as='div'
        static
        className='fixed z-10 inset-0 overflow-y-auto'
        open={openViewModal}
        onClose={setOpenViewModal}>
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'>
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
            <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 '>
              <div className='max-w-max lg:max-w-7xl mx-auto '>
                <div className='relative z-10 mb-8 md:mb-2 md:px-6'>
                  <div className='text-base max-w-prose lg:max-w-none flex justify-between'>
                    <h2 className='leading-6 text-indigo-600 font-semibold tracking-wide uppercase '>
                      {data?.email_template_name}
                    </h2>
                    <GrClose
                      className='text-xl cursor-pointer'
                      onClick={() => setOpenViewModal(false)}
                    />
                  </div>
                </div>
                <div className='relative'>
                  <hr />
                  <div className='relative md:bg-white md:p-6'>
                    <div className='prose prose-indigo prose-lg text-gray-500 lg:max-w-none '>
                      {data.description?.length > 0 && parse(data.description)}
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default EmailView;
