import React, { Fragment } from "react";
import styled from "styled-components";
import { Transition, Dialog } from "@headlessui/react";
import HeadingFive from "./HeadingFive.jsx";
import { GrClose } from "react-icons/gr";
import { SiteModal } from "./HOC.jsx";
import { device } from "./Devices.js";

import HeadingFour from "./HeadingFour.jsx";
import { space } from "./Variables.js";

const PostSubmittedRow = styled.div`
  .modal_content {
    text-align: center;
    /* padding: ${space.space60} ${space.space16}; */
    /* img {
      width: 50px;
      height: 50px;
      margin: 0 auto ${space.space20};
    } */
    h4 {
      margin: 0;
    }
    .modal_inner {
      max-width: 590px;
    }
  }
  @media ${device.mobileL} {
    .modal_content {
      /* padding: 112px 130px; */
      /* img {
        width: 82px;
        height: 82px;
      } */
    }
  }
`;

function CommonModal({
  header,
  openCommonModal,
  setOpenCommonModal,
  message,
  icon,
  pdf,
}) {
  console.log("pdf", pdf);

  const handelPDFSource = () => {
    if (pdf?.url && pdf?.name?.includes("pdf")) {
      return pdf.url;
    } else if (pdf?.url && !pdf?.name?.includes("pdf")) {
      return pdf.url;
    } else if (pdf?.type === "application/pdf") {
      return URL.createObjectURL(pdf);
    } else if (pdf?.type?.includes("image")) {
      return URL.createObjectURL(pdf);
    }
  };

  return (
    <Transition.Root show={openCommonModal} as={Fragment}>
      <Dialog
        as='div'
        static
        open={openCommonModal}
        onClose={setOpenCommonModal}>
        <PostSubmittedRow className='SetProfilePhoto'>
          <SiteModal>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
              <div className='modal_inner'>
                <div className='modal_boday'>
                  <div className='model_top'>
                    <HeadingFive cname='title' h5Data={header} />
                    <span
                      className='modal_close'
                      onClick={() => {
                        setOpenCommonModal(false);
                      }}>
                      {" "}
                      <GrClose />
                    </span>
                  </div>

                  <div className='modal_content flex justify-center'>
                    {pdf?.name && (
                      <>
                        {pdf?.name?.includes("pdf") ? (
                          <iframe
                            src={handelPDFSource()}
                            title='verification_pdf'
                            itemType='application/pdf'
                            frameborder='0'
                            height={500}
                            width={500}></iframe>
                        ) : (
                          <img
                            src={handelPDFSource()}
                            className='max-h-96'
                            alt=''
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </SiteModal>
        </PostSubmittedRow>
      </Dialog>
    </Transition.Root>
  );
}

export default CommonModal;
