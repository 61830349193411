import React, {
  useRef,
  Fragment,
  useState,
  useContext,
  useEffect,
} from "react";
import styled from "styled-components";
import { Transition } from "@headlessui/react";
import HeadingFive from "../../../common/website/HeadingFive.jsx";
import { GrClose } from "react-icons/gr";
import { SiteModal, SiteForm } from "../../../common/website/HOC.jsx";
import Paragraph from "../../../common/website/Paragraph.jsx";
import HeadingFour from "../../../common/website/HeadingFour.jsx";
import { colors, space, siteGradient } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";

import IconBluePhotograph from "../../../images/website/icon_blue_photograph.svg";
import { IMG, IMG_DES } from "../../../utils/constant.js";
import { loaderContext } from "../../global loader/global-loader-provider.js";
import { toast } from "react-toastify";

const CreatePostImgRow = styled.div`
  .add_new_post {
    width: 100%;
    height: 250px;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: ${space.space16};
    overflow: hidden;
    background: ${colors.darkgrey};
  }
  .upload_photos {
    width: 70px;
    height: 70px;
    border-radius: 100%;
    background-color: ${colors.lightblue};
    box-shadow: ${siteGradient.boxshadowblue};
    background-image: url(${IconBluePhotograph});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px 24px;
    cursor: pointer;
    margin: 0 auto;
    input {
      display: none;
    }
  }
  .add_head {
    margin: ${space.space16} 0 2px;
  }
  @media ${device.mobileM} {
    .upload_photos {
      width: 70px;
      height: 70px;
      background-size: 38px 38px;
    }
    .add_new_post {
      height: 360px;
    }
  }
  @media ${device.mobileL} {
    .add_new_post {
      width: 530px;
      height: 490px;
    }
  }
`;

function CreatePostImg({
  setOpenImageModal,
  handleClick,
  setPostDetails,
  openImageModal,
  setErrorMessage,
  errorMessage,
}) {
  const [fileTypeError, setFileTypeError] = useState(false);

  const inputFile = useRef(null);
  const { loaderDispatch } = useContext(loaderContext);

  useEffect(() => {
    if (!openImageModal) {
      // setOpenImageModal(false);
      setPostDetails({
        postImage: null,
        caption: "",
      });
      handleClick(IMG);
    }
  }, [openImageModal]);

  const chooseFileBox = () => {
    setFileTypeError(false);
    inputFile.current.click();
  };

  const handelChange = (e) => {
    let fileData = e?.target?.files[0];
    if (!fileData?.type?.includes("video")) {
      if (
        fileData?.type === "image/png" ||
        fileData?.type === "image/jpg" ||
        fileData?.type === "image/jpeg"
      ) {
        handleClick(IMG_DES);
        setPostDetails((preVal) => {
          return { ...preVal, postImage: fileData };
        });
      } else {
        setFileTypeError(true);
        setErrorMessage(
          "File format not supported, please upload PNG, JPG or JPEG file."
        );
      }
    } else {
      console.log("fileData", fileData);
      if (fileData?.type?.includes("video")) {
        let video = document.createElement("video");
        video.preload = "metadata";

        video.onloadedmetadata = function () {
          window.URL.revokeObjectURL(video.src);
          // setInfos((i) => video.duration);
          let duration = video.duration;
          console.log("onloadedmetadata", duration / 60);
          if (duration / 60 <= 0.334) {
            handleClick(IMG_DES);
            setPostDetails((preVal) => {
              return { ...preVal, postImage: fileData };
            });
          } else {
            setFileTypeError(true);
            setErrorMessage("Video should not be more than 20 seconds");
          }
        };
        video.src = URL.createObjectURL(fileData);
      }
    }
  };

  return (
    <CreatePostImgRow>
      <SiteModal>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          enterTo='opacity-100 translate-y-0 sm:scale-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100 translate-y-0 sm:scale-100'
          leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
          <div className='modal_inner'>
            <div className='modal_boday'>
              <div className='model_top'>
                <HeadingFive cname='title' h5Data={"Create New Post"} />
                <span
                  className='modal_close'
                  onClick={() => {
                    setOpenImageModal(false);
                    setPostDetails({
                      postImage: null,
                      caption: "",
                    });
                    handleClick(IMG);
                  }}>
                  {" "}
                  <GrClose />
                </span>
              </div>
              <div className='modal_content'>
                <SiteForm as='div' className='upload_form'>
                  <div className='add_new_post'>
                    <div className='upload_photos' onClick={chooseFileBox}>
                      {/* <HiPencil /> */}
                      <input
                        type='file'
                        className='hidden'
                        ref={inputFile}
                        accept={"image/png,image/jpeg,video/*"}
                        value=''
                        // readOnly={editTable}
                        onChange={handelChange}
                      />
                    </div>
                    <HeadingFour cname='add_head' h4Data={"Add Post"} />
                    {/* <Paragraph cname='noMargin' pData={"or drag and drop"} /> */}
                    {fileTypeError && (
                      <p className='text-sm text-red-500'>{errorMessage}</p>
                    )}
                  </div>
                </SiteForm>
              </div>
            </div>
          </div>
        </Transition.Child>
      </SiteModal>
    </CreatePostImgRow>
  );
}

export default CreatePostImg;
