import { StatusCodes } from "http-status-codes";
import React, { createContext, useContext, useState } from "react";
import { Outlet } from "react-router-dom";
import AdminSideBar from "../components/adminsidebar/AdminSideBar";
import { loaderContext } from "../components/global loader/global-loader-provider";
import { getAdminProfile } from "../services/auth.services";
import { ADMIN_USER_DATA } from "../utils/constant";

export const AdminContext = createContext(null);
function DashboardWrapper() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [adminActiveTab, setAdminActiveTab] = useState("Dashboard");
  const [open, setOpen] = useState(false);
  const [currentAddress, setCurrentAddress] = useState("");
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [adminData, setadminData] = useState({});

  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }

  const { loaderDispatch } = useContext(loaderContext);

  const getData = async () => {
    loaderDispatch({
      isLoading: true,
    });
    let result = await getAdminProfile(userData.id);

    loaderDispatch({
      isLoading: false,
    });

    if (result?.data?.status === StatusCodes.OK) {
      setadminData(result?.data?.data);
    }
  };

  return (
    <div className='grid lg:grid-cols-6  h-screen'>
      <AdminContext.Provider
        value={{
          sidebarOpen,
          setSidebarOpen,
          adminActiveTab,
          setAdminActiveTab,
          setOpen,
          open,
          currentAddress,
          setCurrentAddress,
          createModalOpen,
          setCreateModalOpen,
          getData,
          adminData,
        }}>
        <AdminSideBar />

        <div className='lg:col-span-5 bg-white'>
          <Outlet />
        </div>
      </AdminContext.Provider>
    </div>
  );
}

export default DashboardWrapper;
