import React, { useEffect, useLayoutEffect } from "react";
import styled from "styled-components";

import { Container } from "../../../common/website/HOC.jsx";
import SiteLogo from "../../../common/website/SiteLogo.jsx";
import HeaderRight from "../../../common/website/HeaderRight.jsx";
import { space } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";
import { useState } from "react";

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  padding: ${space.space16} 0;

  .header_btn_group {
    grid-gap: ${space.space8};
    div {
      a,
      button {
        font-size: 0.875rem;
        line-height: 1.25rem;
        padding: 4px 10px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  @media ${device.mobileM} {
    .header_btn_group {
      grid-gap: ${space.space20};
      div {
        a,
        button {
          font-size: 1rem;
          line-height: 1.5rem;
          padding: 0.3rem 1rem;
          height: 36px;
        }
      }
    }
  }
  @media ${device.tablet} {
    .header_btn_group {
      div {
        a,
        button {
          height: 40px;
          padding: ${space.space8} ${space.space24};
        }
      }
    }
  }
  @media ${device.laptop} {
    padding: ${space.space20} 0;
  }
`;

function HeaderWithoutLogin({ handleClick }) {
  const [state, setstate] = useState(false);
  const changesvalueonScroll = () => {
    const scrollvalue = document.documentElement.scrollTop;
    if (scrollvalue > 100) {
      setstate(true);
    } else {
      setstate(false);
    }
  };

  useLayoutEffect(() => {
    changesvalueonScroll();
    window.addEventListener("scroll", changesvalueonScroll);
  }, []);

  return (
    <>
      <Header className={state ? "sticky" : ""}>
        <Container>
          <div className='flex flex-wrap items-center justify-between'>
            <SiteLogo cname='flex justify-center' link='/' />
            <HeaderRight handleClick={handleClick} />
          </div>
        </Container>
      </Header>
    </>
  );
}

export default HeaderWithoutLogin;
