import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getStudentDetails } from "../../../services/auth.services";
import { loaderContext } from "../../global loader/global-loader-provider";

export default function ViewStudent() {
  const { id } = useParams();
  const [studentDetails, setStudentDetails] = useState({
    studentName: "",
    studentId: "",
    profilePicture: "",
    email: "",
    plan: "",
  });

  const { loaderState, loaderDispatch } = useContext(loaderContext);

  const showStudentProfileData = async () => {
    loaderDispatch({
      isLoading: true,
    });
    let result = await getStudentDetails(id);
    loaderDispatch({
      isLoading: false,
    });
    if (result?.data?.data) {
      let allDetails = result?.data?.data;
      setStudentDetails({
        studentName: allDetails.student_name ? allDetails.student_name : "",
        studentId: allDetails.id ? allDetails.id : "",
        profilePicture: allDetails?.profile_picture
          ? allDetails?.profile_picture
          : "",
        email: allDetails?.email ? allDetails.email : "",
        plan: allDetails?.subscription_plan?.title,
      });
    }
  };

  useEffect(() => {
    showStudentProfileData();
  }, []);

  return (
    <div className='p-4'>
      <div className=''>
        <img
          src={
            studentDetails?.profilePicture
              ? studentDetails?.profilePicture
              : "https://t3.ftcdn.net/jpg/01/09/00/64/360_F_109006426_388PagqielgjFTAMgW59jRaDmPJvSBUL.jpg"
          }
          alt=''
          style={{
            display: "inline-block",
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
        <span className='ml-2'>{studentDetails?.studentName}</span>
      </div>
      <div>
        <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
          <div>
            <label className='block text-sm font-medium text-gray-700'>
              Student ID
            </label>

            <input
              type={"text"}
              value={studentDetails?.studentId}
              disabled
              autoComplete='cc-given-name'
              className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm'
            />

            <label className='block mt-2 text-sm font-medium text-gray-700'>
              Student Name
            </label>

            <input
              type={"text"}
              value={studentDetails?.studentName}
              disabled
              autoComplete='cc-given-name'
              className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm'
            />

            <label className='mt-2 block text-sm font-medium text-gray-700'>
              Student Email
            </label>

            <input
              type={"text"}
              value={studentDetails?.email}
              disabled
              autoComplete='cc-given-name'
              className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm'
            />

            <label className='mt-2 block text-sm font-medium text-gray-700'>
              Subscription Plan
            </label>

            <input
              type={"text"}
              value={studentDetails?.plan}
              disabled
              autoComplete='cc-given-name'
              className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm'
            />
          </div>
        </div>
      </div>
    </div>
  );
}
