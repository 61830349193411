import { StatusCodes } from "http-status-codes";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import { loaderContext } from "../components/global loader/global-loader-provider";
import { memberList } from "../services/auth.services";
import { ADMIN_USER_DATA, CLUB } from "../utils/constant";

function MembersList({ id, heading }) {
  const [page, setPage] = useState(1);
  const [memberData, setMemberData] = useState([]);

  const { loaderState, loaderDispatch } = useContext(loaderContext);
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));

  let location = useLocation();
  let memberRef = useRef();

  let sub = true;
  useEffect(() => {
    if (sub) {
      getMembersList();
    }
    return () => (sub = false);
  }, [page]);

  const getMembersList = async () => {
    try {
      // loaderDispatch({
      //   isLoading: true,
      // });
      let body = {};
      if (userData?.user_type === 2) {
        if (
          location?.pathname
            ?.toLocaleLowerCase()
            ?.includes(CLUB.toLocaleLowerCase())
        ) {
          body = {
            clubId: id,
            schoolId: userData?.id,
            page: page,
          };
        } else {
          body = {
            sportId: id,
            schoolId: userData?.id,
            page: page,
          };
        }
      } else {
        if (
          location?.pathname
            ?.toLocaleLowerCase()
            ?.includes(CLUB.toLocaleLowerCase())
        ) {
          body = {
            clubId: id,
            page: page,
          };
        } else {
          body = {
            sportId: id,
            page: page,
          };
        }
      }
      let result = await memberList(body);
      // loaderDispatch({
      //   isLoading: false,
      // });
      if (result?.data?.status === StatusCodes.OK) {
        let data = result?.data?.data?.docs;
        setMemberData((preVal) => {
          return [...preVal, ...data];
        });
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  let pages = page;
  const handelScroll = () => {
    if (memberRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = memberRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        pages++;
        setPage(pages);
      }
    }
  };
  return (
    <section
      aria-labelledby='timeline-title '
      className='lg:col-start-3 lg:col-span-1 max-h-96'>
      <div className='bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6 '>
        <h2 id='timeline-title' className='text-lg font-medium text-gray-600'>
          {heading}
        </h2>

        {/* list of members */}
        <div
          className='mt-6 flow-root overflow-auto'
          ref={memberRef}
          onScroll={handelScroll}>
          <div className='mb-7 '>
            <ul className=''>
              <div className='max-h-96 h-auto'>
                {memberData?.length > 0 ? (
                  memberData?.map((val, i) => {
                    return (
                      <li
                        className=' mb-2 rounded shadow mx-1 my-1 shadow-slate-400'
                        key={i}>
                        <div className='grid grid-cols-2  px-1 py-1'>
                          <div>
                            <div className='flex flex-row'>
                              <img
                                src={
                                  val?.profile_picture
                                    ? val?.profile_picture
                                    : "https://t3.ftcdn.net/jpg/01/09/00/64/360_F_109006426_388PagqielgjFTAMgW59jRaDmPJvSBUL.jpg"
                                }
                                alt=''
                                style={{
                                  display: "inline-block",
                                  width: "25px",
                                  height: "25px",
                                  borderRadius: "50%",
                                  objectFit: "cover",
                                }}
                              />

                              <div className=''>
                                <div className='text-sm ml-2 text-gray-600'>
                                  {val?.student_name?.charAt(0).toUpperCase() +
                                    val?.student_name?.slice(1)}
                                </div>
                              </div>
                            </div>
                            <div className='ml-8 text-sm text-gray-700 '>
                              <p>{val.email}</p>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <p className='text-sm text-gray-500'>No Members Found</p>
                )}
              </div>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MembersList;
