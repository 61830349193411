import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Dialog, Transition } from "@headlessui/react";
import { GrClose } from "react-icons/gr";
import { SiteModal } from "../../../common/website/HOC.jsx";
import { space, colors, siteGradient } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";

import MomentsImage from "../../../images/website/popUp_image.png";
import IconUser from "../../../images/website/icon_user_white.svg";
import IconNFT from "../../../images/website/icon_NFT_white.svg";
import IconDelete from "../../../images/website/icon_delete.svg";
import IconDots from "../../../images/website/icon_dots.svg";
import { useEffect } from "react";
import { loaderContext } from "../../global loader/global-loader-provider.js";
import { ADMIN_USER_DATA, GRADUATED } from "../../../utils/constant.js";
import {
  deletePost,
  uploadNewProfile,
} from "../../../services/auth.services.js";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import { PostContext } from "../header/MainHeader.jsx";
import DeleteModal from "./DeleteModal.jsx";

const MomentsImageModalRow = styled.div`
  .modal_inner {
    max-width: 610px;
  }
  .model_top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .menu_dropdown {
    position: relative;
    width: 24px;
    height: 24px;
    margin-right: ${space.space20};
    padding-top: 4px;
    .menu_list {
      position: absolute;
      top: 100%;
      background: ${colors.body};
      box-shadow: ${siteGradient.cardlightboxshadow};
      border-radius: ${space.space8};
      width: 196px;
      transform: translate(-170px, 10px);
      overflow: hidden;
      padding: ${space.space14} 0;
    }
    .link {
      display: flex;
      align-items: center;
      grid-gap: ${space.space8};
      font-size: 0.875rem;
      line-height: 1.063rem;
      padding: ${space.space8} 22px;
      width: 100%;
      &:hover {
        background: ${colors.primary};
      }
    }
  }
  .moment_image {
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${space.space16};
    overflow: hidden;
    img {
      object-fit: contain;
      margin: auto;
      border-radius: ${space.space16};
      max-height: 100%;
    }
  }
  @media ${device.mobileL} {
    .moment_image {
      height: 530px;
    }
  }
`;

function MomentsImageModal({
  openImageModal2,
  setOpenImageModal2,
  value,

  type,
  handelChooseFile,
}) {
  const { loaderDispatch } = useContext(loaderContext);
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }

  const { setpageReload, pageReload, userInfo } = useContext(PostContext);
  // Post Dropdown
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };

  const [openDeleteModal, setDeleteModal] = useState(false);
  const handelDelete = (e) => {
    setDeleteModal(true);
  };
  useEffect(() => {
    setOpen(false);
  }, [openImageModal2]);

  // End Post Dropdown

  const changeProfile = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });

      let query = {
        userId: userData?.id,
        postId: value?.id,
      };
      let result = await uploadNewProfile(query);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
          theme: "dark",
        });
        setOpenImageModal2(false);
        setpageReload(!pageReload);
      } else {
        toast(result?.data?.message, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Deletes a post.
  const handleDeletePost = async () => {
    loaderDispatch({
      isLoading: true,
    });
    let result = await deletePost(value?.id);
    loaderDispatch({
      isLoading: false,
    });
    if (result?.data?.status === StatusCodes.OK) {
      toast(result?.data?.message, {
        type: "success",
        theme: "dark",
      });
      setDeleteModal(false);
      setOpenImageModal2(false);
      setpageReload(!pageReload);
    } else {
      toast(result?.data?.message, { type: "error", theme: "dark" });
    }
  };

  return (
    <>
      <Transition.Root show={openImageModal2}>
        <Dialog
          as='div'
          static
          open={openImageModal2}
          onClose={setOpenImageModal2}>
          <MomentsImageModalRow>
            <SiteModal>
              <Transition.Child
                className='h-screen'
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
                <div className='modal_inner'>
                  <div className='modal_boday'>
                    <div className='model_top'>
                      <div className='menu_dropdown'>
                        {value?.postType == 1 &&
                          type &&
                          (userInfo?.standard?.toLowerCase() === GRADUATED ||
                            userInfo?.is_plan_expired !== "1") && (
                            <button className='menu_btn' onClick={handleOpen}>
                              <img src={IconDots} alt='icon dots' />
                            </button>
                          )}
                        {open ? (
                          <ul className='menu_list text-white'>
                            {userInfo?.standard?.toLowerCase() !== GRADUATED &&
                              userInfo?.is_plan_expired !== "1" && (
                                <li className='menu-item'>
                                  <button
                                    className='link'
                                    onClick={changeProfile}>
                                    <img src={IconUser} alt='edit' /> Make
                                    Profile Photo
                                  </button>
                                </li>
                              )}
                            <li className='menu-item'>
                              <button
                                className='link'
                                onClick={handelChooseFile}>
                                <img src={IconNFT} alt='edit' /> Convert to NFT
                              </button>
                            </li>
                            {userInfo?.standard?.toLowerCase() !== GRADUATED &&
                              userInfo?.is_plan_expired !== "1" && (
                                <li className='menu-item'>
                                  <button
                                    className='link'
                                    onClick={handelDelete}>
                                    <img src={IconDelete} alt='close' /> Delete
                                  </button>
                                </li>
                              )}
                          </ul>
                        ) : null}
                      </div>
                      <span
                        className='modal_close'
                        onClick={() => setOpenImageModal2(false)}>
                        {" "}
                        <GrClose />
                      </span>
                    </div>
                    <div className='modal_content'>
                      <div className='moment_image'>
                        {value?.postType == 1 ? (
                          <img src={value?.postName} alt='icon' />
                        ) : (
                          <video src={value?.postName} controls></video>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </SiteModal>
          </MomentsImageModalRow>
        </Dialog>
      </Transition.Root>
      <DeleteModal
        openDeleteModal={openDeleteModal}
        setDeleteModal={setDeleteModal}
        handleDeletePost={handleDeletePost}
      />
    </>
  );
}
export default MomentsImageModal;
