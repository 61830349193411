import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import SiteLogo from "../../../common/website/SiteLogo.jsx";
import { device } from "../../../common/website/Devices";
import { colors, space } from "../../../common/website/Variables";
import {
  Main,
  Container,
  SubscriptionCard,
  CustomLink,
} from "../../../common/website/HOC.jsx";

import CheckImgae from "../../../images/website/icon_check.svg";

import LoginBG from "../../../images/website/login_bg.svg";
import HeadingOne from "../../../common/website/HeadingOne.jsx";
import Paragraph from "../../../common/website/Paragraph.jsx";

const ThankYouRow = styled.div`
  height: 100%;
  position: relative;
  text-align: center;
  &::before {
    content: "";
    position: fixed;
    background: url(${LoginBG}) no-repeat;
    background-position: center;
    background-size: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    z-index: 1;
  }
  .full_screen_container {
    position: relative;
    height: 100%;
    z-index: 2;
    padding: 70px ${space.space20};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .logo {
    text-align: center;
    margin-bottom: ${space.space24};
    a {
      display: inline-block;
    }
  }
  h1 {
    font-size: 1.625rem;
    line-height: 2.125rem;
    color: ${colors.white};
    margin: ${space.space20} 0;
  }
  img {
    margin: 0 auto;
    &.check-image {
      width: 60px;
      height: 60px;
    }
  }
  p {
    font-size: 1rem;
    line-height: 1.625rem;
    color: ${colors.primary};
    margin: 0 auto ${space.space24};
    max-width: 890px;
    a {
      font-weight: 600;
      text-decoration: underline;
      &:hover {
        color: ${colors.primary};
      }
    }
    &.description {
      color: ${colors.white};
      margin: 0 0 ${space.space14};
    }
  }
  @media ${device.mobileL} {
    .logo {
      margin-bottom: ${space.space30};
    }
    img {
      &.check-image {
        width: 120px;
        height: 120px;
      }
    }
    h1 {
      margin: ${space.space40} 0 ${space.space34};
    }
    p {
      margin: 0 auto ${space.space40};
    }
  }
  @media ${device.mobileM} {
    height: 100vh;
    h1 {
      font-size: 2.125rem;
      line-height: 3.188rem;
    }
    .full_screen_container {
      justify-content: center;
    }
  }
  @media ${device.tablet} {
    .full_screen_container {
      padding: ${space.space50} ${space.space20};
    }
    .logo {
      margin-bottom: ${space.space40};
    }
    h1 {
      font-size: 2.5rem;
      line-height: 3.5rem;
    }
  }
  @media ${device.laptop} {
    h1 {
      font-size: 3.438rem;
      line-height: 4.313rem;
    }
  }
  @media ${device.laptopL} {
    .full_screen_container {
      padding: ${space.space50} 0;
    }
    .logo {
      margin-bottom: ${space.space60};
    }
  }
`;

function ThankYou() {
  return (
    <>
      <Main>
        <ThankYouRow className='content_full_screen'>
          <Container className='full_screen_container'>
            <SiteLogo cname='logo' />
            <img src={CheckImgae} alt='check-image' className='check-image' />
            <HeadingOne h1Data={"Thanks for Registering!"} />
            <p className='description'>
              We are committed to providing you with the highest level of
              service and support, so please don't hesitate to reach out to us
              if you have any questions or concerns. We are always here to help.
              Please contact us <a href='tel:8478485168'>847-848-5168</a>.
            </p>
            <p>
              Once again, congratulations on your successful registration, and
              we look forward to seeing you soon!
            </p>
            <CustomLink>
              <Link to='/' className='gradient'>
                Back to Home
              </Link>
            </CustomLink>
          </Container>
        </ThankYouRow>
      </Main>
    </>
  );
}

export default ThankYou;
