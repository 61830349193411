import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
// import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { BiDotsVerticalRounded } from "react-icons/bi";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function IconMenuDropDown({
  options,
  setOpenDeleteModal,
  changePostStatus,
  setActive,
}) {
  return (
    <Menu as='div' className='relative inline-block text-left'>
      <div>
        <Menu.Button className=''>
          <BiDotsVerticalRounded
            className='-mr-1 ml-2 h-5 w-5'
            aria-hidden='true'
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'>
        <Menu.Items className='absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <div className='py-1'>
            {options.status === 2 && (
              <Menu.Item>
                {({ active }) => (
                  <label
                    value='1'
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                    onClick={() => {
                      changePostStatus(true);
                      setActive(false);
                    }}>
                    Active
                  </label>
                )}
              </Menu.Item>
            )}
            {options.status === 1 && (
              <Menu.Item>
                {({ active }) => (
                  <label
                    value='2'
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                    onClick={() => {
                      changePostStatus(true);
                      setActive(true);
                    }}>
                    Inactive
                  </label>
                )}
              </Menu.Item>
            )}
            <Menu.Item>
              {({ active }) => (
                <label
                  value='0'
                  className={classNames(
                    active ? "bg-gray-100 text-red-600" : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                  style={{ color: "red" }}
                  onClick={() => {
                    setOpenDeleteModal(true);
                  }}>
                  Delete
                </label>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
