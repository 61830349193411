import React, { useContext } from "react";
import { website } from "../../linksprovider";
import { Link } from "react-router-dom";
import { CustomLink } from "./HOC.jsx";
import { AppContext } from "../../App";

function HeaderRight({ handleClick }) {
  const { constactUsHide } = useContext(AppContext);
  return (
    <>
      <div className='flex header_btn_group'>
        <CustomLink>
          <Link to={website.studentLogin} className='outline'>
            Login
          </Link>
        </CustomLink>
        <CustomLink>
          {constactUsHide && (
            <Link className='gradient' onClick={handleClick}>
              Register Now
            </Link>
          )}
        </CustomLink>
      </div>
    </>
  );
}

export default HeaderRight;
