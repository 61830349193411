import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { SchoolContext } from "../../common/SchoolDashboardWrapper";
import {
  ADMIN_USER_DATA,
  BROADCAST_MESSAGE,
  DELETE,
  EDIT,
  LABELS,
  UPDATE,
  VIEW,
} from "../../utils/constant";
import SearchFilter from "../../components/search_filter/SearchFilter";
import ListLayout from "../../common/ListLayoutSingleIcon.jsx";
import SelectFilter from "../../components/school_select_filter/SelectFilter";
import ReactPaginate from "react-paginate";
import { loaderContext } from "../../components/global loader/global-loader-provider";
import {
  broadcastMessageListing,
  createBroadcastMessage,
  deleteMessage,
  getSingleMessage,
  labelDelete,
  labelListing,
  studentListing,
  updateLabelData,
  updateMessage,
  viewSchoolDetails,
} from "../../services/auth.services";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";

import CommonModal from "../../common/commonModal";
import CustomInput from "../../common/CustomInput";
import AdminDeleteModel from "../../common/AdminDeleteModel";
import Select from "react-select";
import { CloudOff } from "@material-ui/icons";
function SchoolBroadcastManagement() {
  const [searchItem, setSearchItem] = useState("");
  const [itemOffset, setItemOffset] = useState(1);
  const [sortby, setSortby] = useState("");
  const [orderby, setOrderby] = useState(true);
  const [currentItems, setCurrentItems] = useState(null);
  const [data, setData] = useState([]);
  const [datastudent, setDatastudent] = useState([]);

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [dataCount, setDataCount] = useState(0);
  const [selectedUserIds, setSelectedUserIds] = useState([]);

  const [itemsId, setItemsId] = useState("");
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [page, setPage] = useState(1);
  const [labelOptions, setLabelOptions] = useState([
    {
      id: "",
      label: "Select label",
      value: "",
      selected: false,
    },
  ]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [createMessage, setCreateMessage] = useState({
    title: "",
    message: "",
  });
  const [editMessage, setEditMessage] = useState({
    title: "",
    message: "",
    status: "",
  });
  const [fields, setFields] = useState([
    // Your initial field objects go here
  ]);
  const [submit, setSubmit] = useState(false);
  const [selectedField, setSelectedField] = useState(null);

  const [textValue, SettextValue] = useState(null);

  const [CustomFieldSetstartdate, handleCustomFieldSetstartdate] =
    useState(null);

  const [CustomFieldSetenddate, handleCustomFieldSetenddate] = useState(null);

  const [searchtexterror, Setsearchtexterror] = useState(false);
  const [startdateerror, Setstartdateerror] = useState(false);
  const [enddateerror, Setenddateerror] = useState(false);
  const [checkboxerror, Setcheckboxerror] = useState(false);
  const [dropdownerror, Setdropdownerror] = useState(false);
  const [twofieldserror, Settwofieldserror] = useState(false);
  const { setAdminActiveTab } = useContext(SchoolContext);
  const { loaderDispatch } = useContext(loaderContext);
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));

  const tableHeader = [
    {
      key: "S.No.",
      value: "key",
      clickable: false,
      sorting: false,
    },
    {
      key: "message title",
      value: "title",
      clickable: false,
      sorting: true,
    },
    {
      key: "message",
      value: "message",
      clickable: false,
      sorting: true,
    },

    { key: "status", value: "status", clickable: false, sorting: true },
    {
      key: "options",
      value: "options",
    },
  ];

  const itemsOnPage = [
    { label: 10, value: "10" },
    { label: 20, value: "20" },
    { label: 50, value: "50" },
    { label: 70, value: "70" },
    { label: 100, value: "100" },
  ];

  useLayoutEffect(() => {
    setAdminActiveTab(BROADCAST_MESSAGE);
  }, []);

  useEffect(() => {
    const debounce = setTimeout(() => {
      handelListMessage();
    }, 300);
    return () => clearTimeout(debounce);
  }, [searchItem, sortby, orderby, itemOffset, itemsPerPage]);

  useEffect(() => {
    handleSchoolData();
    getSchoolDetails();
  }, []);

  const getSchoolDetails = async () => {
    loaderDispatch({
      isLoading: true,
    });

    let result = await viewSchoolDetails(userData.id);
    loaderDispatch({
      isLoading: false,
    });

    let schooluserData = result?.data?.data;
    if (schooluserData?.customFields) {
      const updatedFields = schooluserData?.customFields.map((item) => ({
        columnName: item.columnName,
        columnType: item.columnType,
        value: item.value,
        id: item.id,
      }));

      if (updatedFields?.length > 0) {
        setFields(updatedFields);
      }
    }
  };

  useEffect(() => {
    setCurrentItems(data);
    setPageCount(dataCount);
  }, [data, dataCount, itemsPerPage]);

  useEffect(() => {
    if (!openCreateModal) {
      setCreateMessage({ title: "", message: "" });
    }
    if (!openEditModal) {
      setEditMessage({
        title: "",
        message: "",
        status: "",
      });
    }
    setSubmit(false);
  }, [openCreateModal, openEditModal]);

  useEffect(() => {}, []);

  const handleOptions = (optionValue, item) => {
    switch (optionValue) {
      case VIEW:
        handelSingleMessage(item, VIEW);
        setItemsId(item?.id);
        break;
      case UPDATE:
        handelUpdateMessage(item?.status2, item?.id);

        break;

      case DELETE:
        setItemsId(item?.id);
        setOpenDeleteModal(true);
        break;

      default:
        break;
    }
  };

  const handleSorting = (data, order) => {
    setSortby(data);
    setOrderby(order);
  };

  const handlePageClick = (event) => {
    setItemOffset(event.selected + 1);
  };

  const options = [
    {
      value: "",
      label: "none",
    },
    {
      value: "Allstudent",
      label: "Allstudent",
    },
  ];

  const handelChangeCreateMessage = (e) => {
    const { name, value } = e?.target;

    if (value?.toString()?.trim().length > 0 || value?.toString() === "") {
      console.log();
      setCreateMessage((preVal) => {
        return {
          ...preVal,
          [name]: value?.toUpperCase()?.charAt(0) + value.substring(1),
        };
      });
    }
  };

  const handelCreateMessage = async () => {
    let error = true;

    if (
      selectedUserIds?.value === "" ||
      selectedUserIds?.value === null ||
      selectedUserIds?.value === undefined
    ) {
      if (!selectedField?.columnType) {
        Settwofieldserror(true);
        error = true;
      } else {
        Settwofieldserror(false);
        if (selectedField?.columnType === "string") {
          if (textValue === null) {
            Setsearchtexterror(true);
            error = true;
          } else {
            Setsearchtexterror(false);
            error = false;
          }
        } else if (selectedField?.columnType === "date") {
          if (CustomFieldSetstartdate === null) {
            Setstartdateerror(true);
            error = true;
          } else {
            Setstartdateerror(true);
            error = false;
          }

          if (CustomFieldSetenddate === null) {
            Setenddateerror(true);
            error = true;
          } else {
            Setenddateerror(true);
            error = false;
          }
        } else if (selectedField?.columnType === "checkbox") {
          if (textValue === null) {
            Setcheckboxerror(true);
            error = true;
          } else {
            Setcheckboxerror(false);
            error = false;
          }
        } else {
          if (textValue === null) {
            Setdropdownerror(true);
            error = true;
          } else {
            Setdropdownerror(false);
            error = false;
          }
        }
      }
    } else {
      error = false;
    }

    if (createMessage?.title && createMessage?.message && error === false) {
      try {
        loaderDispatch({
          isLoading: true,
        });
        let customFields;
        if (
          selectedField?.id !== null &&
          selectedField?.id !== "" &&
          selectedField?.id !== undefined
        ) {
          customFields = {
            customFieldStartDate:
              CustomFieldSetstartdate !== null
                ? new Date(CustomFieldSetstartdate).toISOString()
                : null,
            customFieldEndDate:
              CustomFieldSetenddate !== null
                ? new Date(CustomFieldSetenddate).toISOString()
                : null,
            customFieldId: selectedField?.id,
            customFieldValue: textValue,
          };
        } else {
          customFields = null;
        }

        // Convert customFields object to a JSON string
        //let customFieldsJsonString = JSON.stringify(customFields);

        let body = {
          ...createMessage,
          school_id: userData?.id,
          status: 1,
          customField: customFields === "null" ? null : customFields,
        };

        let result = await createBroadcastMessage(body);
        loaderDispatch({
          isLoading: false,
        });
        if (result?.data?.status === StatusCodes.OK) {
          toast(result?.data?.message, {
            type: "success",
          });
          setOpenCreateModal(false);
          handelListMessage();
        } else {
          toast(result?.data?.message, {
            type: "error",
          });
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      setSubmit(true);
    }
  };

  const handelListMessage = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let order = orderby ? "desc" : "asc";
      let query = {
        id: userData?.id,
        search: searchItem,
        page: itemOffset,
        sort_by: sortby,
        orderBy: order,
        limit: itemsPerPage,
      };
      let result = await broadcastMessageListing(query);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        const data = result?.data?.data?.messageListing?.rows;
        const count = result?.data?.data?.totalPage;

        setData(data);
        setDataCount(count);
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSchoolData = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let order = orderby ? "desc" : "asc";

      if (searchItem.toLowerCase() === "active") {
        setSearchItem("1");
      } else if (searchItem.toLowerCase() === "inactive") {
        setSearchItem("2");
      }
      let result = await studentListing(
        userData.id,
        `&search=${searchItem.trim()}&sort_by=${sortby}&order=${order}&page=${page}&limit=${itemsPerPage}`
      );
      loaderDispatch({
        isLoading: false,
      });
      setDatastudent([]);

      const studentData = result?.data?.data?.studentListing;

      if (result?.data?.status === StatusCodes.OK) {
        setDatastudent(studentData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handelEditMessage = (e) => {
    const { value, name } = e?.target;
    if (value?.toString()?.trim().length > 0 || value?.toString() === "") {
      console.log();
      setEditMessage((preVal) => {
        return {
          ...preVal,
          [name]: value?.toUpperCase()?.charAt(0) + value.substring(1),
        };
      });
    }
  };

  const handelSingleMessage = async (item, type) => {
    try {
      loaderDispatch({
        isLoading: true,
      });

      let result = await getSingleMessage(item?.id);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        let data = result?.data?.data;
        if (type === VIEW) {
          setOpenViewModal(true);
        } else if (type === EDIT) {
          // setOpenEditModal(true);
        }
        setEditMessage(data);
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelUpdateMessage = async (status, id) => {
    console.log("status", status);
    if (status) {
      try {
        loaderDispatch({
          isLoading: true,
        });
        let body = {
          status: status == 1 ? 2 : 1,
        };

        let result = await updateMessage(id, body);
        loaderDispatch({
          isLoading: false,
        });
        if (result?.data?.status === StatusCodes.OK) {
          toast(result?.data?.message, {
            type: "success",
          });
          setOpenEditModal(false);
          handelListMessage();
        } else {
          toast(result?.data?.message, {
            type: "error",
          });
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      setSubmit(true);
    }
  };

  const handelDeleteMessage = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let body = {
        school_id: userData?.id,
      };

      let result = await deleteMessage(itemsId, body);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
        });
        setOpenDeleteModal(false);
        handelListMessage();
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handelChangeselect = (event) => {
    setSelectedUserIds(event);
  };
  const handleCustomFieldSelection = (selectedLabel) => {
    Settwofieldserror(false);
    const selectedFieldData = fields.find(
      (item) => item.columnName === selectedLabel
    );
    setSelectedField(selectedFieldData);
    SettextValue(null);
    handleCustomFieldSetstartdate(null);
    handleCustomFieldSetenddate(null);
  };
  return (
    <>
      <div className="p-2 grid grid-cols-2 gap-2 mt-1">
        <div className=" flex justify-center">
          <SearchFilter
            setSearchItem={setSearchItem}
            searchItem={searchItem}
            setPage={setItemOffset}
            cname={
              " block w-full border border-gray-300 rounded-md shadow py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
            }
          />
        </div>
        <div className="flex justify-end ">
          <button
            className="px-4 py-2 bg-indigo-500 text-white hover:bg-indigo-600 rounded shadow-md"
            onClick={() => {
              setOpenCreateModal(true);
            }}
          >
            Create message
          </button>
        </div>
      </div>
      <ListLayout
        handleOptions={handleOptions}
        tableHeader={tableHeader}
        handleSorting={handleSorting}
        currentItems={currentItems}
        categoryType={2}
        item={{ value: "votes" }}
        toggle={true}
      />
      <div className="flex justify-between">
        {data?.length > 0 && (
          <>
            <div
              className="block lg:inline-block lg:mt-0"
              style={{ marginTop: "6px" }}
            >
              <label htmlFor="item" className="ml-5">
                Record Per Page:
              </label>
              <SelectFilter
                options={itemsOnPage}
                selectCls={"ml-3 bg-white border p-2 rounded"}
                name={"item"}
                id={"item"}
                onChange={(e) => {
                  setItemsPerPage(parseInt(e.target.value));
                }}
              />
            </div>

            <div className="mt-2 mb-10">
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next >"
                onPageChange={handlePageClick}
                pageCount={pageCount}
                previousLabel="< Previous"
                renderOnZeroPageCount={null}
                containerClassName={"pagination"}
                pageClassName={"pageClass"}
                forcePage={itemOffset - 1}
              />
            </div>
          </>
        )}
      </div>
      {data?.length === 0 && (
        <div className="flex flex-row absolute left-1/2 top-1/2">
          <p>Data not found..!!</p>
        </div>
      )}
      {/* <SchoolLabelEdit
        openViewModal={openViewModal}
        setOpenViewModal={setOpenViewModal}
        view={view}
        itemId={itemsId}
        handelLabelList={handelUpdatedLabelList}
      /> */}

      {/* create message modal starts */}
      <CommonModal
        openModal={openCreateModal}
        setOpenModal={setOpenCreateModal}
        header={"Create message"}
        divClass={
          "inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
        }
      >
        <div className="mb-2  itemsId-center">
          <div className="">
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="message_title"
            >
              Message title<span className="text-red-500">*</span>
            </label>
          </div>
          <div className="">
            <CustomInput
              type="text"
              id={"message_title"}
              inputCls={
                "mt-1 block w-full  border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm bg-white"
              }
              inputData={createMessage?.title}
              placeholder="Enter title"
              onChange={handelChangeCreateMessage}
              name="title"
              minLength={0}
              maxLength={30}
            />
            {submit && !createMessage?.title && (
              <p className="text-sm text-red-500">Please fill the field</p>
            )}
          </div>
        </div>
        <div className="mb-2  itemsId-center">
          <div className="">
            <label className="block text-sm font-medium text-gray-700">
              Message<span className="text-red-500">*</span>
            </label>
          </div>
          <div className="">
            <textarea
              type="text"
              className={
                "mt-1 block w-full  border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm bg-white"
              }
              value={createMessage?.message}
              placeholder="Enter message..."
              onChange={handelChangeCreateMessage}
              name="message"
            />
            {submit && !createMessage?.message && (
              <p className="text-sm text-red-500">Please fill the field</p>
            )}
          </div>
        </div>
        <div className="mb-2  itemsId-center">
          <div className="">
            <label className="block text-sm font-medium text-gray-700">
              Select customfield
            </label>
          </div>
          <select
            disabled={
              selectedUserIds?.value !== "" &&
              selectedUserIds?.value !== null &&
              selectedUserIds?.value !== undefined
                ? true
                : false
            }
            className="mt-1 block w-full  border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm bg-white"
            name="customfieldlabel"
            id="customfieldlabel"
            onChange={(e) => {
              handleCustomFieldSelection(e.target.value);
            }}
          >
            <option value="">Select custom field</option>
            {fields.map((val, i) => {
              return (
                <option value={val.columnName} key={i}>
                  {val.columnName}
                </option>
              );
            })}
          </select>
          {twofieldserror > 0 && (
            <p className="text-sm text-red-500">Please select a value.</p>
          )}

          {selectedUserIds?.length > 0 ? (
            <p className="text-sm text-red-500">
              You can't select customfiled because you have already select
              custom field, you have select only one option customfield ya
              student
            </p>
          ) : (
            ""
          )}
        </div>
        {selectedField?.columnType === "string" && (
          <div className="mb-2  itemsId-center">
            <div className="">
              <label className="block text-sm font-medium text-gray-700">
                Enter search value
              </label>
            </div>
            <input
              className="mt-1 block w-full  border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm bg-white"
              type="text"
              name="searchtext"
              onChange={(e) => {
                SettextValue(e.target.value);
              }}
              placeholder="Enter search value"
            ></input>
            {searchtexterror && (
              <p className="text-sm text-red-500">Please fill the field</p>
            )}
          </div>
        )}
        {selectedField?.columnType === "date" && (
          <>
            <div className="mb-2  itemsId-center">
              <div className="">
                <label className="block text-sm font-medium text-gray-700">
                  Start Date
                </label>
              </div>
              <input
                className="mt-1 block w-full  border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm bg-white"
                type="date"
                name="startdatesearch"
                onChange={(e) => {
                  handleCustomFieldSetstartdate(e.target.value);
                }}
              ></input>
              {startdateerror && (
                <p className="text-sm text-red-500">Please fill the field</p>
              )}
            </div>

            <div className="mb-2  itemsId-center">
              <div className="">
                <label className="block text-sm font-medium text-gray-700">
                  End Date
                </label>
              </div>
              <input
                className="mt-1 block w-full  border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm bg-white"
                type="date"
                name="enddatesearch"
                onChange={(e) => {
                  handleCustomFieldSetenddate(e.target.value);
                }}
              ></input>
              {enddateerror && (
                <p className="text-sm text-red-500">Please fill the field</p>
              )}
            </div>
          </>
        )}
        {selectedField?.columnType === "checkbox" && (
          <div className="mb-2  itemsId-center">
            <div className="">
              <label className="block text-sm font-medium text-gray-700">
                select value
              </label>
            </div>
            <select
              className="mt-1 block w-full  border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm bg-white"
              name="optionsearch"
              id="optionsearch"
              onChange={(e) => {
                SettextValue(e.target.value);
              }}
            >
              <option value="">Select value</option>
              {selectedField?.value.map((val, i) => {
                return (
                  <option value={val} key={i}>
                    {val}
                  </option>
                );
              })}
            </select>
            {checkboxerror && (
              <p className="text-sm text-red-500">Please select the field</p>
            )}
          </div>
        )}
        {selectedField?.columnType === "dropdown" && (
          <div className="mb-2  itemsId-center">
            <div className="">
              <label className="block text-sm font-medium text-gray-700">
                select value
              </label>
            </div>
            <select
              className="mt-1 block w-full  border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm bg-white"
              name="optionsearch"
              id="optionsearch"
              onChange={(e) => {
                SettextValue(e.target.value);
              }}
            >
              <option value="">Select value</option>
              {selectedField?.value.map((val, i) => {
                return (
                  <option value={val} key={i}>
                    {val}
                  </option>
                );
              })}
            </select>
            {dropdownerror && (
              <p className="text-sm text-red-500">Please select the field</p>
            )}
          </div>
        )}
        <div className="mb-2  itemsId-center">
          <div className="">
            <label className="block text-sm font-medium text-gray-700">
              Select student
            </label>
          </div>
          <div className="">
            <Select
              name="userIds"
              id="userIds"
              value={selectedUserIds}
              isDisabled={
                selectedField !== undefined &&
                selectedField !== null &&
                selectedField !== ""
                  ? true
                  : false
              }
              onChange={handelChangeselect}
              options={options}
              className={""}
              placeholder="Select students..."
              menuPlacement="top"
            ></Select>
            {selectedField !== undefined &&
            selectedField !== null &&
            selectedField !== "" ? (
              <p className="text-sm text-red-500">
                You can't select student because you have already select custom
                field, you have select only one option customfield ya student
              </p>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className="px-5  py-2 bg-indigo-500 hover:bg-indigo-600 text-white rounded shadow-md"
            onClick={handelCreateMessage}
          >
            Create
          </button>
        </div>
      </CommonModal>
      {/* create message modal ends */}

      {/*starts Edit message modal  */}
      <CommonModal
        openModal={openEditModal}
        setOpenModal={setOpenEditModal}
        header={"Edit message"}
        divClass={
          "inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
        }
      >
        <div className="mb-2  itemsId-center">
          <div className="">
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="message_title"
            >
              Message title<span className="text-red-500">*</span>
            </label>
          </div>
          <div className="">
            <CustomInput
              type="text"
              id={"message_title"}
              inputCls={
                "mt-1 block w-full  border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm bg-white"
              }
              inputData={editMessage?.title}
              placeholder="Enter title"
              onChange={handelEditMessage}
              name="title"
              minLength={0}
              maxLength={30}
            />
            {submit && !editMessage?.title && (
              <p className="text-sm text-red-500">Please fill the field</p>
            )}
          </div>
        </div>
        <div className="mb-2  itemsId-center">
          <div className="">
            <label className="block text-sm font-medium text-gray-700">
              Message<span className="text-red-500">*</span>
            </label>
          </div>
          <div className="">
            <textarea
              type="text"
              className={
                "mt-1 block w-full  border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm bg-white"
              }
              value={editMessage?.message}
              placeholder="Enter message..."
              onChange={handelEditMessage}
              name="message"
            />
            {submit && !editMessage?.message && (
              <p className="text-sm text-red-500">Please fill the field</p>
            )}
          </div>
        </div>

        <label className="block text-sm font-medium text-gray-700 mt-3 mb-3">
          Status<span className="text-red-600">&#42;</span>
          <label
            htmlFor="default-toggle"
            className="inline-flex relative items-center cursor-pointer align-middle ml-5"
          >
            <input
              type="checkbox"
              checked={editMessage.status == 1 ? true : false}
              name="status"
              onChange={() => {
                setEditMessage((preVal) => {
                  return {
                    ...preVal,
                    status: editMessage.status == 1 ? 2 : 1,
                  };
                });
              }}
              id="default-toggle"
              className="sr-only peer"
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          </label>
        </label>
        <div className="flex justify-end">
          <button
            type="submit"
            className="px-5  py-2 bg-indigo-600 hover:bg-indigo-700 text-white rounded shadow-md"
            onClick={handelUpdateMessage}
          >
            Update
          </button>
        </div>
      </CommonModal>
      {/*end Edit message modal  */}

      {/*starts view message modal  */}
      <CommonModal
        openModal={openViewModal}
        setOpenModal={setOpenViewModal}
        header={"View Message"}
        divClass={
          "inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
        }
      >
        <div className="mb-2  itemsId-center">
          <div className="">
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="message_title"
            >
              Message title<span className="text-red-500">*</span>
            </label>
          </div>
          <div className="">
            {/* <CustomInput
              type='text'
              id={"message_title"}
              inputCls={
                "mt-1 block w-full  border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm bg-white"
              }
              inputData={editMessage?.title}
              placeholder='Enter title'
              onChange={handelEditMessage}
              name='title'
              minLength={0}
              maxLength={30}
            /> */}
            <p className="mt-1 block w-full  border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm bg-white">
              {editMessage?.title}
            </p>
          </div>
        </div>
        <div className="mb-2  itemsId-center">
          <div className="">
            <label className="block text-sm font-medium text-gray-700">
              Message<span className="text-red-500">*</span>
            </label>
          </div>
          <div className="">
            {/* <textarea
              type='text'
              className={
                "mt-1 block w-full  border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm bg-white"
              }
              value={editMessage?.message}
              placeholder='Enter message...'
              onChange={handelEditMessage}
              name='message'
            /> */}
            <p className="mt-1 block w-full  border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm bg-white">
              {editMessage?.message}
            </p>
            {submit && !editMessage?.message && (
              <p className="text-sm text-red-500">Please fill the field</p>
            )}
          </div>
        </div>
      </CommonModal>
      {/*end view message modal  */}

      <AdminDeleteModel
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        header={"Delete Message"}
        deleteData={handelDeleteMessage}
      />
    </>
  );
}

export default SchoolBroadcastManagement;
