import React, { useContext, useState } from "react";
import styled from "styled-components";
import { colors, space, siteGradient } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";
import { SiteCard } from "../../../common/website/HOC.jsx";
import { CiGlobe } from "react-icons/ci";

import Paragraph from "../../../common/website/Paragraph.jsx";

import {
  commentList,
  deletePost,
  schoolPostComment,
} from "../../../services/auth.services";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import dateDifference from "date-difference";

import { MY_MOMENT_POST } from "../../../utils/constant";
import { loaderContext } from "../../global loader/global-loader-provider";
import { PostContext } from "../header/MainHeader";
import blankProfile from "../../../images/website/adminBlankProfile.png";

const PostRow = styled.div`
  .card_head {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    grid-gap: ${space.space8};
    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: ${space.space10};
      img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 100%;
        flex-shrink: 0;
      }
      .profile_name {
        margin: 0 0 2px;
      }
      .time {
        font-size: 0.75rem;
        line-height: 0.938rem;
        margin: 0;
        color: ${colors.lightgrey2};
      }
    }
  }
  .description {
    font-size: 0.75rem;
    margin: ${space.space10} 0 ${space.space20};
  }
  .post_img_box {
    height: auto;
    width: 100%;

    border-radius: ${space.space16};
    overflow: hidden;

    .post_image {
      margin: 0 auto;
      ${"" /* height: 100%; */}
      //width:100%;
      object-fit: contain;
      border-radius: ${space.space16};
    }
  }
  .post_card {
    padding: ${space.space10};
    margin-bottom: ${space.space20};
  }
  .action_group,
  .comment_group {
    display: flex;
    align-items: center;
  }
  .action_group {
    grid-gap: ${space.space20};
    margin: ${space.space20} 0 ${space.space10};
  }
  .comment_group {
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    grid-gap: ${space.space10};
    .user_count,
    .comment_count {
      font-size: 12px;
      line-height: 15px;
      color: ${colors.lightgrey2};
      margin: 0;
    }
    .comment_count {
      cursor: pointer;
      &:hover {
        color: ${colors.primary};
      }
    }
  }
  .mention {
    textarea {
      display: none !important;
    }
    strong {
      font-family: "Inter" !important;
      background-color: #171717;
      color: #00e1f0;
    }
    span {
      font-family: "Inter" !important;
      color: white;
      visibility: visible !important;
    }
  }
  .comment_form {
    padding-top: ${space.space16};
    margin-top: ${space.space16};
    border-top: 1px solid #ffffff1a;
    input {
      font-size: 0.875rem;
      border: none;
      padding: 0;
      padding-right: ${space.space40};
      &::placeholder {
        font-size: 0.875rem;
      }
    }
    .form_group {
      position: relative;
      z-index: 1;
      .post_btn {
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 0;
        font-size: 0.875rem;
        opacity: 0.3;
        pointer-events: none;
        &.show {
          pointer-events: auto;
          opacity: 1;
        }
        &:hover {
          color: ${colors.secondary};
        }
      }
    }
  }
  .menu_dropdown {
    position: relative;
    .menu_list {
      position: absolute;
      top: 100%;
      background: ${colors.body};
      box-shadow: ${siteGradient.cardlightboxshadow};
      border-radius: ${space.space8};
      width: 110px;
      transform: translateX(-80px);
      overflow: hidden;
      padding: ${space.space14} 0;
      z-index: 1;
    }
    .link {
      display: flex;
      align-items: center;
      grid-gap: ${space.space8};
      font-size: 0.875rem;
      line-height: 1.063rem;
      padding: ${space.space8} 22px;
      width: 100%;
      &:hover {
        background: ${colors.primary};
      }
    }
  }
  @media ${device.mobileM} {
    .post_img_box {
      ${"" /* height: 400px; */}
    }
    .action_group {
      grid-gap: ${space.space30};
    }
    .card_head {
      .profile_name {
        margin: 0 0 6px;
      }
    }
    .description {
      font-size: 0.875rem;
    }
    .card_head {
      .left {
        grid-gap: 22px;
        img {
          width: 55px;
          height: 55px;
        }
      }
    }
    .post_card {
      padding: ${space.space20};
    }
    .comment_group {
      align-items: center;
      flex-direction: row;
    }
    .comment_form {
      padding-top: ${space.space20};
      margin-top: ${space.space20};
      input {
        font-size: 1rem;
      }
      &::placeholder {
        font-size: 1rem;
      }
      .form_group {
        .post_btn {
          font-size: 1rem;
        }
      }
    }
  }
`;

function SchoolPost({ val }) {
  // Adds comment to the post
  // Post Dropdown
  const [open, setOpen] = React.useState(false);

  // End Post Dropdown

  // getAllPostDetails & userInfo from post context
  const { userInfo } = useContext(PostContext);

  // handel open comment modal

  // handel Delete modal

  // End Delete Pop Up

  // Returns the post time.
  const postTime = () => {
    let date = new Date(val?.createdAt);
    let vals = dateDifference(new Date(val?.createdAt), new Date(new Date()), {
      compact: true,
    });
    let vals2 = vals.slice(1);

    if (vals2 === "1d") {
      return `yesterday ${date?.getHours()}:${date?.getMinutes()} `;
    } else if (vals2?.includes("ms")) {
      return `few seconds ago`;
    } else if (vals2?.includes("m")) {
      return `${vals2} ago`;
    } else if (vals2?.includes("s")) {
      return `few seconds ago`;
    } else if (vals2?.includes("h")) {
      return `${vals2} ago`;
    } else {
      return `${date?.getDate()}-${
        date?.getMonth() + 1
      }-${date.getFullYear()} ${date?.getHours()}:${date?.getMinutes()} `;
    }
  };

  // SHOW LIKE CAPTION DATA

  // Deletes a post.

  return (
    <>
      <PostRow>
        <SiteCard className='post_card'>
          <div className='card_head'>
            <div className='left'>
              <img
                src={
                  val?.User?.profile_picture
                    ? val?.User?.profile_picture
                    : blankProfile
                }
                alt='Profile_Image'
              />
              <div className='detail'>
                <Paragraph
                  cname='profile_name'
                  pData={
                    <>
                      <span className='opacity-60'>Posted by </span>
                      <span>School Admin</span>
                    </>
                  }
                />
                <Paragraph cname='time' pData={postTime()} />
              </div>
            </div>
          </div>
          <div className='my-2'>
            <p className='smallParagaraph description '>{val?.description}</p>
          </div>
          <div className='bg-[#70308C] px-2 py-2 rounded my-2 truncate'>
            <p className='font-bold flex items-center space-x-1'>
              <CiGlobe className='text-xl' /> <span>Click here :</span>
            </p>
            <a
              className='smallParagaraph description hover:text-cyan-400'
              target='_blank'
              href={val?.postName}
              rel='noreferrer'>
              {val?.postName}
            </a>
          </div>
          <div className='post_img_box'></div>
        </SiteCard>
      </PostRow>
    </>
  );
}

export default SchoolPost;
