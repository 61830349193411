import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { GrClose } from "react-icons/gr";
import moment from "moment";
import { COMMENT } from "../../utils/constant";
import { commentList, likeList } from "../../services/auth.services";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import dateDifference from "date-difference";

function AdminPostDetailModal({
  openDeleteModal,
  setOpenDeleteModal,
  postData,
  type,
}) {
  const [postLikeAndComment, setPostLikeAndComment] = useState({
    Likes: [],
    Comments: [],
  });

  const [page, setPage] = useState(1);

  let likeRef = useRef(null);

  let sub = true;
  useEffect(() => {
    if (sub && type !== COMMENT && openDeleteModal) {
      getLikeList(postData?.id, page);
    } else if (sub && type === COMMENT && openDeleteModal) {
      getCommentList(postData?.id, page);
    }
    setPage(1);
    return () => {
      sub = false;
    };
  }, [postData, openDeleteModal]);

  const getLikeList = async (id, page) => {
    try {
      let body = {
        id: id,
        page: page,
      };
      let result = await likeList(body);
      if (result?.data?.status === StatusCodes.OK) {
        let data = result?.data?.data?.likeListing?.rows;
        if (data?.length > 0 && page > 1) {
          setPostLikeAndComment((preVal) => {
            return { ...preVal, Likes: data };
          });
        }
        if (page === 1 || !page) {
          setPostLikeAndComment({ Likes: data });
        }
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCommentList = async (id, page) => {
    try {
      let body = {
        id: id,
        page: page,
        sort_by: "createdAt",
        order: "desc",
      };
      let result = await commentList(body);
      if (result?.data?.status === StatusCodes.OK) {
        let data = result?.data?.data?.commentListing?.rows;
        if (data?.length > 0 && page > 1) {
          setPostLikeAndComment((preVal) => {
            return {
              ...preVal,
              Comments: data,
            };
          });
        }
        if (page === 1 || !page) {
          setPostLikeAndComment({ Comments: data });
        }
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  let pages = page;
  const handelScroll = () => {
    if (likeRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = likeRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        pages++;
        setPage(pages);
        if (type !== COMMENT) {
          getLikeList(postData?.id, pages);
        } else if (type === COMMENT) {
          getCommentList(postData?.id, pages);
        }
      }
    }
  };

  const postTime = (createdAt) => {
    let date = new Date(createdAt);
    let vals = dateDifference(new Date(createdAt), new Date(new Date()), {
      compact: true,
    });
    let vals2 = vals.slice(1);

    if (vals2 === "1d") {
      return `yesterday ${date?.getHours()}:${date?.getMinutes()} `;
    } else if (vals2?.includes("ms")) {
      return `few seconds ago`;
    } else if (vals2?.includes("m")) {
      return `${vals2} ago`;
    } else if (vals2?.includes("s")) {
      return `few seconds ago`;
    } else if (vals2?.includes("h")) {
      return `${vals2} ago`;
    } else {
      return `${date?.getDate()}-${
        date?.getMonth() + 1
      }-${date.getFullYear()} ${date?.getHours()}:${date?.getMinutes()} `;
    }
  };

  return (
    <Transition.Root show={openDeleteModal} as={Fragment}>
      <Dialog
        as='div'
        static
        className='fixed z-10 inset-0 overflow-y-auto'
        open={openDeleteModal}
        onClose={setOpenDeleteModal}>
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'>
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
            <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
              <div>
                <div className='mb-6 row block text-lg font-medium text-gray-600'>
                  <span>Post detail</span>
                  <span className='float-right cursor-pointer'>
                    {" "}
                    <GrClose onClick={() => setOpenDeleteModal(false)} />
                  </span>
                </div>

                <div className='mb-6 row  text-sm  text-gray-500'>
                  {postData?.postName && (
                    <div>
                      {postData?.postType === 1 ? (
                        <img
                          className='rounded-sm'
                          src={postData?.postName}
                          alt='post img'
                          style={{ width: "100%" }}
                        />
                      ) : (
                        <video
                          controls
                          width='100%'
                          // height='400'
                          title={postData?.postName}
                          src={postData?.postName}
                          type='video/mp4'></video>
                      )}
                    </div>
                  )}
                  <div className=' mt-2 break-words'>
                    <p>{postData?.description}</p>
                  </div>
                </div>
                <div className='mb-6 row block text-lg font-medium text-gray-600'>
                  <span>
                    {type === COMMENT
                      ? postLikeAndComment?.Comments?.length > 0
                        ? "Comments"
                        : "No Comments Found..!!"
                      : postData?.Likes?.length > 0
                      ? "Likes"
                      : "No Likes Found..!!"}
                  </span>
                </div>
                <div className='mt-5 sm:mt-6 '>
                  <div
                    className='max-h-52 overflow-auto'
                    ref={likeRef}
                    onScroll={handelScroll}>
                    <ul>
                      {type === COMMENT &&
                        postData?.Comments?.map((val, i) => {
                          return (
                            <li
                              className=' mb-2 rounded shadow mx-1 my-1 shadow-slate-400'
                              key={i}>
                              <div className='grid grid-cols-2  px-1 py-1'>
                                <div>
                                  <div className='flex flex-row'>
                                    <img
                                      src={
                                        val?.User?.filtered_image
                                          ? val?.User?.filtered_image
                                          : "https://t3.ftcdn.net/jpg/01/09/00/64/360_F_109006426_388PagqielgjFTAMgW59jRaDmPJvSBUL.jpg"
                                      }
                                      alt=''
                                      style={{
                                        display: "inline-block",
                                        width: "25px",
                                        height: "25px",
                                        borderRadius: "50%",
                                        objectFit: "cover",
                                      }}
                                    />

                                    <div className=''>
                                      <div className='text-sm ml-2 text-gray-600'>
                                        {val?.User?.student_name
                                          ?.charAt(0)
                                          .toUpperCase() +
                                          val?.User?.student_name?.slice(1)}
                                      </div>
                                    </div>
                                  </div>
                                  <div className='ml-8 text-sm text-gray-700'>
                                    <p>{val.comment}</p>
                                  </div>
                                </div>

                                <div className='text-sm text-gray-600 flex justify-end'>
                                  <div className='w-fit mr-2 '>
                                    <p>{postTime(val?.createdAt)}</p>
                                    {/* <p className='text-end'>
                                      {postTime(val?.createdAt)}
                                    </p> */}
                                  </div>
                                </div>
                                {/* <button>
                                <Link to="">View post</Link>
                              </button> */}
                              </div>
                            </li>
                          );
                        })}

                      {type !== COMMENT &&
                        postLikeAndComment?.Likes?.map((val, i) => {
                          return (
                            <li
                              className=' mb-2 rounded shadow mx-1 my-1 shadow-slate-400'
                              key={i}>
                              <div className='grid grid-cols-2  px-1 py-1'>
                                <div>
                                  <div className='flex flex-row'>
                                    <img
                                      src={
                                        val?.User?.filtered_image
                                          ? val?.User?.filtered_image
                                          : "https://t3.ftcdn.net/jpg/01/09/00/64/360_F_109006426_388PagqielgjFTAMgW59jRaDmPJvSBUL.jpg"
                                      }
                                      alt=''
                                      style={{
                                        display: "inline-block",
                                        width: "25px",
                                        height: "25px",
                                        borderRadius: "50%",
                                        objectFit: "cover",
                                      }}
                                    />

                                    <div className='text-sm ml-2 text-gray-600'>
                                      {val?.User?.student_name
                                        ?.charAt(0)
                                        .toUpperCase() +
                                        val?.User?.student_name?.slice(1)}
                                    </div>
                                  </div>
                                </div>

                                <div className='text-sm text-gray-600 flex justify-end'>
                                  <div className='w-fit mr-2 '>
                                    <p>{postTime(val?.createdAt)}</p>
                                    {/* <p className='text-end'>
                                      {postTime(val?.createdAt)}
                                    </p> */}
                                  </div>
                                </div>
                                {/* <button>
                                <Link to="">View post</Link>
                              </button> */}
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                  {/* <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:col-start-2 sm:text-sm"
                    onClick={() => deleteData()}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpenDeleteModal(false)}
                  >
                    Cancel
                  </button> */}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default AdminPostDetailModal;
