import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
// import { Formik } from "formik";
import { DashboardBody, SiteForm } from "../../../common/website/HOC.jsx";
import { device } from "../../../common/website/Devices";
import { colors, space } from "../../../common/website/Variables";
import ProfileHeader from "../../../components/website/ProfileHeader/ProfileHeader.jsx";
import VotingSelector from "../../../common/website/VotingSelector.jsx";
import VotingWinnerCard from "../../../common/website/VotingWinnerCard.jsx";

import HeadingThree from "../../../common/website/HeadingThree.jsx";
import HeadingFive from "../../../common/website/HeadingFive.jsx";

import UserProfileImage from "../../../images/website/NFT_image.png";
import DigitalArtImage from "../../../images/website/digital_art_image.png";
import DigitalArtImageNFT from "../../../images/website/digital_art_image_NFT.png";
import DigitalArtArrow from "../../../images/website/digital_art_arrow.svg";
import IconAward from "../../../images/website/icon_award.svg";
import Button from "../../../common/website/Button.jsx";
import { PostContext } from "../../../components/website/header/MainHeader.jsx";
import { loaderContext } from "../../../components/global loader/global-loader-provider.js";
import {
  getAlreadyVotedDetails,
  getSubscriptionDetail,
  getVotingStatus,
  getVotingStudentWinnerHistory,
  getVotingWinnerHistory,
  schoolVoteCategoryListing,
  submitVotes,
} from "../../../services/auth.services.js";
import { StatusCodes } from "http-status-codes";
import { ADMIN_USER_DATA, GRADUATED } from "../../../utils/constant.js";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { student, website } from "../../../linksprovider.js";

const VotingLeaderboardRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  .profile_top {
    padding: ${space.space20};
    margin-bottom: ${space.space24};
    &::before {
      top: -80px;
      left: -36px;
      height: 155%;
    }
    &::after {
      content: "";
      position: absolute;
      top: -80px;
      right: -15px;
      width: 100%;
      height: 155%;
      background-color: ${colors.body};
      z-index: -1;
      display: none;
    }
    .NFT_Balance,
    .member_count {
      display: none !important;
    }
    .profile_image {
      bottom: -6px;
    }
  }
  .club_group {
    display: none;
  }
  h3,
  h4,
  p {
    position: static;
  }
  .sidebar_right {
    position: sticky;
    top: 130px;
    display: none;
  }
  .sceen_bottom {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
  .sceen_main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-wrap: wrap;
  }
  .create_post {
    margin-top: 0;
  }

  .dash_body {
    width: 100%;
    padding-right: 0;
    p {
      margin: 0 0 ${space.space14};
      line-height: 1.625rem;
      &:last-child {
        margin: 0;
      }
      .blue_text {
        color: ${colors.primary};
        font-weight: 600;
        margin-left: 3px;
      }
    }
  }
  .vote_head {
    margin: 0;
    .vote_btn {
      display: block;
    }
  }
  .voting_top {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    grid-gap: ${space.space20};
    .voting_left {
      width: 100%;
      .head {
        display: flex;
        align-items: center;
        grid-gap: ${space.space12};
        margin-bottom: ${space.space14};
        h3 {
          margin: 0;
        }
      }
    }
    .voting_right {
      max-width: 354px;
      margin: 0 auto ${space.space14};
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h5 {
        color: ${colors.primary};
        margin-bottom: ${space.space16};
        width: 100%;
      }
      .digital_art_card_list {
        display: flex;
        align-items: center;
        grid-gap: ${space.space14};
        width: 100%;
        justify-content: center;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          /* background: url(${DigitalArtArrow}) no-repeat center; */
          background-size: contain;
          width: 68px;
          height: 68px;
          left: 0;
          right: 0;
          bottom: -40px;
          margin: auto;
        }
        .item {
          width: 50%;
          max-width: 170px;
          img {
            border-radius: ${space.space8};
          }
        }
      }
    }
  }
  .voting_selector_list,
  .voting_winner_list {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: ${space.space10} -12px 0;
  }
  .hidden {
    display: none;
  }
  .voting_col {
    width: 100%;
    padding: 0 12px;
  }
  .voting_btn {
    text-align: center;
    margin-top: ${space.space30};
    .btn {
      min-width: auto;
    }
  }
  @media ${device.mobileM} {
    .profile_top {
      padding: ${space.space30};
      margin-bottom: 48px;
      .profile_image {
        bottom: -54px;
      }
    }
    .voting_col {
      width: 50%;
    }
  }
  @media ${device.mobileL} {
    .voting_selector_list,
    .voting_winner_list {
      margin: ${space.space24} -12px 0;
    }
    .voting_btn {
      .btn {
        min-width: 310px;
      }
    }
    .voting_col {
      width: 50%;
    }
    .voting_top {
      flex-direction: row;
      .voting_left {
        width: 60%;
      }
      .voting_right {
        margin: 0;
      }
    }
  }
  @media ${device.tablet} {
    width: 75%;
    .profile_header {
      padding-left: ${space.space20};
    }
    .sidebar_right {
      display: block;
    }
    .mobile_hidden {
      display: block;
    }
    .club_group {
      flex-direction: column;
      align-items: flex-start;
      .count {
        font-size: 0.75rem;
        line-height: 19px;
      }
    }
    .dash_body {
      padding-left: ${space.space20};
    }
    .profile_top {
      &::after {
        display: block;
      }
    }
  }
  @media ${device.laptop} {
    width: 77%;
    .voting_top {
      grid-gap: 100px;
      .voting_right {
        h5 {
          margin-bottom: ${space.space40};
        }
      }
    }
    .voting_selector_list,
    .voting_winner_list {
      margin: ${space.space60} -12px 0;
    }
    .voting_btn {
      margin-top: 76px;
    }
    .sidebar_right {
      top: 188px;
    }
    .club_group {
      flex-direction: row;
      align-items: center;
    }
    .profile_top {
      padding: ${space.space60} ${space.space40} ${space.space40};
      margin-bottom: 70px;
      .profile_image {
        bottom: -70px;
      }
    }
  }
  @media ${device.laptopL} {
    width: 80%;
    .voting_col {
      width: 33.33%;
      margin: ${space.space24} 0;
    }
    .dash_body {
      padding-left: ${space.space40};
    }
    .club_group {
      .count {
        font-size: 1rem;
        line-height: 1.625rem;
      }
    }
    .profile_header {
      padding-left: ${space.space40};
    }
  }
`;
function VotingLeaderboard() {
  const {
    setUserInfo,
    gettingClassList,
    classmatesData,
    userInfo,
    handelLogout,
    // getMomentPostListing,
  } = useContext(PostContext);

  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(false);
  const [votedData, setVotedData] = useState([]);
  const [votedStatus, setVotedStatus] = useState(false);
  const [votingStatus, setVotingStatus] = useState({});
  const [postLink, setPostLink] = useState([]);
  const [firstPageLoad, setFirstPageLoad] = useState("");

  const { loaderDispatch } = useContext(loaderContext);

  let sub = true;
  useEffect(() => {
    if (sub) {
      getStudentProfile();
    }
    return () => (sub = false);
  }, []);

  let navigate = useNavigate();

  const gettingAlreadyVotedDetails = async (data) => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let params = {
        action_user_id: data?.id,
        type: 1,
        academic_year: data?.academic_year,
      };
      let result = await getAlreadyVotedDetails(params);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        let datas = result?.data?.data;
        if (datas?.length > 0) {
          setVotedData(datas);
          setData(datas);
          setVotedStatus(true);
        } else {
          handelSchoolVoteCategoryListing(data);
        }
      } else {
        toast(result?.data?.message, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getStudentProfile = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });

      let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));

      let studentData = await getSubscriptionDetail(userData);
      loaderDispatch({
        isLoading: false,
      });
      if (studentData?.data?.status === 410) {
        handelLogout();
      }
      if (studentData?.data?.status === StatusCodes.OK) {
        let data = studentData?.data?.data;

        if (userInfo?.standard?.toLowerCase() === GRADUATED) {
          navigate(student.post);
        }
        setUserInfo(data);
        gettingVotingStatus(data);
      } else {
        toast(studentData?.data?.massage, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  //function to get club list
  const handelSchoolVoteCategoryListing = async (data) => {
    try {
      loaderDispatch({
        isLoading: true,
      });

      let params = {
        id: data?.User?.id,
        userType: data?.user_type,
      };

      let result = await schoolVoteCategoryListing(params);
      console.log(result);

      loaderDispatch({
        isLoading: false,
      });
      setData([]);

      if (result?.data?.status === StatusCodes.OK) {
        const studentData = result?.data?.data?.categoryListing;
        const studentDataCount = result?.data?.data;
        console.log(studentData);

        setData(studentData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelChange = (e, category_id) => {
    setError(false);
    const { name, value } = e?.target;

    setUsers((preVal) => {
      let body = {
        get_user_id: parseInt(value),
        action_user_id: userInfo?.id,
        type: 1,
        category_id: category_id,
        category_name: name,
        status: 1,
        voting_year: userInfo?.academic_year,
        standard: userInfo?.standard,
      };
      let match = false;
      for (let i = 0; i < preVal.length; i++) {
        if (preVal[i]?.category_id === category_id) {
          preVal[i] = body;
          match = true;
        }
      }

      if (match) {
        return [...preVal];
      } else {
        return [...preVal, body];
      }
    });
  };

  const handelSubmit = async () => {
    setError(false);
    try {
      let body = {
        users,
        action_user_id: userInfo?.id,
        academic_year: userInfo?.academic_year,
        school_id: userInfo?.User?.id,
        type: 1,
        standard: userInfo?.standard || "",
      };

      console.log("body", body);

      if (users?.length === data?.length - 1) {
        loaderDispatch({
          isLoading: true,
        });
        let result = await submitVotes(body);
        loaderDispatch({
          isLoading: false,
        });

        if (result?.data?.status === StatusCodes.OK) {
          toast(result?.data?.message, {
            type: "success",
            theme: "dark",
          });
          setUsers([]);
          gettingAlreadyVotedDetails(userInfo);
        } else {
          toast(result?.data?.message, {
            type: "error",
            theme: "dark",
          });
        }
      } else {
        setError(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const gettingVotingStatus = async (data) => {
    try {
      loaderDispatch({
        isLoading: true,
      });

      let params = {
        schoolId: data?.User?.id,
      };

      let result = await getVotingStatus(params);

      if (result?.data?.status === StatusCodes.OK) {
        let datas = result?.data?.data;
        setVotingStatus(datas?.is_voting);
        setFirstPageLoad(datas?.status);
        // setFirstPageLoad(3);

        if (datas?.is_voting) {
          gettingAlreadyVotedDetails(data);
          gettingClassList({ StudentInfo: data });
        } else {
          handelVoteDoneHistory(data);
        }
      } else {
        toast(result?.data?.message, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelVoteDoneHistory = async (data) => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      console.log("datadata", data?.academic_year);
      let params = {
        schoolId: data?.User?.id,
        academicYear: data?.academic_year,
        standard: data?.standard,
      };

      let result = await getVotingWinnerHistory(params);
      console.log(result);

      loaderDispatch({
        isLoading: false,
      });
      setData([]);
      // setDataCount(0);
      if (result?.data?.status === StatusCodes.OK) {
        const studentData = result?.data?.data?.categoryListing;
        // const studentDataCount = result?.data?.data?.totalPage;
        console.log(studentData);

        setData(studentData);
        getPostLink(studentData);
        // setDataCount(studentDataCount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getPostLink = (data) => {
    setPostLink([]);
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].winners.length; j++) {
        if (data[i].winners[j].post) {
          console.log("datass", data[i].winners[j].post?.postName);
          setPostLink((preVal) => {
            return [...preVal, { link: data[i].winners[j].post?.postName }];
          });
        }
      }
    }
  };

  console.log("ssssss", users);

  console.log("classmatesData", data);
  return (
    <>
      <VotingLeaderboardRow>
        <div className='sceen_main'>
          <ProfileHeader
            cname='profile_header'
            ClubProfileImage={userInfo?.filtered_image}
            Name={"Voting Leaderboard"}
            votingStatus={votingStatus}
            votedStatus={votedStatus}
            firstPageLoad={firstPageLoad}
          />
          <div className='sceen_bottom'>
            <DashboardBody className='dash_body'>
              <div className='voting_top'>
                <div className='voting_left'>
                  <div className='head'>
                    <img
                      src={IconAward}
                      alt='award_icon'
                      className={
                        firstPageLoad === 3 || votingStatus ? "hidden" : ""
                      }
                    />
                    {/* Hiiden */}
                    {(votingStatus || firstPageLoad === 3) && (
                      <HeadingThree h3Data={"Select Student"} />
                    )}
                    {/* End Hiiden */}
                    <HeadingThree
                      cname={
                        firstPageLoad === 3 || votingStatus ? "hidden" : ""
                      }
                      h3Data={"Winners"}
                    />
                  </div>

                  {firstPageLoad === 3 && (
                    <p className='description'>
                      Vote soon on the below following categories!
                      {/* <span className='blue_text'>Voting ends 04/01/2023</span> */}
                    </p>
                  )}

                  {firstPageLoad !== 3 && votingStatus && (
                    <p className='description'>
                      Vote on the below categories before time runs out!
                      {/* <span className='blue_text'>Voting ends 04/01/2023</span> */}
                    </p>
                  )}

                  {firstPageLoad !== 3 && !votingStatus && (
                    <p className='description'>
                      Your winners below for the following categories!
                      {/* <span className='blue_text'>Voting ends 04/01/2023</span> */}
                    </p>
                  )}
                </div>
                <div className='voting_right'>
                  <HeadingFive h5Data={"Digital Art Of The Year"} />
                  <div className='digital_art_card_list'>
                    {!votingStatus && postLink?.length > 0 ? (
                      postLink?.map((val, i) => {
                        return (
                          <div className='item' key={i}>
                            <img src={val?.link} alt='original_image' />
                          </div>
                        );
                      })
                    ) : (
                      <>
                        <div className='item'>
                          <img src={DigitalArtImage} alt='original_image' />
                        </div>
                        <div className='item'>
                          <img src={DigitalArtImageNFT} alt='nft_image' />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {/*start Hiiden */}

              <SiteForm as='div'>
                <div
                  className={
                    votingStatus
                      ? "voting_selector_list"
                      : "voting_selector_list hidden"
                  }>
                  {data?.length - 1 > 0 ? (
                    data?.map((val, i) => {
                      if (val?.type !== 2) {
                        return (
                          <div className='voting_col' key={val?.id}>
                            <VotingSelector
                              value={val}
                              classmatesData={classmatesData}
                              handelChange={handelChange}
                              votedData={votedData}
                              i={i}
                              user={val?.User}
                            />
                          </div>
                        );
                      }
                    })
                  ) : (
                    <p>Voting category not found..!!</p>
                  )}

                  {/* <div className='voting_col'>
                    <VotingSelector
                      headingData={"Most popular"}
                      classmatesData={classmatesData}
                    />
                  </div>
                  <div className='voting_col'>
                    <VotingSelector
                      headingData={"Biggest flirt"}
                      classmatesData={classmatesData}
                    />
                  </div> */}
                </div>

                <div
                  className={
                    votingStatus && data?.length - 1 > 0
                      ? "voting_btn"
                      : "voting_btn hidden"
                  }>
                  {error && (
                    <p className='text-sm text-red-600'>
                      {votedData?.length > 0
                        ? "You have already voted"
                        : "Please select all categories"}
                    </p>
                  )}
                  {votedStatus ? (
                    <p>You have already voted</p>
                  ) : (
                    <Button
                      cname='gradient btn'
                      buttonData={"Submit vote"}
                      onClick={handelSubmit}
                    />
                  )}
                </div>
              </SiteForm>

              {/* End Hiiden */}

              <div
                className={
                  votingStatus
                    ? "voting_winner_list hidden"
                    : "voting_winner_list"
                }>
                {data?.length > 0
                  ? data?.map((val) => {
                      if (val?.winners) {
                        return (
                          <div className='voting_col' key={val?.id}>
                            <VotingWinnerCard
                              headingData={val?.name}
                              winners={val?.winners}
                              type={val?.type}
                            />
                          </div>
                        );
                      } else {
                        if (firstPageLoad !== 3) {
                          return (
                            <div className='voting_col' key={val?.id}>
                              <VotingWinnerCard
                                headingData={val?.name}
                                winners={[]}
                                type={val?.type}
                              />
                            </div>
                          );
                        }
                      }
                    })
                  : null}

                {firstPageLoad === 3 ? <p>No category found..!!</p> : null}
              </div>
            </DashboardBody>
          </div>
        </div>
      </VotingLeaderboardRow>
    </>
  );
}

export default VotingLeaderboard;
