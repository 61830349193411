import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import {
  DashboardBody,
  DashboardRightBar,
  SiteForm,
} from "../../../common/website/HOC.jsx";
import { device } from "../../../common/website/Devices";
import { colors, space, siteGradient } from "../../../common/website/Variables";
import Classmates from "../../../components/website/Classmates/Classmates.jsx";
import ProfileHeader from "../../../components/website/ProfileHeader/ProfileHeader.jsx";
import Post from "../../../components/website/Post/Post.jsx";
import Button from "../../../common/website/Button.jsx";

import IconBluePhotograph from "../../../images/website/icon_blue_photograph.svg";
import ProfileImage from "../../../images/website/profile_new_image4.png";
import UserProfileImage from "../../../images/website/profile_rachel_mathis.png";
import SignatureImage from "../../../images/website/NFT_image.png";
import PostImage from "../../../images/website/post_image.png";
import PostImage1 from "../../../images/website/post_1.png";
import PostImage2 from "../../../images/website/post_2.png";
import PostImage3 from "../../../images/website/post_3.png";
import SignatureIcon from "../../../images/website/icon_signatures.svg";
import Paragraph from "../../../common/website/Paragraph.jsx";
import { loaderContext } from "../../../components/global loader/global-loader-provider.js";
import {
  ADMIN_USER_DATA,
  GRADUATED,
  OTHER_STUDENT_POST,
} from "../../../utils/constant.js";
import {
  getSubscriptionDetail,
  postSignature,
  signatureAndPostListing,
} from "../../../services/auth.services.js";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import { PostContext } from "../../../components/website/header/MainHeader.jsx";
import { useParams } from "react-router-dom";
import blankProfile from "../../../images/website/dummy_user_profile.svg";
import SignaturesData from "../../../common/website/SignaturesData.jsx";

const OtherStudentProfileRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  .profile_top {
    .profile_details .member_count {
      display: none;
    }
  }
  .club_group {
    display: none;
  }
  .profile_top {
    .other_profile_text {
      display: block;
      font-size: 0.75rem;
    }
    .NFT_Balance.other_profile_year {
      display: flex;
    }
    .NFT_Balance {
      display: none;
    }
  }

  h3,
  h4,
  p {
    position: static;
  }
  .sidebar_right {
    position: sticky;
    top: 154px;
    display: none;
  }
  .upload_card {
    border-radius: ${space.space16};
    padding: 64px ${space.space24};
    text-align: center;
    .add_head {
      margin: 18px 0 2px;
    }
  }

  .upload_photos {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background-color: ${colors.lightblue};
    box-shadow: ${siteGradient.boxshadowblue};
    background-image: url(${IconBluePhotograph});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40px 40px;
    cursor: pointer;
    margin: auto;
    input {
      display: none;
    }
  }
  .mobile_hidden {
    display: none;
  }
  .classmates_list {
    height: calc(100vh - 472px);
  }
  .sceen_bottom {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
  .sceen_main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-wrap: wrap;
  }
  .create_post {
    margin-top: 0;
  }
  .vote_head {
    flex-direction: row;
    align-items: center;
  }
  .list_wrap {
    .list_item {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      grid-gap: ${space.space8};
      margin-bottom: ${space.space24};
      border: 1px solid ${colors.darkgrey};
      position: relative;
      padding: ${space.space16};
      border-radius: ${space.space8};
      img {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        object-fit: cover;
        flex-shrink: 0;
      }
      .details {
        .name,
        .description {
          font-size: 0.875rem;
          line-height: 1.063rem;
          color: ${colors.white};
          margin-bottom: 4px;
        }
        .description {
          line-height: 1.313rem;
          margin: 0;
        }
        .duration {
          font-size: 0.75rem;
          line-height: 0.938rem;
          color: ${colors.primary};
          margin: 0 0 ${space.space8};
        }
      }
    }
  }
  .comment_form {
    background: ${colors.darkgrey};
    border-radius: ${space.space8};
    padding: ${space.space20};
    margin-bottom: ${space.space24};
    display: flex;
    align-items: center;
    grid-gap: ${space.space8};
    img {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      object-fit: cover;
      flex-shrink: 0;
    }
    input {
      font-size: 0.75rem;
      line-height: 0.938rem;
      border: none;
      padding: 0;
      padding-right: ${space.space40};
      height: 44px;
      &::placeholder {
        font-size: 0.75rem;
      }
    }
    .form_group {
      position: relative;
      z-index: 1;
      width: 100%;
      border: 1px solid ${colors.lightwhiteborder};
      border-radius: ${space.space8};
      padding: 0 ${space.space16};
      display: flex;
      align-items: center;
      grid-gap: ${space.space8};
      img {
        width: 20px;
        height: 20px;
        opacity: 0.3;
        flex-shrink: 0;
        &.show {
          opacity: 1;
        }
      }
      .post_btn {
        position: absolute;
        right: 20px;
        top: 50%;
        bottom: 0;
        transform: translate(0, -50%);
        padding: 0;
        font-size: 0.875rem;
        opacity: 0.3;
        pointer-events: none;
        &.show {
          pointer-events: auto;
          opacity: 1;
        }
        &:hover {
          color: ${colors.secondary};
        }
      }
    }
  }
  @media ${device.mobileM} {
    .comment_form {
      grid-gap: ${space.space12};
      .form_group {
        padding: 0 ${space.space20};
      }
      img {
        width: 44px;
        height: 44px;
      }
    }
    .upload_photos {
      width: 100px;
      height: 100px;
      background-size: 54px 54px;
    }
    .upload_card {
      padding: ${space.space40} ${space.space24};
    }
    .profile_top {
      .other_profile_text {
        font-size: 1rem;
      }
    }
    .list_wrap {
      .list_item {
        flex-direction: row;
        grid-gap: ${space.space14};
        img {
          width: 44px;
          height: 44px;
        }
        .menu_dropdown {
          display: none;
          .menu_list {
            padding: 0.875rem 0;
          }
        }
      }
    }
    .comment_form {
      .form_group {
        .post_btn {
          font-size: 1rem;
        }
      }
    }
  }
  @media ${device.tablet} {
    width: 75%;
    .profile_header {
      padding-left: ${space.space20};
    }
    .sidebar_right {
      display: block;
    }
    .mobile_hidden {
      display: block;
    }
    .club_group {
      flex-direction: column;
      align-items: flex-start;
      .count {
        font-size: 0.75rem;
        line-height: 19px;
      }
    }
    .classmates_list {
      height: calc(100vh - 276px);
    }
  }
  @media ${device.laptop} {
    width: 77%;
    .sidebar_right {
      top: 214px;
    }
    .classmates_list {
      height: calc(100vh - 342px);
    }
    .club_group {
      flex-direction: row;
      align-items: center;
    }
  }
  @media ${device.laptopL} {
    width: 80%;
    .club_group {
      .count {
        font-size: 1rem;
        line-height: 1.625rem;
      }
    }
    .classmates_list {
      height: calc(100vh - 362px);
    }
    .profile_header {
      padding-left: ${space.space40};
    }
  }
`;
function OtherStudentProfile() {
  const [comment, setComment] = useState("");
  const [otherStudentData, setOtherStudentData] = useState({});
  // const [postData, setPostData] = useState([]);

  const { loaderDispatch } = useContext(loaderContext);
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));

  const {
    gettingClassList,
    classmatesData,
    // postData,
    handelPostList,

    setUserInfo,
    userInfo,
    setpostlocation,

    setPage,
    postData,
    handelLogout,
  } = useContext(PostContext);
  let { email } = useParams();
  const [classMatePage, setClassMatePage] = useState(1);
  const [postPageCount, setPostPageCount] = useState(1);

  const scrollRef = useRef();
  const postRef = useRef();
  let pages = classMatePage;
  let postPage = postPageCount;
  let sub = true;
  useEffect(() => {
    if (sub) {
      getStudentProfile();
      getLogedInStudentProfile();
      setpostlocation(OTHER_STUDENT_POST);
      // setClubData({});
      setPage(1);
    }

    return () => (sub = false);
  }, [email]);

  const getStudentProfile = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });

      let body = {
        email: email,
      };

      let studentData = await getSubscriptionDetail(body);
      loaderDispatch({
        isLoading: false,
      });
      if (studentData?.data?.status === StatusCodes.OK) {
        let data = studentData?.data?.data;
        console.log("userData", data);
        // getAllPostDetails({
        //   get_user_id: data?.id,
        //   // id: data?.User?.id,
        //   schoolId: data?.User?.id,
        // });
        handelPostList({ data, loader: true });
        setOtherStudentData(data);
      } else {
        toast(studentData?.data?.massage, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getLogedInStudentProfile = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });

      let body = {
        email: userData?.email,
      };

      let studentData = await getSubscriptionDetail(body);
      loaderDispatch({
        isLoading: false,
      });
      if (studentData?.data?.status === 410) {
        handelLogout();
      }
      if (studentData?.data?.status === StatusCodes.OK) {
        let data = studentData?.data?.data;

        console.log(data, "holand data");
        gettingClassList({ StudentInfo: data });

        setUserInfo(data);
      } else {
        toast(studentData?.data?.massage, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const uploadSignature = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });

      let body = {
        description: comment,
        get_user_id: otherStudentData?.id,
        user_id: userInfo?.id,
        mode: 1,
        status: 1,
        academic_year: userInfo.academic_year,
      };

      let result = await postSignature(body);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
          theme: "dark",
        });
        setComment("");
      } else {
        toast(result?.data?.message, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelChange = (e) => {
    const { value } = e?.target;
    if (value?.trim()?.length > 0 || value === "") {
      setComment(value);
    }
  };

  console.log("postData", postData);

  const handelPagination = () => {
    if (scrollRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        console.log("yesssss", scrollTop, clientHeight, scrollHeight);
        pages++;
        setClassMatePage(pages);
      }
    }
  };

  const handelPaginationPost = () => {
    if (postRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = postRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        console.log("tttttt", scrollTop, clientHeight, scrollHeight);
        postPage++;
        setPostPageCount(postPage);
        handelPostList({ data: userInfo, pages: postPage, loader: false });
      }
    }
  };

  return (
    <>
      <OtherStudentProfileRow ref={postRef} onScroll={handelPaginationPost}>
        <div className='sceen_main'>
          <ProfileHeader
            cname='profile_header'
            ClubProfileImage={otherStudentData?.filtered_image}
            Name={otherStudentData?.student_name}
            // count={classmatesData?.length}
          />
          <div className='sceen_bottom'>
            <DashboardBody className='dash_body'>
              <SiteForm as='div' className='comment_form'>
                <img
                  src={userInfo?.filtered_image || blankProfile}
                  alt='signature_image'
                />
                <div className='form_group'>
                  <img
                    src={SignatureIcon}
                    alt='signature_icon'
                    className={comment.length > 0 ? "show" : ""}
                  />
                  <input
                    type='text'
                    name='text'
                    placeholder='Add Signature'
                    value={comment}
                    onChange={handelChange}
                  />
                  {userInfo?.standard?.toLowerCase() !== GRADUATED &&
                    userInfo?.is_plan_expired !== "1" && (
                      <Button
                        tag='button'
                        cname={
                          comment.length > 0 ? "post_btn show" : "post_btn"
                        }
                        buttonData={"Post"}
                        onClick={uploadSignature}
                      />
                    )}
                </div>
              </SiteForm>
              <ul className='list_wrap'>
                {postData?.length > 0 ? (
                  postData?.map((value) => {
                    if (value?.mode) {
                      return (
                        <SignaturesData
                          key={value.id}
                          id={value?.id}
                          profileImage={value?.User?.filtered_image}
                          name={value?.User?.student_name}
                          duration={value?.createdAt}
                          description={value?.description}
                          modes={value?.mode}
                          type={false}
                        />
                      );
                    } else {
                      return <Post val={value} key={value?.id} />;
                    }
                  })
                ) : (
                  <p className='flex justify-center'>
                    Posts and signatures not found..!!
                  </p>
                )}
              </ul>
            </DashboardBody>
            <DashboardRightBar className='sidebar_right'>
              <Classmates
                classmatesData={classmatesData}
                message={"No Classmates Found..!!"}
                pData={"Classmates"}
                handelPagination={handelPagination}
                paginationRef={scrollRef}
              />
            </DashboardRightBar>
          </div>
        </div>
      </OtherStudentProfileRow>
    </>
  );
}

export default OtherStudentProfile;
