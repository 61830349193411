import React, { useContext, useRef, useState } from "react";
import styled from "styled-components";

import { siteGradient, space } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { SiteForm, CustomDatePicker } from "../../../common/website/HOC.jsx";

import Label from "../../../common/website/Label.jsx";
import Button from "../../../common/website/Button.jsx";
import Paragraph from "../../../common/website/Paragraph.jsx";

import UserProfileImage from "../../../images/website/NFT_image.png";
import OpenEye from "../../../images/website/icon_open_eye.svg";
import CloseEye from "../../../images/website/icon_close_eye.svg";
import * as Yup from "yup";
import { loaderContext } from "../../../components/global loader/global-loader-provider";
import {
  docsStudentUpload,
  getVerificationDocs,
  updateStudentDetail,
} from "../../../services/auth.services";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import { PostContext } from "../../../components/website/header/MainHeader";
import { GRADUATED } from "../../../utils/constant";
import imageCompression from "browser-image-compression";
import DatePicker from "react-date-picker";

const EditProfileRow = styled.div`
  .profile_form {
    width: 100%;
  }
  .react-calendar__tile:disabled {
    background-color: #0b0b0b00;
    color: #acacac4a;
  }
  .react-calendar__navigation button:disabled {
    background-color: #0b0b0b00;
    color: #acacac4a;
  }
  .form_group {
    margin-bottom: ${space.space24};
  }
  .btn_group {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: ${space.space16};
    margin-top: 0;
  }
  .pass_group {
    position: relative;
    input {
      padding-right: 26px;
    }
    .eye_btn {
      content: "";
      position: absolute;
      background: url(${OpenEye}) no-repeat;
      background-size: contain;
      background-position: center;
      width: 18px;
      height: 18px;
      right: 0;
      bottom: 15px;
    }
    input[type="text"] ~ .eye_btn {
      background: url(${CloseEye}) no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
  .upload_file {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: ${space.space16};
    margin-bottom: ${space.space24};
    .file {
      width: 55px;
      height: 55px;
      border-radius: 100%;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%;
      }
      .camera_icon {
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        cursor: pointer;
        background: rgba(23, 23, 23, 0.4);
        box-shadow: ${siteGradient.boxshadowWhite};
        backdrop-filter: blur(7.5px);
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
  @media ${device.mobileM} {
    .upload_file {
      .file {
        width: 65px;
        height: 65px;
      }
    }
  }
  @media ${device.mobileL} {
    .upload_file {
      .file {
        width: 80px;
        height: 80px;
      }
    }
  }
  @media ${device.tablet} {
    .upload_file {
      margin-bottom: ${space.space40};
    }
    .form_group {
      margin-bottom: 40px;
    }
    .btn_group {
      margin-top: ${space.space10};
    }
  }
  @media ${device.laptop} {
    .profile_form {
      width: 90%;
    }
  }
`;
function EditProfile(props) {
  const { userInfo } = useContext(PostContext);
  const inputFile = useRef(null);
  const chooseFileBox = () => {
    inputFile.current.click();
  };

  // Password Show Hiden
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const [profile_picture, setprofile_picture] = useState();
  const [passwordError, setPasswordError] = useState(false);
  const [value, onChange] = useState(() => {
    return userInfo?.dob ? new Date(userInfo?.dob) : new Date();
  });

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const togglePassword2 = () => {
    setPasswordShown2(!passwordShown2);
  };
  // End Password Show Hiden

  const { loaderDispatch } = useContext(loaderContext);

  // Sets the initial values.
  let initialValues = {
    fullName: userInfo?.student_name || "",
    newPassword: "",
    confirmPassword: "",
  };

  // Creates a boolean validation schema
  const validationSchema = Yup.object({
    fullName: Yup.string().required("Please fill the field"),
    newPassword: Yup.string().min(
      4,
      "Password must be at least 4 characters long"
    ),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("newPassword"), null],
      "New & confirm password must be same"
    ),
  });

  // Update student details.
  const onSubmit = async (values, { resetForm, isSubmitting }) => {
    try {
      loaderDispatch({
        isLoading: true,
      });

      let body = {
        student_name: values?.fullName.trim(),
        password: values?.newPassword,
        profile_picture: profile_picture
          ? profile_picture
          : userInfo?.filtered_image,
        dob: value,
      };

      if (typeof profile_picture === "object") {
        body.profile_picture = await handelProfileImg(body.profile_picture);
      }

      let formData = new FormData();
      for (const key in body) {
        formData.append(key, body[key]);
      }
      if (values?.newPassword === values?.confirmPassword) {
        setPasswordError(false);
        let result = await updateStudentDetail(formData, userInfo?.id);
        loaderDispatch({
          isLoading: false,
        });
        if (result?.data?.status === StatusCodes.OK) {
          toast(result?.data?.message, {
            type: "success",
            theme: "dark",
          });
          resetForm();

          props.getinfo();
        } else {
          toast(result?.data?.message, {
            type: "error",
            theme: "dark",
          });
        }
      } else {
        setPasswordError(true);
        loaderDispatch({
          isLoading: false,
        });
      }
    } catch (error) {
      console.error(error);
    }

    isSubmitting(true);
  };

  const handelProfileImg = async (file) => {
    if (file?.size / 1024 / 1024 > 4) {
      const options = {
        maxSizeMB: 4,
        // maxWidthOrHeight: 1920,
        // alwaysKeepResolution: true,
      };
      try {
        loaderDispatch({
          isLoading: true,
        });
        const compressedFile = await imageCompression(file, options);
        console.log("compressedFileSize", compressedFile.size / 1024 / 1024);
        return compressedFile;
      } catch (error) {
        console.log(error);
        loaderDispatch({
          isLoading: false,
        });
      }
    } else {
      return file;
    }
  };

  // handelCancel is called when the user clicks on cancel
  const handelCancel = () => {
    setprofile_picture();
    props.handleClick("my_subscription_plan");
  };

  const handelError = () => {
    setPasswordError(false);
  };

  return (
    <EditProfileRow className='tab_body'>
      <ul className='tab_list'>
        <li
          className='tab_item'
          onClick={() => props.handleClick("my_subscription_plan")}>
          My Subscription Plan
        </li>
        <li className='tab_item active'>Edit Profile</li>
        {props.metaMaskShow && (
          <li
            className='tab_item'
            onClick={() => props.handleClick("meta_mask_wallet")}>
            Meta Mask Wallet
          </li>
        )}
        <li className='tab_item' onClick={() => props.handleClick("id")}>
          ID
        </li>
      </ul>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form>
            <SiteForm as='div' className='profile_form'>
              <div className='upload_file'>
                <div className='file'>
                  <img
                    src={
                      profile_picture
                        ? URL.createObjectURL(profile_picture)
                        : userInfo?.filtered_image
                    }
                    alt='profile_image'
                  />
                  <div
                    className={
                      userInfo?.standard?.toLowerCase() !== GRADUATED &&
                      userInfo?.is_plan_expired !== "1"
                        ? "camera_icon"
                        : ""
                    }
                    onClick={
                      userInfo?.standard?.toLowerCase() !== GRADUATED &&
                      userInfo?.is_plan_expired !== "1"
                        ? chooseFileBox
                        : ""
                    }>
                    {userInfo?.standard?.toLowerCase() !== GRADUATED &&
                      userInfo?.is_plan_expired !== "1" && (
                        <svg
                          width='12'
                          height='12'
                          viewBox='0 0 12 12'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'>
                          <g clip-path='url(#clip0_2749_178073)'>
                            <path
                              d='M10.3249 3.03433H9.13868L8.48376 2.04577C8.23662 1.67506 7.80412 1.45264 7.35927 1.45264H4.64074C4.19589 1.45264 3.76339 1.67506 3.51625 2.04577L2.86133 3.03433H1.67506C0.921288 3.03433 0.315796 3.63982 0.315796 4.3936V9.18811C0.315796 9.94188 0.921288 10.5474 1.67506 10.5474H10.3249C11.0787 10.5474 11.6842 9.94188 11.6842 9.18811V4.3936C11.6842 3.63982 11.0787 3.03433 10.3249 3.03433ZM6.00001 9.43524C4.3071 9.43524 2.93548 8.06362 2.93548 6.37071C2.93548 4.67781 4.3071 3.31854 6.00001 3.31854C7.69291 3.31854 9.06454 4.69017 9.06454 6.38307C9.06454 8.06362 7.69291 9.43524 6.00001 9.43524ZM10.152 4.94966C10.1396 4.94966 10.1272 4.94966 10.1025 4.94966H9.60824C9.38582 4.9373 9.21282 4.75195 9.22518 4.52952C9.23753 4.31946 9.39817 4.15881 9.60824 4.14646H10.1025C10.3249 4.1341 10.5103 4.3071 10.5227 4.52952C10.535 4.75195 10.3744 4.9373 10.152 4.94966Z'
                              fill='white'
                            />
                            <path
                              d='M6.00006 4.67761C5.06093 4.67761 4.2948 5.44375 4.2948 6.38288C4.2948 7.32201 5.06093 8.07578 6.00006 8.07578C6.93919 8.07578 7.70533 7.30965 7.70533 6.37052C7.70533 5.43139 6.93919 4.67761 6.00006 4.67761Z'
                              fill='white'
                            />
                          </g>
                          <defs>
                            <clipPath id='clip0_2749_178073'>
                              <rect
                                width='11.3684'
                                height='11.3684'
                                fill='white'
                                transform='translate(0.315796 0.315796)'
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      )}
                    <input
                      type='file'
                      ref={inputFile}
                      className='hidden'
                      accept='image/*'
                      onChange={(e) => {
                        if (e?.target?.files)
                          setprofile_picture(e?.target?.files[0]);
                      }}
                    />
                  </div>
                </div>
                <Paragraph cname='file_name' pData={"Profile Photo"} />
              </div>
              <div className='grid md:gap-7 md:grid-cols-2 items-center form_label_group'>
                <div className='form_group'>
                  <Label labelData={"Full Name"} />
                  <Field
                    type='text'
                    name='fullName'
                    placeholder='Enter your first name'
                    autoComplete='off'
                  />
                  <div className='text-sm  text-red-500'>
                    <ErrorMessage name={"fullName"} as='div' />
                  </div>
                </div>
                <div className='form_group'></div>
              </div>
              <div className='grid md:gap-7 md:grid-cols-2 items-center form_label_group'>
                <div className='form_group pass_group'>
                  <Label labelData={"New Password"} />
                  <Field
                    type={passwordShown ? "text" : "password"}
                    name='newPassword'
                    placeholder='Enter your password'
                    autoComplete='off'
                  />
                  <button
                    type='button'
                    onClick={togglePassword}
                    className='eye_btn'></button>
                  <div className='text-sm  text-red-500 absolute'>
                    <ErrorMessage name={"newPassword"} as='div' />
                  </div>
                </div>
                <div className='form_group pass_group'>
                  <Label labelData={"Confirm Password"} />
                  <Field
                    type={passwordShown2 ? "text" : "password"}
                    name='confirmPassword'
                    placeholder='Confirm password'
                    autoComplete='off'
                    onKeyDown={handelError}
                  />
                  <button
                    type='button'
                    onClick={togglePassword2}
                    className='eye_btn'></button>
                  <div className='text-sm  text-red-500 absolute'>
                    <ErrorMessage name={"confirmPassword"} as='div' />
                  </div>
                  {passwordError && (
                    <div className='text-sm  text-red-500 absolute'>
                      <p>Please fill the filed</p>
                    </div>
                  )}
                </div>
              </div>

              <div className='grid md:gap-7 md:grid-cols-2 items-center form_label_group'>
                <div className='form_group'>
                  <Label labelData={"Birth Date*"} />
                  <CustomDatePicker>
                    <DatePicker
                      onChange={onChange}
                      // onCalendarClose={handelAgeVerification}
                      value={value}
                      maxDate={new Date()}
                    />
                  </CustomDatePicker>
                  <div className='text-sm  text-red-500'>
                    <ErrorMessage name={"fullName"} as='div' />
                  </div>
                </div>
              </div>

              <div className='btn_group'>
                <Button
                  tag='button'
                  type='submit'
                  cname='gradient btn'
                  buttonData={"Save"}
                  disabled={isSubmitting}
                />
                <Button
                  tag='button'
                  type='button'
                  cname='outline btn'
                  buttonData={"Cancel"}
                  disabled={isSubmitting}
                  onClick={handelCancel}
                />
              </div>
            </SiteForm>
          </Form>
        )}
      </Formik>
    </EditProfileRow>
  );
}

export default EditProfile;
