import React, { useState, useEffect, useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../../../components/website/checkoutForm_strip/CheckoutForm";
import "./stripPayment.css";
import { useParams } from "react-router";
import { initialPayment } from "../../../services/auth.services";
import { StatusCodes } from "http-status-codes";
import { loaderContext } from "../../../components/global loader/global-loader-provider";
import { toast } from "react-toastify";

// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe(
  "pk_test_51BTUDGJAJfZb9HEBwDg86TN1KNprHjkfipXmEDMb0gSCassK5T3ZfxsAbcgKVmAIXF7oZ6ItlZZbXO6idTHE67IM007EwQ4uN3"
);

function StripePayment() {
  const [clientSecret, setClientSecret] = useState("");
  let { amount, plan_type, id } = useParams();
  const { loaderDispatch } = useContext(loaderContext);

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    // fetch("/create-payment-intent", {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
    // })
    //   .then((res) => res.json())
    //   .then((data) => setClientSecret(data.clientSecret));

    getInitalPayment();
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  const getInitalPayment = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let body = {
        amount: amount,
      };
      let result = await initialPayment(body);
      loaderDispatch({
        isLoading: false,
      });
      console.log("resultPayment", result?.data?.status);
      if (result?.data?.status === StatusCodes.OK) {
        let data = result?.data?.data?.client_secret;
        setClientSecret(data);
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console?.error(error);
    }
  };
  return (
    <>
      <div className='h-screen w-full bg-white flex justify-center items-center'>
        {clientSecret && (
          <div className='form-containers'>
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm plan_type={plan_type} amount={amount} id={id} />
            </Elements>
          </div>
        )}
      </div>
    </>
  );
}

export default StripePayment;
