import React, { useContext } from "react";
import { Dna } from "react-loader-spinner";
import { loaderContext } from "./global-loader-provider";

const ShowLoader = () => {
  const { loaderState } = useContext(loaderContext);

  return (
    <>
      {loaderState.isLoading ? (
        <div className="loader-overlay">
          <div className="loader">
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="DNA-loading"
              wrapperClassName="dna-wrapper"
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ShowLoader;
