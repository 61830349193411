import React from "react";
import styled from "styled-components";

import { colors, space, siteGradient } from "./Variables.js";
import { device } from "./Devices.js";

const H1 = styled.h1`
  font-family: "Inter";
  font-size: 2.1rem;
  font-weight: 900;
  line-height: 3.1rem;
  position: relative;

  color: ${colors.primary};

  // Spacing
  margin-bottom: ${space.space8};

  @media ${device.mobileL} {
    font-size: 2.125rem;
    line-height: 3.188rem;
  }

  @media ${device.tablet} {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }

  @media ${device.laptop} {
    font-size: 3.438rem;
    line-height: 4.313rem;
  }

  // Gradient
  &.gradient {
    background: ${siteGradient.bluePink};
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-flex;
  }
`;

function HeadingOne(props) {
  return (
    <>
      <H1 className={props.cname}>{props.h1Data}</H1>
    </>
  );
}
export default HeadingOne;
