import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import logo from "../../../images/logo.svg";
import { student, website } from "../../../linksprovider";
import { paymentDone } from "../../../services/auth.services";
import { ADMIN_USER_DATA } from "../../../utils/constant";
import { StatusCodes } from "http-status-codes";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function CheckoutForm({ plan_type, amount, id }) {
  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }
  let navigate = useNavigate();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          changePaymentDone(paymentIntent);
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${process.env.REACT_APP_DEV_SERVER}/checkout/${amount}/${plan_type}/${id}`,
      },
    });
    // changePaymentDone(paymentIntent);

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage(error.message);
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  const changePaymentDone = async (paymentIntent) => {
    try {
      let body = {
        transaction_id: paymentIntent?.id,
        plan_type: plan_type,
        user_id: userData?.id,
        amount,
        subscription_id: id,
      };
      let result = await paymentDone(body);
      if (result?.data?.status === StatusCodes.OK) {
        navigate(student.dashbord);
        toast(result?.data?.message, {
          type: "success",
          theme: "dark",
        });
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className='flex justify-center'>
        <img src={logo} alt='' />
      </div>
      <form id='payment-form' onSubmit={handleSubmit} className='mt-10'>
        <h1 className='mb-2 text-gray-700 font-semibold text-md'>
          Use Card to Pay..!!
        </h1>
        <LinkAuthenticationElement
          id='link-authentication-element'
          onChange={(e) => setEmail(e.target.value)}
        />
        <PaymentElement id='payment-element' options={paymentElementOptions} />
        <button
          disabled={isLoading || !stripe || !elements}
          id='submit'
          className='button_pay'>
          <span id='button-text'>
            {isLoading ? (
              <div className='spinner' id='spinner'></div>
            ) : (
              `Pay $${amount}`
            )}
          </span>
        </button>
        {/* Show any error or success messages */}
        {message && <div id='payment-message'>{message}</div>}
      </form>
    </>
  );
}
