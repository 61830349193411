import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { SchoolContext } from "../../../common/SchoolDashboardWrapper.jsx";
import { ADMIN_USER_DATA, NFT } from "../../../utils/constant";
import SearchFilter from "../../search_filter/SearchFilter.jsx";
import ListLayout from "../../shared/listLayout.jsx";
import SelectFilter from "../../school_select_filter/SelectFilter.jsx";
import ReactPaginate from "react-paginate";
import { loaderContext } from "../../global loader/global-loader-provider";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import { JobUserListing } from "../../../services/auth.services.js";
import { useParams } from "react-router-dom";

function JobUserAppliedDetails() {
  const [searchItem, setSearchItem] = useState("");
  const [itemOffset, setItemOffset] = useState(1);
  const [sortby, setSortby] = useState("");
  const [orderby, setOrderby] = useState(true);
  const [currentItems, setCurrentItems] = useState(null);
  const [data, setData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [dataCount, setDataCount] = useState(0);

  const { setAdminActiveTab } = useContext(SchoolContext);
  const { loaderDispatch } = useContext(loaderContext);
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  const { id } = useParams();

  const tableHeader = [
    {
      key: "S.No.",
      value: "key",
      clickable: false,
      sorting: false,
    },
    {
      key: "Name",
      value: "User",
      subValue: "student_name",
      clickable: false,
      sorting: true,
    },
    {
      key: "email",
      value: "User",
      subValue: "email",
      clickable: false,
      sorting: true,
    },
    {
      key: "Applied date",
      value: "createdAt",
      clickable: false,
      sorting: true,
    },

    {
      key: "status",
      value: "User",
      subValue: "status",
      clickable: false,
      sorting: true,
    },
  ];

  const itemsOnPage = [
    { label: 10, value: "10" },
    { label: 20, value: "20" },
    { label: 50, value: "50" },
    { label: 70, value: "70" },
    { label: 100, value: "100" },
  ];

  useLayoutEffect(() => {
    setAdminActiveTab(NFT);
  }, []);
  useEffect(() => {
    const debounce = setTimeout(() => {
      handelJobList();
    }, 300);
    return () => clearTimeout(debounce);
  }, [searchItem, sortby, orderby, itemOffset, itemsPerPage]);

  useEffect(() => {
    setCurrentItems(data);
    setPageCount(dataCount);
  }, [data, dataCount, itemsPerPage]);

  const handleSorting = (data, order, subData) => {
    console.log(data, order);

    setSortby(data);

    setOrderby(order);
  };

  const handlePageClick = (event) => {
    setItemOffset(event.selected + 1);
  };

  const handelJobList = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let order = orderby ? "desc" : "asc";
      let params = {
        id: userData?.id,
        search: searchItem,
        page: itemOffset,
        sort_by: sortby,
        orderBy: order,
        limit: itemsPerPage,
      };

      let result = await JobUserListing(id, params);
      loaderDispatch({
        isLoading: false,
      });

      if (result?.data?.status === StatusCodes.OK) {
        const data = result?.data?.data?.appliedUserListing?.rows;
        const count = result?.data?.data?.totalPage;

        setData(data);
        setDataCount(count);
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className='p-2 grid grid-cols-2 gap-2 mt-1'>
        <div className=' flex justify-center'>
          <SearchFilter
            setSearchItem={setSearchItem}
            searchItem={searchItem}
            setPage={setItemOffset}
            cname={
              " block w-full border border-gray-300 rounded-md shadow py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
            }
          />
        </div>
      </div>
      <ListLayout
        // handleOptions={handleOptions}
        tableHeader={tableHeader}
        handleSorting={handleSorting}
        currentItems={currentItems}
        isUser={true}
      />
      <div className='flex justify-between'>
        {data?.length > 0 && (
          <>
            <div
              className='block lg:inline-block lg:mt-0'
              style={{ marginTop: "6px" }}>
              <label htmlFor='item' className='ml-5'>
                Record Per Page:
              </label>
              <SelectFilter
                options={itemsOnPage}
                selectCls={"ml-3 bg-white border p-2 rounded"}
                name={"item"}
                id={"item"}
                onChange={(e) => {
                  setItemsPerPage(parseInt(e.target.value));
                }}
              />
            </div>

            <div className='mt-2 mb-10'>
              <ReactPaginate
                breakLabel='...'
                nextLabel='Next >'
                onPageChange={handlePageClick}
                pageCount={pageCount}
                previousLabel='< Previous'
                renderOnZeroPageCount={null}
                containerClassName={"pagination"}
                pageClassName={"pageClass"}
                forcePage={itemOffset - 1}
              />
            </div>
          </>
        )}
      </div>
      {data?.length === 0 && (
        <div className='flex flex-row absolute left-1/2 top-1/2'>
          <p>Data not found..!!</p>
        </div>
      )}
    </>
  );
}

export default JobUserAppliedDetails;
