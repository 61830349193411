import React, { useState } from "react";
import styled from "styled-components";

import DigitalAdjust from "./DigitalAdjust.jsx";
import DigitalFilters from "./DigitalFilters.jsx";
import { colors, space } from "./Variables";
import { device } from "./Devices";

const DigitalArtFilterListRow = styled.div`
  background: ${colors.body};
  border-radius: ${space.space16};
  height: 100%;
  width: 100%;
  flex-shrink: 0;
  .tab_list {
    display: flex;
    align-items: center;
    padding: ${space.space16};
    border-bottom: 1px solid ${colors.lightwhiteborder};
    .tab_item {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.625rem;
      color: ${colors.lightgrey2};
      margin-right: 24px;
      cursor: pointer;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        background: ${colors.white};
        width: 0%;
        height: 1px;
        bottom: -17px;
        transition: all 0.4s;
      }
      &.active {
        &::before {
          width: 100%;
        }
      }
      &:hover {
        color: ${colors.white};
        &::before {
          width: 100%;
          transition: all 0.4s;
        }
      }
      &.active {
        color: ${colors.white};
      }
      &:last-child {
        margin: 0;
      }
    }
  }
  .digital_body {
    padding: ${space.space16};
    overflow-y: auto;
    max-height: 210px;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(172 172 172 / 10%);
      border-radius: 8px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    /* Fire Fox */
    scrollbar-color: rgb(172 172 172 / 10%) transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: transparent;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(172 172 172 / 10%);
      border-radius: 8px;
    }
    /* End Fire Fox */
  }
  @media ${device.mobileM} {
    .digital_body {
      padding: ${space.space30} ${space.space16} ${space.space16};
      max-height: 160px;
    }
  }
  @media ${device.tablet} {
    width: 220px;
    .digital_body {
      max-height: calc(100vh - 270px);
    }
  }
  @media ${device.laptop} {
    width: 270px;
  }
  @media ${device.laptopL} {
    .digital_body {
      max-height: calc(100vh - 294px);
    }
  }
`;

function DigitalArtFilterList(props) {
  const [game, setGame] = useState("digital_adjust");
  const handleClick = (gameState) => {
    setGame(gameState);
  };

  return (
    <>
      <DigitalArtFilterListRow className={props.cname}>
        {(() => {
          switch (game) {
            case "digital_adjust":
              return <DigitalAdjust handleClick={handleClick} />;
            case "digital_filters":
              return <DigitalFilters handleClick={handleClick} />;
            default:
              return null;
          }
        })()}
      </DigitalArtFilterListRow>
    </>
  );
}

export default DigitalArtFilterList;
