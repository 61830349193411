// globalStyles.js
import React from "react";
import { Outlet } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { colors } from "./common/website/Variables";
import FontStyles from "./common/website/FontFaceKit";

const GlobalStyle = createGlobalStyle`
  body {    
    font-family: 'Inter';
    margin: 0;  
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      background-color:${colors.body};
      color:${colors.white};
  }
  .sticky{
    background-color:${colors.body};
    box-shadow: 0 3px 10px 4px rgb(0 0 0 / 20%);
  }
  :focus-visible{
    outline: none;
  }
  a,a:hover,button,button:hover{
    transition: all .3s ease-in-out;
  }
`;

function GlobalStyles() {
  return (
    <>
      <GlobalStyle />
      <FontStyles />
      <Outlet />
    </>
  );
}

export default GlobalStyles;
