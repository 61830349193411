import { StatusCodes } from "http-status-codes";
import React, { createContext, useContext, useState } from "react";
import { Outlet } from "react-router-dom";
import { loaderContext } from "../components/global loader/global-loader-provider.js";
import SchoolSidebar from "../components/schools/school sidebar/schoolSidebar.jsx";
import { viewSchoolDetails } from "../services/auth.services.js";
import { ADMIN_USER_DATA } from "../utils/constant.js";

export const SchoolContext = createContext(null);
function SchoolDashboardWrapper() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [adminActiveTab, setAdminActiveTab] = useState("Dashboard");
  const [open, setOpen] = useState(false);
  const [currentAddress, setCurrentAddress] = useState("");
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [adminData, setadminData] = useState({});

  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }

  const { loaderDispatch } = useContext(loaderContext);

  const getSchoolProfile = async () => {
    loaderDispatch({
      isLoading: true,
    });
    let result = await viewSchoolDetails(userData.id);

    loaderDispatch({
      isLoading: false,
    });

    if (result?.data?.status === StatusCodes.OK) {
      if (result?.data?.data) {
        setadminData(result?.data?.data);
      }
    }
  };

  return (
    <div className='grid lg:grid-cols-6 h-screen'>
      <SchoolContext.Provider
        value={{
          sidebarOpen,
          setSidebarOpen,
          adminActiveTab,
          setAdminActiveTab,
          setOpen,
          open,
          currentAddress,
          setCurrentAddress,
          setCreateModalOpen,
          createModalOpen,
          getSchoolProfile,
          adminData,
        }}>
        {/* <div> */}
        <SchoolSidebar />
        {/* </div> */}
        <div className='lg:col-span-5 bg-white'>
          <Outlet />
        </div>
      </SchoolContext.Provider>
    </div>
  );
}

export default SchoolDashboardWrapper;
