import React, { Fragment, useContext, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import logo from "../../images/logo.svg";
import { HiSelector, HiOutlineXCircle, HiTemplate } from "react-icons/hi";
import { RiArrowDropDownLine } from "react-icons/ri";
import { SiCraftcms, SiHackclub } from "react-icons/si";
import { FaSchool } from "react-icons/fa";
import { RiMoneyDollarBoxFill } from "react-icons/ri";
import { AiFillDashboard, AiFillSetting } from "react-icons/ai";
import { IoIosSchool, IoMdAnalytics } from "react-icons/io";
import { AdminContext } from "../../common/DashboardWrapper";
import { admin } from "../../linksprovider.js";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  ADMIN_USER_DATA,
  CLUB,
  CMS,
  DASHBOARD,
  EMAIL_TEMPLATES,
  PLANS,
  REPORTS,
  SCHOOL,
  SCHOOL_SPORTS_CLUB,
  SETTING,
  STUDENT,
} from "../../utils/constant";
import { MdSportsFootball } from "react-icons/md";

function AdminSideBar() {
  let navigate = useNavigate();
  const { sidebarOpen, setSidebarOpen, setAdminActiveTab } =
    useContext(AdminContext);

  // let { userData, setUserData } = useContext(AppContext);
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }

  let location = useLocation();

  const { getData, adminData } = useContext(AdminContext);
  const [navigation, setNavigation] = useState([
    {
      name: DASHBOARD,
      href: admin.dashbord,
      icon: AiFillDashboard,
      current: false,
    },
    {
      name: SCHOOL,
      href: admin.school,
      icon: FaSchool,
      current: false,
    },
    {
      name: STUDENT,
      href: admin.student,
      icon: IoIosSchool,
      current: false,
    },
    {
      name: CLUB,
      href: admin.club,
      icon: SiHackclub,
      current: false,
    },
    {
      name: SCHOOL_SPORTS_CLUB,
      href: admin.sports,
      icon: MdSportsFootball,
      current: false,
    },
    {
      name: PLANS,
      href: admin.plans,
      icon: RiMoneyDollarBoxFill,
      current: false,
    },
    {
      name: CMS,
      href: admin.Cms,
      icon: SiCraftcms,
      current: false,
    },
    {
      name: EMAIL_TEMPLATES,
      href: admin.editor,
      icon: HiTemplate,
      current: false,
    },

    {
      name: REPORTS,
      href: admin.report,
      icon: IoMdAnalytics,
      current: false,
    },
    {
      name: SETTING,
      href: admin.setting,
      icon: AiFillSetting,
      current: false,
    },
  ]);

  let count = 0;
  useEffect(() => {
    if (count === 0) {
      getData();
    }
    setNavigation((preVal) => {
      for (let j = 0; j < preVal.length; j++) {
        preVal[j].active = false;
      }
      for (let j = 0; j < preVal.length; j++) {
        if (
          preVal[j].href === location?.pathname ||
          location?.pathname
            .toLocaleLowerCase()
            .includes(preVal[j].name.toLocaleLowerCase())
        ) {
          preVal[j].active = true;
        }
      }
      return preVal;
    });
    count++;
  }, [location]);

  const handelLogout = () => {
    sessionStorage.clear();
    navigate(admin.login);
  };

  const handelClick = (e, i) => {
    setAdminActiveTab(e.target.getAttribute("data-value"));
  };

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 flex z-40 lg:hidden"
          open={sidebarOpen}
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <HiOutlineXCircle
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex items-center px-4">
                <img className="h-14 w-auto" src={logo} alt="Workflow" />
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <nav className="px-2">
                  <div className="space-y-1">
                    {navigation?.map((item, i) => (
                      <Link
                        key={item.name}
                        data-value={item.name}
                        to={item.href}
                        className={
                          item.active
                            ? "bg-gray-200 text-gray-900 group flex items-center px-2 py-2 text-base leading-5 font-medium rounded-md"
                            : "text-gray-600 hover:text-gray-900 hover:bg-gray-50 group flex items-center px-2 py-2 text-base leading-5 font-medium rounded-md"
                        }
                        aria-current={item.current ? "page" : undefined}
                        onClick={(e) => handelClick(e, i)}
                      >
                        <item.icon
                          className={
                            item.active
                              ? "text-gray-500 mr-3 flex-shrink-0 h-6 w-6"
                              : "text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6"
                          }
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:flex lg:flex-shrink-0">
        <div
          className="flex flex-col w-64 border-r border-gray-200 pt-5 bg-gray-100"
          style={{ width: "-webkit-fill-available" }}
        >
          <div className="flex items-center flex-shrink-0 px-6">
            <img className=" w-auto" src={logo} alt="logo" />
          </div>
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="h-0 flex-1 flex flex-col overflow-y-auto">
            {/* User account dropdown */}
            <Menu
              as="div"
              className="px-3 mt-6 relative inline-block text-left"
            >
              {({ open }) => (
                <>
                  <div>
                    <Menu.Button className="group w-full bg-gray-100 rounded-md px-3.5 py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500">
                      <span className="flex w-full justify-between items-center">
                        <span className="flex min-w-0 items-center justify-between space-x-3">
                          <img
                            className="w-12 h-12 bg-gray-300 rounded-full flex-shrink-0"
                            src={
                              adminData?.profile_picture
                                ? adminData?.profile_picture
                                : "https://t3.ftcdn.net/jpg/01/09/00/64/360_F_109006426_388PagqielgjFTAMgW59jRaDmPJvSBUL.jpg"
                            }
                            alt=""
                          />
                          <span className="flex-1 flex flex-col min-w-0">
                            <span className="text-gray-900 text-sm font-medium truncate">
                              {adminData.first_name}
                            </span>
                            <span className="text-gray-500 text-sm truncate">
                              {adminData.last_name}
                            </span>
                          </span>
                        </span>
                        <RiArrowDropDownLine
                          className="flex-shrink-0 h-5 w-5 text-lg text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      </span>
                    </Menu.Button>
                  </div>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="z-10 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
                    >
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={admin.profile}
                              className={
                                "text-gray-700 hover:bg-gray-100 hover:text-gray-900 block px-4 py-2 text-sm"
                              }
                            >
                              View profile
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={admin.editProfile}
                              className={
                                "text-gray-700 hover:bg-gray-100 hover:text-gray-900 block px-4 py-2 text-sm"
                              }
                            >
                              Edit Profile
                            </Link>
                          )}
                        </Menu.Item>
                      </div>

                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <label
                              onClick={handelLogout}
                              className={
                                "text-gray-700 hover:bg-gray-100 hover:text-gray-900 block px-4 py-2 text-sm"
                              }
                            >
                              Logout
                            </label>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>

            {/* Navigation */}
            <nav className="px-3 mt-6">
              <div className="space-y-1">
                {navigation.map((item, i) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    data-value={item.name}
                    className={
                      item.active
                        ? "bg-white text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                        : "text-gray-700 hover:text-gray-900 hover:bg-gray-50group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                    }
                    aria-current={item.current ? "page" : undefined}
                    onClick={(e) => handelClick(e, i)}
                  >
                    <item.icon
                      className={
                        item.active
                          ? "text-gray-500 mr-3 flex-shrink-0 h-6 w-6"
                          : "text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6"
                      }
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                ))}
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminSideBar;
