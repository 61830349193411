import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../../images/logo.svg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { adminForgetPassword } from "../../services/auth.services";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import { admin } from "../../linksprovider";
import { IoEyeSharp } from "react-icons/io5";
import { BsEyeSlashFill } from "react-icons/bs";
import { loaderContext } from "../global loader/global-loader-provider";

function AdminForgetPassword() {
  let navigate = useNavigate();
  let { u_id } = useParams();

  const [newPassword, setNewPassword] = useState({
    password: "",
    confirm_password: "",
  });
  const [passwordHideAndShow, setPasswordHideAndShow] = useState({
    password: false,
    confirm_password: false,
  });

  const { loaderDispatch } = useContext(loaderContext);

  const validadtion = Yup.object({
    password: Yup.string()
      .required("Please fill the field ")
      .min(4, "Password must be at least 4 characters long"),
    confirm_password: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        "New and confirm password must be same"
      )
      .required("Please fill the field"),
  });
  const inputFields = [
    {
      name: "password",
      id: "password",
      label: "New Password",
      typePassword: "password",
      typeText: "text",
    },
    {
      name: "confirm_password",
      id: "confirm_password",
      label: "Confirm Password",
      typePassword: "password",
      typeText: "text",
    },
  ];

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      delete values.confirm_password;
      let result = await adminForgetPassword(values, u_id);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
        });
        navigate(admin.login);
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }

    setSubmitting(false);
  };

  const handelPassWordHideAndShow = (id) => {
    setPasswordHideAndShow((preVal) => {
      return {
        ...preVal,
        [id]: !preVal[id],
      };
    });
  };
  return (
    <div className='min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
      <div className='sm:mx-auto sm:w-full sm:max-w-md'>
        <img className='mx-auto h-12 w-auto' src={logo} alt='Workflow' />
        <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
          Reset Password
        </h2>
      </div>

      <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
        <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
          <Formik
            onSubmit={onSubmit}
            initialValues={newPassword}
            validationSchema={validadtion}>
            {({ isSubmitting }) => (
              <Form className='space-y-6'>
                <div>
                  {inputFields.map((val, i) => {
                    return (
                      <div key={i} className='mt-3'>
                        <label
                          htmlFor={val.id}
                          className='block text-sm font-medium text-gray-700'>
                          {val.label}
                        </label>
                        <div className='mt-1 flex items-center border border-gray-300 rounded-md shadow-sm px-3 py-2 '>
                          <Field
                            id={val.id}
                            name={val.name}
                            type={
                              passwordHideAndShow?.[val.id]
                                ? val.typeText
                                : val.typePassword
                            }
                            autoComplete='off'
                            className='appearance-none block w-full   placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                          />
                          {passwordHideAndShow?.[val.id] ? (
                            <BsEyeSlashFill
                              className='text-lg text-gray-400 cursor-pointer'
                              onClick={() => handelPassWordHideAndShow(val.id)}
                            />
                          ) : (
                            <IoEyeSharp
                              className='text-lg text-gray-400 cursor-pointer'
                              onClick={() => handelPassWordHideAndShow(val.id)}
                            />
                          )}
                        </div>

                        <div className='text-red-600 text-sm'>
                          <ErrorMessage name={val.name} as='div' />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div>
                  <button
                    type='submit'
                    disabled={isSubmitting}
                    className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                    Reset
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default AdminForgetPassword;
