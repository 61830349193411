import React, { useState } from "react";
import styled from "styled-components";

import { space } from "./Variables";

import IconComment from "../../images/website/icon_comment.svg";

const CommentsRow = styled.div`
  .comment_link {
    display: flex;
    align-items: center;
    grid-gap: ${space.space8};
    cursor: pointer;
    color: white;
    span {
      font-size: 0.75rem;
      line-height: 0.938rem;
      vertical-align: middle;
    }
  }
`;

function Comments() {
  return (
    <>
      <CommentsRow>
        <span className='comment_link'>
          <img src={IconComment} alt='icon comment' />
          <span>Comments</span>
        </span>
      </CommentsRow>
    </>
  );
}
export default Comments;
