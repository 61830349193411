import React, { useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";

import { SiteCard, SiteForm } from "../../../common/website/HOC.jsx";
import { device } from "../../../common/website/Devices";
import { colors, space, siteGradient } from "../../../common/website/Variables";
import ChatList from "../../../common/website/ChatList.jsx";
import Paragraph from "../../../common/website/Paragraph.jsx";

import ProfileImage from "../../../images/website/profile_new_image.png";
import ProfileImage2 from "../../../images/website/chat_DP.png";
import IconDots from "../../../images/website/icon_dots.svg";
import IconDelete from "../../../images/website/icon_delete.svg";
import IconReport from "../../../images/website/icon_report.svg";
import ProfileImage3 from "../../../images/website/profile_image3.png";
import ProfileImage4 from "../../../images/website/profile_image4.png";
import ProfileImage5 from "../../../images/website/profile_image5.png";
import ProfileImage6 from "../../../images/website/profile_image6.png";
import ProfileImage7 from "../../../images/website/profile_image7.png";
import ProfileImage8 from "../../../images/website/profile_image8.png";
import ProfileImage9 from "../../../images/website/profile_image9.png";
import IconChatBack from "../../../images/website/icon_chat_back.svg";
import EmptyChat from "../../../images/website/empty-chat.png";

import { BsEmojiSmile } from "react-icons/bs";
import {
  chatList,
  getSubscriptionDetail,
  listChatUsers,
  sendMessage,
} from "../../../services/auth.services.js";
import { ADMIN_USER_DATA, GRADUATED } from "../../../utils/constant.js";
import { StatusCodes } from "http-status-codes";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { toast } from "react-toastify";
import { loaderContext } from "../../../components/global loader/global-loader-provider.js";
import dateDifference from "date-difference";
import { PostContext } from "../../../components/website/header/MainHeader.jsx";
import blankProfile from "../../../images/website/dummy_user_profile.svg";
import HeadingThree from "../../../common/website/HeadingThree.jsx";

const NotificationRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  h3,
  h4,
  p {
    position: static;
  }
  .body_full_screen {
    width: 100%;
    height: auto;
    .full_screen_card {
      display: flex;
      align-items: flex-start;
      width: 100%;
      border-radius: ${space.space16};
      padding: 0;
      height: 100%;
      position: relative;
      .left {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 99;
        background: ${colors.darkgrey};
        border-radius: ${space.space16};
        transform: scale(0.5);
        visibility: hidden;
        opacity: 0;
        transition: all 0.4s;
        &.active {
          transform: scale(1);
          visibility: visible;
          opacity: 1;
          transition: all 0.4s;
        }
      }
      .right {
        width: 100%;
        height: calc(100vh - 140px);
        .chat_list_form {
          padding: ${space.space12} ${space.space12} 0;
          input[type="search"] {
            border: 1px solid rgb(255 255 255 / 20%);
          }
        }
        .messages_head {
          font-weight: 600;
          padding: 0 ${space.space12};
          margin: ${space.space16} 0;
          color: ${colors.lightgrey2};
          position: relative;
          &::before {
            content: "";
            position: absolute;
            bottom: -4px;
            background: ${colors.white};
            width: 100%;
            height: 1px;
            opacity: 0.1;
            left: 0;
          }
          span {
            display: inline-block;
            margin-right: ${space.space16};
            cursor: pointer;
            position: relative;
            &::before {
              content: "";
              position: absolute;
              bottom: -4px;
              left: 0;
              background: ${colors.white};
              width: 0%;
              height: 1px;
              border-radius: 1.25rem;
              -webkit-transition: all 0.4s;
              transition: all 0.4s;
            }
            &:hover {
              &::before {
                width: 100%;
              }
            }
            &:last-child {
              margin: 0;
            }
            &.active {
              color: ${colors.white};
              &::before {
                width: 100%;
              }
            }
          }
        }
        .classmates_list {
          padding: 0 ${space.space12};
          height: calc(100vh - 256px);
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
          }
          &::-webkit-scrollbar-thumb {
            background: rgb(172 172 172 / 10%);
            border-radius: 8px;
          }
          &::-webkit-scrollbar-track {
            background: transparent;
          }
          /* Fire Fox */
          scrollbar-color: rgb(172 172 172 / 10%) transparent;
          scrollbar-width: thin;
          &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
            background-color: transparent;
            border-radius: 8px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: rgb(172 172 172 / 10%);
            border-radius: 8px;
          }
          /* End Fire Fox */
          .list_item {
            margin-bottom: ${space.space20};
            &:last-child {
              margin: 0;
            }
            .link {
              display: flex;
              align-items: center;
              grid-gap: ${space.space8};
              padding: ${space.space8};
              border-radius: ${space.space8};
              &:hover,
              &.active {
                background: ${colors.lightwhiteborder};
              }
              img {
                width: 34px;
                height: 34px;
                border-radius: 100%;
                object-fit: cover;
              }
              .detail {
                width: 100%;
                position: relative;
                .name {
                  margin-bottom: 2px;
                  padding-right: 68px;
                }
                .messages_text,
                .time,
                .messages_count {
                  font-size: 0.75rem;
                  line-height: 0.938rem;
                  margin: 0;
                }
                .messages_text {
                  color: ${colors.lightgrey2};
                  padding-right: ${space.space24};
                }
                .name,
                .messages_text {
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                }
                .time {
                  position: absolute;
                  top: 4px;
                  right: 0;
                  color: ${colors.lightgrey};
                }
                .messages_count {
                  position: absolute;
                  bottom: 0;
                  right: 0;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 18px;
                  height: 18px;
                  border-radius: 100%;
                  background: ${colors.secondary};
                  color: ${colors.white};
                }

                .count {
                  position: absolute;
                  font-size: 10px;
                  line-height: 11px;
                  color: ${colors.white};
                  width: 20px;
                  height: 20px;
                  border-radius: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background: ${siteGradient.bluePink};
                  right: -2px;
                  top: 28px;
                }
              }
            }
          }
        }
      }
    }
  }
  .profile_head {
    display: flex;
    align-items: center;
    flex-direction: row;
    grid-gap: ${space.space16};
    border-bottom: 1px solid ${colors.lightwhiteborder};
    position: relative;
    padding: ${space.space12};
    padding-right: ${space.space30};
    &.hidden {
      display: none;
    }
    .profile_head_image {
      display: flex;
      align-items: center;
      grid-gap: ${space.space16};
      .mobile_back {
        background: url(${IconChatBack}) no-repeat center;
        background-size: contain;
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
    img {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      object-fit: cover;
      flex-shrink: 0;
    }
    .details {
      .name {
        color: ${colors.white};
        margin-bottom: 2px;
      }
      .duration {
        font-size: 0.75rem;
        line-height: 0.938rem;
        color: ${colors.primary};
        margin: 0;
      }
    }
    .menu_dropdown {
      position: absolute;
      right: 6px;
      top: 50%;
      height: 24px;
      transform: translateY(-50%);
      .dropdown_btn {
        display: inline-block;
        transform: rotate(90deg);
        img {
          width: 24px;
          height: 24px;
          object-fit: contain;
          border-radius: 0px;
        }
      }
      .menu_list {
        content: "";
        position: absolute;
        top: 100%;
        right: 0;
        background: ${colors.body};
        box-shadow: ${siteGradient.cardlightboxshadow};
        border-radius: ${space.space8};
        width: 120px;
        overflow: hidden;
        padding: ${space.space14} 0;
        z-index: 1;
      }
      .link {
        display: flex;
        align-items: center;
        grid-gap: ${space.space8};
        font-size: 0.875rem;
        line-height: 1.063rem;
        padding: ${space.space8} 22px;
        width: 100%;
        img {
          width: 14px;
          height: 14px;
          object-fit: contain;
          border-radius: 0px;
        }
        &:hover {
          background: ${colors.primary};
        }
      }
    }
  }
  .chat_list {
    padding: ${space.space12} ${space.space12};
    /* height: calc(100vh - 130px); */
    height: calc(100% - 70px);
    overflow-y: auto;
    &.hidden {
      display: none;
    }
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(172 172 172 / 10%);
      border-radius: 8px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    /* Fire Fox */
    scrollbar-color: rgb(172 172 172 / 10%) transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: transparent;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(172 172 172 / 10%);
      border-radius: 8px;
    }
    /* End Fire Fox */
  }
  .active.left {
    padding-bottom: 70px;
  }
  .chat_form {
    padding: 0 ${space.space12} 0;
    position: fixed;
    width: 100%;
    background-color: #171717;

    bottom: 0;
    &.hidden {
      display: none;
    }
    .form_group {
      border-top: 1px solid ${colors.lightwhiteborder};
      position: relative;
      padding: ${space.space20} 0;
      display: flex;
      align-items: center;
      input {
        border: none;
        padding: 0;
        padding-right: 52px;
      }
      .send_btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        width: 35px;
        height: 35px;
        border-radius: 100%;
        cursor: pointer;
        box-shadow: inset 0px 0px 10px rgba(48, 223, 242, 0.3);
        background: ${colors.lightblue};
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 1px 0 0;
        &:hover {
          box-shadow: inset 0px 0px 40px rgba(48, 223, 242, 0.3);
        }
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .empty-chat {
    padding: ${space.space20};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 100%;
    &.hidden {
      display: none;
    }
    h3 {
      margin: ${space.space24} 0 2px;
    }
    p {
      margin: 0 auto;
      max-width: 288px;
      color: ${colors.lightgrey2};
    }
  }
  @media ${device.mobileM} {
    .profile_head {
      padding: ${space.space24};
      img {
      }
      .menu_dropdown {
        right: 14px;
      }
    }
    .chat_list {
      height: calc(100vh - 162px);
      padding: ${space.space20} ${space.space24};
    }
    .chat_form {
      padding: 0 ${space.space24} 0;
      .form_group {
        padding: ${space.space24} 0;
      }
    }
    .body_full_screen {
      .full_screen_card {
        .left {
        }
        .right {
          .chat_list_form {
            padding: ${space.space24} ${space.space24} 0;
          }
          .messages_head {
            margin: ${space.space30} 0 ${space.space16};
            padding: 0 ${space.space24};
          }
          .classmates_list {
            padding: 0 ${space.space24};
            height: calc(100vh - 298px);
            .list_item {
              .link {
                img {
                  width: 40px;
                  height: 40px;
                }
                .detail {
                  margin-bottom: 6px;
                }
              }
            }
          }
        }
      }
    }
  }
  @media ${device.tablet} {
    width: 75%;
    .chat_form {
      .form_group {
        .send_btn {
          width: 44px;
          height: 44px;
          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
    .body_full_screen {
      padding-left: ${space.space20};
      height: calc(100vh - 40px);
      .full_screen_card {
        .left {
          position: absolute;
        }
        .right {
          height: auto;
          .classmates_list {
            height: calc(100vh - 194px);
          }
        }
      }
    }
    .chat_list {
      height: calc(100vh - 202px);
    }
  }
  @media ${device.laptop} {
    width: 77%;
    .body_full_screen {
      .full_screen_card {
        .left {
          transform: scale(1);
          position: static;
          visibility: visible;
          opacity: 1;
          border-radius: 0;
          background: transparent;
          width: 60%;
          border-right: 1px solid ${colors.lightwhiteborder};
        }
        .right {
          width: 40%;
          .classmates_list {
            height: calc(100vh - 200px);
          }
        }
      }
    }
    .profile_head {
      .profile_head_image {
        .mobile_back {
          display: none;
        }
      }
    }
  }
  @media ${device.mobileL} {
    .chat_form {
      position: static;
    }
    .active.left {
      padding-bottom: 0;
    }
  }
  @media ${device.laptopL} {
    width: 80%;
    .body_full_screen {
      padding-left: ${space.space40};
      height: calc(100vh - 60px);
      .full_screen_card {
        .left {
          width: 65%;
        }
        .right {
          width: 35%;
          .classmates_list {
            height: calc(100vh - 220px);
          }
        }
      }
    }
    .chat_form {
      padding: 0 ${space.space24} 26px;
    }
    .chat_list {
      height: calc(100vh - 250px);
    }
  }
`;
function Notification() {
  // Chat Dropdown
  const [emojiHide, setEmojiHide] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const [chattingUsers, setChattingUsers] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [usersDetails, setUsersDetails] = useState({});
  const [allMessages, setAllMessages] = useState([]);
  let [changeText, setChangeText] = useState(true);
  const [pagess, setPagess] = useState(1);
  const [messagePagess, setMessagePagess] = useState(1);
  const [classmatesPagess, setClassmatesPages] = useState(1);

  const [count, setCount] = useState({
    senderCount: 0,
    recieverCount: 0,
  });

  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }
  const {
    userInfo,
    setUserInfo,
    gettingClassList,
    classmatesData,
    setIsMyMoment,
    handelLogout,
  } = useContext(PostContext);

  const listInnerRef = useRef();
  const scrollRef = useRef();
  const classmateRef = useRef();

  useEffect(() => {
    const deBounace = setTimeout(() => {
      if (changeText) {
        getListOfChatUsers({ pages: 1 });
        setIsMyMoment(false);
      }
      getinfo();
    }, 300);

    return () => clearInterval(deBounace);
  }, [searchTerm, changeText]);

  const handleOpen = () => {
    setOpen(!open);
  };
  // End Chat Dropdown

  const { loaderDispatch } = useContext(loaderContext);

  // Add class in Searchbar
  const handleClick = (users) => {
    setIsActive((current) => !current);
    setUsersDetails(users);
    getChatList(users?.User_id ? users?.User_id : users?.id, 1);
    setCount({
      senderCount: 0,
      recieverCount: 0,
    });
    handelProfileImageShow(users);
    // setAllMessages([]);
    setPagess(1);
  };
  // End Add class in Searchbar

  const getinfo = async () => {
    let user = {
      email: userData.email,
    };
    let subDetails = await getSubscriptionDetail(user);
    if (subDetails?.data?.status === 410) {
      handelLogout();
    }

    if (subDetails?.data?.status === StatusCodes.OK) {
      let StudentInfo = subDetails?.data?.data;

      setUserInfo(StudentInfo);
      if (!changeText) {
        gettingClassList({ StudentInfo, search: searchTerm, loader: true });
      }
    }
  };

  function onClick(event) {
    setMessage((preVal) => {
      return preVal + event?.native;
    });
  }
  const handelChat = (e) => {
    if (e?.target?.value?.trim().length > 0 || e?.target?.value === "") {
      setMessage(e?.target?.value);
    }
  };

  const sendMessages = async () => {
    try {
      // loaderDispatch({
      //   isLoading: true,
      // });
      let body = {
        sender_id: userData?.id,
        reciever_id: usersDetails?.User_id
          ? usersDetails?.User_id
          : usersDetails?.id,
        status: 1,
        message: message,
        action_user_name: userInfo?.student_name,
      };
      if (message?.length > 0) {
        let result = await sendMessage(body);
        loaderDispatch({
          isLoading: false,
        });
        if (result?.data?.status === StatusCodes.OK) {
          setMessage("");
          setEmojiHide(false);
          getChatList(
            usersDetails?.User_id ? usersDetails?.User_id : usersDetails?.id,
            1
          );
        } else {
          toast(result?.data?.message, {
            type: "error",
            theme: "dark",
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getListOfChatUsers = async ({ pages = null, loader = null, ss }) => {
    try {
      if (loader) {
        loaderDispatch({
          isLoading: true,
        });
      }
      let body = {
        id: userData?.id,
        search: searchTerm.trim(),
        page: pages ? pages : 1,
      };

      let result = await listChatUsers(body);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        let data = result?.data?.data;
        if (data?.length > 0 && pages > 1) {
          setChattingUsers((preVal) => {
            return [...preVal, ...data];
          });
          // setUsersDetails(data[0]);
          // getChatList(data[0]?.User_id, 1);
        }
        if (data?.length > 0 && (pages === 1 || !pages) && !ss) {
          setChattingUsers(data);

          setUsersDetails(data[0]);
          getChatList(data[0]?.User_id, 1);
        }
        if (data?.length > 0 && pages === 1 && ss) {
          setChattingUsers(data);
        }
      } else {
        toast(result?.data?.message, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const messageTime = (val) => {
    let date = new Date(val?.createdAt);
    let vals = dateDifference(new Date(val?.createdAt), new Date(new Date()), {
      compact: true,
    });
    let vals2 = vals.slice(1);

    if (vals2 === "~1d") {
      return `yesterday ${date?.getHours()}:${date?.getMinutes()} `;
    } else if (vals2?.includes("ms")) {
      return `few seconds ago`;
    } else if (vals2?.includes("m")) {
      return `${vals2} ago`;
    } else if (vals2?.includes("s")) {
      return `few seconds ago`;
    } else if (vals2?.includes("h")) {
      return `${vals2} ago`;
    } else {
      return `${date?.getDate()}-${date?.getMonth()}-${date.getFullYear()} ${date?.getHours()}:${date?.getMinutes()} `;
    }

    // console.log(val);
  };

  const getChatList = async (id, page = null) => {
    try {
      loaderDispatch({
        isLoading: true,
      });

      let body = {
        user_id: userData?.id,
        reciever_id: id,
        page: page,
      };
      let result = await chatList(body);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        let list = result?.data?.data?.chatList;
        if (page === 1) {
          setAllMessages(list);
        }
        if (list?.length > 0 && page > 1) {
          setAllMessages((preVal) => {
            return [...list, ...preVal];
          });
          setTimeout(() => {
            const { scrollHeight, scrollTop } = listInnerRef.current;

            listInnerRef?.current?.scrollTo({
              top: (scrollHeight + scrollTop) / 4,
              behavior: "auto",
            });
          }, 100);
        }
        if (page === 1) {
          getListOfChatUsers({ pages: 1, loader: true, ss: true });
          GoToBottom();
        }
      } else {
        toast(result?.data?.message, {
          type: "error",
          theme: "dark",
        });
      }
      loaderDispatch({
        isLoading: false,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handelProfileImageShow = (user) => {
    for (let i = 0; i < allMessages.length; i++) {
      console.log("testss", allMessages[i]?.sender_id);
      if (userInfo?.id === allMessages[i]?.sender_id) {
        setCount((preVal) => {
          return { ...preVal, senderCount: preVal?.senderCount + 1 };
        });
      } else if (user?.reciever_id === allMessages[i]?.reciever_id) {
        setCount((preVal) => {
          return { ...preVal, recieverCount: preVal?.recieverCount + 1 };
        });
      }
    }
  };

  const GoToBottom = () => {
    if (listInnerRef?.current) {
      setTimeout(() => {
        const { scrollHeight, scrollTop } = listInnerRef.current;

        listInnerRef?.current?.scrollTo({
          top: scrollHeight + scrollTop,
          behavior: "auto",
        });
      }, 100);
    }
  };

  let page = pagess;
  const onScroll = () => {
    if (listInnerRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop === 0) {
        page++;
        setPagess(pagess + 1);
        console.log("yesssss", scrollTop, clientHeight, scrollHeight);
        getChatList(
          usersDetails?.User_id ? usersDetails?.User_id : usersDetails?.id,
          page
        );
      }
    }
  };

  const handleChange = (value) => {
    setChangeText(value);
  };

  //pagination at chat users
  let messagedUserPage = messagePagess;
  const massagedUserPagination = () => {
    if (scrollRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        messagedUserPage++;
        setMessagePagess(messagedUserPage);
        getListOfChatUsers({ pages: messagedUserPage, loader: false });
      }
    }
  };

  //pagination at classmate list
  let classmateUserPage = classmatesPagess;
  const classMatesPagination = () => {
    if (classmateRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = classmateRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        classmateUserPage++;
        setClassmatesPages(classmateUserPage);
        gettingClassList({ pages: classmateUserPage, loader: false });
      }
    }
  };

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      sendMessages();
    }
  };
  console.log("chattingUsers", chattingUsers);
  return (
    <>
      <NotificationRow>
        <div className='body_full_screen'>
          <SiteCard className='full_screen_card'>
            <div className={isActive ? "active left" : "left"}>
              <div
                className={
                  Object?.keys(usersDetails).length <= 0
                    ? "empty-chat"
                    : "empty-chat hidden"
                }>
                <img src={EmptyChat} alt='images' />
                <HeadingThree h3Data={"No Conversation"} />
                <Paragraph
                  pData={
                    "You didnt made any conversation yet, please select classmate."
                  }
                />
              </div>
              <div
                className={
                  Object?.keys(usersDetails).length > 0
                    ? "profile_head "
                    : "profile_head hidden"
                }>
                <div className='profile_head_image '>
                  <button
                    className='mobile_back'
                    onClick={handleClick}></button>
                  <img
                    src={
                      usersDetails?.User_filtered_image
                        ? usersDetails?.User_filtered_image
                        : usersDetails?.filtered_image
                        ? usersDetails?.filtered_image
                        : blankProfile
                    }
                    alt='profile_image'
                  />
                </div>
                <div className='details'>
                  <p className='name'>
                    {usersDetails?.User_student_name
                      ? usersDetails?.User_student_name
                      : usersDetails?.student_name}
                  </p>
                  {/* <p className='duration '>Typing...</p> */}
                </div>
                {/* <div className='menu_dropdown'>
                  <button className='dropdown_btn' onClick={handleOpen}>
                    <img src={IconDots} alt='icon dots' />
                  </button>
                  {open ? (
                    <ul className='menu_list'>
                      <li className='menu-item'>
                        <button className='link'>
                          <img src={IconDelete} alt='close' />
                          Delete
                        </button>
                      </li>
                      <li className='menu-item'>
                        <button className='link'>
                          <img src={IconReport} alt='close' />
                          Report
                        </button>
                      </li>
                    </ul>
                  ) : null}
                </div> */}
              </div>
              <ul
                className={
                  Object?.keys(usersDetails).length > 0
                    ? "chat_list"
                    : "chat_list hidden"
                }
                onScroll={onScroll}
                ref={listInnerRef}>
                {allMessages?.length > 0 ? (
                  allMessages?.map((chats, i) => {
                    if (userInfo?.id === chats?.sender_id) {
                      return (
                        <ChatList
                          cname='right_chat'
                          ProfileImage={
                            userInfo?.filtered_image
                              ? userInfo?.filtered_image
                              : blankProfile
                          }
                          massage={chats?.message}
                          time={chats?.created_at}
                          key={i}
                        />
                      );
                    } else {
                      return (
                        <ChatList
                          cname='left_chat'
                          ProfileImage={
                            usersDetails?.User_filtered_image
                              ? usersDetails?.User_filtered_image
                              : usersDetails?.filtered_image
                              ? usersDetails?.filtered_image
                              : blankProfile
                          }
                          massage={chats?.message}
                          time={chats?.created_at}
                          key={i}
                        />
                      );
                    }
                  })
                ) : (
                  <p>No chats available</p>
                )}
                {/* <GoToBottom /> */}

                {/* <ChatList
                  cname='left_chat'
                  ProfileImage={ProfileImage}
                  massage={"We need to discuss about it"}
                  time={"02:43 am"}
                  massage2={"please let me know when you get the time."}
                  time2={"02:43 am"}
                />
                <ChatList
                  cname='right_chat'
                  ProfileImage={ProfileImage2}
                  massage={"can we discuss after 30 minutes"}
                  time={"02:45 pm"}
                  massage2={
                    "The project from you, for the brief please send it now so I can study it first."
                  }
                  time2={"02:50 pm"}
                />
                <ChatList
                  cname='left_chat'
                  ProfileImage={ProfileImage}
                  massage={"Sure, Thanks for your time!"}
                  time={"02:52 am"}
                  massage2={"please let me know when you get the time."}
                  time2={"02:54 am"}
                /> */}
              </ul>
              {emojiHide && (
                <div className='z-10 absolute bottom-20'>
                  {/* <EmojiPicker theme='dark' onEmojiClick={onClick} /> */}
                  <Picker
                    data={data}
                    onEmojiSelect={onClick}
                    theme={"dark"}
                    previewPosition='none'
                  />
                </div>
              )}

              <SiteForm
                as='div'
                className={
                  Object?.keys(usersDetails).length > 0
                    ? "chat_form"
                    : "chat_form hidden"
                }>
                <div className='form_group'>
                  <div className='flex'>
                    <BsEmojiSmile
                      className='mr-2 text-xl text-gray-500 cursor-pointer mt-1'
                      onClick={() => setEmojiHide(!emojiHide)}
                    />
                    <input
                      type='text'
                      name='text'
                      value={message}
                      onChange={handelChat}
                      onFocus={() => setEmojiHide(false)}
                      onKeyDown={
                        userInfo?.standard?.toLowerCase() !== GRADUATED &&
                        userInfo?.is_plan_expired !== "1"
                          ? handleKeypress
                          : ""
                      }
                      placeholder='Type your message here...'
                    />
                  </div>

                  {userInfo?.standard?.toLowerCase() !== GRADUATED &&
                    userInfo?.is_plan_expired !== "1" && (
                      <button className='send_btn' onClick={sendMessages}>
                        <svg
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M21.7403 2.83128C21.6406 2.41353 21.1816 2.12928 20.7653 2.27703C20.7653 2.27703 2.73455 8.64078 2.73155 8.64303C2.4548 8.74578 2.25155 9.00978 2.23655 9.31203C2.22305 9.60228 2.4023 9.90453 2.66705 10.0275L9.65105 13.2878C9.65105 13.2878 13.0538 9.88503 13.0598 9.87903C13.7348 9.20403 14.8126 10.248 14.1203 10.9395L10.7116 14.3483C10.7116 14.3483 13.9673 21.3248 13.9733 21.336C14.1061 21.591 14.3656 21.753 14.6506 21.7658C14.9603 21.78 15.2468 21.543 15.3578 21.2655C15.3578 21.2655 21.7178 3.24528 21.7216 3.23403C21.7688 3.10428 21.7718 2.96403 21.7403 2.83128Z'
                            fill='#30DFF2'
                          />
                        </svg>
                      </button>
                    )}
                </div>
              </SiteForm>
            </div>
            <div className='right'>
              <SiteForm as='div' className='chat_list_form'>
                <div className='form_group'>
                  <input
                    type='search'
                    name='search'
                    placeholder='Search classmate'
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e?.target?.value);
                    }}
                  />
                </div>
              </SiteForm>

              <p className='messages_head'>
                <span
                  className={changeText ? "active" : ""}
                  onClick={() => handleChange(true)}>
                  Messages
                </span>
                <span
                  className={changeText ? "" : "active"}
                  onClick={() => handleChange(false)}>
                  Classmates
                </span>
              </p>
              {changeText ? (
                <ul
                  className='classmates_list'
                  ref={scrollRef}
                  onScroll={massagedUserPagination}>
                  {chattingUsers?.length > 0 ? (
                    chattingUsers?.map((user, i) => {
                      return (
                        <li className='list_item' key={i}>
                          <div
                            className='link cursor-pointer'
                            onClick={() => handleClick(user)}>
                            <img
                              src={
                                user?.User_filtered_image
                                  ? user?.User_filtered_image
                                  : blankProfile
                              }
                              alt='Profile'
                            />
                            <div className='detail'>
                              <Paragraph
                                cname='name smallParagaraph'
                                pData={user?.User_student_name}
                              />
                              <Paragraph
                                cname='messages_text smallParagaraph'
                                pData={user?.message}
                              />
                              <Paragraph
                                cname='time'
                                pData={messageTime(user)}
                              />
                              <div className='flex justify-end'>
                                {user?.messageCount > 0 && (
                                  <span className='count'>
                                    {user?.messageCount}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })
                  ) : (
                    <p>Data not found..!!..!!</p>
                  )}
                </ul>
              ) : (
                <ul
                  className='classmates_list'
                  ref={classmateRef}
                  onScroll={classMatesPagination}>
                  {classmatesData?.length > 0 ? (
                    classmatesData?.map((mates, i) => {
                      return (
                        <li className='list_item' key={i}>
                          <div
                            className='link cursor-pointer'
                            onClick={() => handleClick(mates)}>
                            <img
                              src={
                                mates?.filtered_image
                                  ? mates?.filtered_image
                                  : blankProfile
                              }
                              alt='Profile'
                            />
                            <div className='detail'>
                              <Paragraph
                                cname='name smallParagaraph'
                                pData={mates?.student_name}
                              />
                            </div>
                          </div>
                        </li>
                      );
                    })
                  ) : (
                    <p>Data not found..!!..!!</p>
                  )}
                </ul>
              )}
            </div>
          </SiteCard>
        </div>
      </NotificationRow>
    </>
  );
}

export default Notification;
