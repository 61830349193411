import React from "react";

function RangeInput({ onChange, min, max, step, value, cname, name }) {
  return (
    <>
      <input
        className={cname}
        type='range'
        onChange={onChange}
        min={min}
        max={max}
        step={step}
        value={value}
        name={name}
      />
    </>
  );
}

export default RangeInput;
