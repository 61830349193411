import React, { Fragment, useContext, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import CreatePostImage from "./CreatePostImg.jsx";
import ImgDes from "./ImgDes.jsx";
import PostSubmitted from "./PostSubmitted.jsx";
import Discard from "./DisCard.jsx";
import iconAlert from "../../../images/website/icon_alert.svg";
import imageCompression from "browser-image-compression";

import {
  ADMIN_USER_DATA,
  CLUB_POST,
  DISCARD,
  IMG,
  IMG_DES,
  MY_MOMENT_POST,
  MY_POST,
  POST_SUBMIT,
  SCHOOL_MOMENTS,
  TEAM_POST,
} from "../../../utils/constant.js";
import { loaderContext } from "../../global loader/global-loader-provider.js";
import {
  getSubscriptionDetail,
  updatePost,
  uploadPost,
} from "../../../services/auth.services.js";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import PostDetails from "../../schools/post details/postDetails.jsx";
import { PostContext } from "../header/MainHeader.jsx";
import CommonModal from "../../../common/website/CommonModal.jsx";

function CreateNewPostModal({
  openImageModal,
  setOpenImageModal,
  value,
  val,
  setOpen,
  clubId,
}) {
  let initailModalValue = value || IMG;
  const [game, setGame] = useState(initailModalValue);
  const [postDetails, setPostDetails] = useState({
    postImage: null,
    caption: "",
  });
  const { loaderDispatch } = useContext(loaderContext);
  const {
    getAllPostDetails,
    userInfo,
    clubData,
    postlocation,
    teamData,
    isMyMoment,
    getMomentPostListing,
    classmatesData,
    handelLogout,
    page,
  } = useContext(PostContext);
  const [openCommonModal, setOpenCommonModal] = useState(false);
  const [message, setMessage] = useState("");
  const [tagId, setTagId] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }
  let subsCribe = true;

  useEffect(() => {
    setPostDetails({
      postImage: null,
      caption: val?.description ? val?.description : "",
    });
    if (val?.description?.length > 0) {
      if (val?.description?.match(/[\\<>@#&!]/g)) {
        let idArrays = value.match(/\d+/g);

        setTagId([...handelRemovalNotRelevantIds(idArrays, classmatesData)]);
      }
    }
  }, [openImageModal]);

  const handelRemovalNotRelevantIds = (idArrays, classmatesData) => {
    let ids = [];
    if (idArrays?.length > 0 && classmatesData?.length > 0) {
      for (let i = 0; i < idArrays?.length; i++) {
        for (let j = 0; j < classmatesData?.length; j++) {
          if (classmatesData[j]?.id == idArrays[i]) {
            ids.push(idArrays[i]);
          }
        }
      }
      console.log(ids, "tagId");
    }
    return ids;
  };

  // Sets the game to the given state when the user clicks.
  const handleClick = (gameState) => {
    setGame(gameState);
    setTagId([]);
  };

  // Asynchronously uploads a post.
  const handelUploadPost = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let body = {};
      if (postlocation === CLUB_POST) {
        body = {
          postName: postDetails.postImage,
          description: postDetails.caption,
          postType: getPostType(),
          school_id: userInfo?.User.id,
          user_id: userInfo?.id,
          status: 1,
          club_id: clubId,
        };
      } else if (postlocation === SCHOOL_MOMENTS) {
        body = {
          postName: postDetails.postImage,
          description: postDetails.caption,
          postType: getPostType(),
          school_id: userInfo?.User?.id,
          user_id: userInfo?.id,
          status: 1,
          is_school_moment: 1,
        };
      } else if (postlocation === TEAM_POST) {
        body = {
          postName: postDetails.postImage,
          description: postDetails.caption,
          postType: getPostType(),
          school_id: userInfo?.User.id,
          user_id: userInfo?.id,
          status: 1,
          sport_id: clubId,
        };
      } else if (postlocation === MY_MOMENT_POST) {
        body = {
          postName: postDetails.postImage,
          description: postDetails.caption,
          postType: getPostType(),
          school_id: userInfo?.User.id,
          user_id: userInfo?.id,
          status: 1,
          is_moment: isMyMoment,
          tagged_user_id: [...tagId],
        };
      }
      if (body?.postName?.type.includes("image")) {
        body.postName = await handleImageUpload(body?.postName);
      }

      let formData = new FormData();
      for (const key in body) {
        formData.append([key], body[key]);
      }

      let result = await uploadPost(formData);
      loaderDispatch({
        isLoading: false,
      });

      if (result?.data?.status === 410) {
        handelLogout();
      }

      if (result?.data?.status === StatusCodes.OK) {
        handleClick(POST_SUBMIT);
      } else {
        setOpenImageModal(false);
        setGame(IMG);
        setMessage(result?.data?.message);
        setOpenCommonModal(true);
      }
    } catch (error) {
      console.error(error);
      setMessage("Something went wrong..!!");
      setOpenCommonModal(true);
    }
  };

  async function handleImageUpload(imageFile) {
    let size = imageFile?.size / 1024 / 1024;
    if (size > 4) {
      const options = {
        maxSizeMB: 3,
        // maxWidthOrHeight: 1920,
        // alwaysKeepResolution: true,
      };
      try {
        const compressedFile = await imageCompression(imageFile, options);
        console.log("compressedFileSize", compressedFile.size / 1024 / 1024);
        return compressedFile;
      } catch (error) {
        console.log(error);
        loaderDispatch({
          isLoading: false,
        });
      }
    } else {
      return imageFile;
    }
  }

  // Returns the post type.
  const getPostType = () => {
    let file = postDetails.postImage;
    if (file.type.includes("video/")) {
      return 2;
    } else if (file.type.includes("image/")) {
      return 1;
    }
  };

  // Updates a post.
  const upDatePost = async (postId, isMyMoment) => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let body = {
        description: postDetails.caption,
      };
      if (postlocation === MY_MOMENT_POST || isMyMoment) {
        body.is_moment = true;
        body.tagged_user_id = [...tagId];
        body.user_id = userInfo?.id;
      }

      let result = await updatePost(postId, body);
      if (result?.data?.status === 410) {
        handelLogout();
      }
      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
          theme: "dark",
        });
        if (postlocation === SCHOOL_MOMENTS) {
          getAllPostDetails({
            id: userInfo?.User?.id,
            studentId: userInfo?.id,
            academicYear: userInfo?.academic_year,
            standard: userInfo?.standard,
          });
        } else if (postlocation === CLUB_POST) {
          getAllPostDetails({
            id: userInfo?.User?.id,
            clubId: clubData?.id,
            studentId: userInfo?.id,
          });
        } else if (postlocation === TEAM_POST) {
          getAllPostDetails({
            id: userInfo?.User?.id,
            sport_id: teamData?.id,
            studentId: userInfo?.id,
          });
        } else if (postlocation === MY_MOMENT_POST) {
          getMomentPostListing({ userInfo, is_moment: true });
        } else if (postlocation === MY_POST) {
          getAllPostDetails({
            id: userInfo?.User?.id,
            studentId: userInfo?.id,
            userDatas: userInfo,
          });
        }
        setOpenImageModal(false);
        setOpen(false);
      } else {
        toast(result?.data?.message, { type: "error", theme: "dark" });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Transition.Root show={openImageModal} as={Fragment}>
        <Dialog
          as='div'
          static
          open={openImageModal}
          onClose={setOpenImageModal}>
          {(() => {
            switch (game) {
              case IMG:
                return (
                  <CreatePostImage
                    setOpenImageModal={setOpenImageModal}
                    handleClick={handleClick}
                    setPostDetails={setPostDetails}
                    openImageModal={openImageModal}
                    setErrorMessage={setErrorMessage}
                    errorMessage={errorMessage}
                  />
                );

              case IMG_DES:
                return (
                  <ImgDes
                    setOpenImageModal={setOpenImageModal}
                    handleClick={handleClick}
                    postDetails={postDetails}
                    setPostDetails={setPostDetails}
                    handelUploadPost={handelUploadPost}
                    value={value}
                    val={val}
                    upDatePost={upDatePost}
                    tagId={tagId}
                    setTagId={setTagId}
                    openImageModal={openImageModal}
                  />
                );

              case POST_SUBMIT:
                return (
                  <PostSubmitted
                    setOpenImageModal={setOpenImageModal}
                    handleClick={handleClick}
                    setPostDetails={setPostDetails}
                    openImageModal={openImageModal}
                  />
                );

              case DISCARD:
                return (
                  <Discard
                    setOpenImageModal={setOpenImageModal}
                    setPostDetails={setPostDetails}
                    handleClick={handleClick}
                    openImageModal={openImageModal}
                  />
                );

              default:
                return null;
            }
          })()}
        </Dialog>
      </Transition.Root>
      <CommonModal
        setOpenCommonModal={setOpenCommonModal}
        openCommonModal={openCommonModal}
        header={"Error in Sharing Post "}
        message={message}
        icon={iconAlert}
      />
    </>
  );
}
export default CreateNewPostModal;
