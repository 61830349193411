import React from "react";
import styled from "styled-components";
import { colors, space } from "./Variables.js";
import { Field } from "formik";
import { device } from "./Devices";
import Paragraph from "./Paragraph.jsx";

import IconCheck from "../../images/website/icon_check_blue.svg";

const VotingImageBoxRow = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: ${space.space16};
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: ${space.space16};
  }
  .count {
    font-size: 0.875rem;
    line-height: 1.063rem;
    font-weight: 600;
    color: ${colors.darkgrey};
    background: rgba(255, 255, 255, 0.6);
    position: absolute;
    top: 6px;
    left: 6px;
    width: 28px;
    height: 28px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
  input[type="checkbox"] {
    width: 20px;
    height: 20px;
    cursor: pointer;
    border: none;
    background: rgba(11, 11, 11, 0.6);
    backdrop-filter: blur(4px);
    border-radius: ${space.space8};
    appearance: none;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: 10px;
    bottom: 10px;
    &:checked {
      background-image: url(${IconCheck});
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      border: 1px solid ${colors.secondary};
    }
    &[value="true"] {
      background-image: url(${IconCheck});
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      border: 1px solid ${colors.secondary};
    }
  }
  @media ${device.mobileM} {
    width: 180px;
    height: 180px;
    input[type="checkbox"] {
      width: 30px;
      height: 30px;
    }
  }
`;

function VotingImageBox(props) {
  return (
    <>
      <VotingImageBoxRow>
        <img src={props.boxImage} alt='image' />
        <Paragraph cname='count' pData={props.NumberCount || 0} />
        <input
          type='checkbox'
          name={props.checkboxName}
          checked={props?.defaultChecked}
          onChange={(e) =>
            props?.handleChooseDigitalArt({
              name: props.checkboxName,
              userId: props?.userId,
              post_id: props?.post_id,
            })
          }
        />
      </VotingImageBoxRow>
    </>
  );
}
export default VotingImageBox;
