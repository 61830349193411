import { StatusCodes } from "http-status-codes";
import React, { useState, useEffect, useContext } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import { AppContext } from "../../App.js";
import { AdminContext } from "../../common/DashboardWrapper.jsx";
import { studentListToClub } from "../../services/auth.services.js";
import { ADMIN_USER_DATA, CLUB } from "../../utils/constant.js";
import { loaderContext } from "../global loader/global-loader-provider.js";

import Incorporate from "./Incorporate.jsx";
import { toast } from "react-toastify";

function ClubUserAddAndRemove() {
  const [itemsNormal, setitemsNormal] = useState({});
  const [search, setSearch] = useState({
    studentListingInclub: "",
    studentListingNotInClub: "",
  });
  let { id } = useParams();

  const { loaderDispatch } = useContext(loaderContext);

  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }

  useEffect(() => {
    const deBounce = setTimeout(() => {
      getStudentList();
    }, 300);

    return () => {
      clearTimeout(deBounce);
    };
  }, []);

  const getStudentList = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let body = {
        id,
        schoolId: userData.id,
      };
      let result = await studentListToClub(body);
      loaderDispatch({
        isLoading: false,
      });
      setitemsNormal({});

      if (result?.data?.status === StatusCodes.OK) {
        let data = result?.data?.data;
        setitemsNormal(data);
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className='flex flex-col items-center bg-gray-100 min-h-screen'>
        <h1 className='text-2xl font-semibold mt-6 mb-7 text-gray-600'>
          STUDENTS LIST
        </h1>
        {Object.keys(itemsNormal).length > 0 ? (
          <Incorporate
            itemsNormal={itemsNormal}
            setSearch={setSearch}
            search={search}
          />
        ) : (
          <p className='text-sm  relative left-1/2 mt-20'>Data not found..!!</p>
        )}
      </div>
    </>
  );
}

export default ClubUserAddAndRemove;
