import React, { useState, useContext } from "react";
import styled from "styled-components";

import { space } from "./Variables";
import { device } from "./Devices";

import ConvertYourNFTModal from "../../components/website/modals/ConvertYourNFT.jsx";
import IconVideo from "../../images/website/icon_video.png";
import MomentsImageModal from "../../components/website/modals/MomentsImageModal.jsx";
import { loaderContext } from "../../components/global loader/global-loader-provider";
import { convertPostToNft } from "../../services/auth.services";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import { ADMIN_USER_DATA } from "../../utils/constant";
import { PostContext } from "../../components/website/header/MainHeader";

const MomentsImageRow = styled.div`
  position: relative;
  margin-bottom: ${space.space8};
  &.video {
    .video_icon {
      display: block;
    }
    .nft_image {
      display: none;
    }
  }
  .video_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    display: none;
  }
  .moment_image {
    width: 100%;
    height: auto;
    cursor: pointer;
    border-radius: ${space.space16};
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: ${space.space16};
    }
  }
  .nft_image {
    position: absolute;
    bottom: 4px;
    right: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      width: 24px;
      height: 24px;
    }
  }
  @media ${device.mobileM} {
    margin-bottom: ${space.space16};
    .nft_image {
      bottom: 8px;
      right: 8px;
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
`;

function MomentsImage(props) {
  const [openImageModal2, setOpenImageModal2] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [photosValue, setPhotosValue] = useState(false);

  const { loaderDispatch } = useContext(loaderContext);
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }
  const { setpageReload, pageReload, userInfo } = useContext(PostContext);

  const handelChooseFile2 = (e) => {
    setOpenImageModal2(true);
  };

  const handelCovertPostToNFT = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });

      let body = {
        user_id: userData?.id,
        post_id: props?.value?.id,
        status: 1,
        is_original: photosValue,
      };
      let result = await convertPostToNft(body);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
          theme: "dark",
        });
        setOpenImageModal(false);
        setOpenImageModal2(false);
        setpageReload(!pageReload);
      } else {
        toast(result?.data?.message, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handelChooseFile = (e) => {
    setOpenImageModal(true);
  };

  const handelSetSource = (sourceObject) => {
    if (sourceObject?.value?.postName) {
      return sourceObject?.value?.postName;
    } else if (sourceObject?.value?.Post?.postName) {
      return sourceObject?.value?.Post?.postName;
    } else {
      return "";
    }
  };

  return (
    <>
      <MomentsImageRow className={props.cname}>
        <div className='moment_image' onClick={handelChooseFile2}>
          {props?.value?.postType == 1 || props?.value?.Post?.postType == 1 ? (
            <img src={handelSetSource(props)} alt='icon' />
          ) : (
            <video src={handelSetSource(props)}></video>
          )}
          <div className='video_icon' onClick={handelChooseFile2}>
            <img src={IconVideo} alt='icon' />
          </div>
        </div>
      </MomentsImageRow>

      <MomentsImageModal
        openImageModal2={openImageModal2}
        setOpenImageModal2={setOpenImageModal2}
        value={props?.value?.postName ? props?.value : props?.value?.Post}
        type={props?.value?.postName ? true : false}
        handelChooseFile={handelChooseFile}
      />
      <ConvertYourNFTModal
        openImageModal={openImageModal}
        setOpenImageModal={setOpenImageModal}
        handelCovertPostToNFT={handelCovertPostToNFT}
        photosValue={photosValue}
        setPhotosValue={setPhotosValue}
      />
    </>
  );
}
export default MomentsImage;
