import React from "react";
import {
  Droppable,
  DragDropContext,
  DroppableProvided,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";

const List = ({
  children,
  title,
  onDragEnd,
  name,
  setSearch,
  searchFunction,
  type,
  search,
}) => {
  return (
    <div className='flex flex-col w-96'>
      <h2 className='text-xl font-semibold mb-2 mx-5 text-gray-600 uppercase'>
        {title}
      </h2>
      <div className=''>
        <Droppable droppableId={name}>
          {(provided, snapshot) => (
            <div ref={provided.innerRef} className='h-fit max-h-auto'>
              <div className='p-2 rounded-md border-gray-300 bg-gray-300 mx-2  flex flex-col  overflow-y-auto gap-y-3  h-96  '>
                <input
                  type='text'
                  name={name}
                  className='focus:outline-none rounded p-2 focus:outline-indigo-500'
                  id=''
                  autoComplete='off'
                  value={
                    type
                      ? search.studentListingInclub
                      : search.studentListingNotInClub
                  }
                  placeholder='Search'
                  onChange={(e) => {
                    searchFunction(e);
                  }}
                />
                {children?.length > 0 ? (
                  <div className='p-2 rounded-md border-gray-300 bg-gray-300 gap-y-3 flex flex-col  overflow-y-auto h-96 mt-5'>
                    {children}
                    {provided.placeholder}
                  </div>
                ) : (
                  <div className='w-full h-full flex justify-center items-center'>
                    <p>Data not found..!!</p>
                  </div>
                )}
              </div>
            </div>
          )}
        </Droppable>
      </div>
    </div>
  );
};

export default List;
