import React, { useState, useEffect, useContext } from "react";
import ListLayout from "../../components/admin club/AdminClubListing.jsx";
import { DELETE, UPDATE, VIEW, CLUB } from "../../utils/constant";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { loaderContext } from "../../components/global loader/global-loader-provider.js";
import {
  clubDelete,
  clubListing,
  clubUpdate,
  clubView,
} from "../../services/auth.services.js";
import { StatusCodes } from "http-status-codes";
import AdminClubEdit from "./AdminClubEdit.jsx";
import { toast } from "react-toastify";
import AdminDeleteModel from "../../common/AdminDeleteModel.jsx";
import { AdminContext } from "../../common/DashboardWrapper.jsx";

function AdminClubManagment() {
  const [data, setData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [viewData, setViewData] = useState({});
  const [sortby, setSortby] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentItems, setCurrentItems] = useState(null);
  const [dataCount, setDataCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  const [orderby, setOrderby] = useState(true);
  const [editData, setEditData] = useState({});
  const [openEditModal, setOpenEditModal] = useState(false);
  const [error, setError] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedData, setDeletedData] = useState(0);
  const [dataToUpdate, setDataToUpdate] = useState({
    clubName: "",
    status: false,
    club_profile: null,
  });

  let navigate = useNavigate();
  const { loaderState, loaderDispatch } = useContext(loaderContext);
  const { setAdminActiveTab } = useContext(AdminContext);

  const tableHeader = [
    {
      key: "S.No.",
      value: "key",
    },
    {
      key: "School Name",
      subValue: "school_name",
      value: "User",
      clickable: false,
      sorting: true,
    },
    { key: "Club Name", value: "clubName", clickable: false, sorting: true },
    { key: "Status", value: "status", clickable: false, sorting: true },

    { key: "Created On", value: "createdAt", clickable: false, sorting: true },
    {
      key: "Total Member",
      value: "total_members",
      clickable: false,
      sorting: false,
    },
    {
      key: "Total Post",
      value: "total_post",
      clickable: false,
      sorting: false,
    },

    {
      key: "options",
      value: "options",
    },
  ];

  const option = [
    {
      name: "Select Status",
      value: editData?.status == 1 ? true : false,
    },
    {
      name: "Active",
      value: "1",
    },
    {
      name: "Inactive",
      value: "2",
    },
  ];

  const edit = [
    {
      label: "Club Name",
      type: "text",
      value: dataToUpdate?.clubName,
      name: "clubName",
      maxLength: "60",
      minLength: "0",
    },
  ];

  const itemsOnPage = [
    { option: 10 },
    { option: 20 },
    { option: 50 },
    { option: 70 },
    { option: 100 },
  ];

  useEffect(() => {
    setAdminActiveTab(CLUB);
  }, []);

  useEffect(() => {
    const debounce = setTimeout(() => {
      handelClubListing();
    }, 300);

    return () => {
      clearTimeout(debounce);
    };
  }, [searchItem, sortby, itemOffset, itemsPerPage, orderby]);

  useEffect(() => {
    setCurrentItems(data);
    setPageCount(Math.ceil(dataCount / itemsPerPage));
  }, [data, dataCount, itemsPerPage]);

  //function to get selected options
  const handleOptions = (optionValue, item) => {
    switch (optionValue) {
      case VIEW:
        navigate(`/admin/clubs/view/${item.id}`);
        break;
      case UPDATE:
        setOpenEditModal(true);
        studentEdit(item.id);
        break;

      case DELETE:
        setOpenDeleteModal(true);
        setDeletedData(item.id);
        break;

      default:
        break;
    }
  };

  //function to get club list
  const handelClubListing = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let order = orderby ? "desc" : "asc";
      let searchItems = searchItem.toLocaleLowerCase();
      let search = searchItems.trim();

      if (search.toLowerCase().includes("active")) {
        search = "1";
      } else if (search.toLowerCase().includes("inactive")) {
        search = "2";
      }

      let body = {
        search: search,
        sort: sortby,
        page: itemOffset,
        limit: itemsPerPage,
        order: order,
      };

      let result = await clubListing(body);
      // console.log(result);
      setData([]);
      setDataCount(0);

      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        // console.log(result);
        const studentData = result?.data?.data?.rows;
        const studentDataCount = result?.data?.data?.count;
        setData(studentData);
        setDataCount(studentDataCount);
      }
    } catch (error) {}
  };

  //handel shorting
  const handleSorting = (data, order) => {
    setSortby(data);
    setOrderby(order);
  };

  //handel pagination
  const handlePageClick = (event) => {
    let newOffset = (event.selected * itemsPerPage) % dataCount;
    // dataCall(body);
    setItemOffset(newOffset / 10 + 1);
  };

  //function to update club data
  const updateData = async () => {
    for (const key in dataToUpdate) {
      console.log("error", key);
      if (dataToUpdate[key] === "") {
        setError((preVal) => {
          return { ...preVal, [key]: true };
        });
      }
    }

    // console.log(checkError.length, data.length, checkError);
    let data = {
      clubName: dataToUpdate?.clubName.trim(),
      club_profile: dataToUpdate?.club_profile,
      description: dataToUpdate?.description.trim(),
      status: dataToUpdate?.status,
    };
    let formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }

    if (dataToUpdate?.clubName !== "" && dataToUpdate?.description !== "") {
      loaderDispatch({
        isLoading: true,
      });
      let result = await clubUpdate(editData.id, formData);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        let item = result?.data?.data;
        setEditData(item);
        setOpenEditModal(false);
        handelClubListing();
        toast(result?.data?.message, {
          type: "success",
        });
      } else {
        setOpenEditModal(false);
        handelClubListing();
        toast(result?.data?.message, {
          type: "error",
        });
      }
    }
  };

  //handel deletion of club
  const deleteData = async () => {
    try {
      let result = await clubDelete(deletedData);

      if (result?.data?.status === StatusCodes.OK) {
        toast("Club Deleted..!!", {
          type: "success",
        });
        setOpenDeleteModal(false);
        handelClubListing();
      }
    } catch (error) {
      setOpenDeleteModal(false);
    }
  };

  const studentEdit = async (id) => {
    loaderDispatch({
      isLoading: true,
    });
    let param = {
      id: id,
    };
    let result = await clubView(param);

    loaderDispatch({
      isLoading: false,
    });
    if (result?.data?.status === StatusCodes.OK) {
      let item = result?.data?.data;
      setEditData(item);
      for (const key in item) {
        setError((preVal) => {
          return { ...preVal, [key]: false };
        });
      }
    }
  };

  //api to get all data

  return (
    <div className="">
      <div className="p-2">
        <input
          type={"text"}
          placeholder={"Search"}
          onChange={(e) => {
            setSearchItem(e.target.value);
            setItemOffset(1);
          }}
          autoComplete="cc-given-name"
          className="mt-1 block w-1/2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
        />
      </div>

      <AdminClubEdit
        viewData={editData}
        openViewModal={openEditModal}
        setOpenViewModal={setOpenEditModal}
        view={edit}
        setEditData={setEditData}
        updateData={updateData}
        error={error}
        setError={setError}
        option={option}
        setDataToUpdate={setDataToUpdate}
        dataToUpdate={dataToUpdate}
      />

      <AdminDeleteModel
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        deleteData={deleteData}
        header={"Delete Club"}
      />

      <ListLayout
        handleOptions={handleOptions}
        tableHeader={tableHeader}
        handleSorting={handleSorting}
        currentItems={currentItems}
      />
      <div className="flex justify-between mb-10">
        {data?.length > 0 && (
          <div className="block lg:inline-block ">
            <label htmlFor="item" className="ml-5">
              Record Per Page:
            </label>
            <select
              className="ml-3 bg-white border p-2 rounded"
              name="item"
              id="item"
              onChange={(e) => {
                setItemsPerPage(parseInt(e.target.value));
              }}
            >
              {itemsOnPage.map((val, i) => {
                return (
                  <option value={val.option} key={i}>
                    {val.option}
                  </option>
                );
              })}
            </select>
          </div>
        )}

        <div className="">
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next >"
            onPageChange={handlePageClick}
            pageCount={pageCount}
            previousLabel="< Previous"
            renderOnZeroPageCount={null}
            containerClassName={"pagination"}
            pageClassName={"pageClass"}
            forcePage={itemOffset - 1}
          />
        </div>
      </div>

      {!data?.length && !loaderState?.isLoading ? (
        <div className="flex absolute left-1/2 top-1/2 mx-auto">
          Data not found..!!
        </div>
      ) : null}
    </div>
  );
}

export default AdminClubManagment;
