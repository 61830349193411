import React, { useContext, useState } from "react";
import styled from "styled-components";
import { colors, space } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";

import BgRectangle from "../../../images/website/bg_rectangle.png";
import IconMember from "../../../images/website/icon_members.svg";
import NFTBalance from "../../../images/website/icon_NFT_Balance.svg";

import HeadingThree from "../../../common/website/HeadingThree.jsx";
import Paragraph from "../../../common/website/Paragraph.jsx";
import { PostContext } from "../header/MainHeader";
import blankProfile from "../../../images/website/dummy_user_profile.svg";
import Button from "../../../common/website/Button";
import VotingModalRow from "../modals/VotingModal.jsx";
import { loaderContext } from "../../global loader/global-loader-provider";
import {
  getAlreadyVotedDetails,
  getSubscriptionDetail,
} from "../../../services/auth.services";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { ADMIN_USER_DATA } from "../../../utils/constant";

const ProfileHeaderRow = styled.div`
  width: 100%;
  position: static;
  top: 0;
  z-index: 8;
  .profile_top {
    background: url(${BgRectangle}) no-repeat center;
    background-size: cover;
    padding: ${space.space10};
    border-radius: ${space.space16};
    margin-bottom: ${space.space24};
    width: 100%;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      background: #0b0b0b;
      width: 100%;
      top: -100px;
      left: 0;
      height: 100%;
      z-index: -1;
      display: none;
    }
    .NFT_Balance {
      display: inline-flex;
      align-items: center;
      font-size: 0.75rem;
      line-height: 0.938rem;
      background-color: ${colors.primary};
      color: ${colors.body};
      border-radius: ${space.space8};
      padding: 4px ${space.space10};
      img {
        position: static;
        width: 14px;
        height: 14px;
        flex-shrink: 0;
        margin-right: 4px;
      }
    }
    .other_profile_text {
      margin: 0;
      color: ${colors.primary};
    }
    .other_profile_year {
      display: none;
    }
  }
  .profile_image {
    position: absolute;
    width: 56px;
    height: 56px;
    object-fit: cover;
    border-radius: 100%;
    left: 0;
    bottom: 0;
    top: 0;
    margin: auto;
  }
  .profile_details {
    position: relative;
    padding-left: 72px;
    h3 {
      font-size: 1rem;
      margin-bottom: 0;
    }
    .member_count {
      display: flex;
      align-items: center;
      grid-gap: 6px;
      img {
        flex-shrink: 0;
      }
      .member_text {
        font-size: 0.75rem;
        line-height: 0.938rem;
        margin: 0;
        color: ${colors.lightgrey2};
      }
    }
  }
  .vote_head {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
    grid-gap: 6px;
    margin-bottom: 6px;
    .vote_btn {
      display: none;
      font-size: 0.75rem;
      line-height: 0.938rem;
      padding: 4px 0.625rem;
    }
  }
  @media ${device.mobileM} {
    .profile_top {
      padding: ${space.space30};
      margin-bottom: ${space.space30};
      .profile_image {
        width: 100px;
        height: 100px;
        bottom: -44px;
        top: auto;
      }
      .profile_details {
        padding-left: 132px;
        h3 {
          font-size: 1.257rem;
        }
      }
    }
  }
  @media ${device.tablet} {
    position: sticky;
    .profile_top {
      &::before {
        display: block;
      }
      .profile_details {
        h3 {
          font-size: 1.375rem;
        }
      }
    }
  }
  @media ${device.laptop} {
    .profile_top {
      padding: ${space.space60} ${space.space40} ${space.space40};
      margin-bottom: 48px;
      .profile_image {
        width: 132px;
        height: 132px;
        bottom: -66px;
      }
      .profile_details {
        padding-left: 172px;
      }
    }
    .vote_head {
      flex-direction: row;
      align-items: center;
      .vote_btn {
        font-size: 1rem;
        padding: ${space.space14} ${space.space20};
      }
    }
  }
`;

function ProfileHeader({
  clubData,
  cname,
  ClubProfileImage,
  Name,
  count,
  votingStatus,
  votedStatus,
  firstPageLoad,
}) {
  const [openImageModal, setOpenImageModal] = useState(false);
  const [digitalVotedStatus, setDigitalVotedStatus] = useState(true);

  const handelChooseFile = (e) => {
    setOpenImageModal(true);
  };
  const { userInfo } = useContext(PostContext);
  const { loaderDispatch } = useContext(loaderContext);

  useEffect(() => {
    getStudentProfile();
  }, []);

  const getStudentProfile = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });

      let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));

      let studentData = await getSubscriptionDetail(userData);
      loaderDispatch({
        isLoading: false,
      });
      if (studentData?.data?.status === StatusCodes.OK) {
        let data = studentData?.data?.data;

        // getAllPostDetails({
        //   id: data?.User?.id,
        //   clubId: data?.club_id,
        //   studentId: data?.id,
        // });
        gettingAlreadyVotedDetails(data);
      } else {
        toast(studentData?.data?.massage, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setProfileImage = () => {
    if (ClubProfileImage) {
      return ClubProfileImage;
    } else if (clubData?.club_profile) {
      return clubData?.club_profile;
    } else {
      return blankProfile;
    }
  };

  const gettingAlreadyVotedDetails = async (data) => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let params = {
        action_user_id: data?.id,
        type: 2,
      };
      let result = await getAlreadyVotedDetails(params);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        let datas = result?.data?.data[0];
        if (datas) {
          setDigitalVotedStatus(false);
        }

        // setVotedData(datas);
      } else {
        toast(result?.data?.message, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <ProfileHeaderRow className={cname}>
        <div className='profile_top'>
          <div className='profile_details'>
            <img
              src={setProfileImage()}
              alt='profile_image'
              className='profile_image'
            />
            <div className='vote_head'>
              <HeadingThree
                cname='head'
                h3Data={clubData?.clubName || Name || ""}
              />

              {firstPageLoad === 3 ||
                (digitalVotedStatus && votingStatus && (
                  <Button
                    cname='gradient vote_btn'
                    buttonData='Vote for Best Digital Arts'
                    onClick={handelChooseFile}
                  />
                ))}

              <div className='NFT_Balance other_profile_year'>
                Year: <span>{userInfo?.academic_year || 0}</span>
              </div>
            </div>

            <div className='member_count'>
              <img src={IconMember} alt='profile_image' />
              <Paragraph
                cname='member_text'
                pData={
                  clubData?.total_members?.count
                    ? `${clubData?.total_members?.count} members`
                    : count
                    ? count + " members"
                    : 0 + " members"
                }
              />
            </div>
            <Paragraph
              cname='other_profile_text hidden'
              pData={`School name: ${
                userInfo?.User?.school_name?.charAt(0)?.toUpperCase() +
                  userInfo?.User?.school_name?.slice(1) || ""
              }`}
            />
            <div className='NFT_Balance'>
              <img
                src={NFTBalance}
                alt='profile_image'
                className='profile_image'
              />
              NFT Balance :{" "}
              <span>{userInfo?.total_nft ? userInfo?.total_nft : 0}</span>
            </div>
            <div>
              <Paragraph
                cname='other_profile_text hidden'
                pData={`Grade level: ${
                  userInfo?.standard?.charAt(0)?.toUpperCase() +
                    userInfo?.standard?.slice(1) || ""
                }`}
              />
            </div>
          </div>
        </div>
      </ProfileHeaderRow>
      <VotingModalRow
        openImageModal={openImageModal}
        setOpenImageModal={setOpenImageModal}
        setDigitalVotedStatus={setDigitalVotedStatus}
        digitalVotedStatus={digitalVotedStatus}
      />
    </>
  );
}

export default ProfileHeader;
