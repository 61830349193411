import React from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import SiteLogo from "../../../common/website/SiteLogo.jsx";
import { device } from "../../../common/website/Devices";
import { colors, space } from "../../../common/website/Variables";
import {
  Main,
  Container,
  SubscriptionCard,
  CustomLink,
} from "../../../common/website/HOC.jsx";

import LoginBG from "../../../images/website/login_bg.svg";
import Paragraph from "../../../common/website/Paragraph.jsx";
import HeadingThree from "../../../common/website/HeadingThree.jsx";
import HeadingOne from "../../../common/website/HeadingOne.jsx";

const PageNotFoundRow = styled.div`
  height: 100%;
  position: relative;
  text-align: center;
  &::before {
    content: "";
    position: fixed;
    background: url(${LoginBG}) no-repeat;
    background-position: center;
    background-size: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    z-index: 1;
  }
  .full_screen_container {
    position: relative;
    height: 100%;
    z-index: 2;
    padding: 100px ${space.space20};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .logo {
    text-align: center;
    margin-bottom: ${space.space24};
    a {
      display: inline-block;
    }
  }
  img {
    margin: 0 auto;
  }
  h1 {
    font-size: 3.75rem;
    line-height: 3.5rem;
    color: ${colors.white};
    margin: 0;
    animation: glitch 2s infinite;
  }
  @keyframes glitch {
    0%,
    50%,
    100% {
      transform: skewX(0deg) translateX(0px) scale(1, 1);
      text-shadow: 0px 0 cyan;
      clip-path: none;
    }
    5%,
    15%,
    25% {
      transform: skewX(0deg) translateX(0px) scale(1, 1);
      text-shadow: 0px 0rem magenta, 5px 5px ${colors.primary};
    }
    10%,
    30% {
      transform: skewX(0deg) translateX(5px) scale(1, 1);
      text-shadow: 0px 0rem yellow;
    }
    35%,
    45% {
      transform: skewX(0deg) translateX(-1px) scale(1, 1);
      text-shadow: 0px 0rem ${colors.primary}, -10px 0px #70308c;
    }
    11%,
    14% {
      clip-path: polygon(
        100% 0,
        0 18%,
        99% 46%,
        0 78%,
        100% 100%,
        100% 57%,
        0 100%,
        100% 76%,
        0 59%,
        100% 35%,
        0 37%,
        100% 21%
      );
    }
    12%,
    16% {
      clip-path: polygon(
        0 51%,
        0 29%,
        100% 0,
        0 14%,
        99% 57%,
        100% 23%,
        0 100%,
        100% 72%,
        0 0,
        100% 100%
      );
    }
    13%,
    17% {
      clip-path: polygon(
        0% 0%,
        99% 0%,
        99% 8%,
        0% 7%,
        0% 14%,
        99% 15%,
        100% 22%,
        0% 22%,
        0% 30%,
        99% 30%,
        99% 39%,
        0% 37%,
        0% 43%,
        100% 45%,
        100% 50%,
        0% 49%,
        0% 56%,
        99% 57%,
        99% 63%,
        0% 63%,
        0% 68%,
        99% 68%,
        99% 75%,
        0% 76%,
        0% 81%,
        100% 83%,
        100% 89%,
        0% 88%,
        0% 94%,
        100% 95%,
        100% 100%,
        0% 99%
      );
    }
  }
  h3 {
    color: ${colors.primary};
    margin: ${space.space16} 0 ${space.space14};
  }
  p {
    font-size: 1rem;
    line-height: 1.625rem;
    color: ${colors.white};
    margin: 0 auto ${space.space24};
    max-width: 540px;
    a {
      font-weight: 600;
      text-decoration: underline;
      &:hover {
        color: ${colors.primary};
      }
    }
  }
  @media ${device.mobileL} {
    .logo {
      margin-bottom: ${space.space30};
    }
    p {
      margin: 0 auto ${space.space40};
    }
    h3 {
      margin: ${space.space24} 0 ${space.space14};
    }
  }
  @media ${device.mobileM} {
    height: 100vh;
    .full_screen_container {
      justify-content: center;
    }
    h1 {
      font-size: 4.375rem;
      line-height: 3.875rem;
    }
  }
  @media ${device.tablet} {
    .full_screen_container {
      padding: ${space.space50} ${space.space20};
    }
    .logo {
      margin-bottom: ${space.space40};
    }
    h3 {
      margin: ${space.space50} 0 ${space.space14};
    }
    h1 {
      font-size: 6.25rem;
      line-height: 5.75rem;
    }
  }
  @media ${device.laptopL} {
    .full_screen_container {
      padding: ${space.space50} 0;
    }
    .logo {
      margin-bottom: ${space.space80};
    }
    h1 {
      font-size: 10.375rem;
      line-height: 8.125rem;
    }
  }
`;

function PageNotFound() {
  let navigate = useNavigate();
  return (
    <>
      <Main>
        <PageNotFoundRow className='content_full_screen'>
          <Container className='full_screen_container'>
            <SiteLogo cname='logo' />
            <HeadingOne h1Data={"404"} />
            <HeadingThree h3Data={"OOPS! PAGE NOT FOUND!"} />
            <Paragraph
              pData={
                "You are here because you entered the address of a page that no longer exists or has been moved to a different address."
              }
            />
            <CustomLink>
              <Link className='gradient' onClick={() => navigate(-1)}>
                Go Back
              </Link>
            </CustomLink>
          </Container>
        </PageNotFoundRow>
      </Main>
    </>
  );
}

export default PageNotFound;
