import React from "react";
import styled from "styled-components";

import { SiteCard } from "../../../common/website/HOC.jsx";
import { colors, space } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";
import Paragraph from "../../../common/website/Paragraph.jsx";
import { Link } from "react-router-dom";
import blankProfile from "../../../images/website/dummy_user_profile.svg";
import { student } from "../../../linksprovider.js";

const RecentActivityRow = styled.div`
  margin-bottom: ${space.space24};
  .recentactivity_card {
    border-radius: ${space.space16};
  }
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 0;
    margin-bottom: ${space.space16};
    .title {
      margin: 0;
    }
    .page_link {
      font-size: 0.75rem;
      line-height: 1.063rem;
      color: ${colors.primary};
      &:hover {
        color: ${colors.secondary};
      }
    }
  }
  .recentactivity_list {
    height: 248px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(172 172 172 / 10%);
      border-radius: 8px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    /* Fire Fox */
    scrollbar-color: rgb(172 172 172 / 10%) transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background-color: transparent;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(172 172 172 / 10%);
      border-radius: 8px;
    }
    /* End Fire Fox */
    li {
      display: flex;
      align-items: flex-start;
      grid-gap: ${space.space12};
      margin-bottom: ${space.space14};
      &:last-child {
        margin: 0;
      }
      img {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        object-fit: cover;
      }
      .name {
        font-size: 0.75rem;
        line-height: 1.125rem;
        color: ${colors.white};
        span {
          color: ${colors.lightgrey2};
        }
      }
    }
  }

  @media ${device.laptop} {
    .head {
      grid-gap: ${space.space8};
      .page_link {
        font-size: 0.875rem;
      }
    }
  }
`;

function RecentActivity({ recentActivityData }) {
  return (
    <>
      <RecentActivityRow>
        <SiteCard className='recentactivity_card'>
          <div className='head'>
            <Paragraph cname='title' pData={"Recent Activity"} />
            <Link to={student.notification} className='page_link'>
              See all
            </Link>
          </div>
          <ul className='recentactivity_list'>
            {recentActivityData?.map((val, i) => {
              return (
                <li key={i}>
                  <img
                    src={
                      val?.User?.filtered_image
                        ? val?.User?.filtered_image
                        : blankProfile
                    }
                    alt='Profile'
                  />
                  <div className='name'>
                    {val?.type === 1 && (
                      <p>
                        <span>{val?.action_user_name}</span> Liked your{" "}
                        <span>post.</span>
                      </p>
                    )}
                    {val?.type === 2 && (
                      <p>
                        <span>{val?.action_user_name}</span> Commented on your{" "}
                        <span>post.</span>
                      </p>
                    )}
                    {val?.type === 3 && <p>You have new message.</p>}
                  </div>
                </li>
              );
            })}
          </ul>
        </SiteCard>
      </RecentActivityRow>
    </>
  );
}

export default RecentActivity;
