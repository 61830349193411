import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import LandingNewsletter from "./LandingNewsletter";
import LandingContactUs from "./LandingContactUs";
import LandingBrand from "./LandingBrand";
import LandingNFT from "./LandingNFT";
import LandingDigital from "./LandingDigital";
import LandingFeaturesTop from "./LandingFeaturesTop";
import LandingFeaturesBottom from "./LandingFeaturesBottom";
import LandingAbout from "./LandingAbout";
import HowItWorks from "./HowItWorks";
import LandingBanner from "./LandingBanner";
import { Main } from "../../../common/website/HOC.jsx";
import LandingVideo from "./LandingVideo";
import HeaderWithoutLogin from "../../../components/website/header/HeaderWithoutLogin";
import Footer from "../../../components/website/footer/Footer.jsx";
import { AppContext } from "../../../App";

function Landing() {
  const ref = useRef(null);
  const { setConstactUsHide } = useContext(AppContext);
  const [navAndScroll, setNavAndScroll] = useState(false);

  const handleClick = () => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };
  useLayoutEffect(() => {
    setConstactUsHide(true);
  }, []);
  return (
    <>
      <HeaderWithoutLogin handleClick={handleClick} />
      <Main className='website_main'>
        <LandingBanner />
        <span ref={ref}>
          <LandingContactUs />
        </span>
        <LandingAbout />
        <LandingFeaturesTop />
        <HowItWorks />
        <LandingFeaturesBottom />
        <LandingVideo />
        <LandingDigital />
        <LandingNFT />
        <LandingBrand />
        <LandingNewsletter />
      </Main>
      <Footer handleClick={handleClick} />
    </>
  );
}

export default Landing;
