import React, { useState, useEffect, useContext } from "react";
import ListLayout from "../../components/email listing/EmailListing.jsx";
import {
  ADMIN_USER_DATA,
  DELETE,
  UPDATE,
  VIEW,
  CMS,
  EMAIL_TEMPLATES,
} from "../../utils/constant";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { admin } from "../../linksprovider.js";
import { emailListing } from "../../services/auth.services.js";
import { loaderContext } from "../../components/global loader/global-loader-provider";
import { StatusCodes } from "http-status-codes";
import { AdminContext } from "../../common/DashboardWrapper.jsx";
import EmailView from "../../components/email listing/EmailView.jsx";
import { toast } from "react-toastify";

// import { use } from "express/lib/application.js";

function EmailTemplateListing() {
  const [data, setData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [viewData, setViewData] = useState({});
  const [sortby, setSortby] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentItems, setCurrentItems] = useState(null);
  const [dataCount, setDataCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  const { loaderState, loaderDispatch } = useContext(loaderContext);
  const [orderby, setOrderby] = useState(true);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [id, setId] = useState({});

  let navigate = useNavigate();

  const { setAdminActiveTab } = useContext(AdminContext);

  const tableHeader = [
    {
      key: "S.No.",
      value: "key",
    },
    {
      key: "Name",
      value: "email_template_name",
      sorting: true,
    },
    {
      key: "options",
      value: "options",
    },
  ];

  const itemsOnPage = [
    { option: 10 },
    { option: 20 },
    { option: 50 },
    { option: 70 },
    { option: 100 },
  ];

  useEffect(() => {
    const debunce = setTimeout(() => {
      getListing();
    }, 300);

    return () => {
      clearTimeout(debunce);
    };
  }, [searchItem, sortby, itemOffset, itemsPerPage, orderby]);

  useEffect(() => {
    setAdminActiveTab(EMAIL_TEMPLATES);
  }, []);

  useEffect(() => {
    setCurrentItems(data);
    setPageCount(Math.ceil(dataCount / itemsPerPage));
  }, [data, dataCount, itemsPerPage]);

  const handleOptions = (optionValue, item) => {
    switch (optionValue) {
      case VIEW:
        setOpenViewModal(true);
        setId(item);
        break;
      case UPDATE:
        loaderDispatch({
          isLoading: true,
        });
        navigate(`/admin/emailTemplate/Edit/${item.id}`);
        break;

      default:
        break;
    }
  };

  const handleSorting = (data, order) => {
    setSortby(data);
    setOrderby(order);
  };

  const getListing = async () => {
    loaderDispatch({
      isLoading: true,
    });
    try {
      let order = orderby ? "desc" : "asc";
      let body = {
        search: searchItem,
        sort: sortby,
        page: itemOffset,
        limit: itemsPerPage,
        order: order,
      };
      let result = await emailListing(body);
      loaderDispatch({
        isLoading: false,
      });
      setData([]);
      setDataCount(0);

      if (
        result?.data?.data?.emailListing?.count > 0 &&
        result?.data?.status === StatusCodes.OK
      ) {
        const studentData = result?.data?.data?.emailListing?.rows;
        const studentDataCount = result?.data?.data?.emailListing?.count;
        for (let i = 0; i < studentData?.length; i++) {
          setData((preVal) => {
            return [
              ...preVal,
              {
                id: studentData[i]?.id,
                email_template_name: studentData[i]?.email_template_name,
              },
            ];
          });
        }
        setDataCount(studentDataCount);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageClick = (event) => {
    let newOffset = (event.selected * itemsPerPage) % dataCount;
    // dataCall(body);
    setItemOffset(newOffset / 10 + 1);
  };

  return (
    <div className=''>
      <div className='p-2'>
        <input
          type={"text"}
          placeholder={"Search"}
          onChange={(e) => {
            setSearchItem(e.target.value);
            setItemOffset(1);
          }}
          autoComplete='cc-given-name'
          className='mt-1 block w-1/2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm'
        />
      </div>
      <EmailView
        setOpenViewModal={setOpenViewModal}
        openViewModal={openViewModal}
        item={id}
      />

      <ListLayout
        handleOptions={handleOptions}
        tableHeader={tableHeader}
        handleSorting={handleSorting}
        currentItems={currentItems}
      />
      <div className='flex justify-between mb-10'>
        {data.length > 0 && (
          <div className='block lg:inline-block lg:mt-0 '>
            <label htmlFor='item' className='ml-5'>
              Record Per Page:
            </label>
            <select
              className='ml-3 bg-white border p-2 rounded'
              name='item'
              id='item'
              onChange={(e) => {
                setItemsPerPage(parseInt(e.target.value));
              }}>
              {itemsOnPage.map((val, i) => {
                return (
                  <option value={val.option} key={i}>
                    {val.option}
                  </option>
                );
              })}
            </select>
          </div>
        )}
        <div>
          <ReactPaginate
            breakLabel='...'
            nextLabel='Next >'
            onPageChange={handlePageClick}
            pageCount={pageCount}
            previousLabel='< Previous'
            renderOnZeroPageCount={null}
            containerClassName={"pagination"}
            pageClassName={"pageClass"}
            forcePage={itemOffset - 1}
          />
        </div>
      </div>
      {data.length === 0 && (
        <div className='flex absolute left-1/2 top-1/2 mx-auto'>
          <p className='text-sm '>Data not found..!!</p>
        </div>
      )}
    </div>
  );
}

export default EmailTemplateListing;
