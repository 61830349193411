import React, { useState } from "react";
import { FaSort } from "react-icons/fa";
import { BiEditAlt } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { AiFillEye, AiOutlineUserSwitch } from "react-icons/ai";
import { ADMIN_USER_DATA, DELETE, UPDATE, VIEW } from "../../utils/constant";
import ActiveStatus from "../../common/ActiveStatus";
import InActiveStatus from "../../common/InActiveStatus";
import Tooltip from "@material-ui/core/Tooltip";
import { useNavigate } from "react-router-dom";

function AdminTaskListing({
  handleOptions,
  currentItems,
  tableHeader,
  handleSorting,
  type,
}) {
  const [order, setOrder] = useState(true);

  const handleSortTable = (item, orders, subValue) => {
    if (subValue) {
      handleSorting(subValue, orders);
    } else {
      handleSorting(item, orders);
    }
  };

  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }

  let navigate = useNavigate();

  return (
    <div className="p-1.5">
      <div className="overflow-x-auto border rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              {tableHeader.map((item, key) => {
                return (
                  <th
                    key={key}
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase max-w-xs truncate"
                  >
                    {item.sorting ? (
                      <span className="flex">
                        {item.key}
                        <FaSort
                          className="cursor-pointer text-md"
                          onClick={() => {
                            handleSortTable(
                              item?.value,
                              !order,
                              item?.subValue
                            );
                            setOrder(!order);
                          }}
                        />
                      </span>
                    ) : (
                      <span className="flex float-left">{item.key}</span>
                    )}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {currentItems?.map((dataItem, dataKey) => {
              return (
                <tr key={dataKey}>
                  {tableHeader.map((item, key) => {
                    if (item.key === "options") {
                      return (
                        <td
                          key={key}
                          className="float-left px-5 py-4 font-normal text-gray-600 whitespace-nowrap flex justify-between max-w-xs truncate"
                        >
                          <Tooltip title="view">
                            <span className="p-1">
                              <AiFillEye
                                className="cursor-pointer"
                                onClick={() => handleOptions(VIEW, dataItem)}
                              />
                            </span>
                          </Tooltip>
                          <Tooltip title="edit">
                            <span className="p-1">
                              <BiEditAlt
                                className="cursor-pointer"
                                onClick={() => handleOptions(UPDATE, dataItem)}
                              />
                            </span>
                          </Tooltip>
                          <Tooltip title="delete">
                            <span className="p-1">
                              <MdDelete
                                className="cursor-pointer"
                                onClick={() => handleOptions(DELETE, dataItem)}
                              />
                            </span>
                          </Tooltip>
                        </td>
                      );
                    } else if (item.key === "S.No.") {
                      return (
                        <td
                          className={
                            item?.value === "total_nft" || item?.clickable
                              ? "px-6 py-4 text-sm font-medium text-gray-600 whitespace-nowrap cursor-pointer max-w-xs  truncate"
                              : "px-6 py-4 text-sm font-medium text-gray-600 whitespace-nowrap max-w-xs truncate "
                          }
                          key={key}
                        >
                          {dataKey + 1}
                        </td>
                      );
                    } else {
                      if (dataItem?.startDate) {
                        const parsedDate2 = new Date(dataItem?.startDate);
                        const year2 = parsedDate2.getFullYear();
                        const month2 = String(
                          parsedDate2.getMonth() + 1
                        ).padStart(2, "0"); // Months are zero-based
                        const day2 = String(parsedDate2.getDate()).padStart(
                          2,
                          "0"
                        );
                        const formattedDate2 = `${month2}/${day2}/${year2}`;
                        dataItem["startDate"] = formattedDate2;
                      }

                      if (dataItem?.dueDate) {
                        const parsedDate3 = new Date(dataItem?.dueDate);
                        const year3 = parsedDate3.getFullYear();
                        const month3 = String(
                          parsedDate3.getMonth() + 1
                        ).padStart(2, "0"); // Months are zero-based
                        const day3 = String(parsedDate3.getDate()).padStart(
                          2,
                          "0"
                        );
                        const formattedDate3 = `${month3}/${day3}/${year3}`;
                        dataItem["dueDate"] = formattedDate3;
                      }
                      if (dataItem?.status === 1 || dataItem?.status === "1") {
                        dataItem["status"] = (
                          <ActiveStatus message={"Active"} />
                        );
                      } else if (
                        dataItem?.status === 2 ||
                        dataItem?.status === "2"
                      ) {
                        dataItem["status"] = (
                          <InActiveStatus message={"Inactive"} />
                        );
                      } else if (
                        dataItem?.status === 0 ||
                        dataItem?.status === "0"
                      ) {
                        dataItem["status"] = (
                          <InActiveStatus message={"Inactive"} />
                        );
                      } else if (dataItem?.createdAt) {
                        const parsedDate = new Date(dataItem?.createdAt);
                        const year = parsedDate.getFullYear();
                        const month = String(
                          parsedDate.getMonth() + 1
                        ).padStart(2, "0"); // Months are zero-based
                        const day = String(parsedDate.getDate()).padStart(
                          2,
                          "0"
                        );
                        const formattedDate = `${month}/${day}/${year}`;
                        dataItem["createdAt"] = formattedDate;
                      }
                      return (
                        <td
                          className="px-6 py-4 text-sm font-medium text-gray-600 whitespace-nowrap max-w-xs truncate"
                          key={key}
                        >
                          {dataItem?.[item?.value] &&
                          dataItem?.[item?.value]?.[item?.subValue]
                            ? dataItem?.[item?.value]?.[item?.subValue]
                            : dataItem?.[item?.value] &&
                              dataItem?.[item?.value]?.toString()?.length > 0
                            ? dataItem?.[item?.value]
                            : "--"}
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AdminTaskListing;
