import React from "react";
import styled from "styled-components";

import { colors, space, siteGradient } from "./Variables";
import { device } from "./Devices.js";

const ButtonStyle = styled.button`
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  border-radius: ${space.space8};
  display: inline-block;
  text-align: center;
  position: relative;
  outline: 0;
  transition: all 0.3s ease-in-out;
  color: ${colors.primary};
  z-index: 1;

  @keyframes glowing {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }

  // Gradient
  &.gradient {
    padding: ${space.space8} 1rem;
  }
  &.outline {
    padding: 0.438rem 1rem;
  }
  &.gradient,
  &.outline {
    /* background:${siteGradient.bluePink}; */
    color: ${colors.white};

    &::after,
    &::before {
      background: ${siteGradient.bluePink};
      position: absolute;
      content: "";
      border-radius: ${space.space8};
      z-index: -1;
    }

    &::before {
      background-size: 400%;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      top: -2px;
      left: -2px;
      opacity: 0;
      filter: blur(5px);
      transition: all 0.3s ease-in-out;
      animation: glowing 8s linear infinite;
      /* animation: glowing 20s linear infinite; */
    }

    &::after {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }

    &:hover,
    &:active {
      color: ${colors.primary};
      transition: all 0.3s ease-in-out;

      &::before {
        opacity: 1;
        color: ${colors.primary};
      }

      &::after {
        background: ${colors.body};
      }
    }
  }

  // Outline
  &.outline {
    color: ${colors.primary};
    border: 1px solid ${colors.primary};
    &:hover {
      border-color: transparent;
    }
    &::after {
      background: ${colors.body};
    }
  }

  // Only Text
  &.textOnly {
    padding: 0 ${space.space8};

    &:hover {
      color: ${colors.white};
      text-decoration: underline;
    }
  }
  @media ${device.tablet} {
    font-size: 1rem;
    line-height: 1.5rem;
    &.gradient {
      padding: ${space.space12} ${space.space24};
    }
    &.outline {
      padding: 11px ${space.space24};
    }
  }
`;

function Button(props) {
  return (
    <>
      <ButtonStyle
        ref={props?.buttonRef}
        type={props?.type}
        as={props?.tag}
        href={props?.to}
        className={props?.cname}
        onClick={props?.onClick}>
        {props.buttonData}
      </ButtonStyle>
    </>
  );
}
export default Button;
