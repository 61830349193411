import React from "react";
import styled from "styled-components";

import { colors, space, siteGradient } from "./Variables.js";
import { device } from "./Devices.js";

const H4 = styled.h4`
  font-family: "Inter", sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.5rem;
  position: relative;

  color: ${colors.white};

  // Spacing
  margin-bottom: ${space.space8};

  @media ${device.tablet} {
    font-size: 1.25rem;
  }

  // Gradient
  &.gradient {
    background: ${siteGradient.bluePink};
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-flex;
  }
`;

function HeadingFour(props) {
  return (
    <>
      <H4 className={props.cname}>{props.h4Data}</H4>
    </>
  );
}
export default HeadingFour;
