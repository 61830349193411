import { useReducer } from "react";
import { addDays } from "date-fns";

const reducer = (state, newValue) => {
  const newState = {
    ...state,
    ...newValue,
  };
  return newState;
};

const initialState = {
  isLoading: false,
};

const useGlobalLoader = () => {
  const [loaderState, loaderDispatch] = useReducer(reducer, initialState);
  return { loaderState, loaderDispatch };
};

export default useGlobalLoader;
