import React, { Fragment, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Dialog, Transition } from "@headlessui/react";
import { GrClose } from "react-icons/gr";
import { SiteModal } from "../../../common/website/HOC.jsx";
import HeadingFive from "../../../common/website/HeadingFive.jsx";
import Button from "../../../common/website/Button.jsx";
import { space } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";
import { loaderContext } from "../../global loader/global-loader-provider.js";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import { upLoadProfileImage } from "../../../services/auth.services.js";
import { ADMIN_USER_DATA } from "../../../utils/constant.js";
import { student } from "../../../linksprovider.js";
import { useNavigate } from "react-router-dom";
import imageCompression from "browser-image-compression";

const SetProfilePhoto = styled.div`
  .profile_image {
    width: 100%;
    height: auto;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${space.space16};
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  @media ${device.mobileM} {
    .profile_image {
      height: 360px;
    }
  }
  @media ${device.mobileL} {
    .profile_image {
      height: 490px;
    }
  }
`;

function SetProfilePhotoModal({
  openImageModal,
  setOpenImageModal,
  imagefile,
  fileObj,
  setOpenImageModalNFT,
}) {
  const { loaderDispatch } = useContext(loaderContext);

  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }

  let navigate = useNavigate();

  const handelChooseFileNFT = (e) => {
    setOpenImageModalNFT(true);
    setOpenImageModal(false);
  };

  // upLoads a profile image.
  const handelProfileImageUpload = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });

      let file = await handleImageUpload(fileObj);
      let formData = new FormData();
      formData.append("profile_picture", file);
      let result = await upLoadProfileImage(formData, userData?.id);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
          theme: "dark",
        });
        navigate(student.post);
      } else {
        toast(result?.data?.message, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  async function handleImageUpload(imageFile) {
    let size = imageFile?.size / 1024 / 1024;
    if (size > 4) {
      const options = {
        maxSizeMB: 4,
        // maxWidthOrHeight: 1920,
        // alwaysKeepResolution: true,
      };
      try {
        loaderDispatch({
          isLoading: true,
        });
        const compressedFile = await imageCompression(imageFile, options);
        console.log("compressedFileSize", compressedFile.size / 1024 / 1024);
        return compressedFile;
      } catch (error) {
        console.log(error);
        loaderDispatch({
          isLoading: false,
        });
      }
    } else {
      return imageFile;
    }
  }
  return (
    <Transition.Root show={openImageModal} as={Fragment}>
      <Dialog as='div' static open={openImageModal} onClose={setOpenImageModal}>
        <SetProfilePhoto className='SetProfilePhoto'>
          <SiteModal>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
              <div className='modal_inner'>
                <div className='modal_boday'>
                  <div className='model_top'>
                    <HeadingFive
                      cname='title'
                      h5Data={"Set As a Profile Photo"}
                    />
                    <span
                      className='modal_close'
                      onClick={() => setOpenImageModal(false)}>
                      {" "}
                      <GrClose />
                    </span>
                  </div>
                  <div className='modal_content'>
                    <div className='profile_image'>
                      <img src={imagefile} alt='profile image' />
                    </div>
                  </div>
                  <div className='modal_btn_group'>
                    <Button
                      type='button'
                      tag='button'
                      cname='gradient modal_btn'
                      buttonData={"Done"}
                      onClick={handelProfileImageUpload}
                    />
                    {/* <Button
                      tag='button'
                      cname='gradient modal_btn'
                      buttonData={"Next"}
                      onClick={handelChooseFileNFT}
                    /> */}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </SiteModal>
        </SetProfilePhoto>
      </Dialog>
    </Transition.Root>
  );
}
export default SetProfilePhotoModal;
