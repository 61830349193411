import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { SiteCard, SiteForm } from "../../../common/website/HOC.jsx";
import { colors, space } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";
import { Formik, Field } from "formik";
import Paragraph from "../../../common/website/Paragraph.jsx";
import { Link } from "react-router-dom";

import ProfileImage from "../../../images/website/profile_image.png";
import ProfileImage2 from "../../../images/website/profile_image2.png";
import ProfileImage3 from "../../../images/website/profile_image3.png";
import ProfileImage4 from "../../../images/website/profile_image4.png";
import ProfileImage5 from "../../../images/website/profile_image5.png";
import ProfileImage6 from "../../../images/website/profile_image6.png";
import ProfileImage7 from "../../../images/website/profile_image7.png";
import ProfileImage8 from "../../../images/website/profile_image8.png";
import ProfileImage9 from "../../../images/website/profile_image9.png";
import ProfileImage10 from "../../../images/website/profile_image10.png";
import IconSelectArrow from "../../../images/website/icon_select_grey.svg";
import { loaderContext } from "../../global loader/global-loader-provider.js";
import {
  getSignatureClassmatesList,
  getSubscriptionDetail,
} from "../../../services/auth.services.js";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import { PostContext } from "../header/MainHeader.jsx";
import { ADMIN_USER_DATA } from "../../../utils/constant.js";
import Classmates from "./Classmates.jsx";

const SignaturesFilterRow = styled.div`
  .classmates_card {
    border-radius: ${space.space16};
  }
  .head {
    margin-bottom: ${space.space10};
  }
  .search_group {
    border-bottom: 1px solid ${colors.lightwhiteborder};
    margin-bottom: ${space.space24};
    padding-bottom: ${space.space24};
    select {
      font-size: 0.875rem;
      border: 1px solid #ffffff33;
      padding: ${space.space8} ${space.space16};
      height: 41px;
      border-radius: ${space.space8};
      background: url(${IconSelectArrow}) no-repeat right 10px center !important;
      padding-right: 34px;
      background-color: ${colors.darkgrey} !important;
    }
  }

  .classmates_list {
    margin: 0;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(172 172 172 / 10%);
      border-radius: 8px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    /* Fire Fox */
    scrollbar-color: rgb(172 172 172 / 10%) transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background-color: transparent;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(172 172 172 / 10%);
      border-radius: 8px;
    }
    /* End Fire Fox */
    li {
      margin-bottom: 18px;
      &:last-child {
        margin: 0;
      }
    }
    .link {
      display: inline-flex;
      align-items: center;
      img {
        width: 26px;
        height: 26px;
        object-fit: cover;
        border-radius: 100%;
        margin-right: ${space.space8};
      }
      p {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin: 0;
        transition: all 0.4s;
      }
      &:hover,
      &.active {
        p {
          transition: all 0.4s;
          color: ${colors.primary};
        }
      }
    }
  }
  @media ${device.mobileM} {
  }
  @media ${device.tablet} {
    .signature-classmate {
      .classmates_list {
        height: calc(100vh - 252px);
      }
    }
  }
  @media ${device.laptop} {
    .classmates_list {
      .link {
        img {
          width: 34px;
          height: 34px;
          margin-right: ${space.space12};
        }
      }
    }
    .signature-classmate {
      .classmates_list {
        height: calc(100vh - 282px);
      }
    }
    .search_group {
      margin-bottom: ${space.space30};
      padding-bottom: ${space.space30};
      select {
        height: 53px;
        padding: ${space.space8} ${space.space20};
        padding-right: 34px;
      }
    }
  }
  @media ${device.laptopL} {
    .signature-classmate {
      .classmates_list {
        height: calc(100vh - 302px);
      }
    }
  }
`;

function SignaturesFilter({
  getStudentProfile,
  handelFilterClassmateList,
  classMateData,
  setFilterType,
  filterType,
  paginationRef,
  handelPagination,
  setClassMatePage,
}) {
  useEffect(() => {
    // getStudentProfile();
    setClassMatePage(1);
  }, [filterType]);

  const handelChange = (e) => {
    setFilterType(e?.target?.value);
    handelFilterClassmateList({ singed: e?.target?.value });
  };
  return (
    <>
      <SignaturesFilterRow>
        <SiteCard className='classmates_card signature-classmate'>
          <Paragraph cname='head c' pData={"Filter by"} />

          <SiteForm as='div' className='upload_form'>
            <div className='form_group search_group'>
              <select
                name='color'
                as='select'
                className='menu_list'
                onChange={handelChange}>
                <option className='menu-item' value={true}>
                  Signed
                </option>
                <option className='menu-item' value={false}>
                  Not singed
                </option>
                {/* <option className='menu-item' value='Deactive'>
                  Deactive
                </option> */}
              </select>
            </div>
          </SiteForm>

          {/* <ul className='classmates_list'>
            <li>
              <Link to='' className='link active'>
                <img src={ProfileImage} alt='Profile' />{" "}
                <Paragraph cname='smallParagaraph' pData={"Michelle Coleman"} />
              </Link>
            </li>
            <li>
              <Link to='' className='link'>
                <img src={ProfileImage2} alt='Profile' />{" "}
                <Paragraph cname='smallParagaraph' pData={"Brandon Chapman"} />
              </Link>
            </li>
            <li>
              <Link to='' className='link'>
                <img src={ProfileImage3} alt='Profile' />{" "}
                <Paragraph cname='smallParagaraph' pData={"Tracey Bailey"} />
              </Link>
            </li>
            <li>
              <Link to='' className='link'>
                <img src={ProfileImage4} alt='Profile' />{" "}
                <Paragraph cname='smallParagaraph' pData={"Yvonne Dyer"} />
              </Link>
            </li>
            <li>
              <Link to='' className='link'>
                <img src={ProfileImage5} alt='Profile' />{" "}
                <Paragraph cname='smallParagaraph' pData={"Anthony Gibson"} />
              </Link>
            </li>
            <li>
              <Link to='' className='link'>
                <img src={ProfileImage6} alt='Profile' />{" "}
                <Paragraph cname='smallParagaraph' pData={"Edward Hamilton"} />
              </Link>
            </li>
            <li>
              <Link to='' className='link'>
                <img src={ProfileImage7} alt='Profile' />{" "}
                <Paragraph cname='smallParagaraph' pData={"Wendy Lambert"} />
              </Link>
            </li>
            <li>
              <Link to='' className='link'>
                <img src={ProfileImage8} alt='Profile' />{" "}
                <Paragraph cname='smallParagaraph' pData={"Gavin Lyman"} />
              </Link>
            </li>
            <li>
              <Link to='' className='link'>
                <img src={ProfileImage9} alt='Profile' />{" "}
                <Paragraph cname='smallParagaraph' pData={"Rachel Mathis"} />
              </Link>
            </li>
            <li>
              <Link to='' className='link'>
                <img src={ProfileImage10} alt='Profile' />{" "}
                <Paragraph cname='smallParagaraph' pData={"Jason Paterson"} />
              </Link>
            </li>
          </ul> */}

          <Classmates
            classmatesData={classMateData}
            message={"Classmates Data not found..!!..!!"}
            paginationRef={paginationRef}
            handelPagination={handelPagination}
          />
        </SiteCard>
      </SignaturesFilterRow>
    </>
  );
}

export default SignaturesFilter;
