import React, { Fragment } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { CustomLink, SiteModal } from "../../../common/website/HOC.jsx";
import { Dialog, Transition } from "@headlessui/react";
import { GrClose } from "react-icons/gr";

import UserProfileImage from "../../../images/website/NFT_image.png";
import HeadingFive from "../../../common/website/HeadingFive.jsx";
import Button from "../../../common/website/Button.jsx";
import { space } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";

const SetProfilePhotoNFT = styled.div`
  .profile_image {
    width: 100%;
    height: auto;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${space.space16};
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  @media ${device.mobileM} {
    .profile_image {
      height: 360px;
    }
  }
  @media ${device.mobileL} {
    .profile_image {
      height: 490px;
    }
  }
`;

function SetProfilePhotoNFTModal({ openImageModalNFT, setOpenImageModalNFT }) {
  return (
    <Transition.Root show={openImageModalNFT} as={Fragment}>
      <Dialog
        as='div'
        static
        open={openImageModalNFT}
        onClose={setOpenImageModalNFT}>
        <SetProfilePhotoNFT className='SetProfilePhotoNFT'>
          <SiteModal>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
              <div className='modal_inner'>
                <div className='modal_boday'>
                  <div className='model_top'>
                    <HeadingFive
                      cname='title'
                      h5Data={"Set As a Profile Photo"}
                    />
                    <span
                      className='modal_close'
                      onClick={() => setOpenImageModalNFT(false)}>
                      {" "}
                      <GrClose />
                    </span>
                  </div>
                  <div className='modal_content'>
                    <div className='profile_image'>
                      <img src={UserProfileImage} alt='profile image' />
                    </div>
                  </div>
                  <div className='modal_btn_group'>
                    {/* <Button
                      tag='button'
                      cname='gradient modal_btn'
                      buttonData={"Done"}
                      onClick={() => {
                        setOpenImageModalNFT(false);
                      }}
                    /> */}
                    <CustomLink>
                      <Link to='/SchoolMoments' className='gradient modal_btn'>
                        Done
                      </Link>
                    </CustomLink>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </SiteModal>
        </SetProfilePhotoNFT>
      </Dialog>
    </Transition.Root>
  );
}
export default SetProfilePhotoNFTModal;
