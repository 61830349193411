import React, { useState, useEffect, useContext } from "react";
import ListLayout from "../shared/listLayout.jsx";
import { DELETE, UPDATE, VIEW, SCHOOL } from "../../utils/constant";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { loaderContext } from "../global loader/global-loader-provider.js";
import { getSchoolList, adminSchoolDelete } from "../../services/auth.services";
import AdminView from "../../common/AdminView.jsx";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import AdminDeleteModel from "../../common/AdminDeleteModel.jsx";
import { AdminContext } from "../../common/DashboardWrapper.jsx";

import AdminInviteModal from "../Invite Modal/AdminInviteModal.jsx";

function AdminSchoolManagment() {
  const { loaderDispatch } = useContext(loaderContext);

  const [data, setData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [viewData, setViewData] = useState({});

  const [sortby, setSortby] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentItems, setCurrentItems] = useState(null);
  const [dataCount, setDataCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  const [openViewModal, setOpenViewModal] = useState(false);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedData, setDeletedData] = useState(0);

  const [orderby, setOrderby] = useState(true);

  const { setAdminActiveTab } = useContext(AdminContext);

  let navigate = useNavigate();

  const tableHeader = [
    {
      key: "S.No.",
      value: "key",
    },
    {
      key: "School Name",
      value: "school_name",
      clickable: false,
      sorting: true,
    },
    {
      key: "Email Address / Admin",
      value: "email",
      clickable: false,
      sorting: true,
    },
    {
      key: "street address",
      value: "address",
      clickable: false,
      sorting: true,
    },
    { key: "Country", value: "country", clickable: false, sorting: true },
    {
      key: "State/ Province",
      value: "state",
      clickable: false,
      sorting: true,
    },
    { key: "City", value: "city", clickable: false, sorting: true },

    {
      key: "ZIP code/ Postal Code",
      value: "pincode",
      clickable: false,
      sorting: true,
    },
    { key: "status", value: "status", clickable: false, sorting: true },
    {
      key: "options",
      value: "options",
      clickable: false,
      sorting: false,
    },
  ];

  const view = [
    {
      label: "School Name",
      type: "text",

      value: viewData?.school_name ? viewData?.school_name : "--",
      name: "school_name",
    },
    {
      label: "Email address / Admin",
      type: "email",
      value: viewData?.email ? viewData?.email : "--",
      name: "email",
    },

    {
      label: "Join On",
      type: "text",
      value: viewData?.createdAt ? viewData?.createdAt : "--",
      name: "createdAt",
    },
    {
      label: " Street address",
      type: "text",
      value: viewData?.address ? viewData?.address : "--",
      name: "address",
    },
    {
      label: "Country",
      type: "text",
      value: viewData?.country ? viewData.country : "--",
      name: "country",
    },

    {
      label: "State/ Province",
      type: "text",
      value: viewData?.state ? viewData?.state : "--",
      name: "state",
    },
    {
      label: "City",
      type: "text",
      value: viewData?.city ? viewData?.city : "--",
      name: "city",
    },
    {
      label: "ZIP code/ Postal code",
      type: "number",
      value: viewData?.pincode ? viewData?.pincode : "--",
      name: "pincode",
    },
  ];

  const itemsOnPage = [
    { option: 10 },
    { option: 20 },
    { option: 50 },
    { option: 70 },
    { option: 100 },
  ];

  //to set header
  useEffect(() => {
    setAdminActiveTab(SCHOOL);
  }, []);

  useEffect(() => {
    const debunce = setTimeout(() => {
      handleSchoolData();
    }, 300);

    return () => {
      clearTimeout(debunce);
    };
  }, [searchItem, sortby, orderby, itemOffset, itemsPerPage]);

  useEffect(() => {
    setCurrentItems(data);
    setPageCount(Math.ceil(dataCount / itemsPerPage));
  }, [data, dataCount, itemsPerPage]);

  //function to call API Data
  const handleOptions = (optionValue, item) => {
    switch (optionValue) {
      case VIEW:
        // loaderDispatch({
        //   isLoading: true,
        // });
        setOpenViewModal(true);
        setViewData(item);

        break;
      case UPDATE:
        navigate(`/admin/school/edit/${item.id}`);
        break;
      case DELETE:
        setOpenDeleteModal(true);
        setDeletedData(item.id);
        break;
      default:
        break;
    }
  };

  const handleSorting = (data, order) => {
    setSortby(data);
    setOrderby(order);
  };

  const handlePageClick = (event) => {
    let newOffset = (event.selected * itemsPerPage) % dataCount;
    setItemOffset(newOffset / 10 + 1);
  };

  let order = orderby ? "desc" : "asc";
  let body = {
    itemOffset: itemOffset,
    itemPerPage: itemsPerPage,
    keyWord: searchItem.trim(),
    sortName: sortby,
    sortTypeName: order,
  };

  const handleSchoolData = async () => {
    loaderDispatch({
      isLoading: true,
    });
    try {
      if (searchItem.toLowerCase() === "active") {
        setSearchItem("2");
      }
      if (searchItem.toLowerCase() === "inactive") {
        setSearchItem("1");
      }

      if (searchItem.toLowerCase() === "active") {
        setSearchItem("1");
      } else if (searchItem.toLowerCase() === "inactive") {
        setSearchItem("2");
      }

      let result = await getSchoolList(body);
      loaderDispatch({
        isLoading: false,
      });

      setData([]);
      setDataCount(0);
      if (
        result?.data?.status === StatusCodes.OK &&
        result?.data?.data?.schoolListing?.count > 0
      ) {
        const studentData = result?.data?.data?.schoolListing?.rows;
        const studentDataCount = result?.data?.data?.schoolListing?.count;
        setData(studentData);
        setDataCount(studentDataCount);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteData = async () => {
    try {
      let result = await adminSchoolDelete(deletedData);

      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
        });
        setOpenDeleteModal(false);
        handleSchoolData();
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
        setOpenDeleteModal(false);
      }
    } catch (error) {
      console.error(error);
      setOpenDeleteModal(false);
    }
  };

  return (
    <>
      <div className="p-2">
        <input
          type={"text"}
          placeholder={"Search"}
          onChange={(e) => {
            setSearchItem(e.target.value.trim());
            setItemOffset(1);
          }}
          autoComplete="cc-given-name"
          className="mt-1 block w-1/2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
        />
      </div>
      <AdminInviteModal body={body} dataCall={handleSchoolData} />

      <AdminDeleteModel
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        deleteData={deleteData}
        header={"Delete School"}
      />

      <AdminView
        viewData={viewData}
        openViewModal={openViewModal}
        setOpenViewModal={setOpenViewModal}
        view={view}
      />
      <ListLayout
        handleOptions={handleOptions}
        tableHeader={tableHeader}
        handleSorting={handleSorting}
        currentItems={currentItems}
        type={true}
      />
      <div className="flex justify-between">
        {data.length > 0 && (
          <div
            className="block lg:inline-block lg:mt-0 "
            style={{ marginTop: "6px" }}
          >
            <label htmlFor="item" className="ml-5">
              Record Per Page:
            </label>
            <select
              className="ml-3 bg-white border p-2 rounded"
              name="item"
              id="item"
              onChange={(e) => {
                setItemsPerPage(parseInt(e.target.value));
              }}
            >
              {itemsOnPage.map((val, i) => {
                return (
                  <option value={val.option} key={i}>
                    {val.option}
                  </option>
                );
              })}
            </select>
          </div>
        )}
        <div className="mt-2 mb-10">
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next >"
            onPageChange={handlePageClick}
            pageCount={pageCount}
            previousLabel="< Previous"
            renderOnZeroPageCount={null}
            containerClassName={"pagination"}
            pageClassName={"pageClass"}
            forcePage={itemOffset - 1}
          />
        </div>

        {data.length === 0 && (
          <div className="flex flex-row absolute left-1/2 top-1/2">
            <p>Data not found..!!</p>
          </div>
        )}
      </div>
    </>
  );
}

export default AdminSchoolManagment;
