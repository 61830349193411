import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";

import {
  DashboardMain,
  DashboardBody,
  DashboardRightBar,
  SiteForm,
  SiteCard,
} from "../../../common/website/HOC.jsx";
import { device } from "../../../common/website/Devices";
import { colors, space, siteGradient } from "../../../common/website/Variables";
import HeaderWithLogin from "../../../components/website/header/HeaderWithLogin copy.jsx";
import Classmates from "../../../components/website/Classmates/Classmates.jsx";
import RecentActivityEmpty from "../../../components/website/RecentActivity/RecentActivityEmpty.jsx";

import HeadingThree from "../../../common/website/HeadingThree.jsx";
import HeadingFour from "../../../common/website/HeadingFour.jsx";
import Paragraph from "../../../common/website/Paragraph.jsx";

import IconBluePhotograph from "../../../images/website/icon_blue_photograph.svg";
import SetProfilePhoto from "../../../components/website/modals/SetProfilePhoto.jsx";
import { useNavigate } from "react-router-dom";
import { admin, school, student, website } from "../../../linksprovider.js";
import { getSubscriptionDetail } from "../../../services/auth.services.js";
import { StatusCodes } from "http-status-codes";
import {
  ADMIN_USER_DATA,
  FRESHMEN,
  GRADUATED,
} from "../../../utils/constant.js";
import SearchInput from "./SearchInput.jsx";
import SetProfilePhotoNFT from "../../../components/website/modals/SetProfilePhotoNFT.jsx";
import { AppContext } from "../../../App.js";
import { toast } from "react-toastify";
import CommonModal from "../../../common/website/CommonModal.jsx";
import IconRight from "../../../images/website/icon_right.svg";
import { loaderContext } from "../../../components/global loader/global-loader-provider.js";

const UploadPhotoRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  .club_group {
    display: none !important;
  }
  h3,
  h4,
  p {
    position: static;
  }
  .sidebar_right {
    display: none;
  }
  .upload_head {
    text-align: center;
    margin: ${space.space24} 0;
  }
  .upload_card {
    border-radius: ${space.space16};
    padding: 64px ${space.space24};
    text-align: center;
    .add_head {
      margin: 18px 0 2px;
    }
  }
  .classmates_list {
    pointer-events: none;
    opacity: 0.4;
  }
  .sidebar_nav {
    .nav_list {
      .link {
        pointer-events: none;
        opacity: 0.4;
        &.logout {
          pointer-events: auto;
          opacity: 1;
        }
      }
    }
  }
  .upload_photos {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background-color: ${colors.lightblue};
    box-shadow: ${siteGradient.boxshadowblue};
    background-image: url(${IconBluePhotograph});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40px 40px;
    cursor: pointer;
    margin: auto;
    input {
      display: none;
    }
  }
  .link {
    &.notification {
      pointer-events: none;
      opacity: 0.4;
    }
  }
  @media ${device.mobileM} {
    .upload_photos {
      width: 100px;
      height: 100px;
      background-size: 54px 54px;
    }
    .upload_card {
      padding: ${space.space40} ${space.space24};
    }
  }
  @media ${device.tablet} {
    .link {
      &.notification {
        pointer-events: none;
        opacity: 1;
      }
    }
    .upload_head {
      margin: ${space.space24} 0;
    }
    .sidebar_right {
      width: 25%;
      display: block;
    }
    .mobile_hidden {
      display: block;
    }
    .UploadPhotoBody {
      width: 50%;
    }
    .classmates_list {
      height: calc(100vh - 470px);
    }
  }
  @media ${device.laptop} {
    .sidebar_right {
      width: 23%;
    }
    .upload_head {
      margin: ${space.space40} 0 ${space.space24};
    }
    .UploadPhotoBody {
      width: 54%;
    }
  }
  @media ${device.laptopL} {
    .sidebar_right {
      width: 20%;
    }
    .UploadPhotoBody {
      width: 60%;
    }
    .classmates_list {
      height: calc(100vh - 500px);
    }
  }
  @media ${device.desktop} {
    .UploadPhotoBody {
      width: 680px;
    }
  }
`;

function UploadPhoto() {
  const [openImageModal, setOpenImageModal] = useState(false);
  const [openImageModalNFT, setOpenImageModalNFT] = useState(false);
  const [imagefile, setImageFile] = useState({});
  const [fileObj, setFileObj] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [openCommonModal, setOpenCommonModal] = useState(false);
  const [fileTypeError, setFileTypeError] = useState(false);

  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  const { loaderDispatch } = useContext(loaderContext);

  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }
  let navigate = useNavigate();

  // Upload File
  const inputFile = useRef(null);
  const chooseFileBox = () => {
    inputFile.current.click();
  };

  // Choose profile image
  const handelChooseFile = (e) => {
    setOpenImageModal(false);
    console.log("openImageModal", e.target.files);

    if (e.target.files) {
      // Assuming only image
      let file = e?.target?.files[0];
      // setImageFile(e.target.files);
      if (
        file?.type === "image/png" ||
        file?.type === "image/jpg" ||
        file?.type === "image/jpeg"
      ) {
        let testUrl = URL.createObjectURL(file);
        setImageFile(testUrl);
        setOpenImageModal(true);
        setFileObj(file);
      } else {
        setFileTypeError(true);
      }
    }
  };
  // End Upload File

  useEffect(() => {
    if (userData && userData.user_type === 1) {
      navigate(admin.dashbord);
    } else if (userData && userData.user_type === 2) {
      navigate(school.dashbord);
    } else if (userData && userData.user_type === 3) {
      const getinfo = async () => {
        let user = {
          email: userData.email,
        };
        let subDetails = await getSubscriptionDetail(user);
        if (subDetails?.data?.status === 410) {
          handelLogout();
        }
        console.log(subDetails);
        let StudentInfo = subDetails?.data?.data;

        if (subDetails?.data?.status === StatusCodes.OK) {
          console.log(StudentInfo?.subscription_id === null);
          if (StudentInfo?.subscription_id === null) {
            navigate(student.subscribeOurPlan);
          } else if (
            StudentInfo?.subscription_id &&
            StudentInfo?.profile_picture &&
            StudentInfo?.is_plan_exist === "1"
          ) {
            navigate(student.post);
          } else if (
            StudentInfo?.subscription_id &&
            !StudentInfo?.profile_picture
          ) {
            navigate(student.dashbord);
            if (
              StudentInfo?.standard !== GRADUATED &&
              StudentInfo?.standard !== FRESHMEN &&
              StudentInfo?.is_promoted
            ) {
              setOpenCommonModal(true);
            }
          }
        }
        console.log("StudentInfo", StudentInfo);
      };
      getinfo();
    } else {
      navigate(website.studentLogin);
    }
    // showAdminPlans();
  }, []);

  const handelLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    // setUserData({});

    navigate(website.studentLogin);
  };

  return (
    <>
      <DashboardMain>
        <UploadPhotoRow>
          <HeaderWithLogin cname='sidebar_left' />
          <DashboardBody className='UploadPhotoBody'>
            <SiteForm as='div' className='upload_form'>
              <div className='form_group mobile_hidden'>
                <input
                  type='search'
                  name='search'
                  placeholder='Search classmate'
                  disabled={true}
                />
              </div>

              <HeadingThree
                cname='upload_head'
                h3Data={"Please upload your profile photo"}
              />

              <SiteCard className='upload_card'>
                <div className='upload_photos' onClick={chooseFileBox}>
                  {/* <HiPencil /> */}
                  <input
                    type='file'
                    ref={inputFile}
                    className='hidden'
                    accept='image/png,image/jpeg'
                    onChange={handelChooseFile}
                    value=''
                    // readOnly={editTable}
                  />
                </div>
                <HeadingFour cname='add_head' h4Data={"Add Profile Photo"} />
                {fileTypeError && (
                  <p className='text-sm text-red-500'>
                    File format not supported, please upload PNG, JPG, or JPEG
                    file
                  </p>
                )}
                {/* <Paragraph cname='noMargin' pData={"or drag and drop"} /> */}
              </SiteCard>
            </SiteForm>
          </DashboardBody>
          <DashboardRightBar className='sidebar_right'>
            <RecentActivityEmpty />
            <Classmates />
          </DashboardRightBar>
        </UploadPhotoRow>
        <SetProfilePhoto
          openImageModal={openImageModal}
          setOpenImageModal={setOpenImageModal}
          imagefile={imagefile}
          fileObj={fileObj}
          setOpenImageModalNFT={setOpenImageModalNFT}
        />
        <SetProfilePhotoNFT
          openImageModalNFT={openImageModalNFT}
          setOpenImageModalNFT={setOpenImageModalNFT}
        />
        <CommonModal
          setOpenCommonModal={setOpenCommonModal}
          openCommonModal={openCommonModal}
          header={"Upload Profile Picture"}
          message={"You are Promoted, Please Upload New Profile Picture"}
          icon={IconRight}
        />
      </DashboardMain>
    </>
  );
}

export default UploadPhoto;
