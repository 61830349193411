import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { SiteCard } from "../../../common/website/HOC.jsx";
import SiteLogo from "../../../common/website/SiteLogo.jsx";
import { colors, siteGradient, space } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";

import Paragraph from "../../../common/website/Paragraph.jsx";

import IconSignatures from "../../../images/website/icon_signatures.svg";
import IconSignaturesBlue from "../../../images/website/icon_blue_signatures.svg";

import IconHome from "../../../images/website/icon_nav_home.svg";
import IconHomeBlue from "../../../images/website/icon_blue_nav_home.svg";
import IconClub from "../../../images/website/icon_nav_club.svg";
import IconClubBlue from "../../../images/website/icon_blue_nav_club.svg";
import IconNotification from "../../../images/website/icon_nav_notification.svg";
import IconNotificationBlue from "../../../images/website/icon_blue_nav_notification.svg";
import IconChat from "../../../images/website/icon_nav_chat.svg";
import IconChatBlue from "../../../images/website/icon_blue_nav_chat.svg";
import IconAccount from "../../../images/website/icon_nav_account.svg";
import IconAccountBlue from "../../../images/website/icon_blue_nav_account.svg";
import IconLogout from "../../../images/website/icon_nav_logout.svg";
import IconLogoutBlue from "../../../images/website/icon_blue_nav_logout.svg";
import IconSearch from "../../../images/website/icon_nav_search.svg";
import IconSearchBlue from "../../../images/website/icon_blue_nav_search.svg";

import IconBack from "../../../images/website/icon_back.svg";
import IconBackBlue from "../../../images/website/icon_blue_back.svg";
import IconHistory from "../../../images/website/icon_history.svg";
import IconHistoryBlue from "../../../images/website/icon_blue_history.svg";
import IconPost from "../../../images/website/icon_Post.svg";
import IconPostBlue from "../../../images/website/icon_blue_Post.svg";
import IconSettings from "../../../images/website/icon_settings.svg";
import IconSettingsBlue from "../../../images/website/icon_blue_settings.svg";
import IconToggle from "../../../images/website/icon_toggle.svg";
import IconToggleBlue from "../../../images/website/icon_blue_toggle.svg";
import IconSportsTeam from "../../../images/website/icon_sports_team.svg";
import IconSportsTeamBlue from "../../../images/website/icon_blue_sports_team.svg";
import IconDigitalArtCreator from "../../../images/website/icon_digital_art_creator.svg";
import IconDigitalArtCreatorBlue from "../../../images/website/icon_blue_digital_art_creator.svg";
import IconVotingLeaderboard from "../../../images/website/icon_voting_leaderboard.svg";
import IconVotingLeaderboardBlue from "../../../images/website/icon_blue_voting_leaderboard.svg";
import { student, website } from "../../../linksprovider.js";
import { loaderContext } from "../../global loader/global-loader-provider.js";
import LogoutModal from "../modals/LogoutModal.jsx";
import { PostContext } from "./MainHeader.jsx";
import { AppContext } from "../../../App.js";
import { object } from "yup/lib/locale.js";
import blankProfile from "../../../images/website/dummy_user_profile.svg";
import {
  GRADUATED,
  MY_POST,
  VOTING_LEADER_BOARD,
  WEBSITE_BACK,
  WEBSITE_CHAT,
  WEBSITE_CLUB,
  WEBSITE_DIGITAL_ART,
  WEBSITE_GALLERY,
  WEBSITE_LOGOUT,
  WEBSITE_MY_ACCOUNT,
  WEBSITE_MY_MOMENTS,
  WEBSITE_NOTIFICATION,
  WEBSITE_SCHOOL_MOMENTS,
  WEBSITE_SETTINGS,
  WEBSITE_SIGNATURE,
  WEBSITE_SPORT_TEAM,
} from "../../../utils/constant.js";
import { schoolLabelListing } from "../../../services/auth.services.js";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";

const HeaderWithLoginRow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  .sidebar_card {
    height: 100%;
    background: ${colors.body};
    padding: ${space.space16} ${space.space20} 0;
    border-radius: 0;
  }
  .sidebar_top {
    padding-bottom: ${space.space16};
    border-bottom: 1px solid ${colors.lightwhiteborder};

    .user_profile {
      display: none;

      @media ${device.tablet} {
        display: block;
        height: ${space.space30};
        width: ${space.space30};
        margin-left: ${space.space8};
        flex-shrink: 0;

        border-radius: 100%;
        overflow: hidden;
      }

      @media ${device.laptopL} {
        height: ${space.space40};
        width: ${space.space40};
      }
    }
  }
  .sidebar_logo {
    a {
      display: block;
      img {
        max-width: 98px;
      }
    }
  }
  .sidebar_nav {
    position: fixed;
    top: 0;
    left: -310px;
    right: 0;
    bottom: 0;
    height: 100vh;
    padding: 1rem 1.25rem;
    visibility: hidden;
    transition: all 0.4s;
    opacity: 0;
    width: 300px;
    display: block;
    background: ${colors.darkgrey};
    border-right: 1px solid ${colors.lightwhiteborder};
    &.show {
      left: 0;
      visibility: visible;
      opacity: 1;
      transition: all 0.4s;
      &::before {
        left: 300px;
        right: 0;
        transition: all 0.4s;
      }
    }
    &::before {
      content: "";
      position: fixed;
      left: 100%;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgb(23 23 23 / 70%);
      transition: all 0.4s;
    }
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(172 172 172 / 10%);
      border-radius: 8px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    /* Fire Fox */
    scrollbar-color: rgb(172 172 172 / 10%) transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: transparent;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(172 172 172 / 10%);
      border-radius: 8px;
    }
    /* End Fire Fox */
  }
  .nav_list {
    display: block;
    height: calc(100vh - 116px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(172 172 172 / 10%);
      border-radius: 8px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    /* Fire Fox */
    scrollbar-color: rgb(172 172 172 / 10%) transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: transparent;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(172 172 172 / 10%);
      border-radius: 8px;
    }
    /* End Fire Fox */
    li {
      margin: 0 0 ${space.space16};
      display: block;
      &:last-child {
        margin: 0;
      }
    }
  }
  .link {
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    p {
      font-size: 0.875rem;
      line-height: 1rem;
      margin: 0;
      transition: all 0.4s;
      text-align: center;
    }
    .icon {
      display: block;
      flex-shrink: 0;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      background: ${colors.lightwhitecolor};
      box-shadow: ${siteGradient.boxshadowWhite};
      border-radius: 100%;
      margin: 0 ${space.space8} 0 0;
      position: relative;
      transition: all 0.4s;
      &::before,
      &::after {
        content: "";
        position: absolute;
        background-size: contain !important;
        width: 16px;
        height: 16px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        transition: all 0.4s;
      }
      &::before {
        opacity: 1;
        transition: all 0.4s;
      }
      &::after {
        opacity: 0;
        transition: all 0.4s;
      }
    }
    &.active,
    &:hover {
      .icon {
        background: ${colors.lightblueColor};
        box-shadow: ${siteGradient.boxshadowblue};
        transition: all 0.4s;
        &::before {
          opacity: 0;
          transition: all 0.4s;
        }
        &::after {
          opacity: 1;
          transition: all 0.4s;
        }
      }
      p {
        color: ${colors.primary};
        transition: all 0.4s;
      }
    }
    &.home {
      .icon {
        &::before {
          background: url(${IconHome}) no-repeat center;
        }
        &::after {
          background: url(${IconHomeBlue}) no-repeat center;
        }
      }
    }
    &.club {
      .icon {
        &::before {
          background: url(${IconClub}) no-repeat center;
        }
        &::after {
          background: url(${IconClubBlue}) no-repeat center;
        }
      }
    }
    &.sports_team {
      .icon {
        &::before {
          background: url(${IconSportsTeam}) no-repeat center;
        }
        &::after {
          background: url(${IconSportsTeamBlue}) no-repeat center;
        }
      }
    }
    &.digital_art_creator {
      .icon {
        &::before {
          background: url(${IconDigitalArtCreator}) no-repeat center;
        }
        &::after {
          background: url(${IconDigitalArtCreatorBlue}) no-repeat center;
        }
      }
    }
    &.voting_leaderboard {
      .icon {
        &::before {
          background: url(${IconVotingLeaderboard}) no-repeat center;
        }
        &::after {
          background: url(${IconVotingLeaderboardBlue}) no-repeat center;
        }
      }
    }
    &.chat {
      .icon {
        &::before {
          background: url(${IconChat}) no-repeat center;
        }
        &::after {
          background: url(${IconChatBlue}) no-repeat center;
        }
      }
      .count {
        position: absolute;
        font-size: 10px;
        line-height: 11px;
        color: ${colors.white};
        width: 15px;
        height: 15px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${siteGradient.bluePink};
        right: -2px;
        top: 0;
      }
    }
    &.account {
      .icon {
        &::before {
          background: url(${IconAccount}) no-repeat center;
        }
        &::after {
          background: url(${IconAccountBlue}) no-repeat center;
        }
      }
    }
    &.notification {
      .icon {
        &::before {
          background: url(${IconNotification}) no-repeat center;
        }
        &::after {
          background: url(${IconNotificationBlue}) no-repeat center;
        }
      }
      .count {
        position: absolute;
        font-size: 10px;
        line-height: 11px;
        color: ${colors.white};
        width: 15px;
        height: 15px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${siteGradient.bluePink};
        right: -2px;
        top: 0;
      }
    }
    &.logout {
      .icon {
        &::before {
          background: url(${IconLogout}) no-repeat center;
        }
        &::after {
          background: url(${IconLogoutBlue}) no-repeat center;
        }
      }
    }
    &.search {
      cursor: pointer;
      .icon {
        &::before {
          background: url(${IconSearch}) no-repeat center;
        }
        &::after {
          background: url(${IconSearchBlue}) no-repeat center;
        }
      }
    }
    &.back {
      .icon {
        &::before {
          background: url(${IconBack}) no-repeat center;
        }
        &::after {
          background: url(${IconBackBlue}) no-repeat center;
        }
      }
    }
    &.mymoments {
      .icon {
        &::before {
          background: url(${IconHistory}) no-repeat center;
        }
        &::after {
          background: url(${IconHistoryBlue}) no-repeat center;
        }
      }
    }
    &.mypost {
      .icon {
        &::before {
          background: url(${IconPost}) no-repeat center;
        }
        &::after {
          background: url(${IconPostBlue}) no-repeat center;
        }
      }
    }
    &.settings {
      .icon {
        &::before {
          background: url(${IconSettings}) no-repeat center;
        }
        &::after {
          background: url(${IconSettingsBlue}) no-repeat center;
        }
      }
    }
    &.signatures {
      .icon {
        &::before {
          background: url(${IconSignatures}) no-repeat center;
        }
        &::after {
          background: url(${IconSignaturesBlue}) no-repeat center;
        }
      }
    }
    &.toggle {
      display: block;
      cursor: pointer;
      margin-left: ${space.space16};
      .icon {
        &::before {
          background: url(${IconToggle}) no-repeat center;
        }
        &::after {
          background: url(${IconToggleBlue}) no-repeat center;
        }
      }
    }
  }
  .nav_list {
    .item_notification {
      display: none;
    }
    .item_chat {
      display: none;
    }
  }
  .sidebar_top,
  .mobile_nave {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .search {
      margin-right: ${space.space16};
    }
    .icon {
      margin: 0;
    }
  }
  .searchbar_hidden,
  .searchbar_show {
    position: fixed;
    left: 0;
    right: 0;
    background: #0b0b0b;
    z-index: 9;
    top: 0;
    bottom: 0;
    padding: 20px;
    transition: all 0.6s;
  }
  .searchbar_hidden {
    visibility: hidden;
    opacity: 0;
  }
  .searchbar_show {
    visibility: visible;
    opacity: 1;
  }
  .mobile_searchbar {
    .form_group {
      display: flex;
      align-items: center;
      grid-gap: 10px;
    }
    .close_btn {
      position: relative;
      border: 1px solid ${colors.inputbordercolor};
      border-radius: ${space.space8};
      width: 37px;
      height: 37px;
      flex-shrink: 0;
      &::after,
      &::before {
        content: "";
        position: absolute;
        background: ${colors.white};
        width: 16px;
        height: 2px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
      &::after {
        transform: rotate(45deg);
      }
      &::before {
        transform: rotate(135deg);
      }
    }
  }
  .mobile_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${space.space16};
    padding-bottom: ${space.space14};
    border-bottom: 1px solid ${colors.lightwhiteborder};
    .colse_btn {
      display: block;
      flex-shrink: 0;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      background: ${colors.lightwhitecolor};
      box-shadow: ${siteGradient.boxshadowWhite};
      border-radius: 100%;
      margin: 0;
      cursor: pointer;
      position: relative;
      transition: all 0.4s;
      &::before,
      &::after {
        content: "";
        position: absolute;
        background: ${colors.white};
        width: 12px;
        height: 2px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        transition: all 0.4s;
      }
      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(135deg);
      }
      &:hover {
        background: ${colors.lightblueColor};
        box-shadow: ${siteGradient.boxshadowblue};
        transition: all 0.4s;
        &::before,
        &::after {
          background: ${colors.primary};
        }
      }
    }
  }
  @media ${device.mobileM} {
    .sidebar_logo {
      a {
        img {
          max-width: 120px;
          max-height: 80px;
        }
      }
    }
    .mobile_searchbar {
      .close_btn {
        width: 41px;
        height: 41px;
      }
    }
    .sidebar_nav {
      padding: 1rem 1.25rem;
    }
    .sidebar_card {
      padding: ${space.space20} ${space.space20} 0;
    }
  }
  @media ${device.mobileL} {
  }
  @media ${device.tablet} {
    width: 25%;
    position: sticky;
    top: 0;
    left: auto;
    right: auto;
    .searchbar_hidden,
    .searchbar_hidden,
    .mobile_header {
      display: none;
    }
    .toggle {
      display: none;
    }
    .mobile_nave {
      display: none;
    }
    .nav_list {
      li {
        margin: 0 0 ${space.space20};
      }
      .item_notification {
        display: block;
      }
      .item_chat {
        display: block;
      }
    }
    .sidebar_card {
      padding: ${space.space20} ${space.space16};
      background: ${colors.darkgrey};
      border-radius: ${space.space16};
    }
    .sidebar_top {
      padding-bottom: ${space.space24};
    }
    .sidebar_nav {
      position: static;
      padding: 0;
      height: calc(100vh - 176px);
      overflow-y: auto;
      margin-top: ${space.space24};
      width: auto;
      border-right: none;
      visibility: visible;
      opacity: 1;
      transition: all 0s;
      &::before {
        display: none;
      }
    }
    .nav_list {
      height: auto;
      overflow-y: hidden;
      .link {
        p {
          text-align: start;
        }
      }
    }
  }
  @media ${device.laptop} {
    width: 23%;
    .sidebar_nav {
      height: calc(100vh - 190px);
    }
    .sidebar_card {
      padding: ${space.space24};
    }
    .sidebar_logo {
      a {
        img {
          max-width: 140px;
        }
      }
    }
    .nav_list {
      li {
        margin-bottom: ${space.space30};
      }
      .link {
        .icon {
          width: 36px;
          height: 36px;
          margin: 0 ${space.space12} 0 0;
          &::before,
          &::after {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
  @media ${device.laptopL} {
    width: 20%;
    .sidebar_nav {
      height: calc(100vh - 210px);
    }
    .link {
      p {
        font-size: 1rem;
      }
    }
  }
`;

function HeaderWithLogin(props) {
  // Add class in Searchbar
  const [isActive, setIsActive] = useState(false);
  let navigate = useNavigate();

  const [logoutmodal, setLogoutModal] = useState(false);
  const { userInfo, newNotificationCount } = useContext(PostContext);
  const { loaderDispatch } = useContext(loaderContext);

  const handleClick = (event) => {
    setIsActive((current) => !current);
  };
  // End Add class in Searchbar

  // Sub Menu Show Hidden
  let [changeText, setChangeText] = useState(true);
  const handleChange = (value) => {
    setChangeText(value);
  };
  // End Sub Menu Show Hidden

  let location = useLocation();

  // Main Menu
  const [menuList, setMenuList] = useState([
    {
      liClassName: "item_home",
      linkTo: student.post,
      linkClass: "link home",
      spanClassName: "icon",
      pData: WEBSITE_SCHOOL_MOMENTS,
      active: "active",
      onClick: () => handleClick(false),
    },
    {
      liClassName: "item_mymoments",
      linkTo: student.myMoment,
      linkClass: "link mymoments",
      spanClassName: "icon",
      pData: WEBSITE_MY_MOMENTS,
      active: "",
      onClick: () => handleClick(false),
    },
    {
      liClassName: "item_club",
      linkTo: student.club,
      linkClass: "link club",
      spanClassName: "icon",
      pData: WEBSITE_CLUB,
      active: "",
      onClick: () => handleClick(false),
    },
    {
      liClassName: "item_sports_team",
      linkTo: student.sports,
      linkClass: "link sports_team",
      spanClassName: "icon",
      pData: WEBSITE_SPORT_TEAM,
      active: "",
      onClick: () => handleClick(false),
    },
    {
      liClassName: "item_digital_art_creator",
      linkTo: student.digitalArt,
      linkClass: "link digital_art_creator",
      spanClassName: "icon",
      pData: WEBSITE_DIGITAL_ART,
      active: "",
      onClick: () => handleClick(false),
    },
    {
      liClassName: "item_voting_leaderboard",
      linkTo: student.voting,
      linkClass: "link voting_leaderboard",
      spanClassName: "icon",
      pData: VOTING_LEADER_BOARD,
      active: "",
      onClick: () => handleClick(false),
    },
    {
      liClassName: "item_chat",
      linkTo: student.chat,
      linkClass: "link chat",
      spanClassName: "icon",
      pData: WEBSITE_CHAT,
      countClassName: "count",
      countData: userInfo?.totalUnreadMessages,
      active: "",
      onClick: () => handleClick(false),
    },
    {
      liClassName: "item_account",
      linkTo: "#",
      linkClass: "link account",
      spanClassName: "icon",
      pData: WEBSITE_MY_ACCOUNT,
      active: "",
      onClick: () => handleChange(false),
    },

    {
      liClassName: "item_notification",
      linkTo: student.notification,
      linkClass: "link notification",
      spanClassName: "icon",
      pData: WEBSITE_NOTIFICATION,
      countClassName: "count",
      countData: newNotificationCount,
      active: "",
      onClick: () => handleClick(false),
    },

    {
      liClassName: "item_logout",
      linkTo: "#",
      linkClass: "link logout",
      spanClassName: "icon",
      pData: WEBSITE_LOGOUT,
      active: "",
      onClick: () => handelLogoutModal(),
    },
  ]);

  // Sub Main Menu
  const [menuList2, setMenuList2] = useState([
    {
      liClassName: "item_back",
      linkTo: "#",
      linkClass: "link back",
      spanClassName: "icon",
      pData: WEBSITE_BACK,
      onClick: () => handleChange(true),
    },
    {
      liClassName: "item_mymoments",
      linkTo: student.gallery,
      linkClass: "link mymoments",
      spanClassName: "icon",
      pData: WEBSITE_GALLERY,
      active: "",
      onClick: () => handleClick(false),
    },
    {
      liClassName: "item_mypost",
      linkTo: student.myPost,
      linkClass: "link mypost",
      spanClassName: "icon",
      pData: MY_POST,
      active: "",
      onClick: () => handleClick(false),
    },
    {
      liClassName: "item_signatures",
      linkTo: student.signatures,
      linkClass: "link signatures",
      spanClassName: "icon",
      pData: WEBSITE_SIGNATURE,
      active: "",
      onClick: () => handleClick(false),
    },
    {
      liClassName: "item_settings",
      linkTo: student.setting,
      linkClass: "link settings",
      spanClassName: "icon",
      pData: WEBSITE_SETTINGS,
      active: "",
      onClick: () => handleClick(false),
    },
  ]);

  useEffect(() => {
    getNewLabelList();
  }, [newNotificationCount, location.pathname, userInfo]);

  // useEffect(() => {
  //   setMenuList((preVal) => {
  //     for (let i = 0; i < preVal.length; i++) {
  //       if (preVal?.linkTo === student.chat) {
  //         preVal[i] = {
  //           ...preVal[i],
  //           countData: userInfo?.totalUnreadMessages,
  //         };
  //       }
  //     }
  //   });
  // }, [userInfo?.totalUnreadMessages]);

  const handelNotificationCount = () => {
    setMenuList((preVal) => {
      for (let i = 0; i < preVal.length; i++) {
        if (preVal[i].linkTo === student.notification) {
          preVal[i].countData = newNotificationCount;
        }
      }

      return [...preVal];
    });
  };

  const handelPreSelectedClass = (dummyArray) => {
    for (let i = 0; i < dummyArray.length; i++) {
      if (dummyArray[i].linkTo.includes(location.pathname)) {
        handleAddClass(i);
        handleAddClass2();
        handelNotificationCount();
        return;
      }
    }
    for (let i = 1; i < menuList2.length; i++) {
      if (menuList2[i].linkTo.includes(location.pathname)) {
        handleChange(false);
        handleAddClass2(i);
        handelNotificationCount();
        return;
      }
    }
  };

  const handelRemovalOfMenu = () => {
    if (Object.keys(userInfo).length > 0) {
      if (!userInfo?.club_id || userInfo?.club_status === 2) {
        setMenuList((preVal) => {
          return preVal?.filter((val, j) => val?.linkTo !== student.club);
        });
      }

      if (!userInfo?.sport_id || userInfo?.sport_status === 2) {
        setMenuList((preVal) => {
          return preVal?.filter((val, j) => val?.linkTo !== student.sports);
        });
      }

      if (userInfo?.standard?.toLowerCase() === GRADUATED) {
        setMenuList((preVal) => {
          return preVal?.filter((val, j) => val?.linkTo !== student.voting);
        });
      }
    }
    setMenuList((preVal) => {
      handelPreSelectedClass(preVal);
      return preVal;
    });
  };

  const handleAddClass2 = (index = null) => {
    if (index !== 0) {
      setMenuList2((preVal) => {
        for (let i = 0; i < preVal.length; i++) {
          preVal[i].active = "";
        }
        if (index) {
          preVal[index].active = "active";
        }
        return [...preVal];
      });
    }
  };

  const handleAddClass = (index) => {
    setMenuList((preVal) => {
      for (let i = 0; i < preVal.length; i++) {
        preVal[i].active = "";
      }
      preVal[index].active = "active";
      return [...preVal];
    });
  };

  const handelLogoutModal = () => {
    setLogoutModal((preVal) => !preVal);
  };

  const getNewLabelList = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let params = {
        id: userInfo?.User?.id,
        user_type: 3,
      };

      let result = await schoolLabelListing(params);
      loaderDispatch({
        isLoading: false,
      });

      if (result?.data?.status === StatusCodes.OK) {
        const data = result?.data?.data?.labelListing?.rows;
        setMenuList2((preVal) => {
          for (let j = 0; j < data?.length; j++) {
            for (let i = 0; i < preVal?.length; i++) {
              if (
                data[j]?.MasterLabel?.name === preVal[i]?.pData &&
                data[j]?.status === 1
              ) {
                preVal[i].pData = data[j]?.labelName;
                console.log("data[j]?.labelName", data[j]?.labelName);
              }
            }
          }
          return [...preVal];
        });
        setMenuList((preVal) => {
          for (let j = 0; j < data?.length; j++) {
            for (let i = 0; i < preVal?.length; i++) {
              if (
                data[j]?.MasterLabel?.name === preVal[i]?.pData &&
                data[j]?.status === 1
              ) {
                preVal[i].pData = data[j]?.labelName;
              }
            }
          }
          return [...preVal];
        });
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
      handelRemovalOfMenu();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <HeaderWithLoginRow className={props.cname}>
        <SiteCard className='sidebar_card'>
          <div className='sidebar_top'>
            <SiteLogo
              cname='sidebar_logo'
              link={student?.post}
              customLogo={userInfo?.school_logo}
            />
            <div className='user_profile'>
              <Link to={student?.setting}>
                <img
                  src={
                    userInfo?.filtered_image
                      ? userInfo?.filtered_image
                      : blankProfile
                  }
                  alt='Profile_Image'
                />
              </Link>
            </div>
            <div className='mobile_nave'>
              {/* <span className='link search' onClick={handleClick}>
                <span className='icon'></span>
              </span> */}

              {/* <div className={isActive ? "searchbar_show" : "searchbar_hidden"}>
                <SiteForm as='div' className='mobile_searchbar'>
                  <div className='form_group'>
                    <SearchInput />
                    <button
                      className='close_btn'
                      onClick={handleClick}></button>
                  </div>
                </SiteForm>
              </div> */}
              <Link to={student.chat} className='link chat mr-4'>
                <span className='icon'>
                  {menuList?.map((val, i) => {
                    if (val?.linkTo === student.chat) {
                      return val?.countData > 0 ? (
                        <span className='count' key={i}>
                          {userInfo?.totalUnreadMessages
                            ? userInfo?.totalUnreadMessages
                            : 0}
                        </span>
                      ) : null;
                    }
                  })}
                </span>
              </Link>

              <Link to={student.notification} className='link notification'>
                <span className='icon'>
                  {menuList?.map((val, i) => {
                    if (val?.linkTo === student.notification) {
                      return val?.countData > 0 ? (
                        <span className='count' key={i}>
                          {newNotificationCount ? newNotificationCount : 0}
                        </span>
                      ) : null;
                    }
                  })}
                </span>
              </Link>
              <Link to='#' className='link toggle' onClick={handleClick}>
                <span className='icon'></span>
              </Link>
            </div>
          </div>

          <nav className={isActive ? "sidebar_nav show" : "sidebar_nav"}>
            <div className='mobile_header'>
              <SiteLogo
                cname='sidebar_logo'
                link={student?.post}
                customLogo={userInfo?.school_logo}
              />
              <span className='colse_btn' onClick={handleClick}></span>
            </div>
            {changeText ? (
              <ul className='nav_list'>
                {menuList?.map((val, i) => {
                  return (
                    <li
                      className={val.liClassName}
                      key={i}
                      onClick={() => {
                        handleAddClass(i);
                        val?.onClick();
                      }}>
                      <Link
                        to={val.linkTo}
                        className={`${val.linkClass} ${val.active}`}>
                        <span className={val.spanClassName}>
                          {val?.linkTo === student.notification &&
                            newNotificationCount > 0 && (
                              <Paragraph
                                cname={val.countClassName}
                                pData={newNotificationCount}
                              />
                            )}
                          {val?.linkTo === student.chat &&
                            userInfo?.totalUnreadMessages > 0 && (
                              <Paragraph
                                cname={val.countClassName}
                                pData={userInfo?.totalUnreadMessages}
                              />
                            )}
                        </span>

                        <Paragraph pData={val.pData} />
                      </Link>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <ul className='nav_list sub_nav_list'>
                {menuList2?.map((val, i) => {
                  return (
                    <li
                      className={val.liClassName}
                      key={i}
                      onClick={() => {
                        handleAddClass2(i);
                        val.onClick();
                      }}>
                      <Link
                        to={val.linkTo}
                        className={`${val.linkClass} ${val.active}`}>
                        <span className={val.spanClassName}>
                          <Paragraph
                            cname={val.countClassName}
                            pData={val.countData}
                          />
                        </span>
                        <Paragraph pData={val.pData} />
                      </Link>
                    </li>
                  );
                })}
              </ul>
            )}
          </nav>
        </SiteCard>
      </HeaderWithLoginRow>
      <LogoutModal
        setDeleteModal={setLogoutModal}
        openDeleteModal={logoutmodal}
      />
    </>
  );
}

export default HeaderWithLogin;
