import React, { useState } from "react";

function YearSelection({ setacadmicYear, checkHistory, academicYear }) {
  const [errorMessage, setErrorMessage] = useState("");

  let currentYear = new Date().getFullYear();
  const years = [
    {
      name: "Select Academic Year",
      value: "",
    },
    {
      name: currentYear - 5,
      value: currentYear - 5,
    },
    {
      name: currentYear - 4,
      value: currentYear - 4,
    },
    { name: currentYear - 3, value: currentYear - 3 },
    { name: currentYear - 2, value: currentYear - 2 },
    { name: currentYear - 1, value: currentYear - 1 },
    {
      name: currentYear,
      value: currentYear,
    },
  ];

  return (
    <>
      <div className='mb-6 text-lg text-gray-600'>
        <b>YearBook</b>
      </div>
      <div className=' w-full h-auto border border-gray-500 py-4'>
        <div className='grid grid-cols-2 text-sm '>
          <div className='pl-4  '>
            <select
              name='academic_year'
              className='bg-white border border-gray-300 px-4 py-2 rounded shadow'
              onChange={(e) => {
                setacadmicYear(e.target.value);
                setErrorMessage("");
              }}>
              {years.map((year, i) => {
                return (
                  <option value={year?.value} key={i}>
                    {year?.name}
                  </option>
                );
              })}
            </select>
            <p className='text-sm text-red-500'>{errorMessage}</p>
          </div>
          <div className=''>
            <button
              className='bg-indigo-500 text-white px-4 py-2 rounded hover:bg-indigo-600 shadow font-semibold'
              onClick={() =>
                academicYear
                  ? checkHistory(false)
                  : setErrorMessage("Please select academic year")
              }>
              Publish YearBook
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default YearSelection;
