import React, { Fragment, useContext, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import styled from "styled-components";
import HeadingFive from "../../../common/website/HeadingFive.jsx";
import { GrClose } from "react-icons/gr";
import { Formik } from "formik";
import { SiteModal, SiteForm } from "../../../common/website/HOC.jsx";
import { space } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";

import boxImage1 from "../../../images/website/imageBox_image1.png";
import boxImage2 from "../../../images/website/imageBox_image2.png";
import boxImage3 from "../../../images/website/imageBox_image3.png";
import boxImage4 from "../../../images/website/imageBox_image4.png";
import boxImage5 from "../../../images/website/imageBox_image5.png";
import boxImage6 from "../../../images/website/imageBox_image6.png";
import boxImage7 from "../../../images/website/imageBox_image7.png";
import boxImage8 from "../../../images/website/imageBox_image8.png";
import boxImage9 from "../../../images/website/imageBox_image9.png";
import boxImage10 from "../../../images/website/imageBox_image10.png";
import boxImage11 from "../../../images/website/imageBox_image11.png";
import boxImage12 from "../../../images/website/imageBox_image12.png";
import boxImage13 from "../../../images/website/imageBox_image13.png";
import boxImage14 from "../../../images/website/imageBox_image14.png";
import boxImage15 from "../../../images/website/imageBox_image15.png";

import VotingImageBox from "../../../common/website/VotingImageBox.jsx";
import Button from "../../../common/website/Button.jsx";
import { loaderContext } from "../../global loader/global-loader-provider.js";
import { PostContext } from "../header/MainHeader.jsx";
import {
  getAlreadyVotedDetails,
  getDigitalArt,
  getDigitalArtWithCount,
  schoolVoteCategoryListing,
  submitVotes,
} from "../../../services/auth.services.js";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";

const VotingModalRow = styled.div`
  .modal_inner {
    max-width: 1250px;
  }
  .model_top {
    margin-bottom: ${space.space16};
  }
  .image_box_list {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    grid-gap: ${space.space16};
    width: 100%;
    margin-bottom: ${space.space24};
    max-height: 362px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(172 172 172 / 10%);
      border-radius: 8px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    /* Fire Fox */
    scrollbar-color: rgb(172 172 172 / 10%) transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: transparent;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(172 172 172 / 10%);
      border-radius: 8px;
    }
    /* End Fire Fox */
  }
  .image_box_btn {
    text-align: center;
  }
  @media ${device.mobileM} {
    .image_box_list {
      grid-gap: 22px;
      max-height: 585px;
      margin-bottom: ${space.space40};
    }
  }
  @media ${device.laptopL} {
    .model_top {
      margin-bottom: ${space.space30};
    }
  }
  @media (min-width: 1250px) {
    .image_box_list {
      justify-content: flex-start;
    }
  }
`;

function VotingModal({
  openImageModal,
  setOpenImageModal,
  digitalVotedStatus,
  setDigitalVotedStatus,
}) {
  //state to store make digital art select and deselected
  const [selected, setSelected] = useState([]);

  const [users, setUsers] = useState([]);

  const [error, setError] = useState(false);

  const [data, setData] = useState({});

  const [body, setBody] = useState({});

  //state to store digital art data
  const [digitalArtData, setDigitalArtData] = useState([]);

  // const [digitalVotedStatus, setDigitalVotedStatus] = useState(true);

  //getting variables from post context
  const { userInfo } = useContext(PostContext);

  //getter loader
  const { loaderDispatch } = useContext(loaderContext);

  //getting digitalArts on modal open
  useEffect(() => {
    if (openImageModal) {
      gettingDigitalArt();
      handleChooseDigitalArt({});
      handelSchoolVoteCategoryListing();
      gettingAlreadyVotedDetails();
    }
  }, [openImageModal]);

  //function to get category list
  const handelSchoolVoteCategoryListing = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });

      let params = {
        id: userInfo?.User.id,
        userType: userInfo?.user_type,
      };

      let result = await schoolVoteCategoryListing(params);

      loaderDispatch({
        isLoading: false,
      });
      setData([]);

      if (result?.data?.status === StatusCodes.OK) {
        const studentData = result?.data?.data?.categoryListing;
        const studentDataCount = result?.data?.data;

        for (let i = 0; i < studentData.length; i++) {
          if (studentData[i]?.type === 2) setData(studentData[i]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  //get digital art data
  const gettingDigitalArt = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let params = {
        id: userInfo?.User?.id,
        search: "",
        userId: userInfo?.id,
        academicYear: userInfo?.academic_year,
        standard: userInfo?.standard,
      };

      let result = await getDigitalArtWithCount(params);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        let data = result?.data?.data?.docs;
        for (let i = 0; i < data.length; i++) {
          data[i].checked = false;
          data[i].name = `checked${i + 1}`;
        }
        setDigitalArtData(data);
        toMakeCheckedArray(data);
      } else {
        toast(result?.data?.massage, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toMakeCheckedArray = (data) => {
    for (let i = 0; i < data?.length; i++) {
      setSelected((preVal) => {
        return [...preVal, { name: `checked${i + 1}`, checked: false }];
      });
    }
  };

  //called when one digital art get selected
  const handleChooseDigitalArt = ({
    name = null,
    userId = null,
    post_id = null,
  }) => {
    setBody({});
    setError(false);
    if (name) {
      setDigitalArtData((preVal) => {
        for (let i = 0; i < preVal?.length; i++) {
          if (preVal[i]?.name === name) {
            preVal[i].checked = true;
          } else {
            preVal[i].checked = false;
          }
        }
        return [...preVal];
      });
    } else {
      setDigitalArtData((preVal) => {
        for (let i = 0; i < preVal?.length; i++) {
          preVal[i].checked = false;
        }
        return [...preVal];
      });
    }

    setBody({
      type: 2,
      school_id: userInfo?.User?.id || "",
      action_user_id: userInfo?.id || "",
      user_id: userId || "",
      category_id: data?.id || "",
      post_id: post_id || "",
      category_name: data?.name || "",
      academic_year: parseInt(userInfo?.academic_year) || "",
      digital_art: true,
      standard: userInfo?.standard || "",
    });
  };

  const handelSubmit = async () => {
    setError(false);
    try {
      if (body?.post_id !== "") {
        loaderDispatch({
          isLoading: true,
        });
        let result = await submitVotes(body);
        loaderDispatch({
          isLoading: false,
        });

        if (result?.data?.status === StatusCodes.OK) {
          toast(result?.data?.message, {
            type: "success",
            theme: "dark",
          });
          // setUsers([]);
          gettingDigitalArt();
          gettingAlreadyVotedDetails();
        } else {
          toast(result?.data?.message, {
            type: "error",
            theme: "dark",
          });
        }
      } else {
        setError(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const gettingAlreadyVotedDetails = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let params = {
        action_user_id: userInfo?.id,
        type: 2,
      };
      let result = await getAlreadyVotedDetails(params);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        let datas = result?.data?.data[0];
        if (datas) {
          setDigitalVotedStatus(false);
          setDigitalArtData((preVal) => {
            for (let i = 0; i < preVal.length; i++) {
              if (preVal[i].id === datas?.post_id) {
                preVal[i].checked = true;
              }
            }
            return [...preVal];
          });
        }

        // setVotedData(datas);
      } else {
        toast(result?.data?.message, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Transition.Root show={openImageModal} as={Fragment}>
      <Dialog as='div' static open={openImageModal} onClose={setOpenImageModal}>
        <VotingModalRow>
          <SiteModal>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
              <div className='modal_inner'>
                <div className='modal_boday'>
                  <div className='model_top'>
                    <HeadingFive
                      cname='title'
                      h5Data={"Vote for the best Digital Art!"}
                    />
                    <span
                      className='modal_close'
                      onClick={() => setOpenImageModal(false)}>
                      {" "}
                      <GrClose />
                    </span>
                  </div>

                  <div className='modal_content'>
                    <SiteForm as='div' className='upload_form'>
                      <div className='image_box_list'>
                        {digitalArtData?.length > 0 ? (
                          digitalArtData?.map((val, i) => {
                            return (
                              <VotingImageBox
                                boxImage={val?.postName}
                                NumberCount={val?.votes}
                                checkboxName={val?.name}
                                key={i}
                                post_id={val?.id}
                                defaultChecked={val?.checked}
                                handleChooseDigitalArt={handleChooseDigitalArt}
                                userId={val?.User?.id}
                              />
                            );
                          })
                        ) : (
                          <p>No data found..!!</p>
                        )}
                        {/* <VotingImageBox
                          boxImage={boxImage1}
                          NumberCount={"12"}
                          checkboxName={"checked"}
                        />
                        <VotingImageBox
                          boxImage={boxImage2}
                          NumberCount={"8"}
                          checkboxName={"checked2"}
                        />
                        <VotingImageBox
                          boxImage={boxImage3}
                          NumberCount={"18"}
                          checkboxName={"checked3"}
                        />
                        <VotingImageBox
                          boxImage={boxImage4}
                          NumberCount={"5"}
                          checkboxName={"checked4"}
                        />
                        <VotingImageBox
                          boxImage={boxImage5}
                          NumberCount={"4"}
                          checkboxName={"checked5"}
                        />
                        <VotingImageBox
                          boxImage={boxImage6}
                          NumberCount={"2"}
                          checkboxName={"checked6"}
                        />
                        <VotingImageBox
                          boxImage={boxImage7}
                          NumberCount={"15"}
                          checkboxName={"checked7"}
                        />
                        <VotingImageBox
                          boxImage={boxImage8}
                          NumberCount={"8"}
                          checkboxName={"checked8"}
                        />
                        <VotingImageBox
                          boxImage={boxImage9}
                          NumberCount={"0"}
                          checkboxName={"checked9"}
                        />
                        <VotingImageBox
                          boxImage={boxImage10}
                          NumberCount={"4"}
                          checkboxName={"checked10"}
                        />
                        <VotingImageBox
                          boxImage={boxImage11}
                          NumberCount={"2"}
                          checkboxName={"checked11"}
                        />
                        <VotingImageBox
                          boxImage={boxImage12}
                          NumberCount={"6"}
                          checkboxName={"checked12"}
                        />
                        <VotingImageBox
                          boxImage={boxImage13}
                          NumberCount={"0"}
                          checkboxName={"checked13"}
                        />
                        <VotingImageBox
                          boxImage={boxImage14}
                          NumberCount={"7"}
                          checkboxName={"checked14"}
                        />
                        <VotingImageBox
                          boxImage={boxImage15}
                          NumberCount={"12"}
                          checkboxName={"checked15"}
                        /> */}
                      </div>
                      <div className='image_box_btn'>
                        {error && (
                          <p className='text-center text-sm text-red-600'>
                            Select atleast one digital art
                          </p>
                        )}
                        {!digitalVotedStatus ? (
                          <p>You have already voted</p>
                        ) : (
                          <Button
                            cname='gradient btn'
                            buttonData={"Submit vote"}
                            onClick={handelSubmit}
                          />
                        )}
                      </div>
                    </SiteForm>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </SiteModal>
        </VotingModalRow>
      </Dialog>
    </Transition.Root>
  );
}
export default VotingModal;
