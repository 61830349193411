import React, { useState } from "react";
import styled from "styled-components";

import { SiteCard } from "../../../common/website/HOC.jsx";
import { colors, space } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";
import ReadMore from "./ReadMore.jsx";
import Paragraph from "../../../common/website/Paragraph.jsx";

const RecentActivityEmptyRow = styled.div`
  .about_card {
    border-radius: ${space.space16};
    margin-bottom: ${space.space24};
    .head {
      margin-bottom: ${space.space14};
    }
    .description {
      font-size: 0.875rem;
      line-height: 1.313rem;
      margin: 0;
      height: 134px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background: rgb(172 172 172 / 10%);
        border-radius: 8px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      /* Fire Fox */
      scrollbar-color: rgb(172 172 172 / 10%) transparent;
      scrollbar-width: thin;
      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: transparent;
        border-radius: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgb(172 172 172 / 10%);
        border-radius: 8px;
      }
      /* End Fire Fox */
      span {
        color: ${colors.primary};
        cursor: pointer;
        transition: all 0.4s;
        &:hover {
          color: ${colors.secondary};
          transition: all 0.4s;
        }
      }
    }
  }
  @media ${device.laptop} {
  }
`;

function RecentActivityEmpty({ description }) {
  return (
    <>
      <RecentActivityEmptyRow>
        <SiteCard className='about_card'>
          <Paragraph cname='head' pData={"About"} />
          <ReadMore>{description}</ReadMore>
        </SiteCard>
      </RecentActivityEmptyRow>
    </>
  );
}

export default RecentActivityEmpty;
