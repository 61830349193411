import React, { useState, useEffect, useContext } from "react";
import ListLayout from "../../components/admin task/AdminTaskListing.jsx";
import {
  DELETE,
  UPDATE,
  VIEW,
  SCHOOL_CLUB,
  ADMIN_USER_DATA,
  TASK,
} from "../../utils/constant.js";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import {} from "../../linksprovider.js";
import { loaderContext } from "../../components/global loader/global-loader-provider.js";
import {
  TaskDelete,
  clubDelete,
  clubListing,
  clubUpdate,
  clubView,
  schoolClubListing,
  schoolTaskListing,
  taskUpdate,
  taskView,
} from "../../services/auth.services.js";
import { StatusCodes } from "http-status-codes";
import ClubEdit from "../school_task/ClubEdit.jsx";
import { toast } from "react-toastify";
import AdminDeleteModel from "../../common/AdminDeleteModel.jsx";
import CreateTaskModal from "./CreateTaskModal.jsx";
import { AppContext } from "../../App.js";
import { SchoolContext } from "../../common/SchoolDashboardWrapper.jsx";
import SearchFilter from "../../components/search_filter/SearchFilter.jsx";
import TaskView from "./TaskView.jsx";
// import { AdminContext } from "../../common/DashboardWrapper.jsx";

function SchoolTaskManagment() {
  const [data, setData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [viewData, setViewData] = useState({});
  const [sortby, setSortby] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentItems, setCurrentItems] = useState(null);
  const [dataCount, setDataCount] = useState(1);
  const [itemOffset, setItemOffset] = useState(1);
  const [orderby, setOrderby] = useState(true);
  const [editData, setEditData] = useState({});
  const [openEditModal, setOpenEditModal] = useState(false);
  const [error, setError] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedData, setDeletedData] = useState(0);
  const [dataToUpdate, setDataToUpdate] = useState({});
  const [openCreateClubModal, setOpenCreateClubModal] = useState(false);
  const [selectedUserIds, setSelectedUserIds] = useState([]);

  let navigate = useNavigate();
  const { loaderState, loaderDispatch } = useContext(loaderContext);
  const { setAdminActiveTab } = useContext(SchoolContext);
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }
  const tableHeader = [
    { key: "S.No.", value: "key" },
    // {
    //   key: "School Name",
    //   subValue: "User",
    //   value: "school_name",
    // },
    { key: "Title", value: "title", clickable: false, sorting: true },
    { key: "Status", value: "status", clickable: false, sorting: true },
    { key: "Start Date", value: "startDate", clickable: false, sorting: true },
    { key: "Due Date", value: "dueDate", clickable: false, sorting: true },
    { key: "Created On", value: "createdAt", clickable: false, sorting: true },
    // { key: "Created By", value: "created_by" },
    {
      key: "options",
      value: "options",
      clickable: false,
      sorting: false,
    },
  ];

  const option = [
    {
      name: "Select Status",
      value: editData?.status == 1 ? "1" : "2",
    },
    {
      name: "Active",
      value: "1",
    },
    {
      name: "Inactive",
      value: "2",
    },
  ];
  const startDate = new Date(dataToUpdate?.startDate);
  // Extract year, month, and day components
  const years = startDate.getFullYear();
  const months = String(startDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const days = String(startDate.getDate()).padStart(2, "0");
  const formattedstartDate = `${years}-${months}-${days}`;

  const dueDate = new Date(dataToUpdate?.dueDate);
  // Extract year, month, and day components
  const yeard = dueDate.getFullYear();
  const monthd = String(dueDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const dayd = String(dueDate.getDate()).padStart(2, "0");
  const formatteddueDate = `${yeard}-${monthd}-${dayd}`;
  const edit = [
    {
      label: "Title",
      type: "text",
      value: dataToUpdate?.title,
      name: "title",
      maxLength: "60",
      minLength: "0",
    },

    {
      label: "Startdate",
      type: "date",
      value: formattedstartDate,
      name: "startDate",
      maxLength: "60",
      minLength: "0",
    },
    {
      label: "DueDate",
      type: "date",
      value: formatteddueDate,
      name: "dueDate",
      maxLength: "60",
      minLength: "0",
    },
  ];

  const itemsOnPage = [
    { option: 10 },
    { option: 20 },
    { option: 50 },
    { option: 70 },
    { option: 100 },
  ];

  // useEffect(() => {
  //   setAdminActiveTab(CLUB);
  // }, []);

  useEffect(() => {
    const debounce = setTimeout(() => {
      handelClubListing();
    }, 300);
    setAdminActiveTab(TASK);
    return () => {
      clearTimeout(debounce);
    };
  }, [searchItem, sortby, itemOffset, itemsPerPage, orderby]);

  useEffect(() => {
    setCurrentItems(data);
    setPageCount(Math.ceil(dataCount / itemsPerPage));
  }, [data, dataCount, itemsPerPage]);

  //function to get selected options
  const handleOptions = (optionValue, item) => {
    console.log("test", optionValue, item);
    switch (optionValue) {
      case VIEW:
        navigate(`/school/task/view/${item.id}`);
        break;
      case UPDATE:
        setOpenEditModal(true);
        studentEdit(item.id);
        break;

      case DELETE:
        setOpenDeleteModal(true);
        setDeletedData(item.id);
        break;

      default:
        break;
    }
  };

  //function to get club list
  const handelClubListing = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let order = orderby ? "desc" : "asc";

      let body = {
        id: userData.id,
        search: searchItem.trim(),
        sort: sortby,
        page: itemOffset,
        limit: itemsPerPage,
        order: order,
      };

      let result = await schoolTaskListing(body);
      // console.log(result);

      loaderDispatch({
        isLoading: false,
      });
      setData([]);
      setDataCount(0);
      if (result?.data?.status === StatusCodes.OK) {
        // console.log(result);
        const studentData = result?.data?.data?.rows;
        const studentDataCount = result?.data?.data?.count;
        console.log(studentData);
        setData(studentData);
        setDataCount(studentDataCount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //handel shorting
  const handleSorting = (data, order) => {
    console.log("sorting works", data);
    setSortby(data);
    setOrderby(order);
  };

  //handel pagination
  const handlePageClick = (event) => {
    let newOffset = (event.selected * itemsPerPage) % dataCount;
    console.log("pagination", newOffset);
    // dataCall(body);
    setItemOffset(newOffset / 10 + 1);
  };

  //function to update club data
  const updateData = async () => {
    for (const key in dataToUpdate) {
      if (dataToUpdate[key] === "") {
        setError((preVal) => {
          return { ...preVal, [key]: true };
        });
      }
    }

    // console.log(checkError.length, data.length, checkError);
    const arrayOfStrings = selectedUserIds?.map((obj) => obj.value.toString());
    let data = {
      title: dataToUpdate?.title.trim(),
      description: dataToUpdate?.description.trim(),
      status: dataToUpdate?.status,
      startDate: dataToUpdate?.startDate,
      dueDate: dataToUpdate?.dueDate,
      userIds: arrayOfStrings,
    };

    if (
      dataToUpdate?.title !== "" &&
      dataToUpdate?.description !== "" &&
      dataToUpdate?.startDate !== "" &&
      dataToUpdate?.dueDate !== "" &&
      selectedUserIds.length > 0
    ) {
      loaderDispatch({
        isLoading: true,
      });
      let result = await taskUpdate(editData.id, data);
      loaderDispatch({
        isLoading: false,
      });

      if (result?.status === StatusCodes.OK) {
        let item = result?.data?.data;
        setEditData(item);
        setOpenEditModal(false);
        handelClubListing();
        toast(result?.data?.message, {
          type: "success",
        });
      } else {
        setOpenEditModal(false);
        handelClubListing();
        toast(result?.data?.message, {
          type: "error",
        });
      }
    }
  };

  //handel deletion of club
  const deleteData = async () => {
    try {
      let result = await TaskDelete(deletedData);

      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
        });
        setOpenDeleteModal(false);
        handelClubListing();
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
      setOpenDeleteModal(false);
    }
  };

  const studentEdit = async (id) => {
    loaderDispatch({
      isLoading: true,
    });
    let param = {
      id: id,
    };
    let result = await taskView(param);

    if (result?.data?.status === StatusCodes.OK) {
      loaderDispatch({
        isLoading: false,
      });
      let item = result?.data?.data;
      setEditData(item);
      const convertedArray = item?.students?.map((student) => ({
        value: student.id,
        label: student.student_name,
      }));
      setSelectedUserIds(convertedArray);
      for (const key in item) {
        setError((preVal) => {
          return { ...preVal, [key]: false };
        });
      }
    }
  };

  //api to get all data

  return (
    <div className="">
      <div className="p-2 flex justify-between">
        <SearchFilter
          setSearchItem={setSearchItem}
          searchItem={searchItem}
          setPage={setItemOffset}
          cname={
            "mt-1 block w-1/2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
          }
        />

        <button
          className=" text-sm mr-1 px-4 py-2 bg-indigo-500 mt-1 block text-white rounded shadow-md font-semibold"
          onClick={() => setOpenCreateClubModal(true)}
        >
          Create Task
        </button>
      </div>

      <CreateTaskModal
        openCreateClubModal={openCreateClubModal}
        setOpenCreateClubModal={setOpenCreateClubModal}
        id={userData.id}
        handelClubListing={handelClubListing}
      />

      <ClubEdit
        viewData={editData}
        openViewModal={openEditModal}
        setOpenViewModal={setOpenEditModal}
        view={edit}
        setEditData={setEditData}
        updateData={updateData}
        error={error}
        setError={setError}
        option={option}
        setDataToUpdate={setDataToUpdate}
        dataToUpdate={dataToUpdate}
        setSelectedUserIds={setSelectedUserIds}
        selectedUserIds={selectedUserIds}
      />
      <AdminDeleteModel
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        deleteData={deleteData}
        header={"Delete Task"}
      />
      <ListLayout
        handleOptions={handleOptions}
        tableHeader={tableHeader}
        handleSorting={handleSorting}
        currentItems={currentItems}
        type={true}
      />
      <div className="flex justify-between ">
        {data?.length > 0 && (
          <>
            <div
              className="block lg:inline-block lg:mt-0"
              style={{ marginTop: "5px" }}
            >
              <label htmlFor="item" className="ml-5">
                Record Per Page:
              </label>
              <select
                className="ml-3 bg-white border p-2 rounded"
                name="item"
                id="item"
                onChange={(e) => {
                  setItemsPerPage(parseInt(e.target.value));
                }}
              >
                {itemsOnPage.map((val, i) => {
                  return (
                    <option value={val.option} key={i}>
                      {val.option}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="mt-2">
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next >"
                onPageChange={handlePageClick}
                pageCount={pageCount}
                previousLabel="< Previous"
                renderOnZeroPageCount={null}
                containerClassName={"pagination"}
                pageClassName={"pageClass"}
                forcePage={itemOffset - 1}
              />
            </div>
          </>
        )}
      </div>
      {!data.length && !loaderState.isLoading ? (
        <div className="flex absolute left-1/2 top-1/2 mx-auto">
          Data not found..!!
        </div>
      ) : null}
    </div>
  );
}

export default SchoolTaskManagment;
