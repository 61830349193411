import React, { useState, useContext, Fragment, useEffect } from "react";
import {
  bulkInvite,
  sendInvite,
  studentDelete,
  studentListing,
} from "../../../services/auth.services.js";
import {
  ADMIN_USER_DATA,
  DELETE,
  NFT,
  STUDENT,
  UPDATE,
  VIEW,
} from "../../../utils/constant.js";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import { StatusCodes } from "http-status-codes";
import logo from "../../../images/logo.svg";
import ListLayout from "../../shared/listLayout.jsx";
import { useNavigate } from "react-router-dom";
import { GrClose } from "react-icons/gr";
import ReactPaginate from "react-paginate";
import { loaderContext } from "../../global loader/global-loader-provider.js";
import Papa from "papaparse";
import AdminView from "../../../common/AdminView.jsx";
import { CSVLink } from "react-csv";
import validator from "validator";
import moment from "moment/moment.js";
import { AppContext } from "../../../App.js";
import { SchoolContext } from "../../../common/SchoolDashboardWrapper.jsx";
import SearchFilter from "../../search_filter/SearchFilter.jsx";

function SchoolStudent() {
  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [schoolData, setSchoolData] = useState({
    schoolName: "",
  });

  const [inviteformData, setInviteFormData] = useState({
    name: "",
    email: "",
    academic_year: "",
    standard: "",
  });

  // const [open, setOpen] = useState(false);
  let navigate = useNavigate();
  const [openViewModal, setOpenViewModal] = useState(false);
  const [viewData, setViewData] = useState({});

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [submitForm, setSubmitForm] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortby, setSortby] = useState("");
  const [orderby, setOrderby] = useState(true);
  const [page, setPage] = useState(1);
  const [currentItems, setCurrentItems] = useState(null);
  const [file, setFile] = useState();
  const [csvValidationErr, setCsvValidationErr] = useState(false);
  const [idForDelete, setIdForDelete] = useState();
  const [emailValidation, setEmailValidation] = useState(false);
  const [fileName, setFileName] = useState("");

  const { loaderDispatch } = useContext(loaderContext);
  // let { userData } = useContext(AppContext);
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }

  const { setOpen, open, setAdminActiveTab } = useContext(SchoolContext);

  const itemsOnPage = [
    { option: 10 },
    { option: 20 },
    { option: 50 },
    { option: 70 },
    { option: 100 },
  ];

  const tableHeader = [
    { key: "S.No.", value: "key" },
    {
      key: "Users name",
      value: "student_name",
      clickable: false,
      sorting: true,
    },
    {
      key: "email address",
      value: "email",
      clickable: false,
      sorting: true,
    },
    {
      key: "invite status",
      value: "status",
      clickable: false,
      sorting: true,
    },
    {
      key: "total post",
      value: "Posts",
      clickable: false,
      sorting: false,
    },
    {
      key: "total NFT Posts",
      value: "total_nft",
      clickable: false,
      sorting: false,
    },

    {
      key: "plan details",
      value: "Subscription",
      subValue: "description",
      clickable: false,
      sorting: false,
    },
    {
      key: "plan status",
      value: "Subscription",
      subValue: "status",
      clickable: false,
      sorting: false,
    },
    {
      key: "options",
      value: "options",
      clickable: false,
      sorting: false,
    },
  ];

  const view = [
    {
      label: "Users Name",
      type: "text",
      value: viewData?.student_name ? viewData?.student_name : "--",
      name: "Student_name",
    },
    {
      label: "Email Address",
      type: "email",
      value: viewData?.email ? viewData?.email : "--",
      name: "email",
    },
    {
      label: "Grade Level",
      type: "text",

      value: viewData?.standard ? viewData?.standard : "--",
      name: "standard",
    },
    {
      label: "Date of Birth",
      type: "text",
      value: viewData?.dob ? viewData?.dob : "--",
      name: "dob",
    },
    {
      label: "Join On",
      type: "text",
      value: viewData?.createdAt ? viewData?.createdAt : "--",
      name: "createdAt",
    },

    {
      label: "Academic Year",
      type: "text",
      value: viewData?.academic_year ? viewData?.academic_year : "--",
      name: "academic_year",
    },
    {
      label: "Meta Mask Wallet Address",
      type: "text",
      value: viewData?.metamask_id ? viewData?.metamask_id : "--",
      name: "metamask_id",
    },

    {
      label: " Street address",
      type: "text",
      value: viewData.address ? viewData.address : "--",
      name: "address",
    },
    {
      label: "Country",
      type: "text",
      value: viewData?.country ? viewData?.country : "--",
      name: "country",
    },
    {
      label: "State/ Province",
      type: "text",
      value: viewData?.state ? viewData?.state : "--",
      name: "state",
    },
    {
      label: "City",
      type: "text",
      value: viewData.city ? viewData.city : "--",
      name: "city",
    },

    {
      label: "ZIP code/ Postal code",
      type: "number",
      value: viewData?.pincode ? viewData?.pincode : "--",
      name: "pincode",
    },

    {
      label: "Employment Status",
      type: "number",
      value: viewData?.is_employed === true ? "Yes" : "No",
      name: "is_employed",
    },
    {
      label: "SalaryRange",
      type: "text",
      value: viewData.salaryRange ? viewData.salaryRange : "--",
      name: "salaryRange",
    },
  ];

  let currentYear = new Date().getFullYear();
  const years = [
    {
      name: "Select academic year",
      value: "",
    },

    {
      name: currentYear,
      value: currentYear,
    },
    {
      name: currentYear + 1,
      value: currentYear + 1,
    },
    {
      name: currentYear + 2,
      value: currentYear + 2,
    },
    {
      name: currentYear + 3,
      value: currentYear + 3,
    },
    {
      name: currentYear + 4,
      value: currentYear + 4,
    },
    {
      name: currentYear + 5,
      value: currentYear + 5,
    },
  ];

  const headers = [
    { label: "email", key: "email" },
    { label: "student_name", key: "student_name" },
    { label: "academic_year", key: "academic_year" },
    { label: "grade_level", key: "grade_level" },
  ];

  //csv file data to export
  const csvData = [
    {
      email: "vandana@yopmail.com",
      student_name: "oreo",
      academic_year: `${currentYear}`,
      grade_level: "freshmen",
    },
    {
      email: "zzz@yopmail.com",
      student_name: "oreo",
      academic_year: `${currentYear}`,
      grade_level: "Juniors",
    },
  ];

  const standard = [
    {
      name: "Select grade level",
      value: "",
    },
    {
      name: "Freshmen",
      value: "freshmen",
    },
    {
      name: "Sophomores",
      value: "sophomores",
    },
    { name: "Juniors", value: "juniors" },
    { name: "Seniors", value: "seniors" },
  ];

  useEffect(() => {
    if (!open) {
      setInviteFormData({
        name: "",
        email: "",
        academic_year: "",
        standard: "",
      });
      setFile();
      setSubmitForm(false);
      setCsvValidationErr(false);
    }
  }, [open]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleSchoolData();
    }, 300);
    setAdminActiveTab(STUDENT);

    return () => clearTimeout(delayDebounceFn);
  }, [searchItem, page, sortby, orderby, itemsPerPage]);

  useEffect(() => {
    setCurrentItems(data);
    setPageCount(dataCount);
  }, [data, dataCount, itemsPerPage]);

  const handleSendInvite = async () => {
    setSubmitForm(true);

    if (
      file === undefined &&
      (inviteformData.email === "" ||
        inviteformData.name === "" ||
        inviteformData.academic_year === "" ||
        inviteformData.standard === "" ||
        !inviteformData.standard)
    ) {
      return;
    } else if (
      file === undefined &&
      inviteformData.email !== "" &&
      inviteformData.name !== ""
      // inviteformData.academic_year === "" &&
      // inviteformData.standard === ""
    ) {
      if (
        inviteformData.email.includes("@") &&
        inviteformData.email.includes(".")
      ) {
        let jsonvalue = {
          email: inviteformData.email.trim(),
          student_name: inviteformData.name.trim(),
          school_id: userData.id,
          status: 2,
          academic_year: inviteformData.academic_year,
          standard: inviteformData.standard,
        };

        try {
          loaderDispatch({
            isLoading: true,
          });
          let result = await sendInvite(jsonvalue);

          loaderDispatch({
            isLoading: false,
          });

          if (result?.data?.status === StatusCodes.OK) {
            setOpen(false);
            toast(result?.data.message, {
              type: "success",
            });

            setSubmitForm(false);

            setInviteFormData({
              name: "",
              email: "",
              academic_year: "",
              standard: "",
            });

            handleSchoolData();
          } else {
            toast(result?.data?.message, {
              type: "error",
            });
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        setEmailValidation(true);
      }
    } else if (
      file !== undefined &&
      inviteformData.email === "" &&
      inviteformData.name === "" &&
      csvValidationErr === false
    ) {
      let formData = new FormData();
      let body = {
        file: file,
        school_id: userData.id,
        status: "2",
      };
      for (const key in body) {
        formData.append([key], body[key]);
      }
      try {
        loaderDispatch({
          isLoading: true,
        });
        let result = await bulkInvite(body);

        loaderDispatch({
          isLoading: false,
        });

        if (result?.data?.status === StatusCodes.OK) {
          setOpen(false);
          let response = result?.data?.data;
          if (response?.alreadyExistStudents?.length > 0) {
            toast(result?.data?.message, {
              type: "error",
              autoClose: "false",
            });
          }
          if (response?.blankData?.length > 0) {
            toast(result?.data?.message, {
              type: "error",
              autoClose: "false",
            });
          }
          if (response?.registeredStudents?.length > 0) {
            toast(result?.data?.message1, {
              type: "success",
              autoClose: "false",
            });
          }

          handleSchoolData();
        } else {
          toast(result?.data.message, {
            type: "error",
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handlePageClick = async (event) => {
    const pageNumber = event.selected + 1;
    setPage(pageNumber);
  };

  const handleDelete = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let result = await studentDelete(idForDelete);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
        });
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }

    setOpenDeleteModal(false);

    handleSchoolData();
  };

  const handleSchoolData = async () => {
    try {
      loaderDispatch({
        isLoading: true,
      });
      let order = orderby ? "desc" : "asc";

      if (searchItem.toLowerCase() === "active") {
        setSearchItem("1");
      } else if (searchItem.toLowerCase() === "inactive") {
        setSearchItem("2");
      }
      let result = await studentListing(
        userData.id,
        `&search=${searchItem.trim()}&sort_by=${sortby}&order=${order}&page=${page}&limit=${itemsPerPage}`
      );
      loaderDispatch({
        isLoading: false,
      });
      setData([]);
      setDataCount(0);
      const studentData = result?.data?.data?.studentListing;
      const studentDataCount = result?.data?.data?.totalPage;
      if (result?.data?.status === StatusCodes.OK) {
        setData(studentData);
        setDataCount(studentDataCount);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSorting = (data, order) => {
    setSortby(data);
    setOrderby(order);
  };

  const handleOptions = (optionValue, item) => {
    switch (optionValue) {
      case VIEW:
        setOpenViewModal(true);
        setViewData(item);
        break;
      case UPDATE:
        navigate(`/school/students/${item.id}`);
        break;
      case DELETE:
        setIdForDelete(item.id);
        setOpenDeleteModal(true);
        break;
      case NFT:
        navigate(`/school/students/nft/${item.id}`);
        break;

      default:
        break;
    }
  };

  //function to read csv file
  const handleOnChange = (e) => {
    setInviteFormData({
      name: "",
      email: "",
      academic_year: "",
      standard: "",
    });
    setCsvValidationErr(false);
    setFileName(e.target.files[0].name);
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        setFile(results.data);

        if (results.data.length) {
          let keys = [];
          let values = [];

          for (let i = 0; i < results.data.length; i++) {
            for (const [key, value] of Object.entries(results.data[i])) {
              keys.push(key.toLocaleLowerCase());
              values.push(value.toLocaleLowerCase());
            }
          }

          if (
            keys.includes("student_name") &&
            keys.includes("email") &&
            keys.includes("academic_year") &&
            keys.includes("grade_level") &&
            !values.includes("")
          ) {
            for (let i = 0; i < results.data.length; i++) {
              if (validator.isEmail(results.data[i].email)) {
                console.log("name & email includes");
              } else {
                setCsvValidationErr(true);
                break;
              }
            }
          } else {
            setCsvValidationErr(true);
          }
        }
      },
    });
  };

  //regex for alphabets and space
  const alphaSpace = /^[A-Za-z\s]*$/;
  //checking condition
  const checkAlphaSpace = (value) => {
    return value.match(alphaSpace);
  };

  return (
    <>
      {/* student Invite modal starts */}

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={open}
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div className="">
                  <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                      className="mx-auto h-16 w-auto"
                      src={logo}
                      alt="Workflow"
                    />
                    <div className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                      <h2>Student Invite</h2>
                    </div>

                    <div className="mt-1 sm:mx-auto sm:w-full sm:max-w-md">
                      <div className="bg-white py-8 px-4  sm:rounded-lg sm:px-10">
                        {file !== undefined &&
                          (inviteformData.name !== "" ||
                            inviteformData.email !== "" ||
                            inviteformData.academic_year !== "") && (
                            <p className="text-sm text-red-500 mb-2">
                              Please Use Any One Method
                            </p>
                          )}
                        <div>
                          <label
                            htmlFor="name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Name <span className="text-red-500">&#42;</span>
                          </label>
                          <div className="mt-1">
                            <input
                              type={"text"}
                              value={inviteformData.name}
                              onChange={(e) => {
                                setInviteFormData((preVal) => {
                                  if (
                                    (e?.target?.value?.trim()?.length > 0 &&
                                      e?.target?.value?.trim()?.length < 31) ||
                                    e?.target?.value === ""
                                  ) {
                                    if (checkAlphaSpace(e?.target?.value)) {
                                      return {
                                        ...preVal,
                                        name: e.target.value,
                                      };
                                    } else {
                                      return {
                                        ...preVal,
                                        name: inviteformData.name,
                                      };
                                    }
                                  } else {
                                    return {
                                      ...preVal,
                                      name: inviteformData.name,
                                    };
                                  }
                                });
                                setFile();
                                setEmailValidation(false);
                              }}
                              disabled={file === undefined ? false : true}
                              autoComplete="off"
                              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                            />
                          </div>

                          {inviteformData.name === "" &&
                          submitForm &&
                          file === undefined ? (
                            <div className="text-sm text-red-500">
                              please enter name
                            </div>
                          ) : null}
                        </div>

                        <div>
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700 mt-2"
                          >
                            Email address{" "}
                            <span className="text-red-500">&#42;</span>
                          </label>
                          <div className="mt-1">
                            <input
                              type={"text"}
                              value={inviteformData.email}
                              disabled={file === undefined ? false : true}
                              onChange={(e) => {
                                setInviteFormData((preVal) => {
                                  if (
                                    e?.target?.value?.trim()?.length > 0 ||
                                    e?.target?.value === ""
                                  ) {
                                    return {
                                      ...preVal,
                                      email: e.target.value,
                                    };
                                  } else {
                                    return {
                                      ...preVal,
                                      email: "",
                                    };
                                  }
                                });
                                setFile();
                                setEmailValidation(false);
                              }}
                              autoComplete="cc-given-name"
                              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                            />
                          </div>

                          {inviteformData.email === "" &&
                          submitForm &&
                          file === undefined ? (
                            <div className="text-sm text-red-500">
                              please enter email
                            </div>
                          ) : null}
                          {emailValidation && inviteformData.email !== "" && (
                            <div className="text-sm text-red-500">
                              please enter valid email id
                            </div>
                          )}
                        </div>

                        <div>
                          <label
                            htmlFor="date"
                            className="block text-sm font-medium text-gray-700 mt-2"
                          >
                            Academic Year
                          </label>
                          <div className="mt-1">
                            <select
                              onChange={(e) => {
                                setInviteFormData((preVal) => {
                                  return {
                                    ...preVal,
                                    academic_year: e.target.value,
                                  };
                                });
                                setFile();
                              }}
                              disabled={file === undefined ? false : true}
                              autoComplete="cc-given-date"
                              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-500 bg-white focus:border-gray-500 sm:text-sm"
                            >
                              {years.map((val, i) => {
                                return (
                                  <option key={i} value={val.value}>
                                    {val.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>

                          {inviteformData.academic_year === "" &&
                          submitForm &&
                          file === undefined ? (
                            <div className="text-sm text-red-500">
                              Please enter academic year
                            </div>
                          ) : null}
                        </div>
                        {/* Standard */}
                        <div>
                          <label
                            htmlFor="date"
                            className="block text-sm font-medium text-gray-700 mt-2"
                          >
                            Grade Level
                          </label>
                          <div className="mt-1">
                            <select
                              onChange={(e) => {
                                setInviteFormData((preVal) => {
                                  return {
                                    ...preVal,
                                    standard: e.target.value,
                                  };
                                });
                                setFile();
                              }}
                              disabled={file === undefined ? false : true}
                              autoComplete="cc-given-date"
                              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-500 bg-white focus:border-gray-500 sm:text-sm"
                            >
                              {standard.map((val, i) => {
                                return (
                                  <option key={i} value={val.value}>
                                    {val.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>

                          {console.log("sssssss", inviteformData.standard)}

                          {!inviteformData.standard &&
                          submitForm &&
                          file === undefined ? (
                            <div className="text-sm text-red-500">
                              Please select standard
                            </div>
                          ) : null}
                        </div>

                        <div className="text-center mt-5">OR</div>

                        <hr className="m-5" />

                        {file === undefined ? (
                          <>
                            <label
                              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                              style={{ cursor: "pointer" }}
                              htmlFor="upload-photo"
                            >
                              Select CSV file
                            </label>
                          </>
                        ) : (
                          <label
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                            style={{ cursor: "pointer" }}
                            htmlFor="upload-photo"
                          >
                            {fileName}
                          </label>
                        )}

                        <input
                          id="upload-photo"
                          type={"file"}
                          accept={".csv"}
                          style={{
                            opacity: 0,
                            position: "absolute",
                            zIndex: -1,
                          }}
                          onChange={handleOnChange}
                          disabled={
                            inviteformData.academic_year !== "" ||
                            inviteformData.email !== "" ||
                            inviteformData.name !== ""
                              ? true
                              : false
                          }
                        />

                        {csvValidationErr ? (
                          <span className="text-red-500 text-sm">
                            Please add correct formatted data in the CSV file.
                            Kindly check below sample file for the reference
                          </span>
                        ) : null}

                        <div className="text-sky-500 mt-1 ml-1 cursor-pointer text-sm">
                          <CSVLink
                            data={csvData}
                            filename={"Sample.csv"}
                            target="_blank"
                            headers={headers}
                          >
                            Sample.csv
                          </CSVLink>
                        </div>

                        <div className="mt-5 sm:mt-6">
                          <button
                            onClick={handleSendInvite}
                            type="submit"
                            className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                          >
                            Send Invite
                          </button>
                        </div>
                        <div className="mt-1 sm:mt-6">
                          <button
                            onClick={() => {
                              setOpen(false);
                              setCsvValidationErr(false);
                              setFile();
                              setSubmitForm(false);
                              setInviteFormData({
                                name: "",
                                email: "",
                                academic_year: "",
                                standard: "",
                              });
                            }}
                            type="submit"
                            className="inline-flex justify-center w-full rounded-md border border-transparent shadow px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white sm:text-sm "
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* student Invite modal ends */}

      {/* view modal */}
      <AdminView
        viewData={viewData}
        openViewModal={openViewModal}
        setOpenViewModal={setOpenViewModal}
        view={view}
      />

      <Transition.Root show={openDeleteModal} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={openDeleteModal}
          onClose={setOpenDeleteModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mb-6 row block text-lg font-medium text-gray-700">
                    <span>Delete Student</span>
                    <span className="float-right cursor-pointer">
                      {" "}
                      <GrClose onClick={() => setOpenDeleteModal(false)} />
                    </span>
                  </div>

                  <div className="mb-6 row block text-sm font-medium text-gray-700">
                    Are you sure want to delete this student ?
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:col-start-2 sm:text-sm"
                      onClick={() => handleDelete(false)}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:col-start-1 sm:text-sm"
                      onClick={() => setOpenDeleteModal(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <div className="p-2 w-1/2">
        <SearchFilter
          setSearchItem={setSearchItem}
          searchItem={searchItem}
          setPage={setPage}
          cname={
            " block w-full border border-gray-300 rounded-md shadow py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
          }
        />
      </div>

      <>
        <ListLayout
          handleOptions={handleOptions}
          tableHeader={tableHeader}
          handleSorting={handleSorting}
          currentItems={currentItems}
        />
        {data?.length > 0 && (
          <div className="block lg:inline-block lg:mt-0 ">
            <label htmlFor="item" className="ml-5">
              Record Per Page:
            </label>
            <select
              className="ml-3 bg-white border p-2 rounded"
              name="item"
              id="item"
              onChange={(e) => {
                setPage(1);
                setItemsPerPage(parseInt(e.target.value));
              }}
            >
              {itemsOnPage.map((val, i) => {
                return (
                  <option value={val.option} key={i}>
                    {val.option}
                  </option>
                );
              })}
            </select>
          </div>
        )}
      </>

      <div className="mt-[-35px] mb-11">
        <ReactPaginate
          breakLabel="..."
          nextLabel="Next >"
          onPageChange={handlePageClick}
          pageCount={pageCount}
          previousLabel="< Previous"
          renderOnZeroPageCount={null}
          containerClassName={"pagination"}
          pageClassName={"pageClass"}
          forcePage={page - 1}
          // disableInitialCallback={true}
        />
      </div>

      {!data?.length ? (
        <div className="flex absolute left-1/2 top-1/2 mx-auto">
          Data not found..!!
        </div>
      ) : null}
    </>
  );
}

export default SchoolStudent;
