import React, { useState, useEffect, useContext } from "react";
import ListLayout from "../../components/adminCMS/adminCmsListLayout.jsx";
import {
  ADMIN_USER_DATA,
  DELETE,
  UPDATE,
  VIEW,
  CMS,
} from "../../utils/constant";

import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { admin } from "../../linksprovider.js";
import { cmsListing } from "../../services/auth.services.js";
import { loaderContext } from "../../components/global loader/global-loader-provider";
import { StatusCodes } from "http-status-codes";
import { AdminContext } from "../../common/DashboardWrapper.jsx";
import { toast } from "react-toastify";

// import { use } from "express/lib/application.js";

function AdminCMS() {
  const [data, setData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [viewData, setViewData] = useState({});
  const [sortby, setSortby] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentItems, setCurrentItems] = useState(null);
  const [dataCount, setDataCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  const { loaderState, loaderDispatch } = useContext(loaderContext);
  const [orderby, setOrderby] = useState(true);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState(0);

  let navigate = useNavigate();

  const { setAdminActiveTab } = useContext(AdminContext);

  const tableHeader = [
    {
      key: "S.No.",
      value: "key",
      sorting: false,
    },
    {
      key: "Name",
      value: "title",
      sorting: true,
    },
    {
      key: "options",
      value: "options",
    },
  ];

  const itemsOnPage = [
    { option: 10 },
    { option: 20 },
    { option: 50 },
    { option: 70 },
    { option: 100 },
  ];

  useEffect(() => {
    const debunce = setTimeout(() => {
      getListing();
    }, 300);

    return () => {
      clearTimeout(debunce);
    };
  }, [searchItem, sortby, itemOffset, itemsPerPage, orderby]);

  useEffect(() => {
    setAdminActiveTab(CMS);
  }, []);

  useEffect(() => {
    setCurrentItems(data);
    setPageCount(Math.ceil(dataCount / itemsPerPage));
  }, [data, dataCount, itemsPerPage]);

  const handleOptions = (optionValue, item) => {
    switch (optionValue) {
      case VIEW:
        loaderDispatch({
          isLoading: true,
        });
        navigate(`/admin/cms/view/${item.id}`);
        break;
      case UPDATE:
        loaderDispatch({
          isLoading: true,
        });
        navigate(`/admin/cms/editor/${item.id}`);
        break;

      case DELETE:
        setOpenDeleteModal(true);
        setDeleteData(item.id);
        break;

      default:
        break;
    }
  };

  const handleSorting = (data, order) => {
    setSortby(data);
    setOrderby(order);
  };

  const getListing = async () => {
    loaderDispatch({
      isLoading: true,
    });
    try {
      let order = orderby ? "desc" : "asc";
      let body = {
        search: searchItem,
        sort: sortby,
        page: itemOffset,
        limit: itemsPerPage,
        order: order,
      };
      let result = await cmsListing(body);
      setData([]);
      setDataCount(0);
      loaderDispatch({
        isLoading: false,
      });
      if (
        result?.data?.data?.cmsListing?.count > 0 &&
        result?.data?.status === StatusCodes.OK
      ) {
        const studentData = result?.data?.data?.cmsListing?.rows;
        const studentDataCount = result?.data?.data?.cmsListing?.count;
        for (let i = 0; i < studentDataCount; i++) {
          setData((preVal) => {
            return [
              ...preVal,
              {
                id: studentData[i].id,
                title: studentData[i].title,
              },
            ];
          });
        }
        setDataCount(studentDataCount);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageClick = (event) => {
    let newOffset = (event.selected * itemsPerPage) % dataCount;
    // dataCall(body);
    setItemOffset(newOffset / 10 + 1);
  };

  return (
    <div className="">
      <div className="p-2">
        <input
          type={"text"}
          placeholder={"Search"}
          onChange={(e) => {
            setSearchItem(e.target.value);
            setItemOffset(1);
          }}
          autoComplete="cc-given-name"
          className="mt-1 block w-1/2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
        />
      </div>

      {data.length > 0 && !loaderState.isLoading ? (
        <>
          <ListLayout
            handleOptions={handleOptions}
            tableHeader={tableHeader}
            handleSorting={handleSorting}
            currentItems={currentItems}
          />
          <div className="flex justify-between ">
            <div className="block lg:inline-block lg:mt-0 ">
              <label htmlFor="item" className="ml-5">
                Record Per Page:
              </label>
              <select
                className="ml-3 bg-white border p-2 rounded"
                name="item"
                id="item"
                onChange={(e) => {
                  setItemsPerPage(parseInt(e.target.value));
                }}
              >
                {itemsOnPage.map((val, i) => {
                  return (
                    <option value={val.option} key={i}>
                      {val.option}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next >"
                onPageChange={handlePageClick}
                pageCount={pageCount}
                previousLabel="< Previous"
                renderOnZeroPageCount={null}
                containerClassName={"pagination"}
                pageClassName={"pageClass"}
                forcePage={itemOffset - 1}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          {data.length === 0 && (
            <div className="flex flex-row absolute left-1/2">
              <p>Data not found..!!</p>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default AdminCMS;
