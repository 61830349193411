import React from "react";
import styled from "styled-components";
import { space } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";
import { Container } from "../../../common/website/HOC.jsx";

import HeadingTwo from "../../../common/website/HeadingTwo.jsx";
import HeadingFive from "../../../common/website/HeadingFive.jsx";
import List from "../../../common/website/List.jsx";

import DifferentDigital from "../../../images/website/different_digital.png";
import DifferentDigitalCircel from "../../../images/website/different_digital_circel.svg";

const LandingDigitalRow = styled.section`
  padding: ${space.space40} 0;
  overflow: hidden;
  .digital_row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .digital_content {
    position: relative;
    padding: 0 0 ${space.space40};
    h2 {
      margin-bottom: ${space.space16};
    }
    h5 {
      margin-bottom: ${space.space14};
    }
    &::before {
      position: absolute;
      content: "";
      top: auto;
      bottom: 0;
      left: auto;
      right: 0;
      width: 158px;
      height: 146px;
      background: url(${DifferentDigitalCircel}) no-repeat;
      background-size: contain;
      z-index: 1;
    }
    img {
      display: block;
      margin-bottom: ${space.space24};
    }
    ul {
      li {
        &:last-child {
          margin: 0;
        }
      }
    }
  }
  .digital_image {
    display: none;
  }
  @media ${device.tablet} {
    padding: ${space.space150} 0;
    .digital_image {
      display: flex;
      justify-content: flex-end;
      height: 100%;
      align-items: center;
    }
    .digital_content {
      padding: 0;
      width: 50%;
      h2 {
        margin-bottom: ${space.space30};
      }
      img {
        display: none;
      }
      &::before {
        top: -20px;
        bottom: auto;
        right: auto;
        left: 0;
        width: 222px;
        height: 205px;
      }
    }
    .digital_image {
      width: 50%;
      padding-left: ${space.space24};
    }
  }
  @media ${device.laptop} {
    .digital_content {
      width: 58.33333333%;
    }
    .digital_image {
      width: 41.66666667%;
    }
  }
  @media ${device.laptopL} {
    .digital_image {
      align-items: flex-start;
    }
    .digital_content {
      &::before {
        left: -135px;
      }
    }
  }
`;

function LandingDigital() {
  return (
    <>
      <LandingDigitalRow>
        <Container>
          <div className='digital_row'>
            <div className='digital_content'>
              <HeadingTwo
                cname='white'
                h2Data={"YB3 -Building Communities that Last a Lifetime"}
              />
              <img src={DifferentDigital} alt='Different Digital' />
              {/* <HeadingFive h5Data={"I'm a paragraph. I’m a great place for you to tell a story and let your"}/> */}
              <ul>
                <List
                  liData={
                    "Created by students on a mobile app, with upload, chat, tag features"
                  }
                />
                <List
                  liData={
                    "Manage your own identity, name, and likeness by minting NFT’s of yourself"
                  }
                />
                <List liData={"Capture real-time in-the-moment experiences"} />
                <List
                  liData={
                    "Vote for Class Leaders or Star Athletes or Most Talented"
                  }
                />
                <List liData={"Sign and message senior students' yearbooks"} />
                <List
                  liData={"Design digital art and mint on Web 3 marketplaces"}
                />
                <List
                  liData={"Built on the Blockchain- Never lost or damaged"}
                />
                <List liData={"Accessible 24/7 anytime, and anywhere!"} />
              </ul>
            </div>
            <div className='digital_image'>
              <img src={DifferentDigital} alt='Different Digital' />
            </div>
          </div>
        </Container>
      </LandingDigitalRow>
    </>
  );
}

export default LandingDigital;
