import React from "react";
import styled from "styled-components";
import { space } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";
import { Container } from "../../../common/website/HOC.jsx";

import Paragraph from "../../../common/website/Paragraph.jsx";
import HeadingTwo from "../../../common/website/HeadingTwo.jsx";
import HeadingFive from "../../../common/website/HeadingFive.jsx";

import NFTImg from "../../../images/website/nft_img.png";
import NFTCircle from "../../../images/website/nft_circle.svg";

const LandingNFTRow = styled.section`
  padding: 0 0 ${space.space40};
  position: relative;
  &::before {
    position: absolute;
    content: "";
    bottom: 20px;
    right: 14px;
    width: 96px;
    height: 110px;
    background: url(${NFTCircle}) no-repeat;
    background-size: contain;
    z-index: -1;
  }
  .fw-bold {
    font-weight: 700;
  }
  .nft_content {
    position: relative;

    h2 {
      margin-bottom: ${space.space16};
    }
    h5 {
      margin-bottom: ${space.space14};
    }
    img {
      display: block;
    }
  }
  .nft_image {
    display: none;
  }
  @media ${device.tablet} {
    padding: 0 0 ${space.space150};
    .nft_content {
      h2 {
        margin-bottom: ${space.space30};
      }
      img {
        display: none;
      }
    }
    .nft_image {
      display: flex;
      justify-content: center;
    }
    &::before {
      bottom: 180px;
      right: 14px;
      width: 155px;
      height: 176px;
    }
  }
`;

function LandingNFT() {
  return (
    <>
      <LandingNFTRow>
        <Container>
          <div className='grid gap-4 md:grid-cols-2 gap-4 items-center'>
            <div className='nft_image'>
              <img src={NFTImg} alt='NFT Img' />
            </div>
            <div className='nft_content'>
              <HeadingTwo cname='white' h2Data={"NFT"} />
              <img src={NFTImg} alt='Different Digital' />
              {/* <HeadingFive h5Data={"NFT stands for 'non-fungible token'."}/> */}
              <Paragraph
                cname='fw-bold'
                pData={"NFT stands for 'non-fungible token'."}
              />
              <Paragraph
                cname='noMargin'
                pData={
                  "Non-fungible means that something is unique and can't be replaced."
                }
              />
            </div>
          </div>
        </Container>
      </LandingNFTRow>
    </>
  );
}

export default LandingNFT;
