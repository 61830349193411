import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import DatePicker from "react-date-picker";
import "react-datepicker/dist/react-datepicker.css";
import SiteLogo from "../../../common/website/SiteLogo.jsx";
import plus from "../../../images/website/plus.svg";

import { Link, useNavigate, useParams } from "react-router-dom";
import { colors, space } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Main, Container } from "../../../common/website/HOC.jsx";
import * as Yup from "yup";
import {
  LoginCard,
  LoginCardInner,
  SiteForm,
  CustomDatePicker,
} from "../../../common/website/HOC.jsx";

import HeadingTwo from "../../../common/website/HeadingTwo.jsx";
import Paragraph from "../../../common/website/Paragraph.jsx";
import Label from "../../../common/website/Label.jsx";
import Button from "../../../common/website/Button.jsx";

import IconCheck from "../../../images/website/icon_check_blue.svg";
import siteLogo from "../../../images/website/site_logo.svg";
import OpenEye from "../../../images/website/icon_open_eye.svg";
import CloseEye from "../../../images/website/icon_close_eye.svg";
import InfoBtn from "../../../images/website/icon_info_button.svg";
import { Subscriptions } from "@material-ui/icons";
import { loaderContext } from "../../../components/global loader/global-loader-provider";
import {
  getStudentDetails,
  studentRegistration,
} from "../../../services/auth.services";
import { getStatusCode, StatusCodes } from "http-status-codes";
import LoginBG from "../../../images/website/login_bg.svg";
import { toast } from "react-toastify";
import { student, website } from "../../../linksprovider.js";
import pdfIcons from "../../../images/website/pdfIcon.png";
import CommonModal from "../../../common/website/CommonPdfModal.jsx";
import iconcross from "../../../images/website/icon_cross.svg";
import imageCompression from "browser-image-compression";

const RegisterFormRow = styled.div`
  .meta_mask {
    text-align: right;
    margin-bottom: ${space.space24};
    a {
      font-size: 0.875rem;
      padding: 0;
      margin-top: ${space.space14};
      &:hover {
        color: ${colors.secondary};
      }
    }
    label {
      text-align: start;
    }
  }
  .react-calendar__tile:disabled {
    background-color: #0b0b0b00;
    color: #acacac4a;
  }
  .react-calendar__navigation button:disabled {
    background-color: #0b0b0b00;
    color: #acacac4a;
  }
  h2 {
    margin-bottom: ${space.space14};
    text-align: center;
  }
  .form_group {
    margin-bottom: ${space.space24};
  }
  .subtilte {
    margin-bottom: ${space.space30};
    text-align: center;
  }
  .submit-btn {
    width: 100%;
    &.hidden {
      display: none;
    }
  }
  .close-icon {
    position: relative;
    z-index: 9;
    left: 23px;
    top: 13px;
    background: #30dff2;
    width: 14px;
    height: 14px;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .upload_proof .font-medium {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .upload_proof .font-medium img {
    width: 12px;
  }

  .account_link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ${space.space16};
    grid-gap: 6px;
    flex-wrap: wrap;
    p {
      font-size: 0.875rem;
      margin: 0;
    }
    .link {
      font-size: 0.875rem;
      line-height: 19px;
      color: ${colors.primary};
      padding: 0;
      &:hover {
        color: ${colors.secondary};
      }
    }
  }
  .check_group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    grid-gap: 10px;
    .left {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      white-space: nowrap;
      input[type="checkbox"] {
        width: 20px;
        height: 20px;
        cursor: pointer;
        border: 1px solid ${colors.white};
        border-radius: 5px;
        appearance: none;
        overflow: hidden;
        padding: 0;
        &:checked {
          background-image: url(${IconCheck});
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          border: 1px solid ${colors.primary};
        }
        &[value="true"] {
          background-image: url(${IconCheck});
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          border: 1px solid ${colors.primary};
        }
      }
      label {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        padding-left: 10px;
        margin: 0;
      }
    }
    .right {
      a {
        padding: 0;
        &:hover {
          color: ${colors.secondary};
        }
      }
    }
  }
  .logo {
    text-align: center;
    margin-bottom: ${space.space20};
    display: block;
    a {
      display: inline-block;
    }
    img {
      max-width: 120px;
    }
  }
  .pass_group {
    position: relative;
    input {
      padding-right: 26px;
    }
    .eye_btn {
      content: "";
      position: absolute;
      background: url(${OpenEye}) no-repeat;
      background-size: contain;
      background-position: center;
      width: 18px;
      height: 18px;
      right: 0;
      bottom: 15px;
    }
    input[type="text"] ~ .eye_btn {
      background: url(${CloseEye}) no-repeat;
    }
  }
  .tool_tip_body {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    .tooltip {
      position: relative;
      display: inline-block;
      border-bottom: 1px dotted black;
      cursor: pointer;
      margin-bottom: 0.5rem;
      &:hover {
        .tooltiptext {
          visibility: visible;
        }
      }
    }
    .tooltiptext {
      font-size: 0.875rem;
      line-height: 1.25rem;
      visibility: hidden;
      width: 116px;
      background-color: ${colors.primary};
      color: ${colors.secondary};
      text-align: center;
      border-radius: 6px;
      padding: 5px;
      position: absolute;
      z-index: 1;
      bottom: 150%;
      left: 50%;
      margin-left: -60px;
      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: ${colors.primary} transparent transparent transparent;
      }
    }
  }
  @media ${device.mobileM} {
    .account_link {
      p,
      .link {
        font-size: 1rem;
      }
    }
    .meta_mask {
      a {
        font-size: 1rem;
      }
    }
  }
  @media ${device.mobileL} {
    .logo {
      img {
        max-width: 150px;
      }
    }
    .account_link {
      margin-top: ${space.space30};
    }
    .meta_mask {
      margin-bottom: ${space.space30};
    }
  }
  @media ${device.tablet} {
    .subtilte {
      margin-bottom: ${space.space50};
    }
    .logo {
      margin-bottom: ${space.space24};
      img {
        max-width: 168px;
      }
    }
    .form_group {
      margin-bottom: 34px;
    }
    .meta_mask {
      margin-bottom: ${space.space50};
    }
  }
  @media ${device.laptop} {
    .logo {
      display: none;
    }
  }
`;
const LoginInnerRow = styled.div`
  height: 100vh;
  position: relative;
  &::before {
    content: "";
    position: fixed;
    background: url(${LoginBG}) no-repeat;
    background-position: center;
    background-size: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    z-index: 1;
  }
  .login_logo {
    text-align: center;
    display: none;
    a {
      display: inline-block;
      img {
        width: 250px;
        max-width: 100%;
      }
    }
  }
  .content_col {
    height: 100%;
    position: relative;
    z-index: 2;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .full_screen_container {
    height: 100%;
  }
  @media ${device.laptop} {
    .content_col {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .login_logo {
      display: block;
      a {
        img {
          width: 310px;
        }
      }
    }
  }
  .checkbox_class {
    width: auto;
    margin-right: 10px;
    background-color: ${colors.primary};
  }
`;

function RegisterForm(props) {
  // DatePicker
  const [value, onChange] = useState(new Date());
  // End DatePicker

  // Password Show Hiden
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const [metaMaskShow, setMetaMaskShow] = useState(false);
  const [metaMaskAddress, setMetaMaskAddress] = useState("");
  const [metamaskInstallationError, setMetamaskInstallationError] =
    useState(false);

  const [fileTypeError, setFileTypeError] = useState(false);

  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [verificationDocument, setVerificationDocument] = useState({});
  const [openCommonModal, setOpenCommonModal] = useState(false);
  const [sizeLimit, setSizeLimit] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { loaderDispatch } = useContext(loaderContext);
  let { u_id } = useParams();
  let navigate = useNavigate();
  let fileUploadRef = useRef(null);

  const buttonRef = useRef();

  let initialValues = {
    student_name: "",
    email: "",
    password: "",
    cnf_password: "",
    dateValue: value || "",
    termsAndConditionCheck: false,
  };

  const validation = Yup.object({
    email: Yup.string().required("please fill the field"),
    student_name: Yup.string().required("please fill the field"),
    password: Yup.string()
      .required("please fill the field")
      .min(4, "Password must be at least 4 characters long"),
    cnf_password: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        "Password & confirm password must be same"
      )
      .required("Please fill the Field "),

    dateValue: Yup.string().required("please fill the field"),
    termsAndConditionCheck: Yup.bool().oneOf(
      [true],
      "You must accept the terms and conditions"
    ),
  });

  useEffect(() => {
    getPreRegistrationDetails();
  }, []);

  // Sets whether or not the password should be shown.
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  // Toggle password 2.
  const togglePassword2 = () => {
    setPasswordShown2(!passwordShown2);
  };
  const onSubmit = async (values, { setSubmitting }) => {
    setSizeLimit(false);
    if (
      verificationDocument?.name &&
      verificationDocument?.type === "application/pdf"
    ) {
      let size = verificationDocument?.size / 1024 / 1024;
      if (size > 5) {
        setSizeLimit(true);
        return;
      }
    }

    setIsFormSubmit(true);
    delete values.cnf_password;
    delete values.dateValue;
    delete value.termsAndConditionCheck;

    try {
      let body = {};
      if (!metaMaskShow) {
        body = {
          ...values,
          dob: value,
          doc: verificationDocument?.name ? verificationDocument : "",
        };
      }
      if (metaMaskShow) {
        body = {
          ...values,
          dob: value,
          metamask_id: metaMaskAddress[0],
          doc: verificationDocument?.name ? verificationDocument : "",
        };
      }

      //image compression
      if (
        verificationDocument?.name &&
        verificationDocument?.type?.includes("image")
      ) {
        body.doc = await handleImageUpload(body?.doc);
      }

      // console.log(body);
      let formData = new FormData();
      for (const key in body) {
        formData.append(key, body[key]);
      }

      loaderDispatch({
        isLoading: true,
      });
      let result = await studentRegistration(u_id, formData);
      loaderDispatch({
        isLoading: false,
      });

      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
          theme: "dark",
        });

        if (props.handleClick) {
          props.handleClick("login");
        } else {
          navigate(website.studentLogin);
        }
      } else {
        toast(result?.data?.message, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(error);
    }
    // getPreRegistrationDetails();
    setSubmitting(false);
  };

  const handelAgeVerification = () => {
    let date = new Date();
    let date2 = new Date(initialValues.dateValue);
    let birthYear = date2.getFullYear();
    let birthMonth = date2.getMonth();
    let birthDay = date2.getDate();
    let currentYear = date.getFullYear();
    let currentMonth = date.getMonth();
    let currentDate = date.getDate();
    const date1 = new Date(`${birthMonth + 1}/${birthDay}/${birthYear}`);
    const date3 = new Date(`${currentMonth + 1}/${currentDate}/${currentYear}`);
    const diffTime = Math.abs(date3 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    console.log(`${birthMonth + 1}/${birthDay}/${birthYear}`);
    if (diffDays - 4 >= 6570) {
      setMetaMaskShow(true);
    } else {
      setMetaMaskShow(false);
    }
  };

  const connectToMetaMask = () => {
    if (window.ethereum) {
      window.ethereum.request({ method: "eth_requestAccounts" }).then((res) => {
        // Return the address of the wallet
        console.log(res);
        setMetaMaskAddress(res);
      });
    } else {
      setMetamaskInstallationError(true);
    }
  };

  const getPreRegistrationDetails = async () => {
    loaderDispatch({
      isLoading: true,
    });
    let id = null;
    let result = await getStudentDetails(id, u_id);

    console.log(result);

    loaderDispatch({
      isLoading: false,
    });

    if (result?.data?.status === StatusCodes.OK) {
      // setinitialValues((preVal) => {
      //   return {
      //     ...preVal,
      //     email: result?.data?.data?.student_details?.email,
      //     student_name: result?.data?.data?.student_details?.student_name,
      //   };
      // });
      if (result?.data?.data) {
        initialValues["email"] = result?.data?.data?.student_details?.email;
        initialValues["student_name"] =
          result?.data?.data?.student_details?.student_name;
      }
    } else {
      toast(result?.data?.message, {
        type: "error",
        theme: "dark",
      });
    }
  };

  const handelFileChoose = () => {
    console.log("current", fileUploadRef);
    fileUploadRef?.current?.click();
  };

  const handelFileUpload = (e) => {
    const file = e?.target?.files[0];
    if (file?.type?.includes("image")) {
      setFileTypeError(false);
      setSizeLimit(false);

      console.log("file?.type", file?.type);
      if (file?.type === "image/tiff") {
        setFileTypeError(true);
        setErrorMessage("Please upload standard format image file");
      } else {
        setFileTypeError(false);
        setVerificationDocument(file);
      }
    } else if (file?.type === "application/pdf") {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = function () {
        if (reader?.result?.includes("%PDF")) {
          setFileTypeError(false);
          setSizeLimit(false);
          setVerificationDocument(file);
        } else {
          setErrorMessage("Please upload valid PDF");
          setFileTypeError(true);

          setSizeLimit(false);
        }
      };
      reader.onerror = function () {
        toast(reader?.error, {
          type: "error",
        });
      };
    } else {
      setFileTypeError(true);
      setErrorMessage("Please upload PDF or image only");
      setSizeLimit(false);
    }
  };

  const handelCancel = () => {
    setVerificationDocument({});
  };

  async function handleImageUpload(imageFile) {
    let size = imageFile?.size / 1024 / 1024;
    if (size > 4) {
      const options = {
        maxSizeMB: 3,
        // maxWidthOrHeight: 1920,
        // alwaysKeepResolution: true,
      };
      try {
        const compressedFile = await imageCompression(imageFile, options);
        console.log("compressedFileSize", compressedFile.size / 1024 / 1024);
        return compressedFile;
      } catch (error) {
        console.log(error);
        loaderDispatch({
          isLoading: false,
        });
      }
    } else {
      return imageFile;
    }
  }

  // console.log("test condition", isFormSubmit && !termsAndConditionCheck);
  return (
    <>
      <Main>
        <LoginInnerRow className='content_full_screen'>
          <Container className='full_screen_container'>
            <div className='grid gap-4 md:grid-cols-2 items-center content_col text-white'>
              <SiteLogo cname='login_logo' link='#' />
              <RegisterFormRow className='right'>
                <LoginCard>
                  <LoginCardInner>
                    <div className='logo'>
                      <Link to='/'>
                        <img
                          src={siteLogo}
                          srcSet={siteLogo}
                          className='sitelogo_img'
                          alt='YearBook3.0'
                        />
                      </Link>
                    </div>
                    <HeadingTwo cname='white' h2Data={"Registration"} />
                    <Paragraph
                      cname='subtilte'
                      pData={"Let's get started setting up your profile."}
                    />
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validation}
                      onSubmit={onSubmit}>
                      {({ isSubmitting }) => (
                        <SiteForm as='div'>
                          {console.log("isSubmitting", isSubmitting)}
                          <Form>
                            <div className='grid gap-0 md:grid-cols-1 items-center form_label_group'>
                              <div className='form_group'>
                                <Label labelData={"Name*"} />
                                <Field
                                  type='text'
                                  name='student_name'
                                  placeholder='Enter your name'
                                  // value={initialValues?.student_name}
                                  disabled
                                />
                                <ErrorMessage
                                  name='student_name'
                                  component='div'
                                  className='text-red-500 text-sm'
                                />
                              </div>
                              <div className='form_group'>
                                <Label labelData={"Email*"} />
                                <Field
                                  type='email'
                                  name='email'
                                  placeholder='Enter your email'
                                  // value={initialValues?.email}
                                  disabled
                                />
                                <ErrorMessage
                                  name='email'
                                  component='div'
                                  className='text-red-500 text-sm'
                                />
                              </div>
                            </div>
                            <div className='grid gap-0 md:grid-cols-1 items-center form_label_group'>
                              <div className='form_group'>
                                <Label labelData={"Birth Date*"} />

                                <CustomDatePicker>
                                  <DatePicker
                                    onChange={onChange}
                                    onCalendarClose={handelAgeVerification}
                                    value={value}
                                    maxDate={new Date()}
                                  />
                                </CustomDatePicker>
                              </div>
                            </div>
                            <div className='grid md:gap-7 md:grid-cols-2 items-center form_label_group'>
                              <div className='form_group pass_group'>
                                <Label labelData={"Password*"} />
                                <Field
                                  autoComplete='off'
                                  type={passwordShown ? "text" : "password"}
                                  name='password'
                                  placeholder='Enter your password'
                                />
                                <button
                                  type='button'
                                  onClick={togglePassword}
                                  className='eye_btn'></button>
                                <ErrorMessage
                                  name='password'
                                  component='div'
                                  className='text-red-500 text-sm absolute'
                                />
                              </div>
                              <div className='form_group pass_group'>
                                <Label labelData={"Confirm Password*"} />
                                <Field
                                  autoComplete='off'
                                  type={passwordShown2 ? "text" : "password"}
                                  name='cnf_password'
                                  placeholder='Confirm password'
                                />
                                <button
                                  type='button'
                                  onClick={togglePassword2}
                                  className='eye_btn'></button>
                                <ErrorMessage
                                  name='cnf_password'
                                  component='div'
                                  className='text-red-500 text-sm absolute'
                                />
                              </div>
                            </div>
                            <div className='col-span-6 upload_proof form_group'>
                              <Label labelData={"Upload ID Proof (Optional)"} />

                              {!verificationDocument?.name && (
                                <label
                                  className='block text-sm font-medium text-gray-700 cursor-pointer'
                                  onClick={handelFileChoose}>
                                  <img
                                    className=' h-10 w-auto'
                                    src={plus}
                                    alt=''
                                  />
                                </label>
                              )}
                              {verificationDocument?.name && (
                                <>
                                  <span
                                    className='close-icon'
                                    onClick={handelCancel}>
                                    <img
                                      src={iconcross}
                                      alt='profile_image'
                                      className='profile_image cursor-pointer'
                                    />
                                  </span>
                                  <img
                                    src={
                                      verificationDocument?.type ===
                                      "application/pdf"
                                        ? pdfIcons
                                        : URL.createObjectURL(
                                            verificationDocument
                                          )
                                    }
                                    className={`cursor-pointer w-9`}
                                    alt=''
                                    onClick={() => setOpenCommonModal(true)}
                                  />
                                </>
                              )}

                              <p className='text-xs text-cyan-400'>
                                {verificationDocument?.name}
                              </p>
                              <input
                                ref={fileUploadRef}
                                accept='.pdf, image/*'
                                autoComplete='off'
                                type='file'
                                value=''
                                name='file'
                                className='hidden'
                                placeholder='Upload verification documentation'
                                onChange={handelFileUpload}
                              />
                              {fileTypeError && (
                                <div className='text-sm  text-red-500'>
                                  <p>
                                    {errorMessage}
                                    {/* Please upload PDF only */}
                                  </p>
                                </div>
                              )}
                              {sizeLimit && verificationDocument?.name && (
                                <div className='text-sm mt-1  text-red-500'>
                                  <p>Upload PDF upto 5mb</p>
                                </div>
                              )}
                            </div>

                            {metaMaskShow && (
                              <div className='form_group meta_mask'>
                                <div className='tool_tip_body'>
                                  <Label
                                    labelData={"Meta Mask Wallet Address"}
                                  />
                                  <span className='tooltip'>
                                    <img src={InfoBtn} />{" "}
                                    <span className='tooltiptext'>
                                      Address optional
                                    </span>
                                  </span>
                                </div>
                                <input
                                  type='text'
                                  name='text2'
                                  placeholder='Connect Meta Mask'
                                  disabled
                                  value={metaMaskAddress}
                                />
                                {metamaskInstallationError && (
                                  <span className='text-sm text-red-500 float-left '>
                                    Please Install Meta Mask Extension{" "}
                                  </span>
                                )}

                                <Button
                                  type='button'
                                  tag='button'
                                  onClick={connectToMetaMask}
                                  cname='link cursor-pointer float-right '
                                  buttonData={"Connect Wallet"}
                                />
                              </div>
                            )}

                            <div className=' flex items-center justify-start text-sm'>
                              <Field
                                type='checkbox'
                                name='termsAndConditionCheck'
                                id='terms'
                                className='cursor-pointer checkbox_class'
                              />
                              <label htmlFor='terms' className=''>
                                By creating an account, you’re agreed to our{" "}
                                <span className='underline text-[#30Dff2]'>
                                  <a
                                    href={`${process.env.REACT_APP_DEV_SERVER}${website.termsAndCondition}`}
                                    target='_blank'
                                    rel='noreferrer'>
                                    Terms & Conditions
                                  </a>
                                </span>{" "}
                                and{" "}
                                <span className='underline text-[#30Dff2]'>
                                  <a
                                    href={`${process.env.REACT_APP_DEV_SERVER}${website.privacyPolicy}`}
                                    target='_blank'
                                    rel='noreferrer'>
                                    Privacy Policy
                                  </a>
                                </span>
                              </label>

                              {/* {isFormSubmit && !termsAndConditionCheck && (
                                <p className='text-sm text-red-500'>
                                  please click on checkbox
                                </p>
                              )} */}
                            </div>
                            <ErrorMessage
                              name='termsAndConditionCheck'
                              component='div'
                              className='text-sm text-red-500 '
                            />
                            <div className='btn_group mt-4'>
                              <Button
                                buttonRef={buttonRef}
                                tag={"button"}
                                type='submit'
                                cname='gradient submit-btn'
                                buttonData={"Register"}
                                // onClick={onSubmit}
                                disabled={isSubmitting}
                              />
                            </div>

                            {/* <div > */}
                          </Form>
                        </SiteForm>
                      )}
                    </Formik>

                    <div className='account_link'>
                      <Paragraph cname='' pData={"Already have an account?"} />
                      <button
                        className='link'
                        onClick={() =>
                          props.handleClick
                            ? props.handleClick("login")
                            : navigate(website.studentLogin)
                        }>
                        Login
                      </button>
                    </div>
                  </LoginCardInner>
                </LoginCard>
              </RegisterFormRow>
            </div>
          </Container>
        </LoginInnerRow>
      </Main>
      <CommonModal
        header={"Verification ID"}
        openCommonModal={openCommonModal}
        setOpenCommonModal={setOpenCommonModal}
        pdf={verificationDocument}
      />
    </>
  );
}

export default RegisterForm;
