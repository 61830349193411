import React, { useState, useEffect, useContext } from "react";
import "../../App.css";
import Editor from "../../common/Editor.jsx";
import { useParams } from "react-router-dom";
import { loaderContext } from "../global loader/global-loader-provider";
import { cmsView, cmsUpdate } from "../../services/auth.services";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { admin } from "../../linksprovider";
// import { ADMIN_USER_DATA } from "../../utils/constant";

function AdminCmsEditor() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { loaderState, loaderDispatch } = useContext(loaderContext);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    setEditorLoaded(true);
    // getEmailTemplate();
    getDataById();
  }, []);

  const getDataById = async () => {
    loaderDispatch({
      isLoading: true,
    });
    try {
      let result = await cmsView(id);
      if (result?.data?.status === StatusCodes.OK) {
        loaderDispatch({
          isLoading: false,
        });
        setData(result?.data?.data.description);
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelChange = (data) => {
    setData(data);
  };

  const handelUpdate = async (newData) => {
    loaderDispatch({
      isLoading: true,
    });
    try {
      let body = {
        description: data,
      };
      if (body.description.length > 0) {
        let result = await cmsUpdate(id, body);
        if (result?.data?.status === StatusCodes.OK) {
          toast(result?.data?.massage, {
            type: "success",
          });
          getDataById();
        } else {
          toast(result?.data?.massage, {
            type: "error",
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='bg-gray-100 min-h-screen px-4 py-16 sm:px-6 sm:py-10 md:grid lg:px-8 fade-in'>
      <div className='py-12 xl:py-30 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden fade-in'>
        <div className='max-w-max lg:max-w-7xl mx-auto'>
          <div className='App'>
            <Editor
              name='description'
              value={data}
              handelChange={handelChange}
              editorLoaded={editorLoaded}
              // handelUpdate={handelUpdate}
            />
          </div>
          <button
            className='bg-indigo-600 px-4 py-2 text-white mt-2 shadow rounded focus:bg-indigo-500'
            onClick={() => handelUpdate()}>
            Save
          </button>
          <button
            className='bg-white px-4 py-2 text-gray-600 mt-2 shadow rounded ml-2'
            onClick={() => navigate(admin.Cms)}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default AdminCmsEditor;
