import React, { useContext, useEffect, Fragment } from "react";
// import { AdminContext } from "../../common/DashboardWrapper";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { admin } from "../../../linksprovider";
import { HiOutlineMenuAlt1, HiSearch } from "react-icons/hi";
import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import {
  ADMIN_USER_DATA,
  NFT,
  PLANS,
  STUDENT,
  STUDENT_DETAILS,
} from "../../../utils/constant";
// import { AppContext } from "../../App";
import { SchoolContext } from "../../../common/SchoolDashboardWrapper";
import { BiArrowBack } from "react-icons/bi";

function Schoolheader() {
  const {
    adminActiveTab,
    setOpen,
    setCurrentAddress,
    setSidebarOpen,
    getSchoolProfile,
    adminData,
  } = useContext(SchoolContext);

  let navigate = useNavigate();
  let location = useLocation();
  let address = location.pathname;

  useEffect(() => {
    setCurrentAddress(address);
    getSchoolProfile();
  }, [address, setCurrentAddress]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }

  const handelLogout = () => {
    sessionStorage.clear();
    navigate(admin.login);
  };

  return (
    <>
      <main className='flex-1 relative z-0 focus:outline-none'>
        {(adminActiveTab === NFT || adminActiveTab === STUDENT_DETAILS) && (
          <button
            className=' cursor-pointer w-fit bg-white px-2 py-2 flex rounded-md shadow text-gray-500 hover:text-gray-600 mt-2 mb-2  ml-2 '
            onClick={() => navigate(-1)}>
            <BiArrowBack className='text-xl outline-none ' />
            <p>Go Back</p>
          </button>
        )}
        {/* Page title & actions */}
        <div className='border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8'>
          {adminActiveTab !== NFT && adminActiveTab !== STUDENT_DETAILS && (
            <>
              <div className='flex-1 min-w-0'>
                <h1 className='text-lg font-medium leading-6 text-gray-900 sm:truncate'>
                  {adminActiveTab}
                </h1>
              </div>

              <div
                className={
                  adminActiveTab === "Student Management"
                    ? "mt-4 flex sm:mt-0 sm:ml-4  py-4 "
                    : "mt-4 flex sm:mt-0 sm:ml-4"
                }>
                {adminActiveTab === STUDENT && (
                  <button
                    type='button'
                    className='order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:order-1 sm:ml-3'
                    onClick={() => {
                      setOpen(true);
                    }}>
                    Invite +
                  </button>
                )}
              </div>
            </>
          )}
        </div>
        {/* Pinned projects */}
      </main>
      {/* Search header */}
      <div className='relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 lg:hidden'>
        <button
          className='px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 lg:hidden'
          onClick={() => setSidebarOpen(true)}>
          <span className='sr-only'>Open sidebar</span>
          <HiOutlineMenuAlt1 className='h-6 w-6' aria-hidden='true' />
        </button>
        <div className='flex-1 flex justify-between px-4 sm:px-6 lg:px-8'>
          <div className='flex-1 flex'>
            <form className='w-full flex md:ml-0' action='#' method='GET'>
              {/* <label htmlFor="search-field" className="sr-only">
                Search
              </label> */}
              <div className='relative w-full text-gray-400 focus-within:text-gray-600'>
                <div className='absolute inset-y-0 left-0 flex items-center pointer-events-none'>
                  {/* <HiSearch className="h-5 w-5" aria-hidden="true" /> */}
                </div>
                {/* <input
                  id="search-field"
                  name="search-field"
                  className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400 sm:text-sm"
                  placeholder="Search"
                  type="search"
                /> */}
              </div>
            </form>
          </div>
          <div className='flex items-center'>
            {/* Profile dropdown */}
            <Menu as='div' className='ml-3 relative'>
              {({ open }) => (
                <>
                  <div>
                    <Menu.Button className='max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500'>
                      <span className='sr-only'>Open user menu</span>
                      <div className='h-8 w-8 rounded-full overflow-hidden'>
                        <img
                          className='object-cover h-full w-full'
                          src={
                            adminData?.profile_picture
                              ? adminData?.profile_picture
                              : "https://t3.ftcdn.net/jpg/01/09/00/64/360_F_109006426_388PagqielgjFTAMgW59jRaDmPJvSBUL.jpg"
                          }
                          alt=''
                        />
                      </div>
                    </Menu.Button>
                  </div>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'>
                    <Menu.Items
                      static
                      className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none'>
                      <div className='py-1'>
                        <Menu.Item>
                          {({ active }) => (
                            <label
                              onClick={handelLogout}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900 cursor-pointer"
                                  : "text-gray-700 cursor-pointer",
                                "block px-4 py-2 text-sm cursor-pointer"
                              )}>
                              Logout
                            </label>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default Schoolheader;
