import React from "react";
import styled from "styled-components";
import { space } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";
import { Container, SiteCard } from "../../../common/website/HOC.jsx";

import WheelApp from "../../../images/website/logo_WheelApp.svg";
import Kinetic from "../../../images/website/logo_Kinetic.svg";
import Infinity from "../../../images/website/logo_Infinity.svg";
import EdgeKart from "../../../images/website/logo_EdgeKart.svg";
import Grasshopper from "../../../images/website/logo_Grasshopper.svg";
import Majenta from "../../../images/website/logo_Majenta.svg";

const LandingBrandRow = styled.section`
  padding: 0 0 ${space.space40};
  .brand_col {
    padding: 20px;
    transition: all 0.4s;

    &:hover {
      background: rgba(48, 223, 242, 0.05);
      box-shadow: inset 0px 0px 10px rgb(48 223 242 / 30%);
      transition: all 0.4s;
    }

    img {
      height: 27px;
      margin: 0 auto;
    }
  }
  .brand_col_main {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media ${device.tablet} {
    padding: 0 0 ${space.space150};
    .brand_col_main {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
  @media ${device.laptop} {
    .brand_col {
      padding: 32px 24px;
    }
    .brand_col_main {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }
  }
`;

function LandingBrand() {
  return (
    <>
      <LandingBrandRow>
        <Container>
          <div className="grid gap-6 lg:grid-cols-6 md:grid-cols-4 items-center brand_col_main">
            <SiteCard className="brand_col">
              <img src={WheelApp} alt="WheelApp" />
            </SiteCard>
            <SiteCard className="brand_col">
              <img src={Kinetic} alt="Kinetic" />
            </SiteCard>
            <SiteCard className="brand_col">
              <img src={Infinity} alt="Infinity" />
            </SiteCard>
            <SiteCard className="brand_col">
              <img src={EdgeKart} alt="EdgeKart" />
            </SiteCard>
            <SiteCard className="brand_col">
              <img src={Grasshopper} alt="Grasshopper" />
            </SiteCard>
            <SiteCard className="brand_col">
              <img src={Majenta} alt="Majenta" />
            </SiteCard>
          </div>
        </Container>
      </LandingBrandRow>
    </>
  );
}

export default LandingBrand;
