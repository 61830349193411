import React, { Fragment, useContext, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { HiSelector, HiOutlineXCircle, HiSpeakerphone } from "react-icons/hi";
import { AiFillDashboard, AiFillBook, AiOutlineGlobal } from "react-icons/ai";
import { BsCardList } from "react-icons/bs";
import { admin, school } from "../../../linksprovider";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SchoolContext } from "../../../common/SchoolDashboardWrapper";
import { IoIosSchool, IoMdAnalytics } from "react-icons/io";
import {
  ADMIN_USER_DATA,
  BROADCAST_MESSAGE,
  CLUB,
  DASHBOARD,
  JOB,
  LABELS,
  POSTS,
  PROFILE,
  REPORTS,
  SCHOOL_SPORTS_CLUB,
  STUDENT,
  TASK,
  TASKREPORTS,
  VOTING,
  WEB_LINKS,
  YEARBOOK,
} from "../../../utils/constant";
import logo from "../../../images/logo.svg";
import { SiHackclub } from "react-icons/si";
import { FaTasks } from "react-icons/fa";
import { GiVote } from "react-icons/gi";
import { ImUser } from "react-icons/im";
import { MdSportsFootball, MdLabel } from "react-icons/md";
import { IoBagSharp } from "react-icons/io5";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function SchoolSidebar() {
  let navigate = useNavigate();
  const {
    sidebarOpen,
    setSidebarOpen,
    setAdminActiveTab,
    getSchoolProfile,
    adminData,
  } = useContext(SchoolContext);

  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }

  let location = useLocation();

  const [navigation, setNavigation] = useState([
    {
      name: DASHBOARD,
      value: "Dashboard",
      href: school.dashbord,
      icon: AiFillDashboard,
      current: false,
    },
    {
      name: STUDENT,
      value: "Students",
      href: school.student,
      icon: IoIosSchool,
      current: false,
    },
    {
      name: POSTS,
      value: "Posts",
      href: school.postDetails,
      icon: BsCardList,
      current: false,
    },
    {
      name: CLUB,
      value: "Clubs",
      href: school.club,
      icon: SiHackclub,
      current: false,
    },
    {
      name: TASK,
      value: "Tasks",
      href: school.task,
      icon: FaTasks,
      current: false,
    },
    {
      name: SCHOOL_SPORTS_CLUB,
      value: "Sport team",
      href: school.sports,
      icon: MdSportsFootball,
      current: false,
    },
    {
      name: PROFILE,
      value: "Profile",
      href: school.setting,
      icon: ImUser,
      current: false,
    },
    {
      name: YEARBOOK,
      value: "YearBook",
      href: school.yearBook,
      icon: AiFillBook,
      current: false,
    },
    {
      name: REPORTS,
      value: "Reports",
      href: school.report,
      icon: IoMdAnalytics,
      current: false,
    },

    {
      name: TASKREPORTS,
      value: "Task reports",
      href: school.taskreport,
      icon: IoMdAnalytics,
      current: false,
    },

    {
      name: VOTING,
      value: "Voting",
      href: school.vote,
      icon: GiVote,
      current: false,
    },
    {
      name: LABELS,
      value: "Labels",
      href: school.labels,
      icon: MdLabel,
      current: false,
    },
    {
      name: BROADCAST_MESSAGE,
      value: "Broadcast message",
      href: school.broadcast,
      icon: HiSpeakerphone,
      current: false,
    },
    {
      name: WEB_LINKS,
      value: "Web links",
      href: school.webLink,
      icon: AiOutlineGlobal,
      current: false,
    },
    {
      name: JOB,
      value: "jobCentre",
      href: school.job,
      icon: IoBagSharp,
      current: false,
    },
  ]);

  useEffect(() => {
    getSchoolProfile();
    setNavigation((preVal) => {
      for (let j = 0; j < preVal.length; j++) {
        preVal[j].active = false;
      }
      for (let j = 0; j < preVal.length; j++) {
        if (
          preVal[j].href === location?.pathname ||
          location?.pathname
            .toLocaleLowerCase()
            .includes(preVal[j].name.toLocaleLowerCase()) ||
          location?.pathname
            .toLocaleLowerCase()
            .includes(preVal[j].value.toLocaleLowerCase())
        ) {
          preVal[j].active = true;
        }
      }
      return preVal;
    });
  }, [location]);

  const handelLogout = () => {
    sessionStorage.clear();
    navigate(admin.login);
  };

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 flex z-40 lg:hidden"
          open={sidebarOpen}
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <HiOutlineXCircle
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex items-center px-4">
                <img className="h-14 w-auto" src={logo} alt="Workflow" />
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <nav className="px-2">
                  <div className="space-y-1">
                    {navigation?.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={
                          item.active
                            ? "bg-gray-100 text-gray-900 group flex items-center px-2 py-2 text-base leading-5 font-medium rounded-md"
                            : "text-gray-600 hover:text-gray-900 hover:bg-gray-50 group flex items-center px-2 py-2 text-base leading-5 font-medium rounded-md"
                        }
                        aria-current={item.active ? "page" : undefined}
                        onClick={(e) => {
                          setAdminActiveTab(item.name);
                        }}
                      >
                        <item.icon
                          className={
                            item.active
                              ? "text-gray-500 mr-3 flex-shrink-0 h-6 w-6"
                              : "text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6"
                          }
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:flex lg:flex-shrink-0 h-full bg-white">
        <div
          className="flex flex-col w-64 border-r border-gray-200 pt-5 pb-4 bg-gray-100"
          style={{ width: "-webkit-fill-available" }}
        >
          <div className="flex items-center flex-shrink-0 px-6">
            <img className="w-auto" src={logo} alt="logo" />
          </div>
          <div className="mt-5 flex items-center flex-shrink-0 px-6"></div>
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="h-0 flex-1 flex flex-col overflow-y-auto">
            {/* User account dropdown */}
            <Menu
              as="div"
              className="px-3 py-2 relative inline-block text-left"
            >
              {({ open }) => (
                <>
                  <div>
                    <Menu.Button className="group w-full bg-gray-100 rounded-md px-3.5 py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500">
                      <span className="flex w-full justify-between items-center">
                        <span className="flex min-w-0 items-center justify-between space-x-3">
                          <img
                            className="object-cover w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
                            src={
                              adminData?.profile_picture
                                ? adminData?.profile_picture
                                : "https://t3.ftcdn.net/jpg/01/09/00/64/360_F_109006426_388PagqielgjFTAMgW59jRaDmPJvSBUL.jpg"
                            }
                            alt=""
                          />
                          <span className="flex-1 flex flex-col min-w-0">
                            <h2 className="text-gray-900 text-sm font-medium truncate">
                              {adminData.school_name}
                            </h2>
                          </span>
                        </span>
                        <HiSelector
                          className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      </span>
                    </Menu.Button>
                  </div>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="z-10 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
                    >
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <label
                              onClick={handelLogout}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              Logout
                            </label>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>

            {/* Navigation */}
            <nav className="px-3">
              <div className="space-y-1">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    data-value={item.name}
                    className={
                      item.active
                        ? "bg-white text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                        : "text-gray-700 hover:text-gray-900 hover:bg-gray-50 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                    }
                    aria-current={item.active ? "page" : undefined}
                    onClick={(e) => {
                      setAdminActiveTab(e.target.getAttribute("data-value"));
                    }}
                  >
                    <item.icon
                      className={
                        item.active
                          ? "text-gray-500 mr-3 flex-shrink-0 h-6 w-6"
                          : "text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6"
                      }
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                ))}
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}

export default SchoolSidebar;
