import React from "react";
import { useNavigate } from "react-router-dom";

function YearBookHistory({ setOpen, historyData, setYearBookLink }) {
  let navigate = useNavigate();

  const handelImgClick = (link) => {
    setYearBookLink(link);
    link && setOpen(true);
  };
  return (
    <>
      <div className='mt-5 text-lg text-gray-600 mb-4'>
        <b>History</b>
      </div>
      <div>
        <div className='flex flex-col border border-gray-500 max-h-80'>
          <div className='overflow-x-auto sm:-mx-6 lg:-mx-6 '>
            <div className='py-2 inline-block min-w-full md:px-6 sm:px-6'>
              <div className='overflow-hidden px-2'>
                <table className='min-w-full'>
                  <thead className='border-b border-gray-500'>
                    <tr>
                      <th
                        scope='col'
                        className='text-sm font-medium text-gray-700 px-2 py-2 text-left'>
                        Academic Year
                      </th>
                      <th
                        scope='col'
                        className='text-sm font-medium text-gray-700 px-2 py-2 text-left'>
                        Student Count
                      </th>
                      <th
                        scope='col'
                        className='text-sm font-medium text-gray-700 px-2 py-2 text-left'>
                        Date of Creation
                      </th>
                      <th
                        scope='col'
                        className='text-sm font-medium text-gray-700 px-2 py-2 text-left'>
                        YearBook
                      </th>
                      <th
                        scope='col'
                        className='text-sm font-medium text-gray-700 px-2 py-2 text-left'>
                        Options
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {historyData?.map((history, i) => {
                      return (
                        <tr
                          className={
                            i !== historyData?.length - 1
                              ? "bg-white border-b border-gray-300"
                              : ""
                          }
                          key={i}>
                          <td className='py-4 px-2 whitespace-nowrap text-sm font-medium text-gray-700'>
                            {history?.academic_year}
                          </td>
                          <td className='text-sm text-gray-700 font-medium px-2 py-4 whitespace-nowrap'>
                            {history?.total}
                          </td>
                          <td className='text-sm text-gray-700 font-medium px-2 py-4 whitespace-nowrap'>
                            {history?.createdAt}
                          </td>
                          <td className='text-sm text-gray-700 font-light px-2 py-4 whitespace-nowrap'>
                            <img
                              src={history?.link ? history?.link : ""}
                              alt='yearBook'
                              onClick={() => handelImgClick(history?.link)}
                              className='cursor-pointer max-h-12'
                            />
                          </td>
                          <td className='text-sm text-white font-medium px-2 py-4 whitespace-nowrap'>
                            <button
                              className='bg-indigo-600 px-4 py-2 rounded shadow-lg hover:bg-indigo-700'
                              onClick={() =>
                                navigate(
                                  `/school/yearbook/student/${history?.academic_year}`
                                )
                              }>
                              Details
                            </button>
                          </td>
                        </tr>
                      );
                    })}

                    {/* <tr className='bg-white border-b'>
                      <td className='py-4 px-2 whitespace-nowrap text-sm font-medium text-gray-700'>
                        2
                      </td>
                      <td className='text-sm text-gray-700 font-light px-2 py-4 whitespace-nowrap'>
                        15
                      </td>
                      <td className='text-sm text-gray-700 font-light px-2 py-4 whitespace-nowrap'>
                        Thornton
                      </td>
                    </tr>
                    <tr className='bg-white '>
                      <td className=' py-4 px-2 whitespace-nowrap text-sm font-medium text-gray-700'>
                        3
                      </td>
                      <td className='text-sm text-gray-700 font-light px-2 py-4 whitespace-nowrap'>
                        16
                      </td>
                      <td className='text-sm text-gray-700 font-light px-2 py-4 whitespace-nowrap'>
                        Wild
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default YearBookHistory;
