import { StatusCodes } from "http-status-codes";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { SchoolContext } from "../../../common/SchoolDashboardWrapper.jsx";
import { getNFTPosts } from "../../../services/auth.services";
import { NFT } from "../../../utils/constant";
import { loaderContext } from "../../global loader/global-loader-provider";

import NFTImageCard from "../../admin_nft_post/NFTImageCard.jsx";
import YearBookModal from "../schoolmodals/YearBookModal.jsx";

function SchoolAdminNFTPost() {
  const [nftPostData, setNftPostData] = useState([]);

  const { setAdminActiveTab } = useContext(SchoolContext);
  const { loaderDispatch } = useContext(loaderContext);
  const [open, setOpen] = useState(false);
  const [yearBookLink, setYearBookLink] = useState("");
  const [page, setPage] = useState(1);

  let { id } = useParams();
  let scrollRef = useRef();
  let pages = page;

  let sub = true;
  useEffect(() => {
    if (sub) {
      setAdminActiveTab(NFT);
      nftPostList({ pages: 1, loader: true });
    }
    return () => (sub = false);
  }, []);

  const nftPostList = async ({ pages = null, loader = null }) => {
    try {
      if (loader) {
        loaderDispatch({
          isLoading: true,
        });
      }
      let params = {
        userId: id,
        page: pages,
      };

      let result = await getNFTPosts(params);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        let data = result?.data?.data?.docs;
        if (data?.length > 0 && pages > 1) {
          setNftPostData((preVal) => {
            return [...preVal, ...data];
          });
        }
        if (pages === 1 || !pages) {
          setNftPostData(data);
        }
      } else {
        toast(result?.data?.massage, {
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelScroll = () => {
    if (scrollRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        pages++;
        setPage(pages);
        nftPostList({ pages: pages, loader: false });
      }
    }
  };

  const handelClick = (link) => {
    setOpen(true);
    setYearBookLink(link);
  };
  return (
    <div className='container px-3 py-3'>
      <div className=' h-full w-full '>
        <p className='mb-5 font-semibold text-gray-700 text-xl'>NFT Posts</p>
        <div
          className='grid lg:grid-cols-8 md:grid-cols-7 sm:grid-cols-5 grid-cols-2 gap-3 w-full overflow-y-auto'
          ref={scrollRef}>
          {nftPostData?.length > 0 &&
            nftPostData?.map((val, i) => {
              return (
                <NFTImageCard
                  key={i}
                  nftimage={val?.name}
                  onClick={() => handelClick(val?.name)}
                />
              );
            })}
        </div>
        {nftPostData?.length <= 0 && (
          <div className='w-full h-full flex justify-center '>
            <p>Data not found..!!..!!</p>
          </div>
        )}
      </div>

      <YearBookModal
        open={open}
        setOpen={setOpen}
        yearBookLink={yearBookLink}
        nft={NFT}
        heading='NFT Post'
      />
    </div>
  );
}

export default SchoolAdminNFTPost;
