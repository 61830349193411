import React from "react";
import styled from "styled-components";
import { space, siteGradient, colors } from "../../../common/website/Variables";
import Paragraph from "../../../common/website/Paragraph";
import { device } from "../../../common/website/Devices";
import { Container, SiteForm } from "../../../common/website/HOC.jsx";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import HeadingThree from "../../../common/website/HeadingThree.jsx";
import Label from "../../../common/website/Label.jsx";
import Button from "../../../common/website/Button.jsx";
import ArrowRight from "../../../images/website/icon_arrow_right.svg";
import LineArrowRight from "../../../images/website/line_arrow_right.svg";
import { getSubscribed } from "../../../services/auth.services";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";

const LandingContactUsRow = styled.section`
  padding: 0;
  overflow: hidden;
  .newslatter_card {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 600px;
    margin: auto;
    padding: 20px;
    background: ${siteGradient.bluePink};
    border-radius: 1rem;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      background: url(${LineArrowRight}) no-repeat center;
      width: 223px;
      height: 176px;
      left: -147px;
      top: 38px;
      bottom: 0;
      margin: auto;
      display: none;
    }
    h3 {
      max-width: 372px;
      margin: 0 0 ${space.space24};
    }
    .newsletter_heding {
      font-size: 1.257rem;
      font-weight: 600;
      line-height: 1.75rem;
      max-width: 372px;
      margin: 0 0 ${space.space16};
      @media ${device.mobileL} {
        font-size: 1.375rem;
        line-height: 2.063rem;
      }
      @media ${device.laptop} {
        margin: 0;
      }
    }
  }

  .newsletter_form {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
    form {
      width: 100%;
    }
  }
  .form_group {
    width: 100%;
    position: relative;
  }
  label {
    font-size: 1rem;
    line-height: 1.188rem;
    margin-bottom: 2px;
  }
  input {
    border-bottom: 1px solid #ffffff80;

    &::placeholder {
      color: #ffffff80;
    }
  }
  button {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.188rem;
    color: ${colors.white};
    padding: 0;
    position: static;
    right: 17px;
    top: 50%;
    transform: translate(0, -50%);
    margin: ${space.space24} 0 0;

    &::before {
      content: "";
      position: absolute;
      background: url(${ArrowRight}) no-repeat center;
      width: 11px;
      height: 9px;
      right: -17px;
      top: 0;
      bottom: 0;
      margin: auto;
      transition: all 0.4s;
    }
    &:hover {
      &::before {
        right: -20px;
        transition: all 0.4s;
      }
    }
  }
  @media ${device.mobileM} {
    .newsletter_form {
      min-width: 385px;
    }
    input {
      padding-right: 140px;
    }
    button {
      position: absolute;
      margin: 0;
    }
  }
  @media ${device.mobileL} {
    .newslatter_card {
      padding: 24px;
    }
  }
  @media ${device.laptop} {
    .newslatter_card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      max-width: 100%;
      padding: 48px 70px 48px 92px;
      &::before {
        display: block;
      }
      h3 {
        margin: 0;
        text-align: start;
      }
    }
  }
  @media ${device.laptopL} {
    .newsletter_form {
      min-width: 450px;
    }
  }
`;

function LandingContactUs() {
  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .trim()
      .email("Invalid email address")
      .required("Please fill the field"),
  });

  const onSubmit = async (values, { resetForm, setSubmitting }) => {
    console.log(values);

    let body = {
      email: values.email.trim(),
    };

    try {
      let result = await getSubscribed(body);
      console.log("res", result);
      if (result?.data?.status === StatusCodes.OK) {
        toast("You have successfully subscribed", {
          type: "success",
          theme: "dark",
        });
        resetForm();
      } else {
        toast(result?.data?.message, {
          type: "error",
          theme: "dark",
        });
      }
    } catch (error) {
      console.log(error);
    }

    setSubmitting(false);
  };

  return (
    <>
      <LandingContactUsRow className='newslatter'>
        <Container>
          <div className='newslatter_card'>
            {/* <HeadingThree
              h3Data={
                "Keep up to date with our progress. Subscribe for e-mail updates."
              }
            /> */}
            <Paragraph
              cname='newsletter_heding'
              pData={
                "Keep up to date with our progress. Subscribe for e-mail updates."
              }
            />
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}>
              {({
                isSubmitting,
                /* and other goodies */
              }) => (
                <SiteForm as='div' className='newsletter_form'>
                  <Form>
                    <div className='form_group m-0'>
                      <Label labelData={"Email*"} />
                      <Field
                        type='text'
                        name='email'
                        placeholder='Enter Email'
                      />
                      <ErrorMessage
                        name='email'
                        component='div'
                        className='text-red-500 text-sm font-bold absolute'
                      />
                      <Button
                        tag='button'
                        type='submit'
                        cname='subscribe-btn'
                        buttonData={"Subscribe Now"}
                        disabled={isSubmitting}
                      />
                    </div>
                  </Form>
                </SiteForm>
              )}
            </Formik>
          </div>
        </Container>
      </LandingContactUsRow>
    </>
  );
}

export default LandingContactUs;
