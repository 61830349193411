import React from "react";
import CommonModal from "../../../common/commonModal";

function WebLinkView({ openViewModal, setOpenViewModal, items }) {
  return (
    <>
      <CommonModal
        openModal={openViewModal}
        setOpenModal={setOpenViewModal}
        header={"View web-link"}
        divClass={
          "inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6"
        }>
        <div className='max-h-96 overflow-y-auto px-2 w-80'>
          <div className='my-2 text-sm'>
            <div className=''>
              <label htmlFor='' className='font-medium text-slate-800'>
                Channel
              </label>
            </div>
            <div>
              <p className='border px-2 py-2 rounded focus:ring-1 ring-indigo-500 outline-none w-full'>
                {items?.is_school_moment && "School moment"}
                {items?.is_profile_web_link && "Profile"}
                {items?.Club?.id && "Club"}
                {items?.Sport?.id && "Sport"}
              </p>
            </div>
          </div>
          {!items?.is_school_moment && !items?.is_profile_web_link && (
            <div className='my-2 text-sm'>
              <div className=''>
                <label htmlFor='' className='font-medium text-slate-800'>
                  Channel Name
                </label>
              </div>
              <div>
                <p className='border px-2 py-2 rounded focus:ring-1 ring-indigo-500 outline-none w-full'>
                  {items?.Club?.id && items?.Club?.clubName}

                  {items?.Sport?.id && items?.Sport?.sportName}
                </p>
              </div>
            </div>
          )}
          <div className='my-2 text-sm'>
            <div className=''>
              <label htmlFor='' className='font-medium text-slate-800'>
                Description
              </label>
            </div>
            <div>
              <p className='border px-2 py-2 rounded focus:ring-1 ring-indigo-500 outline-none w-full max-h-48 overflow-y-auto'>
                {items?.description}
              </p>
            </div>
          </div>
          <div className='my-2 text-sm'>
            <div className=''>
              <label htmlFor='' className='font-medium text-slate-800'>
                Link
              </label>
            </div>
            <div>
              <p className='border px-2 py-2 rounded focus:ring-1 ring-indigo-500 outline-none w-full break-words max-h-48 overflow-y-auto'>
                <a className='outline-none' href={items?.postName}>
                  {items?.postName}
                </a>
              </p>
            </div>
          </div>
        </div>
      </CommonModal>
    </>
  );
}

export default WebLinkView;
