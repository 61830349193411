import React from "react";
import { Link, useNavigate } from "react-router-dom";
import siteLogo from "../../images/website/site_logo.svg";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { AiFillCloseCircle } from "react-icons/ai";

import { device } from "./Devices.js";
import styled from "styled-components";
import { student } from "../../linksprovider";
import { GRADUATED } from "../../utils/constant";

const SiteLogoRow = styled.div`
  .sitelogo_img {
    max-width: 98px;

    @media ${device.mobileM} {
      max-width: 120px;
      /* max-height: 80px; */
    }
    @media ${device.tablet} {
      max-width: 170px;
      /* max-width: 100px; */
    }
  }
`;
function SiteLogo(props) {
  let navigate = useNavigate();
  return (
    <>
      <SiteLogoRow className={`${props.cname} flex justify-between text-4xl`}>
        <Link to={props.link}>
          <img
            src={props?.customLogo ? props?.customLogo : siteLogo}
            // srcSet={siteLogo}
            className='sitelogo_img'
            alt='YearBook3.0'
          />
        </Link>
        {props?.userInfo?.standard.toLowerCase() !== GRADUATED &&
          props?.userInfo?.subscription_id && (
            <div className='model_top'>
              <span
                className='modal_close'
                onClick={() => navigate(student.post)}>
                {" "}
                <AiFillCloseCircle className='text-gray-200 hover:text-cyan-500 cursor-pointer ease-in-out duration-300' />
              </span>
            </div>
          )}
      </SiteLogoRow>
    </>
  );
}

export default SiteLogo;
