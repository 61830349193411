import React, { useState, useContext } from "react";
import styled from "styled-components";
import { colors, space } from "./Variables";
import { device } from "./Devices";
import RangeInput from "./RangeInput";
import { FilterContext } from "../../Pages/website/Dashboard/DigitalArtCreatorDetails";
import Button from "./Button";

const DigitalAdjustRow = styled.div`
  .adjust_item {
    margin-bottom: ${space.space16};
    &:last-child {
      margin: 0;
    }
    label {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.063rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        color: ${colors.primary};
      }
    }
  }
  input[type="range"] {
    height: auto;
    -webkit-appearance: none;
    margin: 0;
    width: 100%;
    background: transparent;
    padding: 0;
  }
  input[type="range"]:focus {
    outline: none;
  }
  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    box-shadow: 0px 0px 0px #30dff2;
    background: #30dff2;
    border-radius: 50px;
    border: 0px solid #30dff2;
  }
  input[type="range"]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #30dff2;
    border: 0px solid #30dff2;
    height: 9px;
    width: 9px;
    border-radius: 50px;
    background: #30dff2;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -4px;
  }
  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #30dff2;
  }
  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    box-shadow: 0px 0px 0px #30dff2;
    background: #30dff2;
    border-radius: 50px;
    border: 0px solid #30dff2;
  }
  input[type="range"]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #30dff2;
    border: 0px solid #30dff2;
    height: 9px;
    width: 9px;
    border-radius: 50px;
    background: #30dff2;
    cursor: pointer;
  }
  input[type="range"]::-ms-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type="range"]::-ms-fill-lower {
    background: #30dff2;
    border: 0px solid #30dff2;
    border-radius: 100px;
    box-shadow: 0px 0px 0px #30dff2;
  }
  input[type="range"]::-ms-fill-upper {
    background: #30dff2;
    border: 0px solid #30dff2;
    border-radius: 100px;
    box-shadow: 0px 0px 0px #30dff2;
  }
  input[type="range"]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #30dff2;
    border: 0px solid #30dff2;
    height: 9px;
    width: 9px;
    border-radius: 50px;
    background: #30dff2;
    cursor: pointer;
  }
  input[type="range"]:focus::-ms-fill-lower {
    background: #30dff2;
  }
  input[type="range"]:focus::-ms-fill-upper {
    background: #30dff2;
  }
  @media ${device.tablet} {
    .adjust_item {
      margin-bottom: ${space.space24};
    }
  }
  @media ${device.laptop} {
    .adjust_item {
      margin-bottom: ${space.space34};
    }
  }
`;

// const DEFAULT_OPTIONS = ;

function DigitalAdjust(props) {
  // the width of the box

  const { options, setOptions, initialFilterValues } =
    useContext(FilterContext);
  // End the width of the box

  // This function is called when the first range slider changes

  function handleSliderChange(e) {
    const { name, value } = e.target;
    setOptions((prevOptions) => {
      return prevOptions.map((option, index) => {
        if (name == option?.name) {
          return { ...option, value: value };
        }
        return option;
      });
    });
  }
  // End This function is called when the first range slider changes

  return (
    <>
      <DigitalAdjustRow className={props.cname}>
        <ul className='tab_list'>
          <li className='tab_item active'>Adjust</li>
          <li
            className='tab_item'
            onClick={() => props.handleClick("digital_filters")}>
            Filters
          </li>
        </ul>
        <div className='digital_body'>
          <div className='adjust_list'>
            {options?.map((option, index) => {
              return (
                <div className='adjust_item' key={index}>
                  <label>
                    {option?.name} <span>{option?.value}</span>
                  </label>
                  <RangeInput
                    onChange={handleSliderChange}
                    min={option?.range?.min}
                    max={option?.range?.max}
                    step={option?.step}
                    value={option?.value}
                    name={option?.name}
                  />
                </div>
              );
            })}
            <Button
              tag='button'
              type='button'
              cname='outline w-full'
              buttonData={"Reset"}
              onClick={() => setOptions(initialFilterValues)}
            />
            {/* <div className='adjust_item'>
              <label>
                Contrast <span>{ContrastWidth}</span>
              </label>
              <RangeInput
                onChange={changeContrast}
                min={0}
                max={100}
                step={1}
                value={ContrastWidth}
              />
            </div> */}
            {/*<div className='adjust_item'>
              <label>
                Saturation <span>{SaturationWidth}</span>
              </label>
              <input
                type='range'
                onChange={changeSaturation}
                min={0}
                max={100}
                step={1}
                value={SaturationWidth}></input>
            </div>
            <div className='adjust_item'>
              <label>
                Sharpness <span>{SharpnessWidth}</span>
              </label>
              <input
                type='range'
                onChange={changeSharpness}
                min={0}
                max={100}
                step={1}
                value={SharpnessWidth}></input>
            </div> */}
          </div>
        </div>
      </DigitalAdjustRow>
    </>
  );
}

export default DigitalAdjust;
