import React from "react";
import styled from "styled-components";

import { SiteCard } from "../../../common/website/HOC.jsx";
import { space } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";

import Paragraph from "../../../common/website/Paragraph.jsx";
import IconRecentActivity from "../../../images/website/recent_activity_image.svg";

const RecentActivityEmptyRow = styled.div`
  .recent_activity_card {
    border-radius: ${space.space16};
    margin-bottom: ${space.space24};
    p {
      margin-bottom: ${space.space30};
    }
    img {
      margin: auto;
      max-width: 162px;
    }
  }
  @media ${device.laptop} {
    .recent_activity_card {
      img {
        max-width: 162px;
      }
    }
  }
`;

function RecentActivityEmpty() {
  return (
    <>
      <RecentActivityEmptyRow>
        <SiteCard className='recent_activity_card'>
          <Paragraph pData={"Recent Activity"} />
          <img src={IconRecentActivity} alt='Recent Activity Image' />
        </SiteCard>
      </RecentActivityEmptyRow>
    </>
  );
}

export default RecentActivityEmpty;
