import { createGlobalStyle } from "styled-components";

/* Inter Regular */
import Inter400 from "../../fonts/inter-v12-latin-regular.eot";
import Inter400Woff2 from "../../fonts/inter-v12-latin-regular.woff2";
import Inter400Woff from "../../fonts/inter-v12-latin-regular.woff";
import Inter400Ttf from "../../fonts/inter-v12-latin-regular.ttf";
import Inter400Svg from "../../fonts/inter-v12-latin-regular.svg#Inter";

/* Inter SemiBold */
import Inter600 from "../../fonts/inter-v12-latin-600.eot";
import Inter600Woff2 from "../../fonts/inter-v12-latin-600.woff2";
import Inter600Woff from "../../fonts/inter-v12-latin-600.woff";
import Inter600Ttf from "../../fonts/inter-v12-latin-600.ttf";
import Inter600Svg from "../../fonts/inter-v12-latin-600.svg#Inter";

/* Inter Bold */
import Inter700 from "../../fonts/inter-v12-latin-700.eot";
import Inter700Woff2 from "../../fonts/inter-v12-latin-700.woff2";
import Inter700Woff from "../../fonts/inter-v12-latin-700.woff";
import Inter700Ttf from "../../fonts/inter-v12-latin-700.ttf";
import Inter700Svg from "../../fonts/inter-v12-latin-700.svg#Inter";

/* Inter Black */
import Inter900 from "../../fonts/inter-v12-latin-900.eot";
import Inter900Woff2 from "../../fonts/inter-v12-latin-900.woff2";
import Inter900Woff from "../../fonts/inter-v12-latin-900.woff";
import Inter900Ttf from "../../fonts/inter-v12-latin-900.ttf";
import Inter900Svg from "../../fonts/inter-v12-latin-900.svg#Inter";

const FontStyles = createGlobalStyle`

    /* inter-regular - latin */
    @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url(${Inter400}); /* IE9 Compat Modes */
    src: local(''),
        url(${Inter400}#iefix) format('embedded-opentype'), /* IE6-IE8 */
        url(${Inter400Woff2}) format('woff2'), /* Super Modern Browsers */
        url(${Inter400Woff}) format('woff'), /* Modern Browsers */
        url(${Inter400Ttf}) format('truetype'), /* Safari, Android, iOS */
        url(${Inter400Svg}#Inter) format('svg'); /* Legacy iOS */
    }


    @font-face {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        src: url(${Inter600}); /* IE9 Compat Modes */
        src: local(''),
            url(${Inter600}?#iefix) format('embedded-opentype'), /* IE6-IE8 */
            url(${Inter600Woff2}) format('woff2'), /* Super Modern Browsers */
            url(${Inter600Woff}) format('woff'), /* Modern Browsers */
            url(${Inter600Ttf}) format('truetype'), /* Safari, Android, iOS */
            url(${Inter600Svg}#Inter) format('svg'); /* Legacy iOS */
    }

    @font-face {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        src: url(${Inter700}); /* IE9 Compat Modes */
        src: local(''),
            url(${Inter700}?#iefix) format('embedded-opentype'), /* IE6-IE8 */
            url(${Inter700Woff2}) format('woff2'), /* Super Modern Browsers */
            url(${Inter700Woff}) format('woff'), /* Modern Browsers */
            url(${Inter700Ttf}) format('truetype'), /* Safari, Android, iOS */
            url(${Inter700Svg}#Inter) format('svg'); /* Legacy iOS */
    }

    @font-face {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 900;
        src: url(${Inter900}); /* IE9 Compat Modes */
        src: local(''),
            url(${Inter900}?#iefix) format('embedded-opentype'), /* IE6-IE8 */
            url(${Inter900Woff2}) format('woff2'), /* Super Modern Browsers */
            url(${Inter900Woff}) format('woff'), /* Modern Browsers */
            url(${Inter900Ttf}) format('truetype'), /* Safari, Android, iOS */
            url(${Inter900Svg}#Inter) format('svg'); /* Legacy iOS */
    }

`;

export default FontStyles;
