import React, { useState } from "react";
import { FaSort } from "react-icons/fa";
import { AiFillEye } from "react-icons/ai";
import { NFT, UPDATE, VIEW } from "../utils/constant";

import Tooltip from "@material-ui/core/Tooltip";
import ActiveStatus from "./ActiveStatus";
import InActiveStatus from "./InActiveStatus";

function ListLayoutSingleIcon({
  handleOptions,
  tableHeader,
  handleSorting,
  currentItems,
  type,
  isHistory,
  categoryType,
  toggle,
}) {
  const [order, setOrder] = useState(true);

  const handleSortTable = (item, orders) => {
    handleSorting(item, orders);
  };

  const handeldataShow = (dataItem, item) => {
    if (dataItem?.[item?.value] && !item.subValue && !item.subSubValue) {
      return dataItem?.[item?.value];
    } else if (
      dataItem?.[item?.value] &&
      dataItem?.[item?.value]?.[item?.subValue] &&
      !item.subSubValue
    ) {
      return dataItem?.[item?.value]?.[item?.subValue];
    } else if (
      item?.value &&
      item.subValue &&
      item.subSubValue &&
      Array?.isArray(dataItem?.[item?.value])
    ) {
      if (
        dataItem?.[item?.value][item?.positionInArray]?.[item?.subValue]?.[
          item?.subSubValue
        ]
      ) {
        return dataItem?.[item?.value][item?.positionInArray]?.[
          item?.subValue
        ]?.[item?.subSubValue];
      } else {
        return "--";
      }
    } else {
      return "--";
    }
  };

  return (
    <div className='flex flex-col'>
      <div className='overflow-x-auto'>
        <div className='p-1.5  min-w-full inline-block align-middle'>
          <div className='overflow-hidden border rounded-lg'>
            <table className='min-w-full divide-y divide-gray-200'>
              <thead className='bg-gray-50'>
                <tr>
                  {tableHeader.map((item, key) => {
                    return (
                      <th
                        key={key}
                        className='px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase max-w-xs truncate'>
                        {item.sorting ? (
                          <span className='flex'>
                            {item.key}
                            <FaSort
                              className='cursor-pointer text-md'
                              onClick={() => {
                                handleSortTable(item.value, !order);
                                setOrder(!order);
                              }}
                            />
                          </span>
                        ) : (
                          <>
                            {(parseInt(categoryType) === 2 ||
                              item.value === "votes") && (
                              <span className='flex float-left'>
                                {item.key}
                              </span>
                            )}
                          </>
                        )}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200'>
                {currentItems?.map((dataItem, dataKey) => {
                  if (dataItem.status === 1) {
                    dataItem.status2 = 1;
                    dataItem.status = <ActiveStatus message={"Active"} />;
                  } else if (dataItem.status === 2) {
                    dataItem.status2 = 2;
                    dataItem.status = <InActiveStatus message={"Inactive"} />;
                  }

                  return (
                    <tr key={dataKey}>
                      {tableHeader.map((item, key) => {
                        if (item.key === "options") {
                          return (
                            <td
                              key={key}
                              className='float-left px-5 py-4 font-normal text-gray-600 whitespace-nowrap flex justify-between w-fit'>
                              <>
                                {" "}
                                {parseInt(categoryType) === 2 && (
                                  <Tooltip title='view' arrow>
                                    <span className='p-2'>
                                      <AiFillEye
                                        className='cursor-pointer'
                                        onClick={() =>
                                          handleOptions(VIEW, dataItem)
                                        }
                                      />
                                    </span>
                                  </Tooltip>
                                )}
                              </>
                              {toggle && (
                                <Tooltip title='Status' arrow>
                                  <label
                                    htmlFor={dataItem?.id}
                                    className='inline-flex relative items-center cursor-pointer align-middle ml-5'>
                                    <input
                                      type='checkbox'
                                      checked={
                                        dataItem.status2 == 1 ? true : false
                                      }
                                      name='status'
                                      onChange={() =>
                                        handleOptions(UPDATE, dataItem)
                                      }
                                      id={dataItem?.id}
                                      className='sr-only peer'
                                    />
                                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[6px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                  </label>
                                </Tooltip>
                              )}

                              {/* {type && (
                                <Tooltip title='school common post' arrow>
                                  <span className='p-2'>
                                    <Link
                                      to={`/admin/school/post/${dataItem.id}`}>
                                      <BsCardList className='cursor-pointer' />
                                    </Link>
                                  </span>
                                </Tooltip>
                              )} */}
                            </td>
                          );
                        } else if (item.key === "S.No.") {
                          return (
                            <td
                              className={
                                item?.value === "total_nft" || item?.clickable
                                  ? "px-6 py-4 text-sm font-medium text-gray-600 whitespace-nowrap cursor-pointer max-w-xs  truncate"
                                  : "px-6 py-4 text-sm font-medium text-gray-600 whitespace-nowrap max-w-xs truncate "
                              }
                              onClick={
                                item?.value === "total_nft" || item?.clickable
                                  ? () => handleOptions(NFT, dataItem)
                                  : null
                              }
                              key={key}>
                              {dataKey + 1}
                            </td>
                          );
                        } else {
                          return (
                            <td
                              className={
                                item?.value === "total_nft" || item?.clickable
                                  ? "px-6 py-4 text-sm font-medium text-gray-600 whitespace-nowrap cursor-pointer max-w-xs  truncate "
                                  : "px-6 py-4 text-sm font-medium text-gray-600 whitespace-nowrap max-w-xs  truncate"
                              }
                              onClick={
                                item?.value === "total_nft" || item?.clickable
                                  ? () => handleOptions(NFT, dataItem)
                                  : null
                              }
                              key={key}>
                              {/* {console.log(
                                dataItem[item?.value][item?.subValue]
                              )} */}
                              {handeldataShow(dataItem, item)}
                              {/* {dataItem?.[item?.value] &&
                              dataItem?.[item?.value]?.[item?.subValue]
                                ? dataItem?.[item?.value]?.[item?.subValue]
                                : dataItem?.[item?.value] &&
                                  dataItem?.[item?.value]?.toString()?.length >
                                    0
                                ? dataItem?.[item?.value]
                                : "--"} */}
                            </td>
                          );
                        }
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListLayoutSingleIcon;
