import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  DashboardBody,
  DashboardRightBar,
  SiteForm,
} from "../../../common/website/HOC.jsx";
import { device } from "../../../common/website/Devices";
import { colors, space, siteGradient } from "../../../common/website/Variables";
import Classmates from "../../../components/website/Classmates/Classmates.jsx";
import RecentActivity from "../../../components/website/RecentActivity/RecentActivity.jsx";
import CreatePost from "../../../components/website/CreatePost/CreatePost.jsx";
import Post from "../../../components/website/Post/Post.jsx";
import IconBluePhotograph from "../../../images/website/icon_blue_photograph.svg";
import { useNavigate } from "react-router-dom";
import { admin, school, website } from "../../../linksprovider.js";
import {
  ADMIN_USER_DATA,
  GRADUATED,
  SCHOOL_MOMENTS,
} from "../../../utils/constant.js";
import SearchInput from "./SearchInput.jsx";
import RecentActivityEmpty from "../../../components/website/RecentActivity/RecentActivityEmpty.jsx";
import { PostContext } from "../../../components/website/header/MainHeader.jsx";
import SchoolPost from "../../../components/website/Post/SchoolPost.jsx";

const HomeRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  .club_group {
    display: none !important;
  }
  h3,
  h4,
  p {
    position: static;
  }
  .sidebar_right {
    display: none;
  }
  .upload_card {
    border-radius: ${space.space16};
    padding: 64px ${space.space24};
    text-align: center;
    .add_head {
      margin: 18px 0 2px;
    }
  }
  .upload_photos {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background-color: ${colors.lightblue};
    box-shadow: ${siteGradient.boxshadowblue};
    background-image: url(${IconBluePhotograph});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40px 40px;
    cursor: pointer;
    margin: auto;
    input {
      display: none;
    }
  }
  .classmates_list {
    height: calc(100vh - 472px);
  }
  @media ${device.mobileM} {
    .upload_photos {
      width: 100px;
      height: 100px;
      background-size: 54px 54px;
    }
    .upload_card {
      padding: ${space.space40} ${space.space24};
    }
  }
  @media ${device.tablet} {
    width: 75%;
    .sidebar_right {
      display: block;
    }
    .mobile_hidden {
      display: block;
    }
  }
  @media ${device.laptop} {
    width: 77%;
    .classmates_list {
      height: calc(100vh - 480px);
    }
  }
  @media ${device.laptopL} {
    width: 80%;
    .classmates_list {
      height: calc(100vh - 500px);
    }
  }
`;

function Home() {
  //variable of navigation
  let navigate = useNavigate();

  //getting user data from local or session storage
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }

  //getting state variables and function from context
  const {
    getinfo,
    searchData,
    postData,
    recentActivityData,
    classmatesData,
    setClubData,
    userInfo,
    paginantionState,
    setPage,
    setpostlocation,
    setTeamData,
    setIsMyMoment,
    gettingClassList,
  } = useContext(PostContext);

  const [classMatePage, setClassMatePage] = useState(1);

  const scrollRef = useRef();
  let pages = classMatePage;

  // Navigates to the student website.
  useEffect(() => {
    let test;

    if (userData && userData.user_type === 1) {
      navigate(admin.dashbord);
    } else if (userData && userData.user_type === 2) {
      navigate(school.dashbord);
    } else if (userData && userData.user_type === 3) {
      setIsMyMoment(false);
      setTeamData({});
      setClubData({});
      test = setTimeout(() => {
        console.log("me second");
        getinfo();
      }, 800);
    } else {
      navigate(website.studentLogin);
    }
    return () => clearTimeout(test);
  }, [searchData, paginantionState]);
  useEffect(() => {
    setpostlocation(SCHOOL_MOMENTS);
  }, []);

  useEffect(() => {
    setPage(1);
  }, []);

  console.log("school", postData);

  const handelPagination = () => {
    if (scrollRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        console.log("yesssss", scrollTop, clientHeight, scrollHeight);
        pages++;
        setClassMatePage(pages);
        let loader = 1;
        gettingClassList({ StudentInfo: userInfo, pages, loader: false });
      }
    }
  };

  return (
    <>
      <HomeRow>
        <DashboardBody>
          <SiteForm as='form' className='upload_form'>
            <div className='form_group mobile_hidden'>
              <SearchInput placeholder='Search Posts' />
            </div>
          </SiteForm>

          {<CreatePost location={"school_moments"} />}

          {postData?.length > 0 ? (
            postData?.map((val, i) => {
              return val?.User ? (
                <div
                  className={
                    userInfo?.standard?.toLowerCase() === GRADUATED ||
                    userInfo?.is_plan_expired === "1"
                      ? "mt-5"
                      : ""
                  }
                  key={val?.id}>
                  {val?.User?.user_type === 2 ? (
                    <SchoolPost val={val} key={i} />
                  ) : (
                    <Post val={val} key={i} />
                  )}
                </div>
              ) : (
                ""
              );
            })
          ) : (
            <p className='flex justify-center'>Post not found..!!</p>
          )}
        </DashboardBody>
        <DashboardRightBar className='sidebar_right'>
          {recentActivityData && recentActivityData?.length > 0 ? (
            <RecentActivity recentActivityData={recentActivityData} />
          ) : (
            <RecentActivityEmpty />
          )}
          <Classmates
            classmatesData={classmatesData}
            message={"No Classmates Found..!!"}
            pData={"Classmates"}
            paginationRef={scrollRef}
            handelPagination={handelPagination}
          />
        </DashboardRightBar>
      </HomeRow>
    </>
  );
}

export default Home;
