import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { colors, siteGradient, space } from "./Variables";
import { Field } from "formik";
import { device } from "./Devices";

import HeadingThree from "./HeadingThree.jsx";
import IconDropdown from "../../images/website/icon_dropdown.svg";
import { loaderContext } from "../../components/global loader/global-loader-provider";
import { PostContext } from "../../components/website/header/MainHeader";
import { ADMIN_USER_DATA } from "../../utils/constant";
import { StatusCodes } from "http-status-codes";
import { getSubscriptionDetail } from "../../services/auth.services";
import { toast } from "react-toastify";

const VotingSelectorRow = styled.div`
  background: ${siteGradient.linearGradientBorder};
  border-radius: ${space.space16};
  box-shadow: ${siteGradient.cardlightboxshadow};
  backdrop-filter: blur(15px);
  padding: 1px;
  margin: ${space.space24} 0 0;
  height: 100%;
  .select_inner {
    background: ${colors.body};
    padding: ${space.space24};
    border-radius: ${space.space16};
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  h3 {
    color: ${colors.primary};
    margin-bottom: ${space.space16};
    text-align: center;
  }
  select {
    font-size: 0.875rem;
    border: none;
    padding: ${space.space16} ${space.space20};
    padding-right: 38px;
    height: auto;
    background: url(${IconDropdown}) no-repeat !important;
    background-position: right 16px center !important;
    background-color: ${colors.darkgrey} !important;
    border-radius: ${space.space10};
  }
  @media ${device.mobileL} {
    .select_inner {
      padding: ${space.space40};
    }
    h3 {
      margin-bottom: ${space.space24};
    }
  }
`;

function VotingSelector(props) {
  const handelVotedData = () => {
    if (props?.votedData?.length > 0) {
      return (
        <option value='' selected disabled hidden>
          {props?.votedData[props?.i]?.User?.student_name
            ?.charAt(0)
            ?.toUpperCase() +
            props?.votedData[props?.i]?.User?.student_name?.slice(1)}
        </option>
      );
    } else {
      return "";
    }
  };

  return (
    <>
      <VotingSelectorRow>
        <div className='select_inner'>
          <HeadingThree
            h3Data={
              props?.value?.name
                ? props?.value?.name
                : props?.value?.category_name
            }
          />
          {props?.votedData?.length > 0 ? (
            <div className='flex justify-center bg-[#171717] py-3 rounded-md'>
              {props?.user?.student_name?.charAt(0)?.toUpperCase() +
                props?.user?.student_name?.slice(1)}
            </div>
          ) : (
            <select
              as='select'
              name={props?.value?.name}
              onChange={(e) => props?.handelChange(e, props?.value?.id)}>
              <option value='' selected disabled hidden>
                Select student
              </option>

              {props?.votedData?.length > 0 ? null : props?.classmatesData
                  ?.length > 0 ? (
                props?.classmatesData?.map((val) => {
                  return (
                    <option
                      value={val?.id}
                      key={val?.id}
                      className='text-white'>
                      {val?.student_name?.charAt(0)?.toUpperCase() +
                        val?.student_name?.slice(1)}
                    </option>
                  );
                })
              ) : (
                <option value='' className='text-white' disabled>
                  Classmate not found..!!
                </option>
              )}
              {/* <option value='student1'>student 1</option>
            <option value='student2'>student 2</option>
            <option value='student3'>student 3</option>
            <option value='student4'>student 4</option> */}
            </select>
          )}
        </div>
      </VotingSelectorRow>
    </>
  );
}
export default VotingSelector;
