import React, { useContext } from "react";
import styled from "styled-components";

import { Link, useNavigate } from "react-router-dom";
import { colors, space, siteGradient } from "../../../common/website/Variables";
import { device } from "../../../common/website/Devices";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  LoginCard,
  LoginCardInner,
  SiteForm,
} from "../../../common/website/HOC.jsx";

import HeadingTwo from "../../../common/website/HeadingTwo.jsx";
import Paragraph from "../../../common/website/Paragraph.jsx";
import Label from "../../../common/website/Label.jsx";

import IconCheck from "../../../images/website/icon_check_blue.svg";
import siteLogo from "../../../images/website/site_logo.svg";
import OpenEye from "../../../images/website/icon_open_eye.svg";
import CloseEye from "../../../images/website/icon_close_eye.svg";
import { loaderContext } from "../../../components/global loader/global-loader-provider";
import { toast } from "react-toastify";
import { StatusCodes } from "http-status-codes";
import { forgetPassword } from "../../../services/auth.services";
import { website } from "../../../linksprovider";

const ForgotPasswordRow = styled.div`
  h2 {
    margin-bottom: ${space.space14};
    text-align: center;
  }
  .form_group {
    margin-bottom: ${space.space24};
  }
  .subtilte {
    margin: 0 auto;
    margin-bottom: ${space.space30};
    text-align: center;
    max-width: 350px;
  }
  .submit-btn {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    font-family: "Inter", sans-serif;
    background: linear-gradient(90deg, #30dff2 0.7%, #70308c 97.91%);
    color: #ffffff;
    width: 100%;
    border-radius: 0.5rem;
    padding: ${space.space8} 1rem;
    display: inline-block;
    text-align: center;
    position: relative;
    outline: 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: #30dff2;
      background: #0b0b0b;
      transition: all 0.3s ease-in-out;
    }
    &::after,
    &::before {
      background: ${siteGradient.bluePink};
      position: absolute;
      content: "";
      border-radius: ${space.space8};
      z-index: -1;
    }

    &::before {
      background-size: 400%;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      top: -2px;
      left: -2px;
      opacity: 0;
      filter: blur(5px);
      transition: all 0.3s ease-in-out;
      animation: glowing 20s linear infinite;
    }

    &::after {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }

    &:hover:not(:disabled),
    &:active:not(:disabled),
    &:focus {
      color: ${colors.primary};
      background: ${colors.body};
      transition: all 0.3s ease-in-out;

      &::before {
        opacity: 1;
        color: ${colors.primary};
      }

      &::after {
        background: ${colors.body};
      }
    }
  }
  .account_link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ${space.space16};
    grid-gap: 6px;
    flex-wrap: wrap;
    p {
      font-size: 0.875rem;
      margin: 0;
    }
    .link {
      font-size: 0.875rem;
      line-height: 19px;
      color: ${colors.primary};
      padding: 0;
      &:hover {
        color: ${colors.secondary};
      }
    }
  }
  .check_group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    grid-gap: 10px;
    .left {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      white-space: nowrap;
      input[type="checkbox"] {
        width: 20px;
        height: 20px;
        cursor: pointer;
        border: 1px solid ${colors.white};
        border-radius: 5px;
        appearance: none;
        overflow: hidden;
        padding: 0;
        &:checked {
          background-image: url(${IconCheck});
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          border: 1px solid ${colors.primary};
        }
        &[value="true"] {
          background-image: url(${IconCheck});
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          border: 1px solid ${colors.primary};
        }
      }
      label {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        padding-left: 10px;
        margin: 0;
      }
    }
    .right {
      a {
        padding: 0;
        &:hover {
          color: ${colors.secondary};
        }
      }
    }
  }
  .logo {
    text-align: center;
    margin-bottom: ${space.space20};
    display: block;
    a {
      display: inline-block;
    }
    img {
      max-width: 120px;
    }
  }
  .pass_group {
    position: relative;
    input {
      padding-right: 26px;
    }
    .eye_btn {
      content: "";
      position: absolute;
      background: url(${OpenEye}) no-repeat;
      background-size: contain;
      background-position: center;
      width: 18px;
      height: 18px;
      right: 0;
      bottom: 15px;
    }
    input[type="text"] ~ .eye_btn {
      background: url(${CloseEye}) no-repeat;
    }
  }
  @media ${device.mobileM} {
    .account_link {
      p,
      .link {
        font-size: 1rem;
      }
    }
  }
  @media ${device.mobileL} {
    .logo {
      img {
        max-width: 150px;
      }
    }
    .account_link {
      margin-top: ${space.space30};
    }
    .form_group {
      margin-bottom: ${space.space30};
    }
  }
  @media ${device.tablet} {
    .subtilte {
      margin-bottom: ${space.space50};
    }
    .logo {
      margin-bottom: ${space.space24};
      img {
        max-width: 168px;
      }
    }
    .form_group {
      margin-bottom: ${space.space50};
    }
    .submit-btn {
      padding: ${space.space12} ${space.space24};
    }
  }
  @media ${device.laptop} {
    .logo {
      display: none;
    }
  }
`;

function ForgotPassword(props) {
  const { loaderDispatch } = useContext(loaderContext);
  let navigate = useNavigate();

  // Sets the initial values of the email address.
  const initialValues = {
    email: "",
  };

  // Creates a validation schema
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email")
      .required("Please fill the field"),
  });

  // Attempts to forget the user s password.
  const onSubmit = async (values, { setSubmitting }) => {
    try {
      console.log("values", values);
      let body = {
        email: values.email.trim(),
      };
      loaderDispatch({
        isLoading: true,
      });
      let result = await forgetPassword(body);
      loaderDispatch({
        isLoading: false,
      });
      console.log(result);

      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
          theme: "dark",
        });
        props.handleClick("login");
      } else {
        toast(result?.data?.message, {
          type: "error",
          theme: "dark",
        });
      }
      setSubmitting(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <ForgotPasswordRow className='right'>
      <LoginCard>
        <LoginCardInner>
          <div className='logo'>
            <Link to='/'>
              <img
                src={siteLogo}
                srcSet={siteLogo}
                className='sitelogo_img'
                alt='YearBook3.0'
              />
            </Link>
          </div>
          <HeadingTwo cname='white' h2Data={"Forgot Password"} />
          <Paragraph
            cname='subtilte'
            pData={
              "Enter the email associated with your account and we'll send an email with instruction to reset your password."
            }
          />
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>
            {({
              isSubmitting,
              /* and other goodies */
            }) => (
              <SiteForm as='div'>
                <Form>
                  <div className='grid gap-0 md:grid-cols-1 items-center form_label_group'>
                    <div className='form_group'>
                      <Label labelData={"Email*"} />
                      <Field
                        type='email'
                        name='email'
                        placeholder='Enter your email'
                        autoComplete='off'
                      />
                      <ErrorMessage
                        name='email'
                        component='div'
                        className='text-red-500 text-sm'
                      />
                    </div>
                  </div>
                  <div className='btn_group'>
                    <button
                      className='gradient submit-btn'
                      type='submit'
                      disabled={isSubmitting}>
                      Send
                    </button>
                  </div>
                  <div className='account_link'>
                    <button
                      className='link'
                      onClick={() => props.handleClick("login")}>
                      Back to Login
                    </button>
                  </div>
                </Form>
              </SiteForm>
            )}
          </Formik>
        </LoginCardInner>
      </LoginCard>
    </ForgotPasswordRow>
  );
}

export default ForgotPassword;
