import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  adminPostStatus,
  deletePost,
  postListing,
} from "../../services/auth.services";
import { loaderContext } from "../global loader/global-loader-provider";
import { ADMIN_USER_DATA, COMMENT, COMMON_WALL } from "../../utils/constant";
import { toast } from "react-toastify";
import AdminClubPostDetails from "../../Pages/admin club managment/AdminClubPostDetails.jsx";
import AdminDeleteModel from "../../common/AdminDeleteModel.jsx";
import AdminPostDetailModal from "../../Pages/admin club managment/AdminPostDetailModal.jsx";
import { StatusCodes } from "http-status-codes";
import ActiveInactiveModal from "../../common/ActiveInactiveModal.jsx";
import { BiArrowBack } from "react-icons/bi";

export default function AdminSchoolPost() {
  const [postData, setPostData] = useState({
    Posts: [],
  });
  const [search, setSearch] = useState("");
  const { loaderState, loaderDispatch } = useContext(loaderContext);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openPostModal, setOpenPostModal] = useState(false);
  const [postDetail, setPostDetail] = useState({});
  const [type, setType] = useState("");
  const [id, setId] = useState("");
  const [openActiveModal, setOpenActiveModal] = useState(false);
  const [isActivePost, setActive] = useState(false);
  const [page, setPage] = useState(1);

  let { schoolId } = useParams();
  let navigate = useNavigate();
  let postref = useRef();

  useEffect(() => {
    const debunce = setTimeout(() => {
      getAllPostDetails(page);
    }, 300);

    return () => {
      clearTimeout(debunce);
    };
  }, []);

  const getAllPostDetails = async (pagesss, loader = null) => {
    if (!loader) {
      loaderDispatch({
        isLoading: true,
      });
    }
    try {
      let params = {
        id: schoolId,
        search: search,
        page: pagesss,
      };

      let result = await postListing(params);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        let postListingData = result?.data?.data?.postsListing?.rows;
        setPostData((preVal) => {
          return {
            Posts: [...preVal.Posts, ...postListingData],
          };
        });
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const adminDeletePost = async () => {
    loaderDispatch({
      isLoading: true,
    });
    try {
      let result = await deletePost(id);
      loaderDispatch({
        isLoading: false,
      });
      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
        });
        setOpenDeleteModal(false);
        setPostData((preVal) => {
          for (let i = 0; i < preVal?.Posts?.length; i++) {
            if (preVal?.Posts[i]?.id === id) {
              delete preVal?.Posts[i];
            }
          }
          return preVal;
        });
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
        setOpenDeleteModal(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const searchKeyWord = () => {
    setPostData({ Posts: [] });
    getAllPostDetails(page);
  };

  const handleStatus = async () => {
    loaderDispatch({
      isLoading: true,
    });

    try {
      let jsonData = {
        status: isActivePost ? "2" : "1",
      };

      let result = await adminPostStatus(id, jsonData);

      if (result?.data?.status === StatusCodes.OK) {
        toast(result?.data?.message, {
          type: "success",
        });
        setPostData((preVal) => {
          for (let i = 0; i < preVal?.Posts?.length; i++) {
            if (preVal?.Posts[i]?.id === id) {
              preVal.Posts[i].status = isActivePost ? 2 : 1;
            }
          }
          return preVal;
        });
      } else {
        toast(result?.data?.message, {
          type: "error",
        });
      }

      setActive(!isActivePost);

      loaderDispatch({
        isLoading: false,
      });

      setOpenActiveModal(false);
      getAllPostDetails(page);
    } catch (error) {
      console.error(error);
    }
  };

  // const GoToBottom = () => {
  //   if (listInnerRef?.current) {
  //     setTimeout(() => {
  //       const { scrollHeight, scrollTop } = listInnerRef.current;

  //       listInnerRef?.current?.scrollTo({
  //         top: scrollHeight + scrollTop,
  //         behavior: "auto",
  //       });
  //     }, 100);
  //   }
  // };

  let pages = page;
  const handelScroll = () => {
    if (postref?.current) {
      const { scrollTop, scrollHeight, clientHeight } = postref.current;
      if (scrollTop + clientHeight === scrollHeight) {
        pages++;
        setPage(pages + 1);
        let loader = 1;
        getAllPostDetails(pages, loader);
      }
    }
  };

  return (
    <>
      <div
        className=' bg-gray-100 h-screen overflow-auto'
        ref={postref}
        onScroll={handelScroll}>
        <button
          className=' bg-white px-2 py-2 flex rounded-md shadow text-gray-500 hover:text-gray-600 mb-4 ml-6 absolute top-7 justify-center items-center '
          onClick={() => navigate(-1)}>
          <BiArrowBack className='text-xl outline-none ' />
          <p>Go Back</p>
        </button>
        <div className=' max-w-[55%] mx-auto '>
          <div className='space-y-6 lg:col-start-1 lg:col-span-2'>
            <AdminClubPostDetails
              viewData={postData}
              setOpenDeleteModal={setOpenDeleteModal}
              setOpenPostModal={setOpenPostModal}
              setPostDetail={setPostDetail}
              setType={setType}
              changePostStatus={setOpenActiveModal}
              setId={setId}
              setActive={setActive}
              setSearch={setSearch}
              type={COMMON_WALL}
              searchKeyWord={searchKeyWord}
            />
          </div>
        </div>
      </div>
      <AdminDeleteModel
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        deleteData={adminDeletePost}
        header={"Delete Post"}
      />
      <AdminPostDetailModal
        openDeleteModal={openPostModal}
        setOpenDeleteModal={setOpenPostModal}
        postData={postDetail}
        type={type}
      />

      <ActiveInactiveModal
        openActiveModal={openActiveModal}
        setOpenActiveModal={setOpenActiveModal}
        isActivePost={isActivePost}
        handleStatus={handleStatus}
      />
    </>
  );
}
