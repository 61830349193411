import React, { useContext, useEffect, useState } from "react";
import ListLayout from "../../shared/listLayout.jsx";
import {
  ADMIN_USER_DATA,
  NFT,
  REPORTS,
  USERLIST,
  USERS,
} from "../../../utils/constant.js";
import DateFilter from "../../date_filter/DateFilter.jsx";
import SearchFilter from "../../search_filter/SearchFilter.jsx";
import DownloadButton from "../../download_button/DownloadButton.jsx";
import ReactPaginate from "react-paginate";
import { StatusCodes } from "http-status-codes";
import {
  adminStudentListing,
  adminStudentListingincom,
  downloadCSV,
  getSchoolList,
} from "../../../services/auth.services.js";
import { loaderContext } from "../../global loader/global-loader-provider.js";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useParams, useNavigate } from "react-router-dom";
import SelectFilter from "../../school_select_filter/SelectFilter.jsx";
import { toast } from "react-toastify";
import { SchoolContext } from "../../../common/SchoolDashboardWrapper.jsx";

function Usercomplate() {
  const [sortby, setSortby] = useState("id");
  const [orderby, setOrderby] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [dataCount, setDataCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [data, setData] = useState([]);
  const [sendingDate, setSedningDate] = useState({
    startDate: "",
    endDate: "",
  });
  let { id } = useParams();
  const [searchItem, setSearchItem] = useState("");
  const [open, setOpen] = useState(false);
  const [schoolOptions, setSchoolOptions] = useState([
    {
      value: "",
      label: "Select School",
      selected: false,
    },
  ]);
  const [dates, setDates] = useState({
    startDate: "",
    endDate: "",
  });

  const [fileLink, setFileLink] = useState(null);

  const tableHeader = [
    {
      key: "S.No.",
      value: "key",
      clickable: false,
      sorting: false,
    },
    {
      key: "User Name",
      value: "student_name",
      clickable: false,
      sorting: true,
    },
    { key: "Email address", value: "email", clickable: false, sorting: true },

    { key: "status", value: "status", clickable: false, sorting: true },
  ];

  const itemsOnPage = [
    { label: 10, value: "10" },
    { label: 20, value: "20" },
    { label: 50, value: "50" },
    { label: 70, value: "70" },
    { label: 100, value: "100" },
  ];

  const { setAdminActiveTab } = useContext(SchoolContext);
  let userData = JSON.parse(sessionStorage.getItem(ADMIN_USER_DATA));
  // if (!userData) {
  //   userData = JSON.parse(localStorage.getItem(ADMIN_USER_DATA));
  // }

  const { loaderDispatch } = useContext(loaderContext);
  let navigate = useNavigate();

  useEffect(() => {
    setAdminActiveTab(USERLIST);
  }, []);

  useEffect(() => {
    const debunce = setTimeout(() => {
      handleSchoolData();
      handleStudentData();
      // setItemOffset(1);
    }, 300);

    return () => {
      clearTimeout(debunce);
    };
  }, [searchItem, sortby, orderby, itemOffset, itemsPerPage, sendingDate]);

  useEffect(() => {
    setCurrentItems(data);
    setPageCount(dataCount);
  }, [data, dataCount, itemsPerPage]);

  useEffect(() => {
    let event = {
      selected: 0,
    };
    handlePageClick(event);
  }, [sendingDate]);

  const handleStudentData = async () => {
    loaderDispatch({
      isLoading: true,
    });
    try {
      if (searchItem.toLowerCase() === "active") {
        setSearchItem("2");
      }
      if (searchItem.toLowerCase() === "inactive") {
        setSearchItem("1");
      }
      let order = orderby ? "desc" : "asc";

      if (searchItem.toLowerCase() === "active") {
        setSearchItem("1");
      } else if (searchItem.toLowerCase() === "inactive") {
        setSearchItem("2");
      }
      console.log("itemOffset", itemOffset);
      let result = await adminStudentListingincom(
        userData.id,
        `search=${searchItem.trim()}&sortBy=${sortby}&order=${order}&page=${itemOffset}&limit=${itemsPerPage}&isCompleted=true&todoId=${id}`
      );
      loaderDispatch({
        isLoading: false,
      });
      setData([]);
      setDataCount(0);

      if (
        result?.data?.status === StatusCodes.OK &&
        result?.data?.data?.totalUsers > 0
      ) {
        const studentData = result?.data?.data?.students;
        const studentDataCount = result?.data?.data?.totalPages;
        let studentInfo = [];
        for (let i = 0; i < studentData.length; i++) {
          studentInfo.push({
            id: studentData[i].id,
            student_name: studentData[i].student_name,
            email: studentData[i].email,
            academic_year: studentData[i].academic_year,
            metamask_id: studentData[i].metamask_id,
            total_product: studentData[i].total_product,
            user_type: handelUserType(studentData[i].user_type),
            status: studentData[i]?.status,
          });
        }

        setData((preVal) => {
          return [...preVal, ...studentInfo];
        });
        setDataCount(studentDataCount);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const userTypeDetails = [
    {
      type: 1,
      value: "Admin",
    },
    {
      type: 2,
      value: "School",
    },
    {
      type: 3,
      value: "Student",
    },
  ];

  const handelUserType = (type) => {
    for (let i = 0; i < userTypeDetails.length; i++) {
      if (userTypeDetails[i].type === type) {
        return userTypeDetails[i].value;
      }
    }
  };

  const handleSchoolData = async () => {
    loaderDispatch({
      isLoading: true,
    });
    try {
      let order = orderby ? "desc" : "asc";
      let body = {
        itemOffset: "",
        itemPerPage: "",
        keyWord: "",
        sortName: "",
        sortTypeName: "",
      };

      let result = await getSchoolList(body);

      loaderDispatch({
        isLoading: false,
      });

      if (
        result?.data?.status === StatusCodes.OK &&
        result?.data?.data?.schoolListing?.count > 0
      ) {
        setSchoolOptions([
          {
            value: "",
            label: "Select School",
            selected: false,
          },
        ]);
        let data = result?.data?.data?.schoolListing?.rows;
        let schoolList = [];
        for (let i = 0; i < data.length; i++) {
          schoolList.push({
            value: data[i].id,
            label: data[i].school_name,
            selected: false,
          });
        }
        setSchoolOptions((preVal) => {
          return [...preVal, ...schoolList];
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSorting = (data, order) => {
    setSortby(data);
    setOrderby(order);
  };

  const handlePageClick = (event) => {
    setItemOffset(event.selected + 1);
  };

  return (
    <>
      <div className="p-2 grid grid-cols-2 gap-2 mt-1">
        <div className=" flex justify-center">
          <SearchFilter
            setSearchItem={setSearchItem}
            searchItem={searchItem}
            setPage={setItemOffset}
            cname={
              " block w-full border border-gray-300 rounded-md shadow py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
            }
          />
        </div>
      </div>
      <ListLayout
        tableHeader={tableHeader}
        handleSorting={handleSorting}
        currentItems={currentItems}
      />
      <div className="flex justify-between">
        {data?.length > 0 && (
          <>
            <div
              className="block lg:inline-block lg:mt-0"
              style={{ marginTop: "6px" }}
            >
              <label htmlFor="item" className="ml-5">
                Record Per Page:
              </label>
              <SelectFilter
                options={itemsOnPage}
                selectCls={"ml-3 bg-white border p-2 rounded"}
                name={"item"}
                id={"item"}
                onChange={(e) => {
                  setItemsPerPage(parseInt(e.target.value));
                }}
              />
            </div>
            <div className="mt-2 mb-10">
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next >"
                onPageChange={handlePageClick}
                pageCount={pageCount}
                previousLabel="< Previous"
                renderOnZeroPageCount={null}
                containerClassName={"pagination"}
                pageClassName={"pageClass"}
                forcePage={itemOffset - 1}
              />
            </div>
          </>
        )}
      </div>
      {data?.length === 0 && (
        <div className="flex flex-row absolute left-1/2 top-1/2">
          <p>Data not found..!!</p>
        </div>
      )}
    </>
  );
}

export default Usercomplate;
